import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import { PageHeader } from '../../../components/elements';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Header = ({
  values,
  loading,
  handleSubmit,
  location,
  placeName,
  status,
  claimStatus,
  next,
  prev,
  activeTab,
  history,
  dirty,
}) => (
  <PageHeader
    title={location.pathname === '/add-place' ? 'Add a Place' : 'Edit a Place'}
    otherText={
      location.pathname === '/add-place'
        ? ''
        : `${placeName} | ${values.state} | ${values.city}, ${values.post_code} (${status} / ${claimStatus})`
    }
  >
    <Level.Item align="right">
      <Level.Item>
        <a onClick={() => history.goBack()} className="button is-light">
          <span>Cancel</span>
        </a>
      </Level.Item>
      {activeTab !== 0 && (
        <Level.Item>
          <button onClick={prev} className="button is-primary">
            <span>Prev Tab</span>
          </button>
        </Level.Item>
      )}

      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action={`${
              location.pathname === '/add-place'
                ? 'create_place'
                : 'update_place'
            }`}
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => (
              <Level.Item>
                <button
                  type="submit"
                  className={`button is-primary ${loading && 'is-loading'}`}
                  onClick={handleSubmit}
                  disabled={!dirty}
                >
                  <span>Submit</span>
                </button>
              </Level.Item>
            )}
          />
        )}
      </ACLSConsumer>

      {activeTab !== 4 && (
        <Level.Item>
          <button onClick={next} className="button is-primary">
            <span>Next Tab</span>
          </button>
        </Level.Item>
      )}
    </Level.Item>
  </PageHeader>
);

export default withRouter(Header);
