import React from 'react';

const PlaceDetailsCard = ({ placeData }) =>
  //
  placeData && (
    <div style={{ paddingLeft: '15px' }}>
      <p>{placeData?.name}</p>
      <p style={{ color: '#737373' }}>
        {placeData?.city}, {placeData?.post_code} | Australia
      </p>
    </div>
  );
export default PlaceDetailsCard;
