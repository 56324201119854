/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';

const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const FormSection = styled.section``;

const getContainerClassNames = isActiveModal =>
  isActiveModal ? 'modal is-active' : 'modal';

const ChartsScreen = ({ isActiveModal, onClose }) => {
  return (
    <div className={getContainerClassNames(isActiveModal)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '60%', height: '60vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">Charts</ModalTitle>
          <button
            onClick={onClose}
            className="delete"
            aria-label="close"
            type="button"
          />
        </header>
        <FormSection className="modal-card-body">
          <span style={{ color: 'red', padding: '1rem' }}>
            Not yet implemented...
          </span>
        </FormSection>
      </div>
    </div>
  );
};

export default ChartsScreen;
