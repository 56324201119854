import React from 'react';

import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';

const FoodOverridePlaceListing = ({ history }) => (
  <Layout>
    <PlaceSelection
      heading="Food Order Override Fees"
      handleSelect={value => {
        history.push(`food-order-override-fee/${value.place_id}`);
      }}
    />
  </Layout>
);

export default FoodOverridePlaceListing;
