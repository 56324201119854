import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';

import CommentModal from './CommentModal';

const AddItemsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 60%;
  }
`;

const ModifierItems = () => {
  const [isActiveModal, setIsActiveModal] = useState(false);

  return (
    <FieldArray
      render={() => (
        <>
          <AddItemsButton
            type="button"
            className="button is-small"
            onClick={() => setIsActiveModal(true)}
          >
            Manage
          </AddItemsButton>

          <CommentModal
            isCreateLinkLoading={false}
            isActive={isActiveModal}
            onClose={() => setIsActiveModal(false)}
          />
        </>
      )}
    />
  );
};

export default ModifierItems;
