import React from 'react';
// import styled from 'styled-components';

// import ScheduleCard from './ScheduleCard';
import ScheduledTab from './ScheduledTab';

// const Container = styled.div`
//   padding: 1rem 1rem;
// `;

// const Flex = styled.div`
//   display: flex;
// `;

// const DetailsButton = styled.button`
//   &&& {
//     border-color: ${({ theme }) => theme.primaryColor};
//     border-width: 2px;
//     color: ${({ theme }) => theme.primaryColor};
//     font-weight: 600;
//     width: 20%;
//     height: 6vh;
//   }
// `;

const Scheduled = ({
  place,
  scheduledOrderCount,
  isActiveTab,
  currentSubscribeToMenuOrderMoveToActive,
  setCurrentSubscribeToMenuOrderMoveToActive,
}) => (
  <>
    <ScheduledTab
      isActiveTab={isActiveTab}
      currentSubscribeToMenuOrderMoveToActive={
        currentSubscribeToMenuOrderMoveToActive
      }
      setCurrentSubscribeToMenuOrderMoveToActive={
        setCurrentSubscribeToMenuOrderMoveToActive
      }
      place={place}
      scheduledOrderCount={scheduledOrderCount}
    />
  </>
);

export default Scheduled;
