import React from 'react';

import { Table, Button, Icon } from 'rbx';

import styled from 'styled-components';

const Element = styled.div`
  width: 8rem;
`;

const List = ({ fees, onEdit, onDelete }) => (
  <>
    {fees.map(item => (
      <Table.Row>
        <Table.Cell>
          <Element>{item.internal_name}</Element>
        </Table.Cell>
        <Table.Cell>{item.display_name}</Table.Cell>
        <Table.Cell>{item.status}</Table.Cell>
        <Table.Cell>
          <Element>{item.fee_type}</Element>
        </Table.Cell>
        <Table.Cell>
          <Element>
            {item.fee_type === 'PERCENTAGE'
              ? `${parseFloat(item.value).toFixed(2)} %`
              : `$${parseFloat(item.value).toFixed(2)}`}
          </Element>
        </Table.Cell>
        <Table.Cell>
          <Element>
            {(fee => {
              const period = {
                NA: 'NA',
                DAYS: (fee?.days ?? [])
                  .filter(ele => ele.is_active)
                  .map(ele => ele.day)
                  .join(', '),
                DATE: fee?.start_date,
              };
              return period[fee.period];
            })(item)}
          </Element>
        </Table.Cell>
        <Table.Cell>
          <Element>{item.payment_method}</Element>
        </Table.Cell>
        <Table.Cell>
          <Element>
            <Button.Group hasAddons>
              <Button type="button" onClick={() => onEdit(item)}>
                <Icon size="small">
                  <i className="fas fa-edit" />
                </Icon>
              </Button>
              <Button type="button" onClick={() => onDelete(item)}>
                <Icon size="small">
                  <i className="fas fa-trash has-text-grey" />
                </Icon>
              </Button>
            </Button.Group>
          </Element>
        </Table.Cell>
      </Table.Row>
    ))}
  </>
);

export default List;
