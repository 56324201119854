import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../../../components/elements';
import OrderDetails from './OrderDetails';

const ActivityTable = () => {
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>
                  Order Date
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Order No.</span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Service
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Ref</span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Payment
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Sale
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Fee
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Processing
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Net Payout
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link
                onClick={() => setIsActiveOrderDetailsModal(true)}
                style={{ color: '#00aeef' }}
              >
                25/03/2020 8:45 AM
              </Link>
            </td>
            <td>2SB477VA5</td>
            <td>Delivery</td>
            <td>Melbourne Cup stakes 2020</td>
            <td>Cash</td>
            <td>$5430</td>
            <td>$230.00</td>
            <td>$0.00</td>
            <td>($230.00)</td>
          </tr>
        </tbody>
      </Table>
      <OrderDetails
        isActiveOrderDetailsModal={isActiveOrderDetailsModal}
        setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
      />
    </>
  );
};

export default ActivityTable;
