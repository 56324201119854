import React from 'react';
import { Link } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({ heading, loading = false, userName, onCancel }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>{heading}</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <Link to={onCancel} className="button is-light">
            <span>Cancel</span>
          </Link>
        </Level.Item>
        <Level.Item>
          <button className={`button is-primary ${loading && 'is-loading'}`}>
            <span>Submit</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>

    <>
      <SubHeading className="is-size-2">{userName}</SubHeading>
      <SubHeading className="is-size-2">
        Joined 18 June 2018 (2 years 2 month before)
      </SubHeading>
    </>
  </Wrapper>
);

export default Header;
