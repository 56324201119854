/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { omit } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import client from '../../../../../utils/apolloClient';

import { editDispatchEnums } from '../helpers';

import CategoryBtn from './CategoryBtn';

const LoadingContainer = styled.div`
  padding: 1rem;
  position: absolute;
  z-index: 300;
`;

const CategoryLoadingIndicator = () => (
  <LoadingContainer>
    <ReactLoading type="spin" color="#363636" />
  </LoadingContainer>
);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const updateMenuMutation = gql`
  mutation updateMenu($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const CategoriesUI = ({
  categoriesIsLoading,
  categories,
  onClick,
  activeCategory,
  setCategories,
  editCategories,
  editsDispatch,
  onTrashIconClick,
  userId,
  menuId,
}) => {
  const [updateMenu] = useMutation(updateMenuMutation, {
    client: client.clientPrivate,
    onCompleted: () => {},
  });

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      categories,
      result.source.index,
      result.destination.index,
    );
    const sortedCategories = newItems.map((item, index) => ({
      ...omit(item, '__typename'),
      display_order: index,
    }));
    setCategories(sortedCategories);
    updateMenu({
      variables: {
        input: {
          menu_section: sortedCategories,
          user_id: userId,
          menu_id: menuId,
        },
      },
    });
  };
  React.useEffect(() => {
    if (categories.length === 0 || categories.length === 1) {
      // pass
    }
    const [, ...remainingCategories] = categories;

    const haveDisplayOrderZero = remainingCategories.some(
      ele => ele.display_order === 0,
    );
    if (haveDisplayOrderZero) {
      const sortedCategories = categories.map((category, idx) => ({
        ...omit(category, '__typename'),
        display_order: idx,
      }));
      setCategories(sortedCategories);
      updateMenu({
        variables: {
          input: {
            menu_section: sortedCategories,
            user_id: userId,
            menu_id: menuId,
          },
        },
      });
    }
  }, [categories]);

  return (
    <div style={{ width: '170px', position: 'relative' }}>
      {categoriesIsLoading && <CategoryLoadingIndicator />}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map((ele, index) => (
                <Draggable
                  key={`${ele.name} ${index}`}
                  draggableId={ele.menu_section_id}
                  index={index}
                  isDragDisabled={!editCategories}
                >
                  {provided2 => (
                    <div
                      ref={provided2.innerRef}
                      {...provided2.draggableProps}
                      {...provided2.dragHandleProps}
                    >
                      <CategoryBtn
                        categoryName={ele.name}
                        key={`${ele.name} ${index}`}
                        categoryId={ele.menu_section_id}
                        onClick={() => {
                          onClick(ele);
                          editsDispatch({
                            type: editDispatchEnums.STOP_EDIT_CATEGORIES,
                          });
                          editsDispatch({
                            type: editDispatchEnums.STOP_EDIT_MENU_ITEMS,
                          });
                        }}
                        isLast={index + 1 === categories.length}
                        activeBtn={activeCategory}
                        editCategories={editCategories}
                        onTrashIconClick={onTrashIconClick}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CategoriesUI;
