import React from 'react';
import styled from 'styled-components';

import Hours from './HoursModal';
import WeeklyHours from './WeeklyHours';

const Container = styled.div`
  padding: 1rem;
  width: 75%;
`;

const BookingHours = ({ setFieldValue, values }) => {
  const [isActiveHourModal, setIsActiveHourModal] = React.useState(false);
  return (
    <Container>
      {isActiveHourModal && (
        <Hours
          isActive={isActiveHourModal}
          onClose={() => {
            setIsActiveHourModal(false);
          }}
          setFieldValue={setFieldValue}
          values={values}
        />
      )}
      <WeeklyHours
        hours={values.booking_hours}
        onClick={() => setIsActiveHourModal(true)}
      />
    </Container>
  );
};

export default BookingHours;
