import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Loading, Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import client from '../../../utils/apolloClient';

import UpdateForm from './UpdateForm';

// const searchUserMenuDealsQuery = gql`
//   query searchMenuDeals($input: SearchInput) {
//     search_user_menu_deal(input: $input) {
//       menu_deal_listing {
//         deal_id
//         name
//         promo_code
//         description
//         mobile_only
//       }
//     }
//   }
// `;

const searchUserMenuDealsQuery = gql`
  query searchMenuDeals($input: SearchInput) {
    search_user_menu_deal(input: $input) {
      menu_deal_listing {
        user_id
        deal_id
        place_id
        menu_id
        deal_type
        promotion
        promo_code
        name
        service_type
        start
        end
        image_url
        slug
        description
        is_published
        customer_type
        can_be_clubbed
        minimum_purchase_amount
        value
        maximum_discount_amount
        maximum_no_of_discounts
        maximum_discount
        maximum_no_of_discounts_per_user
        maximum_discount_per_user
        status
        mobile_only
        free_delivery
      }
    }
  }
`;

const UpdateUserPromo = ({ history, match }) => (
  <Layout>
    <Query
      client={client.clientPrivate}
      query={searchUserMenuDealsQuery}
      variables={{
        input: {
          from: 0,
          size: 10,
          user_id: match.params.userId,
          filter: {
            user_menu_deal_filter: {
              deal_id: match.params.dealId,
            },
          },
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <Message>error</Message>;
        }

        const { search_user_menu_deal } = data;
        let dealsData = {};
        if (Array.isArray(search_user_menu_deal?.menu_deal_listing)) {
          const deal = search_user_menu_deal.menu_deal_listing.filter(
            ele => ele.deal_id === match.params.dealId,
          );
          if (deal.length !== 0) {
            dealsData = { ...deal[0] };
          }
        }

        const updateInput = { dealsData, userId: match.params.userId };

        return <UpdateForm updateInput={updateInput} history={history} />;
      }}
    </Query>
  </Layout>
);

export default UpdateUserPromo;
