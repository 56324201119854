import React from 'react';
import Text from './Text';

const GetDirections = ({ latitude, longitude }) => (
  <a
    href={`https://www.google.com/maps?q=${latitude},${longitude}&z=17&hl=en`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Text>Get Directions</Text>
  </a>
);

export default GetDirections;
