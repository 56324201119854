import React, { useEffect, useState } from 'react';
import { Table } from 'rbx';
import { filter, first, isUndefined } from 'lodash';
import moment from 'moment';
import useFetchUser from '../../../utils/useFetchUser';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      rating
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const UserList = ({ setSocialCount, search_social, setUser, users }) => {
  useEffect(() => {
    // Total Count
    const { social_aggregation } = search_social;
    const triedCountArray =
      social_aggregation.total_social_count &&
      social_aggregation.total_social_count.length !== 0
        ? filter(
            social_aggregation.total_social_count,
            item => item.type === 'TRIED',
          )
        : [];
    const loveCountArray =
      social_aggregation.total_social_count &&
      social_aggregation.total_social_count.length !== 0
        ? filter(
            social_aggregation.total_social_count,
            item => item.type === 'LOVE_IT',
          )
        : [];
    const bookmarkCountArray =
      social_aggregation.total_social_count &&
      social_aggregation.total_social_count.length !== 0
        ? filter(
            social_aggregation.total_social_count,
            item => item.type === 'BOOKMARK',
          )
        : [];
    const shareCountArray =
      social_aggregation.total_social_count &&
      social_aggregation.total_social_count.length !== 0
        ? filter(
            social_aggregation.total_social_count,
            item => item.type === 'SHARE',
          )
        : [];
    const triedCount =
      triedCountArray.length !== 0 ? first(triedCountArray).count : 0;
    const loveCount =
      loveCountArray.length !== 0 ? first(loveCountArray).count : 0;
    const bookmarkCount =
      bookmarkCountArray.length !== 0 ? first(bookmarkCountArray).count : 0;
    const shareCount =
      shareCountArray.length !== 0 ? first(shareCountArray).count : 0;
    setSocialCount({
      tried: triedCount,
      love: loveCount,
      bookmark: bookmarkCount,
      share: shareCount,
    });

    // User Count and Details
    const { total_social_per_object_id } = social_aggregation;
    if (total_social_per_object_id.length !== 0) {
      const totalSocial = first(total_social_per_object_id);
      if (totalSocial.length !== 0) {
        const triedUserArray = filter(
          totalSocial.total_social_count,
          item => item.type === 'TRIED',
        );

        const loveUserArray = filter(
          totalSocial.total_social_count,
          item => item.type === 'LOVE_IT',
        );
        const bookMarkUserArray = filter(
          totalSocial.total_social_count,
          item => item.type === 'BOOKMARK',
        );
        const shareUserArray = filter(
          totalSocial.total_social_count,
          item => item.type === 'SHARE',
        );
        const triedUser =
          triedUserArray.length !== 0 ? first(triedUserArray).user : [];
        const loveUser =
          loveUserArray.length !== 0 ? first(loveUserArray).user : [];
        const bookMarkUser =
          bookMarkUserArray.length !== 0 ? first(bookMarkUserArray).user : [];
        const shareUser =
          shareUserArray.length !== 0 ? first(shareUserArray).user : [];
        setUser({
          tried: triedUser,
          love: loveUser,
          bookmark: bookMarkUser,
          share: shareUser,
        });
      }
    }
  }, []);

  return (
    <Table.Body>
      {users && users.map(user => <User userData={user} />)}
    </Table.Body>
  );
};

const User = ({ userData }) => {
  const userId = userData.user_id;
  const [user, setUser] = useState();
  useEffect(() => {
    client.clientPrivate
      .query({
        query: userQuery,
        variables: { userId },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setUser(data.fetch_user);
        }
      });
  }, []);
  return (
    <Table.Row>
      {isUndefined(user && user.display_name) ? (
        <Table.Cell> </Table.Cell>
      ) : (
        <Table.Cell>{`${user && user.display_name} `} </Table.Cell>
      )}
      <Table.Cell>{user && user.email} </Table.Cell>
      <Table.Cell> - </Table.Cell>
      <Table.Cell>{moment(userData.time).format('lll')} </Table.Cell>
    </Table.Row>
  );
};

export default UserList;
