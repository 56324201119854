import React, { useEffect } from 'react';
import { Table } from 'rbx';
import { filter, first } from 'lodash';
import moment from 'moment';
import useFetchUser from '../../../utils/useFetchUser';

const UserList = ({ setSocialCount, search_social, setUser, users }) => {
  useEffect(() => {
    // Total Count
    const { social_aggregation } = search_social;
    const beenThereCountArray =
      social_aggregation.total_social_count &&
      social_aggregation.total_social_count.length !== 0
        ? filter(
            social_aggregation.total_social_count,
            item => item.type === 'BEEN_THERE',
          )
        : [];
    const likeItCountArray =
      social_aggregation.total_social_count &&
      social_aggregation.total_social_count.length !== 0
        ? filter(
            social_aggregation.total_social_count,
            item => item.type === 'LIKE_IT',
          )
        : [];
    const bookmarkCountArray =
      social_aggregation.total_social_count &&
      social_aggregation.total_social_count.length !== 0
        ? filter(
            social_aggregation.total_social_count,
            item => item.type === 'BOOKMARK',
          )
        : [];
    const shareCountArray =
      social_aggregation.total_social_count &&
      social_aggregation.total_social_count.length !== 0
        ? filter(
            social_aggregation.total_social_count,
            item => item.type === 'SHARE',
          )
        : [];
    const beenThereCount =
      beenThereCountArray.length !== 0 ? first(beenThereCountArray).count : 0;
    const likeItCount =
      likeItCountArray.length !== 0 ? first(likeItCountArray).count : 0;
    const bookmarkCount =
      bookmarkCountArray.length !== 0 ? first(bookmarkCountArray).count : 0;
    const shareCount =
      shareCountArray.length !== 0 ? first(shareCountArray).count : 0;
    setSocialCount({
      like_it: likeItCount,
      bookmark: bookmarkCount,
      share: shareCount,
      beenThere: beenThereCount,
    });

    // User Count and Details
    const { total_social_per_object_id } = social_aggregation;
    if (total_social_per_object_id.length !== 0) {
      const totalSocial = first(total_social_per_object_id);
      if (totalSocial.length !== 0) {
        const beenThereUserArray = filter(
          totalSocial.total_social_count,
          item => item.type === 'BEEN_THERE',
        );
        const likeUserArray = filter(
          totalSocial.total_social_count,
          item => item.type === 'LIKE_IT',
        );
        const bookMarkUserArray = filter(
          totalSocial.total_social_count,
          item => item.type === 'BOOKMARK',
        );
        const shareUserArray = filter(
          totalSocial.total_social_count,
          item => item.type === 'SHARE',
        );
        const beenThereUser =
          beenThereUserArray.length !== 0 ? first(beenThereUserArray).user : [];
        const likeUser =
          likeUserArray.length !== 0 ? first(likeUserArray).user : [];
        const bookMarkUser =
          bookMarkUserArray.length !== 0 ? first(bookMarkUserArray).user : [];
        const shareUser =
          shareUserArray.length !== 0 ? first(shareUserArray).user : [];
        setUser({
          beenThere: beenThereUser,
          like_it: likeUser,
          bookmark: bookMarkUser,
          share: shareUser,
        });
      }
    }
  }, []);

  return (
    <Table.Body>
      {users && users.map(user => <User userData={user} />)}
    </Table.Body>
  );
};

const User = ({ userData }) => {
  const userId = userData.user_id;
  const { user } = useFetchUser({
    userId,
    caption: true,
  });

  return (
    <Table.Row>
      <Table.Cell>{user && user.caption3} </Table.Cell>
      <Table.Cell>{user && user.caption1} </Table.Cell>
      <Table.Cell> - </Table.Cell>
      <Table.Cell>{moment(userData.time).format('lll')} </Table.Cell>
    </Table.Row>
  );
};

export default UserList;
