import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import Layout from '../../../components/global/Layout';
import { ErrorMessage, Loading } from '../../../components/elements';
import Form from './Form';
import client from '../../../utils/apolloClient';

const serviceQuery = gql`
  query service($input: NavigateServiceTypeInput) {
    fetch_service_type(input: $input) {
      service_type_id
      name
      status
      description
      error {
        description
      }
    }
  }
`;

const updateServiceMutation = gql`
  mutation updateService($input: ServiceTypeInput) {
    update_service_type(input: $input) {
      service_type_id
      name
      error {
        description
      }
    }
  }
`;

const ServiceUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const { serviceId } = match.params;
  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={serviceQuery}
        variables={{
          input: {
            service_type_id: serviceId,
          },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const service = data.fetch_service_type;
          return (
            <Mutation
              client={client.clientPrivate}
              mutation={updateServiceMutation}
              onCompleted={({ update_service_type }) => {
                if (!isNull(update_service_type.error)) {
                  update_service_type.error.map(item =>
                    toast.error(item.description),
                  );
                } else {
                  swal(
                    'Good job!',
                    'Service type updated successfully!',
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }
              }}
            >
              {(update_service_type, { loading: loading2, error: error1 }) => (
                <>
                  {error1 && <ErrorMessage message={error1.message} />}
                  <Form
                    service={service}
                    loading={loading2}
                    onSubmit={values => {
                      const inputs = {
                        ...values,
                      };

                      Object.assign(
                        inputs,
                        !isEqual(values.name, service.name) && {
                          name: values.name,
                        },
                        !isEqual(values.description, service.description) && {
                          description: values.description,
                        },
                        !isEqual(values.status, service.status) && {
                          status: values.status,
                        },
                      );
                      update_service_type({
                        variables: {
                          input: {
                            user_id: userId,
                            ...inputs,
                          },
                        },
                      });
                    }}
                  />
                </>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default ServiceUpdate;
