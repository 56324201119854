import React from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import Table from './Table';

const Container = styled.div`
  padding: 1rem;
`;

const QRCode = ({ menuName, placeName, qrCodeValue }) => {
  const [siteLabel, setSiteLabel] = React.useState('Table');
  const [count, setCount] = React.useState(null);

  return (
    <Container className="columns">
      <div className="column is-4">
        <p>Menu QR Code</p>
        <Menu
          menuName={menuName}
          qrCodeValue={qrCodeValue}
          siteLabel={siteLabel}
          count={count}
        />
      </div>
      <div className="column">
        <p>Table Menu QR Code</p>
        <Table
          placeName={placeName}
          qrCodeValue={qrCodeValue}
          menuName={menuName}
          siteLabel={siteLabel}
          count={count}
          setSiteLabel={setSiteLabel}
          setCount={setCount}
        />
      </div>
    </Container>
  );
};

export default QRCode;
