import { AWSAppSyncClient, AUTH_TYPE } from 'aws-appsync';
import config from './config';

const client = new AWSAppSyncClient({
  url: config.secured.aws_appsync_graphqlEndpoint,
  region: config.secured.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: () => window.localStorage.getItem('token'),
  },
});

export default client;
