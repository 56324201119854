import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  .map {
    height: 100%;
  }
`;

function Test() {
  const link='';
}

export default Test;
