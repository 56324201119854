import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import VendorList from './VendorList';
import Loading from '../../../components/elements/Loading';

const vendorsQuery = gql`
  query searchVendors($input: SearchInput) {
    search_vendors(input: $input) {
      vendor_id
      status
      approval_status
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
    }
  }
`;

const Vendors = () => (
  <Layout>
    <div className="container">
      <Header />
      <hr />
      <Query
        query={vendorsQuery}
        variables={{ input: {} }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }

          const { search_vendors } = data;

          return (
            <React.Fragment>
              <VendorList Vendors={search_vendors} />
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  </Layout>
);

export default Vendors;
