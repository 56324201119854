import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  flag,
  placeName,
  action,
  loading,
  heading,
  subHeading,
  handleApproveAction,
  handleRejectAction,
  history,
  status,
  location,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>{heading}</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/flagged-review'
                  ? 'action_flag'
                  : 'action_flag'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  {status !== 'REJECTED' && (
                    <Level.Item>
                      <button
                        className={`button is-danger ${action === 'REJECTED' &&
                          loading &&
                          'is-loading'}`}
                        onClick={handleRejectAction}
                        disabled={flag.status === 'REJECTED'}
                      >
                        <span>Reject</span>
                      </button>
                    </Level.Item>
                  )}
                </>
              )}
            />
          )}
        </ACLSConsumer>

        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/flagged-review'
                  ? 'action_flag'
                  : 'action_flag'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  {status !== 'APPROVED' && (
                    <Level.Item>
                      <button
                        className={`button is-primary ${action === 'APPROVED' &&
                          loading &&
                          'is-loading'}`}
                        onClick={() => handleApproveAction()}
                        disabled={flag.status === 'APPROVED'}
                      >
                        <span>Unpublish and Inactivate</span>
                      </button>
                    </Level.Item>
                  )}
                </>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
    <SubHeading className="is-size-2">{placeName}</SubHeading>
    <SubHeading className="is-size-2">{subHeading}</SubHeading>
  </Wrapper>
);

export default withRouter(Header);
// action_flag
