import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import {
  Input,
  ReactDateTimePicker,
  CheckBox,
} from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = ({
  placeName,
  city,
  startDate,
  endDate,
  searchByBookingNumber,
  bookingNumber,
  handleChange,
}) => {
  const setDateHours = dateObject => {
    dateObject.setHours(0, 0, 0, 0);
    return dateObject;
  };

  const startDateISOString = dateObject =>
    setDateHours(dateObject).toISOString();

  return (
    <Wrapper>
      <CheckBox
        label="Search by Booking Number"
        name="search_by_table_no"
        value={searchByBookingNumber}
        onChange={value => {
          handleChange({
            searchByBookingNumber: value,
            bookingNumberFilter: '',
            placeFilter: '',
            cityFilter: '',
          });
        }}
      />

      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeName}
              placeholder="Start entering place name..."
              onChange={event => {
                handleChange({
                  placeFilter: event.target.value,
                });
              }}
            />
          </div>

          <div className="column is-3">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={value => {
                    handleChange({
                      cityFilter: value.name,
                    });
                  }}
                  onHandleClear={() =>
                    handleChange({
                      cityFilter: '',
                    })
                  }
                  placeholder="Start entering company name..."
                />
              </div>
            </div>
          </div>
          {searchByBookingNumber ? (
            <div className="column is-4">
              <Input
                label="Booking Number"
                type="text"
                value={bookingNumber}
                placeholder="Start entering an Booking Number..."
                onChange={e => {
                  handleChange({
                    bookingNumberFilter: e.target.value,
                  });
                }}
              />
            </div>
          ) : (
            <>
              <div className="column">
                <ReactDateTimePicker
                  value={startDate || startDateISOString(new Date())}
                  // maxDate={moment(endDate || moment().toISOString()).toDate()}
                  label="From"
                  onChange={date => {
                    const a = moment(startDateISOString(new Date(date)));
                    const b = moment(endDate || moment().toISOString());
                    const daysDiff = b.diff(a, 'days');
                    if (daysDiff <= 0 || daysDiff > 45) {
                      handleChange({
                        startDateFilter: startDateISOString(new Date(date)),
                        endDateFilter: moment(date)
                          .set({
                            hour: 23,
                            minute: 59,
                            second: 0,
                            millisecond: 0,
                          })
                          .toISOString(),
                      });
                    } else {
                      handleChange({
                        startDateFilter: startDateISOString(new Date(date)),
                      });
                    }
                  }}
                  clear={false}
                />
              </div>
              <div className="column">
                <ReactDateTimePicker
                  minDate={moment(
                    startDate || startDateISOString(new Date()),
                  ).toDate()}
                  maxDate={moment(startDate || startDateISOString(new Date()))
                    .add(45, 'days')
                    .toDate()}
                  value={endDate || moment().toISOString()}
                  label="To"
                  onChange={date => {
                    handleChange({
                      endDateFilter: moment(date)
                        .set({
                          hour: 23,
                          minute: 59,
                          second: 0,
                          millisecond: 0,
                        })
                        .toISOString(),
                    });
                  }}
                  clear={false}
                />
              </div>
            </>
          )}
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
