import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import PhotoApprovals from './PhotoApprovals';
import PhotoFlags from './PhotoFlags';
import PhotoShares from './PhotoShares';
import PhotoLikes from './PhotoLikes';
import SocialCounts from './SocialCounts';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;
const tabs = [
  {
    key: 0,
    name: 'Photo Details',
  },
  {
    key: 1,
    name: 'Photo Likes',
  },
  {
    key: 2,
    name: 'Photo Shares',
  },
  {
    key: 3,
    name: 'Photo Flags',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const prev = () =>
    activeTab === 0 ? setActiveTab(3) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 3 ? setActiveTab(0) : setActiveTab(activeTab + 1);
  const { place, items, review } = props;

  return (
    <React.Fragment>
      <Header
        heading="Item Photo Approvals"
        prev={prev}
        next={next}
        activeTab={activeTab}
        itemName={items.name}
        action={props.action}
        loading={props.loading}
        onSubmit={value => {
          props.handleAction(value);
          props.handleSubmit();
        }}
        review={review}
        approvalStatus={review.approval_status}
        // eslint-disable-next-line max-len
        subHeading={`${place.name} | ${place.city}, ${place.post_code} | ${place.country} ( ${place.status}  / ${place.claim_status} ) `}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Container>
          <SocialCounts {...props} />

          {activeTab === 0 && <PhotoApprovals {...props} />}
          {activeTab === 1 && <PhotoLikes {...props} />}
        </Container>
        {activeTab === 2 && <PhotoShares />}
        {activeTab === 3 && <PhotoFlags {...props} />}
      </Box>
      <hr />
      <Footer
        approvalStatus={review.approval_status}
        action={props.action}
        loading={props.loading}
        onSubmit={value => {
          props.handleAction(value);
          props.handleSubmit();
        }}
        prev={prev}
        next={next}
        activeTab={activeTab}
      />
    </React.Fragment>
  );
};

const ItemApprovalForm = withFormik({
  mapPropsToValues: ({ images }) => ({
    allImages: images.length !== 0 ? images : [],
    rejectImages: images.length !== 0 ? images : [],
    note: '',
  }),

  validationSchema: yup.object().shape({
    note: yup.string().required('Note is Required!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'ItemApprovalForm',
})(Form);

export default ItemApprovalForm;
