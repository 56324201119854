import React from 'react';
import { Level } from 'rbx';
import styled from 'styled-components';

import { Heading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({
  history = { goBack: () => {} },
  loading,
  handleSubmit = () => {},
  showSubmit,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Mobile Manual Push Notifications</Heading>
      </Level.Item>
      <Level.Item
        align="right"
        style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
      >
        <Level.Item style={{ padding: 0, marginTop: 0, marginBottom: 0 }}>
          <a onClick={() => history?.goBack()} className="button is-grey">
            <span className="has-text-weight-semibold">Cancel</span>
          </a>
        </Level.Item>
        {showSubmit && (
          <Level.Item style={{ padding: 0, marginTop: 0, marginBottom: 0 }}>
            <button
              type="button"
              className={`button is-primary ${loading &&
                'is-loading'} menu-designer-submit-button`}
              // disabled={!dirty}
              onClick={!loading ? handleSubmit : () => {}}
            >
              <span className="has-text-weight-semibold">Submit</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Header;
