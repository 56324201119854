import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({
  loading,
  prev,
  next,
  status,
  handleApprovedAction,
  handleRejectAction,
  handleUpdateReview,
  updateReviewLoading,
  history,
  activeTab,
  action,
  location,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-light">
              <span>Cancel</span>
            </a>
          </Level.Item>
          {activeTab !== 0 && (
            <Level.Item>
              <button onClick={prev} className="button is-primary">
                <span>Prev Tab</span>
              </button>
            </Level.Item>
          )}
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/place-review'
                  ? 'action_place_review'
                  : 'action_place_review'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  {status !== 'REJECTED' && (
                    <Level.Item>
                      <button
                        className={`button is-danger ${action === 'REJECTED' &&
                          loading &&
                          'is-loading'}`}
                        onClick={handleRejectAction}
                        disabled={status === 'REJECTED'}
                      >
                        <span>Reject</span>
                      </button>
                    </Level.Item>
                  )}
                </>
              )}
            />
          )}
        </ACLSConsumer>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/place-review'
                  ? 'action_place_review'
                  : 'action_place_review'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  {status !== 'APPROVED' && (
                    <Level.Item>
                      <button
                        className={`button is-primary ${action === 'APPROVED' &&
                          loading &&
                          'is-loading'}`}
                        onClick={handleApprovedAction}
                        disabled={status === 'APPROVED'}
                      >
                        <span>Approve and Publish</span>
                      </button>
                    </Level.Item>
                  )}
                </>
              )}
            />
          )}
        </ACLSConsumer>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/place-review'
                  ? 'update_place_review'
                  : 'update_place_review'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  <Level.Item>
                    <button
                      className={`button is-primary ${updateReviewLoading &&
                        'is-loading'}`}
                      onClick={handleUpdateReview}
                    >
                      <span>Update Review</span>
                    </button>
                  </Level.Item>
                </>
              )}
            />
          )}
        </ACLSConsumer>
        {activeTab !== 4 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next Tab</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
