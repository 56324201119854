import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Column } from 'rbx';
import moment from 'moment';
import { isNull } from 'lodash';
import { Loading, Message, Input } from '../../../components/elements';
import ReviewPhotos from './ReviewPhotos';
import client from '../../../utils/apolloClient';
import ImageUserCounts from './ImageUserCounts';

const fetchImage = gql`
  query fetchImage($input: NavigateImageInput) {
    fetch_image(input: $input) {
      image_id
      object_id
      parent_id
      parent_type
      object_type
      tags
      status
      url
      audit {
        created_at
        created_by
      }
    }
  }
`;

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      rating
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const Container = styled.div`
  padding: 1rem 1rem;
`;

const ImageReview = ({ flag }) => (
  <Container>
    <Query
      client={client.clientPrivate}
      query={fetchImage}
      variables={{ input: { image_id: flag.object_id } }}
    >
      {({ data: ImageData, loading: imageLoading, error: imageError }) => {
        if (imageLoading) {
          return <Loading />;
        }
        if (imageError) {
          return <Message>{imageError.message}</Message>;
        }
        const { fetch_image } = ImageData;

        const userId = fetch_image.audit.created_by;

        return (
          <Query
            client={client.clientPrivate}
            query={userQuery}
            variables={{ userId }}
            fetchPolicy="network-only"
          >
            {({ data: userData, loading: userLoading, error: userError }) => {
              if (userLoading) {
                return <Loading />;
              }
              if (userError) {
                return <Message>{userError.message}</Message>;
              }
              const { fetch_user } = userData;
              const user = fetch_user;

              return (
                <fieldset className="box">
                  <legend className="label">Image Uploader Details</legend>
                  <Column.Group multiline vcentered gapSize={8}>
                    <Column size={6}>
                      <Input
                        label="Uploaded by"
                        value={`${user && user.first_name} ${user &&
                          user.last_name}`}
                        disabled
                      />
                    </Column>
                    <Column size={6}>
                      <Input
                        label="Display Name"
                        value={user && user.display_name}
                        disabled
                      />
                    </Column>
                    <Column size={6}>
                      <Input
                        label="Uploader E-mail"
                        value={user && user.email}
                        disabled
                      />
                    </Column>
                    <Column size={6}>
                      <Input
                        label="Uploaded On"
                        value={moment(fetch_image.audit.created_at).format(
                          'lll',
                        )}
                        disabled
                      />
                    </Column>
                    <Column size={3}>
                      <Input
                        label="User Rating"
                        value={
                          isNull(user && user.rating) ? 0 : user && user.rating
                        }
                        disabled
                      />
                    </Column>
                    <ImageUserCounts image={fetch_image} />
                  </Column.Group>

                  <ReviewPhotos image={fetch_image} />
                </fieldset>
              );
            }}
          </Query>
        );
      }}
    </Query>
  </Container>
);
export default withRouter(ImageReview);
