import React from 'react';
import styled from 'styled-components';

import { Input } from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = () => (
  <Wrapper>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-4">
          <Input label="Date" />
        </div>
        <div className="column is-4">
          <Input
            label="Company Name"
            type="text"
            placeholder="Start entering company name..."
          />
        </div>

        <div className="column is-4">
          <div className="field">
            <Input label="City / Suburb" />
          </div>
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default Search;
