import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { filter, sortBy, capitalize } from 'lodash';
import { useStoreState } from 'easy-peasy';
import useSound from 'use-sound';
import swal from 'sweetalert';

import ReadyCard from './ReadyCard';
import { getReceivedOrders, handleOrderAction } from './helpers';
import { Loading } from '../../../components/elements';
import OrderDetails from './OrderDetails';
import CompletedOrders from './CompletedOrders';
import sound from '../../../assets/sound/sms-alert-5-daniel_simon.wav';

import {
  scheduleTimeText,
  scheduleCompletionTimeText,
  totalTimeDelay,
  totalTimeDueActiveCard2,
  totalDueTimeInMinsScheduleOrder,
} from './timeHelpers';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const OrderButton = styled.button`
  &&& {
    font-weight: 600;
  }
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
  }
`;

const newOrderPopupContent = ({
  service_type,
  order_reference,
  overall_quantity,
  order_total,
}) => {
  if (window && window.document) {
    const outerDiv = document.createElement('div');
    outerDiv.style.display = 'flex';
    outerDiv.style.justifyContent = 'center';
    outerDiv.style.alignItems = 'center';

    const innerDiv = document.createElement('div');
    innerDiv.style.display = 'flex';
    innerDiv.style.justifyContent = 'space-between';

    const leftDiv = document.createElement('div');
    leftDiv.style.textAlign = 'left';

    const serviceTypeSpan = document.createElement('span');
    serviceTypeSpan.style.fontWeight = '700';
    serviceTypeSpan.style.fontSize = '16px';
    serviceTypeSpan.textContent = service_type;

    const lineBreak = document.createElement('br');

    const orderDetailsSpan = document.createElement('span');
    orderDetailsSpan.textContent = `${order_reference}, ${overall_quantity} item(s)`;

    leftDiv.appendChild(serviceTypeSpan);
    leftDiv.appendChild(lineBreak);
    leftDiv.appendChild(orderDetailsSpan);

    const orderTotalSpan = document.createElement('span');
    orderTotalSpan.style.color = '#EE2A7B';
    orderTotalSpan.textContent = `$${order_total}`;

    innerDiv.appendChild(leftDiv);
    innerDiv.appendChild(orderTotalSpan);

    outerDiv.appendChild(innerDiv);
    return outerDiv;
  }
  return null;
};

const Ready = ({
  place,
  orderCount,
  setCurrentSubscribeToMenuOrderMoveToActive,
  currentSubscribeToMenuOrderMoveToActive,
  isActiveTab,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [readyOrders, setReadyOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const [currentOrder, setCurrentOrder] = useState(null);
  const [
    isActiveOrderDetailsModalReadyTab,
    setIsActiveOrderDetailsModalReadyTab,
  ] = useState(false);

  const [
    isActiveOrderDetailsModalCompletedTab,
    setIsActiveOrderDetailsModalCompletedTab,
  ] = useState(false);

  const { userId } = useStoreState(state => state.auth);

  const [play] = useSound(sound);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getReceivedOrders({
        placeId: place.place_id,
        status: 'READY',
        userId,
        size: 30,
      });
      setIsLoading(false);
      setReadyOrders(data);
    };
    fetchData();
  }, []);

  const handleCompleteOrder = async value => {
    setCompleteLoading(true);
    setCurrentOrderId(value.menu_order_id);
    const data = await handleOrderAction({
      menuId: value.menu_order_id,
      userId: value && value.audit.created_by,
      action: 'done',
    });
    setCompleteLoading(false);
    const newReadyOrders = filter(
      readyOrders,
      item => item.menu_order_id !== value.menu_order_id,
    );
    setReadyOrders(newReadyOrders);
    const newCompletedOrders = [data];
    setCompletedOrders(newCompletedOrders.concat(completedOrders));
  };

  const addCompleteOrderAfterReady = updateOrder => {
    const newCompletedOrders = [updateOrder];
    setCompletedOrders(newCompletedOrders.concat(completedOrders));
  };

  const handleAfterDelayAndAdjustOrder = updateOrder => {
    const newReadyOrders = readyOrders.filter(
      item => item.menu_order_id !== updateOrder.menu_order_id,
    );
    setReadyOrders(
      sortBy(newReadyOrders.concat(updateOrder), 'audit.created_at'),
    );
  };

  const notify2 = subscriptionData => {
    const isConfirmed = subscriptionData.status === 'CONFIRMED';

    const {
      service_type,
      overall_quantity,
      order_reference,
      billing,
    } = subscriptionData;
    const { order_total } = billing ?? {};

    if (isConfirmed) {
      setCurrentOrder(null);
      setIsActiveOrderDetailsModalReadyTab(false);
      setIsActiveOrderDetailsModalCompletedTab(false);
      play();
      swal({
        title: 'Prepare Scheduled Order!',
        text: `A scheduled order is due in ${totalDueTimeInMinsScheduleOrder(
          subscriptionData.estimates,
          subscriptionData?.received?.created_at,
        )}`,
        buttons: ['OK', 'View Detail'],
        content: newOrderPopupContent({
          service_type,
          order_reference,
          overall_quantity,
          order_total,
        }),
        closeOnClickOutside: false,
      }).then(onfulfilled => {
        if (onfulfilled) {
          setCurrentOrder(subscriptionData);
          setIsActiveOrderDetailsModalReadyTab(true);
        }
        setCurrentSubscribeToMenuOrderMoveToActive();
      });
    }
  };

  useEffect(() => {
    if (isActiveTab === 'ready' && currentSubscribeToMenuOrderMoveToActive) {
      notify2(currentSubscribeToMenuOrderMoveToActive);
    }
  }, [currentSubscribeToMenuOrderMoveToActive, isActiveTab]);

  return (
    <>
      {isLoading && <Loading />}
      <Container>
        {readyOrders.length !== 0 &&
          readyOrders.map(item => {
            const showReadyStatus = () => {
              if (item.service_type === 'Pickup') {
                return 'Order Picked Up';
              }
              if (item.service_type === 'Dinein') {
                return 'Order Served';
              }
              if (item.service_type === 'Delivery') {
                return 'Order Delivered';
              }
              return null;
            };

            const receivedStatusHistory = item.received;

            const receivedTime = receivedStatusHistory
              ? receivedStatusHistory.created_at
              : null;
            const title = () => (
              <>
                {item.service_type === 'Delivery' && 'Delivery'}
                {item.service_type === 'Dinein' && item.table_number
                  ? `${item.table_name ?? 'Table'} ${item.table_number}`
                  : item.service_type === 'Dinein' && 'Dinein'}
                {item.service_type === 'Pickup' && 'Pickup'}
                {item.large_order && ` (LARGE ORDER)`}
              </>
            );
            return (
              <ReadyCard
                title={title()}
                schedule={scheduleTimeText(item.estimated_completion_time)}
                scheduleWithDelay={scheduleCompletionTimeText(
                  item.estimates,
                  receivedTime,
                )}
                ready={totalTimeDelay(item.estimates, receivedTime)}
                key={item.menu_order_id}
              >
                <div className="columns">
                  <div className="column is-4">
                    <div>
                      <p className="is-size-4 has-text-weight-semibold">
                        {item?.order_reference} | {item?.overall_quantity} items
                        |{item?.billing?.order_total?.toFixed(2)}
                      </p>
                      <p className="is-size-4" style={{ paddingTop: '8px' }}>
                        {item.contact && item.contact.contact_name}
                        {item.service_type === 'Delivery' &&
                          item.address &&
                          ` ( ${capitalize(item.address.city)} )`}
                        {item.service_type === 'Dinein' &&
                          item.no_of_attendees &&
                          ` (no of attendees : ${item.no_of_attendees})`}
                      </p>
                      {item.service_type === 'Pickup' && item.address && (
                        <p
                          className="is-size-4"
                          style={{ paddingTop: '8px', color: '#00AEEF' }}
                        >
                          {item.address.city}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="column">
                    <DetailsButton
                      type="button"
                      className="button is-large is-fullwidth control"
                      onClick={() => {
                        setIsActiveOrderDetailsModalReadyTab(true);
                        setCurrentOrder(item);
                      }}
                    >
                      Details
                    </DetailsButton>
                  </div>
                  <div className="column">
                    <OrderButton
                      type="button"
                      className={`button is-large is-primary control is-fullwidth ${completeLoading &&
                        item.menu_order_id === currentOrderId &&
                        'is-loading'}`}
                      onClick={() => handleCompleteOrder(item)}
                    >
                      {showReadyStatus()}
                    </OrderButton>
                  </div>
                </div>
              </ReadyCard>
            );
          })}
      </Container>
      {!isLoading && readyOrders.length === 0 && (
        <Container>
          <div
            className="is-flex has-text-weight-semibold"
            style={{ justifyContent: 'center' }}
          >
            <p>No ready orders yet!</p>
          </div>
          <div className="is-flex" style={{ justifyContent: 'center' }}>
            <p>
              To view active orders, choose the &apos;ACTIVE&apos; tab or to the
              review the previous orders , go to Orders history
            </p>
          </div>
        </Container>
      )}
      <br />
      <CompletedOrders
        place={place}
        currentReadyOrder={currentOrder}
        setCompletedOrders={setCompletedOrders}
        completedOrders={completedOrders}
        orderCount={orderCount}
        isActiveOrderDetailsModal={isActiveOrderDetailsModalCompletedTab}
        setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModalCompletedTab}
      />
      {currentOrder && isActiveOrderDetailsModalReadyTab && (
        <OrderDetails
          dueTimeToBeDisplayedInOrderDetailsModal={totalTimeDueActiveCard2(
            currentOrder.estimates,
            currentOrder?.received?.created_at,
          )}
          delayTimeToBeDisplayedInOrderDetailsModal={totalTimeDelay(
            currentOrder.estimates,
            currentOrder?.received?.created_at,
          )}
          isActiveOrderDetailsModal={isActiveOrderDetailsModalReadyTab}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModalReadyTab}
          order={currentOrder}
          setReadyOrders={setReadyOrders}
          readyOrders={readyOrders}
          addCompleteOrderAfterReady={addCompleteOrderAfterReady}
          handleAfterDelayOrder={handleAfterDelayAndAdjustOrder}
          handleAfterAdjustment={handleAfterDelayAndAdjustOrder}
          orderComponent="Orders"
        />
      )}
    </>
  );
};

export default Ready;
