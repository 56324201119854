import React from 'react';
// import styled from 'styled-components';
import { Level } from 'rbx';
import { Radio } from '../../../components/elements';

const Search = ({ filterType, setFilterType }) => (
  <Level>
    <Level.Item align="left">
      <Level.Item>
        <Radio
          label="Event Like Activity"
          value={filterType === 'like'}
          onChange={value => {
            if (value) {
              setFilterType('like');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Event Share Activity"
          value={filterType === 'share'}
          onChange={value => {
            if (value) {
              setFilterType('share');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Event Bookmark Activity"
          value={filterType === 'bookmark'}
          onChange={value => {
            if (value) {
              setFilterType('bookmark');
            }
          }}
        />
      </Level.Item>
    </Level.Item>
  </Level>
);

export default Search;
