import React, { useState, useEffect } from 'react';
import { Column } from 'rbx';
import gql from 'graphql-tag';
import { isUndefined } from 'lodash';

import { Input, MobileInput } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      rating
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const ListingAudit = ({ values, approvedBy, approvedAt }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    client.clientPrivate
      .query({
        query: userQuery,
        variables: { userId: approvedBy },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setUser(data.fetch_user);
        }
      });
  }, []);
  return (
    <fieldset className="box">
      <legend className="label">Listing Audit </legend>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input
            label="Contact Name"
            value={values.listing_audit.listing_contact_name}
            disabled
          />
        </Column>
        <Column size="half">
          <MobileInput
            label="Contact Phone"
            value={values.listing_audit.listing_contact_phone}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Contact Email"
            value={values.listing_audit.listing_contact_email}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Contact Role"
            value={values.listing_audit.listing_contact_role}
            disabled
          />
        </Column>
        {isUndefined(user) || (user && user.user_id === null) ? (
          <Column size="half">
            <Input label="Approved By" disabled />
          </Column>
        ) : (
          <Column size="half">
            <Input
              label="Approved By"
              value={`${user && user.first_name} ${user && user.last_name}`}
              disabled
            />
          </Column>
        )}
        {isUndefined(user) || user.user_id === null ? (
          <Column size="half">
            <Input label="Approved On" disabled />
          </Column>
        ) : (
          <Column size="half">
            <Input label="Approved On" value={approvedAt} disabled />
          </Column>
        )}
      </Column.Group>
    </fieldset>
  );
};

export default ListingAudit;
