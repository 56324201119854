import React, { useState, useEffect } from 'react';
import { Tab } from 'rbx';
import styled from 'styled-components';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import { useStoreState } from 'easy-peasy';
import uuid from 'uuid';
import { Box, Tabs } from '../../../components/elements';
import { getOrders } from '../OrderDashboard/helpers';
import OrderList from './OrderList';
import Header from './Header';

const Container = styled.div``;

const MainForm = props => {
  const { userId } = useStoreState(state => state.auth);
  const [isActiveTab, setIsActiveTab] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [sort, setSort] = useState('CREATED_AT_DESC');
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const resetPaginationStates = () => {
    setTotalPages(0);
    setCurrentPage(1);
  };
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(uuid());

  const trifferRefetchFunc = () => setTriggerRefetch(uuid());

  const [startDate, setStartDate] = useState(
    moment()
      .startOf('day')
      .toISOString(),
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf('day')
      .toISOString(),
  );
  const [searchByOrderNumber, setSearchByOrderNumber] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');

  const { place } = props;

  const getOrderStatus = val => {
    let status;
    switch (val) {
      case 'RECEIVED':
        status = 'received';
        break;
      case 'CONFIRMED':
        status = 'confirmed';
        break;
      case 'READY':
        status = 'ready';
        break;
      case 'COMPLETE':
        status = 'complete';
        break;
      case 'CANCELLED':
        status = 'cancelled';
        break;
      default:
        status = 'received';
    }
    return status;
  };

  const fetchMoreOrders = async ({ serviceType }) => {
    setLoadingMoreData(true);
    const menu_order_filter = {
      status: ['COMPLETE', 'CANCELLED'],
      date_range: { start_date: startDate, end_date: endDate },
    };
    const place_filter = {
      place_id: place.place_id,
    };
    Object.assign(
      menu_order_filter,
      serviceType && { service_type: serviceType },
    );

    const data = await getOrders({
      sort,
      from: currentPage * 20,
      size: 20,
      user_id: userId,
      filter: {
        place_filter,
        menu_order_filter,
      },
    });
    let tempOrders = data.menu_order_listing
      ? JSON.parse(JSON.stringify(data.menu_order_listing))
      : [];
    tempOrders = tempOrders.map(el => {
      const status = getOrderStatus(el.status);
      return {
        ...el,
        created_at: el[status] !== null ? el[status].created_at : null,
      };
    });
    tempOrders.sort(
      (el1, el2) => moment(el1.created_at) - moment(el2.created_at),
    );
    setOrders(prev => [...prev, ...tempOrders]);
    setCurrentPage(prev => prev + 1);
    setLoadingMoreData(false);
  };

  const fetchData = async ({ serviceType }) => {
    setIsLoading(true);
    const menu_order_filter = {
      status: ['COMPLETE', 'CANCELLED'],
      date_range: { start_date: startDate, end_date: endDate },
    };
    const place_filter = {
      place_id: place.place_id,
    };
    Object.assign(
      menu_order_filter,
      serviceType && { service_type: serviceType },
    );

    const data = await getOrders({
      sort,
      from: 0,
      size: 20,
      user_id: userId,
      filter: {
        place_filter,
        menu_order_filter,
      },
    });
    setTotalPages(data.total_pages);
    let tempOrders = data.menu_order_listing
      ? JSON.parse(JSON.stringify(data.menu_order_listing))
      : [];
    tempOrders = tempOrders.map(el => {
      const status = getOrderStatus(el.status);
      return {
        ...el,
        created_at: el[status] !== null ? el[status].created_at : null,
      };
    });
    tempOrders.sort(
      (el1, el2) => moment(el1.created_at) - moment(el2.created_at),
    );
    setOrders(tempOrders);
    setIsLoading(false);
  };

  const fetchDataForSearchByOrderNumber = async () => {
    setIsLoading(true);
    const menu_order_filter = {
      order_reference: orderNumber,
    };
    const place_filter = {
      place_id: place.place_id,
    };
    Object.assign(menu_order_filter);

    const data = await getOrders({
      sort,
      from: 0,
      size: 20,
      user_id: userId,
      filter: {
        place_filter,
        menu_order_filter,
      },
    });
    setTotalPages(data.total_pages);
    let tempOrders = data.menu_order_listing
      ? JSON.parse(JSON.stringify(data.menu_order_listing))
      : [];
    tempOrders = tempOrders.map(el => {
      const status = getOrderStatus(el.status);
      return {
        ...el,
        created_at: el[status] !== null ? el[status].created_at : null,
      };
    });
    tempOrders.sort(
      (el1, el2) => moment(el1.created_at) - moment(el2.created_at),
    );
    setOrders(tempOrders);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isActiveTab === 'all') {
      fetchData({ serviceType: null });
    } else {
      fetchData({ serviceType: isActiveTab });
    }
  }, [startDate, endDate, sort, triggerRefetch]);

  useEffect(() => {
    if (searchByOrderNumber && orderNumber) {
      setOrders([]);
      fetchDataForSearchByOrderNumber();
    }
  }, [searchByOrderNumber, orderNumber]);

  const handleTab = value => {
    setOrders([]);
    setIsActiveTab(value);
    if (value === 'all') {
      fetchData({ serviceType: null });
    } else {
      fetchData({ serviceType: value });
    }
  };
  useEffect(() => {
    const lat = parseFloat(place.latitude).toFixed(5);
    const long = parseFloat(place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    moment.tz.setDefault(timeZone);
  }, []);
  return (
    <>
      <Header
        resetPaginationStates={resetPaginationStates}
        placeName={place.name}
        placeLocation={`${place.city}, ${place.post_code} | ${place.country} `}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        searchByOrderNumber={searchByOrderNumber}
        setSearchByOrderNumber={setSearchByOrderNumber}
        orderNumber={orderNumber}
        setOrderNumber={setOrderNumber}
        setOrders={setOrders}
        trifferRefetchFunc={trifferRefetchFunc}
      />
      <Box>
        {!searchByOrderNumber && (
          <Tabs className="tabs  is-fullwidth">
            <Tab
              active={isActiveTab === 'all'}
              onClick={() => handleTab('all')}
            >
              All
            </Tab>

            <Tab
              active={isActiveTab === 'Pickup'}
              onClick={() => handleTab('Pickup')}
            >
              Pickup
            </Tab>

            <Tab
              active={isActiveTab === 'Delivery'}
              onClick={() => handleTab('Delivery')}
            >
              Delivery
            </Tab>

            <Tab
              active={isActiveTab === 'Dinein'}
              onClick={() => handleTab('Dinein')}
            >
              Dinein
            </Tab>
          </Tabs>
        )}
        <Container>
          <OrderList
            orders={orders}
            isLoading={isLoading}
            sort={sort}
            setSort={setSort}
            fetchMoreOrders={() =>
              fetchMoreOrders({ serviceType: [isActiveTab] })
            }
            loadingMoreData={loadingMoreData}
            canLoadMoreData={currentPage < totalPages}
          />
        </Container>
      </Box>
    </>
  );
};

export default MainForm;
