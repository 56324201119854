import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Label } from 'rbx';

import { CheckBox, Loading } from '../../../components/elements';
import { getAllItemLink } from './helpers';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;
const IconWrapper = styled.div`
  padding-left: 4rem;
  span {
    font-size: 1.8rem;
  }
`;

const LabelWrapper = styled.div`
  position: relative;
  left: 2rem;
`;

const CheckBoxComp = ({
  menuSectionId,
  menuId,
  settingId,
  value,
  onChange,
}) => {
  const [items, setItems] = useState([]);
  const [itemLinkLoading, setItemLinkLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setItemLinkLoading(true);
      try {
        const linksItems = await getAllItemLink({
          menu_section_id: menuSectionId,
          menu_id: menuId,
          service_type_setting_id: settingId,
        });

        setItems(linksItems);
        setItemLinkLoading(false);
      } catch (error) {
        setItemLinkLoading(false);
      }
    };
    fetchData();
  }, []);
  console.log('items', items);
  const disabled = !value ? false : itemLinkLoading || items.length !== 0;
  return <CheckBox value={value} onChange={onChange} disabled={disabled} />;
};

const Menus = ({ values, setFieldValue, loading, setValues }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Flex>
        <Label>Menu</Label>
        <LabelWrapper>
          <Label>Assign Menu Category</Label>
        </LabelWrapper>
      </Flex>
      {values.menus.length === 0 && <p>No menus found in this place</p>}
      {values.menus.map(menu => (
        <Flex>
          <Input value={`${menu.service_type} - ${menu.name}`} disabled />
          <IconWrapper>
            <CheckBoxComp
              menuSectionId={values.menu_section_id}
              menuId={menu.menu_id}
              settingId={
                menu.service_type_setting &&
                menu.service_type_setting.service_type_setting_id
              }
              value={
                values.menu_id.filter(item => item === menu.menu_id).length !==
                0
              }
              onChange={value => {
                if (value) {
                  const newMenuIds = values.menu_id.concat(menu.menu_id);
                  setFieldValue('menu_id', newMenuIds);
                } else {
                  const newMenuIds = values.menu_id.filter(
                    item => item !== menu.menu_id,
                  );
                  setValues({ ...values, menu_id: newMenuIds });
                }
              }}
            />
          </IconWrapper>
        </Flex>
      ))}
    </>
  );
};

export default Menus;
