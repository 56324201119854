export const statusEnum = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

export const sellTypeEnum = [
  { value: 'ITEM', label: 'ITEM' },
  { value: 'MODIFIER', label: 'MODIFIER' },
  { value: 'DEAL', label: 'DEAL' },
];

export const sellTypeValue = {
  ITEM: 'ITEM',
  MODIFIER: 'MODIFIER',
  DEAL: 'DEAL',
};

export const approvalStatusEnum = [
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'UNAPPROVED', label: 'UNAPPROVED' },
  { value: 'SUBMITTED', label: 'SUBMITTED' },
];
