import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, sortBy, reverse } from 'lodash';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import { Loading, Pagination, Table, Box } from '../../../components/elements';

const itemQuery = gql`
  query searchItems($input: SearchInput) {
    search_items(input: $input) {
      total_pages
      total_size
      item_listing {
        place_id
        item_id
        name
        status
        place_name
        city
        cuisine_style {
          name
          description
          display_order
        }
      }
    }
  }
`;

const ItemReviews = () => {
  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeData, setPlaceData] = useState([]);
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [itemNameSort, setItemNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('PLACE_CITY_ASC');
  const [claimStatusSort, setClaimStatusSort] = useState('AES');
  // const [photoSort, setPhotoSort] = useState('AES');
  // const [reviewSort, setReviewSort] = useState('AES');
  // const [likeSort, setLikeSort] = useState('AES');
  // const [ratingSort, setRatingSort] = useState('AES');

  const [statusFilter, setStatusFilter] = useState('SUBMITTED');
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [itemNameSearchInput, setItemNameSearchInput] = useState('');
  const [location, setLocation] = useState('');

  const input = {};
  const item_filter = {};
  Object.assign(
    item_filter,
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    itemNameSearchInput !== '' && { name: itemNameSearchInput },
  );

  Object.assign(
    input,
    location !== '' && { location },
    { from: pageFrom, size: listSize },
    { sort },
    {
      filter: {
        item_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          itemNameSearchInput={itemNameSearchInput}
          setItemNameSearchInput={setItemNameSearchInput}
          location={location}
          setLocation={setLocation}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            currentPage={currentPage}
            totalElements={totalElements}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        const item =
                          itemNameSort === 'AES'
                            ? sortBy(placeData, 'name')
                            : reverse(placeData);
                        setPlaceData(item);
                        setItemNameSort(itemNameSort === 'AES' ? 'DES' : 'AES');
                      }}
                    >
                      <i
                        className={
                          itemNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Item Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setClaimStatusSort(
                          claimStatusSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          claimStatusSort === 'AES'
                            ? 'STATUS_DESC'
                            : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          claimStatusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Claim Status
                  </span>
                  <br />
                  {/* <CustomSelect
                    options={[
                      { value: '', label: 'Select..' },
                      { value: 'Unclaimed ', label: 'Unclaimed' },
                      { value: 'Claimed', label: 'Claimed' },
                    ]}
                  /> */}
                </div>
              </th>

              {/* <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Photos
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Reviews
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Like It
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Ratings
                  </span>
                </div>
              </th> */}
            </tr>
          </thead>
          <Query
            query={itemQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_items } = data;

              if (
                isNull(search_items) ||
                search_items.item_listing.length === 0
              ) {
                return 'NO RECORDS FOUND';
              }

              return (
                <React.Fragment>
                  <View
                    placeData={placeData}
                    setPlaceData={setPlaceData}
                    search_items={search_items}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_items,
  setPageCount,
  setTotalElements,
  placeData,
  setPlaceData,
}) => {
  useEffect(() => {
    setPageCount(search_items.total_pages);
    setTotalElements(search_items.total_size);
    if (search_items.item_listing) {
      setPlaceData(search_items.item_listing);
    }
  }, [search_items.total_pages]);

  return (
    <tbody>
      {placeData.map(review => (
        <ListItem review={review} />
      ))}
    </tbody>
  );
};

export default ItemReviews;
