import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
// import swal from 'sweetalert';
import { isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';
import Form from './Form';

const createMenuMutation = gql`
  mutation createMenu($input: MenuInput) {
    create_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const MenuTypeAdd = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const { defaultPlace } = useStoreState(state => state.menu);
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);

  const placeData = defaultPlace ?? place;
  const placeSelectionValue =
    defaultPlace !== null ? !!defaultPlace : placeSelection;

  return (
    <Layout>
      {placeSelectionValue ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createMenuMutation}
          onCompleted={({ create_menu }) => {
            if (!isNull(create_menu.error)) {
              create_menu.error.map(item => toast.error(item.description));
            } else {
              history.replace(`/update-menu-designer/${create_menu.menu_id}`);
            }
          }}
        >
          {(create_menu, { loading, error }) => (
            <>
              {error && <ErrorMessage message={error.message} />}
              <Form
                userId={userId}
                place={placeData}
                loading={loading}
                onSubmit={inputs => {
                  const input = omit(inputs, [
                    'menus',
                    'step',
                    'openCloseHours',
                  ]);
                  create_menu({
                    variables: {
                      input: {
                        user_id: input.user_id,
                        place_id: input.place_id,
                        service_type_setting_id: input.service_type_setting_id,
                        menu_id: input.menu_id,
                        name: input.name,
                        approval_status: input.approval_status,
                        status: input.status,
                        publish_menu: input.publish_menu,
                      },
                    },
                  });
                }}
              />
            </>
          )}
        </Mutation>
      ) : (
        <PlaceSelection
          claimStatus="NONE"
          hasServiceSetting
          heading="Select Place to add menus"
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default MenuTypeAdd;
