import React, { useState } from 'react';
import { Tab } from 'rbx';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { isNull } from 'lodash';
import * as yup from 'yup';

import { Box, Tabs } from '../../../components/elements';
import ActivityDashboard from './ActivityDashboard';
import ActivitySummary from './ActivitySummary';
import ActivityDetail from './ActivityDetail';
import Header from './Header';

const Container = styled.div``;

const MainForm = props => {
  const [isActiveTab, setIsActiveTab] = useState('ActivityDashboard');

  return (
    <>
      <Header isActiveTab={isActiveTab} />
      <Box>
        <Tabs className="tabs  is-fullwidth">
          <Tab
            active={isActiveTab === 'ActivityDashboard'}
            onClick={() => setIsActiveTab('ActivityDashboard')}
          >
            Activity Dashboard
          </Tab>

          <Tab
            active={isActiveTab === 'ActivitySummary'}
            onClick={() => setIsActiveTab('ActivitySummary')}
          >
            Activity Summary
          </Tab>

          <Tab
            active={isActiveTab === 'ActivityDetail'}
            onClick={() => setIsActiveTab('ActivityDetail')}
          >
            Activity Detail
          </Tab>
        </Tabs>
        <Container>
          <>
            {isActiveTab === 'ActivityDashboard' && (
              <ActivityDashboard {...props} />
            )}
            {isActiveTab === 'ActivitySummary' && <ActivitySummary />}

            {isActiveTab === 'ActivityDetail' && <ActivityDetail />}
          </>
        </Container>
      </Box>
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ orders }) => ({
    take_order:
      orders && !isNull(orders.take_order) ? orders.take_order : false,
  }),
  validationSchema: yup.object().shape({}),

  handleSubmit: () => {},

  displayName: 'MainForm',
})(MainForm);

export default Form;
