import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchUserLoginHistory = gql`
  query searchUserLoginHistory(
    $userId: String
    $sort: String
    $from: Int
    $size: Int
  ) {
    search_user_login_history(
      input: { user_id: $userId, sort: $sort, from: $from, size: $size }
    ) {
      total_pages
      total_size
      user_login_history {
        logged_in
        login_at
        last_login
        user_type
        login_type
        app_type
        version_number
        build_number
        platform
        web_browser
        device_name
        model_number
        ip_address
        unique_id
      }
    }
  }
`;

export const fetchUserLoginHistory = async ({ userId, sort, from, size }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchUserLoginHistory,
      variables: { userId, sort, from, size },
      fetchPolicy: 'network-only',
    });
    if (data && data.data && data.data.search_user_login_history) {
      return data.data.search_user_login_history;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default {};
