/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { Label } from 'rbx';

// import Loading from 'react-loading';
import * as yup from 'yup';
// import { toast } from 'react-toastify';

import { Input } from '../../../components/elements';

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const FormSection = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 0px !important;
`;

const ErrorDiv = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
`;

const getContainerClassNames = isActiveModal =>
  isActiveModal ? 'modal is-active' : 'modal';

const TagsModal = ({
  isActiveModal,
  onClose,
  onSubmitHandlerFromParent = () => {},
  initialValues,
}) => {
  const [tableName, setTableName] = React.useState(
    initialValues?.table_name ?? '',
  );
  const [tableNumber, setTableNumber] = React.useState(
    initialValues?.table_number ?? '',
  );

  const [guestCapacity, setGuestCapacity] = React.useState(
    initialValues?.table_capacity ?? '',
  );

  const [isError, setIsError] = React.useState(false);

  const [errors, setErrors] = React.useState([]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    setIsError(false);
    setErrors([]);
  }, [tableName, guestCapacity]);

  const onSubmit = async () => {
    const validationSchema = yup.object().shape({
      table_name: yup
        .string()
        .test(
          'noSpaces',
          'Please enter a valid Table Name',
          value => value && value.trim().length > 0,
        )
        .required('Table Name is required'),
      table_number: yup
        .string()
        .test(
          'noSpaces',
          'Please enter a valid Table Number',
          value => value && value.trim().length > 0,
        )
        .required('Table Number is required'),
      guest_capacity: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .required('Guest Capacity is required')
        .integer('Guest Capacity must be a valid integer')
        .min(1, 'Guest Capacity must be greater than or equal to 1')
        .max(100, 'Guest Capacity must be less than or equal to 100'),
    });

    try {
      setIsSubmitting(true);
      await validationSchema.validate(
        {
          table_name: tableName,
          table_number: tableNumber,
          guest_capacity: guestCapacity,
        },
        { abortEarly: false },
      );
      setIsError(false);
      setErrors([]);
      onSubmitHandlerFromParent({
        table_name: tableName,
        table_number: tableNumber,
        table_capacity: guestCapacity,
      });
      setIsSubmitting(false);
      onClose();
    } catch (err) {
      setIsError(true);
      setErrors(err.errors);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={getContainerClassNames(isActiveModal)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '60%', height: '60vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">
            Add a Table and Capacity
          </ModalTitle>
          <button
            onClick={onClose}
            className="delete"
            aria-label="close"
            type="button"
          />
        </header>

        <FormSection className="modal-card-body">
          <ErrorDiv>
            {isError && <p className="help is-danger is-size-4">{errors[0]}</p>}
          </ErrorDiv>
          <Content>
            <Input
              label="Table Name"
              name="table_name"
              value={tableName}
              onChange={e => setTableName(e.target.value)}
            />
            <Input
              label="Table Number"
              name="table_number"
              value={tableNumber}
              onChange={e => setTableNumber(e.target.value)}
            />
            <div className="mb-2">
              <Label>Guest Capacity</Label>
              <input
                name="guest_capacity"
                type="number"
                min="0"
                max="100"
                step="1"
                style={{ padding: '4px' }}
                value={guestCapacity}
                onChange={e => setGuestCapacity(e.target.value)}
              />
            </div>
          </Content>
          <div>
            <span className="pr-2">
              <button
                type="button"
                className="button is-grey"
                onClick={onClose}
              >
                <span className="has-text-weight-semibold">Cancel</span>
              </button>
            </span>
            <button
              type="button"
              className={`button is-primary ${isSubmitting &&
                'is-loading'} menu-designer-submit-button`}
              onClick={e => {
                e.stopPropagation();
                onSubmit();
              }}
            >
              <span className="has-text-weight-semibold">Submit</span>
            </button>
          </div>
        </FormSection>
      </div>
    </div>
  );
};

export default TagsModal;
