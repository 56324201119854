import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'rbx';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import gql from 'graphql-tag';

import FollowersList from './FollowersList';
import client from '../../../utils/apolloClient';
// import ListItem from './ListItem';
import {
  Table as CustomTable,
  Pagination,
  Loading,
} from '../../../components/elements';

const placeFollowersQuery = gql`
  query searchPlaceFollowers($input: SearchInput) {
    search_follows(input: $input) {
      total_pages
      total_size
      follow_listing {
        follow_id
        object_id
        object_type
        user_id
        type
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const Container = styled.div``;

const PlaceFollowers = ({ place }) => {
  const [followData, setFollowData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(3);
  const input = {};

  Object.assign(
    input,
    { from: pageFrom, size: listSize },
    {
      filter: {
        follow_filter: { object_id: place.place_id, object_type: 'PLACE' },
      },
    },
  );
  console.log('input', input);
  return (
    <Container>
      {totalElements > 0 ? (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      ) : (
        ' '
      )}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Login
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Followed On
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>

        <Query
          client={client.clientPrivate}
          query={placeFollowersQuery}
          variables={{ input }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }

            const { search_follows } = data;
            if (isNull(search_follows)) {
              return 'no data found';
            }

            return (
              // <tbody>
              //   {search_follows.follow_listing.map(follow => (
              //     <FollowersList
              //       totalPages={search_follows.total_pages}
              //       listSize={search_follows.total_size}
              //       totalElements={search_follows.total_size}
              //       follow={follow}
              //       setPageCount={() =>
              //         setPageCount(search_follows.total_pages)
              //       }
              //       setTotalElements={() =>
              //         setTotalElements(search_follows.total_size)
              //       }
              //     />
              //   ))}
              // </tbody>
              <React.Fragment>
                <View
                  followData={followData}
                  setFollowData={setFollowData}
                  search_follows={search_follows}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </CustomTable>
    </Container>
  );
};

const View = ({
  search_follows,
  setPageCount,
  setTotalElements,
  setFollowData,
  followData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_follows.total_pages);
    setTotalElements(search_follows.total_size);

    if (search_follows.follow_listing) {
      setFollowData(search_follows.follow_listing);
      const userFilter = search_follows.follow_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_follows.total_pages]);

  return (
    <tbody>
      {followData.map(follow => (
        <tr key={follow.follow_id}>
          <FollowersList follow={follow} users={users} />
        </tr>
      ))}
    </tbody>
  );
};

export default PlaceFollowers;
