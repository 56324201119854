import React, { useEffect } from 'react';
import moment from 'moment';
import { join } from 'lodash';
import { Table, Box, Loading } from '../../../../components/elements';

import { ACLSConsumer } from '../../../../utils/aclsContext';
import Can from '../../../../utils/Can';
import Delete from '../../../MenuManagement/HolidayHours/Delete';

const hourCol = hours => {
  if (!Array.isArray(hours.option)) {
    return '';
  }
  if (hours.option.length === 0) {
    return 'Closed all day';
  }
  if (
    hours.option[0].start === '12:00 AM' &&
    hours.option[0].end === '11:59 PM'
  ) {
    return 'Open 24 Hour';
  }

  return (
    hours.option.length !== 0 &&
    hours.option.map(ele => `${ele.start} - ${ele.end}`).join(', ')
  );
};

const List = ({
  holidays,
  fetchHours,
  holidayLoading,
  onHandleUpdate,
  placeName,
  refetchMenus,
}) => {
  console.log({ holidays });
  useEffect(() => {
    fetchHours();
  }, [fetchHours]);

  return (
    <Box>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>Date</span>
              </div>
            </th>
            <th>
              <div>
                <span>ServiceType</span>
              </div>
            </th>
            <th>
              <div>
                <span>Menu</span>
              </div>
            </th>
            <th>
              <div>
                <span>Hours</span>
              </div>
            </th>
            <th>
              <div>
                <span>Action</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {!holidayLoading &&
            holidays.map(hours => (
              <tr key={hours.custom_hour_id}>
                <td>
                  <a onClick={() => onHandleUpdate(hours)}>
                    {moment(hours.date, 'YYYY-MM-DD').format('MMM, DD YYYY')}
                  </a>
                </td>
                <td>{hours.selectedMenu.service_type}</td>
                <td>{join(hours.menu, ',')}</td>
                <td
                  style={{
                    width: '150px',
                    wordWrap: 'break-word',
                  }}
                >
                  {hourCol(hours)}
                </td>
                <td>
                  <ACLSConsumer>
                    {({ apiActions, pageActions }) => (
                      <Can
                        action="delete_custom_hour"
                        apiActions={apiActions}
                        pageActions={pageActions}
                        yes={() => (
                          <Delete
                            menuId={hours.selectedMenu.menu_id}
                            hours={hours}
                            onHandleComplete={() => {
                              refetchMenus();
                            }}
                            placeName={placeName}
                          >
                            <i className="fas fa-trash has-text-grey" />
                          </Delete>
                        )}
                      />
                    )}
                  </ACLSConsumer>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {holidayLoading && <Loading />}
      {holidays.length === 0 && !holidayLoading && (
        <p style={{ textAlign: 'center' }}>No Data Found</p>
      )}
    </Box>
  );
};

export default List;
