import { isEqual, omit, forEach } from 'lodash';

const removeTypeName = values => {
  const data = [];
  forEach(values, item => {
    const itemData = omit(item, ['__typename']);
    data.push(itemData);
  });
  return data;
};

const dirtyFieldHandler = (finalInput, item) => {
  console.log(finalInput, item);
  const final = {};
  Object.assign(
    final,
    {
      user_id: finalInput.user_id,
      modifier_group_id: finalInput.modifier_group_id,
      place_id: finalInput.place_id,
    },
    !isEqual(item.name, finalInput.name) && {
      name: finalInput.name,
    },
    !isEqual(
      item.multiply_selection_quantity,
      finalInput.multiply_selection_quantity,
    ) && {
      multiply_selection_quantity: finalInput.multiply_selection_quantity,
    },
    !isEqual(item.internal_name, finalInput.internal_name) && {
      internal_name: finalInput.internal_name,
    },
    !isEqual(item.description, finalInput.description) && {
      description: finalInput.description,
    },
    !isEqual(item.is_required, finalInput.is_required) && {
      is_required: finalInput.is_required,
    },
    !isEqual(item.meal_deal, finalInput.meal_deal) && {
      meal_deal: finalInput.meal_deal,
    },
    !isEqual(removeTypeName(item.modifier), finalInput.modifier) && {
      modifier: finalInput.modifier,
    },
    !isEqual(item.selection_quantity, finalInput.selection_quantity) && {
      selection_quantity: finalInput.selection_quantity,
    },
    !isEqual(
      item.selection_quantity_from,
      finalInput.selection_quantity_from,
    ) && {
      selection_quantity_from: finalInput.selection_quantity_from,
    },
    !isEqual(item.selection_quantity_to, finalInput.selection_quantity_to) && {
      selection_quantity_to: finalInput.selection_quantity_to,
    },
    !isEqual(item.selection_type, finalInput.selection_type) && {
      selection_type: finalInput.selection_type,
    },
    !isEqual(item.status, finalInput.status) && {
      status: finalInput.status,
    },
  );
  return final;
};

// description: ""
// is_required: true
// modifier: [,…]
// modifier_group_id: "9e5f813b-2207-4b38-8de2-e2a62ae49d4c"
// place_id: "0968eaf5-441d-4e62-be62-7102a003a0ec"
// selection_quantity: 3
// selection_quantity_from: null
// selection_quantity_to: null
// selection_type: "CUSTOM"
// status: "ACTIVE"
// user_id: "3a80912d-a090-4866-a4f3-4e8e6069b4d0"

export default dirtyFieldHandler;
