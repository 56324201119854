/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { CheckBox } from '../../../components/elements';

const Content = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  gap: 20px;
`;

const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const FormSection = styled.section``;

const TagName = styled.div`
  border: 1px solid #e2e2e2;
  font-size: 10px;
  padding: 0.2rem;
  color: #505050;
  font-weight: 600;
`;

const getContainerClassNames = isActiveModal =>
  isActiveModal ? 'modal is-active' : 'modal';

const TagFilterModal = ({
  initialTags,
  isActiveModal,
  onClose,
  changeRoute,
}) => {
  const [tagArray, setTagArray] = React.useState(initialTags);

  return (
    <div className={getContainerClassNames(isActiveModal)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '60%', height: '60vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">Tags</ModalTitle>
          <button
            onClick={() => {
              const t = btoa(tagArray);
              changeRoute(t);
              onClose();
            }}
            className="delete"
            aria-label="close"
            type="button"
          />
        </header>
        <FormSection className="modal-card-body">
          <Content>
            <TagName>
              <span>1 st Time</span>
            </TagName>
            <div>
              <CheckBox
                value={(() => {
                  const temp = new Set(tagArray);
                  if (temp.has('1 st Time')) {
                    return true;
                  }
                  return false;
                })()}
                onChange={() => {
                  setTagArray(prev => {
                    const temp = new Set(prev);
                    let temp2 = [...prev];
                    if (temp.has('1 st Time')) {
                      return temp2.filter(ele => ele !== '1 st Time');
                    }
                    temp2 = [...temp2, '1 st Time'];
                    return temp2;
                  });
                }}
              />
            </div>
            <TagName>
              <span>Anniversary</span>
            </TagName>
            <div>
              <CheckBox
                value={(() => {
                  const temp = new Set(tagArray);
                  if (temp.has('Anniversary')) {
                    return true;
                  }
                  return false;
                })()}
                onChange={() => {
                  setTagArray(prev => {
                    const temp = new Set(prev);
                    let temp2 = [...prev];
                    if (temp.has('Anniversary')) {
                      return temp2.filter(ele => ele !== 'Anniversary');
                    }
                    temp2 = [...temp2, 'Anniversary'];
                    return temp2;
                  });
                }}
              />
            </div>
            <TagName>
              <span>Birthday</span>
            </TagName>
            <div>
              <CheckBox
                value={(() => {
                  const temp = new Set(tagArray);
                  if (temp.has('Birthday')) {
                    return true;
                  }
                  return false;
                })()}
                onChange={() => {
                  setTagArray(prev => {
                    const temp = new Set(prev);
                    let temp2 = [...prev];
                    if (temp.has('Birthday')) {
                      return temp2.filter(ele => ele !== 'Birthday');
                    }
                    temp2 = [...temp2, 'Birthday'];
                    return temp2;
                  });
                }}
              />
            </div>
            <TagName>
              <span>No Nuts</span>
            </TagName>
            <div>
              <CheckBox
                value={(() => {
                  const temp = new Set(tagArray);
                  if (temp.has('No Nuts')) {
                    return true;
                  }
                  return false;
                })()}
                onChange={() => {
                  setTagArray(prev => {
                    const temp = new Set(prev);
                    let temp2 = [...prev];
                    if (temp.has('No Nuts')) {
                      return temp2.filter(ele => ele !== 'No Nuts');
                    }

                    temp2 = [...temp2, 'No Nuts'];
                    return temp2;
                  });
                }}
              />
            </div>
            <TagName>
              <span>VIP</span>
            </TagName>
            <div>
              <CheckBox
                value={(() => {
                  const temp = new Set(tagArray);
                  if (temp.has('VIP')) {
                    return true;
                  }
                  return false;
                })()}
                onChange={() => {
                  setTagArray(prev => {
                    const temp = new Set(prev);
                    let temp2 = [...prev];
                    if (temp.has('VIP')) {
                      return temp2.filter(ele => ele !== 'VIP');
                    }
                    temp2 = [...temp2, 'VIP'];
                    return temp2;
                  });
                }}
              />
            </div>
          </Content>
        </FormSection>
      </div>
    </div>
  );
};

export default TagFilterModal;
