import React from 'react';
import styled from 'styled-components';
import { Tab, Column } from 'rbx';
import { withFormik } from 'formik';

import { isNull, omit, forEach } from 'lodash';
import moment from 'moment';
import PlacePageHeader from '../../../components/PlacePageHeader';

import {
  Box,
  Tabs,
  ReactDateTimePicker,
  Input,
  InputErrorMessage,
} from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import Hours from './Hours';

const Container = styled.div`
  padding: 1rem;
`;

const calCulateOpen24Hours = option => {
  let open24Hours = false;
  if (
    option.length !== 0 &&
    option[0].start_in_sec === 0 &&
    option[0].end_in_sec === 86340
  ) {
    open24Hours = true;
  }

  return open24Hours;
};

const Form = props => {
  const {
    loading,
    dirty,
    values,
    handleSubmit,
    setFieldValue,
    isUpdate,
    errors,
    hour,
    touched,
    history,
    placeName,
  } = props;
  const minDate = new Date();
  // minDate.setDate(minDate.getDate() + 1);
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <Header
        values={values}
        isUpdate={isUpdate}
        loading={loading}
        dirty={dirty}
        customHourId={isUpdate ? hour.custom_hour_id : null}
        placeName={placeName}
        history={history}
      >
        <PlacePageHeader place={props.place} placeId={values.place_id} />
      </Header>
      <Box>
        <Tabs className="tabs is-medium">
          <Tab active onClick={() => {}}>
            Custom Hours
          </Tab>
        </Tabs>
        <Container>
          <Column.Group>
            <Column size="3">
              {isUpdate ? (
                <Input
                  label="Date"
                  value={moment(values.day, 'YYYY-MM-DD').format(
                    'MMM, DD YYYY',
                  )}
                  disabled
                />
              ) : (
                <ReactDateTimePicker
                  label="Date"
                  value={values.day}
                  onChange={value => {
                    setFieldValue('day', value);
                  }}
                  minDate={minDate}
                />
              )}

              <InputErrorMessage
                errors={errors.day}
                touched={touched.day}
                disableToastMessage
              />
            </Column>
            <Column size="4">
              <Input
                label="Description"
                name="description"
                value={values.description}
                onChange={e => {
                  if (e.target.value && e.target.value.length <= 35) {
                    setFieldValue('description', e.target.value);
                  }
                }}
                row={1}
              />
            </Column>
          </Column.Group>
          <br />
          <Hours {...props} />
        </Container>
      </Box>
      <br />
      <Footer loading={loading} dirty={dirty} />
    </form>
  );
};

const HolidayForm = withFormik({
  mapPropsToValues: ({ hour, userId, place }) => {
    let hoursType = 'CUSTOM';
    const open24Hours = hour ? calCulateOpen24Hours(hour.option) : false;
    if (open24Hours) {
      hoursType = 'OPEN_24_HOUR';
    }
    if (hour && hour.closed_all_day) {
      hoursType = 'CLOSED_ALL';
    }

    return {
      user_id: userId,
      place_id: hour && !isNull(hour.place_id) ? hour.place_id : place.place_id,
      object_type:
        hour && !isNull(hour.object_type) ? hour.object_type : 'PLACE',
      description: hour && !isNull(hour.description) ? hour.description : '',
      closedAllDay:
        hour && !isNull(hour.closed_all_day) ? hour.closed_all_day : false,
      day: hour && !isNull(hour.day) ? hour.day : null,
      option: hour && !isNull(hour.option) ? hour.option : [],
      hoursType,
    };
  },

  validate: values => {
    const errors = {};

    if (!values.day) {
      errors.day = 'Date is required field';
    }
    if (values.option.length === 0 && !values.closedAllDay) {
      errors.option = 'Please add hours!';
    }
    if (
      values.hoursType === 'CUSTOM' &&
      values.option.length !== 0 &&
      values.option[0].start.length === 0
    ) {
      errors.option = 'Please enter atleast one hours row!';
    }

    return errors;
  },

  handleSubmit: (values, { props }) => {
    const input = omit(values, [
      'closedAllDay',
      'is_active',
      'option',
      'hoursType',
    ]);
    const parseOption = option => {
      const final = [];
      forEach(option, item => {
        const data = omit(item, 'id', '__typename');
        final.push(data);
      });
      return final;
    };
    const option = parseOption(values.option);
    props.onSubmit({
      ...input,
      day: moment(values.day).format('YYYY-MM-DD'),
      closed_all_day: values.closedAllDay,
      option,
      status: 'ACTIVE',
    });
  },

  displayName: 'Form',
})(Form);

export default HolidayForm;
