import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Header from './Header';

import ListItem from './ListItem';
import {
  Loading,
  Table,
  Box,
  ErrorMessage,
  Pagination,
  AesDes,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';
import Search from './Search';

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      total_size
      total_pages
      menu_listing {
        platform
        place {
          place_id
          name
          city
        }
        service_type
        service_type_setting {
          service_type_setting_id
        }
        menu_id
        name
        internal_name
        slug
        description
        status
        approval_status
        is_private_menu
        sales_method
        menu_hour {
          day
          is_active
          option {
            type
            start
            end
          }
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          display_name
          email
        }
        updated_by_user {
          user_id
          display_name
          email
        }
      }
    }
  }
`;

const listSize = 20;
const Menu = ({ location, history }) => {
  const {
    auth: { userId },
    global: { currentRoute },
  } = useStoreState(state => state);
  const { defaultPlace } = useStoreState(state => state.menu);

  const routeState = qs.parse(location.search);
  const {
    status,
    placeName,
    serviceType,
    city,
    menuName,
    page,
    publish,
  } = routeState;

  const statusFilter = status || 'ACTIVE';
  const placeNameFilter = (() => {
    let temp = null;
    if (!(placeName === null || placeName === undefined)) {
      temp = placeName;
    } else if (defaultPlace) {
      temp = defaultPlace.name;
    }
    return temp;
  })();
  const cityFilter = city || null;
  const menuNameFilter = menuName || null;
  const serviceTypeFilter = serviceType || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  // Pagination
  const [pageCount, setPageCount] = useState(0);

  const [totalElements, setTotalElements] = useState();

  const [sort, setSort] = useState('UPDATED_DESC');

  // const publishFilter = publish ? publish === 'ON' : true;

  const input = { user_id: userId };
  const menu_filter = { status: statusFilter };
  const place_filter = {};
  Object.assign(
    place_filter,
    placeNameFilter && { place_name: placeNameFilter },
    cityFilter && { city: cityFilter },
  );
  Object.assign(
    menu_filter,
    menuNameFilter && { name: menuNameFilter },
    serviceTypeFilter && { service_type: serviceTypeFilter },
    // { publish_menu: publishFilter },
  );
  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    {
      filter: { menu_filter, place_filter },
    },
  );

  return (
    <Layout>
      <Header />
      <Search
        status={statusFilter}
        placeName={placeNameFilter}
        menuName={menuNameFilter}
        city={cityFilter}
        serviceType={serviceTypeFilter}
        routeState={routeState}
        history={history}
        publish={publish || 'ON'}
        defaultPlace={defaultPlace}
      />
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * listSize}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value =>
            history.push(
              `/menus${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            )
          }
        />
      )}
      <Box>
        <Table>
          <thead>
            <tr>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="NAME_ASC"
                  des="NAME_DESC"
                />
                Menu Name
              </th>

              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="SERVICE_TYPE_ASC"
                  des="SERVICE_TYPE_DESC"
                />
                Service Type
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_NAME_ASC"
                  des="PLACE_NAME_DESC"
                />
                Place Name
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_CITY_ASC"
                  des="PLACE_CITY_DESC"
                />
                City / Suburb
              </th>
              <th>Platform</th>

              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_BY_ASC"
                  des="UPDATED_BY_DESC"
                />
                Updated by
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_AT_ASC"
                  des="UPDATED_AT_DESC"
                />
                Updated at
              </th>

              <th>
                <div>
                  <span>Action</span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={searchMenusQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <ErrorMessage message={error.message} />;
              }

              return (
                <React.Fragment>
                  <View
                    data={data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                    routeType={currentRoute?.sub}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, routeType, setTotalElements }) => {
  const { search_menus } = data;

  useEffect(() => {
    setPageCount(search_menus.total_pages);
    setTotalElements(search_menus.total_size);
  }, [search_menus.total_pages]);

  if (search_menus === null) {
    return 'No record found';
  }

  if (!search_menus.menu_listing || search_menus.menu_listing.length === 0) {
    return 'No record found';
  }
  return (
    <tbody>
      {search_menus.menu_listing.map(menu => (
        <tr key={menu.menu_id}>
          <ListItem routeType={routeType} menu={menu} />
        </tr>
      ))}
    </tbody>
  );
};

export default Menu;
