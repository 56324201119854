import React from 'react';
import styled from 'styled-components';
import { Field, Label } from 'rbx';
import ReactSelectInput from './ReactSelectInput';
import InputErrorMessage from './InputErrorMessage';
import { ACLSConsumer } from '../../utils/aclsContext';
import Can from '../../utils/Can';

const OptionalText = styled.p`
  &&& {
    color: #737373;
    padding-left: 2px;
  }
`;

const TagInputs = ({
  label,
  value,
  required,
  onChange,
  suggestions,
  errors,
  touched,
  disabled,
  optionalText,
  action,
  isOptionDisabled,
  defaultValue,
}) => (
  <Field>
    {label && (
      <Label className="is-flex">
        {label}
        {required && <span className="has-text-danger">*</span>}
        {optionalText && <OptionalText>{optionalText}</OptionalText>}
      </Label>
    )}

    <div className="control">
      {action ? (
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={action}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <ReactSelectInput
                  value={value}
                  closeMenuOnSelect={false}
                  isMulti
                  name="colors"
                  options={suggestions}
                  isOptionDisabled={isOptionDisabled}
                  // isLoading={suggestions ? suggestions.length === 0 : true}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onChange}
                  isDisabled={disabled}
                  defaultValue={defaultValue}
                />
              )}
              no={() => (
                <ReactSelectInput
                  value={value}
                  closeMenuOnSelect={false}
                  isMulti
                  name="colors"
                  options={suggestions}
                  isOptionDisabled={isOptionDisabled}
                  // isLoading={suggestions ? suggestions.length === 0 : true}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onChange}
                  defaultValue={defaultValue}
                  isDisabled
                />
              )}
            />
          )}
        </ACLSConsumer>
      ) : (
        <ReactSelectInput
          value={value}
          closeMenuOnSelect={false}
          isMulti
          name="colors"
          options={suggestions}
          isOptionDisabled={isOptionDisabled}
          // isLoading={suggestions ? suggestions.length === 0 : true}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={onChange}
          isDisabled={disabled}
          defaultValue={defaultValue}
        />
      )}
    </div>
    <InputErrorMessage errors={errors} touched={touched} />
  </Field>
);

export default TagInputs;
