import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import moment from 'moment-timezone';
import { Loading, Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import client from '../../../utils/apolloClient';
import Form from './ApprovalComp/Form';

const searchPlaceImageApprovalsQuery = gql`
  query searchItemImageApprovals($input: SearchInput) {
    search_photo_approvals(input: $input) {
      images {
        image_id
        group_id
        url
        status
        approval_status
        audit {
          created_at
        }
      }
      place {
        name
        address_line_1
        city
        state
        country
        post_code
        status
      }
      item {
        item_id
        name
        status
      }
      user {
        first_name
        last_name
        display_name
        email
      }
    }
  }
`;

const updateImageReviewMutation = gql`
  mutation action_image($input: ActionImageInput) {
    action_image(input: $input) {
      image_id
      error {
        description
      }
    }
  }
`;

const handleImageApprove = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: updateImageReviewMutation,
        variables: {
          input,
        },
      })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });

const Approval = ({ match, history }) => {
  const { groupId } = match.params;
  const { userId } = useStoreState(state => state.auth);
  const [imageApprovalLoading, setImageApprovalLoading] = useState(false);
  const { data, loading, error } = useQuery(searchPlaceImageApprovalsQuery, {
    variables: {
      input: { filter: { photo_approval_filter: { group_id: groupId } } },
    },
    client: client.clientPrivate,
  });

  const onSubmit = async values => {
    setImageApprovalLoading(true);
    const promises = [];
    values.images.map(item => {
      promises.push(
        handleImageApprove({
          user_id: userId,
          image_id: item.image_id,
          note: [
            {
              value: values.note,
              date: moment().toISOString(),
            },
          ],
          approval_status:
            item.approval_status === 'SUBMITTED'
              ? 'APPROVED'
              : item.approval_status,
        }),
      );
      return null;
    });

    try {
      const result = await Promise.all(promises);
      swal(
        'Hurray!',
        `Place Photo APPROVED Or REJECTED successfully!`,
        'success',
      ).then(() => {
        setImageApprovalLoading(false);
        history.goBack();
      });
      console.log(result);
    } catch (error1) {
      console.log(error1);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Message>{error.message}</Message>;
  }

  const {
    search_photo_approvals: { images, place, user, item },
  } = data;

  return (
    <Form
      loading={imageApprovalLoading}
      images={images}
      place={place}
      user={user}
      item={item}
      onSubmit={onSubmit}
    />
  );
};

const View = props => (
  <Layout>
    <Approval {...props} />
  </Layout>
);

export default View;
