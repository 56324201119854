import React from 'react';
import { Column } from 'rbx';
import { Input } from '../../../components/elements';

const ItemReviews = () => (
  <fieldset className="box">
    <legend className="label">Item Review</legend>
    <Column.Group vcentered multiline>
      <Column size={3}>
        <Input label=" Likes" placeholder="60" disabled />
      </Column>
      <Column size={3}>
        <Input label=" Shares" placeholder="600" disabled />
      </Column>
      <Column size={3}>
        <Input label="Total Reviewers" placeholder="60" disabled />
      </Column>
      <Column size={3}>
        <Input label="Unique Reviewers" placeholder="60" disabled />
      </Column>
      <Column size={3}>
        <Input label="Review Rating" placeholder="60" disabled />
      </Column>
      <Column size={3}>
        <Input label="Love Percentage" placeholder="60" disabled />
      </Column>
      <Column size={3}>
        <Input label="User Uploaded Photos" placeholder="600" disabled />
      </Column>
      <Column size={3}>
        <Input label="Comments" placeholder="60" disabled />
      </Column>

      <Column size={3}>
        <Input label="Total Reviews Flagged " placeholder="600" disabled />
      </Column>
    </Column.Group>
  </fieldset>
);
export default ItemReviews;
