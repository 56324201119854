import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { first, isUndefined } from 'lodash';

import PlaceDetails from './PlaceDetails';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const fetchPlaceReviewQuery = gql`
  query placeReview($input: NavigatePlaceReviewInput) {
    fetch_place_review(input: $input) {
      place_review_id
      status
      content
      audit {
        created_at
        created_by
      }
      error {
        description
      }
    }
  }
`;

const PlaceReview = ({ flag, placeName }) => {
  console.log('placeName', placeName);
  return (
    <Container>
      <Query
        query={fetchPlaceReviewQuery}
        variables={{ input: { place_review_id: flag.object_id } }}
      >
        {({ data, loading, error }) => {
          // if (loading) {
          //   return <Loading />;
          // }
          if (error) {
            return 'error';
          }
          const place = first(data.fetch_place_review);

          if (isUndefined(place)) {
            return 'loading';
          }

          return <PlaceDetails place={place} placeName={placeName} />;
        }}
      </Query>
    </Container>
  );
};

export default PlaceReview;
