import React from 'react';
import { Link } from 'react-router-dom';

const BookByAttendeeList = ({ booking, tickets }) => (
  <React.Fragment>
    <td>{tickets.attendee.attendee_name || '-'}</td>
    <td>{tickets.attendee.attendee_email || '-'}</td>
    <td>{tickets.attendee.attendee_phone || '-'}</td>
    <td>{tickets.attendee.attendee_age || '-'}</td>
    <td>{tickets.ticket_name}</td>
    <td>{`${tickets.ticket_number}-${booking.booking_number}`}</td>
    <td>{tickets.ticket_status}</td>
    <td>
      <Link
        to={`/ticket/booking-detail/edit-attendee-info/${tickets.ticket_id}`}
      >
        <span className="icon">
          <i className="fas fa-pencil-alt" />
        </span>
      </Link>
    </td>
  </React.Fragment>
);

export default BookByAttendeeList;
