import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { filter, capitalize } from 'lodash';
import { useStoreState } from 'easy-peasy';

import ActiveCards from './ActiveCard';
import OrderDetails from './OrderDetails';
import { getReceivedOrders, handleOrderAction, parsedAmount } from './helpers';
import { Loading } from '../../../components/elements';
import {
  scheduleTimeText,
  scheduleCompletionTimeText,
  textHelper,
  totalTime,
  totalTimeDueActiveCard2,
  totalTimeDelay,
} from './timeHelpers';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
  }
`;

const Progress = styled.p`
  display: flex;
  padding-top: 9px;
  padding-left: 10px;
  &&& {
    background-color: ${({ theme }) => theme.secondaryColor};
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    height: 6vh;
  }
`;

const OrderButton = styled.button`
  &&& {
    font-weight: 600;
  }
`;

const InProgress = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [readyLoading, setReadyLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const { userId } = useStoreState(state => state.auth);

  const {
    place,
    setConfirmedOrders,
    confirmedOrders,
    setOrderCount,
    handleAfterDelayAndAdjustOrder,
    isActiveOrderDetailsModalInProgressTab,
    setIsActiveOrderDetailsModalInProgressTab,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getReceivedOrders({
        placeId: place.place_id,
        status: 'CONFIRMED',
        userId,
      });
      setIsLoading(false);
      const dataFiltered = (data ?? []).filter(ele => {
        if (ele.is_scheduled && ele.scheduled_date_time) {
          return (
            moment(ele.scheduled_date_time).format('YYYY-MM-DD') ===
            moment().format('YYYY-MM-DD')
          );
        }
        return true;
      });
      setConfirmedOrders(dataFiltered);
      setOrderCount(prev => ({
        ...prev,
        confirmedOrder: dataFiltered.length,
      }));
    };
    fetchData();
  }, []);

  const handleReadyOrder = async value => {
    setReadyLoading(true);
    setCurrentOrderId(value.menu_order_id);
    await handleOrderAction({
      menuId: value.menu_order_id,
      userId,
      action: 'prepare',
      delay: null,
    });
    setReadyLoading(false);
    const newConfirmedOrders = filter(
      confirmedOrders,
      item => item.menu_order_id !== value.menu_order_id,
    );
    setConfirmedOrders(newConfirmedOrders);
  };

  return (
    <>
      <Progress>
        <p className="is-size-2">
          IN PROGRESS ({props.orderCount.confirmedOrder})
        </p>
      </Progress>
      <Container>
        <br />
        {isLoading && <Loading />}
        {confirmedOrders !== 0 &&
          confirmedOrders.map(item => {
            const receivedStatusHistory = item.received;

            const receivedTime = receivedStatusHistory
              ? receivedStatusHistory.created_at
              : null;

            const title = () => (
              <>
                {item.service_type === 'Delivery' && 'Delivery'}
                {item.service_type === 'Dinein' && item.table_number
                  ? `${item.table_name ?? 'Table'} ${item.table_number}`
                  : item.service_type === 'Dinein' && 'Dinein'}
                {item.service_type === 'Pickup' && 'Pickup'}
                {item.large_order && ` (LARGE ORDER)`}
              </>
            );

            const delayInMin = totalTime(
              item.estimates.filter(
                estimate_item => estimate_item.type === 'delayed_estimate',
              ),
            );

            return (
              <ActiveCards
                key={item.audit.created_at}
                title={title()}
                schedule={scheduleTimeText(item.estimated_completion_time)}
                scheduleWithDelay={scheduleCompletionTimeText(
                  item.estimates,
                  receivedTime,
                )}
                ready={
                  delayInMin > 0 &&
                  `delayed by ${textHelper(Math.abs(delayInMin))}`
                }
              >
                <div className="columns">
                  <div className="column">
                    <p className="is-size-4 has-text-weight-semibold">
                      {item.order_reference} | {item.overall_quantity} items |
                      {parsedAmount(item?.billing?.order_total?.toFixed(2))}
                    </p>
                    <p className="is-size-4" style={{ paddingTop: '8px' }}>
                      {item.contact && item.contact.contact_name}
                      {item.service_type === 'Delivery' &&
                        item.address &&
                        ` ( ${capitalize(item.address.city)} )`}
                      {item.service_type === 'Dinein' &&
                        item.no_of_attendees &&
                        ` (no of attendees : ${item.no_of_attendees})`}
                    </p>
                    {item.service_type === 'Pickup' && item.address && (
                      <p
                        className="is-size-4"
                        style={{ paddingTop: '8px', color: '#00AEEF' }}
                      >
                        {item.address.city}
                      </p>
                    )}
                  </div>
                  <div className="column is-4">
                    <DetailsButton
                      type="button"
                      className="button is-large is-fullwidth control"
                      onClick={() => {
                        setIsActiveOrderDetailsModalInProgressTab(true);
                        setCurrentOrder(item);
                      }}
                    >
                      Details
                    </DetailsButton>
                  </div>
                  <div className="column is-4">
                    <OrderButton
                      type="button"
                      className={`button is-large is-fullwidth is-primary control ${readyLoading &&
                        item.menu_order_id === currentOrderId &&
                        'is-loading'}`}
                      onClick={() => handleReadyOrder(item)}
                    >
                      {item.service_type === 'Delivery' && 'Order on its way'}
                      {item.service_type === 'Dinein' && 'Serve Order'}
                      {item.service_type === 'Pickup' && 'Order is ready'}
                    </OrderButton>
                  </div>
                </div>

                <div />
              </ActiveCards>
            );
          })}
        {!isLoading && confirmedOrders.length === 0 && (
          <>
            <div
              className="has-text-weight-semibold"
              style={{ justifyContent: 'center' }}
            >
              <div
                className="is-flex has-text-weight-semibold"
                style={{ justifyContent: 'center' }}
              >
                <p>No Confirmed Orders yet!</p>
              </div>
              <div
                className="is-flex has-text-weight-medium"
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <p className="has-text-centered">
                  To review previous orders, go to order history
                </p>
                <br />
                <br />
                <br />
              </div>
            </div>
          </>
        )}
      </Container>
      {currentOrder && isActiveOrderDetailsModalInProgressTab && (
        <OrderDetails
          dueTimeToBeDisplayedInOrderDetailsModal={totalTimeDueActiveCard2(
            currentOrder.estimates,
            currentOrder?.received?.created_at,
          )}
          delayTimeToBeDisplayedInOrderDetailsModal={totalTimeDelay(
            currentOrder.estimates,
            currentOrder?.received?.created_at,
          )}
          isActiveOrderDetailsModal={isActiveOrderDetailsModalInProgressTab}
          setIsActiveOrderDetailsModal={
            setIsActiveOrderDetailsModalInProgressTab
          }
          order={currentOrder}
          setConfirmedOrders={setConfirmedOrders}
          confirmedOrders={confirmedOrders}
          handleAfterDelayOrder={handleAfterDelayAndAdjustOrder}
          handleAfterAdjustment={handleAfterDelayAndAdjustOrder}
          orderComponent="Orders"
        />
      )}
    </>
  );
};

export default InProgress;
