import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import gql from 'graphql-tag';
import { Button } from 'rbx';
import { toast } from 'react-toastify';
import { isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';

import { CustomInput, Radio } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const validateCouponQuery = gql`
  query validateCoupon($input: ValidatePlaceEventVoucherInput) {
    validate_place_event_voucher(input: $input) {
      discount
      error {
        code
        description
      }
    }
  }
`;

const RadioWrapper = styled.div`
  padding-right: 1rem;
`;

const Form = props => {
  const {
    handleSubmit,
    values: { voucherType, voucherCode, defaultDiscountPrice },
    setDiscount,
    setFieldValue,
    handleChange,
    handleBlur,
    errors,
    touched,
    isCouponApplied,
    removeDiscountCoupon,
    applyCouponLoading,
  } = props;

  const { userId } = useStoreState(state => state.auth);
  return (
    <React.Fragment>
      <span className="is-flex">
        <RadioWrapper>
          <Radio
            label="Apply Voucher"
            secondary
            value={voucherType === 'apply'}
            onChange={value => {
              if (value) {
                setFieldValue('voucherType', 'apply');
                setDiscount(0);
                setFieldValue('defaultDiscountPrice', '');
              }
            }}
            disabled={isCouponApplied}
          />
        </RadioWrapper>
        <RadioWrapper>
          <Radio
            label="Override Voucher"
            secondary
            value={voucherType === 'override'}
            onChange={value => {
              if (value) {
                setFieldValue('voucherType', 'override');
                setDiscount(0);
                setFieldValue('voucherCode', '');
              }
            }}
            // disabled
          />
        </RadioWrapper>
      </span>
      <br />
      <div className="field">
        <label className="label">
          {voucherType === 'apply'
            ? 'Voucher / Discount Code'
            : 'Default Value'}
        </label>
        <div className="control" style={{ width: '10rem' }}>
          {voucherType === 'apply' ? (
            <CustomInput
              name="voucherCode"
              value={voucherCode}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isCouponApplied}
            />
          ) : (
            <CustomInput
              type="number"
              name="defaultDiscountPrice"
              value={defaultDiscountPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              min="0"
              disabled={isCouponApplied}
            />
          )}
        </div>
        {errors.defaultDiscountPrice && touched.defaultDiscountPrice && (
          <p className="help is-danger is-size-4">
            {errors.defaultDiscountPrice}
          </p>
        )}
      </div>

      {isCouponApplied ? (
        <Button color="primary is-danger" onClick={removeDiscountCoupon}>
          Remove
        </Button>
      ) : (
        <Button
          color={`primary ${applyCouponLoading && 'is-loading'}`}
          onClick={handleSubmit}
          disabled={
            voucherType === 'apply'
              ? voucherCode.length === 0
              : defaultDiscountPrice.length === 0
          }
        >
          Apply
        </Button>
      )}
      {/* <p>
              Promo Code `Event` has been applied <a>Remove</a>
            </p> */}
    </React.Fragment>
  );
};

const ApplyVoucherForm = withFormik({
  mapPropsToValues: ({ subTotalPrice }) => ({
    voucherType: 'apply',
    voucherCode: '',
    defaultDiscountPrice: '',
    subTotalPrice,
  }),

  validate: values => {
    const errors = {};
    console.log('props', values);
    if (parseFloat(values.defaultDiscountPrice) > values.subTotalPrice) {
      errors.defaultDiscountPrice =
        'Discount price is less than or equal to sub total';
    }
    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      if (values.voucherType === 'apply') {
        props.handleApplyCouponLoading(true);
        const {
          eventId,
          eventOccurrenceId,
          placeId,
          tickets,
          subTotalPrice,
        } = props;
        client.clientPublic
          .query({
            query: validateCouponQuery,
            variables: {
              input: {
                user_id: props.userId,
                event_id: eventId,
                event_occurrence_id: eventOccurrenceId,
                place_id: placeId,
                voucher_code: values.voucherCode,
                tickets: tickets.map(item => ({
                  ticket_id: item.id,
                  price: item.price,
                  quantity: item.quantity,
                })),
              },
            },
          })
          .then(({ data: { validate_place_event_voucher } }) => {
            console.log(validate_place_event_voucher);
            if (!isNull(validate_place_event_voucher.error)) {
              validate_place_event_voucher.error.map(item =>
                toast.error(item.description),
              );

              props.handleApplyCouponLoading(false);
            } else {
              const discountPrice =
                subTotalPrice < validate_place_event_voucher.discount
                  ? subTotalPrice
                  : validate_place_event_voucher.discount;
              props.handleDisCount(discountPrice, values.voucherCode);
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        console.log(values.defaultDiscountPrice);
        props.handleOverrideDiscount(parseFloat(values.defaultDiscountPrice));
      }
      setSubmitting(false);
    }, 100);
  },

  displayName: 'ApplyVoucherForm',
})(Form);

export default ApplyVoucherForm;
