import React, { useState } from 'react';
import styled from 'styled-components';

import { Table } from '../../../components/elements';

const Container = styled.div``;

const KeyValueTable = ({ handleKeyValuePairChange }) => {
  const [rows, setRows] = useState([{ id: 1, key: '', value: '' }]);

  handleKeyValuePairChange(rows);

  const handleAddRow = () => {
    const temp = {};
    temp.key = '';
    temp.value = '';
    temp.id = rows.length !== 0 ? rows[rows.length - 1].id + 1 : 1;
    setRows([...rows, temp]);
  };

  const handleRemoveRow = rowId => {
    setRows(rows.filter(row => rowId !== row.id));
  };

  const handleRowChange = (idx, field, value) => {
    const temp = [...rows];
    temp[idx][field] = value;
    setRows([...temp]);
  };

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, idx) => (
            <tr key={row.id}>
              <td>
                <input
                  type="text"
                  value={row.key}
                  onChange={e => handleRowChange(idx, 'key', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.value}
                  onChange={e => handleRowChange(idx, 'value', e.target.value)}
                />
              </td>
              <td>
                <button
                  type="button"
                  className="button is-primary"
                  onClick={() => handleRemoveRow(row.id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <button
                type="button"
                className="button is-primary"
                onClick={handleAddRow}
              >
                Add Row
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default KeyValueTable;
