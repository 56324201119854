import React from 'react';
import { Button } from 'rbx';

import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import { Pagination, Tabs } from '../../../components/elements';

import MenuServiceAuditTable from './MenuServiceAuditTable';
import { downloadCSVFile, usePlaceMenuServiceAudit } from './helpers';

const Index = ({
  history,
  // match,
  location,
}) => {
  const { userId: adminUserId } = useStoreState(state => state.auth);
  const [downloadCsv, setDownloadCsv] = React.useState(false);

  const routeState = qs.parse(location.search);
  const { page, cityFilter, nameFilter, serviceTypeFilter } = routeState;

  let city = '';
  let name = '';
  let serviceType = '';

  if (cityFilter) {
    city = cityFilter;
  }

  if (nameFilter) {
    name = nameFilter;
  }

  if (serviceTypeFilter) {
    serviceType = serviceTypeFilter;
  }

  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [
    loading,
    placeMenuServiceAudit,
    pageCount,
    totalElements,
    fetchMenuAudit,
  ] = usePlaceMenuServiceAudit({
    adminUserId,
    city,
    name,
    serviceType,
    from: currentPage * 50,
    size: 50,
  });

  return (
    <Layout>
      <Header />
      <Search
        setCityName={v => {
          history.push(
            `/menu-service-audit${qs.stringify(
              {
                ...routeState,
                cityFilter: v,
                page: 0,
              },
              true,
            )}`,
          );
        }}
        setPlaceNameSearchInput={v => {
          history.push(
            `/menu-service-audit${qs.stringify(
              {
                ...routeState,
                nameFilter: v,
                page: 0,
              },
              true,
            )}`,
          );
        }}
        placeNameSearchInput={name}
        service={(() => {
          const st = [
            { label: 'ALL', value: 'All' },
            { label: 'PICKUP', value: 'Pickup' },
            { label: 'DELIVERY', value: 'Delivery' },
            { label: 'DINEIN', value: 'Dinein' },
          ];
          return st.find(ele => ele.value === serviceTypeFilter);
        })()}
        city={city}
        setService={v => {
          history.push(
            `/menu-service-audit${qs.stringify(
              {
                ...routeState,
                serviceTypeFilter: v.value,
                page: 0,
              },
              true,
            )}`,
          );
        }}
      />
      <Tabs className="tabs is-medium is-fullwidth">
        <div style={{ marginLeft: 'auto', padding: '1rem' }}>
          <div>
            <Button
              type="button"
              className={`button control ${downloadCsv && 'is-loading'}`}
              style={{
                marginLeft: '15px',
              }}
              onClick={async () => {
                function generateArray(n) {
                  return Array.from(Array(n - 1).keys(), x => x + 1);
                }
                setDownloadCsv(true);

                try {
                  let temp = [];

                  const muas = await fetchMenuAudit({
                    _adminUserId: adminUserId,
                    _from: 0,
                  });

                  const temp2 = (
                    muas?.search_place_menu_services_list ?? []
                  ).map(mua => ({
                    name: mua.name,
                    service_type: mua.service_type,
                    catering: mua.catering ?? '-',
                    payment_method: (mua?.payment_method ?? '').replaceAll(
                      ',',
                      ' ',
                    ),
                    menu_name: mua.menu_name,
                    auto_confirm: mua.auto_confirm,
                    auto_ready: mua.auto_ready,
                    auto_cancel: mua?.auto_cancel?.is_auto_cancel,
                    auto_cancel_time_in_mins: mua?.auto_cancel?.time_in_mins,
                    delivery_channel: mua?.delivery_channel,
                    api_key: mua?.api_key,
                    preparation_estimate: mua?.preparation_estimate,
                    zone: mua?.zone_fee
                      ? [...mua.zone_fee]
                          .map(
                            ele =>
                              `${ele.name} : ${
                                ele.delivery_estimate
                              } min(s) $${parseFloat(
                                ele?.delivery_fee ?? 0,
                              ).toFixed(2)}`,
                          )
                          .join(' ')
                      : '-',
                    schedule_in_advance: mua.schedule_in_advance,
                    schedule_order_when_closed: mua.schedule_order_when_closed,
                  }));

                  temp = [...temp2];

                  const totalPages = muas.total_pages;

                  const res = await Promise.all(
                    generateArray(totalPages).map(async ele =>
                      fetchMenuAudit({
                        _adminUserId: adminUserId,
                        _from: ele * 50,
                      }),
                    ),
                  );

                  res
                    .map(ele => ele?.search_place_menu_services_list ?? [])
                    .reduce((p, c) => [...p, ...c], [])
                    .map(mua => ({
                      name: mua.name,
                      service_type: mua.service_type,
                      catering: mua?.catering ?? '-',
                      payment_method: (mua?.payment_method ?? '').replaceAll(
                        ',',
                        ' ',
                      ),
                      menu_name: mua.menu_name,
                      auto_confirm: mua.auto_confirm,
                      auto_ready: mua.auto_ready,
                      auto_cancel: mua?.auto_cancel?.is_auto_cancel,
                      auto_cancel_time_in_mins: mua?.auto_cancel?.time_in_mins,
                      delivery_channel: mua?.delivery_channel,
                      api_key: mua?.api_key,
                      preparation_estimate: mua?.preparation_estimate,
                      zone: mua?.zone_fee
                        ? [...mua.zone_fee]
                            .map(
                              ele =>
                                `${ele.name} : ${
                                  ele.delivery_estimate
                                } min(s) $${parseFloat(
                                  ele?.delivery_fee ?? 0,
                                ).toFixed(2)}`,
                            )
                            .join(' ')
                        : '-',
                      schedule_in_advance: mua.schedule_in_advance,
                      schedule_order_when_closed:
                        mua.schedule_order_when_closed,
                    }))
                    .forEach(ele => {
                      temp = [...temp, ele];
                    });

                  const csvData = (() => {
                    let header = Object.keys(temp[0]).join(',');
                    header = `${header}\n`;
                    const body = temp
                      .map(ele => Object.values(ele))
                      .map(ele => ele.join(','))
                      .join('\n');
                    return `${header}${body}`;
                  })();
                  downloadCSVFile(
                    csvData,
                    `menu-audit-${new Date().toISOString()}.csv`,
                  );
                } catch (error) {
                  console.log('error on downloadCsv click function');
                  console.log(error);
                  setDownloadCsv(false);
                }
                setDownloadCsv(false);
              }}
            >
              Download CSV
            </Button>
          </div>
        </div>
      </Tabs>
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * 50}
          pageCount={pageCount}
          listSize={50}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            history.push(
              `/menu-service-audit${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            );
          }}
        />
      )}
      <MenuServiceAuditTable
        onClick={() => {
          console.log('routing to service type settings...');
        }}
        loading={loading}
        placeMenuServiceAudit={placeMenuServiceAudit}
      />
    </Layout>
  );
};

export default Index;
