import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { isNaN } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import { TextArea, CheckBox, Select } from '../../../components/elements';
import { fetchOrder, updateOrderWithRefund } from './helpers';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 2rem;
`;

const LeftSpan = styled.span``;

const RightSpan = styled.span`
  padding-left: 10px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  display: inline-block;
  position: relative;
  top: 5px;
`;

const Form = ({
  order,
  userId,
  orderId,
  setFieldValue,
  values,
  history,
  handleSubmit,
  isSubmitting,
}) => {
  console.log('userId...', userId);
  console.log('orderId...', orderId);
  console.log('values...', values);

  return (
    <Layout>
      <Header
        history={history}
        loading={isSubmitting}
        handleSubmit={handleSubmit}
      />
      <Container>
        <div>
          <LeftSpan>Place Name</LeftSpan>
          <RightSpan>{order?.place?.name}</RightSpan>
        </div>
        <div>
          <LeftSpan>Order ID</LeftSpan>
          <RightSpan>{order?.order_reference}</RightSpan>
        </div>
        <div>
          <LeftSpan>Customer</LeftSpan>
          <RightSpan>
            {order?.created_by_user?.display_name}
            <span style={{ fontSize: '12px', fontWeight: '400' }}>
              &nbsp;({order?.created_by_user?.email})
            </span>
          </RightSpan>
        </div>
        <div>
          <LeftSpan>Phone</LeftSpan>
          <RightSpan>
            {
              order?.created_by_user?.contact.find(ele => ele.type === 'mobile')
                ?.value
            }
          </RightSpan>
        </div>
        <div>
          <LeftSpan>Order Total</LeftSpan>
          <RightSpan>
            ${parseFloat(order?.billing?.order_total).toFixed(2)}
          </RightSpan>
        </div>
        <div>
          <LeftSpan>Order Amount</LeftSpan>
          <RightSpan>
            ${parseFloat(order?.billing?.order_amount).toFixed(2)}
          </RightSpan>
        </div>
        <div style={{ display: 'flex' }}>
          <LeftSpan>Refund Amount</LeftSpan>

          <div
            className="field"
            style={{ paddingLeft: '10px', position: 'relative', bottom: '5px' }}
          >
            <p className="control has-icons-left">
              <input
                className="input is-primary"
                type="number"
                placeholder="0.00"
                style={{ width: '100px' }}
                onChange={e => {
                  if (e.target.value === '') {
                    setFieldValue('value', null);
                  } else if (!isNaN(parseFloat(e.target.value))) {
                    setFieldValue('value', parseFloat(e.target.value));
                  }
                }}
              />
              <span className="icon is-small is-left">$</span>
            </p>
          </div>
        </div>
        <div>
          <LeftSpan>Previously Refunded</LeftSpan>
          <RightSpan>${parseFloat(values.previousRefund).toFixed(2)}</RightSpan>
        </div>
        <div>
          <Select
            label="Reason"
            options={[
              {
                label: 'Out of an item (or items)',
                value: 'Out of an item (or items)',
              },
            ]}
            value={[values.type]}
          />
        </div>
        <div>
          <CheckBox
            label="Refund From Restaraunt"
            name="refund_from_restaraunt"
            value={values.refund_application_fee}
            onChange={() =>
              setFieldValue(
                'refund_application_fee',
                !values.refund_application_fee,
              )
            }
          />
          <CheckBox
            label="Refund Kravein Fee"
            name="refund_from_kravein_fee"
            value={values.refund_kravein_fee}
            onChange={() =>
              setFieldValue('refund_kravein_fee', !values.refund_kravein_fee)
            }
          />
        </div>
        <div>
          <TextArea
            label="Note"
            name="Note"
            optionalText="(Min 10 and Max 8000 Characters)"
            placeholder="Add more details about this refund"
            value={values.note}
            onChange={e => setFieldValue('note', e.target.value)}
          />
        </div>
        <div />
        <div style={{ display: 'flex' }}>
          <LeftSpan>Approval Code</LeftSpan>

          <div style={{ paddingLeft: '15px' }}>
            <input
              className="input is-primary"
              type="text"
              placeholder="XXXXXXX"
              style={{ width: '100px' }}
              onChange={e => {
                setFieldValue('approvalCode', e.target.value);
              }}
              disabled
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

const extractRefunds = order => {
  if (
    order?.refunds &&
    Array.isArray(order.refunds) &&
    order.refunds.length !== 0
  ) {
    return order.refunds.reduce((prev, curr) => {
      let temp = prev;
      if (curr.refund_amount) {
        temp += parseFloat(curr.refund_amount);
      }
      return temp;
    }, 0);
  }
  return null;
};

const lastRefundAmount = order => extractRefunds(order) ?? 0;

// const lastRefundNote = order => extractRefunds(order)?.note?.type ?? null;

const Refunds = withFormik({
  mapPropsToValues: ({ userId, orderId, order }) => ({
    user_id: userId,
    menu_order_id: orderId,
    action: 'refund',
    type: {
      label: 'Out of an item (or items)',
      value: 'Out of an item (or items)',
    },
    value: null,
    note: null,
    approvalCode: null,
    refund_application_fee: true,
    refund_kravein_fee: true,
    previousRefund: lastRefundAmount(order),
  }),

  handleSubmit: async (values, { props }) => {
    const valueValidationSchema = yup.object({
      value: yup
        .number()
        .nullable()
        .required('Value is required!')
        .positive('Value must be positive!'),
    });
    const data1 = { value: values.value };

    try {
      valueValidationSchema.validateSync(data1, { abortEarly: false });
    } catch (error) {
      toast.error(error.errors[0]);
      return;
    }

    const noteValidationSchema = yup.object({
      note: yup
        .string()
        .nullable('Note is required!')
        .min(10, 'Please enter at least 10 characters')
        .max(8000, 'Please enter less than 8000 characters')
        .required('Note is required!'),
    });
    const data2 = { note: values.note };

    try {
      noteValidationSchema.validateSync(data2, { abortEarly: false });
    } catch (error) {
      toast.error(error.errors[0]);
      return;
    }

    const {
      user_id,
      menu_order_id,
      action,
      type,
      value,
      note,
      refund_application_fee,
      // approvalCode,
    } = values;

    const input = {
      user_id,
      menu_order_id,
      action,
      approval_code: '12345678',
      refund_application_fee,
      refunds: {
        type: type.value,
        value,
        note: { type: note },
      },
    };

    try {
      const res = await updateOrderWithRefund({ input });

      if (res) {
        toast.error(res);
      } else {
        swal('You have successfully updated the order with refunds').then(() =>
          props.history.goBack(),
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  },
})(Form);

const Index = ({ history, match }) => {
  // const { orderId } = match.params;
  // const { userId } = useStoreState(state => state.auth);

  // const [loading, setLoading] = React.useState(false);

  // const [order, setOrder] = React.useState(null);

  // React.useEffect(() => {
  //   const fetchFunc = async () => {
  //     setLoading(true);
  //     const o = await fetchOrder({ userId, menuOrderId: orderId });
  //     if (o) {
  //       setOrder(o);
  //     }
  //     setLoading(false);
  //   };
  //   fetchFunc();
  // }, [orderId, userId]);

  // if (!order) {
  //   return (
  //     <Layout>
  //       <Header />
  //       <span style={{ padding: '2rem', color: '#00AEEF' }}>Loading...</span>
  //     </Layout>
  //   );
  // }

  // return (
  //   <Refunds
  //     order={order}
  //     userId={userId}
  //     orderId={orderId}
  //     loading={loading}
  //     history={history}
  //   />
  // );

  return (
    <Layout>
      <Header />
      <span style={{ padding: '2rem', color: 'red' }}>Not yet released!</span>
    </Layout>
  );
};

export default Index;
