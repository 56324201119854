import React, { useState, useEffect, useRef } from 'react';
// import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { filter, sortBy } from 'lodash';

import useSound from 'use-sound';
import moment from 'moment-timezone';
import { useStoreState } from 'easy-peasy';
import uuid from 'uuid';
import swal from 'sweetalert';

import sound from '../../../assets/sound/sms-alert-5-daniel_simon.wav';
import NewOrders from './NewOrders';
import InProgress from './InProgress';
import { getReceivedOrders } from './helpers';
import { Loading } from '../../../components/elements';
import {
  totalTimeDueActiveCard,
  totalTimeDelay,
  totalTimeDueActiveCard2,
  totalDueTimeInMinsScheduleOrder,
} from './timeHelpers';
import OrderDetails from './OrderDetails';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReceivedTimeDuration = ({ item, triggerRender }) =>
  item?.received?.created_at && moment(item?.received?.created_at).fromNow();

const newOrderPopupContent = ({
  service_type,
  order_reference,
  overall_quantity,
  order_total,
}) => {
  if (window && window.document) {
    const outerDiv = document.createElement('div');
    outerDiv.style.display = 'flex';
    outerDiv.style.justifyContent = 'center';
    outerDiv.style.alignItems = 'center';

    const innerDiv = document.createElement('div');
    innerDiv.style.display = 'flex';
    innerDiv.style.justifyContent = 'space-between';

    const leftDiv = document.createElement('div');
    leftDiv.style.textAlign = 'left';

    const serviceTypeSpan = document.createElement('span');
    serviceTypeSpan.style.fontWeight = '700';
    serviceTypeSpan.style.fontSize = '16px';
    serviceTypeSpan.textContent = service_type;

    const lineBreak = document.createElement('br');

    const orderDetailsSpan = document.createElement('span');
    orderDetailsSpan.textContent = `${order_reference}, ${overall_quantity} item(s)`;

    leftDiv.appendChild(serviceTypeSpan);
    leftDiv.appendChild(lineBreak);
    leftDiv.appendChild(orderDetailsSpan);

    const orderTotalSpan = document.createElement('span');
    orderTotalSpan.style.color = '#EE2A7B';
    orderTotalSpan.textContent = `$${order_total}`;

    innerDiv.appendChild(leftDiv);
    innerDiv.appendChild(orderTotalSpan);

    outerDiv.appendChild(innerDiv);
    return outerDiv;
  }
  return null;
};

// export const totalDueTimeInMins = (estimates, receivedAt) => {

const View = ({
  isLoading,
  currentSubscriptionData,
  receivedOrders,
  handleCurrentOrder,
  currentOrder,
  setCurrentOrder,
  setReceivedOrders,
  addOrder,
  addConfirmationOrderAfterReceived,
  setConfirmedOrders,
  confirmedOrders,
  props,
  setCurrentSubscriptionData,
  isActiveOrderDetailsModalNewTab,
  setIsActiveOrderDetailsModalNewTab,
  isActiveOrderDetailsModalInProgressTab,
  setIsActiveOrderDetailsModalInProgressTab,
  currentSubscribeToMenuOrderMoveToActive,
  setCurrentSubscribeToMenuOrderMoveToActive,
}) => {
  const [showConfirmedTime, setShowConfirmedTime] = useState(null);
  const randomIdToTriggerRecalculationOfReceivedTimeDuration = useRef(() =>
    uuid(),
  );

  const [play] = useSound(sound);

  const notify2 = subscriptionData => {
    const isConfirmed = subscriptionData.status === 'CONFIRMED';

    const {
      service_type,
      overall_quantity,
      order_reference,
      billing,
    } = subscriptionData;
    const { order_total } = billing ?? {};

    if (isConfirmed) {
      setCurrentOrder(null);
      setIsActiveOrderDetailsModalNewTab(false);
      setIsActiveOrderDetailsModalInProgressTab(false);
      setConfirmedOrders([...confirmedOrders, subscriptionData]);
      play();
      swal({
        title: 'Prepare Scheduled Order!',
        text: `A scheduled order is due in ${totalDueTimeInMinsScheduleOrder(
          subscriptionData.estimates,
          subscriptionData?.received?.created_at,
        )}`,
        buttons: ['OK', 'View Detail'],
        content: newOrderPopupContent({
          service_type,
          order_reference,
          overall_quantity,
          order_total,
        }),
        closeOnClickOutside: false,
      }).then(onfulfilled => {
        if (onfulfilled) {
          setCurrentOrder(subscriptionData);
          setIsActiveOrderDetailsModalNewTab(true);
        }
        setCurrentSubscribeToMenuOrderMoveToActive();
      });
    }
  };

  // eslint-disable-next-line no-shadow
  const notify = currentSubscriptionData => {
    const {
      service_type,
      overall_quantity,
      order_reference,
      billing,
    } = currentSubscriptionData;
    const { order_total } = billing ?? {};
    if (currentSubscriptionData.status === 'RECEIVED') {
      setCurrentOrder(null);
      setIsActiveOrderDetailsModalNewTab(false);
      setIsActiveOrderDetailsModalInProgressTab(false);
      setReceivedOrders([...receivedOrders, currentSubscriptionData]);
      play();
      swal({
        title: 'You have a new Order!',
        text: 'Please review and confirm your order',
        buttons: ['OK', 'View Detail'],
        closeOnClickOutside: false,
        content: newOrderPopupContent({
          service_type,
          order_reference,
          overall_quantity,
          order_total,
        }),
      }).then(onfulfilled => {
        if (onfulfilled) {
          setCurrentOrder(currentSubscriptionData);
          setIsActiveOrderDetailsModalNewTab(true);
        }
        setCurrentSubscriptionData();
      });
    }
    if (currentSubscriptionData.status === 'CANCELRECEIVED') {
      play();
      swal('You have a cancelled Order!', 'Please review your orders', {
        buttons: 'Ok',
        closeOnClickOutside: false,
      }).then(() => setCurrentSubscriptionData());
    }
    if (currentSubscriptionData.status === 'CANCELLED') {
      play();
      swal('You have a cancelled Order!', 'Please review your orders', {
        buttons: 'Ok',
        closeOnClickOutside: false,
      }).then(() => setCurrentSubscriptionData());
    }
    if (currentSubscriptionData.status === 'CONFIRMED') {
      const newReceivedOrders =
        receivedOrders &&
        filter(
          receivedOrders,
          item => item.menu_order_id !== currentSubscriptionData.menu_order_id,
        );
      setReceivedOrders(newReceivedOrders || []);
    }
  };

  useEffect(() => {
    if (props.isActiveTab === 'new' && currentSubscriptionData) {
      notify(currentSubscriptionData);
    }
  }, [currentSubscriptionData, props.isActiveTab]);

  useEffect(() => {
    if (
      props.isActiveTab === 'new' &&
      currentSubscribeToMenuOrderMoveToActive
    ) {
      notify2(currentSubscribeToMenuOrderMoveToActive);
    }
  }, [currentSubscribeToMenuOrderMoveToActive, props.isActiveTab]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (props.isActiveTab === 'new') {
      const timer = setInterval(() => {
        randomIdToTriggerRecalculationOfReceivedTimeDuration.current = uuid();
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [props.isActiveTab]);

  const handleAfterDelayAndAdjustOrder = updateOrder => {
    const newConfirmedOrders = confirmedOrders.filter(
      item => item.menu_order_id !== updateOrder.menu_order_id,
    );
    setConfirmedOrders(
      sortBy(newConfirmedOrders.concat(updateOrder), 'audit.created_at').filter(
        ele => ele.status !== 'READY',
      ),
    );
  };

  return (
    <>
      <Container>
        {isLoading && <Loading />}
        {receivedOrders.length !== 0 &&
          receivedOrders.map(item => {
            const receivedStatusHistory = item.received;
            const receivedTime = receivedStatusHistory
              ? receivedStatusHistory.created_at
              : null;
            const due = totalTimeDueActiveCard(item.estimates, receivedTime);
            const delay = totalTimeDelay(item.estimates, receivedTime);
            const deliveryEstimateFromBackend = (
              item.estimates.find(ele => ele.type === 'delivery_estimate') ?? {}
            ).value_in_mins;

            // asap format for future use
            // const estTimeTaken = totalEstTimeTakenActiveOrder(
            //   item.estimates,
            //   receivedTime,
            // );
            // const asapFormat = estTimeTaken
            //   ? `${`ASAP (${estTimeTaken})`}`
            //   : 'ASAP';
            return (
              <NewOrders
                type="button"
                setIsActiveOrderDetailsModal={
                  setIsActiveOrderDetailsModalNewTab
                }
                handleCurrentOrder={handleCurrentOrder}
                item={item}
              >
                <Flex>
                  <div>
                    <p className="title has-text-white">
                      {item.service_type === 'Delivery' && 'Delivery'}
                      {item.service_type === 'Dinein' && item.table_number
                        ? `${item.table_name || 'Table'} ${item.table_number}`
                        : item.service_type === 'Dinein' && 'Dinein'}
                      {item.service_type === 'Pickup' && 'Pickup'}
                      {item.large_order && ` (LARGE ORDER)`}
                    </p>
                  </div>
                  <div className="field has-addons">
                    <div className="control">
                      {/* <p className="title has-text-white">NEW</p> */}
                      {item.is_scheduled ? (
                        <p className="title has-text-white">SCHD</p>
                      ) : (
                        <p className="title has-text-white">
                          {item.estimated_completion_time
                            ? moment(item.estimated_completion_time).format(
                                'llll',
                              )
                            : 'ASAP'}
                        </p>
                      )}
                    </div>
                  </div>
                </Flex>
                <Flex>
                  <div style={{ paddingTop: '8px' }}>
                    <p className="has-text-white">
                      {item.order_reference} | {item.overall_quantity}{' '}
                      {item.overall_quantity > 1 ? 'items' : 'item'} | $
                      {item.billing?.order_total?.toFixed(2)}
                    </p>
                    <p className="has-text-white" style={{ paddingTop: '8px' }}>
                      {moment(item?.audit?.created_at).format(
                        'hh:mm A DD/MM/YYYY',
                      )}{' '}
                      {item.service_type === 'Delivery' &&
                        ` | ${item.address.city}`}{' '}
                      {item.service_type === 'Dinein' &&
                        item.no_of_attendees &&
                        ` | ${item.no_of_attendees} ${
                          item.no_of_attendees > 1 ? 'Attendees' : 'Attendee'
                        }`}
                      {' | '}
                      <ReceivedTimeDuration
                        item={item}
                        triggerRender={
                          randomIdToTriggerRecalculationOfReceivedTimeDuration.current
                        }
                      />
                    </p>
                  </div>
                  {item.is_scheduled ? (
                    <div className="field has-addons">
                      <div className="control" style={{ textAlign: 'left' }}>
                        <p
                          className="has-text-white"
                          style={{ paddingTop: '8px' }}
                        >
                          {moment(item.estimated_completion_time).format(
                            'llll',
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="field has-addons">
                      <div className="control" style={{ textAlign: 'left' }}>
                        <p
                          className="has-text-white"
                          style={{ paddingTop: '8px' }}
                        >
                          {delay}
                          {due}
                        </p>
                        <p
                          className="has-text-white"
                          style={{
                            paddingTop: '8px',
                          }}
                        >
                          {deliveryEstimateFromBackend &&
                            `Delivery in ${deliveryEstimateFromBackend} mins`}
                        </p>
                      </div>
                    </div>
                  )}
                </Flex>
              </NewOrders>
            );
          })}
        {!isLoading && receivedOrders.length === 0 && (
          <>
            <div
              className="has-text-weight-semibold"
              style={{ justifyContent: 'center' }}
            >
              <div
                className="is-flex has-text-weight-semibold"
                style={{ justifyContent: 'center' }}
              >
                <p>No New Order!</p>
              </div>
              <div
                className="is-flex has-text-weight-medium"
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <p className="has-text-centered">
                  All your new orders will appear here, To stop taking orders
                  temporarily for the rest of the scheduled hours, Choose
                  &apos;Stop taking orders&apos; on Temporarily Stop / Start
                  taking orders screen
                </p>
                <br />
                <br />
                <br />
              </div>
            </div>
          </>
        )}
      </Container>
      <InProgress
        {...props}
        currentReceivedOrder={currentOrder}
        setConfirmedOrders={setConfirmedOrders}
        confirmedOrders={confirmedOrders}
        handleAfterDelayAndAdjustOrder={handleAfterDelayAndAdjustOrder}
        isActiveOrderDetailsModalInProgressTab={
          isActiveOrderDetailsModalInProgressTab
        }
        setIsActiveOrderDetailsModalInProgressTab={
          setIsActiveOrderDetailsModalInProgressTab
        }
      />
      {currentOrder && isActiveOrderDetailsModalNewTab && (
        <OrderDetails
          dueTimeToBeDisplayedInOrderDetailsModal={totalTimeDueActiveCard2(
            currentOrder.estimates,
            currentOrder?.received?.created_at,
          )}
          delayTimeToBeDisplayedInOrderDetailsModal={totalTimeDelay(
            currentOrder.estimates,
            currentOrder?.received?.created_at,
          )}
          isActiveOrderDetailsModal={isActiveOrderDetailsModalNewTab}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModalNewTab}
          setReceivedOrders={setReceivedOrders}
          setConfirmedOrders={setConfirmedOrders}
          confirmedOrders={confirmedOrders}
          receivedOrders={receivedOrders}
          addOrder={addOrder}
          addConfirmationOrderAfterReceived={addConfirmationOrderAfterReceived}
          order={currentOrder}
          showConfirmedTime={showConfirmedTime}
          setShowConfirmedTime={setShowConfirmedTime}
          handleAfterDelayOrder={handleAfterDelayAndAdjustOrder}
          handleAfterAdjustment={handleAfterDelayAndAdjustOrder}
          orderComponent="Orders"
        />
      )}
    </>
  );
};

const Active = props => {
  const [
    isActiveOrderDetailsModalNewTab,
    setIsActiveOrderDetailsModalNewTab,
  ] = useState(false);
  const [
    isActiveOrderDetailsModalInProgressTab,
    setIsActiveOrderDetailsModalInProgressTab,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [receivedOrders, setReceivedOrders] = useState([]);
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const { userId } = useStoreState(state => state.auth);

  const {
    place,
    currentSubscriptionData,
    setOrders,
    handleDecrementCount,
    setCurrentSubscriptionData,
    currentSubscribeToMenuOrderMoveToActive,
    setCurrentSubscribeToMenuOrderMoveToActive,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getReceivedOrders({
        placeId: place.place_id,
        status: 'RECEIVED',
        userId,
      });
      setIsLoading(false);
      setReceivedOrders(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setOrders(receivedOrders.length);
  }, [receivedOrders, confirmedOrders]);

  const handleCurrentOrder = id => {
    const current = filter(receivedOrders, item => item.menu_order_id === id);
    setCurrentOrder(current[0]);
  };

  const addOrder = () => {
    setConfirmedOrders([
      ...confirmedOrders,
      { ...currentOrder, status: 'CONFIRMED' },
    ]);
    handleDecrementCount();
  };

  const addConfirmationOrderAfterReceived = confirmedOrder => {
    setConfirmedOrders(confirmedOrders.concat(confirmedOrder));
    handleDecrementCount();
  };

  return (
    <View
      currentSubscribeToMenuOrderMoveToActive={
        currentSubscribeToMenuOrderMoveToActive
      }
      setCurrentSubscribeToMenuOrderMoveToActive={
        setCurrentSubscribeToMenuOrderMoveToActive
      }
      isLoading={isLoading}
      currentSubscriptionData={currentSubscriptionData}
      receivedOrders={receivedOrders}
      isActiveOrderDetailsModalNewTab={isActiveOrderDetailsModalNewTab}
      setIsActiveOrderDetailsModalNewTab={setIsActiveOrderDetailsModalNewTab}
      isActiveOrderDetailsModalInProgressTab={
        isActiveOrderDetailsModalInProgressTab
      }
      setIsActiveOrderDetailsModalInProgressTab={
        setIsActiveOrderDetailsModalInProgressTab
      }
      handleCurrentOrder={handleCurrentOrder}
      currentOrder={currentOrder}
      setCurrentOrder={setCurrentOrder}
      setReceivedOrders={setReceivedOrders}
      addOrder={addOrder}
      addConfirmationOrderAfterReceived={addConfirmationOrderAfterReceived}
      setConfirmedOrders={setConfirmedOrders}
      confirmedOrders={confirmedOrders}
      props={props}
      setCurrentSubscriptionData={setCurrentSubscriptionData}
    />
  );
};

export default Active;
