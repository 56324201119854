import React from 'react';
// import styled from 'styled-components';
// import AddEditCustomHours from '../../OrderManagement/MenuCustomHours/CustomHours/AddEditCustomHour/index';

// const Button = styled.button`
//   &&& {
//     background-color: ${({ theme }) => theme.primaryColor};
//     font-weight: 600;
//     width: 35%;
//     height: 5vh;
//   }
// `;

// const ButtonText = styled.p`
//   font-weight: 600;
//   font-size: 12px;
// `;
// {isActive && (
//     <AddEditCustomHours
//       isActive={isActive}
//       setIsActive={setIsActive}
//       activeMenu={{ name: 'Temp' }}
//     />
//   )}
//   {/* <Button className="button is-primary" onClick={() => setIsActive(true)}>
//     <ButtonText>ADD A NEW DATE</ButtonText>
//   </Button> */}

const CustomHours = () => (
  //   const [isActive, setIsActive] = React.useState(false);
  <span style={{ color: 'red' }}>Not Implemented...</span>
);
export default CustomHours;
