import React from 'react';
import styled from 'styled-components';

import LocationSuggestion from '../../../components/global/LocationSuggestion';

import { Input, Select } from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = ({
  setCityName,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  service,
  setService,
  activeTab,
}) => (
  <Wrapper>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-4">
          <Input
            label="Place Name"
            type="text"
            value={placeNameSearchInput}
            onChange={event => setPlaceNameSearchInput(event.target.value)}
            placeholder="Start entering place name..."
            disabled={activeTab === 'TAB2'}
          />
        </div>
        <div className="column is-4">
          <label className="label">City / Suburb</label>
          <div className="control">
            <LocationSuggestion
              initialValue=""
              onSelect={data => {
                setCityName(data.name);
              }}
              disabled={activeTab === 'TAB2'}
            />
          </div>
        </div>
        <div className="column is-4">
          <div className="field">
            <div className="control">
              <Select
                label="Service"
                options={[
                  { label: 'ALL', value: 'All' },
                  { label: 'PICKUP', value: 'Pickup' },
                  { label: 'DELIVERY', value: 'Delivery' },
                ]}
                value={service}
                onChange={value => setService(value)}
                disabled={activeTab === 'TAB2'}
                disableToastMessage
              />
            </div>
          </div>
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default Search;
