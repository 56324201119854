import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { first, isNull, forEach, omit } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import PlaceReviewForm from './PlaceReviewForm';
import { Loading, Message } from '../../../components/elements';

// import PlaceSelection from '../../../components/PlaceSelection';

const fetchPlaceReviewQuery = gql`
  query fetchPlaceReview($placeReviewId: String, $placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      city
      post_code
      country
      status
      claim_status
    }

    fetch_place_review(
      input: { place_id: $placeId, place_review_id: $placeReviewId }
    ) {
      place_id
      place_review_id
      content
      overall_rating
      month_visited
      status
      service_category {
        name
        description
        display_order
      }

      url {
        uri
        display_order
      }
      tag
      caption

      share {
        key
        value
      }
      place_review_audit {
        review_approved_by
        review_approved_at
        review_rejected_by
        review_rejected_at
      }
      audit {
        created_by
        created_at
      }
      error {
        code
        description
      }
    }
  }
`;

const searchPlaceReviewQuery = gql`
  query fetchPlaceReview($placeReviewId: String, $placeId: String) {
    search_image_review(
      input: {
        filter: {
          image_review_filter: {
            object_id: $placeReviewId
            parent_id: $placeId
            object_type: "PLACE_REVIEW"
            parent_type: "PLACE"
            approval_status_many: ["SUBMITTED", "APPROVED", "REJECTED"]
          }
        }
      }
    ) {
      image_review_listing {
        image_id
        image_id
        url
        tags
        approval_status
        status
      }
    }
  }
`;

const actionPlaceReviewMutation = gql`
  mutation actionPlaceReview($input: ActionPlaceReviewInput) {
    action_place_review(input: $input) {
      place_id
      place_review_id
      status
      error {
        description
      }
    }
  }
`;

const updatePlaceReviewMutation = gql`
  mutation update_place_review($input: PlaceReviewInput) {
    update_place_review(input: $input) {
      place_id
      place_review_id
      error {
        description
      }
    }
  }
`;

const approvePhotoMutation = gql`
  mutation approvePhotosAction(
    $user_id: String
    $image_id: String
    $image_review_status: String
  ) {
    action_image(
      input: {
        user_id: $user_id
        image_id: $image_id
        approval_status: $image_review_status
      }
    ) {
      status
      image_id
      url
      error {
        description
      }
    }
  }
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const handleImageApprove = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: approvePhotoMutation,
        variables: {
          ...input,
        },
      })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });

const PlaceReview = ({ match, history }) => {
  const { placeId, reviewId } = match.params;
  const [currentImages, setCurrentImages] = useState([]);
  const [imageApprovalLoading, setImageApprovalLoading] = useState(false);
  const [action, setAction] = useState('');
  const { userId } = useStoreState(state => state.auth);

  const handleImageApproveFunc = async values => {
    setImageApprovalLoading(true);
    const promises = [];
    values.map(item => {
      const approvalStatus =
        item.approval_status === 'SUBMITTED' ? action : item.approval_status;
      promises.push(
        handleImageApprove({
          user_id: userId,
          image_id: item.image_id,
          image_review_status:
            action === 'APPROVED' ? approvalStatus : 'REJECTED',
        }),
      );
      return null;
    });

    try {
      await Promise.all(promises);
      swal(
        'Hurray!',
        `Place Review ${
          action === 'APPROVED' ? 'APPROVED' : 'REJECTED'
        } successfully!`,
        'success',
      ).then(() => {
        setImageApprovalLoading(false);
        history.goBack();
      });
    } catch (error1) {
      console.log(error1);
    }
  };

  return (
    <Layout>
      <Query
        query={fetchPlaceReviewQuery}
        variables={{
          placeId,
          placeReviewId: reviewId,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Message>{error.message}</Message>;
          }

          const review = first(data.fetch_place_review);

          if (!review) {
            return <Loading />;
          }

          const place = data.fetch_place;

          return (
            <Query
              client={client.clientPrivate}
              query={searchPlaceReviewQuery}
              variables={{
                placeId,
                placeReviewId: reviewId,
              }}
              fetchPolicy="network-only"
            >
              {({ data: data2, loading: loading2, error: error2 }) => {
                if (loading2) {
                  return <Loading />;
                }
                if (error2) {
                  return <Message>{error2.message}</Message>;
                }

                const images = data2.search_image_review.image_review_listing;

                return (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={actionPlaceReviewMutation}
                    onCompleted={async ({ action_place_review }) => {
                      if (!isNull(action_place_review.error)) {
                        action_place_review.error.map(item =>
                          toast.error(item.description),
                        );
                      } else if (currentImages.length !== 0) {
                        handleImageApproveFunc(currentImages);
                      } else {
                        swal(
                          'Hurray!',
                          `Place Review ${
                            action === 'APPROVED' ? 'Approved' : 'Rejected'
                          } successfully!`,
                          'success',
                        ).then(() => {
                          history.goBack();
                        });
                      }
                    }}
                  >
                    {(action_place_review, { loading: updateLoading }) => (
                      <Mutation
                        client={client.clientPrivate}
                        mutation={updatePlaceReviewMutation}
                        onCompleted={() => {
                          swal(
                            'Hurray!',
                            `Place Review updated successfully!`,
                            'success',
                          ).then(() => history.goBack());
                        }}
                      >
                        {(
                          update_place_review,
                          {
                            loading: updateReviewLoading,
                            error: updateReviewError,
                          },
                        ) => (
                          <PlaceReviewForm
                            place={place}
                            images={images}
                            review={review}
                            updateReviewLoading={updateReviewLoading}
                            updateReviewError={updateReviewError}
                            loading={updateLoading || imageApprovalLoading}
                            action={action}
                            handleAction={setAction}
                            onSubmit={values => {
                              if (action === 'UPDATE_REVIEW') {
                                update_place_review({
                                  variables: {
                                    input: {
                                      user_id: userId,
                                      place_id: review.place_id,
                                      place_review_id: review.place_review_id,
                                      content: values.content,
                                      service_category: removeTypename(
                                        values.service_category,
                                      ),
                                    },
                                  },
                                });
                              } else {
                                setCurrentImages(values.images);
                                action_place_review({
                                  variables: {
                                    input: {
                                      user_id: userId,
                                      place_id: review.place_id,
                                      place_review_id: review.place_review_id,
                                      status: action,
                                      note: [
                                        {
                                          type: action,
                                          value: values.note,
                                          date: moment().toISOString(),
                                        },
                                      ],
                                    },
                                  },
                                });
                              }
                            }}
                          />
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default withRouter(PlaceReview);
