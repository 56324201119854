import React, { useState } from 'react';
import { Column } from 'rbx';
import { isNull } from 'lodash';
import styled from 'styled-components';

import { Select } from '../../../../components/elements';
import DiscountValueInput from './DiscountValueInput';
import PriceInput from './PriceInput';

const Container = styled(Column.Group)`
  border-bottom: 1px solid #b0b0b0;
`;

const calculate = (price, discountValue, discountType) => {
  if (
    isNull(price) ||
    isNull(discountValue) ||
    discountValue === 0 ||
    discountValue === '' ||
    discountValue === '0'
  ) {
    return { new_price: price, savings: 0 };
  }
  if (discountType === 'PRICE') {
    return {
      new_price: parseFloat(price - discountValue).toFixed(2),
      savings: parseFloat(discountValue).toFixed(2),
    };
  }
  return {
    new_price: parseFloat(price - (discountValue * price) / 100).toFixed(2),
    savings: parseFloat((discountValue * price) / 100).toFixed(2),
  };
};

const ItemWithVariant = ({
  variantAtRootLevel,
  itemVariant,
  itemVariantIndex,
  setItemLink,
}) => {
  const discountTypeOptions = [
    { value: 'PERCENTAGE', label: '% rate' },
    { value: 'PRICE', label: 'flat rate' },
  ];

  const [discountType, setDiscountType] = useState(
    itemVariant.type
      ? discountTypeOptions.find(ele => ele.value === itemVariant.type)
      : discountTypeOptions[0],
  );
  const [discountValue, setDiscountValue] = useState(itemVariant.value || 0);

  return (
    <>
      <Container>
        <Column size={2}>{itemVariant.variant_name}</Column>
        <Column size={10} style={{ fontSize: '13px' }}>
          <Column.Group>
            <Column size={2}>
              <PriceInput value={itemVariant.variant_price || 0} />
            </Column>
            <Column size={2} style={{ paddingTop: '8px' }}>
              <Select
                value={discountType}
                options={discountTypeOptions}
                onChange={value => {
                  setDiscountType(value);
                  const { new_price, savings } = calculate(
                    itemVariant.variant_price,
                    discountValue,
                    value.value,
                  );
                  setItemLink(prev => {
                    const modifiedVariantAtRootLevel = [...variantAtRootLevel];
                    const modifiedItemVariant = {
                      ...itemVariant,
                      type: value.value,
                      new_price,
                      savings,
                    };
                    modifiedVariantAtRootLevel[0].variants[
                      itemVariantIndex
                    ] = modifiedItemVariant;
                    return { ...prev, variant: modifiedVariantAtRootLevel };
                  });
                }}
                disabled={isNull(itemVariant.variant_price)}
              />
            </Column>
            <Column size={2}>
              <DiscountValueInput
                value={discountValue}
                discountType={discountType?.value}
                price={itemVariant.variant_price}
                onChange={e => {
                  setDiscountValue(e.target.value);
                  const { new_price, savings } = calculate(
                    itemVariant.variant_price,
                    e.target.value,
                    discountType?.value,
                  );
                  setItemLink(prev => {
                    const modifiedVariantAtRootLevel = [...variantAtRootLevel];
                    const modifiedItemVariant = {
                      ...itemVariant,
                      value: parseFloat(e.target.value || 0),
                      new_price,
                      savings,
                    };
                    modifiedVariantAtRootLevel[0].variants[
                      itemVariantIndex
                    ] = modifiedItemVariant;
                    return { ...prev, variant: modifiedVariantAtRootLevel };
                  });
                }}
                disabled={isNull(itemVariant.variant_price)}
              />
            </Column>
            <Column size={2}>
              <PriceInput value={itemVariant.new_price} />
            </Column>
            <Column size={2}>
              <PriceInput value={itemVariant.savings} />
            </Column>
          </Column.Group>
        </Column>
      </Container>
    </>
  );
};

export default ItemWithVariant;
