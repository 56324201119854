import React from 'react';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchTableBookingsQuery = gql`
  query searchTableBookingDataQuery($input: SearchInput) {
    search_table_booking_data(input: $input) {
      total_pages
      total_size
      table_booking_listing_data {
        user_id
        table_booking_id
        place_name
        city
        booking_date
        booking_time
        guest_count
        first_name
        last_name
        contact_phone
        contact_email
        status
        booking_status
        booking_reference
        created_by
      }
    }
  }
`;

export const searchTableBookings = async ({
  adminUserId,
  placeId,
  userId = null,
  bookingDate = null,
  bookingStatus = null,
  tableBookingId = null,
  guestStatus = null,
  dateFilter = null,
  tags = null,
  from,
  size,
}) => {
  const input = {
    user_id: adminUserId,
    size,
    from,
    filter: {
      place_filter: {
        place_id: placeId,
      },
      table_booking_filter: {},
    },
  };

  if (tags) {
    input.filter.table_booking_filter.tags = tags;
  }

  if (dateFilter) {
    input.filter.table_booking_filter.booking_date = dateFilter;
  }

  if (userId) {
    input.filter.table_booking_filter.user_id = userId;
  }

  if (bookingDate) {
    input.filter.table_booking_filter.booking_date = bookingDate;
  }

  if (bookingStatus) {
    input.filter.table_booking_filter.booking_status = bookingStatus;
  }

  if (tableBookingId) {
    input.filter.table_booking_filter.table_booking_id = tableBookingId;
  }

  if (guestStatus) {
    input.filter.table_booking_filter.guest_status = guestStatus;
  }

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTableBookingsQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_booking_data &&
      Array.isArray(
        data.data.search_table_booking_data?.table_booking_listing_data,
      ) &&
      data.data.search_table_booking_data?.table_booking_listing_data.length !==
        0
    ) {
      return data.data.search_table_booking_data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useTableBookingsData = ({
  adminUserId,
  placeId,
  userId = null,
  bookingDate = null,
  bookingStatus = null,
  tableBookingId = null,
  guestStatus = null,
  dateFilter = null,
  from = 0,
  size = 10,
  tags = null,
}) => {
  console.log('tag', tags);
  const [loading, setLoading] = React.useState(false);
  const [tableBookings, setTableBookings] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState();

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      setTableBookings([]);
      // setPageCount(0);
      // setTotalElements();
      if (adminUserId && placeId) {
        const res = await searchTableBookings({
          adminUserId,
          userId,
          placeId,
          bookingDate,
          bookingStatus,
          tableBookingId,
          guestStatus,
          dateFilter,
          from,
          size,
          tags,
        });

        if (res) {
          setTableBookings(res.table_booking_listing_data);
          setPageCount(res.total_pages);
          setTotalElements(res.total_size);
        } else {
          // pass
        }
      } else {
        // pass
      }

      setLoading(false);
    };
    fetchFunc();
  }, [
    userId,
    placeId,
    bookingDate,
    bookingStatus,
    tableBookingId,
    guestStatus,
    dateFilter,
    from,
    size,
  ]);

  return [loading, tableBookings, pageCount, totalElements];
};
const searchTableBookingDetailsQuery = gql`
  query searchTableBookingDetailsQuery($input: SearchInput) {
    search_table_booking(input: $input) {
      total_size
      table_booking_listing {
        user_id
        tags
        service_type_setting_id
        place_id
        platform
        guest_count
        guest_notes
        table_booking_id
        booking_date
        booking_time
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        service_type
        booking_reference
        booking_status
        guest_status
        image_url
        table_number
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        cancelled {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_data {
            email
            first_name
            last_name
          }
          user_type
        }
        confirmed {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_data {
            email
            first_name
            last_name
          }
          user_type
        }
        contact {
          contact_email
          contact_phone
          first_name
          last_name
        }
        received {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_type
          user_data {
            email
            first_name
            last_name
          }
        }
        waitlisted {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_data {
            email
            first_name
            last_name
          }
          user_type
        }
      }
    }
  }
`;

export const getTableBookingsDetails = async ({
  adminUserId,
  tableBookingId,
}) => {
  const input = {
    user_id: adminUserId,
    filter: {
      table_booking_filter: {
        table_booking_id: tableBookingId,
      },
    },
  };

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTableBookingDetailsQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_booking &&
      Array.isArray(data.data.search_table_booking?.table_booking_listing) &&
      data.data.search_table_booking?.table_booking_listing.length !== 0
    ) {
      return data.data.search_table_booking.table_booking_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useTableBookingsDetails = ({
  adminUserId,
  tableBookingId,
  reloadTableBookingData,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [tableBookingDetails, setTableBookingDetails] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      setTableBookingDetails(null);
      if (adminUserId && tableBookingId) {
        const res = await getTableBookingsDetails({
          adminUserId,
          tableBookingId,
        });
        if (res) {
          setTableBookingDetails(res);
        } else {
          // pass
        }
      } else {
        // pass
      }
      setLoading(false);
    };

    fetchFunc();
  }, [adminUserId, tableBookingId, reloadTableBookingData]);

  return [
    loading,
    tableBookingDetails,
    async () => {
      setLoading(true);
      setTableBookingDetails(null);
      if (adminUserId && tableBookingId) {
        const res = await getTableBookingsDetails({
          adminUserId,
          tableBookingId,
        });
        if (res) {
          setTableBookingDetails(res);
        } else {
          // pass
        }
      } else {
        // pass
      }
      setLoading(false);
    },
  ];
};

const cancellTableBookingMutation = gql`
  mutation cancellTableBookingMutation(
    $tableBookingId: String
    $userId: String
  ) {
    update_table_booking(
      input: {
        booking_status: "CANCELLED"
        table_booking_id: $tableBookingId
        user_id: $userId
      }
    ) {
      error {
        code
        description
      }
      cancelled {
        created_at
        created_by
        note {
          date
          type
          value
        }
        status
        user_type
        user_data {
          email
          first_name
          last_name
        }
      }
    }
  }
`;

export const cancellTableBooking = async ({ adminUserId, tableBookingId }) => {
  try {
    const data = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: cancellTableBookingMutation,
      variables: {
        userId: adminUserId,
        tableBookingId,
      },
    });

    if (
      data &&
      data?.data?.update_table_booking &&
      data.data.update_table_booking.error
    ) {
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const useCancellTableBooking = ({ adminUserId, tableBookingId }) => {
  const [loading, setLoading] = React.useState(false);
  const mutateFnc = async () => {
    setLoading(true);
    const res = await cancellTableBooking({ adminUserId, tableBookingId });
    setLoading(false);
    return res;
  };
  return [loading, mutateFnc];
};

export default {};
