import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import { withFormik } from 'formik';

import Hours from './HourFieldForm';
import {
  menuHourSorter,
  placeBusinessHoursHandler,
} from '../../../../utils/helpers';
import client from '../../../../utils/apolloClient';
import {
  defaultHours,
  addIdInputHours,
  parseFinalInputHours,
} from '../../../MenuManagement/Menu/helper';

const updateMenuMutation = gql`
  mutation updateMenu($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const MainForm = ({
  userId,
  values,
  menu,
  place,
  isActiveHourModal,
  onClose,
  fetchMenus,
  setFieldValue,
}) => (
  <Mutation
    client={client.clientPrivate}
    mutation={updateMenuMutation}
    onCompleted={({ update_menu }) => {
      if (!isNull(update_menu[0].error)) {
        update_menu[0].error.map(item => toast.error(item.description));
      } else {
        fetchMenus();
        onClose();
      }
    }}
  >
    {(update_menu, { loading: updateLoading }) => (
      <>
        {console.log('menu', menu)}
        <Hours
          menu={menu}
          values={values}
          isHolidayHourComp
          loading={updateLoading}
          isActive={isActiveHourModal}
          setFieldValue={setFieldValue}
          onClose={onClose}
          onSubmit={() => {
            update_menu({
              variables: {
                input: [
                  {
                    user_id: userId,
                    place_id: place.place_id,
                    menu_id: menu.menu_id,
                    menu_hour: menuHourSorter(
                      parseFinalInputHours(values.hours),
                    ),
                  },
                ],
              },
            });
          }}
        />
      </>
    )}
  </Mutation>
);

const Form = withFormik({
  mapPropsToValues: ({ menu }) => ({
    hours:
      menu && !isNull(menu) && menu.menu_hour.length !== 0
        ? placeBusinessHoursHandler(addIdInputHours(menu.menu_hour))
        : defaultHours,
  }),
})(MainForm);

export default Form;
