import React from 'react';
import styled from 'styled-components';
import Loading from 'react-loading';
import { useStoreState } from 'easy-peasy';

import { Table } from '../../../components/elements';
import { getOrders } from './helpers';
import OrderDetails from '../Order/OrderDetails';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const Center2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const transformOrderData = order => {
  if (
    order &&
    Array.isArray(order.menu_order_listing) &&
    order.menu_order_listing[0]
  ) {
    return order.menu_order_listing[0];
  }
  return null;
};

const OrderDetailsTable = ({
  loading,
  userOrderDetailsData,
  canLoadMoreData,
  loadMoreData,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [orderDataIsLoading, setOrderDataIsLoading] = React.useState(false);
  const [menuOrderId, setMenuOrderId] = React.useState({ id: null });
  const [orderData, setOrderData] = React.useState(null);
  const [
    isActiveOrderDetailsModal,
    setIsActiveOrderDetailsModal,
  ] = React.useState(false);

  React.useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      setOrderDataIsLoading(true);
      const data = await getOrders({
        from: 0,
        size: 50,
        user_id: userId,
        filter: {
          menu_order_filter: {
            menu_order_id: menuOrderId.id,
          },
        },
      });
      const fo = transformOrderData(data);
      if (!ignore && fo) {
        setOrderData(fo);
        setIsActiveOrderDetailsModal(true);
      }
      setOrderDataIsLoading(false);
    };
    if (menuOrderId.id) {
      fetchData();
    }
    return () => {
      console.log(
        'unmounting <or> ignoring previous graphql call to search_menu_order...',
      );
      ignore = true;
    };
  }, [menuOrderId]);
  return (
    <>
      <div style={{ padding: '10px', position: 'relative' }}>
        {orderDataIsLoading && (
          <Center2>
            <Loading type="spin" color="#363636" width="150px" height="150px" />
          </Center2>
        )}
        <h1 style={{ display: 'none' }}>Order Details</h1>

        <Table id="order-details-table">
          <thead>
            <tr>
              {[
                'Order Ref',
                'Status',
                'Customer',
                'Email',
                'Phone',
                'Device Info',
                'Place Name',
                'Date',
                'Service',
                'Promo',
                'Amount',
                'Refund',
                'Method',
                // 'Actions',
              ].map(headerCellValue => (
                <th key={headerCellValue} style={{ fontWeight: 700 }}>
                  <div>
                    <span>{headerCellValue}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          {userOrderDetailsData.length !== 0 && (
            <tbody>
              {userOrderDetailsData.map(detail => (
                <tr style={{ cursor: 'pointer' }} key={detail['Order Ref']}>
                  <td style={{ color: '#00AEEF', whiteSpace: 'nowrap' }}>
                    <span
                      onClick={() => {
                        setMenuOrderId({ id: detail.menu_order_id });
                      }}
                    >
                      {detail['Order Ref']}
                    </span>
                  </td>
                  <td>{detail.Status}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{detail.Customer}</td>
                  <td>{detail.Email}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{detail.Phone}</td>
                  <td>{detail['Device Info']}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {detail['Place Name']}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>{detail.Date}</td>
                  <td>{detail.Service}</td>
                  <td>{detail.Promo}</td>
                  <td>{detail.Amount}</td>
                  <td>{detail.Refund}</td>
                  <td>{detail.Method}</td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {loading && (
          <Center>
            <Loading type="spin" color="#363636" width="60px" height="60px" />
          </Center>
        )}
        {canLoadMoreData && (
          <PaginationContainer>
            <ShowMoreButton type="button" onClick={loadMoreData}>
              <span>Show More</span>
            </ShowMoreButton>
          </PaginationContainer>
        )}
      </div>
      {isActiveOrderDetailsModal && (
        <OrderDetails
          dueTimeToBeDisplayedInOrderDetailsModal={null}
          delayTimeToBeDisplayedInOrderDetailsModal={null}
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          orderComponent="OrderDashboard"
          order={orderData}
          refetchData={() => {}}
        />
      )}
    </>
  );
};

export default OrderDetailsTable;
