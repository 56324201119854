import React from 'react';
import TimePicker from 'react-times';
import styled from 'styled-components';
import 'react-times/css/classic/default.css';

const Wrapper = styled.div`
  .preview_container {
    width: 80%;
    margin-left: 10%;
  }
`;

const MyTimePicker = props => (
  <Wrapper>
    <TimePicker
      timeMode="12"
      time={props.time}
      minuteStep={15}
      timeConfig={{ step: 15, unit: 'minutes' }}
      theme="classic"
      disabled={props.disabled}
      onTimeChange={props.onTimeChange}
    />
  </Wrapper>
);

export default MyTimePicker;
