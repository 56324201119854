import gql from 'graphql-tag';
// import format from 'date-fns/format';
import client from '../../../utils/apolloClient';

const searchFoodOrdersQuery = gql`
  query searchFoodOrdersQuery($input: SearchInput) {
    search_food_orders(input: $input) {
      place_and_service_type_listing {
        place {
          place_id
          service_type {
            published_menu_count
            name
          }
        }
        service_type_setting {
          service_type
        }
      }
    }
  }
`;

const searchMenuQuery = gql`
  query searchMenuQuery($serviceType: String, $placeId: String) {
    search_menu(
      input: {
        filter: {
          menu_filter: {
            publish_menu: true
            status: "ACTIVE"
            service_type: $serviceType
            place_id: [$placeId]
          }
        }
      }
    ) {
      menu_listing {
        name
        hours
      }
    }
  }
`;

export const getMenusObj = async ({ serviceType, slug }) => {
  try {
    const data = await client.clientPublic.query({
      client: client.clientPublic,
      query: searchFoodOrdersQuery,
      variables: {
        input: {
          filter: { place_filter: { service_type: serviceType, slug } },
        },
      },
      fetchPolicy: 'network-only',
    });
    if (
      data &&
      data.data.search_food_orders &&
      data.data.search_food_orders.place_and_service_type_listing &&
      data.data.search_food_orders.place_and_service_type_listing[0] &&
      data.data.search_food_orders.place_and_service_type_listing[0].place
    ) {
      const id =
        data.data.search_food_orders.place_and_service_type_listing[0].place
          .place_id;

      const data2 = await client.clientPublic.query({
        client: client.clientPublic,
        query: searchMenuQuery,
        variables: {
          placeId: id,
          serviceType,
        },
        fetchPolicy: 'network-only',
      });
      if (data2?.data?.search_menu?.menu_listing) {
        return data2?.data?.search_menu?.menu_listing.reduce((p, c) => {
          const t = { ...p };
          t[c.name] = c.hours === null ? 'CLOSED' : c.hours;
          return t;
        }, {});
      }
    }
    return {};
  } catch {
    return {};
  }
};

const pollFoodOrdersQuery = async ({ serviceType, slug }) => {
  try {
    const data = await client.clientPublic.query({
      client: client.clientPublic,
      query: searchFoodOrdersQuery,
      variables: {
        input: {
          filter: { place_filter: { service_type: serviceType, slug } },
        },
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data.data.search_food_orders &&
      data.data.search_food_orders.place_and_service_type_listing &&
      data.data.search_food_orders.place_and_service_type_listing[0]
    ) {
      const temp =
        data.data.search_food_orders.place_and_service_type_listing[0];
      const tServiceType = temp.service_type_setting.service_type;
      const { published_menu_count } = temp.place.service_type.find(
        ele => ele.name === tServiceType,
      );
      return (
        published_menu_count >= 1 ||
        published_menu_count === null ||
        published_menu_count === undefined
      );
    }
    return false;
  } catch {
    return false;
  }
};

const parseUrl = url =>
  new URL(url).pathname.split('/').filter(ele => !!ele && ele !== 'order-food');

export { pollFoodOrdersQuery, parseUrl };

export default {};
