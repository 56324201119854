import React, { useState } from 'react';
import { Column, Title } from 'rbx';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';
import uuid from 'uuid';
import randomColor from 'randomcolor';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import client from '../../../utils/apolloClient';

import { Input, Select, TextArea } from '../../../components/elements';

import Item from './Item';
import AddItemModal from './AddItemModal';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
  }
`;

const checkItemStatusForDictionary = values => {
  const activeItems = values.filter(item => item.status === 'ACTIVE');
  const dictionaryStatus = activeItems.length === 0;
  return dictionaryStatus;
};

const createItemMutation = gql`
  mutation createItem($input: ItemInput) {
    create_item(input: $input) {
      item_id
      place_id
      item_dictionary_id
      place_name
      status
      name
      slug
      city
      state
      error {
        description
      }
    }
  }
`;

const messageText =
  // eslint-disable-next-line max-len
  'Item dictionary name cannot be inactivated if any item name for a place is active. To inactive an item dictionary name, all item names for a place in the item dictionary name should be inactivated first ';

const ItemDictionaryDetails = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  loading,
  handleSubmit,
  setValues,
}) => {
  const [isActiveAddItemModal, setIsActiveAddItemModal] = useState(false);
  const { userId } = useStoreState(state => state.auth);

  return (
    <React.Fragment>
      <Mutation
        client={client.clientPrivate}
        mutation={createItemMutation}
        onCompleted={({ create_item }) => {
          if (!isNull(create_item.error)) {
            create_item.error.map(item => toast.error(item.description));
          } else {
            setIsActiveAddItemModal(false);
            setValues({ ...values, items: values.items.concat(create_item) });
            toast.success('New item added successfully!');
          }
        }}
      >
        {(create_item, { loading: createItemLoading }) => (
          <AddItemModal
            loading={createItemLoading}
            isActive={isActiveAddItemModal}
            onClose={() => setIsActiveAddItemModal(false)}
            itemName={values.name}
            dictionaryStatus={values.status}
            onSubmit={item => {
              create_item({
                variables: {
                  input: {
                    user_id: userId,
                    item_id: uuid(),
                    name: values.name,
                    place_id: item.place.place_id,
                    status: item.status,
                    color_code: randomColor({
                      luminosity: 'dark',
                    }),
                    item_dictionary_id: values.item_dictionary_id,
                  },
                },
              });
            }}
          />
        )}
      </Mutation>

      <Column.Group vcentered multiline gapSize={8}>
        <Column size={5}>
          <Input
            label="Item Dictionary Name"
            value={values.name}
            optionalText="(Max 50 Characters)"
            required
            name="name"
            onChange={handleChange}
            onBlur={() => handleSubmit()}
            errors={errors.name}
            touched={touched.name}
            disabled={values.items !== 0}
          />
        </Column>

        <Column size={5}>
          <Select
            label="Item Dictionary Status"
            options={status}
            name="status"
            value={[{ value: values.status, label: values.status }]}
            onChange={async ({ value }) => {
              if (value === 'ACTIVE') {
                await setFieldValue('status', value);
                handleSubmit();
              } else if (!checkItemStatusForDictionary(values.items)) {
                swal({
                  title: 'Oops!',
                  // eslint-disable-next-line max-len
                  text: messageText,
                  dangerMode: false,
                });
              } else {
                await setFieldValue('status', value);
                handleSubmit();
              }
            }}
            errors={errors.status}
            touched={touched.status}
            required
            // disabled={!checkItemStatusForDictionary(values.items)}
            isLoading={loading}
          />
        </Column>
        <Column size={10}>
          <TextArea
            label="Item Dictionary Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={() => handleSubmit()}
            optionalText="(Max 1200 Characters)"
            errors={errors.description}
            touched={touched.description}
          />
        </Column>
        <Column size={3}>
          <button
            className="button is-primary"
            onClick={() => setIsActiveAddItemModal(true)}
          >
            <span>Add this item to a place</span>
          </button>
        </Column>
      </Column.Group>

      <hr />
      <Title>Item Name to Item Dictionary assignment</Title>
      <Container>
        <FieldArray
          name="items"
          render={() => (
            <React.Fragment>
              {values.items.map((item, index) => (
                <Item
                  item={item}
                  index={index}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                />
              ))}
            </React.Fragment>
          )}
        />
      </Container>
    </React.Fragment>
  );
};

export default ItemDictionaryDetails;
