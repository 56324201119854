import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';

import { isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';

import { toast } from 'react-toastify';
import { Loading, Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import ItemDictionaryActivity from './ItemDictionaryActivity';
import client from '../../../utils/apolloClient';

const fetchItemDictionary = gql`
  query fetchItemDictionary($input: NavigateItemDictionaryInput) {
    fetch_item_dictionary(input: $input) {
      item_dictionary_id
      name
      description
      status
    }
  }
`;

const searchItems = gql`
  query searchItems($input: SearchInput) {
    search_items(input: $input) {
      total_pages
      total_size
      item_listing {
        item_id
        place_id
        item_dictionary_id
        place_name
        status
        name
        slug
        city
        state
        approval_status
        review_submitted
        review_approved
        review_rejected
      }
    }
  }
`;

const updateMutation = gql`
  mutation updateItemDictionary($input: ItemDictionaryInput) {
    update_item_dictionary(input: $input) {
      item_dictionary_id
      name
      description
      status
      error {
        description
      }
    }
  }
`;

const ItemDictionaryUpdate = ({ match }) => {
  const { itemDictionaryId } = match.params;
  const itemFilter = {
    item_dictionary_id: itemDictionaryId,
    // approval_status: 'APPROVED',
  };

  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <Query
        query={fetchItemDictionary}
        variables={{ input: { item_dictionary_id: itemDictionaryId } }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }

          if (error) {
            return <Message>error</Message>;
          }

          const { fetch_item_dictionary } = data;

          return (
            <Query
              query={searchItems}
              variables={{
                input: {
                  filter: {
                    item_filter: itemFilter,
                  },
                },
              }}
              fetchPolicy="network-only"
            >
              {({ data: ItemData, loading: itemLoading, error: itemError }) => {
                if (itemLoading) {
                  return <Loading />;
                }
                if (itemError) {
                  return <Message>{error.message}</Message>;
                }
                const {
                  search_items: { item_listing },
                } = ItemData;

                return (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={updateMutation}
                    onCompleted={({ update_item_dictionary }) => {
                      if (!isNull(update_item_dictionary.error)) {
                        update_item_dictionary.error.map(item =>
                          toast.error(item.description),
                        );
                      } else {
                        toast.success(
                          'Item dictionary has been updated successfully!',
                        );
                      }
                    }}
                  >
                    {(
                      update_item_dictionary,
                      {
                        loading: itemDictionaryLoading,
                        error: itemDictionaryError,
                      },
                    ) => (
                      <ItemDictionaryActivity
                        loading={itemDictionaryLoading}
                        error={itemDictionaryError}
                        itemDictionary={fetch_item_dictionary}
                        items={item_listing}
                        onSubmit={value => {
                          update_item_dictionary({
                            variables: {
                              input: {
                                user_id: userId,
                                item_dictionary_id:
                                  fetch_item_dictionary.item_dictionary_id,
                                name: value.name,
                                description: value.description,
                                status: value.status,
                              },
                            },
                          });
                        }}
                      />
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default ItemDictionaryUpdate;
