import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import qs from 'querystringify';
import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';
import Header from './Header';

import ListItem from './ListItem';
import {
  Loading,
  Table,
  Box,
  ErrorMessage,
  Pagination,
  AesDes,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';
import Search from './Search';

const searchTableSettingQuery = gql`
  query searchTableSettingQuery($input: SearchInput) {
    search_table_setting(input: $input) {
      table_setting_listing {
        place_id
        place_name
        error {
          code
          description
        }
        service_type
      }
      total_pages
      total_size
    }
  }
`;

const listSize = 20;
const TableBookingSettingsListings = ({ location, history }) => {
  const {
    auth: { userId },
    global: { currentRoute },
  } = useStoreState(state => state);

  const routeState = qs.parse(location.search);
  const {
    placeId,
    // serviceType,
    page,
    publish,
  } = routeState;

  const table_setting_filter = {
    // service_type: serviceType,
    publish_booking: publish ? publish === 'ON' : true,
  };

  if (placeId) {
    table_setting_filter.place_id = placeId;
  }
  // const serviceTypeFilter = serviceType || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const [pageCount, setPageCount] = useState(0);

  const [totalElements, setTotalElements] = useState();

  const [sort, setSort] = useState('UPDATED_DESC');

  const input = { user_id: userId };
  Object.assign(input, { filter: { table_setting_filter } });
  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
  );

  return (
    <Layout>
      <Header />
      <Search
        // serviceType={serviceTypeFilter}
        routeState={routeState}
        history={history}
        publish={publish || 'ON'}
        defaultPlace={null}
        placeId={placeId}
      />
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * listSize}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value =>
            history.push(
              `/table-booking-setting-listing${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            )
          }
        />
      )}
      <Box>
        <Table>
          <thead>
            <tr>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PLACE_NAME_ASC"
                  des="PLACE_NAME_DESC"
                />
                Place Name
              </th>
              <th>Service Type</th>

              <th>Updated by</th>
              <th>Updated at</th>

              <th>
                <div>
                  <span>Action</span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={searchTableSettingQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <ErrorMessage message={error.message} />;
              }

              return (
                <>
                  <View
                    data={data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                    routeType={currentRoute?.sub}
                  />
                </>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements }) => {
  const { search_table_setting } = data;

  useEffect(() => {
    setPageCount(search_table_setting.total_pages);
    setTotalElements(search_table_setting.total_size);
  }, [search_table_setting.total_pages]);

  if (search_table_setting === null) {
    return 'No record found';
  }

  if (
    !search_table_setting.table_setting_listing ||
    search_table_setting.table_setting_listing.length === 0
  ) {
    return 'No record found';
  }
  return (
    <tbody>
      {search_table_setting.table_setting_listing.map(tableSetting => (
        <tr key={tableSetting.place_id}>
          <ListItem tableSetting={tableSetting} />
        </tr>
      ))}
    </tbody>
  );
};

export default TableBookingSettingsListings;
