import React from 'react';
import { capitalize, first, isNull } from 'lodash';

const BookingByTicketList = ({ tickets }) => {
  const aggregationTicketType = tickets.ticket_record_aggregation.ticket_type;

  return (
    <>
      {aggregationTicketType.map(ticket => {
        // Fee Filter
        const onlinePassOnFeeArray =
          ticket.fee && !isNull(ticket.fee)
            ? ticket.fee.filter(item => item.name === 'online_passon_fee')
            : [];

        const offlinePassOnFeeArray =
          ticket.fee && !isNull(ticket.fee)
            ? ticket.fee.filter(item => item.name === 'offline_passon_fee')
            : [];

        const onlineAbsorbFeeArray =
          ticket.fee && !isNull(ticket.fee)
            ? ticket.fee.filter(item => item.name === 'online_absorb_fee')
            : [];

        const offlineAbsorbFeeArray =
          ticket.fee && !isNull(ticket.fee)
            ? ticket.fee.filter(item => item.name === 'offline_absorb_fee')
            : [];
        // Fee Filter

        // Fee Values
        const onlinePassFee =
          onlinePassOnFeeArray.length !== 0
            ? first(onlinePassOnFeeArray).value
            : 0;

        const offlinePassOnFee =
          offlinePassOnFeeArray.length !== 0
            ? first(offlinePassOnFeeArray).value
            : 0;

        const onlineAbsorbFee =
          onlineAbsorbFeeArray.length !== 0
            ? first(onlineAbsorbFeeArray).value
            : 0;

        const offlineAbsorbFee =
          offlineAbsorbFeeArray.length !== 0
            ? first(offlineAbsorbFeeArray).value
            : 0;
        // Fee Values

        return (
          <tr>
            <td>{ticket.ticket_name}</td>
            <td>{ticket.quantity}</td>
            <td>$ {parseFloat(ticket.price).toFixed(2)}</td>
            <td>$ {parseFloat(onlinePassFee).toFixed(2)}</td>
            <td>$ {parseFloat(onlineAbsorbFee).toFixed(2)}</td>
            <td>$ {parseFloat(offlinePassOnFee).toFixed(2)}</td>
            <td>$ {parseFloat(offlineAbsorbFee).toFixed(2)}</td>
            <td>{capitalize(ticket.ticket_type)}</td>
          </tr>
        );
      })}
    </>
  );
};

export default BookingByTicketList;
