/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import client from '../../../../../utils/apolloClient';

const updateMenuItemMutation = gql`
  mutation updateMenuItem($input: MenuItemInput) {
    update_menu_item(input: $input) {
      menu_item_id
      error {
        description
      }
    }
  }
`;

export const updateMenuItem = async ({
  userId,
  menuItemId,
  placeId,
  imageUrl,
}) => {
  const input = {
    user_id: userId,
    menu_item_id: menuItemId,
    place_id: placeId,
    image_url: imageUrl,
  };
  await client.clientPrivate.mutate({
    client: client.clientPrivate,
    mutation: updateMenuItemMutation,
    variables: {
      input,
    },
  });
};
