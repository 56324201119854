import React from 'react';
import styled from 'styled-components';
import { Level } from 'rbx';
import { omit } from 'lodash';
import qs from 'querystringify';
import { withRouter } from 'react-router-dom';
import { Input, Select } from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const options = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const Search = ({ name, status, history, routeState }) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/item-dictionaries${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };
  return (
    <Wrapper>
      <Level />
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-half">
            <Input
              label="Item Dictionary Name"
              type="text"
              value={name}
              onChange={place => {
                handlePush({ name: place.target.value }, 'name');
              }}
              placeholder="Start entering item dictionary name..."
            />
          </div>

          <div className="column is-half">
            <Select
              label="Status"
              options={options}
              value={[{ value: status, label: status }]}
              onChange={({ value }) => handlePush({ status: value }, 'name')}
              placeholder="Select a Status..."
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
