/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import ItemOverrideModal from '../ItemOverrideModal';
import { updateItemLink, getItemDetailsFormData } from '../helpers';
import OverrideIcon from '../OverrideIcon';

const kraveinLightGreyTheta = '#eeeeee';
const kraveinPrimaryColor = '#00aeef';
const kraveinDark = '#0D0D0D';

const MainContainer = styled.div`
  width: 320px;
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  font-weight: ${({ fontWeight }) => fontWeight ?? '600'};
  padding: 4px;
  border-left: 1px solid
    ${({ borderColor }) => borderColor ?? kraveinLightGreyTheta};
`;

const DistributeSpaceBetween = styled.p`
  display: flex;
  justify-content: space-between;
`;

const LeftSpan = styled.span`
  display: inline-block;
  color: ${({ color }) => color ?? kraveinDark};
`;

const RightSpan = styled.span`
  color: ${({ color }) => color ?? kraveinPrimaryColor};
  padding-right: 6px;
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
`;

const MiddleSpan = styled.span`
  color: #ee2a7b;
  padding-right: 6px;
  font-weight: ${({ fontWeight }) => fontWeight ?? '400'};
`;

const HorizontalLineInItemDetailsForm = styled.div`
  width: 100%;
  height: 0px;
  border-bottom: 0.1px solid
    ${({ borderColor }) => borderColor ?? kraveinLightGreyTheta};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const VariantOrModiferPrompt = ({
  prompt,
  isActive,
  setIsActive,
  showOverride,
  subText,
  onClick,
}) => {
  return (
    <DistributeSpaceBetween
      style={{ paddingTop: '18px', paddingBottom: isActive ? '9px' : '18px' }}
    >
      <LeftSpan>
        <FlexRow>
          {isActive ? (
            <MdKeyboardArrowDown
              style={{ cursor: 'pointer' }}
              onClick={() => setIsActive(false)}
            />
          ) : (
            <MdKeyboardArrowRight
              style={{ cursor: 'pointer' }}
              onClick={() => setIsActive(true)}
            />
          )}
          <span style={{ paddingLeft: '5px' }}>{prompt}</span>
        </FlexRow>
      </LeftSpan>
      {subText && <MiddleSpan>{subText}</MiddleSpan>}
      {showOverride && (
        <RightSpan style={{ cursor: 'pointer' }} onClick={onClick}>
          Override
        </RightSpan>
      )}
    </DistributeSpaceBetween>
  );
};

const CostDetail = ({
  costDetail,
  showAvailabilty,
  showOverrideBtn = false,
  onOverride = null,
  refreshItemCards = null,
}) => {
  return (
    <>
      <DistributeSpaceBetween style={{ paddingLeft: '18px', fontSize: '11px' }}>
        <LeftSpan style={{ fontWeight: '400' }}>{costDetail.name}</LeftSpan>
        {showAvailabilty && (
          <span
            style={{ color: '#EE2A7B', fontWeight: '400', fontSize: '11px' }}
          >
            Avl 26 May
          </span>
        )}
      </DistributeSpaceBetween>

      {!isNull(costDetail.basePrice) && (
        <p
          style={{
            lineHeight: '12px',
            paddingBottom: '12px',
            color: '#505050',
            paddingLeft: '18px',
            fontSize: '11px',
          }}
        >
          ${parseFloat(costDetail.basePrice).toFixed(2)}
          {costDetail.overridePrice && (
            <span
              style={{
                fontWeight: '400',
                paddingLeft: '12px',
                color: '#EE2A7B',
                fontSize: '11px',
              }}
            >
              Price ${parseFloat(costDetail.overridePrice).toFixed(2)}
              {showOverrideBtn && (
                <span
                  style={{
                    paddingLeft: '6px',
                    position: 'relative',
                    top: '2px',
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    if (onOverride) await onOverride(costDetail.id);
                    if (refreshItemCards) refreshItemCards();
                  }}
                >
                  <OverrideIcon />
                </span>
              )}
            </span>
          )}
        </p>
      )}
    </>
  );
};

const InfoCard = ({
  prompt,
  subText,
  costDetails,
  showAvailabilty,
  showOverride,
  apiObj,
  updateFormObj,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const [overrideModalIsActive, setOverrideModalIsActive] = React.useState(
    false,
  );
  const [isSubmittingOverrides, setIsSubmittingOverrides] = React.useState(
    false,
  );
  const { userId } = useStoreState(state => state.auth);

  const onOverride = async variantId => {
    const {
      menuId,
      activeCategory,
      setItemDetailsFormDataLoading,
      setItemDetailsFormData,
    } = updateFormObj;

    setItemDetailsFormDataLoading(true);

    const temp = apiObj?.mergedVariant.variants.filter(
      ele => ele.variant_id === variantId,
    )[0];
    temp.variant_price = null;

    const variant = {
      variants: apiObj?.mergedVariant.variants,
      prompt_id: apiObj?.mergedVariant.prompt_id,
      prompt: apiObj?.mergedVariant.prompt,
    };
    const menuItemLinkInput = {
      price: null,
      variant,
      item_link_id: apiObj?.item_link_id,
      menu_item_id: apiObj?.menu_item_id,
      user_id: userId,
    };

    try {
      await updateItemLink({ menuItemLinkInput });
    } catch (error) {
      console.log(error);
    }

    const data = await getItemDetailsFormData(
      apiObj?.menu_item_id,
      setItemDetailsFormDataLoading,
      menuId,
      activeCategory,
    );

    setItemDetailsFormData(data);

    setItemDetailsFormDataLoading(false);
  };

  return (
    <>
      {overrideModalIsActive && (
        <ItemOverrideModal
          name={`${apiObj?.itemName}`}
          variants={apiObj?.mergedVariant.variants}
          itemVariant={apiObj?.menuItemVariant ?? []}
          basePrice={null}
          price={null}
          loading={isSubmittingOverrides}
          isActive={overrideModalIsActive}
          onClose={() => setOverrideModalIsActive(false)}
          onSubmit={async input => {
            setIsSubmittingOverrides(true);
            const variant = {
              variants: input.variants,
              prompt_id: apiObj?.mergedVariant.prompt_id,
              prompt: apiObj?.mergedVariant.prompt,
            };
            const menuItemLinkInput = {
              price: null,
              variant,
              item_link_id: apiObj?.item_link_id,
              menu_item_id: apiObj?.menu_item_id,
              user_id: userId,
            };

            try {
              await updateItemLink({ menuItemLinkInput });
            } catch (error) {
              console.log(error);
            }
            setIsSubmittingOverrides(false);
            setOverrideModalIsActive(false);
            const {
              menuId,
              activeCategory,
              setItemDetailsFormDataLoading,
              setItemDetailsFormData,
              refreshItemCards,
            } = updateFormObj;

            setItemDetailsFormDataLoading(true);

            const data = await getItemDetailsFormData(
              apiObj?.menu_item_id,
              setItemDetailsFormDataLoading,
              menuId,
              activeCategory,
            );

            setItemDetailsFormData(data);

            setItemDetailsFormDataLoading(false);
            refreshItemCards();
          }}
        />
      )}

      <VariantOrModiferPrompt
        isActive={isActive}
        setIsActive={setIsActive}
        prompt={prompt}
        subText={subText}
        showOverride={showOverride}
        onClick={() => setOverrideModalIsActive(true)}
      />
      {isActive &&
        costDetails.map(ele => {
          if (ele.variant) {
            return (
              <>
                <CostDetail
                  showAvailabilty={showAvailabilty}
                  costDetail={ele}
                />
                <div style={{ paddingLeft: '18px', marginTop: '-12px' }}>
                  <Info
                    info={ele.variant}
                    showAvailabilty={false}
                    showOverride={false}
                  />
                </div>
              </>
            );
          } else {
            return (
              <CostDetail
                showOverrideBtn
                showAvailabilty={showAvailabilty}
                costDetail={ele}
                key={ele.id}
                onOverride={onOverride}
                refreshItemCards={updateFormObj?.refreshItemCards}
              />
            );
          }
        })}
      <HorizontalLineInItemDetailsForm />
    </>
  );
};
const Info = ({
  info,
  showAvailabilty = false,
  showOverride,
  updateFormObj,
}) => {
  return (
    <InfoCard
      prompt={info.prompt}
      subText={info?.subText}
      showAvailabilty={showAvailabilty}
      showOverride={showOverride ?? info.type !== 'modifier'}
      costDetails={info.costDetails}
      apiObj={info?.apiObj}
      updateFormObj={updateFormObj}
    />
  );
};

const DishDetails = ({ data, updateFormObj }) => {
  return (
    <MainContainer>
      {data.map(info => (
        <Info info={info} updateFormObj={updateFormObj} />
      ))}
    </MainContainer>
  );
};

const Index = ({
  itemDetailsFormData,
  setItemDetailsFormDataLoading,
  setItemDetailsFormData,
  refreshItemCards,
  activeCategory,
  menuId,
}) => {
  console.log(itemDetailsFormData);
  if (!itemDetailsFormData) {
    return null;
  }
  const updateFormObj = {
    activeCategory,
    menuId,
    setItemDetailsFormDataLoading,
    setItemDetailsFormData,
    refreshItemCards,
  };
  return (
    <DishDetails data={itemDetailsFormData} updateFormObj={updateFormObj} />
  );
};

export default Index;
