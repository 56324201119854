import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
// import uuidv4 from 'uuid/v4';
import UserDashboard from './UserDashboard';

import PlaceReviews from './PlaceReviews';
import PlaceSocial from './PlaceSocial';
import ItemSocial from './ItemSocial';
import ItemReviews from './ItemReviews';
import EventSocial from './EventSocial';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'User Dashboard',
  },
  {
    key: 1,
    name: 'Place Social',
  },
  {
    key: 2,
    name: 'Place Reviews',
  },
  {
    key: 3,
    name: 'Item Social',
  },
  {
    key: 4,
    name: 'Item Reviews',
  },
  {
    key: 5,
    name: 'Event Social',
  },
  // {
  //   key: 6,
  //   name: 'Badges',
  // },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const prev = () =>
    activeTab === 0 ? setActiveTab(6) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 5 ? setActiveTab(0) : setActiveTab(activeTab + 1);
  return (
    <React.Fragment>
      <Header
        heading="User Activity"
        next={next}
        prev={prev}
        activeTab={activeTab}
      />
      <hr />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>

        <Container>
          {activeTab === 0 && <UserDashboard {...props} />}
          {activeTab === 1 && <PlaceSocial {...props} />}
          {activeTab === 2 && <PlaceReviews />}
          {activeTab === 3 && <ItemSocial />}
          {activeTab === 4 && <ItemReviews />}
          {activeTab === 5 && <EventSocial />}
          {/* {activeTab === 6 && <Badges />} */}
        </Container>
      </Box>
      <hr />
      <Footer prev={prev} next={next} activeTab={activeTab} />
    </React.Fragment>
  );
};

const UserActivity = withFormik({
  mapPropsToValues: () => ({}),

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'UserActivity',
})(Form);

export default UserActivity;
