/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { Column, Label } from 'rbx';
import { isNaN, forEach } from 'lodash';
import { withFormik, FieldArray } from 'formik';
import { InputAddOn } from '../../../../components/elements';
import { countDecimals } from './helpers';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 50%);
      position: absolute;
      width: 35%;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
  }
`;

const SectionChild = styled.div`
  padding: 0rem 1rem;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const calculateVariantPriceBase = (rootVariant, variantId) => {
  let price = parseFloat(0);
  forEach(rootVariant[0].variants, item => {
    if (item.variant_id === variantId) {
      price = parseFloat(item.variant_price);
    }
  });
  return parseFloat(price).toFixed(2);
};

const Form = ({
  isActive,
  name,
  onClose,
  loading,
  setFieldValue,
  values,
  handleSubmit,
  basePrice,
  itemVariant,
}) => (
  <Container className={`modal ${isActive && 'is-active'}`}>
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title is-size-1">Override price for</p>
        <CloseButtonWrapper>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </CloseButtonWrapper>
      </header>

      <section className="modal-card-body">
        <SectionChild>
          <p className="is-size-2">{name}</p>
          <br />
          {values.variants && values.variants.length === 0 && (
            <Column.Group>
              <Column>
                <InputAddOn
                  label="Item price"
                  isLeft
                  addonsText="$"
                  type="number"
                  min="0"
                  step="any"
                  value={values.price}
                  required
                  onChange={e => {
                    if (e.target.value === '') {
                      setFieldValue('price', null);
                    } else if (
                      !isNaN(parseFloat(e.target.value)) &&
                      countDecimals(e.target.value) <= 2
                    ) {
                      setFieldValue('price', parseFloat(e.target.value));
                    } else if (e.target.value === 'e') {
                      setFieldValue('price', '');
                    }
                  }}
                  onBlur={e => {
                    if (e.target.value === '') {
                      setFieldValue('price', null);
                    } else if (!isNaN(parseFloat(e.target.value))) {
                      setFieldValue(
                        'price',
                        parseFloat(Math.abs(e.target.value)).toFixed(2),
                      );
                    }
                  }}
                  placeholder="0.00"
                />
                <p className="is-size-4">Base Price: ${basePrice}</p>
              </Column>
            </Column.Group>
          )}
          <FieldArray
            name="variants"
            render={() => (
              <>
                {values.variants.map((item, index) => {
                  const basePrice2 = calculateVariantPriceBase(
                    itemVariant,
                    item.variant_id,
                  );

                  const isOverride2 = !!(
                    item.variant_price || item.variant_price === 0
                  );
                  return (
                    <Column.Group>
                      <Column size="5">
                        <Label>Variant name</Label>
                        <div className="is-capitalized">
                          <p className="is-size-4">{item.variant_name}</p>
                          <p className="is-size-4">Base Price: ${basePrice2}</p>
                        </div>
                      </Column>
                      <Column>
                        <InputAddOn
                          label="Item price"
                          isLeft
                          addonsText="$"
                          type="number"
                          min="0"
                          step="any"
                          value={isOverride2 ? item.variant_price : ''}
                          onChange={e => {
                            if (e.target.value === '') {
                              setFieldValue(
                                `variants.${index}.variant_price`,
                                null,
                              );
                            } else if (
                              !isNaN(parseFloat(e.target.value)) &&
                              countDecimals(e.target.value) <= 2
                            ) {
                              setFieldValue(
                                `variants.${index}.variant_price`,
                                parseFloat(e.target.value),
                              );
                            } else if (e.target.value === 'e') {
                              setFieldValue('price', '');
                            }
                          }}
                          onBlur={e => {
                            if (e.target.value === '') {
                              setFieldValue(
                                `variants.${index}.variant_price`,
                                null,
                              );
                            } else if (!isNaN(parseFloat(e.target.value))) {
                              setFieldValue(
                                `variants.${index}.variant_price`,
                                parseFloat(Math.abs(e.target.value)).toFixed(2),
                              );
                            }
                          }}
                          placeholder="0.00"
                          // required
                        />
                      </Column>
                    </Column.Group>
                  );
                })}
              </>
            )}
          />
        </SectionChild>
      </section>
      <footer className="modal-card-foot">
        <button
          type="button"
          className="button"
          aria-label="close"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`button is-primary ${loading && 'is-loading'}`}
          aria-label="close"
          onClick={() => handleSubmit()}
        >
          Update price
        </button>
      </footer>
    </div>
  </Container>
);

const ItemOverrideModal = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ price, variants }) => {
    console.log('variants', variants, price);
    return { price, variants };
  },

  validate: values => {
    const errors = {};
    if (values.variants.length === 0) {
      if (!values.price) {
        errors.price = 'price is required!';
      }
    }
    return errors;
  },

  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'Item Override Form',
})(Form);

export default ItemOverrideModal;
