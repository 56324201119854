import React from 'react';
import { withRouter } from 'react-router-dom';
import { Title, Column } from 'rbx';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import {
  SubHeading,
  ReactDateTimePicker,
  CheckBox,
  Input,
} from '../../../components/elements';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const LeftWrapper = styled.div`
  flex-basis: 40%;
  padding: 12px;
`;

const RightWrapper = styled.div`
  flex-basis: 60%;
  padding: 24px;
`;

const Header = ({
  placeName,
  placeLocation,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  searchByOrderNumber,
  setSearchByOrderNumber,
  orderNumber,
  setOrderNumber,
  setOrders,
  trifferRefetchFunc,
  resetPaginationStates,
}) => (
  // const audio = new Audio('./Alert-notification.mp3');
  // const start = () => {
  //   audio.play();
  // };
  <>
    <Wrapper>
      <LeftWrapper>
        <Title size={2}>Order History</Title>
        <SubHeading className="is-size-2 has-text-weight-medium">
          {placeName}
        </SubHeading>
        <SubHeading className="is-size-2">{placeLocation}</SubHeading>
      </LeftWrapper>

      <RightWrapper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <Title
              size={3}
              style={{ paddingRight: '8px', paddingTop: '0.3rem' }}
            >
              Date
            </Title>
            <Column.Group style={{ paddingBottom: '1.5rem' }}>
              <Column size={6}>
                <ReactDateTimePicker
                  disabled={searchByOrderNumber}
                  maxDate={moment(endDate).toDate()}
                  value={startDate}
                  label="From"
                  onChange={date => {
                    setStartDate(date);
                    // setEndDate(
                    //   moment(date)
                    //     .add(45, 'days')
                    //     .toISOString(),
                    // );
                  }}
                  clear={false}
                />
              </Column>
              <Column size={6}>
                <ReactDateTimePicker
                  disabled={searchByOrderNumber}
                  minDate={moment(startDate).toDate()}
                  value={endDate}
                  label="To"
                  onChange={date => {
                    const a = moment(startDate);
                    const b = moment(date).set({
                      hour: 23,
                      minute: 59,
                      second: 0,
                      millisecond: 0,
                    });
                    const daysDiff = b.diff(a, 'days');
                    if (daysDiff > 45) {
                      toast.error(
                        'Maximum selection range of 45 days exceeded',
                      );
                    } else {
                      setEndDate(date);
                    }
                  }}
                  clear={false}
                />
              </Column>
            </Column.Group>
          </div>

          <CheckBox
            label="Search by Order Number"
            name="search_by_order_no"
            value={searchByOrderNumber}
            onChange={value => {
              resetPaginationStates();
              setOrders([]);
              setOrderNumber('');
              setSearchByOrderNumber(value);
              if (!value) {
                trifferRefetchFunc();
              }
            }}
          />
          {searchByOrderNumber && (
            <div style={{ width: '50%' }}>
              <Input
                label="Order Number"
                type="text"
                value={orderNumber}
                placeholder="Start entering an Order Number..."
                onChange={e => {
                  setOrderNumber(e.target.value);
                }}
              />
            </div>
          )}
        </div>
      </RightWrapper>
    </Wrapper>
  </>
);
export default withRouter(Header);
