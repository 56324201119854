import React from 'react';
import { Column } from 'rbx';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';
import { Input } from '../../../components/elements';

const userFlag = gql`
  query userFlag($input: SearchInput) {
    search_flags(input: $input) {
      total_size
    }
  }
`;

const userPlaceReviewCount = gql`
  query placeReview($input: SearchInput) {
    search_place_review(input: $input) {
      total_size
    }
  }
`;

const userItemReviewCount = gql`
  query itemReview($input: SearchInput) {
    search_item_review(input: $input) {
      total_size
    }
  }
`;

const SocialCounts = ({ review }) => (
  <Query
    client={client.clientPrivate}
    query={userFlag}
    variables={{
      input: {
        filter: {
          flag_filter: {
            user_id: review.audit.created_by,
          },
        },
      },
    }}
    fetchPolicy="network-only"
  >
    {({ data: userFlagData, loading: flagLoading, error: flagError }) => {
      if (flagError) {
        return '';
      }
      const { search_flags } = userFlagData;
      return (
        <Query
          query={userPlaceReviewCount}
          variables={{
            input: {
              filter: {
                place_review_filter: {
                  user_id: review.audit.created_by,
                },
              },
            },
          }}
          fetchPolicy="network-only"
        >
          {({ data: placeData, loading: placeLoading, error: placeError }) => {
            if (placeError) {
              return '';
            }
            const { search_place_review } = placeData;
            return (
              <Query
                query={userItemReviewCount}
                variables={{
                  input: {
                    filter: {
                      item_review_filter: {
                        user_id: review.audit.created_by,
                      },
                    },
                  },
                }}
                fetchPolicy="network-only"
              >
                {({
                  data: itemData,
                  loading: itemLoading,
                  error: itemError,
                }) => {
                  if (itemLoading) {
                    return '';
                  }
                  if (itemError) {
                    return '';
                  }
                  const { search_item_review } = itemData;
                  if (flagLoading || placeLoading || itemLoading) {
                    return (
                      <React.Fragment>
                        <Column size={3}>
                          <Input label="User Flags" placeholder="0" disabled />
                        </Column>
                        <Column size={3}>
                          <Input
                            label="User Place Reviews"
                            placeholder="0"
                            disabled
                          />
                        </Column>
                        <Column size={3}>
                          <Input
                            label="User Item Reviews"
                            placeholder="0"
                            disabled
                          />
                        </Column>
                      </React.Fragment>
                    );
                  }
                  return (
                    <React.Fragment>
                      <Column size={3}>
                        <Input
                          label="User Flags"
                          value={search_flags.total_size}
                          disabled
                        />
                      </Column>
                      {search_place_review === null ? (
                        <Column size={3}>
                          <Input
                            label="User Place Reviews"
                            value="0"
                            disabled
                          />
                        </Column>
                      ) : (
                        <Column size={3}>
                          <Input
                            label="User Place Reviews"
                            value={search_place_review.total_size}
                            disabled
                          />
                        </Column>
                      )}

                      <Column size={3}>
                        <Input
                          label="User Item Reviews"
                          value={search_item_review.total_size}
                          disabled
                        />
                      </Column>
                    </React.Fragment>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      );
    }}
  </Query>
);

export default SocialCounts;
