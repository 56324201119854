import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { Input, TextArea } from '../../../components/elements';
import PlaceReviewUserDetails from './PlaceReviewUserDetails';

const Container = styled.div`
  fieldset {
    margin-right: 2rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const PlaceDetails = ({ place, placeName }) => {
  console.log('PlaceDetails -> placeName', placeName);
  console.log('PlaceDetails -> place', placeName);

  return (
    <Container>
      <Column>
        <fieldset className="box">
          <legend className="label">Place Details</legend>
          <Column>
            <Column.Group vcentered multiline gapSize={8}>
              <Column size="half">
                <Input label="Place Name" value={placeName.name} disabled />
              </Column>
              <Column size="half">
                <Input label="Status" value={place.status} disabled />
              </Column>
            </Column.Group>
          </Column>

          <Column size="full">
            <TextArea label="Review Content" value={place.content} disabled />
          </Column>
          <PlaceReviewUserDetails
            place={place}
            createdBy={place.audit.created_by}
            createdTime={place.audit.created_at}
          />
        </fieldset>
      </Column>
    </Container>
  );
};

export default PlaceDetails;
