import React from 'react';

import Layout from '../../../components/global/Layout';
import Form from './Form';

const CustomHours = () => (
  <Layout>
    <Form />
  </Layout>
);

export default CustomHours;
