import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { first } from 'lodash';

import { Input, Select, MobileInput } from '../../../components/elements';

const Container = styled.div`
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const TIME_ZONE_OPTIONS = [
  { value: '+10', label: 'AEST' },
  { value: '+8', label: 'AWST' },
  { value: '+9.5', label: 'ACST' },
  { value: '+5.5', label: 'IST' },
];

const Details = ({ values, setFieldValue }) => {
  const timeZoneLabel = first(
    TIME_ZONE_OPTIONS.filter(item => item.value === values.timezone),
  );

  return (
    <Container>
      <div className="label">Place Information</div>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input label="Place Name" value={values.name} disabled />
        </Column>
        <Column size={4}>
          <Input
            label="Listing Status"
            value={values.listing_status}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Street Address"
            value={values.address_line_1}
            required
            disabled
          />
        </Column>
        <Column size="half">
          <Column.Group gapSize={1}>
            <Column>
              <Input
                label="Claim Status"
                value={values.claim_status}
                disabled
              />
            </Column>
            <Column>
              <Input
                label="Claimant Status"
                value={values.status}
                required
                disabled
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Column.Group gapSize={1}>
            <Column size={8}>
              <Input
                label="City / Suburb"
                value={values.city}
                required
                disabled
              />
            </Column>
            <Column>
              <Input
                label="Post Code / Pin"
                value={values.post_code}
                required
                disabled
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Input label="State" value={values.state} required disabled />
        </Column>
        <Column size="half" gapSize={1}>
          <Column.Group gapSize={1}>
            <Column size={8}>
              <Input label="Country" value={values.country} required disabled />
            </Column>
            <Column>
              <Select
                label="TimeZone"
                disabled
                options={TIME_ZONE_OPTIONS}
                value={[
                  {
                    value: values.timezone,
                    label:
                      timeZoneLabel && timeZoneLabel.length !== 0
                        ? timeZoneLabel.label
                        : 'AEST',
                  },
                ]}
                onChange={({ value }) => setFieldValue('timezone', value)}
                required
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half" gapSize={1}>
          <Column.Group gapSize={1}>
            <Column>
              <Input
                label="Latitude"
                value={values.latitude}
                required
                disabled
              />
            </Column>
            <Column>
              <Input
                label="Longitude"
                value={values.longitude}
                required
                disabled
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Input
            label="Primary Contact Email"
            value={values.primaryEmail}
            disabled
          />
        </Column>
        <Column size="half">
          <MobileInput
            label="Primary Contact Phone"
            value={values.primaryMobile}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Business Website"
            value={values.businessSite}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Business Facebook Page"
            value={values.facebookPage}
            disabled
          />
        </Column>
      </Column.Group>
    </Container>
  );
};

export default Details;
