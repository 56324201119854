import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { isNaN, range } from 'lodash';
import { Label } from 'rbx';
import {
  Radio,
  InputAddOn,
  Select,
  InputErrorMessage,
} from '../../../../components/elements';

import CircularMapSelection from './CircularMapSelection';
import PolygonMapSelection from './PolygonMapSelection';

const Container = styled.div`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 20%);
      position: absolute;
      width: 60%;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 2rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
  }
`;

const SecondaryHeader = styled.div`
  background: white;
  padding-left: 2rem;
`;

const Content = styled.div`
  padding: 2rem;
`;

const LabelWrapper = styled.div`
  padding-right: 1rem;
  width: 100%;
`;

const SearchItemModal = ({
  zoneSettingExceptSelected,
  isActive,
  onClose,
  onSubmit,
  zone,
  placeLocation,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      delivery_fee: zone ? parseFloat(zone.delivery_fee).toFixed(2) : null,
      delivery_estimate: zone ? zone.delivery_estimate : null,
      area_type: zone ? zone.delivery_area.area_type : null,
      area_circle:
        zone && zone.delivery_area.area_circle !== null
          ? {
              ...zone.delivery_area.area_circle,
              radius: zone.delivery_area.area_circle.radius
                ? zone.delivery_area.area_circle.radius.substring(
                    0,
                    zone.delivery_area.area_circle.radius.length - 2,
                  )
                : null,
            }
          : { radius: 1, location: placeLocation },
      area_polygon: zone
        ? zone.delivery_area.area_polygon
        : [
            {
              latitude: placeLocation.latitude + 0.0466,
              longitude: placeLocation.longitude,
            },
            {
              latitude: placeLocation.latitude + 0.00856,
              longitude: placeLocation.longitude - 0.0456,
            },
            {
              latitude: placeLocation.latitude + 0.001256,
              longitude: placeLocation.longitude + 0.00956,
            },
          ],
    },
    validate: values => {
      const errors = {};

      if (!values.area_type) {
        errors.area_type = 'Area type is required';
      } else {
        if (values.area_type === 'CIRCLE') {
          if (parseFloat(values.area_circle.radius) > 999) {
            errors.radius = 'Radius Should be smaller than 999 !';
          }
        }
        if (!values.delivery_fee) {
          errors.delivery_fee = 'Delivery fee is required';
        } else if (values.delivery_fee < 0) {
          errors.delivery_fee = 'Please enter valid number';
        }

        if (!values.delivery_estimate) {
          errors.delivery_estimate = 'Delivery estimate  is required';
        } else if (values.delivery_estimate < 0) {
          errors.delivery_fee = 'Please enter valid number';
        }
      }

      return errors;
    },
    onSubmit,
  });

  const deliveryEstimates = range(5, 65, 5).map(item => ({
    label: `${item} Min`,
    value: item,
  }));

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    resetForm,
  } = formik;
  console.log('error', errors);
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {zone ? 'Update' : 'Add'} Delivery Zone
          </p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => {
              onClose();
              resetForm();
            }}
          />
        </header>
        <SecondaryHeader>
          <p>Select an option to create your delivery zone</p>
        </SecondaryHeader>
        <section className="modal-card-body">
          <Content>
            {!zone && (
              <>
                <div className="columns">
                  <div className="column is-4">
                    <Radio
                      label="Distance from restaurants"
                      value={values.area_type === 'CIRCLE'}
                      onChange={() => setFieldValue('area_type', 'CIRCLE')}
                    />
                  </div>
                  <div className="column is-4">
                    <Radio
                      label="Draw my own"
                      value={values.area_type === 'POLYGON'}
                      onChange={() => setFieldValue('area_type', 'POLYGON')}
                    />
                  </div>
                </div>
                <InputErrorMessage
                  errors={errors.area_type}
                  touched={touched.area_type}
                  disableToastMessage
                />
              </>
            )}
            <div className="columns">
              {(values.area_type === 'CIRCLE' || values.area_type === null) && (
                <div className="column is-4">
                  <InputAddOn
                    type="number"
                    isRight
                    addonsText="Kms"
                    label="Distance"
                    required
                    value={values.area_circle.radius}
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        parseFloat(e.target.value) < 0
                      ) {
                        setFieldValue('area_circle.radius', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'area_circle.radius',
                          parseFloat(e.target.value),
                        );
                      }
                    }}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setFieldValue('area_circle.radius', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'area_circle.radius',
                          parseFloat(e.target.value).toFixed(2),
                        );
                      }
                    }}
                    disabled={values.area_type === null}
                  />
                  {errors.radius && (
                    <p className="help is-danger is-size-4">{errors.radius}</p>
                  )}
                </div>
              )}
              <div className="column is-4">
                <InputAddOn
                  type="number"
                  isLeft
                  addonsText="$"
                  label="Delivery Fee"
                  value={
                    values.delivery_fee === null ? '' : values.delivery_fee
                  }
                  required
                  onChange={e => {
                    if (
                      e.target.value === '' ||
                      parseFloat(e.target.value) < 0
                    ) {
                      setFieldValue('delivery_fee', null);
                    } else if (!isNaN(parseFloat(e.target.value))) {
                      setFieldValue('delivery_fee', parseFloat(e.target.value));
                    }
                  }}
                  onBlur={e => {
                    if (e.target.value === '') {
                      setFieldValue('delivery_fee', null);
                    } else if (!isNaN(parseFloat(e.target.value))) {
                      setFieldValue(
                        'delivery_fee',
                        parseFloat(e.target.value).toFixed(2),
                      );
                    }
                  }}
                  disabled={values.area_type === null}
                  errors={errors.delivery_fee}
                  touched={touched.delivery_fee}
                  disableToastMessage
                />
              </div>
              <div className="column is-4">
                <LabelWrapper>
                  <Label>Delivery Estimate</Label>
                </LabelWrapper>
                <LabelWrapper>
                  <Select
                    value={
                      values.delivery_estimate && {
                        label: `${values.delivery_estimate} Min`,
                        value: values.delivery_estimate,
                      }
                    }
                    options={deliveryEstimates}
                    onChange={value => {
                      setFieldValue('delivery_estimate', value.value);
                    }}
                    disabled={values.area_type === null}
                    errors={errors.delivery_estimate}
                    touched={touched.delivery_estimate}
                    disableToastMessage
                  />
                </LabelWrapper>
              </div>
            </div>
            {values.area_type === 'CIRCLE' && (
              <CircularMapSelection
                otherZones={zoneSettingExceptSelected}
                initialRadius={values.area_circle.radius}
                centerLocation={{
                  lat: placeLocation.latitude,
                  lng: placeLocation.longitude,
                }}
                initialLocation={{
                  lat: values.area_circle.location.latitude,
                  lng: values.area_circle.location.longitude,
                }}
                onChange={circleZoneValue => {
                  setFieldValue(
                    'area_circle.location',
                    circleZoneValue.location,
                  );
                  setFieldValue(
                    'area_circle.radius',
                    Math.floor(circleZoneValue.radius),
                  );
                }}
              />
            )}
            {values.area_type === 'POLYGON' && (
              <PolygonMapSelection
                otherZones={zoneSettingExceptSelected}
                initialPath={values.area_polygon.map(item => ({
                  lat: item.latitude,
                  lng: item.longitude,
                }))}
                centerLocation={{
                  lat: placeLocation.latitude,
                  lng: placeLocation.longitude,
                }}
                onChange={path => {
                  setFieldValue('area_polygon', path);
                }}
              />
            )}
          </Content>
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-grey-light"
            aria-label="close"
            onClick={() => {
              onClose();
              resetForm();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="button is-primary"
            aria-label="close"
            onClick={handleSubmit}
          >
            <p has-text-weight-semibold>{zone ? 'Update Zone' : 'Add Zone'}</p>
          </button>
        </footer>
      </div>
    </Container>
  );
};

export default SearchItemModal;
