import React, { useState } from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

import { Table, Loading } from '../../../components/elements';

import { totalTimeDelay, totalTimeDueActiveCard2 } from '../Order/timeHelpers';

import OrderDetails from '../Order/OrderDetails';
import DraftOrderDetails from '../Order/DraftOrderDetails';

const ReferenceStyled = styled.div`
  color: #3273dc;
  cursor: pointer;
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const durationColumnValue = (item, isActive) => {
  if (!item) {
    return;
  }
  let duration = '-';
  if (isActive === 'new') {
    duration = moment(item?.received?.created_at).fromNow();
  } else if (isActive === 'confirmed') {
    duration = moment(item?.confirmed?.created_at).fromNow();
  } else if (isActive === 'ready') {
    duration = moment(item?.received?.created_at).fromNow();
  } else if (isActive === 'completed') {
    duration = moment
      .duration(
        moment(item?.completed?.created_at).diff(
          moment(item?.received?.created_at),
        ),
      )
      .humanize();
  } else if (isActive === 'cancelled') {
    duration = moment
      .duration(
        moment(item?.cancelled?.created_at).diff(
          moment(item?.received?.created_at),
        ),
      )
      .humanize();
  }
  // eslint-disable-next-line consistent-return
  return duration;
};

const getPaymentTitle = paymentObj => {
  const { payment_method_type, payment_method } = paymentObj;
  if (payment_method_type) {
    if (payment_method_type === 'G_PAY') {
      return 'Google Pay';
    }
    if (payment_method_type === 'APPLE_PAY') {
      return 'Apple Pay';
    }
    if (payment_method_type === 'CARD') {
      return 'Card';
    }
  }
  return capitalize(payment_method);
};

const DurationColumn = ({
  item,
  isActive,
  triggerReRenderOfDurationColumn,
}) => <td>{durationColumnValue(item, isActive)}</td>;

const ItemRow = ({
  item,
  onClick,
  isActive,
  triggerReRenderOfDurationColumn,
  draftOrderCreatedBy,
}) => {
  const lat = parseFloat(item.place.latitude).toFixed(5);
  const long = parseFloat(item.place.longitude).toFixed(5);
  const timeZone = tzLookup(lat, long);
  // console.log('timezone', timeZone, item);
  moment.tz.setDefault(timeZone);

  return (
    <tbody>
      <tr>
        <td>
          <ReferenceStyled onClick={onClick}>
            {item.order_reference}
          </ReferenceStyled>
        </td>
        <td>{item.place.name}</td>
        <td>{item.place.city}</td>
        <td>{item.service_type}</td>
        {isActive === 'draft' ? (
          <td>{moment(item?.audit?.created_at).format('LLL')}</td>
        ) : (
          <td>{moment(item?.received?.created_at).format('LLL')}</td>
        )}
        <td>
          {item.is_scheduled
            ? moment(item?.scheduled_date_time).format('LLL')
            : '-'}
        </td>
        <td>{item.overall_quantity} items</td>
        <td>${parseFloat(item.billing.order_total).toFixed(2) || 0}</td>
        <td>{item.payment && getPaymentTitle(item.payment)}</td>
        <td>
          {item.payment &&
            item.payment.payment_status &&
            capitalize(item.payment.payment_status)}
        </td>

        <DurationColumn
          triggerReRenderOfDurationColumn={triggerReRenderOfDurationColumn}
          item={item}
          isActive={isActive}
        />
        {isActive === 'draft' ? (
          <td>{draftOrderCreatedBy[item.menu_order_id] || 'Guest'}</td>
        ) : (
          <td>{item.contact && capitalize(item.contact.contact_name)}</td>
        )}
        <td>{item.app_type}</td>
        <td>{item.order_count}</td>
      </tr>
    </tbody>
  );
};

const AesDes = ({ sort, setSort, aes, des }) => (
  <a onClick={() => setSort(sort === des ? aes : des)}>
    <i className={`fas fa-sort-amount-${sort === des ? 'up' : 'down'}`} />
  </a>
);

const New = ({
  sort,
  setSort,
  orders,
  isLoading,
  isActiveTab,
  refetchData,
  triggerReRenderOfDurationColumn,
  draftOrderCreatedBy,
  canLoadMoreData,
  loadingMoreData,
  refetchOrderData,
  loadMoreDraftData,
  refetchDraftOrderData,
  ...props
}) => {
  // const [isActiveModal, setIsActiveModal] = useState(false);
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  const [orderData, setOrderData] = useState(null);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>Order No.</span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="PLACE_NAME_ASC"
                    des="PLACE_NAME_DESC"
                  />
                  Place Name
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="PLACE_CITY_ASC"
                    des="PLACE_CITY_DESC"
                  />
                  City / Suburb
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="SERVICE_TYPE_ASC"
                    des="SERVICE_TYPE_DESC"
                  />
                  Service Type
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="RECEIVED_ASC"
                    des="RECEIVED_DESC"
                  />
                  {isActiveTab === 'draft' ? 'Draft Date' : 'Order Date'}
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Scheduled</span>
              </div>
            </th>

            <th>
              <div>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="OVERALL_QUANTITY_ASC"
                  des="OVERALL_QUANTITY_DESC"
                />
                <span>Items</span>
              </div>
            </th>

            <th>
              <div>
                <span>Total</span>
              </div>
            </th>

            <th>
              <div>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="PAYMENT_METHOD_ASC"
                  des="PAYMENT_METHOD_DESC"
                />
                <span>Payment</span>
              </div>
            </th>
            <th>
              <div>
                <span>Status</span>
              </div>
            </th>
            <th>
              <div>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="TRANSITIONED_ASC"
                  des="TRANSITIONED_DESC"
                />
                <span>Duration</span>
              </div>
            </th>
            <th>
              <div>
                <span>Created By</span>
              </div>
            </th>
            <th>
              <div>
                <span>Device Info</span>
              </div>
            </th>
            <th>
              <div>
                <span>Order Count</span>
              </div>
            </th>
          </tr>
        </thead>

        {isLoading && <Loading />}
        {orders.length !== 0
          ? orders.map(item => (
              <ItemRow
                triggerReRenderOfDurationColumn={
                  triggerReRenderOfDurationColumn
                }
                isActive={isActiveTab}
                item={item}
                onClick={() => {
                  setOrderData(item);
                  setIsActiveOrderDetailsModal(true);
                  // setIsActiveModal(true);
                }}
                draftOrderCreatedBy={draftOrderCreatedBy}
              />
            ))
          : !isLoading && <div className="">No Orders here</div>}
      </Table>
      {!isLoading && canLoadMoreData && isActiveTab === 'completed' && (
        <PaginationContainer>
          {loadingMoreData ? (
            <span>loading...</span>
          ) : (
            <ShowMoreButton
              type="button"
              onClick={() => refetchOrderData({ status: 'COMPLETE' })}
            >
              <span>Show More</span>
            </ShowMoreButton>
          )}
        </PaginationContainer>
      )}
      {!isLoading && loadMoreDraftData && isActiveTab === 'draft' && (
        <PaginationContainer>
          {loadingMoreData ? (
            <span>loading...</span>
          ) : (
            <ShowMoreButton
              type="button"
              onClick={() => refetchDraftOrderData({ status: 'DRAFT' })}
            >
              <span>Show More</span>
            </ShowMoreButton>
          )}
        </PaginationContainer>
      )}
      {orderData && isActiveOrderDetailsModal && isActiveTab !== 'draft' && (
        <OrderDetails
          // onClose={() => setIsActiveModal(false)}
          // isActive={isActiveModal}
          dueTimeToBeDisplayedInOrderDetailsModal={
            isActiveTab !== 'completed' && isActiveTab !== 'cancelled'
              ? totalTimeDueActiveCard2(
                  orderData.estimates,
                  orderData?.received?.created_at,
                )
              : null
          }
          delayTimeToBeDisplayedInOrderDetailsModal={
            isActiveTab !== 'completed' && isActiveTab !== 'cancelled'
              ? totalTimeDelay(
                  orderData.estimates,
                  orderData?.received?.created_at,
                )
              : null
          }
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          orderComponent="OrderDashboard"
          order={orderData}
          refetchData={refetchData}
        />
      )}
      {orderData && isActiveOrderDetailsModal && isActiveTab === 'draft' && (
        <DraftOrderDetails
          onClose={() => setIsActiveOrderDetailsModal(false)}
          isActive={isActiveOrderDetailsModal}
          order={orderData}
        />
      )}
    </>
  );
};

export default New;
