import React, { useState } from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import { toJpeg } from 'html-to-image';
import { kebabCase } from 'lodash';

const Container = styled.div`
  position: relative;
  padding-top: 1rem;
  width: 17rem;
`;

const FlexRow = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
const QRCodeWrapper = styled.div`
  border: 1px solid #bfbdbd;
  padding: 0.5rem;
  background-color: #ffffff;
`;

const LogoContainer = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: white;
  background-image: url('/images/kravein-logo.png');
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MenuQrCodeContainer = styled.div`
  position: absolute;
  z-index: 20;
`;

const HiddenTableQrCodeContainer = styled.div`
  position: absolute;
  z-index: 10;
`;

const qrCodeValueFromCount = (qrCodeValue, siteLabel, count) => {
  let url = qrCodeValue;
  if (siteLabel) {
    url = count ? `${qrCodeValue}&table=${siteLabel}-${count}` : qrCodeValue;
  } else {
    url = count ? `${qrCodeValue}&table=table-${count}` : qrCodeValue;
  }
  return url;
};

const MenuQRCode = ({ menuName, qrCodeValue, siteLabel, count }) => {
  const [loading, setLoading] = useState(false);

  const onDownload = () => {
    setLoading(true);
    const node = document.getElementById('qr-code');
    toJpeg(node, { quality: 1 }).then(dataUrl => {
      const link = document.createElement('a');
      link.download = `${kebabCase(menuName)}-qr-code.png`;
      link.href = dataUrl;
      link.click();
      setLoading(false);
    });
  };

  console.group('the big qr code value @ menu');
  console.log(qrCodeValue);
  console.groupEnd();
  return (
    <>
      <Container>
        <MenuQrCodeContainer>
          <QRCodeWrapper id="qr-code">
            <LogoContainer />
            <QRCode value={qrCodeValue} />
          </QRCodeWrapper>
        </MenuQrCodeContainer>
        <HiddenTableQrCodeContainer>
          <QRCodeWrapper id="qr-code-table">
            <LogoContainer />
            <QRCode
              value={qrCodeValueFromCount(qrCodeValue, siteLabel, count)}
            />
          </QRCodeWrapper>
        </HiddenTableQrCodeContainer>
      </Container>
      <FlexRow>
        <button
          type="button"
          className={`button is-primary ${loading && 'is-loading'}`}
          onClick={onDownload}
          style={{ marginTop: '280px', zIndex: 20 }}
        >
          Download
        </button>
      </FlexRow>
    </>
  );
};

export default MenuQRCode;
