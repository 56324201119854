import React from 'react';

import OrderDetailsForm from './OrderDetailsForm';

const OrderDetails = ({
  dueTimeToBeDisplayedInOrderDetailsModal = null,
  delayTimeToBeDisplayedInOrderDetailsModal = null,
  isActiveOrderDetailsModal,
  setIsActiveOrderDetailsModal,
  setReceivedOrders,
  receivedOrders,
  showConfirmedTime,
  setShowConfirmedTime,
  addConfirmationOrderAfterReceived,
  addCompleteOrderAfterReady,
  readyOrders,
  setReadyOrders,
  order,
  setConfirmedOrders,
  confirmedOrders,
  handleAfterDelayOrder,
  handleAfterAdjustment,
  orderComponent,
  refetchData = () => {},
}) => (
  <OrderDetailsForm
    dueTimeToBeDisplayedInOrderDetailsModal={
      dueTimeToBeDisplayedInOrderDetailsModal
    }
    delayTimeToBeDisplayedInOrderDetailsModal={
      delayTimeToBeDisplayedInOrderDetailsModal
    }
    isActive={isActiveOrderDetailsModal}
    onClose={() => setIsActiveOrderDetailsModal(false)}
    setReceivedOrders={setReceivedOrders}
    receivedOrders={receivedOrders}
    setConfirmedOrders={setConfirmedOrders}
    confirmedOrders={confirmedOrders}
    data={order}
    addConfirmationOrderAfterReceived={addConfirmationOrderAfterReceived}
    addCompleteOrderAfterReady={addCompleteOrderAfterReady}
    readyOrders={readyOrders}
    setReadyOrders={setReadyOrders}
    showConfirmedTime={showConfirmedTime}
    setShowConfirmedTime={setShowConfirmedTime}
    handleAfterDelayOrder={handleAfterDelayOrder}
    handleAfterAdjustment={handleAfterAdjustment}
    orderComponent={orderComponent}
    refetchData={refetchData}
  />
);
export default OrderDetails;
