import React from 'react';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

import gql from 'graphql-tag';
import client from '../../../../utils/apolloClient';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places_from_view(input: $input) {
      total_pages
      total_size
      place_listing {
        place_id
        display_order
        # is_open
        listing_status
        claim_status
        featured
        event_business
        item_review_place
        status
        approval_status
        voucher_count
        default_url
        price_range
        name
        trading_name
        tagline
        description
        slug
        # stars
        review_count
        like_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        social {
          type
          logo
          description
          value
          display
        }
      }
    }
  }
`;

export const fetchPlace = async ({ userId, placeId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: placesQuery,
      variables: {
        input: {
          sort: 'UPDATED_DESC',
          filter: {
            place_filter: {
              place_id: [placeId],
            },
          },
          user_id: userId,
        },
      },
      fetchPolicy: 'network-only',
    });
    if (
      data &&
      data.data &&
      data.data.search_places_from_view &&
      Array.isArray(data.data.search_places_from_view.place_listing)
    ) {
      return data.data.search_places_from_view.place_listing[0];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useCurrentTimeZone = ({
  userId,
  placeId,
  stateSetterFn,
  allowFetch = true,
}) => {
  React.useEffect(() => {
    const setTimeZone = value => {
      if (value.latitude && value.longitude) {
        const lat = parseFloat(value.latitude).toFixed(5);
        const long = parseFloat(value.longitude).toFixed(5);
        const timeZone = tzLookup(lat, long);
        stateSetterFn(timeZone);
        moment.tz.setDefault(timeZone);
      }
    };
    const fetchFunc = async () => {
      if (placeId) {
        const res = await fetchPlace({ userId, placeId });
        setTimeZone(res);
      }
    };

    if (allowFetch) {
      fetchFunc();
    }
  }, [placeId, allowFetch]);
};

export default {};
