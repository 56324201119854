import React from 'react';
import styled from 'styled-components';
import { Field, Label, Control } from 'rbx';
import InputErrorMessage from './InputErrorMessage';
import { ACLSConsumer } from '../../utils/aclsContext';
import Can from '../../utils/Can';

const OptionalText = styled.p`
  &&& {
    padding-left: 4px;
    color: #737373;
  }
`;

const TextArea = ({
  label,
  required,
  optionalText,
  errors,
  touched,
  disableToastMessage,
  action,
  ...props
}) => (
  <Field>
    <Label className="is-flex">
      {label}
      {required && <span className="has-text-danger">*</span>}
      {optionalText && <OptionalText>{optionalText}</OptionalText>}
    </Label>
    <Control>
      {action ? (
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={action}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => <textarea className="textarea" {...props} />}
              no={() => <textarea className="textarea" disabled {...props} />}
            />
          )}
        </ACLSConsumer>
      ) : (
        <textarea className="textarea" {...props} />
      )}
    </Control>
    <InputErrorMessage
      errors={errors}
      touched={touched}
      disableToastMessage={disableToastMessage}
    />
  </Field>
);

export default TextArea;
