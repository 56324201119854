import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { Input, Radio } from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const PlaceSearch = ({
  vendorNameSearchInput,
  setVendorNameSearchInput,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  setLocation,
  status,
  setStatus,
  location,
  // listingApproved,
  // setListingApproved,
  // eventBusiness,
  // setEventBusiness,
  // itemReviewPlace,
  // setItemReviewPlace,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <TitleWrapper>
            <Title className="label">Status</Title>
          </TitleWrapper>
        </Level.Item>
        <Level.Item>
          <Radio
            label="ACTIVE"
            value={status === 'ACTIVE'}
            onChange={() => {
              setStatus('ACTIVE');
            }}
          />
        </Level.Item>
        <Level.Item>
          <Radio
            label="INACTIVE"
            value={status === 'INACTIVE'}
            onChange={() => {
              setStatus('INACTIVE');
            }}
            // disabled
          />
        </Level.Item>
      </Level.Item>
    </Level>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-4">
          <Input
            label="Company Name"
            type="text"
            id="FinancialManagement_AddEditCompany_CompanyName"
            value={vendorNameSearchInput}
            onChange={place => setVendorNameSearchInput(place.target.value)}
            placeholder="Start entering company name..."
          />
        </div>
        <div className="column is-4">
          <div className="field">
            <Input
              label="City / Suburb"
              id="MenuDesigner_City"
              type="text"
              value={location}
              onChange={city => setLocation(city.target.value)}
              placeholder="Enter the city name..."
            />
          </div>
        </div>

        <div className="column is-4">
          <Input
            label="Place Name"
            type="text"
            value={placeNameSearchInput}
            onChange={place => setPlaceNameSearchInput(place.target.value)}
            placeholder="Start entering place name..."
          />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default PlaceSearch;
