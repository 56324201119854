/* eslint-disable func-names */
import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { isNaN } from 'lodash';
import moment from 'moment';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { toast } from 'react-toastify';

import {
  Input,
  Select,
  CheckBoxVariant2,
  Radio,
  InputAddOn,
} from '../../../../../components/elements';

import DateTimePicker from './DateTimePicker';

// const Container = styled.div`
//   &&& {
//     z-index: 1000;

//     .modal-background {
//       background-color: rgba(13, 13, 13, 0.28);
//     }

//     .modal-card-body {
//       height: 100vh;
//       padding: 0rem 1rem;
//     }

//     .modal-card {
//       height: 100vh;
//       position: fixed;
//       top: 0;
//       right: 0;
//       width: 40rem;
//     }

//     .modal-card-head,
//     .modal-card-foot {
//       border-top-left-radius: 0px;
//       border-top-right-radius: 0px;
//     }

//     .modal-card-title {
//       font-size: 1.5rem;
//       font-weight: 600;
//     }

//     .modal-card-head {
//       border-bottom: 0px;
//       padding: 1.5rem 1rem;
//       background: white;
//     }

//     .modal-card-foot {
//       justify-content: flex-end;
//       border: 0px;
//       padding: 1rem;
//       background: white;
//     }

//     a {
//       font-size: 1rem;
//     }
//   }
// `;

const Container = styled.div`
  &&& {
    z-index: 1000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0rem 1rem;
    }
    .modal-card {
      max-height: 100vh;
      position: fixed;
      top: 0;
      right: 0;
      width: 35rem;
      overflow: hidden;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const Form = props => {
  const {
    isActive,
    onClose,
    setFieldValue,
    values,
    handleSubmit,
    loading,
    fee,
    touched,
    errors,
  } = props;

  console.log('values...', values);

  React.useEffect(() => {
    if (values.period !== 'DATE') {
      setFieldValue('startDate', null);
      setFieldValue('startTime', null);
      setFieldValue('endDate', null);
      setFieldValue('endTime', null);
    }
    if (values.period !== 'DAYS') {
      setFieldValue('days', []);
    }
  }, [values.period]);

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add / Edit Charge or Fee</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body" style={{ overflowX: 'hidden' }}>
          <Column.Group gapSize={8} multiline vcentered>
            <Column size="half">
              <Input
                label="Fee Display Name"
                value={values.displayName}
                onChange={event => {
                  setFieldValue('displayName', event.target.value);
                  if (fee) {
                    // pass
                  } else {
                    setFieldValue('internalName', event.target.value);
                  }
                }}
                required
                //
                errors={errors.displayName}
                touched={touched.displayName}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              <Select
                label="Status"
                options={[
                  { label: 'ACTIVE', value: 'ACTIVE' },
                  { label: 'INACTIVE', value: 'INACTIVE' },
                ]}
                value={[values.status]}
                onChange={value => setFieldValue('status', value)}
                required
                errors={errors.status}
                touched={touched.status}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              <Input
                label="Fee Display Internal Name"
                value={values.internalName}
                onChange={event =>
                  setFieldValue('internalName', event.target.value)
                }
                required
                errors={errors.internalName}
                touched={touched.internalName}
                disableToastMessage
                disabled={fee}
              />
            </Column>

            {/* <CheckBoxVariant2
                secondary
                label="User Editable"
                name="userEditable"
                value={values.userEditable}
                onChange={value => {
                  setFieldValue('userEditable', value);
                }}
              /> */}
            <Column
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '10px',
              }}
            >
              <span>User Editable</span>
              <Radio
                textBlack
                label="Yes"
                value={values.userEditable === true}
                onChange={() => {
                  if (values.userEditable) {
                    setFieldValue('userEditable', false);
                  } else {
                    setFieldValue('userEditable', true);
                  }
                }}
              />
              <Radio
                textBlack
                label="No"
                value={values.userEditable === false}
                onChange={() => {
                  if (values.userEditable) {
                    setFieldValue('userEditable', false);
                  } else {
                    setFieldValue('userEditable', true);
                  }
                }}
              />
            </Column>
            <Column size="half">
              <Select
                label="Fee Type"
                options={[
                  { label: 'Price', value: 'PRICE' },
                  { label: 'Percentage', value: 'PERCENTAGE' },
                ]}
                value={[values.feeType]}
                onChange={value => setFieldValue('feeType', value)}
                required
                errors={errors.feeType}
                touched={touched.feeType}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              {values.feeType.value === 'PERCENTAGE' ? (
                <InputAddOn
                  addonsText="%"
                  isRight
                  type="number"
                  min="0"
                  id="Fee"
                  label="Fee"
                  value={values.value}
                  onChange={e => {
                    if (e.target.value === '') {
                      setFieldValue('value', null);
                    } else if (!isNaN(parseFloat(e.target.value))) {
                      setFieldValue('value', parseFloat(e.target.value));
                    }
                  }}
                  errors={errors.value}
                  touched={touched.value}
                  disableToastMessage
                />
              ) : (
                <Input
                  label="Fee"
                  value={values.value}
                  type="number"
                  min={0}
                  onChange={event => {
                    if (
                      event.target.value &&
                      !isNaN(parseFloat(event.target.value)) &&
                      parseFloat(event.target.value) >= 0 &&
                      parseFloat(event.target.value) <= 99999
                    ) {
                      setFieldValue('value', event.target.value);
                    } else if (
                      event.target.value.length === 0 ||
                      isNaN(parseFloat(event.target.value))
                    ) {
                      setFieldValue('value', null);
                    }
                  }}
                  onBlur={event =>
                    setFieldValue(
                      'value',
                      parseFloat(event.target.value).toFixed(2),
                    )
                  }
                  required
                  errors={errors.value}
                  touched={touched.value}
                  disableToastMessage
                />
              )}
            </Column>
            <Column size="half">
              <Select
                label="Payment Method"
                options={[
                  { label: 'All', value: 'ALL' },
                  { label: 'Card', value: 'CARD' },
                  { label: 'Cash', value: 'CASH' },
                ]}
                value={[values.paymentMethod]}
                onChange={value => setFieldValue('paymentMethod', value)}
                required
                //
                errors={errors.paymentMethod}
                touched={touched.paymentMethod}
                disableToastMessage
              />
            </Column>
            <Column size="half" />
            <Column
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '10px',
              }}
            >
              <span>Period</span>

              <Radio
                textBlack
                label="N/A"
                value={values.period === 'NA'}
                onChange={() => {
                  setFieldValue('period', 'NA');
                }}
              />
              <Radio
                textBlack
                label="Day(s)"
                value={values.period === 'DAYS'}
                onChange={() => {
                  setFieldValue('period', 'DAYS');
                }}
              />
              <Radio
                textBlack
                label="Date(s)"
                value={values.period === 'DATE'}
                onChange={() => {
                  setFieldValue('period', 'DATE');
                }}
                style={{ paddingRight: '10px' }}
              />
            </Column>

            {values.period === 'DATE' && (
              <div>
                <Column>
                  <DateTimePicker
                    label="Start Date"
                    timeLabel="Start Time"
                    date={values.startDate}
                    time={values.startTime}
                    onDateChange={value => {
                      setFieldValue('startDate', value);
                      setFieldValue('endDate', value);
                    }}
                    onTimeChange={value => {
                      setFieldValue('startTime', value ? moment(value) : null);
                    }}
                    minDate={new Date()}
                    errors={errors.startDate}
                    touched={touched.startDate}
                    required
                  />
                </Column>
                <Column>
                  <DateTimePicker
                    label="End Date"
                    timeLabel="End Time"
                    date={values.endDate}
                    time={values.endTime}
                    onDateChange={value => {
                      setFieldValue('endDate', value);
                    }}
                    onTimeChange={value => {
                      setFieldValue('endTime', value ? moment(value) : null);
                    }}
                    minDate={new Date(values.startDate)}
                    errors={errors.endDate}
                    touched={touched.endDate}
                    disabled={values.startDate === null}
                    required
                  />
                </Column>
              </div>
            )}

            {values.period === 'DAYS' && (
              <Column
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gap: '20px',
                }}
              >
                <CheckBoxVariant2
                  secondary
                  label="MON"
                  name="MON"
                  value={!!values.days.find(ele => ele === 'MON')}
                  onChange={value => {
                    if (value) {
                      const temp = values.days;
                      temp.push('MON');
                      setFieldValue('days', temp);
                    } else {
                      setFieldValue(
                        'days',
                        values.days.filter(ele => ele !== 'MON'),
                      );
                    }
                  }}
                />
                <CheckBoxVariant2
                  secondary
                  label="TUE"
                  name="TUE"
                  value={!!values.days.find(ele => ele === 'TUE')}
                  onChange={value => {
                    if (value) {
                      const temp = values.days;
                      temp.push('TUE');
                      setFieldValue('days', temp);
                    } else {
                      setFieldValue(
                        'days',
                        values.days.filter(ele => ele !== 'TUE'),
                      );
                    }
                  }}
                />
                <CheckBoxVariant2
                  secondary
                  label="WED"
                  name="WED"
                  value={!!values.days.find(ele => ele === 'WED')}
                  onChange={value => {
                    if (value) {
                      const temp = values.days;
                      temp.push('WED');
                      setFieldValue('days', temp);
                    } else {
                      setFieldValue(
                        'days',
                        values.days.filter(ele => ele !== 'WED'),
                      );
                    }
                  }}
                />
                <CheckBoxVariant2
                  secondary
                  label="THU"
                  name="THU"
                  value={!!values.days.find(ele => ele === 'THU')}
                  onChange={value => {
                    if (value) {
                      const temp = values.days;
                      temp.push('THU');
                      setFieldValue('days', temp);
                    } else {
                      setFieldValue(
                        'days',
                        values.days.filter(ele => ele !== 'THU'),
                      );
                    }
                  }}
                />
                <CheckBoxVariant2
                  secondary
                  label="FRI"
                  name="FRI"
                  value={!!values.days.find(ele => ele === 'FRI')}
                  onChange={value => {
                    if (value) {
                      const temp = values.days;
                      temp.push('FRI');
                      setFieldValue('days', temp);
                    } else {
                      setFieldValue(
                        'days',
                        values.days.filter(ele => ele !== 'FRI'),
                      );
                    }
                  }}
                />
                <CheckBoxVariant2
                  secondary
                  label="SAT"
                  name="SAT"
                  value={!!values.days.find(ele => ele === 'SAT')}
                  onChange={value => {
                    if (value) {
                      const temp = values.days;
                      temp.push('SAT');
                      setFieldValue('days', temp);
                    } else {
                      setFieldValue(
                        'days',
                        values.days.filter(ele => ele !== 'SAT'),
                      );
                    }
                  }}
                />
                <CheckBoxVariant2
                  secondary
                  label="SUN"
                  name="SUN"
                  value={!!values.days.find(ele => ele === 'SUN')}
                  onChange={value => {
                    if (value) {
                      const temp = values.days;
                      temp.push('SUN');
                      setFieldValue('days', temp);
                    } else {
                      setFieldValue(
                        'days',
                        values.days.filter(ele => ele !== 'SUN'),
                      );
                    }
                  }}
                />
              </Column>
            )}
          </Column.Group>
        </section>

        <footer className="modal-card-foot">
          <button type="button" className="button" onClick={onClose}>
            cancel
          </button>
          <button
            type="button"
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </footer>
      </div>
    </Container>
  );
};

const AddEditForm = withFormik({
  mapPropsToValues: ({ fee }) => {
    let initFeeType;
    let initStatus;
    let initPaymentMethod;
    const initDays = [];
    const feeTypeObj = {
      PRICE: { label: 'Price', value: 'PRICE' },
      PERCENTAGE: { label: 'Percentage', value: 'PERCENTAGE' },
    };

    const paymentMethodObj = {
      ALL: { label: 'All', value: 'ALL' },
      CARD: { label: 'Card', value: 'CARD' },
      CASH: { label: 'Cash', value: 'CASH' },
    };

    const statusObj = {
      ACTIVE: { label: 'ACTIVE', value: 'ACTIVE' },
      INACTIVE: { label: 'INACTIVE', value: 'INACTIVE' },
    };

    if (fee) {
      initFeeType = feeTypeObj[fee.fee_type];
      initStatus = statusObj[fee.status];
      initPaymentMethod = paymentMethodObj[fee.payment_method];
    } else {
      initFeeType = feeTypeObj.PRICE;
      initStatus = statusObj.ACTIVE;
      initPaymentMethod = paymentMethodObj.ALL;
    }

    if (fee && fee.days) {
      fee.days.forEach(ele => {
        if (ele.is_active) {
          initDays.push(ele.day);
        }
      });
    }

    return {
      internalName: fee ? fee.internal_name : '',
      displayName: fee ? fee.display_name : '',
      status: initStatus,
      userEditable: fee ? fee.is_editable : false,
      period: fee ? fee.period : 'NA',
      feeType: initFeeType,
      value: fee ? fee.value : '',
      paymentMethod: initPaymentMethod,
      startDate:
        fee && fee.start_time && fee.start_date
          ? moment(
              parse(
                `${fee.start_date} ${fee.start_time}`,
                'dd-MM-yyyy hh:mm a',
                new Date(),
              ),
            )
          : null,

      startTime:
        fee && fee.start_time && fee.start_date
          ? moment(
              parse(
                `${fee.start_date} ${fee.start_time}`,
                'dd-MM-yyyy hh:mm a',
                new Date(),
              ),
            )
          : null,
      endDate:
        fee && fee.end_time && fee.end_date
          ? moment(
              parse(
                `${fee.end_date} ${fee.end_time}`,
                'dd-MM-yyyy hh:mm a',
                new Date(),
              ),
            )
          : null,
      endTime:
        fee && fee.end_time && fee.end_date
          ? moment(
              parse(
                `${fee.end_date} ${fee.end_time}`,
                'dd-MM-yyyy hh:mm a',
                new Date(),
              ),
            )
          : null,
      days: initDays,
    };
  },

  validationSchema: yup.object().shape({
    internalName: yup
      .string()
      .nullable('Fee internal name is required!')
      .min(3, 'Please enter at least 3 characters')
      .max(20, 'Please enter less than 20 characters')
      .required('Fee internal name is required!'),
    displayName: yup
      .string()
      .nullable('Fee display name is required!')
      .min(3, 'Please enter at least 3 characters')
      .max(20, 'Please enter less than 20 characters')
      .required('Fee display name is required!'),
  }),

  handleSubmit: (values, { props }) => {
    const tempDays = values.days.map(ele => ({ day: ele, is_active: true }));

    const data = { value: values.value };

    if (isNaN(values.value)) {
      toast.error('Fee is required!');
      return;
    }

    if (values.feeType.value === 'PERCENTAGE') {
      const percentageValidationSchema = yup.object({
        value: yup
          .number()
          .nullable()
          .required('Fee is required!')
          .positive('Fee must be positive!')
          .lessThan(100.0, 'Maximum value of Fee exceeded!'),
      });

      try {
        percentageValidationSchema.validateSync(data, { abortEarly: false });
      } catch (error) {
        toast.error(error.errors[0]);
        return;
      }
    } else {
      const priceValidationSchema = yup.object({
        value: yup
          .number()
          .nullable()
          .required('Fee is required!')
          .positive('Fee must be positive!')
          .lessThan(100.0, 'Maximum value of Fee exceeded!'),
      });

      try {
        priceValidationSchema.validateSync(data, { abortEarly: false });
      } catch (error) {
        toast.error(error.errors[0]);
        return;
      }
    }

    if (values.period === 'DATE') {
      if (values.startDate === null) {
        toast.error('Start Date is required!');
        return;
      }
      // if (values.startTime === null) {
      //   toast.error('Start Time is required!');
      //   return;
      // }
      if (values.endDate === null) {
        toast.error('End Date is required!');
        return;
      }
      // if (values.endTime === null) {
      //   toast.error('End Time is required!');
      //   return;
      // }
      if (
        new Date(values.startDate).getTime() >
        new Date(values.endDate).getTime()
      ) {
        toast.error('End Date must not be less than Start Date!');
        return;
      }
    }
    if (values.period === 'DAYS') {
      if (values.days.length === 0) {
        toast.error('Please select atleast one day!');
        return;
      }
    }
    props.onSubmit({
      display_name: values.displayName,
      internal_name: values.internalName,
      is_editable: values.userEditable,
      fee_type: values.feeType.value,
      value: values.value,
      payment_method: values.paymentMethod.value,
      period: values.period,
      start_date: values.startDate
        ? format(new Date(values.startDate), 'dd-MM-yyyy')
        : null,
      start_time: values.startTime
        ? format(new Date(values.startTime), 'hh:mm a')
        : '12:00 am',
      end_date: values.endDate
        ? format(new Date(values.endDate), 'dd-MM-yyyy')
        : null,
      end_time: values.endTime
        ? format(new Date(values.endTime), 'hh:mm a')
        : '11:59 pm',
      days: tempDays,
      status: values.status.value,
    });
  },
  displayName: 'AddEditForm',
  enableReinitialize: true,
})(Form);

export default AddEditForm;
