import React, { useEffect } from 'react';
import styled from 'styled-components';
import { forEach } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Radio, TagInputs } from '../../../components/elements';

const RadioWrapper = styled.div`
  padding: 0rem 0.3rem;
`;
const Span = styled.span`
  color: red;
`;

const parseSuggestion = values => {
  // const args = values.filter(
  //   item => item.event_occurrence_id === '' && item.ticket_id === '',
  // );
  const events = [];
  forEach(values, item => {
    events.push({ value: item.event_id, label: item.event_name });
  });

  return events;
};

const EventSelection = ({ values, setFieldValue, events, location }) => {
  useEffect(() => {
    if (location.pathname !== '/add-event-deal') {
      if (events.length === values.applies_to_event) {
        setFieldValue('eventsSelection', 'all');
      } else {
        setFieldValue('eventsSelection', 'selected');
      }
    }
  }, []);

  return (
    <div className="field">
      <div className="label">
        Applicable Event(s)<Span>*</Span>
      </div>
      <div className="control">
        <div className="is-flex">
          <RadioWrapper>
            <Radio
              label="All"
              value={values.eventsSelection === 'all'}
              onChange={value => {
                if (value) {
                  setFieldValue('eventsSelection', 'all');
                  setFieldValue('applies_to_event', events);
                  setFieldValue('applies_to_tickets', []);
                  setFieldValue('ticketsSelection', '');
                }
              }}
            />
          </RadioWrapper>
          <RadioWrapper>
            <Radio
              label="Selected Events(s)"
              value={values.eventsSelection === 'selected'}
              onChange={value => {
                if (value) {
                  setFieldValue('eventsSelection', 'selected');
                  setFieldValue('applies_to_event', []);
                  setFieldValue('applies_to_tickets', []);
                  setFieldValue('ticketsSelection', '');
                }
              }}
            />
          </RadioWrapper>
        </div>
        <br />
        {values.eventsSelection === 'all' ? (
          <React.Fragment>
            {events.length !== 0 && (
              <TagInputs
                label="Events(s)"
                value={parseSuggestion(events)}
                required
                suggestions={parseSuggestion(events)}
                disabled
              />
            )}
          </React.Fragment>
        ) : (
          <TagInputs
            label="Events(s)"
            value={parseSuggestion(values.applies_to_event)}
            suggestions={parseSuggestion(events)}
            onChange={value => {
              if (value) {
                const applies_to = value.map(item => ({
                  event_id: item.value,
                  event_occurrence_id: '',
                  ticket_id: '',
                  ticket_price: 0,
                  event_name: item.label,
                  ticket_name: '',
                }));
                setFieldValue('applies_to_event', applies_to);
              } else {
                setFieldValue('applies_to_event', []);
                setFieldValue('ticketsSelection', '');
              }
              setFieldValue('applies_to_tickets', []);
              setFieldValue('ticketsSelection', '');
            }}
            required
            disabled={values.eventsSelection === ''}
          />
        )}
      </div>
    </div>
  );
};
export default withRouter(EventSelection);
