import React from 'react';
import { Table } from '../../../components/elements';
import GoogleOrderFeedDeleteButton from './GoogleOrderFeedDeleteButton';

const GoogleFeedsTable = ({
  setSort,
  setPlaceNameSort,
  placeNameSort,
  setCitySort,
  citySort,
  googleFeeds,
  history,
}) => {
  console.log('Google Feeds Table...');
  return (
    <Table>
      <thead>
        <tr>
          <th>
            <div>
              <span>
                <a
                  onClick={() => {
                    setPlaceNameSort(placeNameSort === 'AES' ? 'DES' : 'AES');
                    setSort(
                      placeNameSort === 'AES'
                        ? 'PLACE_NAME_DESC'
                        : 'PLACE_NAME_ASC',
                    );
                  }}
                >
                  <i
                    className={
                      placeNameSort === 'AES'
                        ? 'fas fa-sort-amount-down'
                        : 'fas fa-sort-amount-up'
                    }
                  />
                </a>
                Place Name
              </span>
            </div>
          </th>
          <th>
            <div>
              <span>
                <a
                  onClick={() => {
                    setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                    setSort(
                      citySort === 'AES' ? 'CITY_NAME_ASC' : 'CITY_NAME_DESC',
                    );
                  }}
                >
                  <i
                    className={
                      citySort === 'AES'
                        ? 'fas fa-sort-amount-down'
                        : 'fas fa-sort-amount-up '
                    }
                  />
                </a>
                City / Suburb
              </span>
            </div>
          </th>
          {/* <th>
            <div>
              <span>
                <a
                  onClick={() => {
                    setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                    setSort(
                      statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                    );
                  }}
                >
                  <i
                    className={
                      statusSort === 'AES'
                        ? 'fas fa-sort-amount-down'
                        : 'fas fa-sort-amount-up '
                    }
                  />
                </a>{' '}
                Services
              </span>
              <br />
            </div>
          </th> */}

          <th>
            <span>Pickup</span>
          </th>
          <th>
            <span>Delivery</span>
          </th>
          <th>
            <span>Business Website</span>
          </th>
          <th>
            <span>Contact Phone</span>
          </th>
          <th>
            <span>Delete</span>
          </th>
        </tr>
      </thead>
      {googleFeeds.length !== 0 && (
        <tbody>
          {googleFeeds.map(ele => (
            <tr key={ele.google_order_feed_id}>
              <td
                style={{
                  cursor: 'pointer',
                  color: '#00AEEF',
                  whiteSpace: 'nowrap',
                }}
              >
                <span
                  onClick={() => {
                    history.push(`/google-feed/${ele.place_id}`);
                  }}
                >
                  {ele.place_name}
                </span>
              </td>

              <td style={{ whiteSpace: 'nowrap' }}>{ele.city}</td>
              <td>{ele.service_types.find(ele2 => ele2 === 'Pickup')}</td>
              <td>{ele.service_types.find(ele2 => ele2 === 'Delivery')}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{ele.website}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{ele.telephone}</td>
              <td>
                <GoogleOrderFeedDeleteButton
                  googleOrderFeedId={ele.google_order_feed_id}
                />
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </Table>
  );
};

export default GoogleFeedsTable;
