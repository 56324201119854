import React from 'react';
import Input from '../../../components/elements/Input';
import TextArea from '../../../components/elements/TextArea';

import Layout from '../../../components/global/Layout';

const BisinessFee = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  next,
}) => (
  <Layout>
    <div className="container">
      <div className="columns">
        <div className="column is-half">
          <Input
            label="Absorb Fee"
            name="absorbFee"
            value={values.absorbFee}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="column is-half">
          <Input
            label="Pass on Fee"
            name="passOnFee"
            value={values.passOnFee}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          <Input
            label="Ticket Agent Absorb Fee"
            name="ticketAgentAbsorbFee"
            value={values.ticketAgentAbsorbFee}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="column is-half">
          <Input
            label="Ticket Agent Pass on Fee"
            name="ticketAgentpassOnFee"
            value={values.ticketAgentpassOnFee}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          <TextArea
            label="Online Event Payment Types"
            name="onlineEPaymentTypes"
            value={values.onlineEPaymentTypes}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="column is-half">
          <TextArea
            label="Ticket Agent Payment Types"
            name="ticAgentPaymentTypes"
            value={values.ticAgentPaymentTypes}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default BisinessFee;
