import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import MenuSuggestionInput from './MenuSuggestionInput';
import { Input, Select } from '../../../components/elements';

const Text = styled.div`
  font-size: 0.8rem;
  color: #737373;
`;
const OrderBasedRewards = () => (
  <React.Fragment>
    <Column.Group>
      <Column size={7}>
        <MenuSuggestionInput label="Applicable Menus" />
      </Column>
    </Column.Group>
    <React.Fragment>
      <div className="label">Reward Conditions</div>
      <Text>
        Customers earn a cash as credits after a set amount of orders with min
        purchase.
      </Text>
    </React.Fragment>
    <Column.Group>
      <Column size={8}>
        <div className="is-flex">
          <div className="label" style={{ paddingTop: '1.2rem' }}>
            Number of orders to get reward
          </div>

          <Column size={2}>
            <Input type="number" />
          </Column>
        </div>
      </Column>
    </Column.Group>
    <Text>Choose from 2,5,8 or 10 item purchases to received a reward</Text>
    <Column.Group>
      <Column size={8}>
        <div className="is-flex">
          <div className="label" style={{ paddingTop: '1.2rem' }}>
            Min purchase amount per order
          </div>

          <Column size={2}>
            <Input type="number" />
          </Column>
        </div>
      </Column>
    </Column.Group>
    <Text>Choose from $10, $15, $20, $30 $40 and $50</Text>
    <Column.Group>
      <Column size={7}>
        <div className="label" style={{ paddingTop: '1.2rem' }}>
          Reward details
        </div>
        <Text>
          Once the set amount of order is reached, the cash credit can be
          applied to a future order at your resturant
        </Text>
      </Column>
    </Column.Group>
    <Column.Group>
      <Column size={8}>
        <div className="is-flex">
          <div className="label" style={{ paddingTop: '1.2rem' }}>
            Select reward Items
          </div>
          <Column size={4}>
            <Select />
          </Column>
        </div>
      </Column>
    </Column.Group>
    <Column.Group>
      <Column size={7}>
        <div className="label" style={{ paddingTop: '1.2rem' }}>
          Reward redemption validity
        </div>
        <Text>Validity of the reward for the customer to redeem</Text>
      </Column>
    </Column.Group>
    <Column.Group>
      <Column size={8}>
        <div className="is-flex">
          <div className="label" style={{ paddingTop: '1.2rem' }}>
            Max number of days to be redeem the reward
          </div>

          <Column size={2}>
            <Input type="number" />
          </Column>
        </div>
      </Column>
    </Column.Group>
    <Text>
      Choose from credit expiry of 30 days, 60 days, 90 days and 120 days
    </Text>
  </React.Fragment>
);
export default OrderBasedRewards;
