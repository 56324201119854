import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import { ErrorMessage, Loading } from '../../../components/elements';
import Main from './Main';

const searchQuery = gql`
  query search($placeId: [String], $userId: String) {
    search_places(
      input: {
        filter: { place_filter: { place_id: $placeId } }
        user_id: $userId
      }
    ) {
      place_listing {
        place_id
        display_order
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        status
        claim_status
        latitude
        longitude
        service_type {
          description
          name
          display_order
        }
      }
    }
  }
`;

const MenuCustomHours = ({ match }) => {
  const { placeId } = match.params;
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <Query
        query={searchQuery}
        client={client.clientPrivate}
        variables={{ placeId: [placeId], userId }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const {
            search_places: { place_listing },
          } = data;
          return <Main place={place_listing[0]} />;
        }}
      </Query>
    </Layout>
  );
};

export default MenuCustomHours;
