import React from 'react';
// import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { Radio } from '../../../components/elements';

const Search = ({ filterType, setFilterType }) => (
  <Level>
    <Level.Item align="right">
      <Level.Item>
        <Radio
          label="Tried"
          value={filterType === 'tried'}
          onChange={value => {
            if (value) {
              setFilterType('tried');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Love It"
          value={filterType === 'love_it'}
          onChange={value => {
            if (value) {
              setFilterType('love_it');
            }
          }}
        />
      </Level.Item>

      <Level.Item>
        <Radio
          label="Shares"
          value={filterType === 'share'}
          onChange={value => {
            if (value) {
              setFilterType('share');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Bookmarks"
          value={filterType === 'bookmark'}
          onChange={value => {
            if (value) {
              setFilterType('bookmark');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Place Link Clicks"
          value={filterType === 'PLACE LINK CLICKS'}
          onChange={value => {
            if (value) {
              setFilterType('PLACE LINK CLICKS');
            }
          }}
          disabled
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Registered User Page Views"
          value={filterType === 'REGISTERED USER PAGE VIEWS'}
          onChange={value => {
            if (value) {
              setFilterType('REGISTERED USER PAGE VIEWS');
            }
          }}
          disabled
        />
      </Level.Item>
    </Level.Item>
  </Level>
);

export default Search;
