import React from 'react';
import moment from 'moment';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { SubHeading, Input } from '../../../components/elements';

const Wrapper = styled.div`
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Footer = ({
  event,
  subHeading3,
  goToStepOne,
  goToStepTwo,
  step,
  history,
}) => (
  <React.Fragment>
    <Flex>
      <div>
        <ButtonWrapper>
          <Level.Item align="right">
            <a onClick={() => history.goBack()} className="button is-primary">
              <span>Back</span>
            </a>
          </Level.Item>
        </ButtonWrapper>
      </div>
    </Flex>
  </React.Fragment>
);

export default withRouter(Footer);
