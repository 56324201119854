import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import { startCase } from 'lodash';
import styled from 'styled-components';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({
  history,
  location,
  loading,
  dirty,
  children,
  menuName,
  isEdit,
}) => (
  <Wrapper>
    <Level style={{ padding: 0, marginTop: 0, marginBottom: 0 }}>
      <Level.Item
        align="left"
        style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
      >
        <Title
          className="is-size-2"
          style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
        >
          Menu
        </Title>
      </Level.Item>
      <Level.Item
        align="right"
        style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
      >
        <Level.Item style={{ padding: 0, marginTop: 0, marginBottom: 0 }}>
          <a onClick={() => history.goBack()} className="button is-grey">
            <span className="has-text-weight-semibold">Cancel</span>
          </a>
        </Level.Item>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/add-menu'
                  ? 'create_menu'
                  : 'update_menu'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item
                  style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
                >
                  <button
                    type="submit"
                    className={`button is-primary ${loading && 'is-loading'}`}
                    disabled={!dirty}
                  >
                    <span className="has-text-weight-semibold">
                      {isEdit ? 'Submit' : 'Next'}
                    </span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
    <p className="is-size-1">{startCase(menuName)}</p>
    {children}
  </Wrapper>
);

export default withRouter(Header);
