import React from 'react';
import styled from 'styled-components';

import icons from '../../theme/styles/svgIcons';

const Image = styled.img`
  width: ${({ size }) => `${size}rem`};
  
`;

const KrIcon = ({ name, size, ...props }) => (
  <Image src={icons[name]} size={size} {...props} />
);

export default KrIcon;
