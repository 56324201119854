import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Level, Label } from 'rbx';
import { withFormik } from 'formik';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

import uuidv4 from 'uuid/v4';

import Layout from '../../../components/global/Layout';
import {
  Input,
  TextArea,
  Radio,
  CheckBox,
  ReactDateTimePicker,
  MobileInput,
  Select,
  TagInputs,
} from '../../../components/elements';
import Header from './Header';
import { useTableBookingsDetails } from '../TableBookingManager/helpers';
import Footer from './Footer';
import {
  useBookingSlots,
  useCreateTableBooking,
  useUpdateTableBooking,
} from './helpers';
import BookingSlotsList from './BookingSlotsList';
import { useTableSetting } from '../TableBookingSetting/helpers';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 360px 360px;
  gap: 10px;
  padding: 10px;
`;

const GridItem = styled.div`
  padding: 5px 20px;
  text-align: left;
`;

const ActionButtonsContainer = styled.div`
  position: relative;
  left: 40px;
  width: 500px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 0 30px 0;
`;

const Form = ({ ...props }) => {
  const {
    values,
    tableBookingDataProps,
    isSubmitting,
    handleSubmit,
    history,
    setFieldValue,
    errors,
    touched,
    updateTableBooking,
    tableSettingProps,
    isAdd,
  } = props;

  console.log('values', values);

  const { userId: adminUserId } = useStoreState(state => state.auth);

  const { place_id: placeId } = tableBookingDataProps ?? {};

  const tempDate = new Date(values.booking_date)
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replaceAll('/', '-');

  const [, currentDateBookingSlots] = useBookingSlots({
    adminUserId,
    date: [
      tempDate.split('-')[0],
      tempDate.split('-')[1],
      tempDate.split('-')[2],
    ].join('-'),
    placeId,
  });

  console.log('currentDateBookingSlots', currentDateBookingSlots);

  const onChangeBookingStatus = async bookingStatus =>
    updateTableBooking({
      user_id: adminUserId,
      table_booking_id: tableBookingDataProps.table_booking_id,
      booking_status: bookingStatus,
    });

  const onChangeGuestStatus = async guestStatus =>
    updateTableBooking({
      user_id: adminUserId,
      table_booking_id: tableBookingDataProps.table_booking_id,
      guest_status: guestStatus,
    });

  const tableDetailsOptions = (tableSettingProps?.table_details ?? []).map(
    ele => ({
      label: `${ele.table_name} ${ele.table_number}`,
      value: ele.table_number,
    }),
  );

  const tagOptions = (tableSettingProps?.tag ?? []).map(ele => ({
    label: ele,
    value: ele,
  }));

  return (
    <Layout>
      <Header />
      <GridContainer>
        <GridItem>
          <div className="is-flex">
            <div style={{ padding: '10px' }}>
              <Label>Booking Date</Label>
            </div>
            <ReactDateTimePicker
              value={new Date(values.booking_date)}
              onChange={value => {
                setFieldValue('booking_date', value);
              }}
            />
          </div>
        </GridItem>
        <GridItem>
          <div className="is-flex">
            <div style={{ padding: '10px' }}>
              <Label>Booking Status</Label>
            </div>
            <div style={{ padding: '10px' }}>
              <Label>{values.booking_status}</Label>
            </div>
          </div>
        </GridItem>
        <GridItem />
      </GridContainer>
      {!isAdd && (
        <ActionButtonsContainer>
          <button
            disabled={values.booking_status === 'CANCELLED'}
            className="button is-grey is-outlined"
            onClick={() => {
              if (values.booking_status === 'CANCELLED') {
                // pass
              } else {
                swal({
                  text: 'Would you like to cancel this table booking?',
                  buttons: ['Cancel', 'OK'],
                }).then(res => {
                  if (res) {
                    onChangeBookingStatus('CANCELLED');
                    console.log('Cancelling...');
                  }
                });
              }
            }}
          >
            Cancel Booking
          </button>
          <button
            className="button is-warning is-outlined"
            disabled={values.booking_status === 'WAITLISTED'}
            onClick={() => {
              if (values.booking_status === 'WAITLISTED') {
                // pass
              } else {
                swal({
                  text: 'Would you like to waitlist this table booking?',
                  buttons: ['Cancel', 'OK'],
                }).then(res => {
                  if (res) {
                    onChangeBookingStatus('WAITLISTED');
                    console.log('Waitlisting...');
                  }
                });
              }
            }}
          >
            Waitlist Booking
          </button>
          <button disabled className="button is-outlined is-primary">
            Resend Confirmation
          </button>
        </ActionButtonsContainer>
      )}

      <div className="is-flex" style={{ padding: '10px' }}>
        <div style={{ padding: '0 20px' }}>
          <div style={{ padding: '10px', position: 'relative', top: '-8px' }}>
            <Label>Guest Status</Label>
          </div>
        </div>
        <div style={{ padding: '0 20px' }}>
          <div className="is-flex">
            <Level.Item>
              <div>
                <Radio
                  stopClickFn
                  label="UPCOMING"
                  value={values.guest_status === 'UPCOMING'}
                  onChange={() => {
                    if (isAdd) {
                      setFieldValue('guest_status', 'UPCOMING');
                    } else {
                      swal({
                        text: 'Would you like to UPCOMING this table booking?',
                        buttons: ['Cancel', 'OK'],
                      }).then(res => {
                        if (res) {
                          onChangeGuestStatus('UPCOMING');
                          console.log('UPCOMING...');
                        }
                      });
                    }
                  }}
                />
              </div>
            </Level.Item>
            &nbsp;
            <Level.Item>
              <div>
                <Radio
                  stopClickFn
                  label="CHECKED IN"
                  value={values.guest_status === 'CHECKED_IN'}
                  onChange={() => {
                    if (isAdd) {
                      setFieldValue('guest_status', 'CHECKED_IN');
                    } else {
                      swal({
                        text:
                          'Would you like to CHECKED IN this table booking?',
                        buttons: ['Cancel', 'OK'],
                      }).then(res => {
                        if (res) {
                          onChangeGuestStatus('CHECKED_IN');

                          console.log('CHECKED_IN...');
                        }
                      });
                    }
                  }}
                />
              </div>
            </Level.Item>
            &nbsp;
            <Level.Item>
              <div>
                <Radio
                  stopClickFn
                  label="SEATED"
                  value={values.guest_status === 'SEATED'}
                  onChange={() => {
                    if (isAdd) {
                      setFieldValue('guest_status', 'SEATED');
                    } else {
                      swal({
                        text: 'Would you like to SEATED this table booking?',
                        buttons: ['Cancel', 'OK'],
                      }).then(res => {
                        if (res) {
                          onChangeGuestStatus('SEATED');
                          console.log('SEATED...');
                        }
                      });
                    }
                  }}
                />
              </div>
            </Level.Item>
            &nbsp;
            <Level.Item>
              <div>
                <Radio
                  stopClickFn
                  label="NO SHOW"
                  value={values.guest_status === 'NO_SHOW'}
                  onChange={() => {
                    if (isAdd) {
                      setFieldValue('guest_status', 'NO_SHOW');
                    } else {
                      swal({
                        text: 'Would you like to NO SHOW this table booking?',
                        buttons: ['Cancel', 'OK'],
                      }).then(res => {
                        if (res) {
                          onChangeGuestStatus('NO_SHOW');
                          console.log('NO_SHOW...');
                        }
                      });
                    }
                  }}
                />
              </div>
            </Level.Item>
            &nbsp;
          </div>
        </div>
      </div>
      <div className="is-flex" style={{ padding: '10px' }}>
        <div style={{ padding: '20px' }}>
          <div className="is-flex">
            <Label
              style={{ padding: '10px', position: 'relative', top: '-8px' }}
            >
              Number of Guests
            </Label>
            <Input
              value={values.guest_count}
              onChange={e => setFieldValue('guest_count', e.target.value)}
              disableToastMessage
              required
            />
          </div>
        </div>
        <div style={{ padding: '20px', width: '60%' }}>
          <div
            className="is-flex"
            style={{
              padding: '10px',
              position: 'relative',
              top: '-32px',
            }}
          >
            <div style={{ width: '400px' }}>
              <Select
                label="Table Number"
                options={tableDetailsOptions}
                value={(() =>
                  tableDetailsOptions.find(
                    tdo => tdo.value === values.table_number,
                  ))()}
                onChange={value => setFieldValue('table_number', value.value)}
                disableToastMessage
              />
            </div>
          </div>
        </div>
      </div>

      <div className="is-flex">
        <div style={{ padding: '0 40px' }}>
          <Label>Booking Time</Label>
        </div>
        <div>
          <Label>{values.booking_time}</Label>
        </div>
      </div>

      <BookingSlotsList
        placeId={placeId}
        setFieldValue={setFieldValue}
        booking_time={values.booking_time}
      />

      <div className="is-flex" style={{ padding: '0 10px' }}>
        <div style={{ padding: '20px' }}>
          <div className="is-flex">
            <Label
              style={{ padding: '10px', position: 'relative', top: '-8px' }}
            >
              Guest First Name
            </Label>
            <Input
              value={values.first_name}
              onChange={e => setFieldValue('first_name', e.target.value)}
              disableToastMessage
              errors={errors.first_name}
              touched={touched.first_name}
              required
            />
          </div>
        </div>
        <div style={{ padding: '20px' }}>
          <div className="is-flex">
            <Label
              style={{ padding: '10px', position: 'relative', top: '-8px' }}
            >
              Guest Last Name
            </Label>
            <Input
              value={values.last_name}
              onChange={e => setFieldValue('last_name', e.target.value)}
              disableToastMessage
              errors={errors.last_name}
              touched={touched.last_name}
              required
            />
          </div>
        </div>
      </div>

      <div className="is-flex" style={{ padding: '10px' }}>
        <div style={{ padding: '20px' }}>
          <div className="is-flex">
            <Label
              style={{ padding: '10px', position: 'relative', top: '-8px' }}
            >
              Email
            </Label>
            <Input
              value={values.contact_email}
              onChange={e => setFieldValue('contact_email', e.target.value)}
              disableToastMessage
              errors={errors.contact_email}
              touched={touched.contact_email}
              required
            />
          </div>
        </div>
        <div style={{ padding: '20px' }}>
          <div className="is-flex">
            <Label
              style={{ padding: '10px', position: 'relative', top: '-8px' }}
            >
              Phone
            </Label>
            <MobileInput
              name="contact_phone"
              // label="Primary Contact Phone"
              value={values.contact_phone}
              errors={errors.contact_phone}
              touched={touched.contact_phone}
              // onChange={value => setFieldValue('primaryMobile', value)}
              required
              onChange={e => setFieldValue('contact_phone', e)}
              disableToastMessage
            />
          </div>
        </div>
        <div style={{ padding: '20px' }}>
          <div className="is-flex">
            <Label
              style={{ padding: '10px', position: 'relative', top: '-8px' }}
            >
              Source
            </Label>
            <Input value={values.platform} disabled />
          </div>
        </div>
      </div>

      <div style={{ padding: '0 40px' }}>
        <Label>Guest Requests</Label>
        <TextArea
          value={values.guest_notes}
          onChange={e => setFieldValue('guest_notes', e.target.value)}
          name="Guest Requests"
          errors={errors.guest_notes}
          touched={touched.guest_notes}
          optionalText="(Max 144 Characters)"
        />
        {values.guest_notes && (
          <div style={{ textAlign: 'right' }}>
            {values.guest_notes.length > 144 || values.guest_notes.length < 1
              ? ''
              : `Characters left ${144 - values.guest_notes.length}`}
          </div>
        )}

        <Label>Restaurant Notes</Label>
        <TextArea
          optionalText="(Max 144 Characters)"
          onChange={e => console.log(e)}
          name="Restaurant Notes"
        />
      </div>

      <div className="is-flex" style={{ padding: '0 10px' }}>
        <div style={{ padding: '20px 20px 0 20px' }}>
          <div className="is-flex">
            <Label
              style={{ padding: '10px', position: 'relative', bottom: '11px' }}
            >
              Email Notification
            </Label>
            <CheckBox
              value={values.emailNotif}
              onChange={e => {
                setFieldValue('emailNotif', e);
              }}
            />
          </div>
        </div>
        <div style={{ padding: '20px 20px 0 20px' }}>
          <div className="is-flex">
            <Label
              style={{ padding: '10px', position: 'relative', bottom: '11px' }}
            >
              SMS Notification
            </Label>
            <CheckBox
              value={values.smsNotif}
              onChange={e => {
                setFieldValue('smsNotif', e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="is-flex" style={{ padding: '0 10px' }}>
        <div
          style={{
            padding: '0 20px',
            marginBottom: '60px',
            marginLeft: '10px',
          }}
        >
          <div style={{ width: '500px' }}>
            <TagInputs
              label="Tags / Occasion"
              value={values.tags.map(ele => ({ label: ele, value: ele }))}
              suggestions={tagOptions}
              onChange={value =>
                setFieldValue(
                  'tags',
                  value.map(v => v.value),
                )
              }
            />
          </div>
        </div>
      </div>
      <Footer
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        history={history}
      />
    </Layout>
  );
};

const AddModifyBookingForm = withFormik({
  mapPropsToValues: ({ tableBookingDataProps }) => ({
    contact_email:
      tableBookingDataProps &&
      !isNull(tableBookingDataProps?.contact?.contact_email)
        ? tableBookingDataProps.contact.contact_email
        : '',

    contact_phone:
      tableBookingDataProps &&
      !isNull(tableBookingDataProps?.contact?.contact_phone)
        ? tableBookingDataProps.contact.contact_phone
        : '',

    first_name:
      tableBookingDataProps &&
      !isNull(tableBookingDataProps?.contact?.first_name)
        ? tableBookingDataProps.contact.first_name
        : '',
    last_name:
      tableBookingDataProps &&
      !isNull(tableBookingDataProps?.contact?.last_name)
        ? tableBookingDataProps.contact.last_name
        : '',

    platform:
      tableBookingDataProps && !isNull(tableBookingDataProps?.platform)
        ? tableBookingDataProps.platform
        : 'ADMIN',

    guest_notes:
      tableBookingDataProps && !isNull(tableBookingDataProps.guest_notes)
        ? tableBookingDataProps.guest_notes
        : '',

    tags:
      tableBookingDataProps && !isNull(tableBookingDataProps.tags)
        ? tableBookingDataProps.tags
        : [],

    booking_time:
      tableBookingDataProps && !isNull(tableBookingDataProps.booking_time)
        ? tableBookingDataProps.booking_time
        : '',
    guest_count:
      tableBookingDataProps && !isNull(tableBookingDataProps?.guest_count)
        ? tableBookingDataProps.guest_count
        : '',

    table_number:
      tableBookingDataProps && !isNull(tableBookingDataProps?.table_number)
        ? tableBookingDataProps.table_number
        : '',

    booking_date:
      tableBookingDataProps && !isNull(tableBookingDataProps?.booking_date)
        ? [
            tableBookingDataProps.booking_date.split('-')[1],
            tableBookingDataProps.booking_date.split('-')[0],
            tableBookingDataProps.booking_date.split('-')[2],
          ].join('-')
        : new Date(),

    booking_status:
      tableBookingDataProps && !isNull(tableBookingDataProps.booking_status)
        ? tableBookingDataProps.booking_status
        : 'Confirmed',

    guest_status:
      tableBookingDataProps && !isNull(tableBookingDataProps?.guest_status)
        ? tableBookingDataProps.guest_status
        : 'UPCOMING',

    emailNotif: false,
    smsNotif: false,
  }),

  validationSchema: yup.object().shape({
    contact_email: yup
      .string()
      .required('Email is required')
      .email('Invalid email format'),
    contact_phone: yup
      .string()
      .max(10, 'Please enter a valid phone number!')
      .required('Phone number is required'),
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    guest_notes: yup
      .string()
      .max(144, 'Notes must be 144 characters or less')
      .test(
        'not-all-space',
        'Notes cannot be empty or contain only spaces',
        value => (value ? value.trim().length > 0 : true),
      ),
  }),
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: async (values, { setSubmitting, props }) => {
    setSubmitting(true);

    const { createTableBooking, isAdd, placeId, history } = props;
    const {
      contact_email,
      contact_phone,
      first_name,
      last_name,
      booking_date,
      booking_status,
      booking_time,
      guest_count,
      guest_notes,
      guest_status,
      table_number,
      tags,
    } = values;

    if (isAdd) {
      const input = {
        contact: {
          contact_email,
          contact_phone,
          first_name,
          last_name,
        },
        booking_date: new Date(booking_date)
          .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          .replaceAll('/', '-'),
        booking_status,
        booking_time,
        guest_count,
        guest_notes,
        guest_status,
        table_booking_id: uuidv4(),
        table_number,
        tags,
        place_id: placeId,
        service_type_setting_id:
          props.tableSettingProps.service_type_setting_id,
      };
      const res = await createTableBooking(input);
      if (res) {
        swal('Successfully created table bookings').then(() => {
          history.goBack();
        });
      } else {
        toast.error('Error while updating table booking');
      }
    } else {
      const { table_booking_id, user_id } = props.tableBookingDataProps;
      const input = {
        contact: {
          contact_email,
          contact_phone,
          first_name,
          last_name,
        },
        booking_date: new Date(booking_date)
          .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          .replaceAll('/', '-'),
        booking_time,
        guest_count,
        guest_notes,
        table_booking_id,
        table_number,
        tags,
        user_id,
        place_id: placeId,
        service_type_setting_id:
          props.tableSettingProps.service_type_setting_id,
      };
      if (booking_status === 'CONFIRMED') {
        // pass
      } else {
        input.booking_status = 'CONFIRMED';
      }
      const res = await props.updateTableBooking(input);
      if (res) {
        swal('Successfully updated table bookings').then(() => {
          history.goBack();
        });
      } else {
        toast.error('Error while updating table booking');
      }
    }
    setSubmitting(false);
  },
  displayName: 'Form',
})(Form);

const useRefState = refValue => {
  const initialiseRef = React.useRef(true);
  const [refState, setRefState] = React.useState();

  if (initialiseRef.current && refValue) {
    setRefState(refValue);
    initialiseRef.current = false;
  }
  return [refState, setRefState];
};

const AddModifyBooking = ({ match, history }) => {
  const [tableBookingId, ,] = useRefState(match.params.id);
  const [placeId, ,] = useRefState(match.params.placeId);
  const { userId: adminUserId } = useStoreState(state => state.auth);
  const [
    updatingTableBooking,
    updateTableBooking,
    reloadTableBookingData,
  ] = useUpdateTableBooking();
  const [dataLoading, tableBookingData] = useTableBookingsDetails({
    adminUserId,
    tableBookingId,
    reloadTableBookingData,
  });
  const tableBookingDataProps = tableBookingData ?? null;
  const [tableSettingProps] = useTableSetting({ placeId });

  const [, createTableBooking, ,] = useCreateTableBooking();

  const isAdd = tableBookingId === undefined;

  if (dataLoading) {
    return (
      <Layout>
        <Header />
        loading...
      </Layout>
    );
  }
  return (
    <AddModifyBookingForm
      history={history}
      isAdd={isAdd}
      placeId={placeId}
      tableSettingProps={tableSettingProps}
      updatingTableBooking={updatingTableBooking}
      updateTableBooking={updateTableBooking}
      createTableBooking={createTableBooking}
      tableBookingDataProps={tableBookingDataProps}
    />
  );
};

export default AddModifyBooking;
