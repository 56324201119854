import React from 'react';

const AesDes = ({ sort, setSort, aes, des }) => (
  <a onClick={() => setSort(sort === des ? aes : des)}>
    <i className={`fas fa-sort-amount-${sort === des ? 'up' : 'down'}`} />
  </a>
);

const TableHead = ({ sort, setSort }) => (
  <thead>
    <tr>
      <th>
        <AesDes
          sort={sort}
          setSort={setSort}
          aes="PLACE_NAME_ASC"
          des="PLACE_NAME_DESC"
        />
        Place Name
      </th>
      <th>
        <AesDes
          sort={sort}
          setSort={setSort}
          aes="PLACE_CITY_ASC"
          des="PLACE_CITY_DESC"
        />
        City / Suburb
      </th>

      <th>
        {/* <AesDes
          sort={sort}
          setSort={setSort}
          aes="UPDATED_ASC"
          des="UPDATED_DESC"
        /> */}
        Created By
      </th>
      <th>
        <AesDes
          sort={sort}
          setSort={setSort}
          aes="CREATED_ASC"
          des="CREATED_DESC"
        />
        Created By
      </th>

      <th>
        <AesDes
          sort={sort}
          setSort={setSort}
          aes="STATUS_ASC"
          des="STATUS_DESC"
        />
        Status
      </th>
    </tr>
  </thead>
);

export default TableHead;
