import React, { useState } from 'react';
import { Tab } from 'rbx';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { isNull } from 'lodash';
import * as yup from 'yup';
import Header from './Header';
import { Box, Tabs } from '../../../components/elements';
import PaymentsDetail from './PaymentsDetail';
import UpcomingPayments from './UpcomingPayments';

const Container = styled.div``;

const MainForm = props => {
  const [isActiveTab, setIsActiveTab] = useState('PaymentsDetail');

  return (
    <>
      <Header isActiveTab={isActiveTab} />
      <Box>
        <Tabs className="tabs  is-fullwidth">
          <Tab
            active={isActiveTab === 'PaymentsDetail'}
            onClick={() => setIsActiveTab('PaymentsDetail')}
          >
            Payments Detail
          </Tab>

          <Tab
            active={isActiveTab === 'UpcomingPayments'}
            onClick={() => setIsActiveTab('UpcomingPayments')}
          >
            Upcoming Payments
          </Tab>
        </Tabs>
        <Container>
          <>
            {isActiveTab === 'PaymentsDetail' && <PaymentsDetail {...props} />}
            {isActiveTab === 'UpcomingPayments' && <UpcomingPayments />}
          </>
        </Container>
      </Box>
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ orders }) => ({
    take_order:
      orders && !isNull(orders.take_order) ? orders.take_order : false,
  }),
  validationSchema: yup.object().shape({}),

  handleSubmit: () => {},

  displayName: 'MainForm',
})(MainForm);

export default Form;
