import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { isNull, first } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';

import client from '../../../utils/apolloClient';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const checkInAndOutMutation = gql`
  mutation checkInAndOut($input: TicketRecordCheckInInput) {
    update_ticket_record_checkin(input: $input) {
      ticket_record_id
      check_in {
        type
        timestamp
      }
      error {
        description
      }
    }
  }
`;

const CheckInList = ({
  ticket,
  noOfCheckingAttendees,
  setNoOfCheckingAttendees,
}) => {
  const checkInStatus =
    ticket && !isNull(ticket.check_in) ? first(ticket.check_in).type : '';
  const [checkInLocal, setCheckInLocal] = useState(checkInStatus);

  useEffect(() => {
    setCheckInLocal(checkInStatus);
  }, [checkInStatus]);

  const { userId } = useStoreState(state => state.auth);
  console.log('ticket', ticket);
  return (
    <tr>
      <td>
        <Link to={`/edit-attendee-info/${ticket.ticket_id}?type=details`}>
          {ticket.attendee.attendee_name}
        </Link>
      </td>
      <td>{ticket.attendee.attendee_email}</td>
      <td>{ticket.ticket_name}</td>
      <td>{ticket.booking_number}</td>
      <td>{ticket.ticket_number}</td>
      <td>
        <Flex>
          <span className="icon">
            {checkInLocal === '' && (
              <i className="far fa-square fa-lg has-text-grey-light	" />
            )}
            {checkInLocal === 'CHECK_IN' && (
              <i className="fas fa-check-square fa-lg has-text-success" />
            )}
            {checkInLocal === 'CHECK_OUT' && (
              <i className="fas fa-times-circle fa-lg has-text-warning" />
            )}
          </span>
        </Flex>
      </td>
      <td>
        <Mutation
          client={client.clientPrivate}
          mutation={checkInAndOutMutation}
          variables={{
            input: {
              user_id: userId,
              ticket_record_id: ticket && ticket.ticket_record_id,
              check_in: {
                type: checkInLocal === 'CHECK_IN' ? 'CHECK_OUT' : 'CHECK_IN',
              },
            },
          }}
          onCompleted={({ update_ticket_record_checkin }) => {
            if (!isNull(update_ticket_record_checkin.error)) {
              update_ticket_record_checkin.error.map(item =>
                toast.error(item.description),
              );
            } else {
              if (checkInLocal === 'CHECK_IN') {
                setNoOfCheckingAttendees(noOfCheckingAttendees - 1);
              } else {
                setNoOfCheckingAttendees(noOfCheckingAttendees + 1);
              }
              setCheckInLocal(
                first(update_ticket_record_checkin.check_in).type,
              );
            }
          }}
        >
          {(update_ticket_record_checkin, { loading }) => (
            <button
              className={`button is-primary is-fullwidth ${loading &&
                'is-loading'}`}
              onClick={() => update_ticket_record_checkin()}
            >
              {checkInLocal === 'CHECK_IN' ? 'Check Out' : 'Check In'}
            </button>
          )}
        </Mutation>
      </td>
      <td>
        <Link to={`/edit-attendee-info/${ticket.ticket_id}?type=details`}>
          <span className="icon ">
            {checkInStatus === 'CHECK_IN' ? (
              <i className="fas fa-pencil-alt" />
            ) : (
              <i className="fas fa-pencil-alt" />
            )}
          </span>
        </Link>
      </td>
      <td>
        <Link to={`/edit-attendee-info/${ticket.ticket_id}?type=activity`}>
          <span className="icon">
            <i className="fas fa-eye" />
          </span>
        </Link>
      </td>
    </tr>
  );
};

export default CheckInList;
