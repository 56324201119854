import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import client from '../../../../utils/apolloClient';

const deleteNotificationTemplateMutation = gql`
  mutation deleteNotificationTemplateMutation(
    $userId: String
    $notificationId: String
  ) {
    delete_notification_template(
      input: { user_id: $userId, notification_id: $notificationId }
    ) {
      notification_id
      name
      status
      notification_platform
      error {
        code
        description
      }
    }
  }
`;
const DeleteEmailTemplate = ({ notificationId, refetch = () => {} }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteNotificationTemplateMutation}
      onCompleted={({ delete_notification_template }) => {
        if (!isNull(delete_notification_template.error)) {
          delete_notification_template.error.map(item =>
            toast.error(item.description),
          );
        } else {
          toast.success('Email Notification template deleted successfully!');
          refetch();
        }
      }}
    >
      {(delete_notification_template, { loading }) => (
        <button
          className={`button ${loading && 'is-loading'}`}
          onClick={() => {
            swal({
              title: 'Are you sure?',
              // eslint-disable-next-line max-len
              text: `Are you sure you want to delete this Template?`,
              buttons: ['Cancel', 'Yes'],
              dangerMode: true,
            }).then(willDelete => {
              if (willDelete) {
                delete_notification_template({
                  variables: {
                    userId,
                    notificationId,
                  },
                });
              }
            });
          }}
        >
          <i className="fas fa-trash has-text-grey" />
        </button>
      )}
    </Mutation>
  );
};

export default DeleteEmailTemplate;
