import React, { useState, useEffect } from 'react';
import { Table } from 'rbx';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';
import client from '../../../utils/apolloClient';
import {
  Table as CustomTable,
  // CustomInput,
  Pagination,
  // Input,
  Loading,
  Message,
} from '../../../components/elements';
import PhotoFlagList from './PhotoFlagList';

const searchFlags = gql`
  query searchFlags($input: SearchInput) {
    search_flags(input: $input) {
      total_size
      total_pages
      flag_listing {
        flag_id
        flag_type
        flag_content
        object_id
        object_type
        parent_type
        parent_id
        status
        audit {
          updated_by
          updated_at
          created_at
          created_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const PhotoFlags = ({ review }) => {
  const objectId = review.image_id;
  const flagFilter = {
    object_id: objectId,
  };

  const [reviewData, setReviewData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const input = {};
  Object.assign(input, { from: pageFrom, size: listSize });

  return (
    <React.Fragment>
      {totalElements > 0 && (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          currentPage={currentPage}
          totalElements={totalElements}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Login
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              {' '}
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Updated At
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Flag Content
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Query
          client={client.clientPrivate}
          query={searchFlags}
          variables={{ input: { filter: { flag_filter: flagFilter } } }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>{error.message}</Message>;
            }
            const { search_flags } = data;
            console.log('search_flags', search_flags);

            return (
              <React.Fragment>
                <View
                  reviewData={reviewData}
                  setReviewData={setReviewData}
                  search_flags={search_flags}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </CustomTable>
    </React.Fragment>
  );
};

export default PhotoFlags;

const View = ({
  search_flags,
  setPageCount,
  setTotalElements,
  setReviewData,
  reviewData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_flags.total_pages);
    setTotalElements(search_flags.total_size);
    if (search_flags.flag_listing) {
      setReviewData(search_flags.flag_listing);

      const userFilter = search_flags.flag_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');
          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_flags.total_pages]);
  return (
    <Table.Body>
      {reviewData &&
        reviewData.map(flag => <PhotoFlagList flag={flag} users={users} />)}
    </Table.Body>
  );
};
