import React from 'react';
import AsyncSelect from 'react-select/async';
import { forEach } from 'lodash';

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '1px',
    // height: '29px',
    fontSize: '0.8rem',
  }),
  input: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    minHeight: '1px',
    height: '11px',
  }),
  clearIndicator: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: '1px',
    // height: '27px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: provided => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px',
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8rem',
  }),
};

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const ReactSelectInput = ({ promiseFunction, ...props }) => (
  <AsyncSelect
    cacheOptions
    styles={customStyles}
    loadOptions={promiseFunction}
    value={props.defaultValue}
    {...props}
  />
);
export default ReactSelectInput;
