import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import { omit } from 'lodash';

import {
  Input,
  ReactDateTimePicker,
  CheckBox,
} from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

// const options = [
//   { value: 'card', label: 'Card' },
//   { value: 'cash', label: 'Cash' },
// ];

const PlaceSearch = ({
  placeName,
  city,
  startDate,
  endDate,
  routeState,
  history,
  searchByOrderNumber,
  setSearchByOrderNumber,
  orderNumber,
  setOrderNumber,
  setOrders,
  refetchData,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/orders-dashboard${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };

  const setDateHours = dateObject => {
    dateObject.setHours(0, 0, 0, 0);
    return dateObject;
  };

  const startDateISOString = dateObject =>
    setDateHours(dateObject).toISOString();

  return (
    <Wrapper>
      <CheckBox
        label="Search by Order Number"
        name="search_by_order_no"
        value={searchByOrderNumber}
        onChange={value => {
          setOrders([]);
          setOrderNumber('');
          setSearchByOrderNumber(value);
          if (!value) {
            refetchData();
          }
        }}
      />

      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeName}
              placeholder="Start entering place name..."
              onChange={event =>
                handlePush({ placeName: event.target.value }, 'placeName')
              }
            />
          </div>

          <div className="column is-3">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={value => {
                    handlePush({ city: value.name }, 'city');
                  }}
                  onHandleClear={() => handlePush({ city: '' }, 'city')}
                  placeholder="Start entering company name..."
                />
              </div>
            </div>
          </div>
          {searchByOrderNumber ? (
            <div className="column is-4">
              <Input
                label="Order Number"
                type="text"
                value={orderNumber}
                placeholder="Start entering an Order Number..."
                onChange={e => {
                  setOrderNumber(e.target.value);
                }}
              />
            </div>
          ) : (
            <>
              <div className="column">
                <ReactDateTimePicker
                  value={startDate || startDateISOString(new Date())}
                  // maxDate={moment(endDate || moment().toISOString()).toDate()}
                  label="From"
                  onChange={date => {
                    const a = moment(startDateISOString(new Date(date)));
                    const b = moment(endDate || moment().toISOString());
                    const daysDiff = b.diff(a, 'days');
                    if (daysDiff <= 0 || daysDiff > 45) {
                      handlePush(
                        {
                          from: startDateISOString(new Date(date)),
                          to: moment(date)
                            .set({
                              hour: 23,
                              minute: 59,
                              second: 0,
                              millisecond: 0,
                            })
                            .toISOString(),
                        },
                        'from',
                      );
                    } else {
                      handlePush(
                        {
                          from: startDateISOString(new Date(date)),
                          // to: moment(date)
                          //   .add(45, 'days')
                          //   .toISOString(),
                        },
                        'from',
                      );
                    }
                  }}
                  clear={false}
                />
              </div>
              <div className="column">
                <ReactDateTimePicker
                  minDate={moment(
                    startDate || startDateISOString(new Date()),
                  ).toDate()}
                  maxDate={moment(startDate || startDateISOString(new Date()))
                    .add(45, 'days')
                    .toDate()}
                  value={endDate || moment().toISOString()}
                  label="To"
                  onChange={date => {
                    handlePush(
                      {
                        to: moment(date)
                          .set({
                            hour: 23,
                            minute: 59,
                            second: 0,
                            millisecond: 0,
                          })
                          .toISOString(),
                      },
                      'to',
                    );
                  }}
                  clear={false}
                />
              </div>
            </>
          )}
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(PlaceSearch);
