import React from 'react';
import styled from 'styled-components';
import { BoxWithHeader } from '../../../components/elements';

const Container = styled.div`
  width: 40%;
`;

const ModifiersItemLink = ({ values }) => (
  <Container>
    {values.linkedMenuItem.map(item => (
      <BoxWithHeader
        key={item.menu_item_id}
        header={<p className="title">{item.name}</p>}
      />
    ))}
  </Container>
);

export default ModifiersItemLink;
