import React, { useState } from 'react';
import styled from 'styled-components';
import { Column, Title, Level, Table } from 'rbx';
import { Query } from 'react-apollo';
import { isNull } from 'lodash';
import gql from 'graphql-tag';
import Footer from './Footer';
import PlaceSocialList from './PlaceSocialList';
import Search from './Search';
import {
  Input,
  TextArea,
  TagInputs,
  CheckBox,
  Table as CustomTable,
  CustomInput,
  CustomSelect,
  Loading,
  Pagination,
} from '../../../components/elements';

const searchSocial = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_count {
          type
          count
        }
        total_social_per_object_id {
          total_social_count {
            type
            user {
              user_id
              time
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div``;

const Social = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  createForm,
  props,
  place,
}) => {
  const [socialCount, setSocialCount] = useState({
    beenThere: 0,
    like_it: 0,
    bookmark: 0,
    share: 0,
  });
  const [filterType, setFilterType] = useState('been_there');
  const [user, setUser] = useState({
    beenThere: [],
    like_it: [],
    bookmark: [],
    share: [],
  });
  const social_filter = {
    object_id: place.place_id,
    object_type: 'PLACE',
  };
  return (
    <React.Fragment>
      <Level>
        <Column size={3}>
          <Input label="Been There" value={socialCount.beenThere} disabled />
        </Column>
        <Column size={3}>
          <Input label="Like It" value={socialCount.like_it} disabled />
        </Column>
        <Column size={3}>
          <Input label="Shares" value={socialCount.share} disabled />
        </Column>
        <Column size={3}>
          <Input label="Bookmarks" value={socialCount.bookmark} disabled />
        </Column>
      </Level>
      <Search filterType={filterType} setFilterType={setFilterType} />
      {/* <Pagination /> */}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Place Name
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> City / Suburb
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Social Type
                </span>
                <br />
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Updated At
                </span>
                <br />
              </div>
            </Table.Heading>
            {/* <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Shared On
                </span>
              </div>
            </Table.Heading> */}
          </Table.Row>
        </Table.Head>
        <Query
          query={searchSocial}
          variables={{ input: { filter: { social_filter } } }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }

            const { search_social } = data;
            if (isNull(search_social)) {
              return 'no data found';
            }
            return (
              <PlaceSocialList
                setSocialCount={setSocialCount}
                socialFilter={social_filter}
                users={user[filterType]}
                setUser={setUser}
                place={place}
                search_social={search_social}
              />
            );
          }}
        </Query>
      </CustomTable>
    </React.Fragment>
  );
};

export default Social;
