import React from 'react';
import styled from 'styled-components';
import { isNull, first } from 'lodash';
import { Column } from 'rbx';
import ItemReviews from './ItemReviews';

import { Input } from '../../../components/elements';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const PlaceDashboard = ({ social }) => {
  const likeIt =
    social.total_social_count && !isNull(social.total_social_count)
      ? social.total_social_count.filter(item => item.type === 'LOVE_IT')
      : [];

  const triedIt =
    social.total_social_count && !isNull(social.total_social_count)
      ? social.total_social_count.filter(item => item.type === 'TRIED')
      : [];

  const bookmarked =
    social.total_social_count && !isNull(social.total_social_count)
      ? social.total_social_count.filter(item => item.type === 'BOOKMARK')
      : [];

  const bookmark = bookmarked.length !== 0 ? first(bookmarked).count : 0;
  const tried = triedIt.length !== 0 ? first(triedIt).count : 0;
  const like = likeIt.length !== 0 ? first(likeIt).count : 0;
  return (
    <Container>
      <fieldset className="box">
        <legend className="label">Item</legend>
        <Column.Group vcentered multiline>
          <Column size={3}>
            <Input label=" Tried" value={tried} placeholder="600" disabled />
          </Column>
          <Column size={3}>
            <Input label=" Love It" value={like} placeholder="60" disabled />
          </Column>
          <Column size={3}>
            <Input label=" Shares" placeholder="600" disabled />
          </Column>
          <Column size={3}>
            <Input
              label=" Bookmarks"
              value={bookmark}
              placeholder="600"
              disabled
            />
          </Column>

          <Column size={3}>
            <Input label="User Uploaded Photos" placeholder="600" disabled />
          </Column>
        </Column.Group>
      </fieldset>

      <ItemReviews />

      <fieldset className="box">
        <Column.Group vcentered multiline>
          <Column size={3}>
            <Input label="Menu Views" placeholder="60" disabled />
          </Column>

          <Column size={3}>
            <Input label="Page Views" placeholder="60" disabled />
          </Column>

          <Column size={3}>
            <Input label="Place Link Clicks" placeholder="60" disabled />
          </Column>
          <Column size={3}>
            <Input label="Mobile calls" placeholder="60" disabled />
          </Column>
          <Column size={3}>
            <Input label="Direction and Map Views" placeholder="60" disabled />
          </Column>
        </Column.Group>
      </fieldset>

      <hr />
    </Container>
  );
};
export default PlaceDashboard;
