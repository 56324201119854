import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { isNull, forEach, concat, omit, isEqual } from 'lodash';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import { Loading, Message, Blank } from '../../../components/elements';
import AddCompanyForm from '../AddCompany/AddCompanyForm';

const UpdateCompanyMutation = gql`
  mutation updateCompany($input: CompanyInput) {
    update_company(input: $input) {
      company_id
      name
      error {
        code
        description
      }
    }
  }
`;

const companyQuery = gql`
  query fetchCompany($companyId: String!) {
    fetch_company(input: { company_id: $companyId }) {
      company_id
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      status
      registration_id
      tax_id
      tax {
        type
        value
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      error {
        code
        description
      }
    }
  }
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const UpdateCompany = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <div className="container">
        <Query
          client={client.clientPrivate}
          query={companyQuery}
          variables={{ companyId: match.params.companyId }}
        >
          {({ data: data1, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>{error.message}</Message>;
            }
            const company = data1.fetch_company;

            if (isNull(company)) {
              return <Blank message="Company not found" />;
            }
            return (
              <Mutation
                client={client.clientPrivate}
                mutation={UpdateCompanyMutation}
                onCompleted={({ update_company }) => {
                  if (!isNull(update_company.error)) {
                    update_company.error.map(item =>
                      toast.error(item.description),
                    );
                  } else {
                    swal(
                      'Nice!',
                      'Company has been updated successfully',
                      'success',
                    ).then(() => {
                      history.goBack();
                    });
                  }
                }}
              >
                {(update_company, { loading: loading1, error: error2 }) => (
                  <AddCompanyForm
                    company={company}
                    loading={loading1}
                    error={error2}
                    onSubmit={data => {
                      const inputs = omit(data, [
                        'primaryEmail',
                        'primaryMobile',
                        'companySite',
                        'contact',
                      ]);

                      const primaryContacts = [
                        {
                          type: 'email',
                          value: data.primaryEmail,
                          display: data.displayEmail,
                          is_primary: true,
                          display_order: '1',
                        },
                        {
                          type: 'mobile',
                          value: data.primaryMobile,
                          display: data.displayMobile,
                          is_primary: true,
                          display_order: '1',
                        },
                        {
                          type: 'website',
                          value: data.businessSite,
                          display: data.displayBusiness,
                          is_primary: true,
                          display_order: '1',
                        },
                      ];

                      const formContact = () => {
                        const final = [];
                        forEach(data.contact, item => {
                          const custom = omit(item, ['id']);
                          final.push(custom);
                        });
                        return final;
                      };

                      const contact = concat(primaryContacts, formContact());

                      const input = {
                        ...inputs,
                        contact: removeTypename(contact),
                      };

                      const final = {};
                      Object.assign(
                        final,
                        { user_id: userId },
                        { company_id: inputs.company_id },
                        !isEqual(company.name, input.name) && {
                          name: input.name,
                        },
                        !isEqual(
                          company.address_line_1,
                          input.address_line_1,
                        ) && {
                          address_line_1: input.address_line_1,
                        },
                        !isEqual(
                          company.address_line_2,
                          input.address_line_2,
                        ) && {
                          address_line_2: input.address_line_2,
                        },
                        !isEqual(company.city, input.city) && {
                          city: input.city,
                        },
                        !isEqual(company.state, input.state) && {
                          state: input.state,
                        },
                        !isEqual(company.country, input.country) && {
                          country: input.country,
                        },
                        !isEqual(company.post_code, input.post_code) && {
                          post_code: input.post_code,
                        },

                        !isEqual(company.location, input.location) && {
                          location: input.location,
                        },
                        { tax: input.tax },
                      );

                      update_company({ variables: { input: final } });
                    }}
                  />
                )}
              </Mutation>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default withRouter(UpdateCompany);
