import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';

import client from '../../../../utils/apolloClient';
import Layout from '../../../../components/global/Layout';
import {
  Loading,
  Pagination,
  Table,
  Box,
  Message,
} from '../../../../components/elements';
import Header from './Header';
import EmailTemplateList from './EmailTemplateList';
import Search from './Search';

const searchNotificationTemplateQuery = gql`
  query searchNotificationTemplateQuery($input: SearchInput) {
    search_notification_template(input: $input) {
      total_pages
      total_size
      notification_template_listing {
        notification_id
        notification_template_id
        notification_platform
        status
        name
        user_role
        api_action
        action_status
        reply_to
        bcc
        cc
        to
      }
    }
  }
`;

const EmailTemplates = () => {
  const { userId } = useStoreState(state => state.auth);
  const [status, setStatus] = useState({ value: 'ALL', label: 'ALL' });
  const [platform, setPlatform] = useState({ value: 'ALL', label: 'ALL' });
  const [notificationTitle, setNotificationTitle] = useState();

  const [sort, setSort] = useState('UPDATED_DESC');
  const [page, setPage] = useState(0);
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const listSize = 30;

  const input = { user_id: userId };
  const notification_template_filter = {};

  const statusF = status.value === 'ALL' ? null : status.value;
  const platformF = platform.value === 'ALL' ? null : platform.value;

  Object.assign(
    notification_template_filter,
    { notification_title: notificationTitle },
    statusF && { status: statusF },
    platformF && { platform: platformF },
  );

  Object.assign(
    input,
    { from: currentPage * listSize, size: listSize },
    { sort },
    {
      filter: {
        notification_template_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={status}
          setStatus={setStatus}
          platform={platform}
          setPlatform={setPlatform}
          notificationTitle={notificationTitle}
          setNotificationTitle={setNotificationTitle}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => setPage(value.selected + 1)}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setSort(
                          sort === 'UPDATED_DESC'
                            ? 'UPDATED_ASC'
                            : 'UPDATED_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          sort === 'UPDATED_ASC'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>
                    Notification Title
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>Platform</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Status</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Sent to Email Id</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Copy to Email Id</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Total Push Sent</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Action</span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={searchNotificationTemplateQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error, refetch }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.message}</Message>;
              }

              return (
                <View
                  data={data}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                  refetch={refetch}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements, refetch }) => {
  const { search_notification_template } = data;
  useEffect(() => {
    setPageCount(search_notification_template.total_pages);
    setTotalElements(search_notification_template.total_size);
  }, [search_notification_template]);
  return (
    <tbody>
      {search_notification_template.notification_template_listing &&
        search_notification_template.notification_template_listing.map(
          notification => (
            <EmailTemplateList notification={notification} refetch={refetch} />
          ),
        )}
    </tbody>
  );
};

export default EmailTemplates;
