import React from 'react';
import styled from 'styled-components';
import { Input, Select } from '../../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const NotificationTitle = ({ notificationTitle, setNotificationTitle }) => (
  <>
    <Input
      label="Notification Title"
      id="EmailNotification__notification_title"
      value={notificationTitle}
      onChange={e => setNotificationTitle(e.target.value)}
    />
  </>
);

const statusValues = [
  { value: 'ALL', label: 'ALL' },
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const Status = ({ status, setStatus }) => (
  <>
    <Select
      label="Status"
      options={statusValues}
      id="EmailNotification__status"
      value={[status]}
      onChange={value => setStatus(value)}
    />
  </>
);

const notificationPlatfromValues = [
  { value: 'ALL', label: 'ALL' },
  { value: 'SES', label: 'SES' },
  { value: 'SENDGRID', label: 'SENDGRID' },
];

const Platform = ({ platform, setPlatform }) => (
  <>
    <Select
      label="Platform"
      options={notificationPlatfromValues}
      id="EmailNotification__status"
      value={[platform]}
      onChange={value => setPlatform(value)}
    />
  </>
);

const Search = ({
  notificationTitle,
  setNotificationTitle,
  setStatus,
  status,
  platform,
  setPlatform,
}) => (
  <Wrapper>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-three-quarters">
          <NotificationTitle
            notificationTitle={notificationTitle}
            setNotificationTitle={setNotificationTitle}
          />
        </div>
        <div className="column is-one-quarter">
          <Status setStatus={setStatus} status={status} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-quarter">
          <Platform platform={platform} setPlatform={setPlatform} />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default Search;
