import React, { useState } from 'react';

import Layout from '../../../components/global/Layout';
import Form from './Form';
import PlaceListing from '../PlaceListing';

const CustomHours = () => {
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);

  return (
    <Layout>
      {placeSelection ? (
        <Form place={place} />
      ) : (
        <PlaceListing
          heading="Bank Account"
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default CustomHours;
