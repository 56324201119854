import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Column } from 'rbx';
import { first } from 'lodash';
import voucher_codes from 'voucher-code-generator';

import {
  Select,
  TextArea,
  Radio,
  Input,
  CheckBox,
  TagInputs,
  CreatableSelectInputs,
} from '../../../components/elements';
import TimeDatePicker from '../../../components/global/TimeDatePicker';

const RadioWrapper = styled.div`
  padding: 0rem 0.3rem;
`;
const CheckBoxWrapper = styled.div`
  padding-top: 1.5rem;
`;
const VoucherDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const [autoGenerateRadioValue, setAutoGenerateRadioValue] = useState(true);
  return (
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={6}>
        <div className="field">
          <div className="label">Deal / Voucher Type</div>
          <div className="control">
            <div className="is-flex">
              <RadioWrapper>
                <Radio
                  label="Deal Listing Only"
                  value={values.deal_type === 'Deal Listing Only'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('deal_type', 'Deal Listing Only');
                    }
                  }}
                />
              </RadioWrapper>
              <RadioWrapper>
                <Radio
                  label="Automatic Promotion"
                  value={values.deal_type === 'Automatic Promotion'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('deal_type', 'Automatic Promotion');
                    }
                  }}
                />
              </RadioWrapper>
              <RadioWrapper>
                <Radio
                  label="Requires Voucher Code"
                  value={values.deal_type === 'Requires Voucher Code'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('deal_type', 'Requires Voucher Code');
                    }
                  }}
                />
              </RadioWrapper>
            </div>
          </div>
        </div>
      </Column>
      <Column size={3}>
        <CheckBoxWrapper>
          {values.deal_type === 'Requires Voucher Code' && (
            <CheckBox
              label="Auto generate Voucher Code"
              value={autoGenerateRadioValue}
              onChange={value => {
                console.log(value);
                if (value) {
                  const coupon = voucher_codes.generate({
                    length: 10,
                    count: 1,
                    charset: voucher_codes.charset('alphabetic'),
                  });
                  setFieldValue('voucher_code', first(coupon).toUpperCase());
                  setAutoGenerateRadioValue(true);
                } else {
                  setAutoGenerateRadioValue(false);
                }
              }}
            />
          )}
        </CheckBoxWrapper>
      </Column>
      <Column size={3}>
        <Select
          label="Deal/Voucher Status"
          options={[
            { value: 'ACTIVE', label: 'ACTIVE' },
            { value: 'INACTIVE', label: 'INACTIVE' },
          ]}
          value={[{ value: values.status, label: values.status }]}
          onChange={value => {
            setFieldValue('status', [value.value]);
          }}
          required
        />
      </Column>
      <Column size={6}>
        <Input
          label="Enter Deal / Voucher Name"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          optionalText="(Min 4 & Max 50 Characters )"
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      <Column size={3}>
        {values.deal_type === 'Requires Voucher Code' && (
          <Input
            label="Voucher Code"
            name="voucher_code"
            value={values.voucher_code}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.voucher_code}
            touched={touched.voucher_code}
            disabled={autoGenerateRadioValue}
          />
        )}
      </Column>
      <Column size={3}>
        <Select
          label="Approval Status"
          options={[
            { value: 'APPROVED', label: 'APPROVED' },
            { value: 'REJECTED', label: 'REJECTED' },
            { value: 'SUBMITTED', label: 'SUBMITTED' },
          ]}
          value={[]}
          onChange={value => {
            setFieldValue('verification_mode', [value.value]);
          }}
          disabled
        />
      </Column>
      <Column size={9}>
        <Input
          label="Deal/Voucher Tagline"
          name="tagline"
          value={values.tagline}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.tagline}
          touched={touched.tagline}
          required
        />
      </Column>
      <Column size={3}>
        <CheckBoxWrapper>
          <CheckBox
            label="Newly Register user Only"
            value={values.valid_for_new_user}
            onChange={value => {
              setFieldValue('valid_for_new_user', value.value);
            }}
          />
        </CheckBoxWrapper>
      </Column>
      <Column size="full">
        <TextArea
          label="Deal / Voucher Description"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.description}
          touched={touched.description}
        />
      </Column>
      <Column size={4}>
        <TimeDatePicker
          label="Deal / Voucher Start Date"
          value={values.start}
          onChange={value => {
            setFieldValue('start', value);
            if (moment(value).isSameOrAfter(values.end)) {
              setFieldValue('end', value);
            }
          }}
          required
        />
      </Column>
      <Column size={4}>
        <TimeDatePicker
          label="Deal / Voucher Expire Date"
          value={values.end}
          onChange={value => setFieldValue('end', value)}
          required
        />
      </Column>
      <Column size={3}>
        <Input
          label="Voucher Timezone"
          name="voucher_code"
          value="AEST"
          onChange={handleChange}
          onBlur={handleBlur}
          disabled
          required
        />
      </Column>

      <Column size={12}>
        <CreatableSelectInputs label="Place Menu Deal Tags" disabled />
      </Column>
    </Column.Group>
  );
};

export default VoucherDetails;
