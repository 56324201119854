import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import apolloSubscriptionClient from '../../../utils/apolloSubscriptionClient';
import apolloPublicSubscriptionClient from '../../../utils/apolloPublicSubscriptionClient';

import Form from './Form';

import { ErrorMessage, Loading } from '../../../components/elements';

import { fetchOrder } from './helpers';

const searchQuery = gql`
  query search($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      display_order
      name
      address_line_1
      address_line_2
      city
      hours
      hours_pickup
      hours_dinein
      hours_delivery
      state
      country
      post_code
      status
      claim_status
      latitude
      longitude
      service_type {
        description
        name
        display_order
      }
    }
  }
`;

const orderSubscription = gql`
  subscription subscribe_to_menu_order($place_id: String) {
    subscribe_to_menu_order(place_id: $place_id) {
      menu_order_id
      status
      place_id
      order_reference
      overall_quantity
      large_order
      service_type
      large_order

      payment {
        payment_method
        payment_method_id
        payment_intent_id
        payment_status
        payment_card {
          brand
          country
          last4
        }
        payment_at
      }
      contact {
        contact_name
        contact_phone
      }
      billing {
        delivery_fee_discount
        charges_list {
          name
          value
          is_payment_gateway_fee
        }
        surcharge_list {
          name
          charge
          menu_fee_id
        }
        sub_total
        order_total
        deal_discount
        payment_gateway_fee
        adjustment_amount
        order_amount
        delivery_fee
        charge
      }
      estimates {
        type
        value_in_mins
      }
      audit {
        created_at
        created_by
      }
    }
  }
`;

const subscribeToMenuOrderMoveToActive = gql`
  subscription subscribe_to_menu_order_move_to_active($place_id: String) {
    subscribe_to_menu_order_move_to_active(place_id: $place_id) {
      menu_order_id
    }
  }
`;

const Order = ({ match }) => {
  const [currentSubscriptionData, setCurrentSubscriptionData] = useState();
  const [
    currentSubscribeToMenuOrderMoveToActive,
    setCurrentSubscribeToMenuOrderMoveToActive,
  ] = useState();

  const [orders, setOrders] = useState(0);

  const { placeId } = match.params;
  const { userId } = useStoreState(state => state.auth);

  useEffect(() => {
    apolloSubscriptionClient
      .hydrated()
      .then(clientSubscription => {
        // Now subscribe to results
        const observable = clientSubscription.subscribe({
          query: orderSubscription,
          variables: {
            place_id: placeId,
          },
        });
        const realtimeResults = async function realtimeResults(data) {
          const order = await fetchOrder({
            placeId,
            userId,
            menuOrderId: data.data.subscribe_to_menu_order.menu_order_id,
          });
          if (order) {
            setCurrentSubscriptionData(order);
          } else {
            setCurrentSubscriptionData(data.data.subscribe_to_menu_order);
          }
          console.log(
            'data.data.subscribe_to_menu_order',
            data.data.subscribe_to_menu_order,
          );
        };
        observable.subscribe({
          next: realtimeResults,
          complete: console.log,
          error: console.log,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    apolloPublicSubscriptionClient
      .hydrated()
      .then(clientSubscription => {
        // Now subscribe to results
        const observable2 = clientSubscription.subscribe({
          query: subscribeToMenuOrderMoveToActive,
          variables: {
            place_id: placeId,
          },
        });
        async function realtimeResults(data) {
          console.log('triggered subscribeToMenuOrderMoveToActive...');
          const order = await fetchOrder({
            placeId,
            userId,
            menuOrderId:
              data.data.subscribe_to_menu_order_move_to_active.menu_order_id,
          });
          setCurrentSubscribeToMenuOrderMoveToActive(order);
        }
        observable2.subscribe({
          next: realtimeResults,
          complete: console.log,
          error: console.log,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <Layout>
      <Query
        query={searchQuery}
        client={client.clientPrivate}
        variables={{ placeId }}
      >
        {({ data, loading, error, refetch }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }

          return (
            <Form
              placeId={placeId}
              place={data.fetch_place}
              currentSubscriptionData={currentSubscriptionData}
              currentSubscribeToMenuOrderMoveToActive={
                currentSubscribeToMenuOrderMoveToActive
              }
              setCurrentSubscribeToMenuOrderMoveToActive={
                setCurrentSubscribeToMenuOrderMoveToActive
              }
              setCurrentSubscriptionData={setCurrentSubscriptionData}
              orders={orders}
              setOrders={setOrders}
              refetchPlace={refetch}
            />
          );
        }}
      </Query>
    </Layout>
  );
};

export default Order;
