import React from 'react';
//import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { Radio } from '../../../components/elements';

const PhotoUpload = ({ statusFilter, setStatusFilter }) => (
  <Level>
    <Level.Item align="right">
      <Level.Item>
        <Radio
          label="Item Review"
          value={statusFilter === 'ADD A REVIEW'}
          onChange={value => {
            if (value) {
              setStatusFilter('ADD A REVIEW');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Upload item photos"
          value={statusFilter === 'VENDOR UPLOAD'}
          onChange={value => {
            if (value) {
              setStatusFilter('VENDOR UPLOAD');
            }
          }}
        />
      </Level.Item>
    </Level.Item>
  </Level>
);

export default PhotoUpload;
