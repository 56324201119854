import { forEach, omit, toNumber, isNil } from 'lodash';
import uuid from 'uuid';
import gql from 'graphql-tag';

import client from '../../../utils/apolloClient';

export const mergeItemWithCurrentModifiers = (modifier, fetchItems) => {
  const data = [];
  forEach(fetchItems, item => {
    const temp = [];
    const currentModifiers = modifier.filter(
      edge => edge.menu_item_id === item.menu_item_id,
    );
    forEach(item.variant, item2 => {
      const temp2 = [];
      const currentItemVariant =
        currentModifiers && currentModifiers.length !== 0
          ? currentModifiers[0].override_variant
          : null;
      const overrideVariant =
        currentItemVariant && currentItemVariant.length !== 0
          ? currentItemVariant[0].variants
          : [];
      forEach(item2.variants, item3 => {
        const currentItemVariants = overrideVariant
          ? overrideVariant.filter(
              item4 => item4.variant_id === item3.variant_id,
            )
          : [];
        temp2.push({
          variant_id: item3.variant_id,
          basePrice: parseFloat(item3.variant_price).toFixed(2),
          variant_price:
            currentItemVariants.length !== 0
              ? currentItemVariants[0].variant_price
              : parseFloat(item3.variant_price).toFixed(2),
          variant_name: item3.variant_name,
        });
      });
      temp.push({ ...item2, variants: temp2 });
    });
    const itemPrice = item.price
      ? parseFloat(item.price).toFixed(2)
      : parseFloat(0).toFixed(2);
    data.push({
      menu_item_id: item.menu_item_id,
      name: item.name,
      basePrice: itemPrice,
      override_price:
        currentModifiers.length !== 0 && currentModifiers[0].override_price
          ? parseFloat(currentModifiers[0].override_price).toFixed(2)
          : itemPrice,
      override_variant: temp,
    });
  });
  return data;
};

const convertFloatValue = price =>
  price ? parseFloat(price).toFixed(2) : parseFloat(0).toFixed(2);

const getOverride_variant = (override_variantObj, variantData) => {
  let overrideVariantData = [];
  variantData.forEach(variantDataObj => {
    if (override_variantObj?.Prompt_id === variantDataObj?.Prompt_id) {
      const data = [];
      variantDataObj.variants.forEach(item1 => {
        const matchVariants = (override_variantObj?.variants || []).find(
          item2 => item1.variant_id === item2.variant_id,
        );
        if (matchVariants) {
          data.push({
            variant_id: item1.variant_id,
            basePrice: convertFloatValue(
              !isNil(matchVariants.variant_price)
                ? matchVariants.variant_price
                : item1.variant_price,
            ),
            variant_price: convertFloatValue(
              !isNil(matchVariants.variant_price)
                ? matchVariants.variant_price
                : item1.variant_price,
            ),
            variant_name: item1.variant_name,
          });
        }
      });
      overrideVariantData = data;
    }
  });
  return overrideVariantData;
};

export const mergeItemModifiers = modifierData => {
  const data = [];
  (modifierData || []).forEach(item => {
    if (!isNil(item?.override_price) || item?.menu_item?.price != null) {
      data.push({
        menu_item_id: item.menu_item.menu_item_id,
        name: item.menu_item.name,
        basePrice: convertFloatValue(
          !isNil(item.override_price)
            ? item.override_price
            : item?.menu_item?.price,
        ),
        override_price: convertFloatValue(
          !isNil(item.override_price)
            ? item.override_price
            : item?.menu_item?.price,
        ),
        override_variant: [],
      });
    } else {
      (item?.override_variant || []).forEach(override_variantObj => {
        data.push({
          menu_item_id: item.menu_item.menu_item_id,
          name: item.menu_item.name,
          basePrice: convertFloatValue(item?.menu_item?.price),
          override_price: convertFloatValue(item?.menu_item?.price),
          override_variant: getOverride_variant(
            override_variantObj,
            item.menu_item.variant,
          ),
        });
      });
    }
  });
  return data;
};
export const removeTypeName = values => {
  const data = [];
  forEach(values, item => {
    const itemData = omit(item, ['id', '__typename']);
    data.push(itemData);
  });
  return data;
};

export const removeIdFromVariantsInput = variants => {
  const data = [];
  forEach(variants, item => {
    const temp = [];
    const variant = omit(item, ['id', '__typename']);
    forEach(variant.variants, item2 => {
      const variant2 = omit(item2, ['__typename']);
      console.log('item2', item2);
      if (variant2.variant_name && variant2.variant_name.length !== 0) {
        temp.push({
          ...variant2,
        });
      }
    });
    data.push({ ...variant, variants: temp });
  });
  console.log('data', data);
  return data;
};

export const addIdInVariantsInput = variants => {
  const data = [];
  forEach(variants, item => {
    const temp = [];
    const variant = omit(item, ['__typename']);
    forEach(variant.variants, item2 => {
      const variant2 = omit(item2, ['__typename']);
      temp.push({
        ...variant2,
        variant_price: parseFloat(variant2.variant_price).toFixed(2),
      });
    });
    data.push({ id: uuid(), ...variant, variants: temp });
  });
  console.log('addIdInVariantsInput', data);
  return data;
};

export const convertModifierPriceInDecimals = modifiers => {
  const data = [];
  forEach(modifiers, item => {
    const temp = [];
    forEach(item.override_variant, item1 => {
      const temp2 = [];
      forEach(item1.variants, item2 => {
        temp2.push({
          ...item2,
          variant_price: parseFloat(item2.variant_price).toFixed(2),
        });
      });
      temp.push({ ...item1, variants: temp2 });
    });
    data.push({
      ...item,
      override_price: parseFloat(item.override_price).toFixed(2),
      override_variant: temp,
    });
  });
  return data;
};

export const parseInputModifier = modifier => {
  const finalModifier = modifier.map(item => {
    const data = [];
    forEach(item.override_variant, item2 => {
      const temp = [];
      forEach(item2.variants, items3 => {
        temp.push({
          variant_id: items3.variant_id,
          variant_name: items3.variant_name,
          variant_price: toNumber(items3.variant_price),
          variant_tax: items3.variant_tax,
        });
      });
      if (temp.length !== 0) {
        data.push({
          prompt_id: item2.prompt_id,
          prompt: item2.prompt,
          variants: temp,
        });
      }
    });
    return {
      menu_item_id: item.menu_item_id,
      override_price:
        item.override_price === null ? null : toNumber(item.override_price),
      override_variant: data,
    };
  });
  return finalModifier;
};

export const addNameInModifier = (override_variant, variant) => {
  const temp = [];
  forEach(override_variant, (item1, index) => {
    const temp2 = [];
    const itemVariant = variant[index];
    forEach(item1.variants, (item2, index2) => {
      const variantItem = itemVariant.variants[index2];
      temp2.push({
        ...item2,
        variant_name: variantItem && variantItem.variant_name,
      });
    });
    temp.push({ ...item1, variants: temp2 });
  });
  return temp;
};

const searchModifierGroupsQuery = gql`
  query searchModifierGroups($input: SearchInput) {
    search_menu_modifier_groups(input: $input) {
      modifier_group_listing {
        modifier_group_id
        place_id
        name
        internal_name
        description
        status
        is_required
        selection_type
        selection_quantity
        selection_quantity_from
        selection_quantity_to
        modifier {
          menu_item {
            menu_item_id
            name
            price
            variant {
              prompt_id
              prompt
              variants {
                variant_name
                variant_id
                variant_price
                availability_status
              }
            }
          }
          override_price
          override_variant {
            prompt_id
            prompt
            variants {
              variant_id
              variant_price
            }
          }
          min_qty
          max_qty
        }
        is_required
        selection_quantity_from
        selection_quantity_to
      }
    }
  }
`;

export const getAllModifierGroups = filter =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchModifierGroupsQuery,
        variables: {
          input: {
            filter: {
              menu_modifier_group_filter: filter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        console.log('data', data);
        if (
          data.search_menu_modifier_groups &&
          data.search_menu_modifier_groups.modifier_group_listing
        ) {
          resolve(data.search_menu_modifier_groups.modifier_group_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchItemLinkQuery = gql`
  query searchItemsLink($input: SearchInput) {
    search_menu_item_links(input: $input) {
      menu_item_link_listing {
        service_type_setting {
          service_type_setting_id
          service_type
        }
        item_link_id
        place_id
        menu {
          menu_id
          name
        }
        menu_section {
          menu_section_id
          name
        }
        price
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_name
            variant_price
            availability_status
            deliverect_plu_id
          }
        }
      }
    }
  }
`;

export const getAllItemLink = filter =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchItemLinkQuery,
        variables: {
          input: {
            filter: {
              menu_item_link_filter: filter,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        console.log('data', data);
        if (
          data.search_menu_item_links &&
          data.search_menu_item_links.menu_item_link_listing
        ) {
          resolve(data.search_menu_item_links.menu_item_link_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        place {
          place_id
        }
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        name
        internal_name
        slug
        description
      }
    }
  }
`;

export const getMenus = ({ placeId, userId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchMenusQuery,
        variables: {
          input: {
            filter: {
              menu_filter: { status: 'ACTIVE' },
              place_filter: { place_id: [placeId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menus && data.search_menus.menu_listing) {
          resolve(data.search_menus.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchSectionsSettingsQuery = gql`
  query searchSections($input: SearchInput) {
    search_menu_sections(input: $input) {
      menu_section_listing {
        menu_section_id
        place_name
        city
        place_id
        menu_id
        name
        description
        status
      }
    }
  }
`;

export const getSections = ({ menuId, userId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchSectionsSettingsQuery,
        variables: {
          input: {
            filter: {
              menu_section_filter: { menu_id: [menuId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_sections &&
          data.search_menu_sections.menu_section_listing
        ) {
          resolve(data.search_menu_sections.menu_section_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const checkLinkIsAlreadyExist = (links, newLinks) => {
  let isExist = false;
  forEach(links, item => {
    if (
      item.menu.menu_id === newLinks.menu_id &&
      item.menu_section.menu_section_id === newLinks.menu_section_id &&
      item.service_type_setting.service_type_setting_id ===
        newLinks.service_type_setting_id
    ) {
      isExist = true;
    }
  });
  return isExist;
};

export const countDecimals = value => {
  if (Math.floor(value) !== value) {
    if (
      value.toString().split('.')[1] &&
      value.toString().split('.')[1].length
    ) {
      return value.toString().split('.')[1].length;
    }
  }
  return 0;
};

const updateMenuItemMutation = gql`
  mutation updateMenuItem($input: MenuItemInput) {
    update_menu_item(input: $input) {
      menu_item_id
      error {
        description
      }
    }
  }
`;

export const updMenuItem = async ({
  userId,
  menuItemId,
  placeId,
  imageUrl,
}) => {
  const input = {
    user_id: userId,
    menu_item_id: menuItemId,
    place_id: placeId,
    image_url: imageUrl,
  };
  try {
    await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateMenuItemMutation,
      variables: {
        input,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
