import React from 'react';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem 1rem;
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>Check In Attendees</Title>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Header;
