import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import ActivityTable from './ActivityTable';
import Layout from '../../../components/global/Layout';
import Header from './Header';

const Container = styled.div`
  fieldset {
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const BoldText = styled.p.attrs({
  className: 'has-text-weight-bold is-size-4',
})``;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  padding-bottom: 1rem;
  display: flex;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.dark};
  font-size: 0.8rem;
  padding-right: 3vh;
`;

const Activity = () => (
  <Layout>
    <Header />
    <Container>
      <Column>
        <fieldset className="box">
          <Flex>
            <div>
              <TextWrapper>
                <Text>Payment Date </Text> <BoldText>25/03/2020</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Payment Period</Text>{' '}
                <BoldText>Mar 17, 2020 - Mar 23, 2020</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Frequency</Text> <BoldText>Weekly</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Payment ID</Text>
                <BoldText>KNFVSE2SB477VAS</BoldText>
              </TextWrapper>
            </div>

            <div>
              <TextWrapper>
                <Text>Total Payout</Text>
                <BoldText>$52000.00</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Earnings</Text>
                <BoldText>$52000.00</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Fee</Text>
                <BoldText>$230.00</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Payment Details</Text>
                <BoldText>xxxx5639</BoldText>
              </TextWrapper>
            </div>

            <div>
              <TextWrapper>
                <Text>Status</Text>
                <BoldText>Paid</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Food Orders</Text>
                <BoldText>11</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Event Orders</Text>
                <BoldText>3</BoldText>
              </TextWrapper>
              <TextWrapper>
                <Text>Next Payment</Text>
                <BoldText>01/04/2020</BoldText>
              </TextWrapper>
            </div>
          </Flex>
        </fieldset>
      </Column>
    </Container>
    <br />
    <ActivityTable />
  </Layout>
);

export default Activity;
