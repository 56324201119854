import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => (
  <>
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Title size={2}>User Promos</Title>
        </Level.Item>

        <Level.Item align="right">
          <Link to="/add-user-promo" className="button is-primary">
            <span className="has-text-weight-semibold">
              Add a New User Promo
            </span>
          </Link>
        </Level.Item>
      </Level>
    </Wrapper>
  </>
);

export default withRouter(Header);

// import { ACLSConsumer } from '../../../utils/aclsContext';
// import Can from '../../../utils/Can';

/* <ACLSConsumer>
            {({ apiActions, pageActions }) => (
              <Can
                action="create_place_deal"
                apiActions={apiActions}
                pageActions={pageActions}
                yes={() => (
                  <Level.Item align="right">
                    <Link to="/add-place-deal" className="button is-primary">
                      <span className="has-text-weight-semibold">
                        Add a New Deal
                      </span>
                    </Link>
                  </Level.Item>
                )}
              />
            )}
          </ACLSConsumer> */
