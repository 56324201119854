import React from 'react';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const PageHeader = ({ title, subtitle, otherText, children }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">{title}</Title>
      </Level.Item>
      {children}
    </Level>
    {subtitle && <p className="is-size-2">{subtitle}</p>}
    {otherText && <p className="is-size-2">{otherText}</p>}
  </Wrapper>
);

export default PageHeader;
