import React, { useState, useEffect } from 'react';
import { Column } from 'rbx';
import gql from 'graphql-tag';
import moment from 'moment';
import { isNull } from 'lodash';
import styled from 'styled-components';
import PlaceReviewSocialCount from './PlaceReviewSocialCount';
import { Input } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      status
      rating
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const Container = styled.div`
  margin-left: 2rem;
`;

const PlaceReviewUserDetails = ({ place, createdBy, createdTime }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    client.clientPrivate
      .query({
        query: userQuery,
        variables: { userId: createdBy },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setUser(data.fetch_user);
        }
      });
  }, []);

  return (
    <fieldset className="box">
      <legend className="label">Reviewed User Details</legend>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input label="Email" value={user && user.email} disabled />
        </Column>
        <Column size="half">
          <Input
            label="Display Name"
            value={`${user && user.first_name} ${user && user.last_name}`}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Reviewed On"
            value={moment(createdTime).format('lll')}
            disabled
          />
        </Column>
        <Column size="half">
          <Input label="Status" value={user && user.status} disabled />
        </Column>
        <Container>
          <fieldset className="box">
            <Column.Group multiline vcentered gapSize={8}>
              <Column size={3}>
                <Input
                  label="Rating"
                  value={isNull(user && user.rating) ? 0 : user && user.rating}
                  disabled
                />
              </Column>
              <PlaceReviewSocialCount place={place} />
            </Column.Group>
          </fieldset>
        </Container>
      </Column.Group>
    </fieldset>
  );
};

export default PlaceReviewUserDetails;
