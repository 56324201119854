import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import uuid from 'uuid';
import moment from 'moment';
import { range } from 'lodash';
import * as yup from 'yup';

import { Column, Field, Label, Control, Box } from 'rbx';
import { Select, Input, InputErrorMessage } from '../../../components/elements';
import TimePicker from '../../../components/global/TimePicker';
import { days } from './helpers';

const Container = styled.div`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head,
    .modal-card-foot {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
`;

const Form = ({ onSubmit, type }) => {
  const formik = useFormik({
    initialValues: {
      id: uuid(),
      day: '',
      start_hour: '',
      end_hour: '',
      preparation_estimate: 15,
      delivery_estimate: 15,
    },
    validationSchema: yup.object().shape({
      day: yup.string().required('Required!'),
      start_hour: yup.string().required('Required!'),
      end_hour: yup.string().required('Required!'),
      preparation_estimate: yup.string().required('Required!'),
    }),
    onSubmit,
  });

  const { values, setFieldValue, dirty, errors, touched } = formik;

  const timeOptions = range(5, 95, 5).map(item => ({
    label: `${item} Min`,
    value: item,
  }));

  return (
    <Box>
      <Column.Group multiline>
        <Column size="4">
          <Select
            label="Day"
            value={[{ label: values.day, value: values.day }]}
            onChange={value => {
              setFieldValue('day', value.value);
            }}
            options={days.map(item => ({ label: item, value: item }))}
            errors={errors.day}
            touched={touched.day}
            disableToastMessage
          />
        </Column>
        <Column size="4">
          <Field>
            <Label>Start Time</Label>
            <Control>
              <TimePicker
                placeholder="Select Time"
                value={
                  values.start_hour === '' ||
                  values.start_hour === 'Invalid date'
                    ? ''
                    : moment(`April 17, 2020 ${values.start_hour}`)
                }
                onChange={value => {
                  if (value === null) {
                    setFieldValue('start_hour', '');
                  } else {
                    setFieldValue('start_hour', moment(value).format('LT'));
                  }
                }}
              />
              <InputErrorMessage
                errors={errors.start_hour}
                touched={touched.start_hour}
                disableToastMessage
              />
            </Control>
          </Field>
        </Column>
        <Column size="4">
          <Field>
            <Label>End Time</Label>
            <Control>
              <TimePicker
                placeholder="Select Time"
                value={
                  values.end_hour === '' || values.end_hour === 'Invalid date'
                    ? ''
                    : moment(`April 17, 2020 ${values.end_hour}`)
                }
                onChange={value => {
                  if (value === null) {
                    setFieldValue('end_hour', '');
                  } else {
                    setFieldValue('end_hour', moment(value).format('LT'));
                  }
                }}
              />
              <InputErrorMessage
                errors={errors.end_hour}
                touched={touched.end_hour}
                disableToastMessage
              />
            </Control>
          </Field>
        </Column>
        <Column size={type !== 'delivery' ? '6' : '4'}>
          <Select
            label="Prep Estimates"
            value={{
              label: `${values.preparation_estimate} Min`,
              value: values.preparation_estimate,
            }}
            options={timeOptions}
            onChange={value => {
              setFieldValue('preparation_estimate', value.value);
            }}
            errors={errors.preparation_estimate}
            touched={touched.preparation_estimate}
          />
        </Column>
        {type === 'delivery' && (
          <Column size="4">
            <Select
              label="Delivery Estimates"
              value={{
                label: `${values.delivery_estimate} Min`,
                value: values.delivery_estimate,
              }}
              options={timeOptions}
              onChange={value => {
                setFieldValue('delivery_estimate', value.value);
              }}
            />
          </Column>
        )}
        <Column size={type !== 'delivery' ? '6' : '4'}>
          <Input
            label="Total Estimates"
            value={values.preparation_estimate + values.delivery_estimate}
            type="number"
            min="0"
            disabled
          />
        </Column>
        <Column size="4">
          <button
            type="button"
            className="button is-primary"
            onClick={() => {
              setFieldValue('id', uuid());
              formik.handleSubmit();
            }}
            disabled={!dirty}
          >
            Add
          </button>
        </Column>
      </Column.Group>
    </Box>
  );
};

const DayEstimatesModal = ({
  isActive,
  onClose,
  onSubmit,
  initialsEstimates,
  type,
}) => {
  const [estimates, setEstimates] = useState(initialsEstimates);

  useEffect(() => {
    setEstimates(initialsEstimates);
  }, [initialsEstimates]);

  const addEstimate = value => {
    const newEstimate = estimates.concat(value);
    setEstimates(newEstimate);
  };

  const removeEstimate = id => {
    const newEstimate = estimates.filter(item => item.id !== id);
    setEstimates(newEstimate);
  };

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Day Estimates</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body">
          {estimates.length !== 0 && (
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>
                    <abbr>Day</abbr>
                  </th>
                  <th>
                    <abbr>Start Time</abbr>
                  </th>
                  <th>
                    <abbr>End Time</abbr>
                  </th>
                  <th>
                    <abbr>Prep Est</abbr>
                  </th>
                  {type === 'delivery' && (
                    <th>
                      <abbr>Delivery Est</abbr>
                    </th>
                  )}
                  <th>
                    <a>Action</a>
                  </th>
                </tr>
              </thead>
              <tbody>
                {estimates.map(estimate => (
                  <tr>
                    <td>{estimate.day}</td>
                    <td>{estimate.start_hour}</td>
                    <td>{estimate.end_hour}</td>
                    <td>{estimate.preparation_estimate} min</td>
                    {type === 'delivery' && (
                      <td>{estimate.delivery_estimate} min</td>
                    )}
                    <td>
                      <a onClick={() => removeEstimate(estimate.id)}>
                        <i className="fas fa-trash has-text-grey" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <Wrapper>
            <Form
              type={type}
              onSubmit={(values, { resetForm }) => {
                addEstimate(values);
                resetForm();
              }}
            />
          </Wrapper>
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-primary is-fullwidth is-medium"
            aria-label="close"
            onClick={() => onSubmit(estimates)}
            disabled={estimates.length === 0}
          >
            Submit
          </button>
        </footer>
      </div>
    </Container>
  );
};

export default DayEstimatesModal;
