import React from 'react';
import styled from 'styled-components';
import Loading from 'react-loading';
import { Table } from '../../../components/elements';
import PlaceDetailsCard from './PlaceDetailsCard';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const TransactionsDetailsTable = ({
  transactionsDetailsData,
  loading,
  onClick,
  placeData,
  canLoadMoreData,
  loadMoreData,
}) => (
  <>
    <PlaceDetailsCard placeData={placeData} />
    <div style={{ padding: '10px' }}>
      <h1 id="transactions-details-heading-csv-pdf" style={{ display: 'none' }}>
        Transaction Details
      </h1>
      <Table id="transactions-details-table">
        <thead>
          <tr>
            {[
              'Order Date',
              'Orders',
              'Total',
              'Subtotal',
              'Promo',
              'Oth Charges',
              'Payout',
              'Application Fees',
              'Pickup Orders',
              'Pickup Sales',
              'Dinein Orders',
              'Dinein Sales',
              'Del Orders',
              'Del Sales',
              'C Fees',
              'Service Fees',
              'Delivery Fees',
              'KRAVEiN Fees',
              'Franchisee Fees',
              'B Fees',
            ].map(headerCellValue => (
              <th key={headerCellValue} style={{ fontWeight: 700 }}>
                <div>
                  <span>{headerCellValue}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        {(transactionsDetailsData ?? []).length !== 0 && (
          <tbody>
            {transactionsDetailsData.map(tdData => (
              <tr
                style={{ fontWeight: 600, cursor: 'pointer' }}
                key={`${tdData.orderDate}${tdData.Orders}${tdData.Payout}${tdData.Subtotal}`}
                onClick={onClick}
              >
                <td style={{ color: '#00AEEF', whiteSpace: 'nowrap' }}>
                  {tdData.orderDate}
                </td>
                <td>{tdData.Orders}</td>
                <td>{tdData.Total}</td>
                <td>{tdData.Subtotal}</td>
                <td>{tdData.Promo}</td>
                <td>{tdData['Oth Charges']}</td>
                <td>{tdData.Payout}</td>
                <td>{tdData['Application Fee']}</td>
                <td>{tdData['Pickup Orders']}</td>
                <td>{tdData['Pickup Sales']}</td>
                <td>{tdData['Dinein Orders']}</td>
                <td>{tdData['Dinein Sales']}</td>
                <td>{tdData['Del Orders']}</td>
                <td>{tdData['Del Sales']}</td>
                {/* <td>{tdData['Processing Fee']}</td> */}
                <td>{tdData['Royalty Fees']}</td>
                <td>{tdData['Service Fees']}</td>
                <td>{tdData['Delivery Fee']}</td>
                <td>{tdData['KRAVEiN Fees']}</td>
                <td>{tdData['Franchisee Fees']}</td>
                <td>{tdData['B Fees']}</td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {loading && (
        <Center>
          <Loading type="spin" color="#363636" width="60px" height="60px" />
        </Center>
      )}
      {canLoadMoreData && (
        <PaginationContainer>
          <ShowMoreButton type="button" onClick={loadMoreData}>
            <span>Show More</span>
          </ShowMoreButton>
        </PaginationContainer>
      )}
    </div>
  </>
);

export default TransactionsDetailsTable;
