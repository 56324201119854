import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Title, Level, Column } from 'rbx';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { first, kebabCase } from 'lodash';

import { getTicketUrlPdf } from '../../../utils/s3';
import { SubHeading, Table } from '../../../components/elements';

const MainWrapper = styled.div`
  && {
    hr {
      margin: 0;
    }
    .columns:not(:last-child) {
      margin: 0;
    }
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem 13%;
  border-bottom: 2px solid whitesmoke;
`;

const Container = styled.div`
  border-top: 2px solid whitesmoke;
  padding: 1rem;
`;

const BookingResult = ({ eventStartDate, bookingDetails, discount, event }) => {
  console.log('bookingDetails', bookingDetails);
  const bookingFeeDetails = bookingDetails.fee
    ? bookingDetails.fee.filter(value => value.name === 'booking_fee')
    : [];

  const bookingFee =
    bookingFeeDetails.length !== 0 ? first(bookingFeeDetails).value : 0;
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const getTicket = async () => {
    const link = await getTicketUrlPdf(bookingDetails.url);
    console.log(link);
    setPdfUrl(link);
    setLoading(false);
  };
  useEffect(() => {
    getTicket();
  }, []);
  return (
    <MainWrapper>
      <Container>
        <Column.Group>
          <Column size={4} />
          <Column size={4}>
            <div className="has-text-centered">
              <Title className="is-size-1 has-text-weight-semibold">
                Booking Confirmation
              </Title>
            </div>
          </Column>
          <Column size={4}>
            <div className="has-text-centered">
              <Title className="is-size-2 has-text-primary">
                <Link to={`/check-in-attendees/${event.event_id}`}>
                  Check In Attendee
                </Link>
              </Title>
            </div>
          </Column>
        </Column.Group>

        <br />
        <SubHeading className="has-text-weight-semibold">
          Order number is{' '}
          <span className="has-text-weight-normal">
            {bookingDetails.booking_number}
          </span>
        </SubHeading>
        <SubHeading className="has-text-weight-semibold">
          Event date:
          <span className="has-text-weight-semibold">
            {moment(eventStartDate).format('LLLL')}
          </span>
        </SubHeading>
      </Container>
      <Table>
        <thead>
          <th> Ticket type</th>
          <th> Price</th>
          <th> Fee</th>
          <th> Quantity</th>
          <th> Cost</th>
        </thead>
        <tbody>
          {bookingDetails.ticket_record.map(item => {
            const data = first(item);
            const quantity = item.length;

            const bookingTicketFeeDetails = data.fee
              ? data.fee.filter(value => value.name === 'offline_passon_fee')
              : [];
            const bookingTicketFee =
              bookingTicketFeeDetails.length !== 0
                ? first(bookingTicketFeeDetails).value
                : 0;

            // data.fee.length !== 0 ? first(item.fee).value : 0;
            const totalTicketPrice = (data.price + bookingTicketFee) * quantity;

            return (
              <tr>
                <td>{data.ticket_name}</td>
                <td>${parseFloat(data.price).toFixed(2)}</td>
                <td>${parseFloat(bookingTicketFee).toFixed(2)}</td>
                <td>{quantity}</td>
                <td>${parseFloat(totalTicketPrice).toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <hr />
      <Flex className="has-text-weight-semibold">
        <p>
          Sub Total : ${parseFloat(bookingDetails.sub_total_price).toFixed(2)}{' '}
        </p>
        <p>Ticket Fee : ${parseFloat(bookingDetails.total_fee).toFixed(2)}</p>
        <p>
          Booking Fee : ${parseFloat(bookingDetails.booking_fee).toFixed(2)}
        </p>
        <p>
          Transaction Fee : $
          {parseFloat(bookingDetails.transaction_fee).toFixed(2)}
        </p>
        {discount !== 0 && (
          <p>Voucher Discounts: ${parseFloat(discount).toFixed(2)}</p>
        )}
      </Flex>
      <Flex className="has-text-weight-semibold is-size-1">
        <p>
          Order Total : ${parseFloat(bookingDetails.total_price).toFixed(2)}
        </p>
      </Flex>
      <Wrapper>
        <Level>
          <Level.Item align="left">
            <Level.Item>
              <Link
                to="/select-events-for-sell-tickets"
                className="button is-light"
              >
                <span>Back</span>
              </Link>
            </Level.Item>
          </Level.Item>
          <Level.Item align="right">
            <Level.Item>
              <div className="is-flex">
                <a
                  className={`button is-primary ${loading && 'is-loading'}`}
                  href={pdfUrl}
                  download={`${kebabCase(event.name)}-${
                    bookingDetails.booking_id
                  }.pdf`}
                >
                  Download Ticket(s)
                </a>
                &nbsp;&nbsp;
                <button className="button is-primary">Resend Ticket(s)</button>
              </div>
            </Level.Item>
          </Level.Item>
        </Level>
      </Wrapper>
    </MainWrapper>
  );
};

export default BookingResult;
