import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({ history }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>Your Food Licence Verification</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>

        <Level.Item>
          <button
            className="button is-primary"
            // onClick={}
          >
            <span>Submit Changes</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
    <SubHeading className="is-size-2">
      Abbey Road Cafe | Victoria | St Kilda, 3182 (ACTIVE / CLAIMED)
    </SubHeading>
  </Wrapper>
);

export default withRouter(Header);
