import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  Message,
  // CustomInput,
} from '../../../components/elements';

const searchUserMenuDealQuery = gql`
  query MyQuery($input: SearchInput) {
    search_user_menu_deal_list(input: $input) {
      menu_deal_listing {
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        user_id
        deal_id
        place_id
        place_name
        city_subarb
        deal_name
        deal_type
        end_date
        menu_name
        promo_type
        promo_code
        service_type
        start_date
        user_name
        status
      }
      total_pages
      total_size
    }
  }
`;

const UserPromos = () => {
  const { userId } = useStoreState(state => state.auth);

  const [sort, setSort] = useState('UPDATED_DESC');

  const [startDateSort, setStartDateSort] = useState('AES');
  const [endDateSort, setEndDateSort] = useState('AES');

  const [status, setStatus] = useState('CURRENT');
  const [dealStatus, setDealStatus] = useState('ACTIVE');
  const [userName, setUserName] = useState('');

  const { defaultPlace } = useStoreState(state => state.menu);

  const [voucherNameSearch, setVoucherNameSearch] = useState('');
  const [menuNameSearch, setMenuNameSearch] = useState('');

  const [page, setPage] = useState(0);
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(30);

  const [location, setLocation] = useState('');
  const [discountType, setDiscountType] = useState('');

  const input = { user_id: userId };
  const user_menu_deal_list_filter = {};

  Object.assign(
    user_menu_deal_list_filter,
    { user_name: userName },
    { status: dealStatus },
    voucherNameSearch !== '' && { deal_name: voucherNameSearch },
    discountType !== '' && { deal_type: discountType },
    { timeline: status },
  );

  Object.assign(
    input,
    { from: currentPage * listSize, size: listSize },

    { sort },

    {
      filter: {
        user_menu_deal_list_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          userName={userName}
          setUserName={setUserName}
          location={location}
          setLocation={setLocation}
          status={status}
          setStatus={setStatus}
          dealStatus={dealStatus}
          setDealStatus={setDealStatus}
          voucherNameSearch={voucherNameSearch}
          handleVoucherNameSearch={setVoucherNameSearch}
          menuNameSearch={menuNameSearch}
          handleMenuNameSearch={setMenuNameSearch}
          discountType={discountType}
          setDiscountType={setDiscountType}
          defaultPlace={defaultPlace}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => setPage(value.selected + 1)}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>Deal name</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Discount type</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Promo type</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Promo code</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Service Type</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Menu Name</span>
                </div>
              </th>
              <th>
                <div>
                  <span>User Name </span>
                </div>
              </th>
              <th>
                <div>
                  <span>Place Name</span>
                </div>
              </th>
              <th>
                <div>
                  <span>City / Suburb</span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStartDateSort(
                          startDateSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          startDateSort === 'AES' ? 'START_ASC' : 'START_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          startDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>
                    Start Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEndDateSort(endDateSort === 'AES' ? 'DES' : 'AES');
                        setSort(endDateSort === 'AES' ? 'END_ASC' : 'END_DESC');
                      }}
                    >
                      <i
                        className={
                          endDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>
                    End Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>Updated By</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Updated At</span>
                </div>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <Query
            query={searchUserMenuDealQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.message}</Message>;
              }

              return (
                <View
                  data={data}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements }) => {
  const { search_user_menu_deal_list } = data;
  useEffect(() => {
    setPageCount(search_user_menu_deal_list.total_pages);
    setTotalElements(search_user_menu_deal_list.total_size);
  }, [search_user_menu_deal_list]);

  return (
    <tbody>
      {search_user_menu_deal_list.menu_deal_listing &&
        search_user_menu_deal_list.menu_deal_listing.map(deal => (
          <ListItem deal={deal} />
        ))}
    </tbody>
  );
};

export default UserPromos;
