import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const HoursButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 60%;
    margin: 1rem 0rem;
  }
`;

const AddCategoryHoursButton = ({ onClick }) => (
  <Container>
    {/* <h3 className="has-text-weight-semibold is-size-3">Time Based Category</h3> */}
    <HoursButton type="button" className="button control" onClick={onClick}>
      Add category hours
    </HoursButton>
  </Container>
);

export default AddCategoryHoursButton;
