import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';

import { toast } from 'react-toastify';
import client from '../../../utils/apolloClient';

const deletePlaceMutation = gql`
  mutation delete_place($input: NavigatePlaceInput) {
    delete_place(input: $input) {
      place_id
      error {
        description
      }
    }
  }
`;

const DeletePlaceMutation = ({ placeId, onCompleted }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deletePlaceMutation}
      variables={{
        input: {
          user_id: userId,
          place_id: placeId,
        },
      }}
      onCompleted={({ delete_place }) => {
        if (delete_place.error !== null) {
          delete_place.error.map(item => toast.error(item.description));
        } else {
          onCompleted();
        }
      }}
    >
      {delete_place => (
        <a
          onClick={() => {
            swal('Are you sure you want to do delete this place?', {
              buttons: ['Cancel', true],
            }).then(willDelete => {
              if (willDelete) {
                delete_place();
              }
            });
          }}
        >
          <span>
            <i className="fas fa-trash has-text-grey" />
          </span>
        </a>
      )}
    </Mutation>
  );
};

export default DeletePlaceMutation;
