import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import client from '../../../utils/apolloClient';

const deleteMutation = gql`
  mutation delete($input: NavigateItemDictionaryInput) {
    delete_item_dictionary(input: $input) {
      item_dictionary_id
      error {
        description
      }
    }
  }
`;

const Delete = ({ id, onCompleted }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteMutation}
      onCompleted={({ delete_item_dictionary }) => {
        if (!isNull(delete_item_dictionary.error)) {
          delete_item_dictionary.error.map(item =>
            toast.error(item.description),
          );
        } else {
          toast.success('Item dictionary` deleted successfully!');
          onCompleted();
        }
      }}
    >
      {(delete_item_dictionary, { loading }) => (
        <button
          className={`button ${loading && 'is-loading'}`}
          onClick={() => {
            swal({
              title: 'Are you sure?',
              // eslint-disable-next-line max-len
              text: `Are you sure you want to delete this Item dictionary`,
              buttons: ['Cancel', 'Yes'],
              dangerMode: true,
            }).then(willDelete => {
              if (willDelete) {
                delete_item_dictionary({
                  variables: {
                    input: {
                      user_id: userId,
                      item_dictionary_id: id,
                    },
                  },
                });
              }
            });
          }}
        >
          <i className="fas fa-trash has-text-grey" />
        </button>
      )}
    </Mutation>
  );
};

export default Delete;
