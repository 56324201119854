import React from 'react';
import LocationForm from './LocationForm';
import PrimaryDetailsForm from './PrimaryDetailsForm';
import Contacts from './Contacts';

const CompanyInformation = ({ props }) => (
  <div>
    <PrimaryDetailsForm {...props} />
    <br />
    <LocationForm {...props} />
    <br />
    <Contacts {...props} />
    <br />
  </div>
);
export default CompanyInformation;
