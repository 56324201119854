import React from 'react';
import { Link } from 'react-router-dom';

const CheckInList = ({ place }) => (
  <React.Fragment>
    <td>
      <Link to={`/place-activity/${place.place_id}`}>{place.name}</Link>
    </td>
    <td>{place.city}</td>
    <td>{`${place.status}`}</td>
    {/* <td>not found</td>
    <td>not found</td>
    <td>not found</td>
    <td>not found</td>
    <td>not found</td> */}
  </React.Fragment>
);

export default CheckInList;
