import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { first, isNull, isUndefined, kebabCase } from 'lodash';
import moment from 'moment';
import { FieldArray } from 'formik';
import uuid from 'uuid';
// import Input from './Input';
import { toast } from 'react-toastify';
import CreatableSelectInputs from './CreatableSelectInputs';
import { upload_image_s3 } from '../../utils/s3';

const DropZoneWrapper = styled.div`
  .box {
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  padding: 1rem;

  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
`;

const Figure = styled.figure`
  opacity: ${opacity => opacity};
`;

const LoaderWrapper = styled.div`
  &&& {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    bottom: 48%;
  }
`;

const placeTagValues = tagsValue => {
  const tags = [];
  if (!isUndefined(tagsValue)) {
    tagsValue.map(item => {
      tags.push({ value: item, label: item });
      return null;
    });
  }
  return tags;
};

const placeTagInput = tagsValue => {
  const tags = [];
  tagsValue.map(item => {
    tags.push(item.value);
    return null;
  });
  return tags;
};

const MyDropzone = ({
  totalImageLength,
  item,
  s3UploadPath,
  onChange,
  metaData,
  handleSelectLocalFile,
  children,
  index,
  // handleChange,
  // handleBlur,
  setFieldValue,
  handleRemoveImage,
  setDefaultImage,
  defaultButtonDisabled,
}) => {
  const onDrop = useCallback(async acceptedFiles => {
    const file = acceptedFiles[0];
    let dimension;
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      dimension = { width: image.width, height: image.height };
    };
    handleSelectLocalFile(image.src);
    const imageName = file.name.split('.');
    const name = `${kebabCase(first(imageName))}-${moment().format(
      'YYYYMMDD_hhmmss',
    )}.${file.type.substring(6)}`;
    const acceptImageList = ['image/jpeg', 'image/png', 'image/jpg'];
    const imageAcceptable = acceptImageList.filter(
      item1 => item1 === file.type,
    );
    if (imageAcceptable.length !== 0) {
      const { Location: url } = await upload_image_s3(
        file,
        `${s3UploadPath}${name}`,
        metaData,
      );
      const response = { path: name, url, dimension };
      console.log('response', response);
      onChange(response);
    } else {
      toast.error('Only png, jpeg, jpg image are accepted!');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    onDrop,
  });

  return (
    <DropZoneWrapper {...getRootProps()}>
      {item.preview !== null && (
        <ImageContainer>
          <Figure
            className="image is-16by9"
            opacity={item.uploadingStatus === 'uploading' ? 0.5 : 1}
          >
            <img src={item.preview} alt="image-upload" />
          </Figure>
          {item.uploadingStatus === 'uploading' && (
            <LoaderWrapper>
              <progress className="progress is-small is-success" max="100">
                15%
              </progress>
              <p className="is-size-2 has-text-weight-bold">Uploading....</p>
            </LoaderWrapper>
          )}
          <br />
          {item.uploadingStatus === 'uploaded' && (
            <React.Fragment>
              {/* <Input
                className="is-small"
                label="Link"
                name={`image.${index}.link`}
                value={item.link}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                className="is-small"
                label="Tooltip"
                name={`image.${index}.tooltip`}
                value={item.tooltip}
                onChange={handleChange}
                onBlur={handleBlur}
              /> */}
              <CreatableSelectInputs
                components={{
                  DropdownIndicator: null,
                }}
                label="Tags"
                suggestions={[]}
                value={!isNull(item.tags) ? placeTagValues(item.tags) : []}
                onChange={value => {
                  if (value) {
                    setFieldValue(`image.${index}.tags`, placeTagInput(value));
                  }
                }}
                isClearable={false}
              />
              <div className="is-flex">
                <a
                  className=" button is-light is-fullwidth"
                  onClick={setDefaultImage}
                  disabled={defaultButtonDisabled}
                >
                  Set Default
                </a>
                &nbsp;&nbsp;&nbsp;
                <a
                  className="button is-danger is-fullwidth"
                  onClick={handleRemoveImage}
                >
                  Delete
                </a>
              </div>
            </React.Fragment>
          )}
        </ImageContainer>
      )}

      {totalImageLength <= 5 && item.uploadingStatus === 'pending' && (
        <div className={children ? '' : 'box'}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <React.Fragment>
              {children || (
                <p>
                  Drag &apos;n&apos; drop some files here, or click to select
                  files
                </p>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </DropZoneWrapper>
  );
};

const UploadImage = ({
  s3UploadPath,
  metaData,
  setFieldValue,
  handleRemoveCurrentImage,
  setDefaultImage,
  defaultImage,
}) => (
  <FieldArray
    name="image"
    render={arrayHelpers => (
      <React.Fragment>
        {arrayHelpers.form.values &&
          arrayHelpers.form.values.image.map((item, index) => (
            <div className="column is-4">
              <MyDropzone
                totalImageLength={arrayHelpers.form.values.image.length}
                item={item}
                s3UploadPath={s3UploadPath}
                metaData={metaData}
                index={index}
                handleChange={arrayHelpers.form.handleChange}
                handleBlur={arrayHelpers.form.handleBlur}
                setFieldValue={setFieldValue}
                handleSelectLocalFile={preview => {
                  setFieldValue(`image.${index}.preview`, preview);
                  setFieldValue(`image.${index}.uploadingStatus`, 'uploading');
                  arrayHelpers.push({
                    id: uuid(),
                    url: '',
                    preview: null,
                    tooltip: '',
                    link: '',
                    tag: [],
                    uploadingStatus: 'pending',
                  });
                }}
                onChange={value => {
                  setFieldValue(`image.${index}.url`, value.url);
                  setFieldValue(`image.${index}.uploadingStatus`, 'uploaded');
                  if (defaultImage === null || defaultImage.length === 0) {
                    setDefaultImage({
                      url: value.url,
                    });
                  }
                }}
                handleRemoveImage={() => handleRemoveCurrentImage(item.id)}
                defaultButtonDisabled={defaultImage === item.url}
                setDefaultImage={() => setDefaultImage(item)}
              />
            </div>
          ))}
      </React.Fragment>
    )}
  />
);

MyDropzone.defaultProps = {
  s3UploadPath: 'dummy/',
  metaData: {},
  onChange: () => {},
  handleRemoveCurrentImage: () => {},
  defaultImage: '',
  setDefaultImage: () => {},
};

MyDropzone.propTypes = {
  s3UploadPath: PropTypes.string,
  defaultImage: PropTypes.string,
  metaData: PropTypes.object,
  onChange: PropTypes.func,
  handleRemoveCurrentImage: PropTypes.func,
  setDefaultImage: PropTypes.func,
};

export default UploadImage;
