import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Tab, Column } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import uuidv4 from 'uuid/v4';
import { isNull, startCase, omit } from 'lodash';

import {
  Box,
  Tabs,
  Select,
  Input,
  TextArea,
} from '../../../components/elements';

import findDirtyFields from '../../../utils/findDirtyFields';
import { SYSTEM_SERVICE_TYPES } from './util'
import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const {
    loading,
    dirty,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    service,
  } = props;

  useEffect(() => {
    setFieldValue('initialValue', values);
  }, []);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <Header loading={loading} dirty={dirty} serviceType={values.name} />
      <Box>
        <Tabs className="tabs is-medium">
          <Tab active onClick={() => {}}>
            Service Type
          </Tab>
        </Tabs>
        <Container>
          <Column.Group multiline gapSize={8}>
            <Column size="half">
              <Input
                label="Service Type"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={e => setFieldValue('name', startCase(e.target.value))}
                optionalText="( Max 26 characters )"
                maxLength="26"
                required
                disabled={service}
                errors={errors.name}
                touched={touched.name}
                disableToastMessage
                id="AddServiceType_Name"
              />
            </Column>

            <Column size="half">
              <Select
                label="Service Type Status"
                options={[
                  { value: 'ACTIVE', label: 'ACTIVE' },
                  { value: 'INACTIVE', label: 'INACTIVE' },
                ]}
                value={[
                  {
                    value: values.status,
                    label: values.status,
                  },
                ]}
                onChange={value => setFieldValue('status', value.value)}
                disableToastMessage
                required
                disabled={service && SYSTEM_SERVICE_TYPES.includes(service.name)}
              />
            </Column>
            <Column size="full">
              <TextArea
                label="Service Type Description"
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                optionalText="( Min 5 and Max 288 Characters )"
                errors={errors.description}
                touched={touched.description}
                disableToastMessage
                id="AddServiceType_ServiceTypeDescription"
              />
            </Column>
          </Column.Group>
        </Container>
      </Box>
      <Footer loading={loading} dirty={dirty} />
    </form>
  );
};

const ServiceForm = withFormik({
  mapPropsToValues: ({ service }) => ({
    service_type_id:
      service && !isNull(service.service_type_id)
        ? service.service_type_id
        : uuidv4(),
    name: service && !isNull(service.name) ? service.name : '',
    status: service && !isNull(service.status) ? service.status : 'ACTIVE',
    description:
      service && !isNull(service.description) ? service.description : '',
  }),

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(26, 'Service Type should not be greater than 26 characters')
      .required('Service Type is required!'),
    description: yup
      .string()
      .min(5, 'Please enter at least 5 characters')
      .max(288, 'Please enter not greater than 288 characters'),
  }),

  handleSubmit: (values, { props }) => {
    const input = omit(values, 'initialValue');
    const finalValues = findDirtyFields(input, values.initialValue);
    props.onSubmit({ ...finalValues, service_type_id: values.service_type_id });
  },

  displayName: 'GlobalMenuForm',
})(Form);

export default ServiceForm;
