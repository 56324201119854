import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { omit, capitalize } from 'lodash';
import gql from 'graphql-tag';
import styled from 'styled-components';

import client from '../../../../utils/apolloClient';

const searchItemsQuery = gql`
  query searchItems($input: SearchInput) {
    search_items(input: $input) {
      item_listing {
        item_id
        name
        place_name
        status
        city
      }
    }
  }
`;

const SuggestionsContainer = styled.div`
  max-height: 15rem;
  overflow-y: scroll;
`;

const renderInputComponent = inputProps => {
  const props = omit(inputProps, ['className', 'ref']);
  return (
    <div className="field">
      <div className="control has-icons-left has-icons-right">
        <input className="input" {...props} />
        <span className="icon is-small is-left">
          <i className="fas fa-search" />
        </span>
      </div>
    </div>
  );
};

const renderSuggestion = suggestion => (
  <div>
    <p className="is-size-4 has-text-weight-medium is-capitalized">
      {capitalize(suggestion.name)}
    </p>
    <p className="is-size-6 has-text-weight-medium is-capitalized">
      {capitalize(suggestion.place_name)}
    </p>
  </div>
);

const renderSuggestionsContainer = ({ containerProps, children }) =>
  children && (
    <SuggestionsContainer {...containerProps}>{children}</SuggestionsContainer>
  );

const PlaceSuggestionInput = ({
  placeId,
  label,
  initialValue,
  onSelect,
  onHandleClear,
  // swapItemReviewLoading,
  // swapItemReviewFunc,
}) => {
  const [value, setValue] = React.useState(`${initialValue}`);
  const [suggestions, setSuggestions] = React.useState([]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    onHandleClear(newValue.length);
  };

  const getSuggestionValue = suggestion => {
    onSelect(suggestion);
    return suggestion.name;
  };

  const onSuggestionsFetchRequested = inputValue => {
    client.clientPublic
      .query({
        query: searchItemsQuery,
        variables: {
          input: {
            filter: {
              item_filter: {
                name: inputValue.value,
                status: 'ACTIVE',
                place_id: placeId,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setSuggestions(data.search_items.item_listing);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'Search Items',
    value,
    onChange,
    type: 'search',
  };

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          renderSuggestionsContainer={renderSuggestionsContainer}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
      </div>
    </div>
  );
};

PlaceSuggestionInput.defaultProps = {
  label: '',
  initialValue: '',
  onSelect: () => {},
  onHandleClear: () => {},
};

PlaceSuggestionInput.propTypes = {
  label: PropTypes.string,
  initialValue: PropTypes.string,
  onSelect: PropTypes.func,
  onHandleClear: PropTypes.func,
};

export default PlaceSuggestionInput;
