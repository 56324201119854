import React from 'react';

import { useStoreState } from 'easy-peasy';

import Layout from '../../../components/global/Layout';

import Form from './Form';

const RewardUpdate = () => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Layout>
      <Form userId={userId} />
    </Layout>
  );
};

export default RewardUpdate;
