import React from 'react';
import styled from 'styled-components';
import { isNaN } from 'lodash';
import { parsedAmount } from '../helpers';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
`;

const SpecialSpacing = styled.div`
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const SpecialSpacingHr = styled(SpecialSpacing)`
  border-bottom: 1px solid #dbdbdb;
`;

const SpecialSpacingTopHr = styled(SpecialSpacing)`
  border-top: 1px solid #dbdbdb;
`;

const formatCurrency = value => {
  const price = !isNaN(parseFloat(value))
    ? `$${parseFloat(value).toFixed(2)}`
    : '';
  return price;
};

const Pricing = ({ order }) => (
  <Container>
    <SpecialSpacingHr>
      <p className="is-size-3 has-text-weight-semibold">SubTotal</p>
      <p className="is-size-3 has-text-weight-semibold">
        ${order.billing.sub_total.toFixed(2)}
      </p>
    </SpecialSpacingHr>
    {order.service_type === 'Delivery' && (
      <SpecialSpacing>
        <p className="is-size-3 has-text-weight-semibold">Delivery Fee</p>
        <p className="is-size-3 has-text-weight-semibold">
          ${order.billing.delivery_fee.toFixed(2)}
        </p>
      </SpecialSpacing>
    )}
    {!!(
      order.billing.delivery_fee_discount &&
      order.billing.delivery_fee_discount !== 0
    ) && (
      <SpecialSpacing>
        <p className="is-size-3 has-text-weight-semibold">
          Delivery Fee Discount
        </p>
        <p className="is-size-3 has-text-weight-semibold">
          - $
          {parseFloat(order.billing.delivery_fee_discount)
            .toFixed(2)
            .substring(1)}
        </p>
      </SpecialSpacing>
    )}
    {Array.isArray(order?.billing?.charges_list) &&
      order.billing.charges_list
        .filter(
          charges =>
            !charges.is_payment_gateway_fee &&
            charges.is_payment_gateway_fee !== null,
        )
        .filter(charges => {
          const tempValue = parseFloat(charges.value);
          return tempValue !== 0 && !isNaN(tempValue);
        })
        .map(charges => (
          <SpecialSpacing>
            <p className="is-size-3 has-text-weight-semibold">{charges.name}</p>
            <p className="is-size-3 has-text-weight-semibold">
              {formatCurrency(charges.value)}
            </p>
          </SpecialSpacing>
        ))}
    {Array.isArray(order?.billing?.surcharge_list) &&
      order.billing.surcharge_list
        .filter(charges => charges.charge)
        .map(charges => (
          <SpecialSpacing>
            <p className="is-size-3 has-text-weight-semibold">{charges.name}</p>
            <p className="is-size-3 has-text-weight-semibold">
              {formatCurrency(charges.charge)}
            </p>
          </SpecialSpacing>
        ))}
    {order.billing && !!order.billing.payment_gateway_fee && (
      <SpecialSpacing>
        <p className="is-size-3 has-text-weight-semibold">
          Card Processing Fee
        </p>
        <p className="is-size-3 has-text-weight-semibold">
          {formatCurrency(order.billing.payment_gateway_fee)}
        </p>
      </SpecialSpacing>
    )}

    {!!(order.billing.deal_discount && order.billing.deal_discount !== 0) && (
      <SpecialSpacing>
        <p className="is-size-3 has-text-weight-semibold has-text-primary">
          Promo
        </p>
        <p className="is-size-3 has-text-weight-semibold has-text-primary">
          - $
          {parseFloat(order.billing.deal_discount)
            .toFixed(2)
            .substring(1)}
        </p>
      </SpecialSpacing>
    )}

    {order.billing.adjustment_amount && order.billing.adjustment_amount !== 0 && (
      <SpecialSpacing>
        <p className="is-size-3 has-text-weight-semibold has-text-primary">
          Price Adjustments
        </p>
        <p className="is-size-3 has-text-weight-semibold has-text-primary">
          {order.billing.adjustment_amount < 0
            ? `- $${Math.abs(order.billing.adjustment_amount).toFixed(2)}`
            : `$${order.billing.adjustment_amount.toFixed(2)}`}
        </p>
      </SpecialSpacing>
    )}
    {order.adjustment_user && (
      <>
        <SpecialSpacing style={{ marginBottom: '0', paddingBottom: '0' }}>
          <p className="is-size-3 has-text-weight-semibold">
            Price Adjusted By
          </p>
          <p className="is-size-3 has-text-weight-semibold">Raj Mallampati</p>
        </SpecialSpacing>
        <div className="has-text-right">
          (2974f9d4-c4f9-42e3-8225-5d4d54c279ed)
        </div>
      </>
    )}
    <SpecialSpacingTopHr>
      <p className="is-size-3 has-text-weight-semibold">Total Amount</p>
      <p className="is-size-3 has-text-weight-semibold">
        {parsedAmount(order.billing.order_total.toFixed(2))}
      </p>
    </SpecialSpacingTopHr>
  </Container>
);

export default Pricing;
