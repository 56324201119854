import React, { useState } from 'react';
import { Tab } from 'rbx';
import { useStoreState } from 'easy-peasy';
import jsPDF from 'jspdf';
import format from 'date-fns/format';
import moment from 'moment';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import { Tabs } from '../../../components/elements';
import {
  fetchTransactionsSummary,
  transformTransactionsTotals,
  downloadCSVFile,
  fetchTransactionsDetails,
  transformTransactionsDetails,
  fetchPlaceData,
  transformTransactionsOrders,
  fetchTransactionsOrders,
} from './helpers';
import TransactionsSummaryTable from './TransactionsSummaryTable';
import TransactionsDetailsTable from './TransactionsDetailsTable';
import TransactionsOrdersTable from './TransactionsOrdersTable';

const transformStartDate = startDate => {
  const originalTimestamp = moment.utc(startDate);
  const convertedTimestamp = originalTimestamp.set({
    hour: 11,
    minute: 59,
    second: 0,
    millisecond: 0,
  });
  return convertedTimestamp.toISOString();
};

const OrderTransactions = () => {
  const { userId } = useStoreState(state => state.auth);
  // const from = ""
  // const size = ""
  // const totalPages = ""
  // const currentPages = ""

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const canLoadMoreData = currentPage + 1 < totalPages;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [activeTab, setActiveTab] = useState('TAB1');
  const [loading, setLoading] = useState(false);

  const [filterBy, setFilterBy] = useState({
    value: 'FILTER BY DAY',
    label: 'FILTER BY DAY',
  });

  const [placeName, setPlaceName] = useState('');

  const [transactionsTotalData, setTransactionsTotalData] = useState(null);

  const [transactionsDetailsData, setTransactionsDetailsData] = useState(null);
  const [placeId, setPlaceId] = useState(null);
  const [placeData, setPlaceData] = useState(null);
  const [cityFilter, setCityFilter] = useState('');

  const [transactionsOrdersData, setTransactionsOrdersData] = useState(null);

  // console.log(transactionsOrdersData);

  // console.log(placeId);

  // console.log(transactionsDetailsData);

  const onDownloadCsv = () => {
    const data = Array.from(document.getElementsByTagName('tr')).map(row =>
      Array.from(row.querySelectorAll('td,th'))
        .map(tdth => tdth.innerText.replace(/,/g, ' '))
        .join(','),
    );

    let csvDate;

    if (filterBy.value === 'FILTER BY DAY') {
      csvDate = format(new Date(startDate), 'dd/MM/yyyy');
    } else if (filterBy.value === 'FILTER BY MONTH') {
      csvDate = format(new Date(startDate), 'MMM/yyyy');
    } else if (filterBy.value === 'FILTER BY WEEK') {
      const i = format(new Date(startDate), 'dd/MM/yyyy');
      const j = format(new Date(endDate), 'dd/MM/yyyy');
      csvDate = `${i}-${j}`;
    }

    let headingElementId;

    if (activeTab === 'TAB1') {
      headingElementId = 'transactions-totals-heading-csv-pdf';
    } else if (activeTab === 'TAB2') {
      headingElementId = 'transactions-details-heading-csv-pdf';
    } else if (activeTab === 'TAB3') {
      headingElementId = 'transactions-orders-heading-csv-pdf';
    }

    const heading = document.getElementById(headingElementId).innerText;

    const csvData = [`${heading} ${csvDate}`, ...data].join('\n');
    downloadCSVFile(
      csvData,
      `${heading.replace(/ /g, '-')}-${csvDate.replace(/\//g, '-')}.csv`,
    );
  };

  const onDownloadPDF = () => {
    let elementId;
    if (activeTab === 'TAB1') {
      elementId = 'transactions-totals-table';
    } else if (activeTab === 'TAB2') {
      elementId = 'transactions-details-table';
    } else if (activeTab === 'TAB3') {
      elementId = 'transactions-orders-table';
    }
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({ orientation: 'landscape' });
    const table = document.getElementById(elementId);
    const tableHtml = table.innerHTML;

    // Generate the PDF from HTML
    doc.html(tableHtml, {
      callback(pdf) {
        pdf.save('table_landscape.pdf');
      },
      x: 10,
      y: 20,
    });
  };

  const time = `${startDate}${endDate}`;

  React.useEffect(() => {
    let ignore = false;
    const fetchTab1Data = async () => {
      try {
        setLoading(true);
        const tnstTotals = await fetchTransactionsSummary({
          userId,
          log: false,
          startDate: transformStartDate(startDate),
          endDate,
          placeName,
          cityFilter,
          from: currentPage * 50,
        });
        setTotalPages(tnstTotals.total_pages);

        if (!ignore && tnstTotals && currentPage === 0) {
          setTransactionsTotalData(transformTransactionsTotals(tnstTotals));
        } else if (!ignore && tnstTotals) {
          setTransactionsTotalData([
            ...transactionsTotalData,
            ...transformTransactionsTotals(tnstTotals),
          ]);
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    const fetchTab2Data = async () => {
      try {
        setLoading(true);
        const tnstDetails = await fetchTransactionsDetails({
          userId,
          placeId,
          startDate: transformStartDate(startDate),
          endDate,
          log: false,
          from: currentPage * 50,
        });
        setTotalPages(tnstDetails.total_pages);
        if (!ignore && tnstDetails && currentPage === 0) {
          setTransactionsDetailsData(transformTransactionsDetails(tnstDetails));
        } else if (!ignore && tnstDetails) {
          setTransactionsDetailsData([
            ...transactionsDetailsData,
            ...transformTransactionsDetails(tnstDetails),
          ]);
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    const fetchTab3Data = async () => {
      try {
        setLoading(true);
        const tnstOrders = await fetchTransactionsOrders({
          userId,
          placeId,
          startDate: transformStartDate(startDate),
          endDate,
          log: false,
          from: currentPage * 50,
        });
        setTotalPages(tnstOrders.total_pages);
        if (!ignore && tnstOrders && currentPage === 0) {
          setTransactionsOrdersData(transformTransactionsOrders(tnstOrders));
        } else if (!ignore && tnstOrders) {
          setTransactionsOrdersData([
            ...transactionsOrdersData,
            ...transformTransactionsOrders(tnstOrders),
          ]);
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    if (activeTab === 'TAB1') {
      fetchTab1Data();
    }
    if (activeTab === 'TAB2') {
      fetchTab2Data();
    }
    if (activeTab === 'TAB3') {
      fetchTab3Data();
    }
    return () => {
      ignore = true;
    };
  }, [activeTab, placeId, placeName, time, cityFilter, currentPage]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (placeId) {
        const d = await fetchPlaceData({ placeId, userId });
        setPlaceData(d);
      }
    };
    fetchData();
  }, [placeId]);

  const loadMoreDataFunc = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <Layout>
      <Header />
      <Search
        placeName={placeName}
        setPlaceName={setPlaceName}
        setCityFilter={setCityFilter}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        enablePlaceFilter={activeTab === 'TAB1'}
      />
      <div style={{ position: 'absolute', right: 0 }}>
        <button
          className="button is-primary is-outlined"
          style={{ margin: '5px' }}
          onClick={() => {
            onDownloadPDF();
          }}
        >
          Download PDF
        </button>
        <button
          style={{ margin: '5px' }}
          className="button is-primary is-outlined"
          onClick={() => {
            onDownloadCsv();
          }}
        >
          Download CSV
        </button>
      </div>
      <Tabs className="tabs is-medium is-fullwidth">
        <Tab
          active={activeTab === 'TAB1'}
          onClick={() => {
            if (activeTab === 'TAB1') {
              return;
            }
            setActiveTab('TAB1');
            setPlaceId(null);
            setPlaceData(null);
            setCityFilter('');
            setTransactionsDetailsData(null);
            setTotalPages(0);
            setCurrentPage(0);
          }}
          style={{ cursor: activeTab === 'TAB1' ? 'text' : 'pointer' }}
        >
          Transaction Summary
        </Tab>
        <Tab
          active={activeTab === 'TAB2'}
          onClick={() => {
            if (activeTab === 'TAB3') {
              setActiveTab('TAB2');
              setTotalPages(0);
              setCurrentPage(0);
            }
          }}
          style={{ cursor: activeTab === 'TAB3' ? 'pointer' : 'text' }}
        >
          Transaction Details
        </Tab>
        <Tab
          onClick={() => {
            if (activeTab === 'TAB2') {
              setActiveTab('TAB3');
              setTotalPages(0);
              setCurrentPage(0);
            }
          }}
          active={activeTab === 'TAB3'}
          style={{ cursor: activeTab === 'TAB2' ? 'pointer' : 'text' }}
        >
          Transactions
        </Tab>
      </Tabs>

      {activeTab === 'TAB1' && (
        <TransactionsSummaryTable
          loading={loading}
          transactionsTotalData={transactionsTotalData}
          onClick={id => {
            setActiveTab('TAB2');
            setPlaceId(id);
          }}
          canLoadMoreData={canLoadMoreData}
          loadMoreData={loadMoreDataFunc}
        />
      )}
      {activeTab === 'TAB2' && (
        <TransactionsDetailsTable
          placeData={placeData}
          loading={loading}
          transactionsDetailsData={transactionsDetailsData}
          onClick={() => {
            setActiveTab('TAB3');
          }}
          canLoadMoreData={canLoadMoreData}
          loadMoreData={loadMoreDataFunc}
        />
      )}
      {activeTab === 'TAB3' && (
        <TransactionsOrdersTable
          placeData={placeData}
          placeId={placeId}
          loading={loading}
          transactionsOrdersData={transactionsOrdersData}
          onClick={() => {}}
          canLoadMoreData={canLoadMoreData}
          loadMoreData={loadMoreDataFunc}
        />
      )}
    </Layout>
  );
};

export default OrderTransactions;
