import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tab, Column, Label } from 'rbx';
import { useStoreState } from 'easy-peasy';
import { withFormik } from 'formik';
import * as yup from 'yup';
import uuidv4 from 'uuid/v4';
import { isNull, trim } from 'lodash';
import PlacePageHeader from '../../../components/PlacePageHeader';

import {
  Box,
  Tabs,
  Select,
  Input,
  CreatableSelectInputs,
} from '../../../components/elements';
import { fetchAllCategoriesOfThePlace, getMenus } from './helpers';
import Header from './Header';
import Footer from './Footer';
import Menus from './Menus';
import AssignItems from './AssignItems';

import HoursModal from './HoursModal';
import WeeklyHours from './WeeklyHours';
import AddCategoryHoursButton from './AddCategoryHoursButton';

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const {
    loading,
    dirty,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    isCreatePage,
    activeAssignTab,
  } = props;
  const [isActiveTab, setIsActiveTab] = useState('section');
  const [menuLoading, setMenuLoading] = useState(false);
  const { userId } = useStoreState(state => state.auth);
  const [isActiveHoursModal, setIsActiveHoursModal] = useState(false);
  const [category, setCategory] = useState();
  const [categoryLoading, setCategoryLoading] = useState(true);

  const tagInput = tagsValue => {
    const tags = [];
    tagsValue.map(item => {
      tags.push(item.value);
      return null;
    });
    return tags;
  };
  const fetchFunc = async () => {
    setCategoryLoading(true);
    try {
      const c = await fetchAllCategoriesOfThePlace({
        user_id: values.user_id,
        place_id: values.place_id,
        menu_id: values.menu_id,
      });
      if (c)
        setCategory(
          (c ?? []).find(ele => ele.menu_section_id === values.menu_section_id),
        );
      setCategoryLoading(false);
    } catch {
      // pass
      setCategoryLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.section) {
      fetchFunc();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setMenuLoading(true);
      try {
        const menus = await getMenus({ placeId: values.place_id, userId });
        setFieldValue('menus', menus);
        setMenuLoading(false);
      } catch (error) {
        setMenuLoading(false);
      }
    };
    fetchData();
    if (activeAssignTab) {
      setIsActiveTab('assign-menu');
    }
    console.log('activeAssignTab', activeAssignTab);
  }, []);

  return (
    <>
      {isActiveHoursModal && (
        <HoursModal
          existingCategory={[]}
          user_id={values.user_id}
          menu_id={values.menu_id}
          place_id={values.place_id}
          isActive={isActiveHoursModal}
          onClose={f => {
            if (f) {
              setIsActiveHoursModal(false);
            } else {
              setIsActiveHoursModal(false);
              fetchFunc();
            }
          }}
          category={category}
        />
      )}
      <form
        onSubmit={event => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Header
          loading={loading}
          dirty={dirty}
          categoryName={values.name}
          isAssignActive={isActiveTab !== 'assign-menu'}
        >
          <PlacePageHeader
            platform={props?.section?.platform}
            place={props.place}
            placeId={values.place_id}
          />
        </Header>
        <Box>
          <Tabs className="tabs is-medium">
            <Tab
              active={isActiveTab === 'section'}
              onClick={() => setIsActiveTab('section')}
            >
              Menu Category
            </Tab>
            {!isCreatePage && values.menu_id.length !== 0 && (
              <Tab
                active={isActiveTab === 'assign-menu'}
                onClick={() => setIsActiveTab('assign-menu')}
              >
                Assign Items
              </Tab>
            )}
          </Tabs>
          <Container>
            {isActiveTab === 'section' && (
              <Column.Group multiline gapSize={8}>
                <Column size="half">
                  <Input
                    label="Category Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={event =>
                      setFieldValue('name', trim(event.target.value))
                    }
                    optionalText="( Max 35 characters )"
                    maxLength="35"
                    required
                    errors={errors.name}
                    touched={touched.name}
                    disableToastMessage
                  />
                </Column>

                <Column size="half">
                  <Select
                    label="Status"
                    options={[
                      { value: 'ACTIVE', label: 'ACTIVE' },
                      { value: 'INACTIVE', label: 'INACTIVE' },
                    ]}
                    value={[
                      {
                        value: values.status,
                        label: values.status,
                      },
                    ]}
                    onChange={value => setFieldValue('status', value.value)}
                    disableToastMessage
                    required
                  />
                </Column>
                <Column size="full">
                  <CreatableSelectInputs
                    label="Keywords / Tags"
                    name="description"
                    value={values.tag.map(item => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={value => {
                      console.log('value', value);
                      if (value) {
                        setFieldValue('tag', tagInput(value));
                      } else {
                        setFieldValue('tag', []);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </Column>
                <Column size="8">
                  {category && category.section_hour ? (
                    <WeeklyHours
                      hours={category.section_hour}
                      name={category.name}
                      onClick={() => {
                        setCategory(category);
                        setIsActiveHoursModal(true);
                      }}
                    />
                  ) : (
                    <AddCategoryHoursButton
                      onClick={() => {
                        if (categoryLoading) {
                          return;
                        }
                        setCategory(category);
                        setIsActiveHoursModal(true);
                      }}
                    />
                  )}
                </Column>

                <Column size="8">
                  <Label> Assign Menu Category to Menu</Label>
                  <p className="is-size-3">
                    Select the Menu that you would like to have the Menu
                    Category displayed
                  </p>
                  <br />
                  <Menus {...props} loading={menuLoading} />
                </Column>
              </Column.Group>
            )}
            {isActiveTab === 'assign-menu' && <AssignItems {...props} />}
          </Container>
        </Box>
        <Footer
          loading={loading}
          dirty={dirty}
          isAssignActive={isActiveTab !== 'assign-menu'}
        />
      </form>
    </>
  );
};

const ServiceForm = withFormik({
  mapPropsToValues: ({ place, section, userId }) => ({
    user_id: userId,
    place_id: section ? section.place_id : place.place_id,
    menu_id: section && !isNull(section.menu_id) ? section.menu_id : [],
    menu_section_id:
      section && !isNull(section.menu_section_id)
        ? section.menu_section_id
        : uuidv4(),
    name: section && !isNull(section.name) ? section.name : '',
    description:
      section && !isNull(section.description) ? section.description : '',
    status: section && !isNull(section.status) ? section.status : 'ACTIVE',
    tag: section && !isNull(section.tag) ? section.tag : [],
    menus: [],
  }),

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(35, 'Category Name cannot be greater than 35 characters')
      .required('Category Name is required!'),
    description: yup
      .string()
      .min(5, 'Please enter at least 5 characters')
      .max(288, 'Please enter not greater than 288 characters'),
  }),

  handleSubmit: (values, { props, initialValue }) => {
    console.log('initialValue', initialValue);
    props.onSubmit(values);
  },

  displayName: 'GlobalMenuForm',
})(Form);

export default ServiceForm;
