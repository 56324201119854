import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { first } from 'lodash';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import FlaggedReviewDetails from './FlaggedReviewDetails';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import { Loading, Message } from '../../../components/elements';

const flaggedReviewQuery = gql`
  query flaggedReview($input: NavigateFlagInput) {
    fetch_flag(input: $input) {
      flag_id
      flag_type
      flag_content
      object_id
      object_type
      parent_type
      status
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      error {
        description
      }
    }
  }
`;

const fetchPlace = gql`
  query fetchPlace($input: NavigatePlaceInput) {
    fetch_place(input: $input) {
      name
      city
      state
      country
      post_code
      listing_status
      claim_status
      status
      error {
        description
      }
    }
  }
`;

const itemQuery = gql`
  query itemReview($input: NavigateItemReviewInput) {
    fetch_item_review(input: $input) {
      item_id
      item {
        name
      }
      content
      status
      audit {
        created_at
        created_by
      }
      error {
        description
      }
    }
  }
`;

const actionFlagMutation = gql`
  mutation actionFlag($input: ActionFlagInput) {
    action_flag(input: $input) {
      flag_id
      error {
        description
      }
    }
  }
`;

const FlaggedReview = ({ match, history }) => {
  const { placeId, flagId } = match.params;
  const [action, setAction] = useState('APPROVED');
  const { userId } = useStoreState(state => state.auth);

  return (
    <Layout>
      <div>
        <Query query={fetchPlace} variables={{ input: { place_id: placeId } }}>
          {({ data: data1, loading: loading1, error: error1 }) => {
            if (loading1) {
              return <Loading />;
            }
            if (error1) {
              return <Message>{error1.message}</Message>;
            }

            const { fetch_place } = data1;

            return (
              <Query
                client={client.clientPrivate}
                query={flaggedReviewQuery}
                variables={{
                  input: { flag_id: flagId },
                }}
                fetchPolicy="network-only"
              >
                {({ data, loading, error }) => {
                  if (loading) {
                    return <Loading />;
                  }
                  if (error) {
                    return <Message>{error.message}</Message>;
                  }
                  const { fetch_flag } = data;

                  return (
                    <Query
                      query={itemQuery}
                      variables={{
                        input: { item_review_id: fetch_flag.object_id },
                      }}
                      fetchPolicy="network-only"
                    >
                      {({
                        data: itemData,
                        loading: itemLoading,
                        error: itemError,
                      }) => {
                        if (itemLoading) {
                          return <Loading />;
                        }
                        if (itemError) {
                          return <Message>{itemError.message}</Message>;
                        }
                        const items = first(itemData.fetch_item_review);

                        return (
                          <Mutation
                            client={client.clientPrivate}
                            mutation={actionFlagMutation}
                            onCompleted={({ action_flag }) => {
                              if (action_flag.error) {
                                action_flag.error.map(item =>
                                  toast.error(item.description),
                                );
                              } else {
                                swal(
                                  'Hurray!',
                                  `Flag ${
                                    action === 'APPROVED'
                                      ? 'Approved'
                                      : 'Rejected'
                                  } successfully`,
                                  'success',
                                ).then(() => {
                                  history.goBack();
                                });
                              }
                            }}
                          >
                            {(
                              action_flag,
                              { loading: flagLoading, error1: actionFlagError },
                            ) => (
                              <React.Fragment>
                                {actionFlagError && (
                                  <Message type="error">
                                    {actionFlagError && actionFlagError.message}
                                  </Message>
                                )}
                                <FlaggedReviewDetails
                                  action={action}
                                  place={fetch_place}
                                  flag={fetch_flag}
                                  items={items}
                                  loading={flagLoading}
                                  handleSubmit={value => {
                                    setAction(value);
                                    action_flag({
                                      variables: {
                                        input: {
                                          user_id: userId,
                                          flag_id: fetch_flag.flag_id,
                                          status: value,
                                        },
                                      },
                                    });
                                  }}
                                />
                              </React.Fragment>
                            )}
                          </Mutation>
                        );
                      }}
                    </Query>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};
export default withRouter(FlaggedReview);
