import React from 'react';
import { forEach } from 'lodash';

import styled from 'styled-components';

import Menu from './Menu';
import { Loading } from '../../../../components/elements';

const Container = styled.div`
  padding: 0rem 2rem;
`;

const filterOpenAndStart = menus => {
  const data = [];
  forEach(menus, menu => {
    if (menu.hours === 'OPENED' || menu.hours === 'STOPPED') {
      data.push(menu);
    }
  });
  return data;
};

const MainForm = ({ loading, menus, place, fetchMenus }) => (
  <Container>
    {loading && <Loading />}
    {!loading && (
      <Menu
        place={place}
        menus={menus}
        fetchMenus={fetchMenus}
        filterOpenAndStart={filterOpenAndStart}
      />
    )}
  </Container>
);

export default MainForm;
