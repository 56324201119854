import React from 'react';
import { Column } from 'rbx';
import { isNull } from 'lodash';

import ImageItem from '../../../components/ImageItem';

const PhotoReviews = ({ values, setFieldValue, image }) => (
  <React.Fragment>
    <Column.Group vcentered multiline gapSize={8}>
      <React.Fragment>
        <Column size={3}>
          <ImageItem
            // isRejected={values.rejectImages.length !== 0}
            imageReviewId={image.image_id}
            imageSrc={image.url}
            status={image.status}
            tags={
              !isNull(image.tags) && image.tags !== 0
                ? image.tags.map(item => ({
                    label: item,
                    value: item,
                  }))
                : []
            }
            // handleRejection={() => {
            //   setFieldValue(
            //     'rejectImages',
            //     values.rejectImages.concat(values.images),
            //   );
            // }}
          />
        </Column>
      </React.Fragment>
    </Column.Group>
  </React.Fragment>
);

export default PhotoReviews;
