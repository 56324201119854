import React from 'react';
import styled from 'styled-components';

const Counter = styled.div`
  display: inline-flex;
  position: relative;

  bottom: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  padding: 0;
  background-color: white;
  border: 1px solid #00aeef;
  color: #00aeef;
  width: 270px;
  height: 50px;
  font-size: 12px;
  font-weight: bold;

  .counter-btn {
    cursor: pointer;
    border-radius: 30px;
    border: none;
    background-color: #00aeef;
    height: 44px;
    width: 50px;
    color: white;
    padding: 0;
    font-size: 20px;
  }

  .counter-value {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    color: black;
    font-size: 12px;
  }

  .plus {
    margin-right: 2px;
  }

  .minus {
    margin-left: 2px;
  }
`;

const TimeCounter = ({ value, onChange }) => (
  <Counter>
    <button
      type="button"
      className="counter-btn minus"
      onClick={() => {
        if (value > 15) {
          onChange(parseInt(value, 10) - 1);
        }
      }}
    >
      <i className="fas fa-minus" />
    </button>
    <div className="counter-value">{value} MIN(S)</div>
    <button
      type="button"
      className="counter-btn plus"
      onClick={() => {
        if (value < 60) {
          onChange(parseInt(value, 10) + 1);
        }
      }}
    >
      <i className="fas fa-plus" />
    </button>
  </Counter>
);

export default TimeCounter;
