import React, { useState } from 'react';
import styled from 'styled-components';

import Form from './Form';
import VariantItem from './VariantItem';

const Container = styled.div``;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.waterMarkGrey};
  padding: 1rem 1rem;
  padding-left: 3rem;
  align-items: center;
  background-color: ${({ availability }) =>
    !availability ? '#fbf8f8' : '#fbf8f8'};
`;

const Item = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
`;

const IconStyled = styled.i`
  margin-right: 10px;
`;

const ModifierItem = ({
  modifier,
  originalModifiers,
  onHandleLinkModifierSubmission,
  linkRootAVDate,
}) => {
  const [isActive, setIsActive] = useState(false);

  const originalModifier = originalModifiers
    ? originalModifiers.filter(
        item => item.menu_item_id === modifier.menu_item.menu_item_id,
      )
    : [];

  const finalModifier = {
    ...modifier,
    modifier:
      originalModifier.length !== 0 ? originalModifier[0] : modifier.modifier,
  };

  const rootIsAvailable =
    finalModifier.menu_item.availability_status !== 'UNAVAILABLE';
  const availability_date =
    new Date(finalModifier.modifier.availability_date) > new Date()
      ? finalModifier.modifier.availability_date
      : null;
  const availabilityStatus = availability_date ? 'UNAVAILABLE' : 'AVAILABLE';

  return (
    <>
      <Container>
        <Flex>
          <Item
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            {finalModifier.menu_item.variant &&
            finalModifier.menu_item.variant.length !== 0 ? (
              <>
                {isActive ? (
                  <IconStyled className="fas fa-chevron-down" />
                ) : (
                  <IconStyled className="fas fa-chevron-right" />
                )}
              </>
            ) : (
              <div />
            )}
            <div>
              <h4 className="title is-4">{finalModifier.menu_item.name}</h4>
            </div>
          </Item>
          {finalModifier.menu_item.variant &&
            finalModifier.menu_item.variant.length === 0 && (
              <Form
                rootIsAvailable={rootIsAvailable}
                availability_date={availability_date}
                availabilityStatus={availabilityStatus}
                minDate={linkRootAVDate}
                onSubmit={date => {
                  onHandleLinkModifierSubmission(date, 'MODIFIER_ITEM_LINK', {
                    modifier_id: modifier.menu_item.menu_item_id,
                  });
                }}
              />
            )}
        </Flex>
      </Container>
      {isActive &&
        finalModifier.menu_item.variant &&
        finalModifier.menu_item.variant.map(edge => {
          const originalVariant = finalModifier.modifier.variant
            ? finalModifier.modifier.variant.filter(
                item => item.prompt_id === edge.prompt_id,
              )
            : [];
          const finalOriginalVariant =
            originalVariant.length !== 0 ? originalVariant[0] : null;

          return edge.variants.map(edge2 => {
            const originalVariantItem = finalOriginalVariant
              ? finalOriginalVariant.variants.filter(
                  item => item.variant_id === edge2.variant_id,
                )
              : [];
            const finalOriginalVariantItem =
              originalVariantItem.length !== 0 ? originalVariantItem[0] : null;
            const var_availability_date =
              finalOriginalVariantItem &&
              new Date(finalOriginalVariantItem.availability_date) > new Date()
                ? finalOriginalVariantItem.availability_date
                : null;
            const availabilityStatus2 = var_availability_date
              ? 'UNAVAILABLE'
              : 'AVAILABLE';

            return (
              <VariantItem
                isModifierVariant
                availabilityStatus={availabilityStatus2}
                availability_date={var_availability_date}
                rootIsAvailable={rootIsAvailable}
                minDate={linkRootAVDate}
                prompt={edge.prompt}
                variant={edge2}
                onSubmit={date => {
                  onHandleLinkModifierSubmission(
                    date,
                    'MODIFIER_ITEM_LINK_VARIANT',
                    {
                      ...edge2,
                      prompt_id: edge.prompt_id,
                      modifier_id: modifier.menu_item.menu_item_id,
                    },
                  );
                }}
              />
            );
          });
        })}
    </>
  );
};

export default ModifierItem;
