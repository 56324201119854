import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import {
  isNull,
  forEach,
  omit,
  isEqual,
  uniqBy,
  concat,
  differenceBy,
} from 'lodash';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import { Loading, Message } from '../../../components/elements';
import Form from './Form';
import { parseContactValidValue } from '../../../utils/helpers';

const updatePlaceMutation = gql`
  mutation updatePlace($input: PlaceInput) {
    update_place(input: $input) {
      place_id
      name
      error {
        description
      }
    }
  }
`;

const approvePhotoMutation = gql`
  mutation approvePhotosAction(
    $user_id: String
    $image_id: String
    $image_review_status: String
  ) {
    action_image(
      input: {
        user_id: $user_id
        image_id: $image_id
        approval_status: $image_review_status
      }
    ) {
      status
      image_id
      url
      error {
        description
      }
    }
  }
`;

const placeApprovalMutation = gql`
  mutation placeApproval($input: ActionPlaceInput) {
    action_place(input: $input) {
      place_id
      name
      error {
        code
        description
      }
    }
  }
`;

const placeQuery = gql`
  query fetch_place($place_id: String!) {
    fetch_place(input: { place_id: $place_id }) {
      place_id
      name
      listing_status
      claim_status
      status
      description
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      timezone
      latitude
      longitude
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      social {
        type
        logo
        description
        value
        display
      }
      note {
        value
      }
      business_hour {
        day
        is_active
        option {
          type
          start
          end
          start_in_sec
          end_in_sec
        }
      }
      business_type {
        name
        description
        display_order
      }

      service_category {
        name
        description
        display_order
      }

      feature {
        name
        description
        logo
        display_order
      }
      cuisine_style {
        name
        description
        display_order
      }
      good_for {
        name
        description
        display_order
      }

      dining_style {
        name
        description
        display_order
      }
      listing_audit {
        listing_contact_user_id
        listing_contact_name
        listing_contact_phone
        listing_contact_email
        listing_contact_role
        listing_approved_by
        listing_approved_at
      }
      audit {
        created_at
      }
      error {
        code
        description
      }
    }
  }
`;

const searchPlaceImageReviewQuery = gql`
  query searchPlaceImageReview($placeId: String, $imageStatus: String) {
    search_image_review(
      input: {
        filter: {
          image_review_filter: {
            object_id: $placeId
            parent_id: $placeId
            object_type: "PLACE"
            parent_type: "PLACE"
            approval_status: $imageStatus
          }
        }
      }
    ) {
      image_review_listing {
        image_id
        image_id
        url
        tags
        approval_status
        status
      }
    }
  }
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const removeBusinessTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    const hours = omit(item, ['option', '__typename']);
    const option = [];
    forEach(item.option, g => {
      const customOption = omit(g, ['__typename']);
      option.push({ ...customOption });
    });

    final.push({ ...hours, option });
  });
  return final;
};

const PlaceApprovalUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const [approvedImages, setApprovedImages] = useState([]);
  const [placeUpdateData, setPlaceUpdateData] = useState(null);
  const [action, setAction] = useState(null);

  const [
    placePhotoApproval,
    { loading: placePhotoApprovalLoading },
  ] = useMutation(approvePhotoMutation, {
    client: client.clientPrivate,
    onCompleted: () => {},
  });

  const [
    updatePlace,
    { loading: updatePlaceLoading, error: placeUpdateError },
  ] = useMutation(updatePlaceMutation, {
    client: client.clientPrivate,
    onCompleted: ({ update_place }) => {
      if (!isNull(update_place)) {
        if (!isNull(update_place.error)) {
          update_place.error.map(item => toast.error(item.description));
        } else if (action === 'update-place') {
          swal(
            'Nice!',
            'Place has been approved successfully!',
            'success',
          ).then(() => {
            history.goBack();
          });
        } else {
          swal('Nice!', 'Place has been updated successfully!', 'success').then(
            () => {
              history.goBack();
            },
          );
        }
      } else {
        toast.error('something went wrong');
      }
    },
  });

  const [
    actionPlace,
    { loading: actionPlaceLoading, error: approvalError },
  ] = useMutation(placeApprovalMutation, {
    client: client.clientPrivate,
    onCompleted: async () => {
      if (approvedImages.length !== 0) {
        await approvedImages.map(item =>
          placePhotoApproval({
            variables: {
              user_id: userId,
              image_id: item.image_id,
              image_review_status: 'APPROVED',
            },
          }),
        );
        updatePlace({
          variables: { input: placeUpdateData },
        });
      } else {
        updatePlace({
          variables: { input: placeUpdateData },
        });
      }
    },
  });

  return (
    <Layout>
      <div className="container">
        <Query
          query={placeQuery}
          variables={{ place_id: match.params.placeId }}
          fetchPolicy="network-only"
        >
          {({ data: data1, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>{error.description}</Message>;
            }

            const place = data1.fetch_place;

            return (
              <Query
                client={client.clientPrivate}
                query={searchPlaceImageReviewQuery}
                variables={{
                  placeId: place.place_id,
                  imageStatus:
                    place.listing_status === 'APPROVED'
                      ? 'APPROVED'
                      : 'SUBMITTED',
                }}
              >
                {({ data: data2, loading: loading2, error: error2 }) => {
                  if (loading2) {
                    return <Loading />;
                  }
                  if (error2) {
                    return <Message>{error2.description}</Message>;
                  }

                  const images = data2.search_image_review.image_review_listing;

                  return (
                    <Form
                      place={place}
                      images={images}
                      placeUpdateLoading={updatePlaceLoading}
                      error={placeUpdateError || approvalError}
                      approvalLoading={
                        actionPlaceLoading || placePhotoApprovalLoading
                      }
                      onSubmit={data => {
                        const allApprovalPhotos = differenceBy(
                          data.allImages,
                          data.rejectImages,
                          'image_id',
                        );
                        setApprovedImages(allApprovalPhotos);

                        const businessHour = data.business_hour.map(item => {
                          const customItem = omit(item, [
                            'open24Hours',
                            'closed',
                          ]);
                          return customItem;
                        });

                        const primaryContacts = [
                          {
                            type: 'email_primary',
                            value: data.primaryEmail,
                            display: data.displayEmail,
                            is_primary: true,
                            display_order: '1',
                          },
                          {
                            type: 'phone_primary',
                            value: data.primaryMobile,
                            display: data.displayMobile,
                            is_primary: true,
                            display_order: '1',
                          },
                          {
                            type: 'website',
                            value: data.businessSite,
                            display: data.displayBusiness,
                            is_primary: true,
                            display_order: '1',
                          },
                          {
                            type: 'facebook',
                            value: data.facebookPage,
                            display: data.displayFacebook,
                            is_primary: true,
                            display_order: '1',
                          },
                        ];

                        const formContact = () => {
                          const final = [];
                          forEach(data.contact, item => {
                            const custom = omit(item, ['id']);
                            final.push(custom);
                          });
                          return final;
                        };

                        const contact = concat(primaryContacts, formContact());

                        const placeUpdateInput = {
                          user_id: userId,
                          place_id: data.place_id,
                          name: data.name,
                          listing_status: data.listing_status,
                          claim_status: data.claim_status,
                          description: data.description,
                          address_line_1: data.address_line_1,
                          address_line_2: data.address_line_2,
                          listing_contact_role: data.relationShip,
                          city: data.city,
                          state: data.state,
                          country: data.country,
                          post_code: data.post_code,
                          timezone: data.timezone,
                          latitude: data.latitude,
                          longitude: data.longitude,
                          business_hour: businessHour,
                          business_type: data.business_type,
                          service_category: data.service_category,
                          feature: data.feature,
                          cuisine_style: data.cuisine_style,
                          good_for: data.good_for,
                          dining_style: data.dining_style,
                        };
                        const input = {
                          ...placeUpdateInput,
                          business_hour: businessHour,
                          contact: parseContactValidValue(contact),
                        };
                        const final = {};
                        Object.assign(
                          final,
                          { user_id: userId },
                          { place_id: place.place_id },
                          !isEqual(place.name, input.name) && {
                            name: input.name,
                          },
                          !isEqual(place.description, input.description) && {
                            description: input.description,
                          },
                          !isEqual(
                            place.listing_status,
                            input.listing_status,
                          ) && {
                            listing_status: input.listing_status,
                          },
                          !isEqual(
                            place.listing_contact_role,
                            input.listing_contact_role,
                          ) && {
                            listing_role: input.listing_contact_role,
                          },

                          !isEqual(
                            removeTypename(place.contact),
                            input.contact,
                          ) && {
                            contact: input.contact,
                          },

                          !isEqual(
                            place.address_line_1,
                            input.address_line_1,
                          ) && {
                            address_line_1: input.address_line_1,
                          },

                          !isEqual(place.listing_audit, input.relationShip) && {
                            relationship: input.relationShip,
                          },
                          !isEqual(
                            place.address_line_2,
                            input.address_line_2,
                          ) && {
                            address_line_2: input.address_line_2,
                          },
                          !isEqual(place.city, input.city) && {
                            city: input.city,
                          },
                          !isEqual(place.state, input.state) && {
                            state: input.state,
                          },
                          !isEqual(place.country, input.country) && {
                            country: input.country,
                          },
                          !isEqual(place.post_code, input.post_code) && {
                            post_code: input.post_code,
                          },
                          !isEqual(place.latitude, input.latitude) && {
                            latitude: input.latitude,
                          },
                          !isEqual(place.longitude, input.longitude) && {
                            longitude: input.longitude,
                          },
                          !isEqual(place.timezone, input.timezone) && {
                            timezone: input.timezone,
                          },
                          !isEqual(
                            removeTypename(place.business_type),
                            input.business_type,
                          ) && {
                            business_type: input.business_type,
                          },

                          !isEqual(
                            removeBusinessTypename(place.business_hour),
                            uniqBy(input.business_hour, 'day'),
                          ) &&
                            input.business_hour.length !== 0 && {
                              business_hour: uniqBy(input.business_hour, 'day'),
                            },

                          !isEqual(
                            removeTypename(place.service_category),
                            input.service_category,
                          ) && {
                            service_category: input.service_category,
                          },

                          !isEqual(
                            removeTypename(place.feature),
                            input.feature,
                          ) && {
                            feature: input.feature,
                          },

                          !isEqual(
                            removeTypename(place.good_for),
                            input.good_for,
                          ) && {
                            good_for: input.good_for,
                          },

                          !isEqual(
                            removeTypename(place.cuisine_style),
                            input.cuisine_style,
                          ) && {
                            cuisine_style: input.cuisine_style,
                          },

                          !isEqual(
                            removeTypename(place.dining_style),
                            input.dining_style,
                          ) && {
                            dining_style: input.dining_style,
                          },
                        );

                        /* 
                        To PREVENT INVALID CRON EXPRESSION ERROR THROWN BY BACKEND
                        
                        INVALID CRON EXPRESSION ERROR

                        Backend will throw the error if the 
                        bussiness_hour object is of below type

                          [
                            {
                              "day": "MON",
                              "is_active": true,
                              "option": [
                                          {
                                            "type": "",
                                            "start": "",
                                            "end": ""
                                        }
                                        ]
                            },
                            {
                                "day": "TUE",
                                "is_active": true,
                                "option": [
                                    {
                                        "type": null,
                                        "start": "",
                                        "end": "",
                                        "start_in_sec": 0,
                                        "end_in_sec": 0
                                    }
                                ]
                            },
                            {
                                "day": "WED",
                                "is_active": true,
                                "option": [
                                    {
                                        "type": null,
                                        "start": "",
                                        "end": "",
                                        "start_in_sec": 0,
                                        "end_in_sec": 0
                                    }
                                ]
                            },
                            {
                                "day": "THU",
                                "is_active": true,
                                "option": [
                                    {
                                        "type": null,
                                        "start": "",
                                        "end": "",
                                        "start_in_sec": 0,
                                        "end_in_sec": 0
                                    }
                                ]
                            },
                            {
                                "day": "FRI",
                                "is_active": true,
                                "option": [
                                    {
                                        "type": null,
                                        "start": "",
                                        "end": "",
                                        "start_in_sec": 0,
                                        "end_in_sec": 0
                                    }
                                ]
                            },
                            {
                                "day": "SAT",
                                "is_active": true,
                                "option": [
                                    {
                                        "type": null,
                                        "start": "",
                                        "end": "",
                                        "start_in_sec": 0,
                                        "end_in_sec": 0
                                    }
                                ]
                            },
                            {
                                "day": "SUN",
                                "is_active": true,
                                "option": [
                                    {
                                        "type": null,
                                        "start": "",
                                        "end": "",
                                        "start_in_sec": 0,
                                        "end_in_sec": 0
                                    }
                                ]
                            }
                            ]  

                        */

                        if (
                          (final.business_hour ?? []).filter(
                            bh =>
                              bh.option[0].start === '' &&
                              bh.option[0].end === '',
                          ).length === (final.business_hour ?? []).length
                        ) {
                          final.business_hour = [];
                        }

                        setPlaceUpdateData(final);
                        setAction(data.action);
                        if (data.action === 'approve-listing') {
                          actionPlace({
                            variables: {
                              input: {
                                user_id: userId,
                                place_id: place.place_id,
                                type: 'LISTING',
                                status: 'APPROVED',
                                note: [
                                  {
                                    type: 'PLACE_APPROVAL',
                                    value: data.notes,
                                    date: moment().toISOString(),
                                  },
                                ],
                              },
                            },
                          });
                        }
                        if (data.action === 'update-place') {
                          updatePlace({
                            variables: { input: final },
                          });
                        }
                      }}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default withRouter(PlaceApprovalUpdate);
