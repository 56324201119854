import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';

import Delete from './Delete';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const CheckInList = ({ service }) => (
  <>
    <td>
      <ReactTooltip html place="top" type="dark" effect="float" />
      <Link to={`/service/${service.service_type_id}`}>{service.name}</Link>
    </td>
    <td>
      <p
        data-tip={`<span>
          <p>email: ${service.updated_by_user.email}</p>
          <p>objectId: ${service.updated_by_user.user_id}</p>
        </span>`}
        data-html
      >
        {service.updated_by_user.display_name}
      </p>
    </td>
    <td> {moment(service.audit.updated_at).format('lll')} </td>
    <td>
      <p
        data-tip={`<span>
          <p>email: ${service.created_by_user.email}</p>
          <p>objectId: ${service.created_by_user.user_id}</p>
        </span>`}
        data-html
      >
        {service.created_by_user.display_name}
      </p>
    </td>
    <td> {moment(service.audit.created_at).format('lll')} </td>

    <td>
      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action="delete_service_type"
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => (
              <Delete
                disabled={new Set(['Pickup', 'Delivery', 'Dinein']).has(
                  service.name,
                )}
                serviceId={service.service_type_id}
              />
            )}
          />
        )}
      </ACLSConsumer>
    </td>
  </>
);

export default CheckInList;
