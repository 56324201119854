import React, { useState } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import uuid from 'uuid';

import BusinessDetail from './BusinessDetail';
import BusinessFee from './BusinessFee';

const Tabs = styled.div`
  background-color: #f6f6f6;
`;
const Wrapper = styled.div``;

const tabs = [
  {
    key: 'business-details',
    name: 'Business Details',
  },
  {
    key: 'business-fee',
    name: 'Business Fee',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState('business-details');
  const { handleSubmit, loading, error } = props;
  console.log('test.values :', props.values);
  return (
    <form>
      <Tabs className="tabs is-medium">
        <ul>
          {tabs.map(tab => (
            <li className={activeTab === tab.key && 'is-active'} key={tab.key}>
              <a onClick={() => setActiveTab(tab.key)}>{tab.name}</a>
            </li>
          ))}
        </ul>
      </Tabs>
      <Wrapper>
        {activeTab === 'business-details' && (
          <BusinessDetail
            {...props}
            next={() => setActiveTab('business-fee')}
          />
        )}
        {activeTab === 'business-fee' && <BusinessFee {...props} />}
      </Wrapper>
    </form>
  );
};

const TestSection = withFormik({
  mapPropsToValues: () => ({
    vendor_id: '',
    place_id: uuid(),
    name: '',
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'BasicForm',
})(Form);

export default TestSection;
