/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Column } from 'rbx';
import { trimStart } from 'lodash';
import { Select, Input, TextArea } from '../../../components/elements';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';
import UploadImage from '../../../components/elements/UploadImage';
import UpdateUploadImage from '../../../components/elements/UpdateUploadImage';
import client from '../../../utils/apolloClient';

const Container = styled.div``;

const deleteMutation = gql`
  mutation delete($userId: String, $imageId: String) {
    delete_image(input: { user_id: $userId, image_id: $imageId }) {
      image_id
      error {
        description
      }
    }
  }
`;

const updateFeedMutation = gql`
  mutation updateFeed($input: FeedInput) {
    update_feed(input: $input) {
      feed_id
      title
      default_image_url
      error {
        description
      }
    }
  }
`;

const Details = ({ values, touched, errors, setValues, setFieldValue }) => {
  const { userId } = useStoreState(state => state.auth);
  const [
    whichImageSettingDefault,
    setWhichImageSettingDefault,
  ] = React.useState(null);

  const [updateFeed, { loading: updateFeedLoading }] = useMutation(
    updateFeedMutation,
    {
      client: client.clientPrivate,
      onCompleted: ({ update_feed }) => {
        setFieldValue('default_image_url', update_feed.default_image_url);
      },
    },
  );

  const handleRemoveUploadedImage = id => {
    const currentImage = values.uploadedImages.filter(item => item.id === id)[0]
      .url;
    if (currentImage === values.current_default_image_url) {
      toast.error(
        'You cannot delete a default image. Please select another image as the default image and submit it after that you are able to delete this image.',
      );
    } else {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this image!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          setValues({
            ...values,
            uploadedImages: values.uploadedImages.filter(
              item => item.id !== id,
            ),
          });
          client.clientPrivate
            .mutate({
              mutation: deleteMutation,
              variables: { userId, imageId: id },
            })
            .then(result => {
              console.log('result', result);
            })
            .catch(error => {
              console.log('result', error);
            });
        }
      });
    }
  };

  const handleRemoveCurrentImage = id => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this image!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        setValues({
          ...values,
          image: values.image.filter(item => item.id !== id),
        });
      }
    });
  };
  return (
    <Container>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input
            action={[('create_feed', 'update_feed')]}
            label="Posting Title"
            name="title"
            id="PlaceManagement_PlacePosting_PostingTitle"
            value={values.title}
            onChange={event =>
              setFieldValue('title', trimStart(event.target.value))
            }
            optionalText="( Min 3 characters and Max 50 characters )"
            required
            errors={errors.title}
            touched={touched.title}
          />
        </Column>

        <Column size="3">
          <Select
            action={[('create_feed', 'update_feed')]}
            label="Posting Status"
            options={[
              { value: 'ACTIVE', label: 'ACTIVE' },
              { value: 'INACTIVE', label: 'INACTIVE' },
            ]}
            value={[
              {
                value: values.status,
                label: values.status,
              },
            ]}
            onChange={value => setFieldValue('status', value.value)}
            required
          />
        </Column>

        <Column size="full">
          <TextArea
            action={[('create_feed', 'update_feed')]}
            label="Posting Content"
            id="PlaceManagement_PlacePosting_PostingContent"
            required
            name="content"
            value={values.content}
            onChange={event =>
              setFieldValue('content', trimStart(event.target.value))
            }
            optionalText="( Min 20 and Max 800 Characters )"
            errors={errors.content}
            touched={touched.content}
          />
        </Column>
      </Column.Group>
      <div className="columns is-multiline">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={['create_feed', 'update_feed']}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <UpdateUploadImage
                  action={['create_feed', 'update_feed']}
                  s3UploadPath={`place-feed/${values.feed_id}/`}
                  metaData={{
                    feed_id: values.feed_id,
                    user_id: userId,
                  }}
                  setFieldValue={setFieldValue}
                  handleRemoveUploadedImage={handleRemoveUploadedImage}
                  defaultImageLoading={updateFeedLoading}
                  whichImageSettingDefault={whichImageSettingDefault}
                  setDefaultImage={image => {
                    setWhichImageSettingDefault(image.url);
                    updateFeed({
                      variables: {
                        input: {
                          feed_id: values.feed_id,
                          user_id: userId,
                          default_image_url: image.url,
                        },
                      },
                    });
                  }}
                  defaultImage={values.default_image_url}
                />
              )}
              no={() =>
                values.uploadedImages.map(item => (
                  <div className="column is-3">
                    <figure
                      className="image is-16by9"
                      opacity={item.uploadingStatus === 'uploading' ? 0.5 : 1}
                    >
                      <img src={item.preview} alt="image-upload" />
                    </figure>
                  </div>
                ))
              }
            />
          )}
        </ACLSConsumer>

        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={['create_feed', 'update_feed']}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <UploadImage
                  s3UploadPath={`place-feed/${values.feed_id}/`}
                  metaData={{
                    feed_id: values.feed_id,
                    user_id: userId,
                  }}
                  setFieldValue={setFieldValue}
                  handleRemoveCurrentImage={handleRemoveCurrentImage}
                  setDefaultImage={image => {
                    setFieldValue('default_image_url', image.url);
                  }}
                  defaultImage={values.default_image_url}
                />
              )}
            />
          )}
        </ACLSConsumer>
      </div>
    </Container>
  );
};

export default Details;
