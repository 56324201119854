import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';

import { toast } from 'react-toastify';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import AddItemDictionaryForm from './AddDictionaryForm';

const createItemDictionaryMutation = gql`
  mutation createItemDictionary($input: ItemDictionaryInput) {
    create_item_dictionary(input: $input) {
      item_dictionary_id
      name
      description
      status
      error {
        description
      }
    }
  }
`;

const AddItemDictionary = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <Mutation
        client={client.clientPrivate}
        mutation={createItemDictionaryMutation}
        onCompleted={({ create_item_dictionary }) => {
          if (create_item_dictionary.error) {
            create_item_dictionary.error.map(item =>
              toast.error(item.description),
            );
          } else {
            swal(
              'Great!',
              'Item Dictionary has been created successfully!',
              'success',
            ).then(() => {
              history.goBack();
            });
          }
        }}
      >
        {(create_item_dictionary, { loading, error }) => (
          <AddItemDictionaryForm
            userId={userId}
            loading={loading}
            error={error}
            onSubmit={input => {
              Object.assign(
                input,
                { user_id: userId },
                { item_dictionary_id: input.item_dictionary_id },
                { name: input.name },
                { status: input.status },
              );

              create_item_dictionary({ variables: { input } });
            }}
          />
        )}
      </Mutation>
    </Layout>
  );
};
export default AddItemDictionary;
