import React from 'react';
import { Column } from 'rbx';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { filter, first, isUndefined } from 'lodash';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
import { Input } from '../../../components/elements';

const searchSocial = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_count {
          type
          count
        }
      }
    }
  }
`;

const searchFlags = gql`
  query searchFlags($input: SearchInput) {
    search_flags(input: $input) {
      total_size
    }
  }
`;

const SocialCounts = ({ match }) => {
  const socialFilter = {
    type: 'LIKE',
    object_id: match.params.feedId,
  };
  return (
    <Query
      query={searchSocial}
      variables={{ input: { filter: { social_filter: socialFilter } } }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return (
            <Column.Group vcentered multiline>
              <Column size={3}>
                <Input label="Like Count" disabled />
              </Column>
              <Column size={3}>
                <Input label="Share count" disabled />
              </Column>
              <Column size={3}>
                <Input label="Flag count" disabled />
              </Column>
            </Column.Group>
          );
        }
        if (error) {
          return (
            <Column.Group vcentered multiline>
              <Column size={3}>
                <Input label="Like Count" disabled />
              </Column>
              <Column size={3}>
                <Input label="Share count" disabled />
              </Column>
              <Column size={3}>
                <Input label="Flag count" disabled />
              </Column>
            </Column.Group>
          );
        }

        const { search_social } = data;
        if (isUndefined(search_social)) {
          return (
            <Column.Group vcentered multiline>
              <Column size={3}>
                <Input label="Like Count" placeholder="0" disabled />
              </Column>
              <Column size={3}>
                <Input label="Share count" placeholder="0" disabled />
              </Column>
              <Column size={3}>
                <Input label="Flag count" placeholder="0" disabled />
              </Column>
            </Column.Group>
          );
        }
        const { social_aggregation } = search_social;

        const likeCountArray =
          social_aggregation.total_social_count &&
          social_aggregation.total_social_count.length !== 0
            ? filter(
                social_aggregation.total_social_count,
                item => item.type === 'LIKE',
              )
            : [];

        const likeCount =
          likeCountArray.length !== 0 ? first(likeCountArray).count : 0;

        const flagFilter = {
          object_id: match.params.feedId,
        };

        return (
          <Query
            client={client.clientPrivate}
            query={searchFlags}
            variables={{
              input: { filter: { flag_filter: flagFilter } },
            }}
            fetchPolicy="network-only"
          >
            {({ data: flag, error: flagError }) => {
              if (flagError) {
                return (
                  <Column.Group vcentered multiline>
                    <Column size={3}>
                      <Input label="Like Count" placeholder="0" disabled />
                    </Column>
                    <Column size={3}>
                      <Input label="Share count" placeholder="0" disabled />
                    </Column>
                    <Column size={3}>
                      <Input label="Flag count" placeholder="0" disabled />
                    </Column>
                  </Column.Group>
                );
              }
              const { search_flags } = flag;
              return (
                <Column.Group vcentered multiline>
                  <Column size={3}>
                    <Input label="Like Count" value={likeCount} disabled />
                  </Column>
                  <Column size={3}>
                    <Input label="Share Count" placeholder="0" disabled />
                  </Column>
                  {isUndefined(search_flags) ? (
                    <Column size={3}>
                      <Input label="Flag count" placeholder="0" disabled />
                    </Column>
                  ) : (
                    <Column size={3}>
                      <Input
                        label="Flag count"
                        value={search_flags.total_size}
                        disabled
                      />
                    </Column>
                  )}
                </Column.Group>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default withRouter(SocialCounts);
