import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';

import * as yup from 'yup';
import { Column, Title, Level, Button } from 'rbx';
import { times } from 'lodash';
// import uuidv4 from 'uuid/v4';
// import moment from 'moment';

import { Box } from '../../../components/elements';
import AttendeeInputs from './AttendeeInputs';

const Container = styled.div`
  padding: 1rem;
`;
const ContainerHeader = styled.div`
  border-bottom: 2px solid #e0e0e0;
  padding: 0.5rem 1rem;
`;

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;
const Form = props => {
  const {
    values,
    totalQuantity,
    orderTotal,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    loading,
    back,
  } = props;

  return (
    <React.Fragment>
      <ContainerHeader>
        <Column.Group>
          <Column>
            <h1 className="is-size-1 has-text-weight-bold">
              Attendee Information
            </h1>
          </Column>
          <Column>
            <h1 className="is-size-1 has-text-weight-bold">
              Total Quantity: {totalQuantity}
            </h1>
          </Column>
          <Column>
            <h1 className="is-size-1 has-text-weight-bold">
              Order Total :${parseFloat(orderTotal).toFixed(2)}
            </h1>
          </Column>
        </Column.Group>
      </ContainerHeader>
      <Box>
        {values.ticketSelections.map((item, index) => (
          <React.Fragment>
            {item.attendees.map((item2, ind2) => (
              <React.Fragment>
                {item2.attributes.length !== 0 && (
                  <React.Fragment>
                    <Container>
                      <Title size={2}>Ticket: {item2.name}</Title>
                      <AttendeeInputs
                        ticketName={item2.name}
                        attributes={item2.attributes}
                        ticketIndex={index}
                        attendeeIndex={ind2}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </Container>
                    <hr />
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </Box>
      <Wrapper>
        <Level>
          <Level.Item align="left">
            <Level.Item>
              <Button className="button is-light" onClick={back}>
                <span>Back</span>
              </Button>
            </Level.Item>
          </Level.Item>
          <Level.Item align="right">
            <Level.Item>
              <button
                className={`button is-primary ${loading && 'is-loading'}`}
                onClick={handleSubmit}
              >
                <span>Checkout</span>
              </button>
            </Level.Item>
          </Level.Item>
        </Level>
      </Wrapper>
    </React.Fragment>
  );
};

const AttendeeForm = withFormik({
  mapPropsToValues: ({ ticketSelections }) => {
    const ticketAttendeeInputs = ticketSelections.map(item => ({
      name: item.name,
      ticket_id: item.id,
      quantity: item.quantity,
      attendees: times(item.quantity).map(quantity => ({
        name: `${item.name} - Attendee ${quantity + 1}`,
        attributes: item.attributes,
      })),
    }));

    return {
      ticketSelections: ticketAttendeeInputs,
    };
  },

  validationSchema: yup.object().shape({
    ticketSelections: yup.array().of(
      yup.object().shape({
        attendees: yup.array().of(
          yup.object().shape({
            attributes: yup.array().of(
              yup.object().shape(
                {
                  required: yup.bool(),
                  name: yup.string(),
                  values: yup
                    .string()
                    .when(['required'], {
                      is: required => required === true,
                      then: yup.string().required('Required'),
                    })
                    .when(['name'], {
                      is: name => name === 'email',
                      then: yup.string().email('Please enter valid email'),
                    }),
                },
                ['required'],
              ),
            ),
          }),
        ),
      }),
    ),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'AttendeeForm',
})(Form);

export default AttendeeForm;
