import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Container = styled.div`
  padding: 1rem 0.5rem;
`;

const Header = ({ history }) => (
  <Container>
    <Level>
      <Level.Item align="left">
        <Title>Item Dictionary</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>

        {/* <Level.Item>
          <button
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            <span>Submit</span>
          </button>
        </Level.Item> */}
      </Level.Item>
    </Level>
  </Container>
);

export default withRouter(Header);
