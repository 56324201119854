import React from 'react';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import OrderSettingCheckbox from './OrderSettingCheckbox';

const Container = styled.div`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 10px;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const SecondaryHeader = styled.div`
  background: white;
`;

const onChangeHandler = (
  deliveryIntegration,
  property,
  setFieldValue,
) => value => {
  setFieldValue('random_id', uuidv4());
  if (value) {
    const temp = deliveryIntegration.order_settings;
    temp[property] = true;
    setFieldValue('delivery_integration', {
      ...deliveryIntegration,
      order_settings: temp,
    });
  } else {
    const temp = deliveryIntegration.order_settings;
    temp[property] = false;
    setFieldValue('delivery_integration', {
      ...deliveryIntegration,
      order_settings: temp,
    });
  }
};

const OrderSettingsModal = ({
  isActive,
  onClose,
  deliveryIntegration,
  setFieldValue,
}) => (
  <Container className={`modal ${isActive && 'is-active'}`}>
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Order Setting</p>
        <button
          type="button"
          className="delete"
          aria-label="close"
          onClick={onClose}
        />
      </header>
      <SecondaryHeader />

      <section className="modal-card-body">
        <p>Select the options to integration to Shipday Delivery</p>
        <div style={{ width: '60%' }}>
          <OrderSettingCheckbox orderSetting="Order Number" disableAndChecked />
          <OrderSettingCheckbox
            orderSetting="Delivery Details"
            disableAndChecked
          />
          <OrderSettingCheckbox
            orderSetting="Pickup Details"
            checked={deliveryIntegration.order_settings.pickup_details}
            disableAndChecked
          />
          <OrderSettingCheckbox
            orderSetting="Customer Email"
            checked={deliveryIntegration.order_settings.customer_email}
            onChange={onChangeHandler(
              deliveryIntegration,
              'customer_email',
              setFieldValue,
            )}
          />
          <OrderSettingCheckbox
            orderSetting="Order Items"
            checked={deliveryIntegration.order_settings.order_items}
            onChange={onChangeHandler(
              deliveryIntegration,
              'order_items',
              setFieldValue,
            )}
          />
          <OrderSettingCheckbox
            orderSetting="Delivery Fees"
            checked={deliveryIntegration.order_settings.delivery_fees}
            onChange={onChangeHandler(
              deliveryIntegration,
              'delivery_fees',
              setFieldValue,
            )}
          />
          <OrderSettingCheckbox
            orderSetting="Total"
            checked={deliveryIntegration.order_settings.total}
            onChange={onChangeHandler(
              deliveryIntegration,
              'total',
              setFieldValue,
            )}
          />
          <OrderSettingCheckbox
            orderSetting="Payment Details"
            checked={deliveryIntegration.order_settings.payment_details}
            onChange={onChangeHandler(
              deliveryIntegration,
              'payment_details',
              setFieldValue,
            )}
          />
        </div>
      </section>

      <footer className="modal-card-foot">
        <button
          type="button"
          className="button is-grey-light"
          aria-label="close"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button is-primary"
          aria-label="close"
          onClick={onClose}
          // disabled={selectedItems.length === 0}
        >
          <p className="has-text-weight-semibold">Add</p>
        </button>
      </footer>
    </div>
  </Container>
);

export default OrderSettingsModal;
