import React from 'react';

import {
  Input,
  TextArea,
  Select,
  CheckBox,
  Dropzone,
} from '../../../components/elements';

const options = [
  { value: '1', label: 'United States' },
  { value: '2', label: 'Australia' },
  { value: '3', label: 'Canada' },
];

const stateOptions = [
  { value: '1', label: 'State 1' },
  { value: '2', label: 'State 2' },
  { value: '3', label: 'State 3' },
];

const vendorStatusOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

const BusinessDetail = ({
  values,
  // touched,
  // errors,
  handleChange,
  handleBlur,
  setFieldValue,
  next,
}) => (
  <div>
    <div className="columns">
      <div className="column is-one-quarter">
        <Input
          label="Vendor Name"
          name="vendorName"
          value={values.vendorName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Vendor Email"
          name="vendorEmail"
          value={values.vendorEmail}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Vendor Phone"
          name="vendorPhone"
          value={values.vendorPhone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Vendor Mobile"
          name="vendorMobile"
          value={values.vendorMobile}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>

    <div className="columns">
      <div className="column is-one-quarter">
        <Input
          label="Place Name"
          name="bizName"
          value={values.bizName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Business Email"
          name="bizEmail"
          value={values.bizEmail}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-half">
        <Input
          label="Business Phone"
          name="bizPhone"
          value={values.bizPhone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>

    <div className="columns">
      <div className="column is-one-third">
        <TextArea
          label="Business Description"
          name="bizDescription"
          value={values.bizDescription}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-third">
        <TextArea
          label="Business Tag Line"
          name="bizTagLine"
          value={values.bizTagLine}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-third">
        <TextArea
          label="Business Address Line1"
          name="bizAddLine1"
          value={values.bizAddLine1}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>

    <div className="columns">
      <div className="column is-one-quarter">
        <Select
          label="Business Country *"
          options={options}
          value={values.bizCountry}
          onChange={value =>
            setFieldValue('bizCountry', parseInt(value.value, 10))
          }
        />
      </div>
      <div className="column is-one-quarter">
        <Select
          label="Business State *"
          options={stateOptions}
          value={values.bizState}
          onChange={value =>
            setFieldValue('bizState', parseInt(value.value, 10))
          }
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Minimum Delivery Time (in min)"
          name="minDeliveryTime"
          value={values.minDeliveryTime}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Maximum Delivery Time (in min)"
          name="maxDeliveryTime"
          value={values.maxDeliveryTime}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>

    <div className="columns">
      <div className="column is-one-quarter">
        <Input
          label="Minimum Delivery Fees"
          name="minDeliveryFee"
          value={values.minDeliveryFee}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Minimum Purchase Amount"
          name="maxPurchaseAmount"
          value={values.maxPurchaseAmount}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Facebook Handle"
          name="facebookHandle"
          value={values.facebookHandle}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-one-quarter">
        <Input
          label="Twitter Handle"
          name="twitterHandle"
          value={values.twitterHandle}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>

    <div className="columns">
      <div className="column is-half">
        <Input
          label="Website"
          name="website"
          value={values.website}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="column is-half">
        <Select
          label="Vendor Status"
          options={vendorStatusOptions}
          value={values.vendorStatus}
          onChange={value =>
            setFieldValue('vendorStatus', parseInt(value.value, 10))
          }
        />
      </div>
    </div>

    <div className="columns">
      <div className="column is-half">
        <CheckBox
          label="Featured"
          name="featured"
          value={values.featured}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>

    <div>
      <h1>Business Images </h1>
      <p>
        Hint: Formats accepted -.png, .jpg, .jpeg, Maximum Size accepted - 10
        MB, Recommended Image resolution X
      </p>
    </div>

    <div className="columns">
      <div className="column is-half">
        <Dropzone
          onChange={image => {
            setFieldValue('image', {
              url: image,
              tooltip: '',
              link: '',
              display_order: '',
            });
          }}
        />
      </div>
    </div>

    <nav className="level">
      <div className="level-left">
        <div className="level-item">
          <button className="button is-primary" onClick={next}>
            Next
          </button>
        </div>
      </div>
    </nav>
  </div>
);

export default BusinessDetail;
