import React from 'react';
import styled from 'styled-components';

import { Field, Label, Control, Input as RbxInput, Button } from 'rbx';
import InputErrorMessage from './InputErrorMessage';
import { ACLSConsumer } from '../../utils/aclsContext';
import Can from '../../utils/Can';

const Span = styled.span`
  padding-left: 2px;
`;
const OptionalText = styled.p`
  &&& {
    font-size: 13px;
    font-weight: 600;
    color: #737373;
    padding-left: 2px;
  }
`;
const Input = ({
  label,
  value,
  placeholder,
  optionalText,
  optionalText1,
  required,
  children,
  errors,
  touched,
  disableToastMessage,
  action,
  addonsText,
  isLeft,
  isRight,
  ...props
}) => (
  <Field>
    {label && (
      <Label className="is-flex">
        {label}
        {required && <span className="has-text-danger">*</span>}
        {optionalText && <OptionalText>{optionalText}</OptionalText>}
        <Span>{children}</Span>
      </Label>
    )}

    <Field kind="addons">
      {isLeft && (
        <Control>
          <Button static>{addonsText}</Button>
        </Control>
      )}
      <Control>
        {action ? (
          <ACLSConsumer>
            {({ apiActions, pageActions }) => (
              <Can
                action={action}
                apiActions={apiActions}
                pageActions={pageActions}
                yes={() => (
                  <RbxInput
                    value={value === null ? value : value}
                    placeholder={placeholder || null}
                    {...props}
                  />
                )}
                no={() => (
                  <RbxInput
                    value={value === null ? value : value}
                    placeholder={placeholder || null}
                    disabled
                    {...props}
                  />
                )}
              />
            )}
          </ACLSConsumer>
        ) : (
          <RbxInput
            value={value === null ? value : value}
            placeholder={placeholder || null}
            {...props}
          />
        )}
      </Control>
      {isRight && (
        <Control>
          <Button static>{addonsText}</Button>
        </Control>
      )}
    </Field>
    {optionalText1 && <OptionalText>{optionalText1}</OptionalText>}
    <InputErrorMessage
      errors={errors}
      touched={touched}
      disableToastMessage={disableToastMessage}
    />
  </Field>
);

export default Input;
