import React from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import qs from 'querystringify';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import { Box, Tabs, Loading, Message } from '../../../components/elements';
import client from '../../../utils/apolloClient';
import Header from './Header';
import Layout from '../../../components/global/Layout';
import Form from './Form';
import LoginTable from './LoginTable';

const Container = styled.div`
  padding: 1rem;
  padding-bottom: 10rem;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  top: 3.2rem;
  background-color: #fff;
  z-index: 10;
`;

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      date_of_birth
      gender
      description
      status
      user_handle
      external_payment_id
      visibility
      rating
      image_url
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      access {
        place_id
      }
      address {
        address_id
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const updateMutation = gql`
  mutation updateUser($input: UserInput) {
    update_user(input: $input) {
      user_id
      error {
        description
      }
    }
  }
`;

const tabs = [
  {
    key: 'details',
    name: 'User Details',
  },
  {
    key: 'address',
    name: 'User Address',
  },
  {
    key: 'following',
    name: 'Following',
  },
  {
    key: 'followers',
    name: 'Followers',
  },
  {
    key: 'place-followed',
    name: 'Place Followed',
  },
  {
    key: 'images',
    name: 'User Image',
  },
  {
    key: 'login',
    name: 'Login History',
  },
];

const View = ({ history, match, location }) => {
  const routeState = qs.parse(location.search);
  const { tab } = routeState;

  const activeTab = tab || 'details';

  const handlePush = key => {
    history.push(`/user/${match.params.userId}?tab=${key}`);
  };

  const [
    updateUser,
    { loading: updateLoading, error: updateError },
  ] = useMutation(updateMutation, {
    client: client.clientPrivate,
    onCompleted: ({ update_user }) => {
      if (!isNull(update_user.error)) {
        update_user.error.map(item => toast.error(item.description));
      } else {
        swal('Great!', 'User has been updated successfully!', 'success').then(
          () => {
            history.push('/users');
          },
        );
      }
    },
  });

  const { loading, error, data } = useQuery(userQuery, {
    variables: { userId: match.params.userId },
    client: client.clientPrivate,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Message>{error.message}</Message>;
  }

  const user = data.fetch_user;

  return (
    <React.Fragment>
      <Header user={user} />
      <HeaderWrapper>
        {updateError && <Message>{updateError.message}</Message>}
        <Box>
          <Tabs className="tabs is-large">
            {tabs.map(item => (
              <Tab
                active={activeTab === item.key}
                key={item.key}
                onClick={() => handlePush(item.key)}
              >
                {item.name}
              </Tab>
            ))}
          </Tabs>
        </Box>
      </HeaderWrapper>
      <Box>
        {activeTab === 'details' && (
          <Container>
            <Form
              loading={updateLoading}
              user={user}
              onSubmit={inputs => {
                updateUser({
                  variables: {
                    input: { ...inputs },
                  },
                });
              }}
            />
          </Container>
        )}
        {activeTab === 'login' && (
          <LoginTable
            userId={user.user_id}
            routeState={routeState}
            history={history}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

const UpdateUser = props => (
  <Layout>
    <View {...props} />
  </Layout>
);

export default UpdateUser;
