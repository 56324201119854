import React from 'react';
import { Field, Control, Input, Icon } from 'rbx';
import { ceil } from 'lodash';

const DiscountValueInput = ({
  discountType,
  price,
  value,
  onChange,
  ...props
}) => (
  <Field>
    <Control iconLeft>
      <Input
        type="number"
        value={parseFloat(value || 0).toFixed(2)}
        min={0}
        max={discountType === 'PRICE' ? ceil(price * (70 / 100)) : 70}
        onChange={onChange}
        {...props}
        required
        step={0.01}
      />
      <Icon align="left">{discountType === 'PRICE' ? '$' : '%'}</Icon>
    </Control>
  </Field>
);

export default DiscountValueInput;
