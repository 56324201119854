import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import fetch from 'node-fetch';
import { checkTokenExpiration } from './auth';

import config from './config';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const clientPublic = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    createHttpLink({
      uri: config.public.aws_appsync_graphqlEndpoint,
      fetch,
      headers: {
        'x-api-key': config.public.aws_appsync_apiKey,
      },
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions,
});

const httpAppSync = createHttpLink({
  uri: config.secured.aws_appsync_graphqlEndpoint,
  fetch,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  await checkTokenExpiration();
  const token = await window.localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  };
});

const clientPrivate = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.log('graphQLErrors', graphQLErrors);
      }
      if (networkError) console.log('networkError', networkError);
    }),
    authLink.concat(httpAppSync),
  ]),
  cache: new InMemoryCache(),
  defaultOptions,
});

export default { clientPublic, clientPrivate };
