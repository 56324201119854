import React from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';

import { Column } from 'rbx';
import uuid from 'uuid';
import {
  Radio,
  SelectTime,
  InputErrorMessage,
} from '../../../components/elements';

const HoursContainer = styled.div`
  padding: 1rem 0rem;
`;

const PlusOneDay = styled.div`
  position: absolute;
  font-weight: 600;
  color: green;
  text-align: center;
  padding-left: 9px;
`;

const AddButton = styled.a`
  color: ${({ theme }) => theme.primaryColor};
  position: relative;
  :hover {
    color: ${({ theme }) => theme.primaryColor};
  }
  bottom: 10px;
`;

const Hours = ({ values, setFieldValue, errors, touched, size = 2 }) => (
  <div>
    <div className="is-flex">
      <Radio
        label="Closed all day"
        value={values.hoursType === 'CLOSED_ALL'}
        onChange={value => {
          setFieldValue('closedAllDay', value);
          setFieldValue('hoursType', 'CLOSED_ALL');
          if (value) {
            setFieldValue('option', []);
          } else {
            setFieldValue('option', []);
          }
        }}
      />
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      <Radio
        label="Open 24 Hour"
        value={values.hoursType === 'OPEN_24_HOUR'}
        onChange={value => {
          if (value) {
            setFieldValue('closedAllDay', false);
            setFieldValue('hoursType', 'OPEN_24_HOUR');
            setFieldValue('option', [
              {
                id: uuid(),
                start: '12:00 AM',
                end: '11:59 PM',
                start_in_sec: 0,
                end_in_sec: 86340,
              },
            ]);
          } else {
            setFieldValue('option', []);
          }
        }}
      />
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      <Radio
        label="Enter Hours"
        value={values.hoursType === 'CUSTOM'}
        onChange={value => {
          if (value) {
            setFieldValue('closedAllDay', false);
            setFieldValue('hoursType', 'CUSTOM');
            setFieldValue('option', []);
          } else {
            setFieldValue('option', []);
          }
        }}
      />
    </div>

    {values.hoursType === 'OPEN_24_HOUR' && (
      <HoursContainer>
        <div>
          {values.option.length !== 0 && (
            <Column.Group vcentered>
              <Column size={size}>
                <p className="is-size-3 has-text-weight-semibold">Open</p>
              </Column>
              <Column size={size}>
                <p className="is-size-3 has-text-weight-semibold">Closed</p>
              </Column>
            </Column.Group>
          )}
          {values.option.map(item => (
            <Column.Group vcentered>
              <Column size={size}>
                <SelectTime value={item.start} onChange={() => {}} disabled />
              </Column>
              <Column size={size}>
                <SelectTime value={item.end} onChange={() => {}} disabled />
              </Column>
            </Column.Group>
          ))}
        </div>
      </HoursContainer>
    )}

    {values.hoursType === 'CUSTOM' && (
      <FieldArray
        name="option"
        render={helpers => (
          <HoursContainer>
            {values.option.length === 0 && (
              <button
                type="button"
                className="button is-primary"
                onClick={() => {
                  helpers.push({
                    id: uuid(),
                    start: '',
                    end: '',
                  });
                }}
              >
                Add hours
              </button>
            )}
            <div>
              {values.option.length !== 0 && (
                <Column.Group vcentered>
                  <Column size={size}>
                    <p className="is-size-3 has-text-weight-semibold">Open</p>
                  </Column>
                  <Column size={size}>
                    <p className="is-size-3 has-text-weight-semibold">Closed</p>
                  </Column>
                </Column.Group>
              )}
              {values.option.map((item, index) => (
                <Column.Group vcentered>
                  <Column size={size}>
                    <SelectTime
                      value={item.start}
                      onChange={value => {
                        setFieldValue(`option.${index}.start`, value.time);
                        setFieldValue(`option.${index}.end`, '');
                        setFieldValue(`option.${index}.end_in_sec`, null);
                        const start_in_sec =
                          value.hours * 3600 + value.minutes * 60;
                        setFieldValue(
                          `option.${index}.start_in_sec`,
                          start_in_sec,
                        );
                      }}
                    />
                  </Column>
                  <Column size={size}>
                    <SelectTime
                      value={item.end}
                      disabled={item.start === ''}
                      onChange={value => {
                        setFieldValue(`option.${index}.end`, value.time);
                        const end_in_sec =
                          value.hours * 3600 + value.minutes * 60;
                        if (end_in_sec <= item.start_in_sec) {
                          setFieldValue(
                            `option.${index}.end_in_sec`,
                            end_in_sec + 86340,
                          );
                        } else {
                          setFieldValue(
                            `option.${index}.end_in_sec`,
                            end_in_sec,
                          );
                        }
                      }}
                    />
                    {item.end_in_sec && item.end_in_sec > 86340 && (
                      <PlusOneDay>
                        <p>+1 day</p>
                      </PlusOneDay>
                    )}
                  </Column>

                  <Column size={size}>
                    <a
                      onClick={() => {
                        setFieldValue(
                          'option',
                          values.option.filter(item2 => item2.id !== item.id),
                        );
                      }}
                    >
                      <p className="is-size-3 has-text-weight-semibold has-text-danger">
                        Remove hours
                      </p>
                    </a>
                  </Column>
                </Column.Group>
              ))}
              {values.option.length !== 0 && (
                <AddButton
                  onClick={() => {
                    helpers.push({
                      id: uuid(),
                      start: '',
                      end: '',
                    });
                  }}
                >
                  Add more hours
                </AddButton>
              )}
            </div>
          </HoursContainer>
        )}
      />
    )}
    <InputErrorMessage
      errors={errors.option}
      touched={touched.option}
      disableToastMessage
    />
  </div>
);

export default Hours;
