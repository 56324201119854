import { AWSAppSyncClient } from 'aws-appsync';
// eslint-disable-next-line import/no-named-as-default
import config from './config';

const client = new AWSAppSyncClient({
  url: config.public.aws_appsync_graphqlEndpoint,
  region: config.public.aws_appsync_region,
  auth: {
    type: 'API_KEY',
    apiKey: config.public.aws_appsync_apiKey,
  },
});

export default client;
