import React, { useState, useEffect } from 'react';
import { Column } from 'rbx';
import gql from 'graphql-tag';
import moment from 'moment';
// import RejectedByUserDetails from './RejectedByUserDetails';
import { Input } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      rating
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const ApprovedUserDetails = ({ createdBy, createdTime }) => {
  const [user, setUser] = useState();
  useEffect(() => {
    client.clientPrivate
      .query({
        query: userQuery,
        variables: { userId: createdBy },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setUser(data.fetch_user);
        }
      });
  }, []);

  return (
    <fieldset className="box">
      <legend className="label">Approval Details</legend>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input
            label="Approved by User Log-in (Email)"
            value={user && user.email}
            disabled
          />
        </Column>
        <Column size="half">
          <Input label="User Name" value={user && user.first_name} disabled />
        </Column>
        <Column size="half">
          <Input
            label="Review Date and Time"
            value={
              createdTime === null ? '' : moment(createdTime).format('lll')
            }
            disabled
          />
        </Column>
        {/* <Column size="half">
          <Input
            label="User Display Name"
            value={user && user.display_name}
            disabled
          />
        </Column> */}
        {/* <RejectedByUserDetails
          rejectedBy={rejectedBy}
          rejectedAt={rejectedAt}
        /> */}
      </Column.Group>
    </fieldset>
  );
};

export default ApprovedUserDetails;
