import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Column } from 'rbx';
import { trim, first } from 'lodash';

import { Input, CheckBox } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/GoogleSuggest';

const CheckBoxWrapper = styled.div`
  padding-top: 2rem;
`;

const TIME_ZONE_OPTIONS = [
  { value: '+10', label: 'AEST' },
  { value: '+8', label: 'AWST' },
  { value: '+9.5', label: 'ACST' },
  { value: '+5.5', label: 'IST' },
];

const LocationForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  isEventComponent,
}) => {
  const [isManual, setManual] = useState(false);
  const timeZoneLabel = first(
    TIME_ZONE_OPTIONS.filter(item => item.value === values.timezone),
  );

  return (
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={6}>
        <LocationSuggestion
          label="Search Address"
          id="FinancialManagement_PlaceCompany_SearchAddress"
          initialValue={values.city}
          onSelect={place => {
            setFieldValue('address_line_1', trim(place.addressLine1));
            setFieldValue('city', place.city);
            setFieldValue('post_code', place.postcode);
            setFieldValue('state', place.state);
            setFieldValue('country', place.country || 'AUSTRALIA');
            setFieldValue('timezone', place.timezone || '+10');
            setFieldValue('latitude', place.latitude);
            setFieldValue('longitude', place.longitude);
          }}
          disabled={isManual ? !isManual : isEventComponent}
        />
      </Column>
      <Column size={6}>
        <CheckBoxWrapper>
          <CheckBox
            label="Override Location"
            value={isManual}
            onChange={() => {
              setManual(!isManual);
            }}
          />
        </CheckBoxWrapper>
      </Column>
      <Column size={6}>
        <Input
          label="Street Address"
          name="address_line_1"
          value={values.address_line_1}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!isManual}
          className={
            errors.address_line_1 && touched.address_line_1 && 'is-danger'
          }
          required
          errors={errors.address_line_1}
          touched={touched.address_line_1}
        />
      </Column>
      <Column size={5}>
        <Input
          label="Additional Address Information "
          name="address_line_2"
          id="FinancialManagement_PlaceCompany_AdditionalAddressInformation"
          value={values.address_line_2}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Landmark, Shopping Center, etc"
        />
      </Column>
      <Column size={4}>
        <Input
          label="Suburb / City"
          name="city"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!isManual}
          className={errors.city && touched.city && 'is-danger'}
          required
          errors={errors.city}
          touched={touched.city}
        />
      </Column>
      <Column size={2}>
        <Input
          label="Pin / Postcode"
          disabled={!isManual}
          name="post_code"
          value={values.post_code}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.post_code && touched.post_code && 'is-danger'}
          required
          errors={errors.post_code}
          touched={touched.post_code}
        />
      </Column>
      <Column size={5}>
        <Input
          label="State"
          disabled={!isManual}
          name="state"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.state && touched.state && 'is-danger'}
          required
          errors={errors.state}
          touched={touched.state}
        />
      </Column>
      <Column size={4}>
        <Input
          label="Country"
          disabled={!isManual}
          name="country"
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.country && touched.country && 'is-danger'}
          required
          errors={errors.country}
          touched={touched.country}
        />
      </Column>
    </Column.Group>
  );
};

LocationForm.defaultProps = {
  isEventComponent: false,
};

LocationForm.propTypes = {
  isEventComponent: PropTypes.bool,
};

export default LocationForm;
