import React from 'react';

const VendorList = ({ Vendors }) => (
  <div className="table-container">
    <table className="table table is-bordered  is-striped is-fullwidth">
      <thead>
        <tr>
          <th>name</th>
          <th>city</th>
          <th>state</th>
          <th>post_code</th>
          <th>status</th>
        </tr>
      </thead>
      <tbody>
        {Vendors &&
          Vendors.map(Vendor => (
            <tr>
              <td>{Vendor.name}</td>
              <td>{Vendor.city}</td>
              <td>{Vendor.state}</td>
              <td>{Vendor.post_code}</td>
              <td>{`${Vendor.status}`}</td>
              <td>
                <a>Edit</a>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

export default VendorList;
