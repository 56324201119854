import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  box-sizing: border-box;
  background: #ffffff;
`;

const Box = ({ children }) => <Wrapper>{children}</Wrapper>;

export default Box;
