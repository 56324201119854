import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
  background: #ffff;
`;

const Footer = ({
  values,
  loading,
  dirty,
  history,
  isAdd,
  saveAndAddMore,
  setSaveAndAddMore,
  handleSubmit,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        {isAdd && (
          <ACLSConsumer>
            {({ apiActions, pageActions }) => (
              <Can
                action={[
                  'create_menu_modifier_group',
                  'update_menu_modifier_group',
                ]}
                apiActions={apiActions}
                pageActions={pageActions}
                yes={() => (
                  <Level.Item>
                    <button
                      type="button"
                      className={`button is-primary ${saveAndAddMore &&
                        loading &&
                        'is-loading'}`}
                      disabled={values.modifier.length === 0 || !dirty}
                      onClick={() => {
                        if (!saveAndAddMore && loading) {
                          return;
                        }
                        setSaveAndAddMore(true);
                        handleSubmit();
                      }}
                    >
                      <span>Submit And Add More</span>
                    </button>
                  </Level.Item>
                )}
              />
            )}
          </ACLSConsumer>
        )}
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={[
                'create_menu_modifier_group',
                'update_menu_modifier_group',
              ]}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item>
                  <button
                    type="button"
                    className={`button is-primary ${!saveAndAddMore &&
                      loading &&
                      'is-loading'}`}
                    onClick={
                      !isAdd
                        ? handleSubmit
                        : () => {
                            if (saveAndAddMore && loading) {
                              return;
                            }
                            setSaveAndAddMore(false);
                            handleSubmit();
                          }
                    }
                    disabled={values.modifier.length === 0 || !dirty}
                  >
                    <span>Submit</span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
