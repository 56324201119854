import { createStore } from 'easy-peasy';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import global from './global';

import auth from './auth';

const model = {
  ...global,
  ...auth,
};
const store = createStore(model, {
  reducerEnhancer: reducer =>
    persistReducer(
      {
        key: 'easypeasystate',
        storage,
      },
      reducer,
    ),
});

export default store;
