import React from 'react';
import { Label } from 'rbx';
import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import InputErrorMessage from './InputErrorMessage';
import { ACLSConsumer } from '../../utils/aclsContext';
import Can from '../../utils/Can';

const Container = styled.div`
  input {
    border: 0;
    outline: none;
  }
`;
const Span = styled.span`
  padding-left: 1rem;
`;

const MobileInput = ({
  label,
  required,
  children,
  errors,
  touched,
  action,
  value,
  onChange = () => {},
  onBlur = () => {},
  disabled,
}) => (
  <Container className="field">
    {label && (
      <Label className="is-flex">
        {label}
        {required && <span className="has-text-danger">*</span>}
        <Span>{children}</Span>
      </Label>
    )}
    <div className="control">
      {action ? (
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={action}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <PhoneInput
                  value={value}
                  countryCallingCodeEditable={false}
                  defaultCountry="AU"
                  placeholder="Enter mobile number"
                  international
                  className="input"
                  countrySelectProps={{ unicodeFlags: true }}
                  onChange={text => {
                    onChange(text || '');
                  }}
                  onBlur={onBlur}
                  disabled={disabled}
                />
              )}
              no={() => (
                <PhoneInput
                  value={value}
                  countryCallingCodeEditable={false}
                  defaultCountry="AU"
                  placeholder="Enter mobile number"
                  international
                  className="input"
                  countrySelectProps={{ unicodeFlags: true }}
                  onChange={text => {
                    onChange(text || '');
                  }}
                  onBlur={onBlur}
                  disabled
                />
              )}
            />
          )}
        </ACLSConsumer>
      ) : (
        <PhoneInput
          value={value}
          defaultCountry="AU"
          countryCallingCodeEditable={false}
          placeholder="Enter mobile number"
          international
          className="input"
          countrySelectProps={{ unicodeFlags: true }}
          onChange={text => {
            onChange(text || '');
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
      )}
    </div>
    <InputErrorMessage errors={errors} touched={touched} />
  </Container>
);

export default MobileInput;
