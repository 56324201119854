import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  padding: 0rem 15%;
`;

const Blank = ({ message }) => (
  <Container className="hero">
    <div className="hero-body">
      <div className="container has-text-centered">
        <p className="title heading is-size-1">{message}</p>
        <br />
        <figure className="image is-16by">
          <img src="/images/not-found.svg" alt="not-found" />
        </figure>
      </div>
    </div>
  </Container>
);

export default Blank;
