import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Input, Select } from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.div`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = () => (
  <Wrapper>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-one-quarter">
          <Input
            label="Attendee Name"
            name="searchQuery"
            placeholder="Start entering attendee name..."
          />
        </div>
        <div className="column is-one-quarter">
          <Input
            label="Email"
            type="text"
            placeholder="Start entering email id..."
          />
        </div>
        <div className="column is-one-quarter">
          <Select label="Ticket Category" />
        </div>
        <div className="column is-one-quarter">
          <Select label="Status" />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default withRouter(Search);
