import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';

const Container = styled.div``;

const SwitchButton = ({ value, onChange, ...props }) => {
  const [checked, setChecked] = useState(value);

  const handleChange = () => {
    onChange(!checked);
    setChecked(!checked);
  };

  return (
    <Container>
      <Switch onChange={handleChange} checked={checked} {...props} />
    </Container>
  );
};

export default SwitchButton;
