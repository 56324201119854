import React from 'react';
import styled from 'styled-components';
import { Label } from 'rbx';

import {
  // CheckBox,
  // Radio,
  Input,
} from '../../../components/elements';

const Container = styled.div`
  padding: 1rem;
  width: 40%;
`;

const OtherSettings = ({ values }) => (
  <Container>
    <div>
      <Label>Deliverect Settings</Label>
      <div>
        <Input
          label="Platform"
          value={values.platform}
          // onChange={e => {
          //   setFieldValue('delivery_integration', {
          //     ...values.delivery_integration,
          //     api_key: e.target.value,
          //   });
          // }}
          disabled
        />
        <Input
          label="Channel Link ID"
          value={values.channel_link_id}
          // onChange={e => {
          //   setFieldValue('delivery_integration', {
          //     ...values.delivery_integration,
          //     api_key: e.target.value,
          //   });
          // }}
          disabled
        />
        <Input
          label="Deliverect Menu ID"
          value={values.deliverect_menu_item_id}
          // onChange={e => {
          //   setFieldValue('delivery_integration', {
          //     ...values.delivery_integration,
          //     api_key: e.target.value,
          //   });
          // }}
          disabled
        />
        <Input
          label="Deliverect PLU ID"
          value={values.deliverect_plu_id}
          // onChange={e => {
          //   setFieldValue('delivery_integration', {
          //     ...values.delivery_integration,
          //     api_key: e.target.value,
          //   });
          // }}
          disabled
        />
      </div>
    </div>
  </Container>
);

export default OtherSettings;
