import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { omit, isNull } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import tzLookup from 'tz-lookup';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import DealForm from './DealForm';
import PlaceSelection from '../../../components/PlaceSelection';

const createDealMutation = gql`
  mutation createDeal($input: PlaceEventVoucherInput) {
    create_place_event_voucher(input: $input) {
      deal_id
      error {
        code
        description
      }
    }
  }
`;

// const createImageMutation = gql`
//   mutation createImage($input: ImageInput) {
//     create_image(input: $input) {
//       image_id
//       url
//       error {
//         description
//       }
//     }
//   }
// `;

// const parseImage = images => {
//   const imageValues = [];
//   forEach(images, item => {
//     if (item.url !== '') {
//       imageValues.push({
//         image_id: item.id,
//         url: item.url,
//         link: item.link,
//         tags: item.tags,
//         tooltip: item.tooltip,
//       });
//     }
//   });
//   return imageValues;
// };

const EventDealAdd = ({ history }) => {
  const [place, setPlace] = useState();
  const [placeSelection, setPlaceSelection] = useState(false);
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  const setTimeZone = value => {
    const lat = parseFloat(value.latitude).toFixed(5);
    const long = parseFloat(value.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    setCurrentTimeZone(timeZone);
    moment.tz.setDefault(timeZone);
  };

  return (
    <Layout>
      {placeSelection && place ? (
        <View
          place={place}
          history={history}
          currentTimeZone={currentTimeZone}
        />
      ) : (
        <PlaceSelection
          heading="Select Place to add a Deals"
          handleSelect={value => {
            setPlace(value);
            setTimeZone(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

const View = ({ place, history, currentTimeZone }) => {
  // const [images, setImages] = useState({});
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={createDealMutation}
      onCompleted={({ create_place_event_voucher }) => {
        if (create_place_event_voucher.error) {
          create_place_event_voucher.error.map(item =>
            toast.error(item.description),
          );
        } else {
          swal(
            'Great!',
            'Event Deal had been created successfully!',
            'success',
          ).then(() => {
            history.goBack();
          });
        }
      }}
    >
      {(
        create_place_event_voucher,
        { loading: createDealLoading, error: createDealError },
      ) => (
        <DealForm
          isAddDealForm
          error={createDealError}
          loading={createDealLoading}
          place={place}
          onSubmit={value => {
            // setImages(value.images);
            const input = omit(value, [
              'eventsSelection',
              'ticketsSelection',
              'applies_to_event',
              'applies_to_tickets',
              'image',
              'startDate',
              'endDate',
              'startTime',
              'endTime',
              'maximum_discount_amount',
              'minimum_purchase_amount',
              'maximum_usage',
              'maximum_use_per_user',
            ]);

            // const applies_to_input = value.applies_to_event.concat(
            //   value.applies_to_tickets,
            // );
            const applies_to_input = value.applies_to_tickets;

            const customStartDate = moment(value.startDate).format(
              'YYYY-MM-DD',
            );
            const startTimeWhenValueIsNull = moment(value.startDate).isSame()
              ? moment()
                  .add(2, 'minutes')
                  .format('HH:mm:ss')
              : '12:00:00';

            const customStartTime = !isNull(value.startTime)
              ? moment(value.startTime).format('HH:mm:ss')
              : startTimeWhenValueIsNull;

            const customEndDate = moment(value.endDate).format('YYYY-MM-DD');
            const customEndTime = !isNull(value.endTime)
              ? moment(value.endTime).format('HH:mm:ss')
              : '23:59:00';

            const startDate = moment
              .tz(`${customStartDate} ${customStartTime}`, currentTimeZone)
              .toISOString();

            const endDate = moment
              .tz(`${customEndDate} ${customEndTime}`, currentTimeZone)
              .toISOString();

            const applies_to = applies_to_input.map(item => ({
              event_id: item.event_id,
              event_occurrence_id: item.event_occurrence_id,
              ticket_id: item.ticket_id,
              ticket_price: item.ticket_price,
            }));
            const inputs = {
              user_id: userId,
              ...input,
              start: startDate,
              end: endDate,
              applies_to,
              maximum_discount_amount: parseFloat(
                value.maximum_discount_amount,
              ),
              minimum_purchase_amount: parseFloat(
                value.minimum_purchase_amount,
              ),
              maximum_usage: parseInt(value.maximum_usage, 10),
              maximum_use_per_user: parseInt(value.maximum_use_per_user, 10),
            };
            create_place_event_voucher({ variables: { input: inputs } });
          }}
        />
      )}
    </Mutation>
  );
};

export default withRouter(EventDealAdd);
