import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';
import { isNull } from 'lodash';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
} from '../../../components/elements';

const dealsQuery = gql`
  query deal($input: SearchInput) {
    search_place_event_voucher_listing(input: $input) {
      total_pages
      total_size
      place_event_voucher_listing {
        place_id
        deal_id
        place_name
        name
        voucher_code
        description
        tagline
        start
        end
        discount_type
        value
        maximum_discount_amount
        minimum_purchase_amount
        valid_for_new_user
        maximum_usage
        maximum_use_per_user
        can_be_clubbed
        status
        is_published
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const EventDeals = props => {
  const routeState = qs.parse(props.location.search);
  const { eventStatus, place_name, name, voucher_code } = routeState;
  const [status, setStatus] = useState('current');
  // const [eventStatus, setEventStatus] = useState('ACTIVE');

  const [eventData, setEventData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [voucherNameSort, setVoucherNameSort] = useState('AES');
  const [voucherCodeSort, setVoucherCodeSort] = useState('AES');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [startDateSort, setStartDateSort] = useState('AES');
  const [endDateSort, setEndDateSort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');

  // const [searchQuery, setSearchQuery] = useState('');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [startDateRangeFilter, setStartDateRangeFilter] = useState({
    end_date: moment().format('YYYY-MM-DD'),
  });
  // const [endDateRangeFilter, setEndDateRangeFilter] = useState({
  //   start_date: moment(`${moment().format('L')} 11:59 PM`).toISOString(),
  // });
  const [endDateRangeFilter, setEndDateRangeFilter] = useState({
    start_date: moment().format('YYYY-MM-DD'),
  });

  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [suburbNameSearch, setSuburbNameSearch] = useState('');
  const [voucherNameSearch, setVoucherNameSearch] = useState('');
  const [voucherCodeSearch, setVoucherCodeSearch] = useState('');
  const [eventNameSearch, setEventNameSearch] = useState('');
  const [location, setLocation] = useState('');
  const input = {};
  const place_event_voucher_filter = {};

  Object.assign(
    place_event_voucher_filter,
    { status: eventStatus },
    place_name && { place_name },
    voucher_code && { voucher_code },
    name && { name },
    suburbNameSearch !== '' && { location: suburbNameSearch },
    startDateRangeFilter && {
      start_date_range: startDateRangeFilter,
    },
    endDateRangeFilter && {
      end_date_range: endDateRangeFilter,
    },
  );

  Object.assign(
    input,
    location !== '' && { location },
    { sort },
    //  searchQuery !== '' && { search_criteria: searchQuery },
    { from: pageFrom, size: listSize },
    place_event_voucher_filter !== {} && {
      filter: {
        place_event_voucher_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          // searchQuery={searchQuery}
          eventStatus={eventStatus}
          // setEventStatus={setEventStatus}
          status={status}
          setStatus={setStatus}
          voucherNameSearch={voucherNameSearch}
          eventNameSearch={eventNameSearch}
          setEventNameSearch={setEventNameSearch}
          setVoucherNameSearch={setVoucherNameSearch}
          suburbNameSearch={suburbNameSearch}
          handleSuburbNameSearch={setSuburbNameSearch}
          location={location}
          setLocation={setLocation}
          setStartDateRangeFilter={setStartDateRangeFilter}
          setEndDateRangeFilter={setEndDateRangeFilter}
          voucherCodeSearch={voucherCodeSearch}
          setVoucherCodeSearch={setVoucherCodeSearch}
          routeState={routeState}
          place_name={place_name}
          voucher_code={voucher_code}
          name={name}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            currentPage={currentPage}
            totalElements={totalElements}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setVoucherNameSort(
                          voucherNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          voucherNameSort === 'AES' ? 'NAME_DESC' : 'NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          voucherNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Voucher Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setVoucherCodeSort(
                          voucherCodeSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          voucherCodeSort === 'AES'
                            ? 'VOUCHER_CODE_DESC'
                            : 'VOUCHER_CODE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          voucherCodeSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Voucher Code
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStartDateSort(
                          startDateSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          startDateSort === 'AES' ? 'START_DESC' : 'START_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          startDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Start Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEndDateSort(endDateSort === 'AES' ? 'DES' : 'AES');
                        setSort(endDateSort === 'AES' ? 'END_DESC' : 'END_ASC');
                      }}
                    >
                      <i
                        className={
                          endDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    End Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedAtSort(
                          updatedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedAtSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedBySort(
                          updatedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedBySort === 'AES'
                            ? 'CREATED_DESC'
                            : 'CREATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                </div>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <Query
            query={dealsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_place_event_voucher_listing } = data;
              if (
                isNull(search_place_event_voucher_listing) ||
                search_place_event_voucher_listing.place_event_voucher_listing
                  .length === 0
              ) {
                return 'NO RECORDS FOUND';
              }

              return (
                <View
                  eventData={eventData}
                  setEventData={setEventData}
                  search_place_event_voucher_listing={
                    search_place_event_voucher_listing
                  }
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default EventDeals;

const View = ({
  search_place_event_voucher_listing,
  setPageCount,
  setTotalElements,
  eventData,
  setEventData,
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setPageCount(search_place_event_voucher_listing.total_pages);
    setTotalElements(search_place_event_voucher_listing.total_size);
    if (search_place_event_voucher_listing.place_event_voucher_listing) {
      setEventData(
        search_place_event_voucher_listing.place_event_voucher_listing,
      );

      const userFilter = search_place_event_voucher_listing.place_event_voucher_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: userFilter } } },
          },
        })
        .then(({ data: { search_users } }) => {
          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_place_event_voucher_listing.total_pages]);
  return (
    <tbody>
      {eventData &&
        eventData.map(deal => (
          <tr key={deal.deal_id}>
            <ListItem deal={deal} users={users} />
          </tr>
        ))}
    </tbody>
  );
};
