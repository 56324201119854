import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';
import qs from 'querystringify';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import List from './List';
import Search from './Search';
import client from '../../../utils/apolloClient';
import { Loading, Pagination, Table, Box } from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      total_pages
      total_size
      place_listing {
        company_id
        place_id
        display_order
        # is_open
        service_type {
          description
          name
          display_order
        }
        listing_status
        claim_status
        featured
        event_business
        item_review_place
        status
        approval_status
        voucher_count
        default_url
        price_range
        name
        trading_name
        tagline
        description
        slug
        # stars
        review_count
        like_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        audit {
          updated_by
          updated_at
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const ManageRewards = props => {
  const routeState = qs.parse(props.location.search);
  const {
    listing_status,
    item_review_place,
    event_business,
    place_name,
    vendor_name,
  } = routeState;

  const [placeData, setPlaceData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');
  const [claimStatusSort, setClaimStatusSort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');

  // for pagination
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);
  // for pagination

  const [location, setLocation] = useState('');

  const input = {};
  const place_filter = {};
  Object.assign(
    place_filter,
    vendor_name && { vendor_name },
    place_name && { place_name },
    listing_status && { listing_status },
    event_business && { event_business: event_business === 'true' },
    item_review_place && { item_review_place: item_review_place === 'true' },
  );

  Object.assign(
    input,
    location !== '' && { location },
    { sort },
    { from: pageFrom, size: listSize },
    {
      filter: {
        place_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          routeState={routeState}
          location={location}
          setLocation={setLocation}
          listing_status={listing_status}
          event_business={event_business}
          item_review_place={item_review_place}
          place_name={place_name}
          vendor_name={vendor_name}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                  <br />
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setClaimStatusSort(
                          claimStatusSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          claimStatusSort === 'AES'
                            ? 'CLAIM_STATUS_DESC'
                            : 'CLAIM_STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          claimStatusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Claim Status
                  </span>
                </div>
              </th>

              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedBySort(updatedBySort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedBySort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedBySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated By
                </span>
              </th>
              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedAtSort(updatedAtSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedAtSort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedAtSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated At
                </span>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_places } = data;

              if (search_places.place_listing.length === 0) {
                return <div>NO RECORDS FOUND</div>;
              }
              return (
                <View
                  placeData={placeData}
                  setPlaceData={setPlaceData}
                  search_places={search_places}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_places,
  setPageCount,
  setTotalElements,
  setPlaceData,
  placeData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_places.total_pages);
    setTotalElements(search_places.total_size);

    if (search_places.place_listing) {
      setPlaceData(search_places.place_listing);
      const userFilter = search_places.place_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');
          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_places.total_pages]);

  return (
    <tbody>
      {placeData.map(place => (
        <tr key={place.place_id}>
          <List place={place} users={users} />
        </tr>
      ))}
    </tbody>
  );
};

export default ManageRewards;
