import { forEach, differenceBy, isNull, uniqBy, isUndefined } from 'lodash';
import dayjs from 'dayjs';
import { getDistance } from 'geolib';
import axios from 'axios';

const timeZoneGoogleUrl = 'https://maps.googleapis.com/maps/api/timezone/json?';

export const formatDate = data => dayjs(data).format('ddd, M MMM YYYY, h:mm A');
export const startEndDateParser = (start, end) => {
  if (dayjs(start).isSame(dayjs(end))) {
    return formatDate(start);
  }
  if (dayjs(start).diff(dayjs(end)) <= 86400000) {
    return `${dayjs(start).format('ddd M MMM YYYY, h:mm A')} to ${dayjs(
      end,
    ).format('h:mm A')}`;
  }
  return `${formatDate(start)} to ${formatDate(end)}`;
};

export const getDistanceMatrix = (currentLocation, placeLocation) => {
  const distance = getDistance(currentLocation, placeLocation);
  return Math.round(distance / 1000);
};

export const firstTwoLetterPicker = name => {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
};

export const normalizePlaceData = async result => {
  const components = {};
  await forEach(result.address_components, addressComponent => {
    forEach(addressComponent.types, type => {
      components[type] = addressComponent.long_name;
    });
  });

  const finalData = await {
    addressLine1: `${
      components.street_number ? components.street_number : ''
    } ${components.route ? components.route : ''}`,
    addressLine2: result.formatted_address,
    city: components.locality ? components.locality : '',
    state: components.administrative_area_level_1
      ? components.administrative_area_level_1
      : '',
    postcode: components.postal_code ? components.postal_code : '',
    country: components.country ? components.country : '',
    latitude: result.geometry.location.lat().toString(10),
    longitude: result.geometry.location.lng().toString(10),
  };

  await axios
    .get(
      // eslint-disable-next-line max-len
      `${timeZoneGoogleUrl}location=${result.geometry.location.lat()},${result.geometry.location.lng()}&timestamp=1331161200&key=AIzaSyARq3lIWaDnlepdOd2alXdFzuTcd-PyGdY`,
    )
    .then(response => {
      // handle success
      console.log('response', response);
    })
    .catch(error => {
      // handle error
      console.log(error);
    });

  return finalData;
};

export const parseTagInputsData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

export const parseTagInputsInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

export const placeFeeManagementHandler = (defaultFee, placeFee) => {
  let fee = [];
  const exceptCommonFee = differenceBy(defaultFee, placeFee, 'name');
  // const exceptCommonFeeViceVersa = differenceBy(placeFee, defaultFee, 'name');
  // const finalExceptCommonFee = exceptCommonFee.concat(exceptCommonFeeViceVersa);
  if (isNull(placeFee)) {
    fee = defaultFee;
  } else {
    const mergeFee = placeFee.concat(exceptCommonFee);
    fee = mergeFee;
  }
  return fee;
  // else if (finalExceptCommonFee.length < defaultFee.length) {
  //   fee = defaultFee;
  // }
};

export const placeFeeInputManagementHandler = (placeFee, inputFee) => {
  let fee = [];
  if (isNull(placeFee)) {
    fee = inputFee;
  } else {
    const exceptCommonFee = differenceBy(placeFee, inputFee, 'name');
    const mergeFee = inputFee.concat(exceptCommonFee);
    fee = uniqBy(mergeFee, 'name');
  }
  return fee;
};

const initialTime = '';

const business_hour = [
  {
    day: 'MON',
    is_active: true,
    open24Hours: false,
    option: [{ type: '', start: initialTime, end: initialTime }],
  },
  {
    day: 'TUE',
    is_active: true,
    open24Hours: false,
    option: [{ type: '', start: initialTime, end: initialTime }],
  },
  {
    day: 'WED',
    is_active: true,
    open24Hours: false,
    option: [{ type: '', start: initialTime, end: initialTime }],
  },
  {
    day: 'THU',
    is_active: true,
    open24Hours: false,
    option: [{ type: '', start: initialTime, end: initialTime }],
  },
  {
    day: 'FRI',
    is_active: true,
    open24Hours: false,
    option: [{ type: '', start: initialTime, end: initialTime }],
  },
  {
    day: 'SAT',
    is_active: true,
    open24Hours: false,
    option: [{ type: '', start: initialTime, end: initialTime }],
  },
  {
    day: 'SUN',
    is_active: true,
    open24Hours: false,
    option: [{ type: '', start: initialTime, end: initialTime }],
  },
];
const sorter = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};
export const placeBusinessHoursHandler = placeHours => {
  let hours = [];
  const exceptCommonHours = differenceBy(business_hour, placeHours, 'day');
  if (isNull(placeHours)) {
    hours = business_hour;
  } else {
    const mergeHours = placeHours.concat(exceptCommonHours);
    hours = mergeHours;
  }
  hours.sort((a, b) => {
    const day1 = a.day;
    const day2 = b.day;
    return sorter[day1] - sorter[day2];
  });
  return hours;
};

const sorterMenu = {
  SUN: 1,
  MON: 2,
  TUE: 3,
  WED: 4,
  THU: 5,
  FRI: 6,
  SAT: 7,
};

export const menuHourSorter = placeHours => {
  let hours = [];
  const exceptCommonHours = differenceBy(business_hour, placeHours, 'day');
  if (isNull(placeHours)) {
    hours = business_hour;
  } else {
    const mergeHours = placeHours.concat(exceptCommonHours);
    hours = mergeHours;
  }
  hours.sort((a, b) => {
    const day1 = a.day;
    const day2 = b.day;
    return sorterMenu[day1] - sorterMenu[day2];
  });
  return hours;
};

export const parseContactValidValue = contact => {
  const data = [];
  forEach(contact, item => {
    if (item.value && item.value.length !== 0) {
      data.push(item);
    }
  });
  return data;
};

export const createObjFromArrayValues = tagsValue => {
  const data = [];
  if (!isUndefined(tagsValue)) {
    tagsValue.map(item => {
      data.push({
        value: item.charAt(0).toUpperCase() + item.slice(1).toLowerCase(),
        label: item.charAt(0).toUpperCase() + item.slice(1).toLowerCase(),
      });
      return null;
    });
  }
  return data;
};

export const createObjFromArrayCapitalize = tagsValue => {
  const data = [];
  if (!isUndefined(tagsValue)) {
    tagsValue.map(item => {
      data.push({
        value: item.toUpperCase(),
        label: item.toUpperCase(),
      });
      return null;
    });
  }
  return data;
};