import React from 'react';
import styled from 'styled-components';

import {
  GoogleMap,
  Polygon,
  Circle,
  useLoadScript,
  Marker,
} from '@react-google-maps/api';
import config from '../../../../utils/config';

const Container = styled.div`
  height: 60vh;
  .map {
    height: 100%;
  }
`;

const defaultCenterLocation = {
  lat: 52.52047739093263,
  lng: 13.36653284549709,
};

const MultipleMapZone = ({ centerLocation = defaultCenterLocation, zone }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleApiKey,
    language: 'en',
    region: 'us',
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.{loadError}</div>;
  }

  if (!isLoaded) {
    return 'Loading....';
  }

  return (
    <Container>
      <GoogleMap
        mapContainerClassName="map"
        center={centerLocation}
        zoom={12}
        version="weekly"
        on
      >
        <Marker position={centerLocation} animation="DROP" />
        {zone.map(({ color_code, delivery_area }) => {
          console.log('43827482357r', delivery_area);
          return (
            <>
              {delivery_area.area_type === 'CIRCLE' && (
                <Circle
                  options={{
                    strokeColor: color_code,
                    fillColor: color_code,
                  }}
                  editable={false}
                  draggable={false}
                  center={{
                    lat: delivery_area.area_circle.location.latitude,
                    lng: delivery_area.area_circle.location.longitude,
                  }}
                  radius={
                    parseFloat(
                      delivery_area.area_circle.radius.substring(
                        0,
                        delivery_area.area_circle.radius.length - 1,
                      ),
                    ) * 1000
                  }
                />
              )}
              {delivery_area.area_type === 'POLYGON' && (
                <Polygon
                  options={{
                    strokeColor: color_code,
                    fillColor: color_code,
                  }}
                  editable={false}
                  draggable={false}
                  path={delivery_area.area_polygon}
                />
              )}
            </>
          );
        })}
      </GoogleMap>
    </Container>
  );
};
export default MultipleMapZone;
