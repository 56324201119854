import React from 'react';
import { Link } from 'react-router-dom';
import { isNull, startCase } from 'lodash';
import moment from 'moment';

import Delete from './Delete';

const DealList = ({ deal }) => (
  <tr>
    <td>
      <Link to={`/update-user-promo/${deal.user_id}/${deal.deal_id}`}>
        {deal.deal_name}
      </Link>
    </td>
    <td>{startCase(deal.deal_type)}</td>
    <td>{startCase(deal.promo_type)}</td>
    <td>{deal?.promo_code ?? '-'}</td>
    <td>{deal.service_type}</td>
    <td>{deal.menu_name}</td>
    <td>{deal.user_name}</td>
    <td>{deal.place_name}</td>
    <td>{deal.city_subarb}</td>
    <td>
      {isNull(deal.start_date) ? 'N.A' : (deal.start_date ?? '').split('T')[0]}
    </td>
    <td>
      {isNull(deal.end_date) ? 'N.A' : (deal.end_date ?? '').split('T')[0]}
    </td>
    <td>{deal.audit.updated_by}</td>
    <td>{moment(deal.audit.updated_at).format('lll')}</td>{' '}
    <td>
      <Delete menu_deal_id={deal.deal_id} targeted_users={[deal.user_id]} />
    </td>
  </tr>
);

export default DealList;
