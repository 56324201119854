import React from 'react';

const RadioBox = ({ label, name, checked, onChange, ...props }) => (
  <div className="field">
    <div className="control">
      <label className="radiobox is-primary">
        <input
          type="radio"
          {...props}
          checked={checked}
          name={name}
          onChange={onChange}
        />
        <span> {label}</span>
      </label>
    </div>
  </div>
);

export default RadioBox;
