import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import { SubHeading } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const Container = styled.div``;

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        display_order
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        status
        claim_status
      }
    }
  }
`;

const PlaceDataHeader = ({ placeId }) => {
  // const [placeData, setPlaceData] = useState(place);
  const { userId } = useStoreState(state => state.auth);
  const [placeData, setPlaceData] = React.useState(null);

  useEffect(() => {
    const fetchFunc = async () => {
      if (placeId)
        client.clientPrivate
          .query({
            query: placesQuery,
            variables: {
              input: {
                user_id: userId,
                filter: { place_filter: { place_id: placeId } },
              },
            },
          })
          .then(({ data }) => {
            if (
              data &&
              data.search_places &&
              data.search_places.place_listing &&
              data.search_places.place_listing.length !== 0
            ) {
              setPlaceData(data.search_places.place_listing[0]);
            }
          });
    };
    fetchFunc();
  }, [placeId, userId]);

  if (placeData === null) {
    return <></>;
  }

  return (
    <React.Fragment>
      <SubHeading className="is-size-2">
        {placeData && placeData.name}{' '}
      </SubHeading>
      {!isUndefined(placeData) && (
        <Container>
          <SubHeading className="is-size-2">
            {`${placeData && placeData.city}| ${placeData &&
              placeData.state}, ${placeData &&
              placeData.post_code} (${placeData && placeData.status})`}
          </SubHeading>
        </Container>
      )}
    </React.Fragment>
  );
};

PlaceDataHeader.propTypes = {
  placeId: PropTypes.string.isRequired,
};

export default PlaceDataHeader;
