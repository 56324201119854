import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import moment from 'moment';
import { forEach } from 'lodash';
// import Header from './Header';
// import Footer from './Footer';

import { Input, TextArea, TagInputs } from '../../../components/elements';
import CreatedUserDetails from './CreatedUserDetails';
import ApprovalUserDetails from './ApprovalUserDetails';
import { getServiceCategories } from '../../../utils/fetchStatic';

const Container = styled.div``;

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const Details = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  review,
  images,
}) => {
  const [services, setServices] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const service = await getServiceCategories();
      setServices(service);
    };
    getData();
  }, []);

  return (
    <Container>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input
            label="Review Rating"
            value={values.overall_rating}
            placeholder="4"
            type="number"
            disabled
          />
        </Column>
        <Column size={3}>
          <Input
            label="Month Visited"
            value={moment(values.month_visited).format('ll')}
            placeholder="May 19"
            disabled
          />
        </Column>
        <Column size={3}>
          <TagInputs
            action="update_place_review"
            label="Service Used"
            value={parseData(values.service_category)}
            suggestions={parseData(services)}
            onChange={value =>
              setFieldValue('service_category', parseInput(value))
            }
            placeholder="Lunch"
          />
        </Column>
        <Column size={3}>
          <Input label="Photos" value={images.length || 0} disabled />
        </Column>
      </Column.Group>
      {review.audit && (
        <CreatedUserDetails
          review={review}
          createdBy={review.audit.created_by}
          createdTime={review.audit.created_at}
        />
      )}

      <Column.Group>
        <Column size="full">
          <TextArea
            action="update_place_review"
            label="Review Content"
            id="ReviewManagement_ReviewPlace_ReviewContent"
            name="content"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.content}
          />
        </Column>
      </Column.Group>
      {review.status === 'APPROVED' && (
        <Column>
          {review.place_review_audit && (
            <ApprovalUserDetails
              createdBy={review.place_review_audit.review_approved_by}
              createdTime={review.place_review_audit.review_approved_at}
            />
          )}
        </Column>
      )}

      {review.status === 'REJECTED' && (
        <Column>
          {review.place_review_audit && (
            <ApprovalUserDetails
              review={review}
              createdBy={review.place_review_audit.review_rejected_by}
              createdTime={review.place_review_audit.review_rejected_at}
            />
          )}
        </Column>
      )}

      <Column.Group>
        <Column size="full">
          <TextArea
            action="update_place_review"
            label="Note"
            name="note"
            id="ReviewManagement_ReviewPlace_Note"
            value={values.note}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.note}
            touched={touched.note}
            required
            rows={2}
          />
        </Column>
      </Column.Group>

      <Column.Group multiline vcentered gapSize={8} size="half">
        {values.share.map(item => (
          <React.Fragment>
            <Column size="half">
              <Input
                label="Review Shared on"
                value={item.key}
                placeholder="Facebook"
                disabled
              />
            </Column>

            <Column size="half">
              <Input
                label="Shared Status"
                value={item.value}
                placeholder="Success"
                disabled
              />
            </Column>
          </React.Fragment>
        ))}
      </Column.Group>
    </Container>
  );
};

export default Details;
