import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';

import Items from './Items';
import { Loading } from '../../../../components/elements';
import { getAllCategories } from './helpers';

const Container = styled.div`
  width: 50%;
`;

const Header = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.lightGreyBeta};
  margin-bottom: 1rem;
`;

const ItemsParent = ({ placeId, menuId }) => {
  const [items, setItems] = useState([]);
  const [itemLinkLoading, setItemLinkLoading] = useState(false);
  const { userId } = useStoreState(state => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      setItemLinkLoading(true);
      try {
        const linksItems = await getAllCategories(
          {
            place_id: placeId,
            menu_id: menuId,
          },
          userId,
        );
        const finalLinkItems = linksItems.map(item => ({
          menu_section_id: item.menu_section_id,
          name: item.name,
          display_order: item.display_order,
        }));

        setItems(finalLinkItems);
        setItemLinkLoading(false);
      } catch (error) {
        setItemLinkLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {itemLinkLoading && <Loading />}
      <Items items={items} onDelete={() => {}} />
    </>
  );
};

const AssignMenu = ({ placeId, menuId }) => (
  <Container>
    <>
      <Header>
        <p className="is-size-2 has-text-weight-semibold">Menu Categories</p>
      </Header>
      <ItemsParent placeId={placeId} menuId={menuId} />
    </>
  </Container>
);

export default AssignMenu;
