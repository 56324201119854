import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';

import { Input, TextArea } from '../../../components/elements';

const Container = styled.div`
  padding-bottom: 2rem;
`;

const CheckInActivity = ({ ticket }) => (
  <Container>
    <Column.Group multiline vcentered gapSize={8}>
      <Column size={4}>
        <Input
          label="Attendee Name"
          value={ticket && ticket.attendee.attendee_name}
          placeholder="Agent(Super Admin)"
          disabled
        />
      </Column>
      <Column size={4}>
        <Input
          label="Booking No"
          value={ticket.booking_number}
          placeholder="XE921540101"
          disabled
        />
      </Column>
      <Column size={4}>
        <Input
          label="Ticket No"
          value={ticket.ticket_number}
          placeholder="myiEH789"
          disabled
        />
      </Column>
      <Column size={8}>
        <TextArea label="Attendee Notes" placeholder="Comments..." disabled />
      </Column>
      <Column size={4}>
        <Column.Group multiline gapSize={0}>
          <Column size="full">
            <Input label="Purchased By" placeholder="Tasty" disabled />
          </Column>
          <Column size="full">
            <Input
              label="Date and Time"
              placeholder="25-May-2019 05:48 PM"
              disabled
            />
          </Column>
        </Column.Group>
      </Column>

      <Column size={4}>
        <Input label="Date" placeholder="25-May-2019" disabled />
      </Column>
      <Column size={4}>
        <Input label="Scan In" disabled />
      </Column>
      <Column size={4} />
      <Column size={4}>
        <Input label="Date" placeholder="25-May-2019" disabled />
      </Column>
      <Column size={4}>
        <Input label="Scan Out" placeholder=" 17:29:38" disabled />
      </Column>
      <Column size={4} />
      <Column size={4}>
        <Input label="Date" placeholder="25-May-2019" disabled />
      </Column>
      <Column size={4}>
        <Input label="Scan In" placeholder=" 17:29:38" disabled />
      </Column>
    </Column.Group>
  </Container>
);

export default CheckInActivity;
