import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDAXlNtyVl5pJ2YcAuXCidgo_NsrlICgTE',
  authDomain: 'kravein.firebaseapp.com',
  databaseURL: 'https://kravein.firebaseio.com',
  projectId: 'kravein',
  storageBucket: 'kravein.appspot.com',
  messagingSenderId: '134512527976',
  appId: '1:134512527976:web:2870e2f88258a7017b09dd',
  measurementId: 'G-PT4D03GVQQ',
};

const firebase = initializeApp(firebaseConfig);

export const requestPermission = callback => {
  if (isSupported()) {
    console.log('firebase messaging is supported...');
    console.log('Requesting permission...');
    if (window && window.Notification) {
      console.log('has window.Notification object...');
      window.Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          try {
            const messaging = getMessaging(firebase);
            console.log('Notification permission granted.');
            getToken(messaging, {
              vapidKey:
                'BOWQjXD2MJOKKiTMucv51LsrhvxMt-EDcABl_qUR7wTZE_mb1Vq1ndob8e-HNqHP0g8wxPA8KSD5A__nA92bm20',
            })
              .then(currentToken => {
                if (currentToken) {
                  callback(currentToken);
                } else {
                  console.log(
                    'No registration token available. Request permission to generate one.',
                  );
                }
              })
              .catch(err => {
                console.log('An error occurred while retrieving token. ', err);
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
    } else {
      console.log('does not have window.Notification object...');
    }
  } else {
    // pass
  }
};

export default firebase;
