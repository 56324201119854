import React from 'react';
// import styled from 'styled-components';
import { Level } from 'rbx';
import { Radio } from '../../../components/elements';

const Search = ({ filterType, setFilterType }) => (
  <Level>
    <Level.Item align="right">
      <Level.Item>
        <Radio
          label="Been There"
          value={filterType === 'been_there'}
          onChange={value => {
            if (value) {
              setFilterType('been_there');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Like It"
          value={filterType === 'like_it'}
          onChange={value => {
            if (value) {
              setFilterType('like_it');
            }
          }}
        />
      </Level.Item>

      <Level.Item>
        <Radio
          label="Shares"
          value={filterType === 'share'}
          onChange={value => {
            if (value) {
              setFilterType('share');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Bookmarks"
          value={filterType === 'bookmark'}
          onChange={value => {
            if (value) {
              setFilterType('bookmark');
            }
          }}
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Mobile Calls"
          value={filterType === 'MOBILE CALLS'}
          onChange={value => {
            if (value) {
              setFilterType('MOBILE CALLS');
            }
          }}
          disabled
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Direction/Map Views"
          value={filterType === 'DIRECTION/MAP VIEWS'}
          onChange={value => {
            if (value) {
              setFilterType('DIRECTION/MAP VIEWS');
            }
          }}
          disabled
        />
      </Level.Item>
      <Level.Item>
        <Radio
          label="Registered User Page Views"
          value={filterType === 'REGISTERED USER PAGE VIEWS'}
          onChange={value => {
            if (value) {
              setFilterType('REGISTERED USER PAGE VIEWS');
            }
          }}
          disabled
        />
      </Level.Item>
    </Level.Item>
  </Level>
);

export default Search;
