import React from 'react';
import { Column, Title } from 'rbx';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { Select } from '../../../components/elements';

const SpecialText = styled.p`
  color: ${({ theme }) => theme.coolGrey};
  font-size: 0.8rem;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.dark};
  font-size: 0.8rem;
  padding-right: 3vh;
`;

const Button = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 70%;
    height: 5vh;
  }
`;

const Form = () => (
  <>
    <Header />

    <br />

    <div style={{ paddingLeft: '1rem' }}>
      <p className="is-size-4 has-text-weight-semibold">
        Food Licence Verification
      </p>
      <SpecialText>
        Please attach a copy of your food licence with your business name and
        location
      </SpecialText>
    </div>

    <Column>
      <div style={{ display: 'flex' }}>
        <Text style={{ paddingTop: '1rem' }}>Last uploaded date: </Text>
        <Title size={4} style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
          Approval Status
        </Title>
        <Column size={3}>
          <Select />
        </Column>
      </div>
    </Column>
    <Column size={4}>
      <Button
        type="button"
        className="button control"
        // onClick={() => setIsActiveOrderDetailsModal(true)}
      >
        Upload Document
      </Button>
    </Column>

    <br />
    <br />

    <div style={{ paddingLeft: '1rem' }}>
      <p className="is-size-4 has-text-weight-semibold">
        Liquor Licence (If applicable)
      </p>
      <SpecialText>
        If your food orders include liquor, please attach a copy of your licence
        with your business name and location.
      </SpecialText>
    </div>

    <Column>
      <div style={{ display: 'flex' }}>
        <Text style={{ paddingTop: '1rem' }}>Last uploaded date: </Text>
        <Title size={4} style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
          Approval Status
        </Title>
        <Column size={3}>
          <Select />
        </Column>
      </div>
    </Column>

    <Column size={4}>
      <Button
        type="button"
        className="button control"
        // onClick={() => setIsActiveOrderDetailsModal(true)}
      >
        Upload Document
      </Button>
    </Column>

    <hr />
    <Footer />
  </>
);

export default Form;
