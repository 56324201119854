import React from 'react';
import { Link } from 'react-router-dom';
import { isNull, startCase } from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { useStoreActions } from 'easy-peasy';

const DealList = ({ deal }) => {
  const { setDefaultPlaceId } = useStoreActions(state => state.menu);
  return (
    <tr>
      <td>
        <Link
          onClick={() => setDefaultPlaceId(deal.place_id)}
          to={`/update-place-deal/${deal.place.place_id}/${deal.deal_id}`}
        >
          {deal.name}
        </Link>
      </td>
      <td>{startCase(deal.deal_type)}</td>
      <td>{startCase(deal.promotion)}</td>
      <td>{deal.promo_code}</td>
      <td>{deal.service_type}</td>
      <td>{deal.menu === null ? '' : deal.menu.name}</td>
      <td>{deal.place.name}</td>
      <td>{deal.place.city}</td>
      <td>{isNull(deal.start) ? 'N.A' : (deal.start ?? '').split('T')[0]}</td>
      <td>{isNull(deal.end) ? 'N.A' : (deal.end ?? '').split('T')[0]}</td>
      <td>
        <ReactTooltip html place="top" type="dark" effect="float" />
        <p
          data-tip={`<span>
          <p>email: ${deal.updated_by_user.email}</p>
          <p>objectId: ${deal.updated_by_user.user_id}</p>
        </span>`}
          data-html
        >
          {deal.updated_by_user.display_name}
        </p>
      </td>
      <td>{moment(deal.audit.updated_at).format('lll')}</td>{' '}
      <td>
        <Link>
          <i className="fas fa-trash has-text-grey" />
        </Link>
      </td>
    </tr>
  );
};

export default DealList;
