import React from 'react';
import styled from 'styled-components';

import { Input, ReactDateTimePicker } from '../../../components/elements';

const Wrapper = styled.nav`
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Button = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 70%;
    height: 5vh;
  }
`;

const Search = () => (
  <Wrapper>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-3">
          <Input
            label="Place Name"
            type="text"
            placeholder="Start entering place name..."
          />
        </div>

        <div className="column">
          <ReactDateTimePicker value={null} label="From" />
        </div>
        <div className="column">
          <ReactDateTimePicker value={null} label="To" />
        </div>
        <div className="column" style={{ paddingTop: '4%' }}>
          <Button type="button" className="button control">
            Download PDF
          </Button>
        </div>
        <div className="column" style={{ paddingTop: '4%' }}>
          <Button type="button" className="button control">
            Download CSV
          </Button>
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default Search;
