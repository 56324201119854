import React, { useState, useEffect } from 'react';
import { Column } from 'rbx';
import gql from 'graphql-tag';
import { isUndefined } from 'lodash';
import moment from 'moment';
import { Input, MobileInput } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      rating
      contact {
        type
        value
        is_primary
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const ListingAudit = ({ approvedBy, approvedAt }) => {
  const [user, setUser] = useState();

  const primaryEmailArry =
    user && user.contact ? user.contact.filter(item => item.is_primary) : [];

  useEffect(() => {
    if (approvedBy) {
      client.clientPrivate
        .query({
          query: userQuery,
          variables: { userId: approvedBy },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          if (data) {
            setUser(data.fetch_user);
          }
        });
    }
  }, []);

  return (
    <fieldset className="box">
      <legend className="label">Listing Audit </legend>
      <Column.Group multiline vcentered>
        {isUndefined(user) || user.user_id === null ? (
          <Column size="3">
            <Input label="User FullName" disabled />
          </Column>
        ) : (
          <Column size="3">
            <Input
              label="User FullName"
              value={`${user && user.first_name} ${user && user.last_name}`}
              disabled
            />
          </Column>
        )}
        <Column size="3">
          <Input
            label="Contact Email"
            value={user ? user.email : ''}
            disabled
          />
        </Column>
        <Column size="3">
          <MobileInput
            label="Contact Phone"
            value={
              primaryEmailArry.length !== 0 ? primaryEmailArry[0].value : ''
            }
            disabled
          />
        </Column>

        {isUndefined(user) || user.user_id === null ? (
          <Column size="3">
            <Input label="Approved On" disabled />
          </Column>
        ) : (
          <Column size="3">
            <Input
              label="Approved On"
              value={moment(approvedAt).format('lll')}
              disabled
            />
          </Column>
        )}
      </Column.Group>
    </fieldset>
  );
};

export default ListingAudit;
