import { action } from 'easy-peasy';
import config from '../utils/config';

export default {
  global: {
    currentLocation: config.defaultCurrentLocation,
    currentRoute: {
      main: '',
      sub: '',
    },
    switchSideBar: true,
    updateCurrentRoute: action((state, payload) => {
      state.currentRoute = payload;
    }),
    updateSidebar: action((state, payload) => {
      state.switchSideBar = payload;
    }),
  },
};
