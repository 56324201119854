import React from 'react';
import styled from 'styled-components';
import { isNull, isUndefined } from 'lodash';
import { Input, CreatableSelectInputs } from './elements';

const CardContent = styled.div`
  padding: 0.5rem;
`;
const components = {
  DropdownIndicator: null,
};

const placeTagValues = tagsValue => {
  const tags = [];
  if (!isUndefined(tagsValue)) {
    tagsValue.map(item => {
      tags.push({ value: item, label: item });
      return null;
    });
  }
  return tags;
};

const placeTagInput = tagsValue => {
  const tags = [];
  tagsValue.map(item => {
    tags.push(item.value);
    return null;
  });
  return tags;
};

const ImageForm = ({
  item,
  index,
  url,
  handleChange,
  handleBlur,
  setFieldValue,
  handleDefault,
  onDelete,
}) => (
  <div className="card">
    <div className="card-image">
      <figure className="image is-16by9">
        <img src={url} alt={item.tooltip} />
      </figure>
    </div>
    <CardContent>
      <Input
        className="is-small"
        label="Link"
        name={`image.${index}.link`}
        value={item.link}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Input
        className="is-small"
        label="Tooltip"
        name={`image.${index}.tooltip`}
        value={item.tooltip}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <CreatableSelectInputs
        components={components}
        label="Tags"
        suggestions={[]}
        value={!isNull(item.tags) ? placeTagValues(item.tags) : []}
        onChange={value => {
          if (value) {
            setFieldValue(`image.${index}.tags`, placeTagInput(value));
          }
        }}
        isClearable={false}
      />
    </CardContent>
    <footer className="card-footer">
      <a
        className="card-footer-item is-size-6"
        onClick={index !== 0 && handleDefault}
      >
        {index !== 0 ? 'Set Default' : 'Default'}
      </a>
      <a
        className="card-footer-item has-text-danger is-size-6"
        onClick={onDelete}
      >
        Delete
      </a>
    </footer>
  </div>
);

export default ImageForm;
