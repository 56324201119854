import React, { Component } from 'react';

import Layout from '../../../components/global/Layout';
import MainSection from './MainSection';

class SavaTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: {},
    };
  }

  handleSubmit = data => {
    console.log('data :', data);
  };

  render() {
    return (
      <Layout>
        <div className="container">
          <MainSection
            onSubmit={this.handleSubmit}
            loading={this.state.loading}
            error={this.state.error}
          />
        </div>
      </Layout>
    );
  }
}

export default SavaTest;
