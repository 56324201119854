import React from 'react';
import { Column } from 'rbx';

const ItemNameAndRemoveButton = ({
  itemLink,
  menu_item,
  item_link_id,
  setFieldValue,
  selectedLinkIds,
}) => (
  <Column.Group
    style={{
      backgroundColor: '#fbf8f8',
      borderBottom: '1px solid #b0b0b0',
    }}
  >
    <Column size={2}>{itemLink.name}</Column>
    <Column
      size={10}
      style={{ fontSize: '13px', cursor: 'pointer', color: '#EE2A7B' }}
    >
      <Column.Group>
        <Column size={2} />
        <Column size={2} />
        <Column size={2} />
        <Column size={2} />
        <Column size={2} />
        <Column size={2} style={{ cursor: 'pointer', color: '#EE2A7B' }}>
          <span
            onClick={() => {
              const modifiedMenuItem = menu_item.filter(
                e => e.item_link_id !== item_link_id,
              );
              setFieldValue('menu_item', modifiedMenuItem);
              const modifiedItemVariant = selectedLinkIds.filter(
                ele => ele !== item_link_id,
              );
              setFieldValue('selectedLinkIds', modifiedItemVariant);
            }}
          >
            Remove
          </span>
        </Column>
      </Column.Group>
    </Column>
  </Column.Group>
);

export default ItemNameAndRemoveButton;
