import React from 'react';
import styled from 'styled-components';
import { Field, getIn } from 'formik';
import { Table } from 'rbx';
import {
  Input,
  Table as CustomTable,
  CheckBox,
} from '../../../components/elements';

const Fieldset = styled.div`
  &&& {
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
    .table td,
    .table th {
      border: 0;
    }
  }
`;

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <div className="has-text-danger	is-size-5">{error}</div>
      ) : null;
    }}
  />
);

const EventTicketFee = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  <Fieldset className="box">
    <legend className="label">Event Ticket Fees</legend>
    <CustomTable>
      <Table.Head>
        <Table.Row>
          <Table.Heading>Fee Name</Table.Heading>
          <Table.Heading>Fee Display Label</Table.Heading>
          <Table.Heading>Sale Method</Table.Heading>
          <Table.Heading>Override</Table.Heading>
          <Table.Heading>Fee Type</Table.Heading>
          <Table.Heading>Fee</Table.Heading>
          <Table.Heading>Applies To</Table.Heading>
          <Table.Heading>Exempt Fee</Table.Heading>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {values.eventTicketFee.map((item, index) => (
          <Table.Row>
            <Table.Cell>
              <Input value={item.name} disabled />
            </Table.Cell>
            <Table.Cell>
              <Input
                value={item.label}
                name={`eventTicketFee.${index}.label`}
                id={`FeeManagement_PlaceEventOverrideFee_EventTicketFee.${index}.label`}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={item.exempt_fee || !item.isOverride}
              />
              <ErrorMessage name={`eventTicketFee.${index}.label`} />
            </Table.Cell>
            <Table.Cell>
              <Input
                value={item.sales_method}
                name={`eventTicketFee.${index}.sales_method`}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <ErrorMessage name={`eventTicketFee.${index}.sales_method`} />
            </Table.Cell>
            <Table.Cell>
              {!item.can_override ? (
                <div className="is-size-1 has-text-danger">
                  <i className="fas fa-times-circle" />
                </div>
              ) : (
                <CheckBox
                  label=""
                  id="placeEventFeeOverride_CheckBox1"
                  value={item.isOverride}
                  onChange={value =>
                    setFieldValue(`eventTicketFee.${index}.isOverride`, value)
                  }
                  disabled={item.exempt_fee}
                />
              )}
            </Table.Cell>
            <Table.Cell>
              <Input
                value={item.fee_value_type}
                name={`eventTicketFee.${index}.fee_value_type`}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <ErrorMessage name={`eventTicketFee.${index}.fee_value_type`} />
            </Table.Cell>
            <Table.Cell>
              <Input
                value={item.value}
                type="number"
                id={`FeeManagement_PlaceEventOverrideFee_EventTicketFee.${index}.value`}
                name={`eventTicketFee.${index}.value`}
                onChange={handleChange}
                onBlur={e => {
                  if (e.target.value) {
                    setFieldValue(
                      `eventTicketFee.${index}.value`,
                      parseFloat(e.target.value).toFixed(2),
                    );
                  }
                }}
                disabled={item.exempt_fee || !item.isOverride}
              />
              <ErrorMessage name={`eventTicketFee.${index}.value`} />
            </Table.Cell>
            <Table.Cell>
              <Input
                value={item.applies_to}
                name={`eventTicketFee.${index}.applies_to`}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <ErrorMessage name={`eventTicketFee.${index}.applies_to`} />
            </Table.Cell>
            <Table.Cell>
              <CheckBox
                label=""
                value={item.exempt_fee}
                onChange={value => {
                  setFieldValue(`eventTicketFee.${index}.exempt_fee`, value);
                  setFieldValue(`eventTicketFee.${index}.isOverride`, value);
                }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </CustomTable>
  </Fieldset>
);

export default EventTicketFee;
