import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import qs from 'querystringify';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';

import ListItem from './ListItem';
import {
  Loading,
  Table,
  Box,
  ErrorMessage,
  Pagination,
  AesDes,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';

const searchServiceQuery = gql`
  query searchService($input: SearchInput) {
    search_service_type(input: $input) {
      total_size
      total_pages
      service_type_listing {
        service_type_id
        name
        status
        description
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          display_name
          email
        }
        updated_by_user {
          user_id
          display_name
          email
        }
      }
    }
  }
`;

const GlobalMenu = ({ location, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const routeState = qs.parse(location.search);
  const { page, status } = routeState;

  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const statusFilter = status || 'ACTIVE';

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [sort, setSort] = useState('UPDATED_AT_DESC');

  const input = { user_id: userId };
  const service_type_filter = { status: statusFilter, menu_only: true };
  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    {
      filter: {
        service_type_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={statusFilter}
          history={history}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              history.push(
                `/services${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="SERVICE_TYPE_ASC"
                  des="SERVICE_TYPE_DESC"
                />
                Service Type
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_BY_ASC"
                  des="UPDATED_BY_DESC"
                />
                Updated By
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="UPDATED_AT_ASC"
                  des="UPDATED_AT_DESC"
                />
                Updated At
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="CREATED_BY_ASC"
                  des="CREATED_BY_DESC"
                />
                Created By
              </th>
              <th>
                <AesDes
                  sort={sort}
                  setSort={setSort}
                  aes="CREATED_AT_ASC"
                  des="CREATED_AT_DESC"
                />
                Created At
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <Query
            query={searchServiceQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <ErrorMessage message={error.message} />;
              }

              return (
                <React.Fragment>
                  <View
                    data={data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements }) => {
  const { search_service_type } = data;
  useEffect(() => {
    setPageCount(search_service_type.total_pages);
    setTotalElements(search_service_type.total_size);
  }, [search_service_type.total_pages]);

  if (search_service_type === null) {
    return 'No record found';
  }

  if (search_service_type.service_type_listing.length === 0) {
    return 'No record found';
  }
  return (
    <tbody>
      {search_service_type.service_type_listing.map(service => (
        <tr key={service.service_type_id}>
          <ListItem service={service} />
        </tr>
      ))}
    </tbody>
  );
};

export default GlobalMenu;
