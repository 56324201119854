import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { FieldArray } from 'formik';

import uuid from 'uuid';
import { CheckBox, SelectTime } from '../../../components/elements';

const DetailsFormWrapper = styled.div`
  padding: 1rem;
`;

const AddButton = styled.a`
  color: ${({ theme }) => theme.primaryColor};
  position: relative;
  bottom: 10px;
`;

const RemoveButton = styled.a`
  color: ${({ theme }) => theme.secondaryColor};
`;

const PlusOneDay = styled.div`
  position: absolute;
  font-weight: 600;
  color: green;
  text-align: center;
  padding-left: 9px;
`;

const Hours = () => (
  <DetailsFormWrapper>
    <Column.Group multiline gapSize={1}>
      <FieldArray
        name="business_hour"
        render={helpers => {
          const { values, setFieldValue } = helpers.form;
          return values.business_hour.map((hour, index) => (
            <>
              <Column size="1">
                <CheckBox
                  value={hour.is_active}
                  onChange={value => {
                    setFieldValue(`business_hour.${index}.is_active`, value);
                    if (!value) {
                      setFieldValue(
                        `business_hour.${index}.open24Hours`,
                        false,
                      );
                      setFieldValue(`business_hour.${index}.option`, [
                        {
                          id: uuid(),
                          start: '',
                          end: '',
                        },
                      ]);
                    }
                  }}
                />
              </Column>
              <Column size="2">
                <p className="is-size-5 has-text-weight-semibold">{hour.day}</p>
              </Column>
              <Column size="9">
                <Column.Group multiline vcentered gapSize={1}>
                  {hour.option.map((option, index2) => (
                    <>
                      <Column size="4">
                        <SelectTime
                          value={option.start}
                          closed={!hour.is_active}
                          disabled={hour.open24Hours || !hour.is_active}
                          onChange={value => {
                            setFieldValue(
                              `business_hour.${index}.option.${index2}.start`,
                              value.time,
                            );
                            const start_in_sec =
                              value.hours * 3600 + value.minutes * 60;
                            setFieldValue(
                              `business_hour.${index}.option.${index2}.start_in_sec`,
                              start_in_sec,
                            );
                          }}
                        />
                      </Column>
                      <Column size="4">
                        <SelectTime
                          value={option.end}
                          closed={!hour.is_active}
                          disabled={
                            hour.open24Hours ||
                            option.start === '' ||
                            !hour.is_active
                          }
                          onChange={value => {
                            setFieldValue(
                              `business_hour.${index}.option.${index2}.end`,
                              value.time,
                            );
                            const end_in_sec =
                              value.hours * 3600 + value.minutes * 60;
                            if (end_in_sec <= option.start_in_sec) {
                              setFieldValue(
                                `business_hour.${index}.option.${index2}.end_in_sec`,
                                end_in_sec + 86340,
                              );
                            } else {
                              setFieldValue(
                                `business_hour.${index}.option.${index2}.end_in_sec`,
                                end_in_sec,
                              );
                            }
                          }}
                        >
                          {hour.is_active &&
                            option.end_in_sec &&
                            option.end_in_sec > 86340 && (
                              <PlusOneDay>
                                <p>+1 day</p>
                              </PlusOneDay>
                            )}
                        </SelectTime>
                      </Column>
                      <Column size="4">
                        {hour.option.length === 1 ? (
                          <CheckBox
                            label="Open 24 Hrs"
                            value={hour.open24Hours}
                            disabled={!hour.is_active}
                            onChange={value => {
                              setFieldValue(
                                `business_hour.${index}.open24Hours`,
                                value,
                              );
                              if (value) {
                                setFieldValue(`business_hour.${index}.option`, [
                                  {
                                    id: uuid(),
                                    start: '12:00 AM',
                                    end: '11:59 PM',
                                    start_in_sec: 0,
                                    end_in_sec: 86340,
                                  },
                                ]);
                              } else {
                                setFieldValue(`business_hour.${index}.option`, [
                                  {
                                    id: uuid(),
                                    start: '',
                                    end: '',
                                  },
                                ]);
                              }
                            }}
                          />
                        ) : (
                          <RemoveButton
                            onClick={() => {
                              const customOption = hour.option.filter(
                                item => item.id !== option.id,
                              );
                              setFieldValue(
                                `business_hour.${index}.option`,
                                customOption,
                              );
                            }}
                          >
                            Remove hours
                          </RemoveButton>
                        )}
                      </Column>
                    </>
                  ))}
                  {!hour.open24Hours && hour.is_active && (
                    <Column size="4">
                      <AddButton
                        onClick={() => {
                          const option = hour.option.concat({
                            id: uuid(),
                            type: '',
                            start: '',
                            end: '',
                          });
                          setFieldValue(
                            `business_hour.${index}.option`,
                            option,
                          );
                        }}
                      >
                        <p className="is-size-3">Add more hours</p>
                      </AddButton>
                    </Column>
                  )}
                </Column.Group>
              </Column>
            </>
          ));
        }}
      />
    </Column.Group>
  </DetailsFormWrapper>
);

export default Hours;
