import React from 'react';
import { Title } from 'rbx';

const Heading = ({ children }) => (
  <Title className="is-size-4-touch is-size-3-desktop is-size-2-widescreen is-size-1-fullhd">
    {children}
  </Title>
);

export default Heading;
