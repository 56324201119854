/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../../components/elements';
import { ACLSConsumer } from '../../../../utils/aclsContext';
import Can from '../../../../utils/Can';

const Box = styled.div`
  border: 1px solid #dbdbdb;
  padding: 1rem;
  margin: 0.2rem;
`;

const Payouts = ({
  data,
  // stripId,
  setStripId,
  setDetailsSubmitted,
  onUpdate,
  updateLoading,
  editAccount,
  // setEditAccount,
  setFieldValue,
  values,
  location,
}) => {
  const [email, setEmail] = useState(null);
  const [payoutEnable, setPayoutEnable] = useState(false);
  const [chargeEnable, setChargeEnable] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      data &&
      data.fetch_connected_account &&
      data.fetch_connected_account.account
    ) {
      setStripId(data.fetch_connected_account.account.id);
      setEmail(
        data.fetch_connected_account.account.business_profile
          ? data.fetch_connected_account.account.business_profile.name
          : '',
      );
      setPayoutEnable(data.fetch_connected_account.account.payouts_enabled);
      setChargeEnable(data.fetch_connected_account.account.charges_enabled);
      setDetailsSubmitted(
        data.fetch_connected_account.account.details_submitted,
      );
    }
  }, [data]);

  return (
    <Box className="columns is-multiline">
      {!editAccount ? (
        <div style={{ position: 'relative' }} className="column is-6">
          <span />
          <ACLSConsumer>
            {({ apiActions, pageActions }) => (
              <Can
                action="create_place"
                apiActions={apiActions}
                pageActions={pageActions}
                yes={() => (
                  <span
                    style={{
                      position: 'absolute',
                      right: '10px',
                      color: '#00aeef',
                      fontSize: '12px',
                      cursor: 'pointer',
                      textStyle: 'underline',
                    }}
                    onClick={() => setIsDisabled(false)}
                  >
                    change&nbsp;
                  </span>
                )}
              />
            )}
          </ACLSConsumer>
          <Input
            label="Stripe Account ID"
            value={values.account_id}
            onChange={e => setFieldValue('account_id', e.target.value)}
            disabled={isDisabled}
            // optionalText={
            //   !stripId ? (
            //     <a
            //       style={{ paddingLeft: '15rem' }}
            //       onClick={() => setEditAccount(true)}
            //     >
            //       <i className="fas fa-edit" />
            //     </a>
            //   ) : (
            //     ''
            //   )
            // }
          />
        </div>
      ) : (
        <div className="column is-6">
          <label className="label">Stripe Account ID</label>
          <div className="field is-grouped">
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="Account Id"
                value={accountId}
                onChange={e => setAccountId(e.target.value)}
              />
            </p>
            <a
              className={`button is-primary ${updateLoading && 'is-loading'}`}
              onClick={() => onUpdate(accountId)}
              disabled={!accountId}
            >
              Submit
            </a>
          </div>
        </div>
      )}

      <div className="column is-6">
        <Input label="Stripe Account Name" value={email} disabled />
      </div>
      <div className="column is-6">
        <div className="is-flex">
          {chargeEnable ? (
            <span className="has-text-success">
              <i className="fas fa-check" />
            </span>
          ) : (
            <span className="has-text-danger">
              <i className="fas fa-minus-circle" />
            </span>
          )}
          &nbsp; &nbsp;
          <p>Charges Enabled</p>
        </div>
      </div>
      <div className="column is-6">
        <div className="is-flex">
          {payoutEnable ? (
            <span className="has-text-success">
              <i className="fas fa-check" />
            </span>
          ) : (
            <span className="has-text-danger">
              <i className="fas fa-minus-circle" />
            </span>
          )}
          &nbsp; &nbsp;
          <p>Payouts Enabled</p>
        </div>
      </div>
    </Box>
  );
};

export default Payouts;
