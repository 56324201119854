import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  background: rgba(255, 200, 65, 0.1);
  margin-bottom: 1rem;
`;

const Cutlery = ({ order }) => {
  const textToIndicatePaymentType = () => {
    let cardOrCashDineinText =
      order.payment.payment_method.toUpperCase() === 'CARD'
        ? `CARD PAYMENT ${
            order.payment.payment_status
              ? `- ${order.payment.payment_status.toUpperCase()}`
              : ''
          }`
        : `COUNTER PAYMENT - ${
            order.payment.payment_status
              ? `${order.payment.payment_status.toUpperCase()}`
              : 'CHARGE THE CUSTOMER'
          }`;
    let cardOrCashText =
      order.payment.payment_method.toUpperCase() === 'CARD'
        ? `CARD PAYMENT ${
            order.payment.payment_status
              ? `- ${order.payment.payment_status.toUpperCase()}`
              : ''
          }`
        : `CASH ORDER - ${
            order.payment.payment_status
              ? `${order.payment.payment_status.toUpperCase()}`
              : 'CHARGE THE CUSTOMER'
          }`;

    if (order.payment.payment_method_type) {
      if (order.payment.payment_method_type === 'G_PAY') {
        cardOrCashDineinText = `GOOGLE PAY ${
          order.payment.payment_status
            ? `- ${order.payment.payment_status.toUpperCase()}`
            : ''
        }`;
        cardOrCashText = `GOOGLE PAY ${
          order.payment.payment_status
            ? `- ${order.payment.payment_status.toUpperCase()}`
            : ''
        }`;
      }
      if (order.payment.payment_method_type === 'APPLE_PAY') {
        cardOrCashDineinText = `APPLE PAY ${
          order.payment.payment_status
            ? `- ${order.payment.payment_status.toUpperCase()}`
            : ''
        }`;
        cardOrCashText = `APPLE PAY ${
          order.payment.payment_status
            ? `- ${order.payment.payment_status.toUpperCase()}`
            : ''
        }`;
      }
      if (order.payment.payment_method_type === 'CARD') {
        cardOrCashDineinText = `CARD PAYMENT ${
          order.payment.payment_status
            ? `- ${order.payment.payment_status.toUpperCase()}`
            : ''
        }`;
        cardOrCashText = `CARD PAYMENT ${
          order.payment.payment_status
            ? `- ${order.payment.payment_status.toUpperCase()}`
            : ''
        }`;
      }
    }
    return order.service_type === 'Dinein'
      ? cardOrCashDineinText
      : cardOrCashText;
  };
  return (
    <Container>
      {order.payment && order.payment.payment_method && (
        <p
          className="is-size-2 has-text-weight-semibold"
          style={{ color: '#ee2a7b' }}
        >
          {textToIndicatePaymentType()}
        </p>
      )}
      {order.include_cuttlery ? (
        <p className="is-size-3" style={{ color: '#05aeef' }}>
          Please send cutlery, straws, napkins etc
        </p>
      ) : (
        <p className="is-size-3" style={{ color: '#05aeef' }}>
          No Cutlery
        </p>
      )}
      {order.order_contains_alcohol && order.age_above_18 && (
        <p className="is-size-3" style={{ color: '#05aeef' }}>
          User confirms over 18
        </p>
      )}
    </Container>
  );
};
export default Cutlery;
