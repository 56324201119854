import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import moment from 'moment';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';

const placeReviewsQuery = gql`
  query searchPlaceReviews($input: SearchInput) {
    search_place_review(input: $input) {
      total_pages
      total_size
      place_review_listing {
        place_id
        place_review_id
        content
        overall_rating
        month_visited
        status
        place {
          name
          address_line_1
          city
          state
          country
          post_code
          latitude
          longitude
          location
          timezone
          default_url
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const listSize = 10;

const PlaceReviews = props => {
  const routeState = qs.parse(props.location.search);
  const { status, placeName, city, reviewOn, page } = routeState;

  const statusFilter = status || 'SUBMITTED';
  const placeNameFilter = placeName || '';
  const cityFilter = city || null;
  const reviewOnFilter = reviewOn || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  // const [reviewedBySort, setReviewedBySort] = useState('AES');
  const [reviewedOnSort, setReviewedOnSort] = useState('AES');

  const { place } = props;
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();

  const input = {};
  const place_review_filter = { draft: false, status: statusFilter };
  Object.assign(
    place_review_filter,
    placeNameFilter && { place_name: placeNameFilter },
    cityFilter && { city: cityFilter },
    reviewOn && {
      date_range: {
        start_date: moment(reviewOn).format('YYYY-MM-DD'),
        end_date: moment(reviewOn)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      },
    },
  );
  Object.assign(
    input,
    { from: currentPage * listSize, size: listSize, sort },
    {
      filter: {
        place_review_filter,
      },
    },
  );
  return (
    <Layout>
      <Header place={place} />
      <Box>
        <Search
          city={cityFilter}
          status={statusFilter}
          reviewOn={reviewOnFilter}
          placeName={placeNameFilter}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            currentPage={currentPage}
            totalElements={totalElements}
            handlePageClick={value =>
              props.history.push(
                `/place-reviews${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    {/* <a
                      onClick={() => {
                        setReviewedBySort(
                          reviewedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          reviewedBySort === 'AES'
                            ? 'CREATED_DESC'
                            : 'CREATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          reviewedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '} */}
                    Created By
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setReviewedOnSort(
                          reviewedOnSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          reviewedOnSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          reviewedOnSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated On
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={placeReviewsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_place_review } = data;
              if (
                isNull(search_place_review) ||
                search_place_review.place_review_listing.length === 0
              ) {
                return 'no data found';
              }

              return (
                <React.Fragment>
                  <View
                    search_place_review={search_place_review}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default PlaceReviews;

const View = ({ search_place_review, setPageCount, setTotalElements }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setPageCount(search_place_review.total_pages);
    setTotalElements(search_place_review.total_size);
    if (search_place_review.place_review_listing) {
      const userFilter = search_place_review.place_review_listing.map(
        item => item.audit.created_by,
      );

      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_place_review.total_pages]);

  const reviews = search_place_review.place_review_listing;
  return (
    <tbody>
      {reviews.map(review => (
        <ListItem review={review} users={users} />
      ))}
    </tbody>
  );
};
