import React from 'react';
import styled from 'styled-components';
import { Select } from 'rbx';

const Container = styled.div`
  padding-top: 4px;
  &&& {
    .select {
      @media screen and (max-width: 1023px) {
        font-size: 0.65rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 0.55rem;
      }
      @media screen and (min-width: 1216px) {
        font-size: 0.65rem;
      }
      @media screen and (min-width: 1408px) {
        font-size: 0.75rem;
      }
    }
  }
`;

const CustomSelect = ({ options, children, ...props }) => (
  <Container className="is-size-7-touch is-size-6-desktop is-size-5-widescreen is-size-2-fullhd">
    <Select.Container>
      <Select {...props}>
        {options.map(item => (
          <Select.Option value={item.value}>{item.label}</Select.Option>
        ))}
      </Select>
    </Select.Container>
  </Container>
);

export default CustomSelect;
