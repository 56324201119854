import React, { useState } from 'react';
import gql from 'graphql-tag';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';

import client from '../../../utils/apolloClient';
import { parseInputModifier } from './helpers';
import AddEditModifierForm from './AddEditModifierForm';

const createMenuModifierGroupMutation = gql`
  mutation createMenuModifierGroup($input: MenuModifierGroupInput) {
    create_menu_modifier_group(input: $input) {
      modifier_group_id
      place_id
      name
      error {
        description
      }
    }
  }
`;

const Modifiers = ({
  values,
  setFieldValue,
  isActiveAddEditModal,
  setIsActiveAddEditModal,
}) => {
  const [backupModifierGroup, setBackupModifierGroup] = useState();
  const [formReset, setFormReset] = useState();

  const [createMenuModifierGroup, { loading }] = useMutation(
    createMenuModifierGroupMutation,
    {
      client: client.clientPrivate,
      onCompleted: ({ create_menu_modifier_group }) => {
        if (!isNull(create_menu_modifier_group.error)) {
          create_menu_modifier_group.error.map(item =>
            toast.error(item.description),
          );
        } else {
          setFieldValue(
            'modifierGroups',
            values.modifierGroups.concat({
              ...backupModifierGroup,
              modifier: backupModifierGroup.modifier.map(item => ({
                ...item,
                menu_item: {
                  menu_item_id: item.menu_item_id,
                  name: item.name,
                  price: item.price,
                  variant: item.variant,
                },
              })),
            }),
          );
          setIsActiveAddEditModal(false);
          setBackupModifierGroup();
          formReset.resetForm();
        }
      },
    },
  );
  if (!isActiveAddEditModal) {
    return null;
  }

  return (
    <AddEditModifierForm
      isActive={isActiveAddEditModal}
      onClose={() => setIsActiveAddEditModal(false)}
      userId={values.user_id}
      placeId={values.place_id}
      menuItemId={values.menu_item_id}
      loading={loading}
      onSubmit={(inputs, resetForm) => {
        setBackupModifierGroup(inputs);
        setFormReset({ resetForm });
        createMenuModifierGroup({
          variables: {
            input: {
              ...inputs,
              modifier: parseInputModifier(inputs.modifier),
            },
          },
        });
      }}
    />
  );
};
export default Modifiers;
