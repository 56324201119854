import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isNull } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useStoreActions } from 'easy-peasy';

const CheckInList = ({ item }) => {
  const { setDefaultPlaceId } = useStoreActions(state => state.menu);
  return (
    <React.Fragment>
      <td>
        <Link
          onClick={() => setDefaultPlaceId(item.place.place_id)}
          to={`/update-menu-item/${item.menu_item_id}`}
        >
          {item.name}{' '}
          <span style={{ color: '#505050' }}>({item.internal_name})</span>
        </Link>
      </td>
      <td>{item.place.name}</td>
      <td>{item.place.city}</td>
      <td>{isNull(item.price) ? '' : parseFloat(item.price).toFixed(2)}</td>
      <td>{((item?.variant ?? [])[0] ?? {}).variants?.length || ''}</td>
      <td>{item.platform}</td>
      <td>
        <ReactTooltip html place="top" type="dark" effect="float" />
        <p
          data-tip={`<span>
          <p>email: ${item.updated_by_user.email}</p>
          <p>objectId: ${item.updated_by_user.user_id}</p>
        </span>`}
          data-html
        >
          {item.updated_by_user.display_name}
        </p>
      </td>
      <td> {moment(item.audit.updated_at).format('lll')} </td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      {/* <td>
      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action="delete_menu_item"
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => <Delete itemId={item.menu_item_id} />}
          />
        )}
      </ACLSConsumer>
    </td> */}
    </React.Fragment>
  );
};

export default CheckInList;
