import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { isNull } from 'lodash';
import Details from './Details';
import ReviewPhotos from './ReviewPhotos';
import ReviewFlags from './ReviewFlags';
import ReviewComments from './ReviewComments';
import ReviewLikes from './ReviewLikes';
import SocialCounts from './SocialCounts';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;
const tabs = [
  {
    key: 0,
    name: 'Place Review Detail',
  },
  {
    key: 1,
    name: 'Review Photos',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    handleSubmit,
    loading,
    values,
    action,
    handleAction,
    review,
    place,
    dirty,
    updateReviewLoading,
  } = props;
  const prev = () =>
    activeTab === 0 ? setActiveTab(4) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 4 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  const handleApprovedAction = async () => {
    await handleAction('APPROVED');
    handleSubmit();
  };
  const handleRejectAction = async () => {
    await handleAction('REJECTED');
    handleSubmit();
  };
  const handleUpdateReview = async () => {
    await handleAction('UPDATE_REVIEW');
    handleSubmit();
  };
  return (
    <React.Fragment>
      <Header
        review={review}
        heading="Place Review Approval"
        loading={loading}
        action={action}
        status={review.status}
        rejectImages
        updateReviewLoading={updateReviewLoading}
        handleUpdateReview={handleUpdateReview}
        handleApprovedAction={handleApprovedAction}
        handleRejectAction={handleRejectAction}
        prev={prev}
        next={next}
        dirty={dirty}
        activeTab={activeTab}
        // eslint-disable-next-line max-len
        subHeading={`${place.name} | ${place.city}, ${place.post_code} | ${place.country} (${place.status} / ${review.status} / ${place.claim_status} ) `}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
          {review.status !== 'SUBMITTED' && (
            <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>
              Review Likes
            </Tab>
          )}
          {review.status !== 'SUBMITTED' && (
            <Tab active={activeTab === 3} onClick={() => setActiveTab(3)}>
              Review Comments
            </Tab>
          )}
          {review.status !== 'SUBMITTED' && (
            <Tab active={activeTab === 4} onClick={() => setActiveTab(4)}>
              Review Flags
            </Tab>
          )}
        </Tabs>

        <Container>
          <SocialCounts {...props} />

          {activeTab === 0 && <Details {...props} />}
          {activeTab === 1 && (
            <ReviewPhotos
              reviewStatus={values.status}
              images={values.images}
              setFieldValue={props.setFieldValue}
            />
          )}
        </Container>

        {review.status !== 'SUBMITTED' && (
          <React.Fragment>
            {activeTab === 2 && <ReviewLikes {...props} />}
            {activeTab === 3 && <ReviewComments {...props} reivew={review} />}
            {activeTab === 4 && <ReviewFlags {...props} />}
          </React.Fragment>
        )}
      </Box>
      <hr />
      <Footer
        prev={prev}
        next={next}
        dirty={dirty}
        action={action}
        loading={loading}
        status={review.status}
        rejectImages
        activeTab={activeTab}
        updateReviewLoading={updateReviewLoading}
        handleUpdateReview={handleUpdateReview}
        handleApprovedAction={handleApprovedAction}
        handleRejectAction={handleRejectAction}
      />
    </React.Fragment>
  );
};

const PlaceReviewForm = withFormik({
  mapPropsToValues: ({ review, images }) => ({
    note: '',
    overall_rating:
      review && !isNull(review.overall_rating) ? review.overall_rating : '',
    month_visited:
      review && !isNull(review.month_visited) ? review.month_visited : '',
    content: review && !isNull(review.content) ? review.content : '',
    image: review && !isNull(review.image) ? review.image : [],
    share: review && !isNull(review.share) ? review.share : [],
    service_category:
      review && !isNull(review.service_category) ? review.service_category : [],
    status: review && !isNull(review.status) ? review.status : [],
    images: images.length !== 0 ? images : [],
  }),

  validationSchema: yup.object().shape({
    note: yup.string().required('Note is Required!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'PlaceReviewForm',
})(Form);

export default PlaceReviewForm;
