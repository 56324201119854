/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FieldArray, Field, getIn } from 'formik';
import moment from 'moment';
import styled from 'styled-components';
import { isNull } from 'lodash';
import {
  Input,
  CheckBox,
  SelectTime as TimePicker,
} from '../../../components/elements';

const OptionalText = styled.span`
  &&& {
    color: #737373;
  }
`;

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <div className="has-text-danger	is-size-5">{error}</div>
      ) : null;
    }}
  />
);

const PlaceHours = ({ values, setFieldValue, action }) => (
  <div>
    <div className="columns">
      <div className="column is-3 is-offset-1">
        {/* <div className="label">
          Label{' '}
          <OptionalText className="is-size-5">
            ( 20 Characters Max )
          </OptionalText>
        </div> */}
      </div>
    </div>
    {console.log('values', values)}
    {values.business_hour.map((item, index) => (
      <div className="columns is-multiline" key={index}>
        <div className="column is-1">
          <p className="label has-text-weight-semibold">{item.day}</p>
        </div>
        <FieldArray
          name={`business_hour.${index}.option`}
          render={helpers => (
            <div className="column is-11">
              {values.business_hour.length !== 0 && (
                <>
                  {values.business_hour[index].option.map((final, ind) => (
                    <React.Fragment key={`${index}-${ind}`}>
                      <div className="columns is-vcentered">
                        <div className="column is-2">
                          <TimePicker
                            value={final.start}
                            closed={!values.business_hour[index].is_active}
                            disabled={
                              values.business_hour[index].open24Hours ||
                              !values.business_hour[index].is_active
                            }
                            onChange={value => {
                              setFieldValue(
                                `business_hour.${index}.option.${ind}.start`,
                                value.time,
                              );
                              const start_in_sec =
                                value.hours * 3600 + value.minutes * 60;
                              setFieldValue(
                                `business_hour.${index}.option.${ind}.start_in_sec`,
                                start_in_sec,
                              );
                            }}
                          />

                          <ErrorMessage
                            name={`business_hour.${index}.option.${ind}.start`}
                          />
                        </div>
                        <div className="column is-2">
                          <TimePicker
                            value={final.end}
                            disabled={
                              !values.business_hour[index].is_active ||
                              values.business_hour[index].open24Hours
                            }
                            onChange={value => {
                              setFieldValue(
                                `business_hour.${index}.option.${ind}.end`,
                                value.time,
                              );
                              const end_in_sec =
                                value.hours * 3600 + value.minutes * 60;
                              if (end_in_sec <= final.start_in_sec) {
                                setFieldValue(
                                  `business_hour.${index}.option.${ind}.end_in_sec`,
                                  end_in_sec + 86340,
                                );
                              } else {
                                setFieldValue(
                                  `business_hour.${index}.option.${ind}.end_in_sec`,
                                  end_in_sec,
                                );
                              }
                            }}
                          />
                          <ErrorMessage
                            name={`business_hour.${index}.option.${ind}.end`}
                          />
                        </div>
                        <div className="column">
                          {ind === 0 && (
                            <CheckBox
                              action={action}
                              label="Open 24 Hrs"
                              value={values.business_hour[index].open24Hours}
                              onChange={value => {
                                if (value) {
                                  setFieldValue(`business_hour.${index}`, {
                                    day: values.business_hour[index].day,
                                    is_active: true,
                                    open24Hours: value,
                                    option: [
                                      {
                                        type: 'Open 24 hours',
                                        start: '00:00 AM',
                                        end: '11:59 PM',
                                        start_in_sec: 0,
                                        end_in_sec: 23 * 3600 + 59 * 60 + 59,
                                      },
                                    ],
                                  });
                                } else {
                                  setFieldValue(`business_hour.${index}`, {
                                    day: values.business_hour[index].day,
                                    is_active: true,
                                    open24Hours: value,
                                    option: [
                                      {
                                        type: '',
                                        start: '',
                                        end: '',
                                      },
                                    ],
                                  });
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className="column">
                          {ind === 0 && (
                            <CheckBox
                              action={action}
                              label="Closed"
                              value={
                                !isNull(values.business_hour[index].is_active)
                                  ? !values.business_hour[index].is_active
                                  : false
                              }
                              onChange={value => {
                                if (value) {
                                  setFieldValue(`business_hour.${index}`, {
                                    day: values.business_hour[index].day,
                                    is_active: !value,
                                    open24Hours: false,
                                    option: [
                                      {
                                        type: 'Closed',
                                        start: '',
                                        end: '',
                                        start_in_sec: 0,
                                        end_in_sec: 0,
                                      },
                                    ],
                                  });
                                } else {
                                  setFieldValue(`business_hour.${index}`, {
                                    day: values.business_hour[index].day,
                                    is_active: true,
                                    open24Hours: false,
                                    option: [
                                      {
                                        type: '',
                                        start: '',
                                        end: '',
                                        start_in_sec: 0,
                                        end_in_sec: 0,
                                      },
                                    ],
                                  });
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className="column">
                          <nav className="level">
                            <div className="level-item">
                              {ind === 0 ? (
                                <button
                                  type="button"
                                  disabled={
                                    !values.business_hour[index].is_active ||
                                    values.business_hour[index].open24Hours
                                  }
                                  className="button is-primary"
                                  onClick={() =>
                                    helpers.push({
                                      type: ``,
                                      start: '',
                                      end: '',
                                      start_in_sec: 0,
                                      end_in_sec: 0,
                                    })
                                  }
                                >
                                  AddMore
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="button is-danger"
                                  onClick={() => helpers.remove(ind)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </nav>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>
          )}
        />
        <hr />
      </div>
    ))}
  </div>
);

export default PlaceHours;
