import React from 'react';
import { Link } from 'react-router-dom';
import { first, replace } from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const EventList = ({ events, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === events.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <React.Fragment>
      <td>
        <Link to={`/check-in-attendees/${events.event_id}`}>{events.name}</Link>
      </td>
      <td>{`${events.place_name}`}</td>
      <td>{`${events.city}`}</td>
      <td className="is-capitalized">
        {replace(first(events.listing_type), '_', ' ')}
      </td>
      {/* <td>{`${event.place_name}`}</td> */}
      <td>{`${moment(events.start).format('lll')}`}</td>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user.full_name}>{user.full_name}</p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user && user.email}</p>
                <p>objectId: {user && user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          events.audit.updated_by
        )}
      </td>

      <td>{moment(events.audit.updated_at).format('lll')}</td>
      {/* <td>{events.status}</td> */}
    </React.Fragment>
  );
};
export default EventList;
