import gql from 'graphql-tag';
import client from '../../../../utils/apolloClient';

const createSesTemplateMutation = gql`
  mutation createSesTemplateMutation(
    $html: String
    $subject: String
    $template_name: String
    $user_id: String
  ) {
    create_ses_template(
      input: {
        html: $html
        subject: $subject
        template_name: $template_name
        user_id: $user_id
      }
    ) {
      message
      template_id
      error {
        code
        description
      }
    }
  }
`;

const getError = res =>
  Array.isArray(res?.error) && res.error[0] ? res.error[0].description : null;

export const createSesTemplate = async ({
  html,
  subject,
  template_name,
  user_id,
}) => {
  try {
    const {
      data: { create_ses_template },
    } = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createSesTemplateMutation,
      variables: { html, subject, template_name, user_id },
    });
    return getError(create_ses_template);
  } catch (error) {
    return 'Error : UNEXPECTED NETWORK ERROR';
  }
};

export default {};
