import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { Input, TextArea } from '../../../components/elements';
import ReviewPhotos from './ReviewPhotos';
import CreatedUserDetails from './CreatedUserDetails';
import ApprovedUserDetails from './ApprovedUserDetails';

const Container = styled.div`
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const PhotoApprovals = props => {
  const { review, touched, errors, handleBlur, handleChange } = props;
  return (
    <React.Fragment>
      <Column.Group vcentered multiline gapSize={8}>
        <Container>
          <Column>
            <Column.Group vcentered multiline gapSize={8}>
              <Column size={3}>
                <Input
                  label="Review Type"
                  value={review.object_type}
                  disabled
                />
              </Column>
              <Column size={3}>
                <Input
                  label="Approval Status"
                  value={review.approval_status}
                  disabled
                />
              </Column>
              <Column size={3}>
                <Input label="Status" value={review.status} disabled />
              </Column>
            </Column.Group>
            <Column.Group vcentered multiline gapSize={8}>
              <Column size={3}>
                <Input label="Uploaded Place Photos" disabled />
              </Column>
              <Column size={3}>
                <Input label="User Uploaded Place Photos" disabled />
              </Column>
              <Column size={3}>
                <Input label="Total Review Count" disabled />
              </Column>
              <Column size={3}>
                <Input label="Review Rating" placeholder="4" disabled />
              </Column>
            </Column.Group>
          </Column>
          <Column>
            <CreatedUserDetails
              review={review}
              createdBy={review.audit.created_by}
              createdTime={review.audit.created_at}
            />
          </Column>
          <Column>
            <ApprovedUserDetails
              createdBy={review.approved_by}
              createdTime={review.approved_at}
              rejectedBy={review.rejected_by}
              rejectedAt={review.rejected_at}
            />
          </Column>

          <Column>
            <Column.Group>
              <Column size="full">
                <TextArea
                  label="Note"
                  name="note"
                  id="ReviewManagement_ReviewItemPhotos_Note"
                  rows={2}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.note}
                  touched={touched.note}
                />
              </Column>
            </Column.Group>
          </Column>
        </Container>
      </Column.Group>
      <ReviewPhotos {...props} />
    </React.Fragment>
  );
};

export default PhotoApprovals;
