import React, { useState } from 'react';
import styled from 'styled-components';

import DayEstimatesModal from './DayEstimatesModal';
import { BoxWithHeader } from '../../../components/elements';

const Container = styled.div`
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const DayEstimates = ({ values, setFieldValue, setValues, type }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);

  const estimates = values.day_estimate;

  const removeEstimate = id => {
    setValues({
      ...values,
      day_estimate: estimates.filter(item => item.id !== id),
    });
  };

  return (
    <Container>
      <BoxWithHeader title="Specific time of the day estimates">
        {estimates.length !== 0 && (
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>
                  <abbr>Day</abbr>
                </th>
                <th>
                  <abbr>Start Time</abbr>
                </th>
                <th>
                  <abbr>End Time</abbr>
                </th>
                <th>
                  <abbr>Prep Est</abbr>
                </th>
                {type === 'delivery' && (
                  <th>
                    <abbr>Delivery Est</abbr>
                  </th>
                )}
                <th>
                  <a>Action</a>
                </th>
              </tr>
            </thead>
            <tbody>
              {estimates.map(estimate => (
                <tr>
                  <td>{estimate.day}</td>
                  <td>{estimate.start_hour}</td>
                  <td>{estimate.end_hour}</td>
                  <td>{estimate.preparation_estimate} min</td>
                  {type === 'delivery' && (
                    <td>{estimate.delivery_estimate} min</td>
                  )}
                  <td>
                    <a onClick={() => removeEstimate(estimate.id)}>
                      <i className="fas fa-trash has-text-grey" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <button
          type="button"
          className="button is-primary"
          onClick={() => setIsActiveModal(true)}
        >
          Add Day Estimates
        </button>
      </BoxWithHeader>
      <DayEstimatesModal
        type={type}
        initialsEstimates={estimates}
        isActive={isActiveModal}
        onClose={() => setIsActiveModal(false)}
        onSubmit={value => {
          setFieldValue('day_estimate', value);
          setIsActiveModal(false);
        }}
      />
    </Container>
  );
};

export default DayEstimates;
