/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Level, Table } from 'rbx';
import { toast } from 'react-toastify';

import { withRouter } from 'react-router-dom';
import { concat, isNull, isUndefined, differenceBy, first } from 'lodash';
import { useStoreState } from 'easy-peasy';

import TicketFormModal from './TicketFormModal';
import TicketList from './TicketList';
import { Input } from '../../../components/elements';

import { placeEventDefaultFeeParserForAbsorbAndPassOn } from '../../../utils/eventHelpers';

const Container = styled.div`
  &&& {
    fieldset {
      margin-right: 6rem;
    }
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
    .table td,
    .table th {
      border: 0;
    }
    th {
      color: #505050;
      font-weight: 600;
    }
  }
`;

const Text = styled.p`
  padding-left: 0.5rem;
`;

const BoldText = styled.p.attrs({
  className: 'has-text-weight-bold is-size-4',
})``;

const Tickets = ({
  helpers,
  location,
  latitude,
  longitude,
  eventId,
  eventStartDate,
  eventEndDate,
  eventStartTime,
  eventEndTime,
  totalTicketCapacity,
  fees,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [isActive, setActive] = useState(false);
  const [ticketType, setTicketType] = useState('');
  const { values, setValues, setFieldValue } = helpers.form;
  const [editableTicket, setEditableTicket] = useState();
  const [editableTicketQuantity, setEditableTicketQuantity] = useState(0);
  const [ticketSubmissionLoading, setTicketSubmissionLoading] = useState(false);

  const removeTickets = id => {
    const currentTicket = first(
      values.ticket.filter(item => item.ticket_id === id),
    );
    setValues({
      ...values,
      total_tickets:
        values.total_tickets - parseInt(currentTicket.quantity, 10),
      ticket: values.ticket.filter(item => item.ticket_id !== id),
    });
  };

  const pathName = location.pathname;
  const fetchingDefaultFee = !!(
    isUndefined(fees) ||
    isNull(fees) ||
    fees.length === 0
  );

  useEffect(() => {
    const extractTicketFee = fees.filter(
      item =>
        item.name !== 'online_passon_fee' &&
        item.name !== 'online_absorb_fee' &&
        item.name !== 'offline_passon_fee' &&
        item.name !== 'offline_absorb_fee',
    );
    if (pathName === '/add-event') {
      setFieldValue('fee', extractTicketFee);
    } else {
      setFieldValue('eventFeeForUpdate', extractTicketFee);
    }
  }, []);

  const ticketFee = placeEventDefaultFeeParserForAbsorbAndPassOn(fees);
  const eventTicketFee = fees.filter(item => item.name.startsWith('o'));
  const eventFee = differenceBy(fees, eventTicketFee, 'name');

  return (
    <Container>
      <fieldset className="box">
        <legend className="label">Event Fees</legend>
        <Table className="table is-fullwidth is-size-7-touch is-size-6-desktop is-size-5-widescreen is-size-4-fullhd">
          <Table.Head>
            <Table.Row>
              <Table.Heading>Fee Name</Table.Heading>
              <Table.Heading>Fee Display Label</Table.Heading>
              <Table.Heading>Sale Method</Table.Heading>
              <Table.Heading>Fee Type</Table.Heading>
              <Table.Heading>Fee</Table.Heading>
              <Table.Heading>Apply</Table.Heading>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {eventFee.map(fee => (
              <Table.Row>
                <Table.Cell>
                  <Input value={fee.name} disabled />
                </Table.Cell>
                <Table.Cell>
                  <Input value={fee.label} disabled />
                </Table.Cell>
                <Table.Cell>
                  <Input value={fee.sales_method} disabled />
                </Table.Cell>
                <Table.Cell>
                  <Input value={fee.fee_value_type} disabled />
                </Table.Cell>
                <Table.Cell>
                  <Input value={fee.value} disabled />
                </Table.Cell>
                <Table.Cell>
                  <Input value={fee.applies_to} disabled />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </fieldset>

      <fieldset className="box">
        <legend className="label">Event Tickets</legend>
        <Level>
          <Level.Item align="left">
            <BoldText>Quantity : </BoldText>
            <Text>{values.total_tickets} </Text>
          </Level.Item>
          <Level.Item align="left">
            <BoldText>Capacity : </BoldText>
            <Text>{values.total_capacity}</Text>
          </Level.Item>
          <Level.Item align="left">
            <BoldText>Quantity Available : </BoldText>
            <Text> {values.total_capacity - values.total_tickets}</Text>
          </Level.Item>
          <Level.Item align="right">
            <Button.Group>
              <Button
                color="primary"
                state={fetchingDefaultFee && 'loading'}
                onClick={() => {
                  if (isNull(eventStartDate) || isNull(eventEndDate)) {
                    toast.error(
                      'Please enter a start and end date then you are able to add a ticket',
                    );
                  } else if (values.total_capacity === 0) {
                    toast.error(
                      'Please add total capacity then you are able to add tickets',
                    );
                  } else if (
                    parseInt(values.total_tickets) ===
                    parseInt(values.total_capacity)
                  ) {
                    toast.error('You have reached the ticket creation limit');
                  } else {
                    setTicketType('paid');
                    setActive(true);
                    setEditableTicket();
                  }
                }}
              >
                Add Paid Tickets
              </Button>
              <Button
                state={fetchingDefaultFee && 'loading'}
                onClick={() => {
                  if (isNull(values.start) || isNull(values.end)) {
                    toast.error(
                      'Please enter a start and end date then you are able to add a ticket',
                    );
                  } else if (values.total_capacity === 0) {
                    toast.error(
                      'Please add total capacity then you are able to add tickets',
                    );
                  } else if (
                    parseInt(values.total_tickets, 10) ===
                    parseInt(values.total_capacity, 10)
                  ) {
                    toast.error('You have reached the ticket creation limit');
                  } else {
                    setTicketType('free');
                    setActive(true);
                    setEditableTicket();
                  }
                }}
                // disabled={() => {
                //   if (values.total_capacity === 0) {
                //     return false;
                //   }
                //   return (
                //     parseInt(values.total_tickets, 10) ===
                //     parseInt(values.total_capacity, 10)
                //   );
                // }}
              >
                Add Free Ticket
              </Button>
            </Button.Group>
          </Level.Item>
        </Level>
        <hr />
        <Table
          fullwidth
          className="table is-fullwidth is-size-7-touch is-size-6-desktop is-size-5-widescreen is-size-4-fullhd"
        >
          <Table.Head>
            <Table.Row>
              <Table.Heading>Ticket Type</Table.Heading>
              <Table.Heading>Ticket Name</Table.Heading>
              <Table.Heading>Quantity Available</Table.Heading>
              <Table.Heading>Price</Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading>Sale Method</Table.Heading>
              <Table.Heading>Action</Table.Heading>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {values.ticket.map((item, index) => (
              <TicketList
                form={helpers.form}
                ticket={item}
                index={index}
                removeTickets={() => removeTickets(item.ticket_id)}
                onHandleUpdate={async () => {
                  await setEditableTicket(item);
                  setTicketType(item.type);
                  setActive(true);
                  setFieldValue(
                    'total_tickets',
                    values.total_tickets - item.quantity,
                  );
                  setEditableTicketQuantity(parseInt(item.quantity, 10));
                }}
              />
            ))}
          </Table.Body>
        </Table>
      </fieldset>

      {ticketType !== '' && (
        <TicketFormModal
          userId={userId}
          ticketSubmissionLoading={ticketSubmissionLoading}
          setTicketSubmissionLoading={setTicketSubmissionLoading}
          ticketFee={ticketFee}
          totalTicketCapacity={totalTicketCapacity}
          availableQuantity={totalTicketCapacity - values.total_tickets}
          totalTickets={values.total_tickets}
          isActive={isActive}
          eventId={eventId}
          event_occurrence_id={values.event_occurrence_id}
          ticketType={ticketType}
          pathName={pathName}
          eventStartDate={eventStartDate}
          eventEndDate={eventEndDate}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          latitude={latitude}
          longitude={longitude}
          ticket={editableTicket}
          onClose={() => {
            setActive(false);
            setTicketType('');
            setFieldValue(
              'total_tickets',
              parseFloat(values.total_tickets) +
                parseFloat(editableTicketQuantity),
            );
          }}
          isSubmit={async input => {
            await helpers.push(input);
            await setActive(false);
            await setTicketType('');
            const totalTickets =
              parseInt(values.total_tickets, 10) + parseInt(input.quantity, 10);
            setFieldValue('total_tickets', totalTickets);
            setTicketSubmissionLoading(false);
          }}
          handleUpdate={async input => {
            const remainingTickets = await values.ticket.filter(
              item => item.ticket_id !== input.ticket_id,
            );
            const tickets = await concat(remainingTickets, input);
            const totalTickets =
              parseInt(values.total_tickets, 10) + parseInt(input.quantity, 10);

            await setValues({
              ...values,
              total_tickets: totalTickets,
              ticket: tickets,
            });
            await setActive(false);
            await setTicketType('');
            setTicketSubmissionLoading(false);
          }}
        />
      )}
    </Container>
  );
};

export default withRouter(Tickets);
