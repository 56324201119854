import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.primaryColor};
  margin-bottom: 1rem;
`;

const Spacing = styled.div`
  padding: 0.05rem 0rem;
`;

const Information = ({
  order,
  contactMail,
  isDraft = false,
  phoneNumber = null,
  userName = null,
}) => {
  const paymentText = () => {
    const serviceBasePaymentText =
      order.service_type === 'Dinein' ? 'COUNTER PAYMENT' : 'CASH ORDER';
    const { payment_method_type, payment_method } = order.payment;
    let text;
    if (payment_method_type) {
      if (payment_method_type === 'G_PAY') {
        text = 'CARD - GOOGLE PAY';
      }
      if (payment_method_type === 'APPLE_PAY') {
        text = 'CARD - APPLE PAY';
      }
      if (payment_method_type === 'CARD') {
        text = 'CARD';
      }
    } else {
      text = payment_method.toUpperCase();
    }
    return order.payment.payment_method.toUpperCase() === 'CASH'
      ? serviceBasePaymentText
      : text;
  };

  return (
    <Container>
      <div>
        <Spacing>
          {order.menu && <p className="is-size-3">{order.menu.name}</p>}
        </Spacing>
        <Spacing>
          <p className="is-size-4">
            {order.order_reference}, {order.overall_quantity}{' '}
            {order.overall_quantity > 1 ? 'items' : 'item'}, $
            {order.billing &&
              order.billing.order_total &&
              order.billing.order_total.toFixed(2)}
          </p>
        </Spacing>
        {order.contact && (
          <>
            <Spacing>
              <span className="is-size-4 is-capitalized">
                {order.contact.contact_name}&nbsp;
              </span>
            </Spacing>
            <Spacing>
              <span>
                {order.contact.contact_phone},&nbsp;
                {contactMail && `(${contactMail})`}
              </span>
            </Spacing>
          </>
        )}
        {isDraft && (
          <>
            {userName && (
              <Spacing>
                <span className="is-size-4 is-capitalized">
                  {userName}&nbsp;
                </span>
              </Spacing>
            )}
            <Spacing>
              <span>
                {phoneNumber && `${phoneNumber}, `}
                {contactMail && `(${contactMail})`}
              </span>
            </Spacing>
          </>
        )}
      </div>
      <div>
        <p
          className="is-size-3 has-text-weight-semibold"
          style={{ color: '#ee2a7b' }}
        >
          {order.large_order && 'LARGE ORDER | '}{' '}
          {order.payment && order.payment.payment_method && paymentText()}
        </p>
      </div>
    </Container>
  );
};
export default Information;
