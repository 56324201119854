import React from 'react';
import { Column } from 'rbx';
import { withFormik } from 'formik';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import * as yup from 'yup';
import Header from './Header';

import { Input, Select, TextArea } from '../../../components/elements';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const Container = styled.div`
  margin-left: 1rem;
`;

const Form = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  loading,
  handleSubmit,
  dirty,
}) => {
  console.log('values', values);
  return (
    <React.Fragment>
      <Header loading={loading} handleSubmit={handleSubmit} dirty={dirty} />
      <hr />
      <br />
      <Container>
        <Column.Group vcentered multiline gapSize={8}>
          <Column size={7}>
            <Input
              action="create_item_dictionary"
              label="Item Dictionary Name"
              name="name"
              id="ReviewManagement_ReviewPlace_ItemDictionaryName"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.name && touched.name && 'is-danger'}
              optionalText="(Min 3 and Max 75 Characters)"
              errors={errors.name}
              touched={touched.name}
              required
            />
          </Column>

          <Column size={4}>
            <Select
              action="create_item_dictionary"
              label="Item Dictionary Status"
              options={status}
              name="status"
              value={[{ value: values.status, label: values.status }]}
              onChange={({ value }) => setFieldValue('status', value)}
              errors={errors.status}
              touched={touched.status}
              required
            />
          </Column>
          <Column size={11}>
            <TextArea
              action="create_item_dictionary"
              label="Item Dictionary Description"
              name="description"
              id="ReviewManagement_ReviewPlace_ItemDictionaryDescription"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              optionalText="(Min 26 and Max 1200 Characters)"
              errors={errors.description}
              touched={touched.description}
            />
          </Column>
        </Column.Group>
      </Container>
      <br />
      {/* <Footer loading={loading} handleSubmit={handleSubmit} /> */}
    </React.Fragment>
  );
};

const DictionaryForm = withFormik({
  mapPropsToValues: ({ dictionary, userId }) => ({
    user_id: userId,
    item_dictionary_id: dictionary ? dictionary.item_dictionary_id : uuidv4(),
    name: dictionary ? dictionary.name : '',
    status: dictionary ? dictionary.status : 'ACTIVE',
    description: dictionary ? dictionary.description : '',
  }),
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'At least 3 Characters are Required!')
      .max(75, 'Name cannot be greater than 75 Characters'),
    description: yup
      .string()
      .min(26, 'At least 26 Characters are Required!')
      .max(1200, 'Description should not be greater than 1200 Characters!'),
    status: yup.string().required('Status is required'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'DictionaryForm',
})(Form);

export default DictionaryForm;
