import React, { useState, useEffect } from 'react';
import { Column } from 'rbx';
import gql from 'graphql-tag';
import moment from 'moment';

import { Input } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      rating
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const RejectedByUserDetails = ({ rejectedBy, rejectedAt }) => {
  const [user, setUser] = useState();
  useEffect(() => {
    client.clientPrivate
      .query({
        query: userQuery,
        variables: { userId: rejectedBy },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setUser(data.fetch_user);
        }
      });
  }, []);

  return (
    <>
      <Column size="half">
        <Input
          label="Rejected by User Log-in (Email)"
          value={user && user.email}
          disabled
        />
      </Column>
      <Column size="half">
        <Input
          label="Rejected Date and Time "
          value={rejectedAt === null ? '' : moment(rejectedAt).format('lll')}
          disabled
        />
      </Column>
    </>
  );
};

export default RejectedByUserDetails;
