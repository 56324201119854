import React from 'react';

const Information = () => (
  <div>
    <h3 className="has-text-weight-semibold is-size-2">Stop taking orders:</h3>
    <p>
      Select a menu and choose &apos;Stop talking Orders&apos; to pause orders
      until the next scheduled opening hour.
    </p>
    <br />
    <h3 className="has-text-weight-semibold is-size-2">Resume new orders:</h3>
    <p>
      If you have stopped your orders temporarily for a menu, you can take your
      place back online anytime, by choosing &apos;Resume new orders&apos;. To
      change your menu hours or stop taking orders for the whole day, go to
      &apos;Menu or Custom Hours&apos;
    </p>
  </div>
);

export default Information;
