import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import qs from 'querystringify';
import { omit } from 'lodash';
import { Input, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  status,
  sellItsOwn,
  availability,
  placeName,
  city,
  itemName,
  routeState,
  history,
  defaultPlace,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/menu-Items${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="ACTIVE"
                value={status === 'ACTIVE'}
                onChange={() => {
                  handlePush({ status: 'ACTIVE' }, 'placeName');
                }}
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="INACTIVE"
                value={status === 'INACTIVE'}
                onChange={() => {
                  handlePush({ status: 'INACTIVE' }, 'placeName');
                }}
              />
            </div>
          </Level.Item>
        </Level.Item>
        <Level.Item align="left">
          <Level.Item>
            <div>
              <Radio
                label="All Items"
                value={sellItsOwn === 'ALL'}
                onChange={() => {
                  handlePush({ sellItsOwn: 'ALL' }, 'sellItsOwn');
                }}
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="Sold on its own"
                value={sellItsOwn === 'YES'}
                onChange={() => {
                  handlePush({ sellItsOwn: 'YES' }, 'sellItsOwn');
                }}
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="Not sold on its own"
                value={sellItsOwn === 'NO'}
                onChange={() => {
                  handlePush({ sellItsOwn: 'NO' }, 'sellItsOwn');
                }}
              />
            </div>
          </Level.Item>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Availability</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="Available"
                value={availability === 'AVAILABLE'}
                onChange={() => {
                  handlePush({ availability: 'AVAILABLE' }, 'availability');
                }}
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="Unavailable"
                value={availability === 'UNAVAILABLE'}
                onChange={() => {
                  handlePush({ availability: 'UNAVAILABLE' }, 'availability');
                }}
              />
            </div>
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeName}
              onChange={event =>
                handlePush({ placeName: event.target.value }, 'placeName')
              }
              placeholder="Start entering place name..."
              key={defaultPlace}
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={data => {
                    handlePush({ city: data.name }, 'city');
                  }}
                  onHandleClear={() => {
                    handlePush({ city: '' }, 'city');
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column is-4">
            <Input
              label="Item Name"
              type="text"
              value={itemName}
              onChange={event =>
                handlePush({ itemName: event.target.value }, 'itemName')
              }
              placeholder="Start entering item Name..."
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};
export default Search;
