import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

// sort: "UPDATED_ASC",
// from: 0,
// size: 10,

// }

const searchGoogleOrderFeedQuery = gql`
  query searchGoogleOrderFeedQuery($input: SearchInput) {
    search_google_order_feed(input: $input) {
      google_order_feed_listing {
        google_order_feed_id
        place_id
        place_name
        latitude
        longitude
        address
        city
        post_code
        category
        state
        country
        telephone
        website
        service_types
      }
      total_pages
      total_size
    }
  }
`;

export const googleOrderFeeds = async ({
  sort = 'UPDATED_ASC',
  from = 0,
  size = 10,
  city,
  place,
  serviceType,
}) => {
  try {
    const google_order_feed_filter = {};
    if (place) {
      google_order_feed_filter.place_name = place;
    }
    if (city) {
      google_order_feed_filter.city = city;
    }

    if (serviceType) {
      google_order_feed_filter.service_type = serviceType;
    }

    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchGoogleOrderFeedQuery,
      variables: {
        input: {
          sort,
          from,
          size,
          filter: { google_order_feed_filter },
        },
      },
      fetchPolicy: 'network-only',
    });
    if (
      data?.data?.search_google_order_feed &&
      Array.isArray(
        data?.data?.search_google_order_feed?.google_order_feed_listing,
      )
    ) {
      return data.data.search_google_order_feed;
    }
    return [];
  } catch {
    return [];
  }
};

const uploadToSftp1Mutation = gql`
  mutation MyMutation {
    upload_to_sftp(input: { is_download: true }) {
      json_url
    }
  }
`;

export const downloadJSON = async () => {
  try {
    const {
      data: { upload_to_sftp },
    } = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: uploadToSftp1Mutation,
      variables: {},
    });
    return upload_to_sftp.json_url;
  } catch (error) {
    return error;
  }
};

const uploadToSftp2Mutation = gql`
  mutation MyMutation {
    upload_to_sftp(input: { is_download: false }) {
      json_url
    }
  }
`;

export const manualPostToGoogle = async () => {
  try {
    const {
      data: { upload_to_sftp },
    } = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: uploadToSftp2Mutation,
      variables: {},
    });
    return upload_to_sftp.json_url;
  } catch (error) {
    return error;
  }
};

// filter: { google_order_feed_filter: { status: "success" } }

const searchGoogleOrderFeedHistoryLogQuery = gql`
  query searchGoogleOrderFeedHistoryLogQuery {
    search_google_order_feed_history_log(
      input: { from: 0, size: 10, sort: "STATUS_DESC" }
    ) {
      total_pages
      total_size
      google_order_feed_history_log_listing {
        successfullyS3UploadedPath
        failedS3UploadedPath
        date
        status
      }
    }
  }
`;

const googleOrderFeedHistoryLog = async () => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchGoogleOrderFeedHistoryLogQuery,
      variables: {},
      fetchPolicy: 'network-only',
    });
    if (
      data?.data?.search_google_order_feed_history_log
        ?.google_order_feed_history_log_listing &&
      Array.isArray(
        data.data.search_google_order_feed_history_log
          .google_order_feed_history_log_listing,
      )
    ) {
      return data.data.search_google_order_feed_history_log
        .google_order_feed_history_log_listing;
    }
    return [];
  } catch {
    return [];
  }
};

const transformLogs = logs =>
  (logs ?? []).map(ele => ({
    sPath: ele.successfullyS3UploadedPath,
    fPath: ele.failedS3UploadedPath,
    date: ele.date,
    status: ele.status,
  }));

export const createGoogleFeedLogsArray = async () => {
  const logs = await googleOrderFeedHistoryLog();
  return transformLogs(logs);
};

export default {};
