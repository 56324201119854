import React, { useState } from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';

import {
  Select,
  Input,
  TextArea,
  CreatableSelectInputs,
  BoxWithHeader,
  SingleImageUpload,
} from '../../../components/elements';

import options from '../../../utils/optionsHelpers';

const ChangeWrapper = styled.div`
  padding-left: 400%;
  &&& {
    a {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const ExtraDetails = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  touched,
  dealType,
  menuName,
}) => {
  const [changeServiceUrl, setChangeServiceUrl] = useState(true);
  return (
    <>
      <Column.Group>
        <Column size="5">
          <BoxWithHeader title="Photos">
            <SingleImageUpload
              onChange={image => setFieldValue('image_url', image.url)}
            />
          </BoxWithHeader>
        </Column>
        <Column size="7">
          <Column.Group multiline gapSize={4}>
            <Column size="7">
              <Input label="Menu" value={menuName} disabled />
            </Column>
            <Column size="5">
              <Select
                label="Deal Status"
                value={{ label: values.status, value: values.status }}
                options={options.statusOptions}
                onChange={value => setFieldValue('status', value.value)}
              />
            </Column>
            <Column size="7">
              <Input
                label="Discount Type"
                name="service_type"
                value={dealType}
                disabled
                optionalText="( Max 26 characters )"
                required
              />
            </Column>

            <Column size="7">
              <Input label="Deal / Voucher URL" disabled={changeServiceUrl}>
                <ChangeWrapper>
                  <a onClick={() => setChangeServiceUrl(!changeServiceUrl)}>
                    Change
                  </a>
                </ChangeWrapper>
              </Input>
            </Column>
          </Column.Group>
        </Column>
      </Column.Group>
      <Column.Group multiline gapSize={4}>
        <Column size="full">
          <TextArea
            label="Deal / Voucher Description"
            optionalText="( Min 26 and Max 500 Characters )"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.description}
            touched={touched.description}
          />
          Characters left ({values.description.length}/500)
        </Column>
        <Column size="full">
          <CreatableSelectInputs
            label="Place Menu Deal Tags"
            onChange={value => {
              const newValue = value.map(item => item.value);
              setFieldValue('tag', newValue);
            }}
            onBlur={handleBlur}
          />
        </Column>
      </Column.Group>
    </>
  );
};

export default ExtraDetails;
