import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Column, Label } from 'rbx';
import { isNaN, trim } from 'lodash';
import swal from 'sweetalert';

import {
  Input,
  Select,
  BoxWithHeader,
  Radio,
} from '../../../components/elements';
import ReactSelectInput from '../../../components/elements/ReactSelectInput';
import { statusEnum } from '../../../utils/enums';
import ModifierItems from './ModifierItems';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BoxContainer = styled.div`
  width: 70%;
`;

const SelectionQuantity = styled.div`
  display: flex;
  justify-content: flex-end;
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const SelectItemMoreThanOnceInputWrapper = styled.div`
  width: 75px;
  margin-left: 10px;
  margin-top: -7px;
`;

const Wrapper = styled.div`
  width: 50%;
`;

const Details = ({ values, handleChange, setFieldValue, errors, touched }) => (
  <Fragment>
    <Column.Group>
      <Column size="4">
        <Input
          label="Modifier Group Name"
          optionalText="(Max 50 Characters)"
          maxLength="50"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={e => {
            setFieldValue('name', trim(e.target.value));
            setFieldValue('internal_name', trim(e.target.value));
          }}
          errors={errors.name}
          touched={touched.name}
          disableToastMessage
          required
        />
      </Column>
      <Column size="5">
        <Input
          label="Modifier Group Internal Name"
          name="internal_name"
          optionalText="(Max 50 Characters)"
          maxLength="50"
          value={values.internal_name}
          onChange={handleChange}
          onBlur={e => {
            setFieldValue('internal_name', trim(e.target.value));
          }}
          errors={errors.internal_name}
          touched={touched.internal_name}
          disableToastMessage
          required
        />
      </Column>
      <Column size="3">
        <Select
          label="Status"
          value={{ label: values.status, value: values.status }}
          options={statusEnum}
          onChange={value => setFieldValue('status', value.value)}
          required
        />
      </Column>
    </Column.Group>

    <div style={{ display: 'flex', width: '80vw' }}>
      <div style={{ width: '50%' }}>
        <Label>
          Is the Selection Optional or Required
          <span className="has-text-danger">*</span>
        </Label>
        <Flex style={{ width: '50%' }}>
          <Radio
            label="Required"
            value={values.is_required}
            onChange={() => setFieldValue('is_required', true)}
          />
          <Radio
            style={{ paddingLeft: '6px' }}
            label="Optional"
            value={!values.is_required}
            onChange={() => setFieldValue('is_required', false)}
          />
        </Flex>
      </div>
      <div style={{ width: '50%' }}>
        <Label>
          Is a Meal Deal Group
          <span className="has-text-danger">*</span>
        </Label>
        <Flex style={{ width: '25%' }}>
          <Radio
            label="Yes"
            value={values.meal_deal}
            onChange={() => {
              if (values.modifier.length !== 0) {
                setFieldValue('meal_deal', null);
                swal({
                  icon: 'warning',
                  title:
                    'You are changing the Modifier group to Meal Deal Group!',
                  text:
                    'Tap Cancel to undo the change. To go ahead with the change, tap yes and check the over-ride values of all items',
                  buttons: ['Yes', 'Cancel'],
                }).then(willDelete => {
                  if (willDelete) {
                    setFieldValue('meal_deal', false);
                  } else {
                    setFieldValue('meal_deal', true);
                  }
                });
              } else {
                setFieldValue('meal_deal', true);
              }
            }}
          />
          <Radio
            label="No"
            value={!values.meal_deal}
            onChange={() => setFieldValue('meal_deal', false)}
          />
        </Flex>
      </div>
    </div>
    <div style={{ display: 'flex', width: '80vw' }}>
      <Wrapper>
        <Label>
          Selection Quantity <span className="has-text-danger">*</span>
        </Label>
        <p className="is-size-4">
          Choose `custom selection` to enter the total number of modifiers your
          customer can select. Choose `select the range`to allow your customer
          to choose between the minimum and maximum number of items
        </p>
        <br />
      </Wrapper>

      <Label>Allow to select an item more than once</Label>
      <SelectItemMoreThanOnceInputWrapper>
        <Input
          type="number"
          step={1}
          min={1}
          max={values.selection_quantity ?? 1}
          value={values.multiply_selection_quantity}
          onChange={e => {
            if (e.target.value === '') {
              setFieldValue('multiply_selection_quantity', '');
            } else {
              const temp = parseInt(e.target.value, 10);
              if (!isNaN(temp)) {
                setFieldValue('multiply_selection_quantity', temp);
              }
            }
          }}
          disableToastMessage
          errors={errors.multiply_selection_quantity}
          touched={touched.multiply_selection_quantity}
          key={values.modifier_group_id}
        />
      </SelectItemMoreThanOnceInputWrapper>
    </div>
    <Column.Group>
      <Column size="3">
        <ReactSelectInput
          value={
            values.selection_type !== null
              ? {
                  label:
                    values.selection_type === 'RANGE'
                      ? 'Select a Range'
                      : 'Custom Selection',
                  value: values.selection_type,
                }
              : null
          }
          options={[
            { label: 'Select a Range', value: 'RANGE' },
            { label: 'Custom Selection', value: 'CUSTOM' },
          ]}
          onChange={value => {
            setFieldValue('selection_type', value.value);
            if (value.value === 'RANGE') {
              setFieldValue('selection_quantity', null);
            } else {
              setFieldValue('selection_quantity_from', null);
              setFieldValue('selection_quantity_to', null);
            }
          }}
          isDisabled={values.modifier.length === 0}
        />
      </Column>
      <Column size="3">
        {values.selection_type === 'CUSTOM' && (
          <SelectionQuantity>
            <Input
              type="number"
              step="0"
              min="1"
              value={values.selection_quantity}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('selection_quantity', '');
                } else if (!isNaN(parseInt(e.target.value, 10))) {
                  setFieldValue(
                    'selection_quantity',
                    parseInt(e.target.value, 10),
                  );
                }
              }}
              errors={errors.selection_quantity}
              touched={touched.selection_quantity}
              disableToastMessage
              disabled={values.modifier.length === 0}
              key={values.modifier_group_id}
            />
          </SelectionQuantity>
        )}
        {values.selection_type === 'RANGE' && (
          <SelectionQuantity>
            <Input
              type="number"
              step="0"
              min="1"
              value={values.selection_quantity_from}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('selection_quantity_from', '');
                } else if (!isNaN(parseInt(e.target.value, 10))) {
                  setFieldValue(
                    'selection_quantity_from',
                    parseInt(e.target.value, 10),
                  );
                }
              }}
              onBlur={() => {
                if (
                  values.selection_quantity_to ===
                  values.selection_quantity_from
                ) {
                  setFieldValue('selection_type', 'CUSTOM');
                  setFieldValue(
                    'selection_quantity',
                    values.selection_quantity_to,
                  );
                }
              }}
              errors={errors.selection_quantity_from}
              touched={touched.selection_quantity_from}
              disableToastMessage
              disabled={values.modifier.length === 0}
              key={values.modifier_group_id}
            />

            <>
              <p className="separator">to</p>
              <Input
                type="number"
                min={`${values.selection_quantity_to}`}
                value={values.selection_quantity_to}
                step="0"
                onChange={e => {
                  if (e.target.value === '') {
                    setFieldValue('selection_quantity_to', '');
                  } else if (!isNaN(parseInt(e.target.value, 10))) {
                    setFieldValue(
                      'selection_quantity_to',
                      parseInt(e.target.value, 10),
                    );
                  }
                }}
                onBlur={() => {
                  if (
                    values.selection_quantity_to ===
                    values.selection_quantity_from
                  ) {
                    setFieldValue('selection_type', 'CUSTOM');
                    setFieldValue(
                      'selection_quantity',
                      values.selection_quantity_to,
                    );
                  }
                }}
                errors={errors.selection_quantity_to}
                touched={touched.selection_quantity_to}
                disableToastMessage
                disabled={values.modifier.length === 0}
                key={values.modifier_group_id}
              />
            </>
          </SelectionQuantity>
        )}
      </Column>
    </Column.Group>
    <BoxWithHeader title="Modifier Items">
      <BoxContainer>
        <ModifierItems values={values} setFieldValue={setFieldValue} />
      </BoxContainer>
    </BoxWithHeader>
  </Fragment>
);

export default Details;
