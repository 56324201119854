import React from 'react';
import AdjustPriceForm from './AdjustPriceForm';

const Orders = ({
  order,
  isActiveAdjustPriceModal,
  setActiveAdjustPriceModal,
  handleAdjustOrderPrice,
}) => (
  <AdjustPriceForm
    order={order}
    isActive={isActiveAdjustPriceModal}
    handleAdjustOrderPrice={handleAdjustOrderPrice}
    onClose={() => setActiveAdjustPriceModal(false)}
  />
);
export default Orders;
