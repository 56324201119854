import React from 'react';
import styled from 'styled-components';
import { Column, Level, Table } from 'rbx';
// import { Query } from 'react-apollo';
// import { isNull } from 'lodash';
import gql from 'graphql-tag';
import {
  Input,
  Table as CustomTable,
  CustomInput,
  CustomSelect,
  Loading,
  Pagination,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_place_claim(input: $input) {
      place_id
      claimant_id
      name
      city
      status
      claim_status
    }
  }
`;

const Container = styled.div``;

const Summary = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  createForm,
  props,
}) => (
  <React.Fragment>
    <Level>
      <Column size={3}>
        <Input label="Total Reviews" placeholder="600" disabled />
      </Column>
      <Column size={3}>
        <Input label="Average Rating" placeholder="4.22" disabled />
      </Column>
      {/* <Column size={3}>
        <Input label="User Images" placeholder="22" disabled />
      </Column>
      <Column size={3}>
        <Input label="User Flags" placeholder="60" disabled />
      </Column> */}
    </Level>
    {/* <Pagination /> */}
    <CustomTable>
      <Table.Head>
        <Table.Row>
          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Item Name
              </span>
              <br />
              {/* <CustomInput /> */}
            </div>
          </Table.Heading>
          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Place Name
              </span>
              <br />
              {/* <CustomInput /> */}
            </div>
          </Table.Heading>
          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> City / Suburb
              </span>
              <br />
              {/* <CustomInput /> */}
            </div>
          </Table.Heading>
          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Reviewed On
              </span>
              <br />
            </div>
          </Table.Heading>
          {/* <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Reviews
              </span>
            </div>
          </Table.Heading> */}
          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Rating
              </span>
            </div>
          </Table.Heading>
          {/* <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Photos
              </span>
            </div>
          </Table.Heading>

          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Likes
              </span>
            </div>
          </Table.Heading>

          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Flags
              </span>
            </div>
          </Table.Heading>

          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> Comments
              </span>
            </div>
          </Table.Heading> */}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell>David</Table.Cell>
          <Table.Cell>Raj_mallampati@hotmail.com</Table.Cell>
          <Table.Cell>05/06/2018 12:29:21 AM</Table.Cell>
          <Table.Cell>3</Table.Cell>
          <Table.Cell>4</Table.Cell>
          {/* <Table.Cell>2</Table.Cell>
          <Table.Cell>10</Table.Cell>
          <Table.Cell>1</Table.Cell>
          <Table.Cell>3</Table.Cell> */}
        </Table.Row>
      </Table.Body>
    </CustomTable>
    {/* <Query
        query={placesQuery}
        variables={{ input: { filter: { place_filter: {} } } }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }

          const { search_place_claim } = data;
          if (isNull(search_place_claim)) {
            return 'no data found';
          }

          return (
            <tbody>
              {search_place_claim.map(claim => (
                <ListItem claim={claim} />
              ))}
            </tbody>
          );
        }}
      </Query> 
   */}
  </React.Fragment>
);

export default Summary;
