import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
// import Header from './Header';

import { Input } from '../../../components/elements';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const PlaceDashboard = ({
  activity,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  createForm,
}) => (
  <Container>
    <fieldset className="box">
      <legend className="label">Place</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Been There " placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Like It " placeholder="60" disabled />
        </Column>

        <Column size={3}>
          <Input label="Shares " placeholder="40" disabled />
        </Column>
        <Column size={3}>
          <Input label="Bookmarks" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Followers" value={activity.followers} disabled />
        </Column>
        <Column size={3}>
          <Input label="User Uploaded Photos" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Admin Uploaded  Photos" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Vendor Uploaded  Photos" placeholder="60" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Place Feed</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Likes" disabled />
        </Column>
        <Column size={3}>
          <Input label="Shares" disabled />
        </Column>
        <Column size={3}>
          <Input
            label="Total Feeds"
            value={activity.feeds.feeds_total}
            placeholder="60"
            disabled
          />
        </Column>
        <Column size={3}>
          <Input
            label="Total Feeds Flagged"
            value={activity.feeds.feeds_flagged}
            disabled
          />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Place Review</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Likes" disabled />
        </Column>
        <Column size={3}>
          <Input label="Shares" disabled />
        </Column>
        <Column size={3}>
          <Input label="Total Reviews" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Unique Reviewers" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Review Rating" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Like Percentage" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="User Uploaded Photos" placeholder="600" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Place Deals</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input
            label="Total Deals"
            value={activity.deals.deals_total}
            // placeholder="60"
            disabled
          />
        </Column>
        <Column size={3}>
          <Input
            label="Active Deals"
            value={activity.deals.deals_active}
            placeholder="600"
            disabled
          />
        </Column>
        <Column size={3}>
          <Input
            label="Past Deals"
            value={activity.deals.deals_past}
            disabled
          />
        </Column>
        <Column size={3}>
          <Input
            label="Future Deals"
            value={activity.deals.deals_future}
            disabled
          />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Events</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Total Events" placeholder="60" disabled />
        </Column>

        <Column size={3}>
          <Input label="Active Events" placeholder="60" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Item</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label=" Tried" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label=" Love It" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label=" Shares" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label=" Bookmarks" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="User Uploaded Photos" placeholder="600" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Item Review</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label=" Likes" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label=" Shares" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Total Reviewers" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Unique Reviewers" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Review Rating" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Love Percentage" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="User Uploaded Photos" placeholder="600" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Page Views" placeholder="60" disabled />
        </Column>

        <Column size={3}>
          <Input label="Total Deal Views" placeholder="600" disabled />
        </Column>

        <Column size={3}>
          <Input label="Total Menu Views" placeholder="60" disabled />
        </Column>

        <Column size={3}>
          <Input label="Mobile Calls" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Directions and Map Views" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Menu and Bookmark Count" placeholder="600" disabled />
        </Column>
      </Column.Group>
    </fieldset>
  </Container>
);
export default PlaceDashboard;
