import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <nav className="level">
    <div className="level-left">
      <div className="level-item">
        <p className="title is-4">Vendors</p>
      </div>
    </div>

    <div className="level-right">
      <div className="level-item">
        <Link to="/vendors/add-vendor" className="button is-primary">
          <span className="icon">
            <i className="far fa-plus-square" />
          </span>
          <span>Add Vendor</span>
        </Link>
      </div>
    </div>
  </nav>
);

export default Header;
