import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/global/Layout';

const Container = styled.section`
  height: 90vh;
`;

const NotFound = ({ history }) => (
  <Layout>
    <Container className="hero">not found</Container>
  </Layout>
);

export default NotFound;
