import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchMenuOrdersQuery = gql`
  query searchMenuOrdersQuery($userId: String, $menuOrderId: String) {
    search_menu_orders(
      input: {
        user_id: $userId
        filter: { menu_order_filter: { menu_order_id: $menuOrderId } }
      }
    ) {
      menu_order_listing {
        complete {
          created_at
          created_by
        }
        order_reference
        refunds {
          created_at
          created_by
          note {
            date
            type
            value
          }
          refund_amount
          refund_application_fee
          refund_from_place
          refund_id
          refund_reason
          status
        }
        billing {
          order_amount
          order_total
        }
        created_by_user {
          display_name
          email
          contact {
            display
            display_order
            value
            is_primary
            type
          }
        }
        place {
          name
        }
      }
    }
  }
`;

export const fetchOrder = async ({ userId, menuOrderId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchMenuOrdersQuery,
      variables: {
        userId,
        menuOrderId,
      },
      fetchPolicy: 'network-only',
    });
    if (
      Array.isArray(data?.data?.search_menu_orders?.menu_order_listing) &&
      data.data.search_menu_orders.menu_order_listing[0]
    ) {
      return data.data.search_menu_orders.menu_order_listing[0];
    }
    return null;
  } catch {
    return null;
  }
};

const actionMenuOrderMutation = gql`
  mutation actionMenuOrderMutation($input: NavigateMenuOrderInput) {
    action_menu_order(input: $input) {
      menu_order_id
      error {
        description
      }
    }
  }
`;

export const updateOrderWithRefund = async ({ input }) => {
  try {
    const {
      data: { action_menu_order },
    } = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: actionMenuOrderMutation,
      variables: { input },
    });
    return (action_menu_order?.error ?? [])[0]?.description;
  } catch (error) {
    return error;
  }
};

export default {};
