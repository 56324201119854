import React from 'react';
import styled from 'styled-components';
import { Field, Label, Control } from 'rbx';
import ReactSelectInput from './ReactSelectInput';
import InputErrorMessage from './InputErrorMessage';
import { ACLSConsumer } from '../../utils/aclsContext';
import Can from '../../utils/Can';

const OptionalText = styled.p`
  &&& {
    font-size: 0.8rem;
    padding-top: 2px;
    padding-left: 4px;
    color: #737373;
  }
`;

const MySelect = ({
  label,
  value,
  options,
  optionalText,
  required,
  optionalText2,
  onChange,
  errors,
  touched,
  disableToastMessage,
  action,
  applyZIndex = false,
  ...props
}) => (
  <Field style={applyZIndex ? { position: 'relative', zIndex: 8 } : {}}>
    <Label className="is-flex">
      {label}
      {required && <span className="has-text-danger">*</span>}
      {optionalText && <OptionalText>{optionalText}</OptionalText>}
    </Label>
    <Control>
      {action ? (
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={action}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <ReactSelectInput
                  // defaultValue={value}
                  value={value}
                  options={options}
                  onChange={onChange}
                  isDisabled={props.disabled}
                  {...props}
                />
              )}
              no={() => (
                <ReactSelectInput
                  // defaultValue={value}
                  value={value}
                  options={options}
                  onChange={onChange}
                  isDisabled
                  {...props}
                />
              )}
            />
          )}
        </ACLSConsumer>
      ) : (
        <ReactSelectInput
          // defaultValue={value}
          value={value}
          options={options}
          onChange={onChange}
          isDisabled={props.disabled}
          {...props}
        />
      )}

      {optionalText2 && <OptionalText>{optionalText2}</OptionalText>}
      <InputErrorMessage
        errors={errors}
        touched={touched}
        disableToastMessage={disableToastMessage}
      />
    </Control>
  </Field>
);

export default MySelect;
