/* eslint-disable func-names */
import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import {
  Input,
  Select,
  InputErrorMessage,
  CheckBoxVariant2,
} from '../../../components/elements';
import ServiceTypeInput from './ServiceTypeInput';

yup.addMethod(yup.string, 'testNonNegativeValue', function(errorMessage) {
  return this.test(`test-non-negative-value`, errorMessage, function(value) {
    const { path, createError } = this;
    const num = +value;

    return num >= 0 || createError({ path, message: errorMessage });
  });
});

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 80vh;
      padding: 0rem 1rem;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const FeeCheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 35px;
  top: 30px;
`;

const Form = props => {
  const {
    isActive,
    onClose,
    setFieldValue,
    values,
    handleSubmit,
    loading,
    fee,
    touched,
    errors,
  } = props;

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Fee</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body">
          <Column.Group gapSize={8} multiline vcentered>
            <Column size="half">
              <ServiceTypeInput
                label="Service Type"
                value={values.service_type_id}
                onChange={value => setFieldValue(`service_type_id`, value)}
                required
                isDisabled={fee}
              />
              <InputErrorMessage
                errors={errors.service_type_id}
                touched={touched.service_type_id}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              <Input
                label="Fee Name"
                value={values.name}
                onChange={event => setFieldValue(`name`, event.target.value)}
                required
                disabled={fee}
                errors={errors.name}
                touched={touched.name}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              <Input
                label="Display Fee Label"
                value={values.display}
                onChange={event => setFieldValue(`display`, event.target.value)}
                required
                errors={errors.display}
                touched={touched.display}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              <Select
                label="Sale Method"
                options={[
                  { label: 'ONLINE', value: 'ONLINE' },
                  { label: 'OFFLINE', value: 'OFFLINE' },
                ]}
                value={[values.sale_method]}
                onChange={value => setFieldValue(`sale_method`, value)}
                required
                // disabled={fee}
                errors={errors.sale_method}
                touched={touched.sale_method}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              <Select
                label="Fee Type"
                options={[
                  { label: 'PERCENTAGE', value: 'PERCENTAGE' },
                  { label: 'PRICE', value: 'PRICE' },
                ]}
                value={[values.type]}
                onChange={value => setFieldValue(`type`, value)}
                required
                // disabled={fee}
                errors={errors.type}
                touched={touched.type}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              <Input
                label="Fee"
                value={values.value}
                type="number"
                min={0}
                onChange={event => {
                  if (
                    event.target.value &&
                    parseFloat(event.target.value) >= 0 &&
                    parseFloat(event.target.value) <= 99999
                  ) {
                    setFieldValue(`value`, event.target.value);
                  } else if (event.target.value.length === 0) {
                    setFieldValue(`value`, null);
                  }
                }}
                onBlur={event =>
                  setFieldValue(
                    `value`,
                    parseFloat(event.target.value).toFixed(2),
                  )
                }
                required
                errors={errors.value}
                touched={touched.value}
                disableToastMessage
              />
            </Column>
            <Column size="half">
              <Select
                label="Applies To"
                options={[
                  { label: 'ORDER_TOTAL', value: 'ORDER_TOTAL' },
                  { label: 'SUB_TOTAL', value: 'SUB_TOTAL' },
                  { label: 'ORDER_BILLING', value: 'ORDER_BILLING' },
                ]}
                value={[values.applies_to]}
                onChange={value => setFieldValue(`applies_to`, value)}
                required
                // disabled={fee}
                errors={errors.applies_to}
                touched={touched.applies_to}
                disableToastMessage
              />
            </Column>

            <FeeCheckBoxContainer>
              {(values.applies_to ?? {}).value === 'ORDER_BILLING' && (
                <div style={{ marginBottom: '25px' }}>
                  <CheckBoxVariant2
                    secondary
                    label="Is Payment Gateway Fee"
                    name="is_payment_gateway_fee"
                    value={values.is_payment_gateway_fee}
                    onChange={value => {
                      if (value) {
                        setFieldValue('is_kravein_charge', false);
                      } else {
                        setFieldValue('is_kravein_charge', true);
                      }
                      setFieldValue('is_payment_gateway_fee', value);
                    }}
                  />
                </div>
              )}
              {(values.applies_to ?? {}).value === 'ORDER_BILLING' && (
                <div style={{ marginBottom: '25px' }}>
                  <CheckBoxVariant2
                    secondary
                    label="Is Kravein Fee"
                    name="is_kravein_charge"
                    value={values.is_kravein_charge}
                    disabled={values.is_payment_gateway_fee}
                    onChange={value => {
                      // if (value) {
                      //   setFieldValue('is_payment_gateway_fee', false);
                      // } else {
                      //   setFieldValue('is_payment_gateway_fee', true);
                      // }

                      setFieldValue('is_kravein_charge', value);
                    }}
                  />
                </div>
              )}
            </FeeCheckBoxContainer>
          </Column.Group>
        </section>

        <footer className="modal-card-foot">
          <button type="button" className="button" onClick={onClose}>
            cancel
          </button>
          <button
            type="button"
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </footer>
      </div>
    </Container>
  );
};

const AddEditForm = withFormik({
  mapPropsToValues: ({ fee }) => ({
    service_type_id: fee
      ? { label: fee.service_type_name, value: fee.service_type_id }
      : null,
    name: fee ? fee.name : null,
    display: fee ? fee.display : null,
    sale_method: fee
      ? {
          label: fee.sale_method,
          value: fee.sale_method,
        }
      : null,
    applies_to: fee
      ? {
          label: fee.applies_to,
          value: fee.applies_to,
        }
      : null,
    type: fee
      ? {
          label: fee.type,
          value: fee.type,
        }
      : null,
    value: fee ? fee.value : null,
    status: fee ? fee.status : null,
    is_payment_gateway_fee:
      fee &&
      (fee.is_payment_gateway_fee === true ||
        fee.is_payment_gateway_fee === false)
        ? fee.is_payment_gateway_fee
        : false,
    is_kravein_charge:
      fee && (fee.is_kravein_charge === true || fee.is_kravein_charge === false)
        ? fee.is_kravein_charge
        : true,
  }),

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .nullable('Fee Name is required!')
      .min(3, 'Please enter at least 3 characters')
      .max(20, 'Please enter less than 20 characters')
      .required('Fee Name is required!'),
    service_type_id: yup
      .object()
      .nullable('Service type is required!')
      .required('Service type is required!'),
    sale_method: yup
      .object()
      .nullable('Sale Method is required!')
      .required('Sale Method is required!'),
    type: yup
      .object()
      .nullable('Fee type is required!')
      .required('Fee type is required!'),
    applies_to: yup
      .object()
      .nullable('Applies to is required!')
      .required('Applies to  is required!'),
    display: yup
      .string()
      .nullable('Display Fee Name is required!')
      .min(3, 'Please enter at least 3 characters')
      .max(20, 'Please enter less than 20 characters')
      .required('Display Fee Name is required!'),
    value: yup
      .string()
      .nullable('Fee is required!')
      .required('Fee is required!')
      .testNonNegativeValue('Fee should not be negative value'),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit({
      service_type_id: values.service_type_id.value,
      name: values.name,
      display: values.display,
      sale_method: values.sale_method.value,
      applies_to: values.applies_to.value,
      type: values.type.value,
      value: values.value,
      status: values.status,
      is_payment_gateway_fee: values.is_payment_gateway_fee,
      is_kravein_charge: values.is_kravein_charge,
    });
  },
  enableReinitialize: true,
  displayName: 'AddPlaceForm',
})(Form);

export default AddEditForm;
