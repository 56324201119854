import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid';
import { Label } from 'rbx';
import swal from 'sweetalert';

import TbButton from './TbButton';

import TableCapacityModal from './TableCapacityModal';
import TagsModal from './TagsModal';

const Container = styled.div`
  padding: 1rem;
  width: 75%;
`;

const TableListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
`;

const EditText = styled.span`
  color: #00aeef;
  padding-right: 12px;
`;

const ContentContainer = styled.div`
  width: 400px;
  padding: 1rem;
  cursor: pointer;
`;

const TableTagListItem = ({
  // item,
  label = 'Table 01 - 4 Pax',
  onEdit = () => {},
  onDelete = () => {},
  isProtected = false,
}) => (
  <TableListContainer>
    <Label>{label}</Label>
    {!isProtected && (
      <div>
        <EditText onClick={onEdit}>Edit</EditText>
        <EditText onClick={onDelete}>Delete</EditText>
      </div>
    )}
  </TableListContainer>
);

const TagsTable = ({ values, setFieldValue }) => {
  const [
    tableCapacityModalIsActive,
    setTableCapacityModalIsActive,
  ] = React.useState(false);

  const [tagsModalIsActive, setTagsModalIsActive] = React.useState(false);
  const [tagsInitialValue, setTagsInitialValue] = React.useState();
  const [tableInitialValue, setTableInitialValue] = React.useState();

  return (
    <>
      {tableCapacityModalIsActive && (
        <TableCapacityModal
          isActiveModal={tableCapacityModalIsActive}
          onClose={() => setTableCapacityModalIsActive(false)}
          initialValues={tableInitialValue}
          onSubmitHandlerFromParent={({
            table_name,
            table_capacity,
            table_number,
          }) => {
            const t = [...values.table_details];
            if (tableInitialValue) {
              const idx = t.findIndex(ele => ele.id === tableInitialValue.id);
              t[idx].table_capacity = table_capacity;
              t[idx].table_name = table_name;
              t[idx].table_number = table_number;
            } else {
              t.push({
                table_capacity,
                table_name,
                table_number,
                id: uuid(),
              });
            }
            setFieldValue('table_details', t);
          }}
        />
      )}
      {tagsModalIsActive && (
        <TagsModal
          isActiveModal={tagsModalIsActive}
          onClose={() => setTagsModalIsActive(false)}
          initialValues={tagsInitialValue}
          onSubmitHandlerFromParent={({ tagName }) => {
            const t = [...values.tag];
            if (tagsInitialValue) {
              const idx = t.findIndex(
                ele => ele.name === tagsInitialValue.tag_name,
              );
              t[idx].name = tagName;
            } else {
              t.push({ name: tagName, id: uuid() });
            }
            setFieldValue('tag', t);
          }}
        />
      )}

      <Container>
        <TbButton
          onClick={() => {
            setTableCapacityModalIsActive(true);
            setTableInitialValue();
          }}
          innerText="Add a Table"
        />
        <ContentContainer>
          {values.table_details.map(ele => (
            <TableTagListItem
              key={ele.id}
              label={`${ele.table_name}-${ele.table_number}-${ele.table_capacity}`}
              onEdit={() => {
                setTableInitialValue({
                  id: ele.id,
                  table_name: ele.table_name,
                  table_capacity: ele.table_capacity,
                  table_number: ele.table_number,
                });
                setTableCapacityModalIsActive(true);
              }}
              onDelete={() => {
                swal({
                  text: 'Are you sure to delete this Table?',
                  buttons: ['Cancel', 'OK'],
                }).then(res => {
                  if (res) {
                    let t = [...values.table_details];
                    t = t.filter(ele2 => ele2.id !== ele.id);
                    setFieldValue('table_details', t);
                  }
                });
              }}
            />
          ))}
        </ContentContainer>
        <TbButton
          onClick={() => {
            setTagsModalIsActive(true);
            setTagsInitialValue();
          }}
          innerText="Add a Tag"
        />

        <ContentContainer>
          {values.tag.map(ele => (
            <TableTagListItem
              isProtected={ele.isProtected}
              key={ele.id}
              label={ele.name}
              onEdit={() => {
                setTagsInitialValue({ tag_name: ele.name });
                setTagsModalIsActive(true);
              }}
              onDelete={() => {
                swal({
                  text: 'Are you sure to delete this tag?',
                  buttons: ['Cancel', 'OK'],
                }).then(res => {
                  if (res) {
                    let t = [...values.tag];
                    t = t.filter(ele2 => ele2.name !== ele.name);
                    setFieldValue('tag', t);
                  }
                });
              }}
            />
          ))}
        </ContentContainer>
      </Container>
    </>
  );
};

export default TagsTable;
