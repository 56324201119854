import React from 'react';
import { first } from 'lodash';
import moment from 'moment';

const LikeList = ({ social, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === social.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <React.Fragment>
      <td>
        {user ? (
          <React.Fragment>
            <p>{user.full_name}</p>
          </React.Fragment>
        ) : (
          social.audit.updated_by
        )}
      </td>
      <td>
        {user ? (
          <React.Fragment>
            <span>
              <p>{user.email}</p>
            </span>
          </React.Fragment>
        ) : (
          social.audit.updated_by
        )}
      </td>
      <td> {moment(social.updated_at).format('LLL')} </td>
    </React.Fragment>
  );
};
export default LikeList;
