import React from 'react';
import { Field, Control, Icon } from 'rbx';
import { Input } from '../../../../components/elements';

const PriceInput = ({ value }) => (
  <Field>
    <Control iconLeft>
      <Input value={parseFloat(value).toFixed(2)} disabled />
      <Icon align="left">$</Icon>
    </Control>
  </Field>
);

export default PriceInput;
