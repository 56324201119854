import React from 'react';
import { Tab } from 'rbx';

import { Tabs } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import Header from './Header';

import TemplateBody from '../EmailNotification/TemplateBody';
import TestEmailTemplate from '../EmailNotification/TestEmailTemplate';

const CreateSesTemplate = props => {
  const [activeTab, setActiveTab] = React.useState('tab1');
  return (
    <Layout>
      <Header showSubmit={activeTab === 'tab1'} history={props.history} />
      <Tabs className="tabs is-medium is-fullwidth">
        <Tab
          onClick={() => {
            setActiveTab('tab1');
          }}
          active={activeTab === 'tab1'}
          style={{ cursor: activeTab === 'tab1' ? 'text' : 'pointer' }}
        >
          Template Body
        </Tab>
        <Tab
          onClick={() => {
            setActiveTab('tab2');
          }}
          active={activeTab === 'tab2'}
          style={{ cursor: activeTab === 'tab2' ? 'text' : 'pointer' }}
        >
          Test Email Template
        </Tab>
      </Tabs>

      {activeTab === 'tab1' && <TemplateBody notificationTemplateId="" />}

      {activeTab === 'tab2' && <TestEmailTemplate notificationTemplateId="" />}
    </Layout>
  );
};

export default CreateSesTemplate;
