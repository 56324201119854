import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import client from '../../../utils/apolloClient';

const deleteCustomHourMutation = gql`
  mutation deleteCustomHourMutation($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const Delete = ({
  hours,
  placeName,
  onHandleComplete = () => {},
  children,
  menuId,
}) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteCustomHourMutation}
      onCompleted={({ update_menu }) => {
        if (!isNull(update_menu[0].error)) {
          update_menu[0].error.map(item => toast.error(item.description));
        } else {
          onHandleComplete();
          toast.success('Custom Hour deleted successfully!');
        }
      }}
    >
      {(delete_custom_hour, { loading }) => (
        <button
          type="button"
          className={`button ${loading && 'is-loading'}`}
          onClick={() => {
            swal({
              title: `Are you sure you would like to remove the custom hours for ${placeName}?`,
              // eslint-disable-next-line max-len
              text: '',
              buttons: ['Cancel', 'Yes'],
              dangerMode: true,
            }).then(willDelete => {
              if (willDelete) {
                const custom_hour = [
                  ...(hours.selectedMenu.custom_hour ?? [])
                    .filter(ele => ele.date !== hours.date)
                    .map(ele => {
                      const { option, ...rest } = ele;
                      const option2 = option.map(ele2 =>
                        omit(ele2, '__typename'),
                      );

                      return {
                        ...omit(rest, '__typename'),
                        option: option2,
                      };
                    }),
                ];

                delete_custom_hour({
                  variables: {
                    input: {
                      user_id: userId,
                      menu_id: menuId,
                      custom_hour,
                    },
                  },
                });
              }
            });
          }}
        >
          {children}
        </button>
      )}
    </Mutation>
  );
};

export default Delete;
