/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Tab, Column, Field, Label } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import uuidv4 from 'uuid/v4';
import { isNull, omit, isNil } from 'lodash';
import {
  getServiceTypeSettings,
  defaultHours,
  addIdInputHours,
  parseFinalInputHours,
  getDisplayMenuHours,
} from '../helper';
import {
  placeBusinessHoursHandler,
  menuHourSorter,
} from '../../../../utils/helpers';
import {
  Box,
  Tabs,
  Select,
  Input,
  // InputErrorMessage,
} from '../../../../components/elements';

import Header from './Header';
import Hours from '../Hours';

import QrCodes from '../QRCodes';
import WeeklyHours from '../WeeklyHours';
import MenuOpenClose from '../MenuOpenClose';

import { config } from '../../../../utils/config';

import MenuDesigner from './MenuDesigner';
import TimeBasedCategory from './TimeBasedCategory';

import Toggle from './Toggle';

import { fetchMenuListingAndSetState } from './helpers';
import MenuPageHeader from '../../../../components/MenuPageHeader';
import OtherSettings from './OtherSettings';

import ChargesFees from './ChargesFees';
import CateringMenuSetting from './CateringMenuSetting';

const Container = styled.div`
  padding: 0.15rem 1rem;
`;

const Form = props => {
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveHourModal, setIsActiveHourModal] = useState(false);
  const [tab, setTab] = useState(0);
  const formContainerRef = useRef();

  const {
    loading,
    // dirty,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    menu,
    isEdit = false,
    openCloseStatus,
    isAddMenu = false,
    // setErrors,
    placeLocation,
    serviceTypeSettingId,
    setMenuListing,
    userId,
    onToggle,
  } = props;

  console.log('values', values);

  const [placeData, setPlaceData] = useState(props?.place);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getServiceTypeSettings({ placeId: values.place_id });
      setIsLoading(false);
      const newData = data.map(item => ({
        ...item,
        value: item.service_type_setting_id,
        label: item.service_type,
      }));

      setSettings(newData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchMenuListingAndSetState({
      userId,
      serviceTypeSettingId,
      setMenuListing,
    });
  }, []);

  useEffect(() => {
    const onEnterKeyPress = e => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    };

    if (formContainerRef.current) {
      console.log('adding keydown eventlistener...');
      formContainerRef.current.addEventListener('keydown', onEnterKeyPress);
    }

    return () => {
      console.log('removing keydown eventlistener...');
      formContainerRef.current.removeEventListener('keydown', onEnterKeyPress);
    };
  }, [formContainerRef]);

  return (
    <form
      ref={formContainerRef}
      onSubmit={event => {
        event.preventDefault();

        if (
          values.isCustom &&
          values.display_menu_hours &&
          values.hours === defaultHours
        ) {
          toast.error('Hours is required, Please put atleast one day hours ');
        } else if (!values.isCustom && values.hours === defaultHours) {
          toast.error('Hours is required, Please put atleast one day hours ');
        } else {
          handleSubmit();
        }
      }}
    >
      <Header
        loading={loading}
        // dirty={dirty}
        menuName={values.name}
        orderFoodSlug={
          props.menu &&
          props.menu.menu_id &&
          props.menu.place_slug &&
          props.menu.service_type_setting &&
          props.menu.service_type_setting.service_type
            ? `order-food/${props.menu.service_type_setting.service_type}/${props.menu.place_slug}?menuId=${props.menu.menu_id}`
            : ''
        }
      >
        <MenuPageHeader
          platform={values.platform}
          placeData={placeData}
          setPlaceData={setPlaceData}
          placeId={values.place_id}
        />
      </Header>
      <Box>
        <Tabs className="tabs is-medium">
          <Tab active={tab === 0} onClick={() => setTab(0)}>
            Menu
          </Tab>
          <Tab active={tab === 1} onClick={() => setTab(1)}>
            Menu Hours
          </Tab>
          <Tab active={tab === 2} onClick={() => setTab(2)}>
            QR Codes
          </Tab>
          {!isAddMenu && (
            <Tab active={tab === 3} onClick={() => setTab(3)}>
              Open / Closed Status
            </Tab>
          )}
          {!isAddMenu && (
            <Tab active={tab === 4} onClick={() => setTab(4)}>
              Charges / Fees
            </Tab>
          )}
          {!isAddMenu && (
            <Tab active={tab === 5} onClick={() => setTab(5)}>
              Time Based Category
            </Tab>
          )}
          {!isAddMenu && (
            <Tab active={tab === 6} onClick={() => setTab(6)}>
              Other Settings
            </Tab>
          )}
          {!isAddMenu && (
            <Tab active={tab === 7} onClick={() => setTab(7)}>
              Catering Settings
            </Tab>
          )}
        </Tabs>
        {tab === 0 && (
          <>
            <Container>
              <Column.Group multiline gapSize={8}>
                <Column size="4">
                  <Input
                    label="Menu Name"
                    id="MenuDesigner_Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    optionalText="( Max 26 characters )"
                    maxLength="26"
                    required
                    errors={errors.name}
                    touched={touched.name}
                    disableToastMessage
                  />
                </Column>
                <Column size="4">
                  <Select
                    label="Service Type"
                    name="service_type_setting_id"
                    options={settings}
                    value={settings.filter(
                      item => item.value === values.service_type_setting_id,
                    )}
                    onChange={value => {
                      setFieldValue('service_type_setting_id', value.value);
                      setFieldValue('isCustom', value.is_custom_service);
                      if (value.is_custom_service) {
                        getDisplayMenuHours({
                          service_type_setting_id: value.value,
                        }).then(data => {
                          if (!isNull(data)) {
                            setFieldValue('display_menu_hours', data);
                          }
                        });
                      }
                    }}
                    disableToastMessage
                    errors={errors.service_type_setting_id}
                    touched={touched.service_type_setting_id}
                    required
                    isLoading={isLoading}
                    disabled={isEdit}
                  />
                </Column>
                <Column size="4">
                  <Select
                    label="Menu Status"
                    options={[
                      { value: 'ACTIVE', label: 'ACTIVE' },
                      { value: 'INACTIVE', label: 'INACTIVE' },
                    ]}
                    value={[
                      {
                        value: values.status,
                        label: values.status,
                      },
                    ]}
                    onChange={value => setFieldValue('status', value.value)}
                    disableToastMessage
                    required
                  />
                </Column>
              </Column.Group>
              {!!placeData && (
                <Field>
                  <Label>Publish Menu</Label>
                  <Toggle
                    checked={values.publish_menu}
                    onToggle={value => {
                      setFieldValue('publish_menu', value);
                      onToggle({
                        menu_id: values.menu_id,
                        user_id: values.user_id,
                        place_id: values.place_id,
                        publish_menu: value,
                      });
                    }}
                    disabled={values.status === 'INACTIVE'}
                  />
                </Field>
              )}
              <br />
            </Container>
            <MenuDesigner
              menuId={values.menu_id}
              placeId={values.place_id}
              settingId={values.service_type_setting_id}
              placeLocation={placeLocation}
            />
          </>
        )}
        {tab === 1 && (
          <Container>
            <Column.Group>
              <Column size="6">
                {isActiveHourModal && (
                  <Hours
                    serviceType={settings.filter(
                      item => item.value === values.service_type_setting_id,
                    )}
                    isActive={isActiveHourModal}
                    onClose={() => {
                      setIsActiveHourModal(false);
                    }}
                    {...props}
                  />
                )}
                <WeeklyHours
                  hours={values.hours}
                  onClick={() => setIsActiveHourModal(true)}
                />
                {/* <InputErrorMessage
                  errors={errors.hours}
                  touched={errors.hours}
                  setErrors={setErrors}
                /> */}
              </Column>
            </Column.Group>
          </Container>
        )}

        {tab === 2 && menu && (
          <QrCodes
            menuName={values.name}
            placeName={menu.place_name}
            // eslint-disable-next-line max-len
            qrCodeValue={`${config.customerDomain}/order-food/${menu.service_type_setting.service_type}/${menu.place_slug}?menuId=${values.menu_id}`}
          />
        )}
        {!isAddMenu && tab === 3 && menu && (
          <MenuOpenClose
            setFieldValue={setFieldValue}
            values={values}
            openCloseStatus={openCloseStatus}
          />
        )}
        {!isAddMenu && tab === 4 && menu && (
          <ChargesFees menuId={values.menu_id} placeId={values.place_id} />
        )}
        {!isAddMenu && tab === 5 && menu && (
          <TimeBasedCategory
            menuName={values.name}
            user_id={values.user_id}
            menu_id={values.menu_id}
            place_id={values.place_id}
            serviceType={settings.filter(
              item => item.value === values.service_type_setting_id,
            )}
          />
        )}
        {!isAddMenu && tab === 6 && menu && <OtherSettings values={values} />}
        {!isAddMenu && tab === 7 && menu && (
          <CateringMenuSetting
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            tab={tab}
          />
        )}
      </Box>
    </form>
  );
};

const MenuForm = withFormik({
  mapPropsToValues: ({
    menu,
    place,
    userId,
    openCloseStatus,
    display_menu_hours,
    is_custom_service,
  }) => ({
    is_note:
      menu && !isNull(menu.catering) && !isNull(menu.catering.is_note)
        ? menu.catering.is_note
        : false,

    is_catering_menu:
      menu && !isNull(menu.catering) && !isNull(menu.catering.is_catering_menu)
        ? menu.catering.is_catering_menu
        : false,

    schedule_order_duration:
      menu &&
      !isNull(menu.catering) &&
      !isNull(menu.catering.schedule_order_duration)
        ? menu.catering.schedule_order_duration
        : 15,

    lead_time:
      menu && !isNull(menu.catering) && !isNull(menu.catering.lead_time)
        ? menu.catering.lead_time
        : 1,

    lead_type:
      menu && !isNull(menu.catering) && !isNull(menu.catering.lead_type)
        ? menu.catering.lead_type
        : 'DAYS',

    menu_cut_off_time:
      menu && !isNull(menu.catering) && !isNull(menu.catering.menu_cut_off_time)
        ? menu.catering.menu_cut_off_time
        : '11:00 AM',

    notes:
      menu && !isNull(menu.catering) && !isNull(menu.catering.notes)
        ? menu.catering.notes
        : '',

    platform: menu && !isNull(menu.platform) ? menu.platform : '',

    channel_link_id:
      menu && !isNull(menu.channel_link_id) ? menu.channel_link_id : '',
    deliverect_menu_id:
      menu && !isNull(menu.deliverect_menu_id) ? menu.deliverect_menu_id : '',
    user_id: userId,
    place_id: menu && !isNull(menu.place_id) ? menu.place_id : place.place_id,
    service_type_setting_id:
      menu && !isNull(menu.service_type_setting.service_type_setting_id)
        ? menu.service_type_setting.service_type_setting_id
        : '',
    menu_id: menu && !isNull(menu.menu_id) ? menu.menu_id : uuidv4(),
    name: menu && !isNull(menu.name) ? menu.name : '',
    approval_status:
      menu && !isNull(menu.status) ? menu.approval_status : 'APPROVED',
    status: menu && !isNull(menu.status) ? menu.status : 'ACTIVE',
    description: menu && !isNull(menu.description) ? menu.description : '',
    isCustom: !isNull(is_custom_service) ? is_custom_service : false,
    hours:
      menu && !isNull(menu.menu_hour) && menu.menu_hour.length !== 0
        ? placeBusinessHoursHandler(addIdInputHours(menu.menu_hour))
        : defaultHours,
    menu_hour:
      menu && !isNull(menu.menu_hour) && menu.menu_hour.length !== 0
        ? placeBusinessHoursHandler(addIdInputHours(menu.menu_hour))
        : defaultHours,
    isHoursDirty: false,
    openCloseHours: {
      hours: openCloseStatus?.hours,
      touched: openCloseStatus?.touched,
    },
    display_menu_hours: !isNull(display_menu_hours) ? display_menu_hours : true,
    publish_menu: !isNil(menu.publish_menu) ? menu.publish_menu : false,
  }),

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(26, 'Menu Name should not be greater than 26 characters')
      .required('Menu Name is required!'),
    description: yup
      .string()
      .min(5, 'Please enter at least 5 characters')
      .max(288, 'Menu Description cannot be greater than 288 characters'),
    service_type_setting_id: yup.string().required('Service Type is required!'),

    notes: yup.string().when(['is_note', 'is_catering_menu'], {
      is: (isNote, catering) => isNote === true && catering === true,
      then: yup
        .string()
        .required('Please enter a valid terms and conditions!')
        .min(5, 'Please enter at least 5 characters')
        .max(500, 'Please enter not greater than 500 characters'),
      otherwise: yup
        .string()
        .nullable()
        .notRequired(),
    }),
    lead_time: yup.number().when('is_catering_menu', {
      is: true,
      then: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .min(1, 'Minimum lead time is 1!')
        .max(30, 'Maximum lead time is 30!'),
      otherwise: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .notRequired(),
    }),
    schedule_order_duration: yup.number().when('is_catering_menu', {
      is: true,
      then: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .min(1, 'Minimum schedule order duration is 1!')
        .max(30, 'Maximum schedule order duration is 30!'),
      otherwise: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .notRequired(),
    }),
  }),
  validateOnBlur: false,
  validateOnChange: false,

  handleSubmit: (values, { props }) => {
    const {
      is_catering_menu,
      schedule_order_duration,
      lead_time,
      menu_cut_off_time,
      notes,
      is_note,
    } = values;
    const input = omit(values, [
      'hours',
      'menu_hour',
      'isCustom',
      'isHoursDirty',
      'display_menu_hours',
    ]);
    if (props.menu) {
      Object.assign(
        input,
        values.isHoursDirty && {
          menu_hour: menuHourSorter(parseFinalInputHours(values.menu_hour)),
        },
      );
      Object.assign(input, {
        catering: {
          schedule_order_in_advance: is_catering_menu,
          is_catering_menu,
          schedule_order_duration,
          lead_time,
          menu_cut_off_time,
          notes,
          is_note: is_note === true || is_note === false ? is_note : false,
        },
      });
    } else {
      Object.assign(input, {
        menu_hour: menuHourSorter(parseFinalInputHours(values.menu_hour)),
      });
    }

    props.onSubmit({
      ...input,
    });
  },

  displayName: 'GlobalMenuForm',
  enableReinitialize: true,
})(Form);

export default MenuForm;
