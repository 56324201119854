import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { reverse } from 'lodash';
import moment from 'moment-timezone';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import useSound from 'use-sound';
import sound from '../../../assets/sound/sms-alert-5-daniel_simon.wav';

import { BoxWithHeader, Loading } from '../../../components/elements';
import { getCancelledOrders } from './helpers';
import OrderDetails from './OrderDetails';
import { totalDueTimeInMinsScheduleOrder } from './timeHelpers';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px !important;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
  }
`;

const newOrderPopupContent = ({
  service_type,
  order_reference,
  overall_quantity,
  order_total,
}) => {
  if (window && window.document) {
    const outerDiv = document.createElement('div');
    outerDiv.style.display = 'flex';
    outerDiv.style.justifyContent = 'center';
    outerDiv.style.alignItems = 'center';

    const innerDiv = document.createElement('div');
    innerDiv.style.display = 'flex';
    innerDiv.style.justifyContent = 'space-between';

    const leftDiv = document.createElement('div');
    leftDiv.style.textAlign = 'left';

    const serviceTypeSpan = document.createElement('span');
    serviceTypeSpan.style.fontWeight = '700';
    serviceTypeSpan.style.fontSize = '16px';
    serviceTypeSpan.textContent = service_type;

    const lineBreak = document.createElement('br');

    const orderDetailsSpan = document.createElement('span');
    orderDetailsSpan.textContent = `${order_reference}, ${overall_quantity} item(s)`;

    leftDiv.appendChild(serviceTypeSpan);
    leftDiv.appendChild(lineBreak);
    leftDiv.appendChild(orderDetailsSpan);

    const orderTotalSpan = document.createElement('span');
    orderTotalSpan.style.color = '#EE2A7B';
    orderTotalSpan.textContent = `$${order_total}`;

    innerDiv.appendChild(leftDiv);
    innerDiv.appendChild(orderTotalSpan);

    outerDiv.appendChild(innerDiv);
    return outerDiv;
  }
  return null;
};

const Cancelled = ({
  place,
  currentSubscribeToMenuOrderMoveToActive,
  setCurrentSubscribeToMenuOrderMoveToActive,
  isActiveTab,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  const [currentOrder, setCurrentOrder] = useState(null);

  const [play] = useSound(sound);

  useEffect(() => {
    const fetchData = async () => {
      const setDateHours = dateObject => {
        dateObject.setHours(0, 0, 0, 0);
        return dateObject;
      };

      const startDateISOString = dateObject =>
        setDateHours(dateObject).toISOString();

      setIsLoading(true);
      const data = await getCancelledOrders({
        placeId: place.place_id,
        status: 'CANCELLED',
        dateRange: {
          start_date: startDateISOString(new Date()),
          end_date: moment()
            .add(1, 'day')
            .toISOString(),
        },
        userId,
      });
      setIsLoading(false);
      setCancelledOrders(reverse(data));
    };
    fetchData();
  }, []);

  const notify2 = subscriptionData => {
    const isConfirmed = subscriptionData.status === 'CONFIRMED';

    const {
      service_type,
      overall_quantity,
      order_reference,
      billing,
    } = subscriptionData;
    const { order_total } = billing ?? {};

    if (isConfirmed) {
      setCurrentOrder(null);
      setIsActiveOrderDetailsModal(false);
      play();
      swal({
        title: 'Prepare Scheduled Order!',
        text: `A scheduled order is due in ${totalDueTimeInMinsScheduleOrder(
          subscriptionData.estimates,
          subscriptionData?.received?.created_at,
        )}`,
        buttons: ['OK', 'View Detail'],
        content: newOrderPopupContent({
          service_type,
          order_reference,
          overall_quantity,
          order_total,
        }),
        closeOnClickOutside: false,
      }).then(onfulfilled => {
        if (onfulfilled) {
          setCurrentOrder(subscriptionData);
          setIsActiveOrderDetailsModal(true);
        }
        setCurrentSubscribeToMenuOrderMoveToActive();
      });
    }
  };

  useEffect(() => {
    if (
      isActiveTab === 'cancelled' &&
      currentSubscribeToMenuOrderMoveToActive
    ) {
      notify2(currentSubscribeToMenuOrderMoveToActive);
    }
  }, [currentSubscribeToMenuOrderMoveToActive, isActiveTab]);

  return (
    <Container>
      {isLoading && <Loading />}
      {cancelledOrders !== 0 &&
        cancelledOrders.map(item => {
          const handleTimeLineTime = () => {
            const orderStatus = item.cancelled;
            const time = orderStatus
              ? moment(orderStatus.created_at).format('LT')
              : null;
            return time;
          };

          const cancelledBy = () => {
            const orderStatus = item.cancelled;
            let user = '';
            switch (orderStatus.user_type) {
              case 'USER':
                user = 'Customer';
                break;
              case 'KRAVEIN':
                user = orderStatus.user_type;
                break;
              default:
                user = item.place.name;
                break;
            }
            return user;
          };

          return (
            <BoxWithHeader
              title={item.service_type}
              cancelTime={`Cancelled at ${handleTimeLineTime()}`}
              cancelledBy="Cancelled by"
              key={item.menu_order_item}
              className="cancel-header"
            >
              <div className="columns v-centered">
                <div className="column is-4">
                  <p className="is-size-4 has-text-weight-semibold">
                    {item.order_reference} | {item.overall_quantity} items | $
                    {item.billing.sub_total.toFixed(2)}
                  </p>
                  <p>
                    {item.contact && item.contact.contact_name}(
                    {item.address && item.address.city})
                  </p>
                </div>

                <div className="column is-4">
                  <DetailsButton
                    type="button"
                    className="button is-large is-fullwidth control"
                    onClick={() => {
                      setIsActiveOrderDetailsModal(true);
                      setCurrentOrder(item);
                    }}
                  >
                    Details
                  </DetailsButton>
                </div>
                <div className="column is-4 d-flex is-align-items-center">
                  <p className="is-size-4 has-text-weight-semibold">
                    {`Cancelled by ${cancelledBy()}`} -{' '}
                    {item.cancelled.user_data?.first_name}{' '}
                    {item.cancelled.user_data?.last_name}({' '}
                    {item.cancelled.user_data?.email})
                  </p>
                </div>
              </div>
              <div />
            </BoxWithHeader>
          );
        })}
      {currentOrder && isActiveOrderDetailsModal && (
        <OrderDetails
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          order={currentOrder}
        />
      )}
    </Container>
  );
};

export default Cancelled;
