import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { isNull } from 'lodash';
import ReactDateTimePicker from '../elements/ReactDateTimePicker';
import TimePicker from './TimePicker';
import InputErrorMessage from '../elements/InputErrorMessage';

const TimeWrapper = styled.div`
  padding-left: 0.5rem;
`;
const TimeLabel = styled.p`
  position: relative;
  left: 39%;
`;

const TimeDatePicker = ({
  label,
  required,
  value,
  timeLabel,
  onChange,
  errors,
  touched,
  disabled,
  ...dateProps
}) => {
  const [inputDate, setInputDate] = useState(
    !isNull(value) ? moment(value).format('YYYY-MM-DD') : null,
  );
  const [inputTime, setInputTime] = useState(
    !isNull(value) ? moment(value).format('HH:mm:ss') : '',
  );

  const handleTimeDate = (argDate, argTime) => {
    const finalDate = `${argDate} ${argTime}`;
    onChange(finalDate);
  };

  return (
    <div className="field">
      {label && (
        <label className="label is-flex">
          {label}
          {required && <span className="has-text-danger">*</span>}
          {timeLabel && <TimeLabel className="label">{timeLabel}</TimeLabel>}
        </label>
      )}

      <div className="control">
        <div className="is-flex">
          <ReactDateTimePicker
            value={value}
            onChange={date => {
              setInputDate(moment(date).format('YYYY-MM-DD'));
              handleTimeDate(moment(date).format('YYYY-MM-DD'), inputTime);
            }}
            disabled={disabled}
            {...dateProps}
          />
          <TimeWrapper>
            <TimePicker
              placeholder="Select Time"
              value={
                inputTime === '' || inputDate === 'Invalid date'
                  ? ''
                  : moment(`${inputDate} ${inputTime}`)
              }
              onChange={time => {
                if (time !== null) {
                  setInputTime(moment(time).format('HH:mm:ss'));
                  handleTimeDate(inputDate, moment(time).format('HH:mm:ss'));
                } else {
                  setInputTime('');
                }
              }}
              disabled={inputDate === null || disabled}
            />
          </TimeWrapper>
        </div>
      </div>
      <InputErrorMessage errors={errors} touched={touched} />
    </div>
  );
};

TimeDatePicker.defaultProps = {
  value: moment().toISOString(),
};

TimeDatePicker.propTypes = {
  value: PropTypes.string,
};

export default TimeDatePicker;
