/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import tzLookup from 'tz-lookup';
import { MdClose } from 'react-icons/md';

import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';
import Information from './OrderDetailsModal/Information';
import Options from './OrderDetailsModal/Options';
import LineItems from './OrderDetailsModal/LineItems';
import Pricing from './OrderDetailsModal/Pricing';
import ItemTotal from './OrderDetailsModal/ItemTotal';
import Cutlery from './OrderDetailsModal/Cutlery';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      justify-content: center;
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: center;
      background: white;
      border-top: 0px;
      .button {
        font-weight: 600;
      }
    }
    .full-width {
      width: 100%;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 1.2rem;
  cursor: pointer;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 1rem;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
`;

const searchUsers = gql`
  query searchUsers($adminId: String, $userIdFromOrder: String) {
    search_users(
      input: {
        filter: { user_filter: { user_id: [$userIdFromOrder] } }
        user_id: $adminId
      }
    ) {
      user_listing {
        email
        user_id
        first_name
        last_name
        display_name
        email
        contact {
          type
          value
          display
          display_order
          is_primary
        }
      }
    }
  }
`;

const DraftOrderDetails = ({ order, isActive, onClose }) => {
  const { userId } = useStoreState(state => state.auth);

  const [contactMail, setContactMail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [userName, setUserName] = useState(null);

  const lat = parseFloat(order?.place?.latitude).toFixed(5);
  const long = parseFloat(order?.place?.longitude).toFixed(5);
  const timeZone = tzLookup(lat, long);
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    const userContact = (adminId, userIdFromOrder) =>
      new Promise((resolve, reject) => {
        client.clientPrivate
          .query({
            query: searchUsers,
            variables: {
              adminId,
              userIdFromOrder,
            },
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            if (
              data.search_users &&
              data.search_users.user_listing &&
              data.search_users.user_listing[0]
            ) {
              resolve(data.search_users.user_listing[0]);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('No Users Found');
            }
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    if (order && order.audit && order.audit.created_by) {
      userContact(userId, order.audit.created_by).then(res => {
        setContactMail(res.email);
        if (res.first_name && res.last_name) {
          setUserName(`${res.first_name} ${res.last_name}`);
        } else {
          setUserName(res.first_name);
        }
        const mobileObject = (res.contact ?? [])
          .filter(contact => contact.type === 'mobile')
          .shift();
        if (mobileObject) {
          setPhoneNumber(mobileObject.value);
        }
      });
    }
  }, [order]);

  return (
    <>
      <Container
        className={`modal ${isActive && 'is-active'}`}
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <CloseButtonWrapper>
              <MdClose
                size={30}
                type="button"
                onClick={() => {
                  onClose();
                }}
              />
            </CloseButtonWrapper>
            <HeaderContent>
              <p className="modal-card-title is-uppercase">
                {order.service_type}
              </p>
            </HeaderContent>
          </header>

          <section className="modal-card-body">
            <DetailsFormWrapper>
              <>
                {order.service_type === 'Dinein' && order.table_number && (
                  <>
                    <p className="is-size-1 has-text-weight-bold ">
                      {order.table_name || 'Table'} No - {order.table_number}
                    </p>
                  </>
                )}

                <br />
                <Information
                  isDraft={order.status === 'DRAFT'}
                  order={order}
                  contactMail={contactMail}
                  phoneNumber={phoneNumber}
                  userName={userName}
                />
                <div
                  style={{
                    padding: '0.6rem',
                    borderRadius: '10px',
                    border: '2px solid #EE2A7B',
                    marginBottom: '1rem',
                  }}
                >
                  <div style={{ padding: '0.05rem 0rem' }}>
                    <p className="is-size-4">
                      Created at {moment(order.audit.created_at).format('LT')}{' '}
                      {moment(order.audit.created_at).format('DD/MM/YY')}
                    </p>
                  </div>
                </div>
                <Options order={order} />
              </>

              <br />
              <p className="is-size-3 has-text-weight-bold">Order details</p>
              <br />
              <LineItems order={order} />
              <ItemTotal order={order} />
              <Pricing order={order} />
              <Cutlery order={order} />
            </DetailsFormWrapper>
          </section>
        </div>
      </Container>
    </>
  );
};

export default DraftOrderDetails;
