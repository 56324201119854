import React from 'react';
import styled from 'styled-components';
import qs from 'querystringify';
import { Level, Title } from 'rbx';
import { omit } from 'lodash';

import { Radio } from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({ status, history, routeState }) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/services${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="ACTIVE"
                value={status === 'ACTIVE'}
                onChange={() => {
                  handlePush({ status: 'ACTIVE' }, 'status');
                }}
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="INACTIVE"
                value={status === 'INACTIVE'}
                onChange={() => {
                  handlePush({ status: 'INACTIVE' }, 'status');
                }}
              />
            </div>
          </Level.Item>
        </Level.Item>
      </Level>
    </Wrapper>
  );
};

export default Search;
