import React from 'react';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const handleChange = ({
  setIsChecked,
  onToggle,
  isChecked,
  disabled,
}) => () => {
  if (!disabled) {
    setIsChecked(!isChecked);
    onToggle(!isChecked);
  }
};

const Toggle = ({
  toggleWidth,
  toggleHeight,
  checked = false,
  onToggle = () => {},
  disabled = false,
}) => {
  const [isChecked, setIsChecked] = React.useState(checked);

  return (
    <ToggleContainer
      disabled
      toggleWidth={toggleWidth}
      toggleHeight={toggleHeight}
    >
      <label className="switch">
        <input
          className="menu-designer-publish-button"
          type="checkbox"
          checked={isChecked}
          onChange={handleChange({
            setIsChecked,
            onToggle,
            isChecked,
            disabled,
          })}
        />
        <span className="slider round" />
      </label>
    </ToggleContainer>
  );
};

export default Toggle;
