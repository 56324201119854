import React from 'react';
import styled from 'styled-components';

import TableItems from './TableItems';

const BookingManagerTableContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 15px;
`;

const BookingManagerTableItems = styled.div`
  border: 1px solid #e2e2e2;
  font-size: 12px;
  color: black;
  padding: 10px;
  text-align: center;
`;

const LoadingText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00aeef;
  font-size: 24px;
`;

const BookingManagerTable = ({
  tableBookingLoading,
  tableBookings,
  placeId,
  history,
}) => (
  <>
    <BookingManagerTableContainer>
      <BookingManagerTableItems
        style={{ fontWeight: '700', backgroundColor: '#a8a8a8' }}
      >
        Time
      </BookingManagerTableItems>
      <BookingManagerTableItems
        style={{ fontWeight: '700', backgroundColor: '#a8a8a8' }}
      >
        Guests
      </BookingManagerTableItems>
      <BookingManagerTableItems
        style={{ fontWeight: '700', backgroundColor: '#a8a8a8' }}
      >
        Booking Name
      </BookingManagerTableItems>
      <BookingManagerTableItems
        style={{ fontWeight: '700', backgroundColor: '#a8a8a8' }}
      >
        Table Number
      </BookingManagerTableItems>
      <BookingManagerTableItems
        style={{ fontWeight: '700', backgroundColor: '#a8a8a8' }}
      >
        Phone Number
      </BookingManagerTableItems>
      <BookingManagerTableItems style={{ backgroundColor: '#a8a8a8' }} />
      {tableBookings.map(ele => (
        <TableItems ele={ele} placeId={placeId} history={history} />
      ))}
    </BookingManagerTableContainer>
    {tableBookingLoading && <LoadingText>Loading...</LoadingText>}
  </>
);

export default BookingManagerTable;
