import React from 'react';
//import { Link } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({ loading, handleSubmit, prev, next }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <button onClick={prev} className="button is-light">
            <span>Cancel</span>
          </button>
        </Level.Item>
        <Level.Item>
          <button onClick={prev} className="button is-primary">
            <span>Prev Tab</span>
          </button>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button onClick={prev} className="button is-primary">
            <span>Prev Tab</span>
          </button>
        </Level.Item>
        <Level.Item>
          <button className="button is-primary">
            <span>Submit</span>
          </button>
        </Level.Item>
        <Level.Item>
          <button onClick={next} className="button is-primary">
            <span>Next Tab</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Footer;
