/* eslint-disable react/no-array-index-key */
import React from 'react';
import Loading from 'react-loading';
import styled from 'styled-components';
import UAParser from 'ua-parser-js';
import moment from 'moment-timezone';
import qs from 'query-string';

import { Table, Pagination } from '../../../components/elements';
import { fetchUserLoginHistory } from './helper';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AesDes = ({ sort, setSort, aes, des }) => (
  <a onClick={() => setSort(sort === des ? aes : des)}>
    <i className={`fas fa-sort-amount-${sort === des ? 'up' : 'down'}`} />
  </a>
);

const LoginTable = ({ userId, routeState, history }) => {
  const { page } = routeState;
  const [pageCount, setPageCount] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState();
  const listSize = 10;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const [sort, setSort] = React.useState('UPDATED_DESC');

  const [loading, setLoading] = React.useState(false);
  const [userLoginHistoryData, setUserLoginHistoryData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchUserLoginHistory({
          userId,
          sort,
          from: currentPage * listSize,
          size: listSize,
        });
        setPageCount(data.total_pages);
        setTotalElements(data.total_size);
        setUserLoginHistoryData(data.user_login_history ?? []);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId, sort, currentPage]);

  return (
    <>
      {totalElements > 0 && (
        <div style={{ padding: '10px', display: 'flex' }}>
          <div style={{ marginLeft: 'auto' }}>
            <Pagination
              hideLevelLeft
              pageFrom={currentPage * listSize}
              pageCount={pageCount}
              listSize={listSize}
              totalElements={totalElements}
              currentPage={currentPage}
              handlePageClick={value => {
                setSort('UPDATED_DESC');
                history.push(
                  `/user/${userId}?${qs.stringify(
                    {
                      ...routeState,
                      page: value.selected + 1,
                    },
                    true,
                  )}`,
                );
              }}
            />
          </div>
        </div>
      )}
      <div style={{ padding: '10px' }}>
        <Table>
          <thead>
            <tr>
              {[
                'Login Date & Time',
                'Logged In',
                'Previous Login',
                'User Type',
                'Login Type',
                'App Type',
                'App Version',
                'App Build Number',
                'Platform',
                'Parsed User Agent String',
                'Device Name',
                'Model',
                'IP Address',
                'Unique ID',
                'Browser',
              ].map((headerCellValue, index) => (
                <th
                  style={{ fontWeight: 700 }}
                  key={`${headerCellValue}-${index}`}
                >
                  {headerCellValue === 'Login Date & Time' ? (
                    <div>
                      <span>
                        <AesDes
                          sort={sort}
                          setSort={setSort}
                          aes="UPDATED_ASC"
                          des="UPDATED_DESC"
                        />
                        {headerCellValue}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span>{headerCellValue}</span>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          {!loading && userLoginHistoryData.length !== 0 && (
            <tbody>
              {userLoginHistoryData.map((lhData, index) => (
                <tr key={`${userId}-${index}`}>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {moment(lhData?.login_at).format('LLL')}
                  </td>
                  <td>{lhData.logged_in ? 'TRUE' : 'FALSE'}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {moment(lhData?.last_login).format('LLL')}
                  </td>
                  <td>{lhData.user_type}</td>
                  <td>{lhData.login_type}</td>
                  <td>{lhData.app_type}</td>
                  <td>{lhData.version_number}</td>
                  <td>{lhData.build_number}</td>
                  <td>{lhData.platform}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {(() => {
                      if (!lhData.web_browser) {
                        return '';
                      }
                      const temp = new UAParser(lhData.web_browser).getResult();
                      if (
                        temp?.browser?.name &&
                        temp?.browser?.version &&
                        temp?.os?.name &&
                        temp?.os?.version
                      ) {
                        return `${temp.browser.name} ${temp.browser.version} on ${temp.os.name} ${temp.os.version}`;
                      }
                      return lhData.web_browser;
                    })()}
                  </td>
                  <td>{lhData.device_name}</td>
                  <td>{lhData.model_number}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{lhData.ip_address}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{lhData.unique_id}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{lhData.web_browser}</td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {loading && (
          <Center>
            <Loading type="spin" color="#363636" width="60px" height="60px" />
          </Center>
        )}
      </div>
    </>
  );
};

export default LoginTable;
