/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React from 'react';
import styled from 'styled-components';
import { isNull, forEach } from 'lodash';
import { Column } from 'rbx';

import SearchItems from './SearchItems';
import TableRows from './TableRows';

const transform = links => menuItemObj => {
  if (menuItemObj.__typename) {
    const { menu_item_id } = menuItemObj;
    const link = links.find(ele => ele.menu_item_id === menu_item_id);
    const data = {
      item_link_id: link.item_link_id,
      menu_item_id: link.menu_item_id,
      name: link.name,
      menu_item_price: link.menu_item_price,
      menu_section_name: link.menu_section_name,
    };
    if (isNull(menuItemObj.variant)) {
      return {
        ...data,
        type: menuItemObj.type || 'PERCENTAGE',
        value: menuItemObj.value || 0,
        new_price: menuItemObj.new_price || link.menu_item_price,
        savings: menuItemObj.savings || 0,
      };
    }

    const itemVariantsFromMenuObj = menuItemObj.variant[0].variants;

    const temp = [];
    forEach(link.variant, item => {
      const temp2 = [];
      forEach(item.variants, item2 => {
        const newVariant = {
          ...item2,
          type:
            itemVariantsFromMenuObj.find(
              ele => ele.variant_id === item2.variant_id,
            ).type || 'PERCENTAGE',
          value:
            itemVariantsFromMenuObj.find(
              ele => ele.variant_id === item2.variant_id,
            ).value || 0,
          savings:
            itemVariantsFromMenuObj.find(
              ele => ele.variant_id === item2.variant_id,
            ).savings || 0,
          new_price:
            itemVariantsFromMenuObj.find(
              ele => ele.variant_id === item2.variant_id,
            ).new_price || item2.variant_price,
        };
        temp2.push(newVariant);
      });
      temp.push({
        prompt_id: item.prompt_id,
        prompt: item.prompt,
        variants: temp2,
      });
    });

    data.variant = temp;
    return data;
  }
  return menuItemObj;
};

const Container = styled.div`
  /* -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15); */
  border-radius: 6px;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border: 1.5px solid #f2f2f2;
  &&& {
    .button {
      border-width: 0;
    }
  }
`;

const Header = styled.div``;

const ItemsAndVariantsDiscount = ({
  links,
  setFieldValue,
  menu_item,
  selectedLinkIds,
}) => (
  <Container>
    <Header>
      <Column.Group>
        <Column size="4">Select Items:</Column>
        <Column size="4">
          <SearchItems
            links={links}
            selectedLinkIds={selectedLinkIds}
            setFieldValue={setFieldValue}
          />
        </Column>
      </Column.Group>
      <Column.Group style={{ fontWeight: '600', fontSize: '13px' }}>
        <Column size={2}>Item</Column>
        <Column size={10}>
          <Column.Group>
            <Column size={2}>Price</Column>
            <Column size={2}>Discount Type</Column>
            <Column size={2}>Discount</Column>
            <Column size={2}>New Price</Column>
            <Column size={2}>Savings</Column>
            <Column size={2}>&nbsp;&nbsp;</Column>
          </Column.Group>
        </Column>
      </Column.Group>
    </Header>
    {links.length !== 0 &&
      selectedLinkIds.length !== 0 &&
      selectedLinkIds.map(item_link_id => (
        <TableRows
          key={item_link_id}
          item_link_id={item_link_id}
          links={links}
          setFieldValue={setFieldValue}
          menu_item={menu_item.map(transform(links))}
          selectedLinkIds={selectedLinkIds}
        />
      ))}
  </Container>
);

export default ItemsAndVariantsDiscount;
