import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const InputErrorMessage = ({
  errors,
  touched,
  disableToastMessage,
  setErrors,
}) => {
  useEffect(() => {
    if (errors && touched && !disableToastMessage) {
      toast.error(errors, {
        onClose: () => {
          setErrors({ hours: null });
        },
      });
    }
  }, [errors, touched]);

  return (
    <React.Fragment>
      {errors && touched && (
        <p className="help is-danger is-size-4">{errors}</p>
      )}
    </React.Fragment>
  );
};

InputErrorMessage.defaultProps = {
  disableToastMessage: false,
  errors: '',
  touched: '',
};

InputErrorMessage.propTypes = {
  disableToastMessage: PropTypes.bool,
  errors: PropTypes.string,
  touched: PropTypes.string,
};

export default InputErrorMessage;
