import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Label, Field, Column } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull, isNaN, omit, trim } from 'lodash';
import * as yup from 'yup';

import { convertModifierPriceInDecimals } from './helpers';
import { Input, Radio } from '../../../components/elements';
import ReactSelectInput from '../../../components/elements/ReactSelectInput';
import ModifierItems from './CreateModifiersItems';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

const SelectionQuantity = styled.div`
  display: flex;
  justify-content: space-between;
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const SelectItemMoreThanOnceInputWrapper = styled.div`
  width: 75px;
  margin-left: 10px;
  margin-top: -7px;
`;

const Form = ({
  isActive,
  onClose,
  values,
  handleChange,
  setFieldValue,
  handleSubmit,
  loading,
  errors,
  touched,
  resetForm,
  menuItemId,
}) => (
  <Container
    className={`modal ${isActive && 'is-active'}`}
    onSubmit={e => {
      e.preventDefault();
      handleSubmit();
    }}
  >
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <div>
          <p className="modal-card-title">Add New Modifier</p>
          <p className="is-size-4">
            Create set of options that can be linked to multiples items of your
            customers to select.
          </p>
        </div>
        <CloseButtonWrapper>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => {
              onClose();
              resetForm();
            }}
          />
        </CloseButtonWrapper>
      </header>

      <section className="modal-card-body">
        <DetailsFormWrapper>
          <Input
            label="Modifier Group Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={event => {
              setFieldValue('name', trim(event.target.value));
              setFieldValue('internal_name', trim(event.target.value));
            }}
            errors={errors.name}
            touched={touched.name}
            disableToastMessage
            required
          />
          <Input
            label="Modifier Group Internal Name"
            name="internal_name"
            value={values.internal_name}
            onChange={handleChange}
            onBlur={event => {
              setFieldValue('internal_name', trim(event.target.value));
            }}
            errors={errors.internal_name}
            touched={touched.internal_name}
            disableToastMessage
            required
          />
          <Field>
            <Label>
              Is the Selection Optional or Required
              <span className="has-text-danger">*</span>
            </Label>
            <RadioWrapper>
              <Radio
                label="Required"
                value={values.is_required}
                onChange={() => setFieldValue('is_required', true)}
              />
              <Radio
                label="Optional"
                value={!values.is_required}
                onChange={() => setFieldValue('is_required', false)}
              />
            </RadioWrapper>
          </Field>
          <div style={{ display: 'flex', margin: '6px 0' }}>
            <Label>Allow to select an item more than once</Label>
            <SelectItemMoreThanOnceInputWrapper>
              <Input
                type="number"
                step={1}
                min={1}
                max={values.selection_quantity ?? 1}
                value={values.multiply_selection_quantity}
                onChange={e => {
                  if (e.target.value === '') {
                    setFieldValue('multiply_selection_quantity', '');
                  } else {
                    const temp = parseInt(e.target.value, 10);
                    if (!isNaN(temp)) {
                      setFieldValue('multiply_selection_quantity', temp);
                    }
                  }
                }}
                disableToastMessage
                errors={errors.multiply_selection_quantity}
                touched={touched.multiply_selection_quantity}
              />
            </SelectItemMoreThanOnceInputWrapper>
          </div>
          <>
            <Label>
              Selection Quantity <span className="has-text-danger">*</span>
            </Label>
            <p className="is-size-4">
              Choose `custom selection` to enter the total number of modifiers
              your customer can select. Choose `select the range`to allow your
              customer to choose between the minimum and maximum number of items
            </p>
            <br />
            <Column.Group>
              <Column>
                <ReactSelectInput
                  value={{
                    label:
                      values.selection_type === 'RANGE'
                        ? 'Select a Range'
                        : 'Custom Selection',
                    value: values.selection_type,
                  }}
                  options={[
                    { label: 'Select a Range', value: 'RANGE' },
                    { label: 'Custom Selection', value: 'CUSTOM' },
                  ]}
                  onChange={value => {
                    setFieldValue('selection_type', value.value);
                  }}
                  isDisabled={values.modifier.length === 0}
                />
              </Column>
              <Column>
                {values.selection_type === 'CUSTOM' && (
                  <SelectionQuantity>
                    <Input
                      type="number"
                      step="0"
                      min="1"
                      value={values.selection_quantity}
                      onChange={e => {
                        if (e.target.value === '') {
                          setFieldValue('selection_quantity', '');
                        } else if (!isNaN(parseInt(e.target.value, 10))) {
                          setFieldValue(
                            'selection_quantity',
                            parseInt(e.target.value, 10),
                          );
                          setFieldValue(
                            'selection_quantity_from',
                            parseInt(e.target.value, 10),
                          );
                          setFieldValue(
                            'selection_quantity_to',
                            parseInt(e.target.value, 10),
                          );
                        }
                      }}
                      errors={errors.selection_quantity}
                      touched={touched.selection_quantity}
                      disableToastMessage
                      disabled={values.modifier.length === 0}
                    />
                  </SelectionQuantity>
                )}
                {values.selection_type === 'RANGE' && (
                  <SelectionQuantity>
                    <Input
                      type="number"
                      step="0"
                      min="1"
                      value={values.selection_quantity_from}
                      onChange={e => {
                        if (e.target.value === '') {
                          setFieldValue('selection_quantity_from', '');
                        } else if (!isNaN(parseInt(e.target.value, 10))) {
                          setFieldValue(
                            'selection_quantity_from',
                            parseInt(e.target.value, 10),
                          );
                        }
                      }}
                      errors={errors.selection_quantity_from}
                      touched={touched.selection_quantity_from}
                      disableToastMessage
                      disabled={values.modifier.length === 0}
                    />

                    <>
                      <p className="separator">to</p>
                      <Input
                        type="number"
                        min={`${values.selection_quantity_to}`}
                        value={values.selection_quantity_to}
                        step="0"
                        onChange={e => {
                          if (e.target.value === '') {
                            setFieldValue('selection_quantity_to', '');
                          } else if (!isNaN(parseInt(e.target.value, 10))) {
                            setFieldValue(
                              'selection_quantity_to',
                              parseInt(e.target.value, 10),
                            );
                          }
                        }}
                        errors={errors.selection_quantity_to}
                        touched={touched.selection_quantity_to}
                        disableToastMessage
                        disabled={values.modifier.length === 0}
                      />
                    </>
                  </SelectionQuantity>
                )}
              </Column>
            </Column.Group>
          </>
          <ModifierItems menuItemId={menuItemId} />
        </DetailsFormWrapper>
      </section>
      <footer className="modal-card-foot">
        <button
          type="button"
          className="button"
          aria-label="close"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`button is-primary ${loading && 'is-loading'}`}
          aria-label="close"
        >
          Submit
        </button>
      </footer>
    </div>
  </Container>
);

const AddEditModifierModal = withFormik({
  mapPropsToValues: ({ placeId, userId, group }) => ({
    user_id: userId,
    modifier_group_id:
      group && !isNull(group.modifier_group_id)
        ? group.modifier_group_id
        : uuidv4(),
    place_id: group && !isNull(group.place_id) ? group.place_id : placeId,

    name: group && !isNull(group.name) ? group.name : '',
    internal_name:
      group && !isNull(group.internal_name) ? group.internal_name : '',

    description: group && !isNull(group.description) ? group.description : '',
    status: group && !isNull(group.status) ? group.status : 'ACTIVE',
    modifier:
      group && !isNull(group.modifier)
        ? convertModifierPriceInDecimals(group.modifier)
        : [],
    is_required:
      group && !isNull(group.is_required) ? group.is_required : false,
    selectors:
      group &&
      !isNull(group.selection_quantity_from) &&
      group.selection_quantity_from === group.selection_quantity_to
        ? 'Custom Selection'
        : 'Select a Range',
    selection_type:
      group && !isNull(group.selection_type) ? group.selection_type : 'CUSTOM',
    selection_quantity:
      group && !isNull(group.selection_quantity)
        ? group.selection_quantity
        : null,
    selection_quantity_from:
      group && !isNull(group.selection_quantity_from)
        ? group.selection_quantity_from
        : null,
    selection_quantity_to:
      group && !isNull(group.selection_quantity_to)
        ? group.selection_quantity_to
        : null,
    multiply_selection_quantity:
      group && !isNull(group.multiply_selection_quantity)
        ? group.multiply_selection_quantity
        : 1,
  }),

  validate: values => {
    const errors = {};

    if (values.selection_type === 'CUSTOM') {
      if (!values.selection_quantity) {
        errors.selection_quantity = 'Value is required!';
      } else if (
        values.selection_quantity < values.multiply_selection_quantity
      ) {
        errors.multiply_selection_quantity =
          'Value cannot be greater than custom selection';
      } else if (values.multiply_selection_quantity === '') {
        errors.multiply_selection_quantity = 'Value is required';
      } else if (values.multiply_selection_quantity < 1) {
        errors.multiply_selection_quantity = 'Value cannot be less than 1';
      }
      // else if (values.selection_quantity > values.modifier.length) {
      //   errors.selection_quantity =
      //     'Maximum permitted per item should not be greater than maximum number of modifiers permitted total';
      // }
    } else if (values.selection_type === 'RANGE') {
      if (
        values.selection_quantity_to &&
        values.multiply_selection_quantity > values.selection_quantity_to
      ) {
        errors.multiply_selection_quantity =
          'Value cannot be greater than the maximum value of the range';
      }
      if (values.multiply_selection_quantity < 1) {
        errors.multiply_selection_quantity = 'Value cannot be less than 1';
      }
      if (values.multiply_selection_quantity === '') {
        errors.multiply_selection_quantity = 'Value is required!';
      }

      if (!values.selection_quantity_from) {
        errors.selection_quantity_from = 'Value is required!';
      }
      if (!values.selection_quantity_to) {
        errors.selection_quantity_to = 'Value is required!';
      }
      if (
        values.selection_quantity_from &&
        values.selection_quantity_from < 0
      ) {
        errors.selection_quantity_from = 'Value must me positive integer';
      }
      if (values.selection_quantity_to && values.selection_quantity_to < 0) {
        errors.selection_quantity_to = 'Value must me positive integer';
      }
      if (values.selection_quantity_from > values.selection_quantity_to) {
        errors.selection_quantity_to =
          'Maximum value should be more than the minimum value';
      }
      // if (values.selection_quantity_to > values.modifier.length) {
      //   errors.selection_quantity_to =
      //     'Maximum Value should be less than or equal to the total number of items selected';
      // }
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Modifier group name is required!'),
    internal_name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Modifier group name is required!'),
  }),

  handleSubmit: (values, { props, resetForm }) => {
    const input = omit(values, 'selectors');
    props.onSubmit(input, resetForm);
  },

  displayName: 'AddEditModifierModal',
})(Form);

export default AddEditModifierModal;
