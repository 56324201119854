import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { filter, sortBy, capitalize } from 'lodash';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import useSound from 'use-sound';
import sound from '../../../assets/sound/sms-alert-5-daniel_simon.wav';

import ActiveCards from './ActiveCard';
import OrderDetails from './OrderDetails';
import { handleOrderAction, parsedAmount, getScheduledOrder } from './helpers';
import { Loading } from '../../../components/elements';
import {
  scheduleTimeText,
  scheduleCompletionTimeText,
  textHelper,
  totalTime,
  totalTimeDueActiveCard2,
  totalTimeDelay,
  totalDueTimeInMinsScheduleOrder,
} from './timeHelpers';

const Container = styled.div`
  padding: 1rem 1rem;
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
  }
`;

const OrderButton = styled.button`
  &&& {
    font-weight: 600;
  }
`;

const newOrderPopupContent = ({
  service_type,
  order_reference,
  overall_quantity,
  order_total,
}) => {
  if (window && window.document) {
    const outerDiv = document.createElement('div');
    outerDiv.style.display = 'flex';
    outerDiv.style.justifyContent = 'center';
    outerDiv.style.alignItems = 'center';

    const innerDiv = document.createElement('div');
    innerDiv.style.display = 'flex';
    innerDiv.style.justifyContent = 'space-between';

    const leftDiv = document.createElement('div');
    leftDiv.style.textAlign = 'left';

    const serviceTypeSpan = document.createElement('span');
    serviceTypeSpan.style.fontWeight = '700';
    serviceTypeSpan.style.fontSize = '16px';
    serviceTypeSpan.textContent = service_type;

    const lineBreak = document.createElement('br');

    const orderDetailsSpan = document.createElement('span');
    orderDetailsSpan.textContent = `${order_reference}, ${overall_quantity} item(s)`;

    leftDiv.appendChild(serviceTypeSpan);
    leftDiv.appendChild(lineBreak);
    leftDiv.appendChild(orderDetailsSpan);

    const orderTotalSpan = document.createElement('span');
    orderTotalSpan.style.color = '#EE2A7B';
    orderTotalSpan.textContent = `$${order_total}`;

    innerDiv.appendChild(leftDiv);
    innerDiv.appendChild(orderTotalSpan);

    outerDiv.appendChild(innerDiv);
    return outerDiv;
  }
  return null;
};

const ScheduledTab = props => {
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [readyLoading, setReadyLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const { userId } = useStoreState(state => state.auth);
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const [play] = useSound(sound);

  const {
    place,
    isActiveTab,
    currentSubscribeToMenuOrderMoveToActive,
    setCurrentSubscribeToMenuOrderMoveToActive,
  } = props;

  const notify2 = subscriptionData => {
    const isConfirmed = subscriptionData.status === 'CONFIRMED';

    const {
      service_type,
      overall_quantity,
      order_reference,
      billing,
    } = subscriptionData;
    const { order_total } = billing ?? {};

    if (isConfirmed) {
      setCurrentOrder(null);
      setIsActiveOrderDetailsModal(false);
      play();
      swal({
        title: 'Prepare Scheduled Order!',
        text: `A scheduled order is due in ${totalDueTimeInMinsScheduleOrder(
          subscriptionData.estimates,
          subscriptionData?.received?.created_at,
        )}`,
        buttons: ['OK', 'View Detail'],
        content: newOrderPopupContent({
          service_type,
          order_reference,
          overall_quantity,
          order_total,
        }),
        closeOnClickOutside: false,
      }).then(onfulfilled => {
        if (onfulfilled) {
          setCurrentOrder(subscriptionData);
          setIsActiveOrderDetailsModal(true);
        }
        setCurrentSubscribeToMenuOrderMoveToActive();
      });
    }
  };

  useEffect(() => {
    if (
      isActiveTab === 'scheduled' &&
      currentSubscribeToMenuOrderMoveToActive
    ) {
      notify2(currentSubscribeToMenuOrderMoveToActive);
    }
  }, [currentSubscribeToMenuOrderMoveToActive, isActiveTab]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getScheduledOrder({
        place,
        placeId: place.place_id,
        userId,
      });
      setIsLoading(false);
      setConfirmedOrders(data);
    };
    fetchData();
  }, []);

  const handleReadyOrder = async value => {
    setReadyLoading(true);
    setCurrentOrderId(value.menu_order_id);
    await handleOrderAction({
      menuId: value.menu_order_id,
      userId,
      action: 'prepare',
      delay: null,
    });
    setReadyLoading(false);
    const newConfirmedOrders = filter(
      confirmedOrders,
      item => item.menu_order_id !== value.menu_order_id,
    );
    setConfirmedOrders(newConfirmedOrders);
  };

  const handleAfterDelayAndAdjustOrder = updateOrder => {
    const newConfirmedOrders = confirmedOrders.filter(
      item => item.menu_order_id !== updateOrder.menu_order_id,
    );
    setConfirmedOrders(
      sortBy(newConfirmedOrders.concat(updateOrder), 'audit.created_at'),
    );
  };

  console.log('confirmedOrders', confirmedOrders);

  return (
    <>
      <Container>
        <br />
        {isLoading && <Loading />}
        {confirmedOrders !== 0 &&
          confirmedOrders.map(item => {
            const receivedStatusHistory = item.received;

            const receivedTime = receivedStatusHistory
              ? receivedStatusHistory.created_at
              : null;

            const title = () => (
              <>
                {item.service_type === 'Delivery' && 'Delivery'}
                {item.service_type === 'Dinein' && item.table_number
                  ? `${item.table_name ?? 'Table'} ${item.table_number}`
                  : item.service_type === 'Dinein' && 'Dinein'}
                {item.service_type === 'Pickup' && 'Pickup'}
                {item.large_order && ` (LARGE ORDER)`}
              </>
            );

            const delayInMin = totalTime(
              item.estimates.filter(
                estimate_item => estimate_item.type === 'delayed_estimate',
              ),
            );

            return (
              <ActiveCards
                key={item.audit.created_at}
                title={title()}
                schedule={scheduleTimeText(item.estimated_completion_time)}
                scheduleWithDelay={scheduleCompletionTimeText(
                  item.estimates,
                  receivedTime,
                )}
                ready={
                  delayInMin > 0 &&
                  `delayed by ${textHelper(Math.abs(delayInMin))}`
                }
              >
                <div className="columns">
                  <div className="column is-4">
                    <p className="is-size-4 has-text-weight-semibold">
                      {item.order_reference} | {item.overall_quantity} items |
                      {parsedAmount(item?.billing?.order_total?.toFixed(2))}
                    </p>
                    <p className="is-size-4" style={{ paddingTop: '8px' }}>
                      {item.contact && item.contact.contact_name}
                      {item.service_type === 'Delivery' &&
                        item.address &&
                        ` ( ${capitalize(item.address.city)} )`}
                      {item.service_type === 'Dinein' &&
                        item.no_of_attendees &&
                        ` (no of attendees : ${item.no_of_attendees})`}
                    </p>
                    {item.service_type === 'Pickup' && item.address && (
                      <p
                        className="is-size-4"
                        style={{ paddingTop: '8px', color: '#00AEEF' }}
                      >
                        {item.address.city}
                      </p>
                    )}
                  </div>
                  <div className="column is-4">
                    <DetailsButton
                      type="button"
                      className="button is-large is-fullwidth control"
                      onClick={() => {
                        setIsActiveOrderDetailsModal(true);
                        setCurrentOrder(item);
                      }}
                    >
                      Details
                    </DetailsButton>
                  </div>
                  <div className="column is-4">
                    <OrderButton
                      type="button"
                      className={`button is-large is-fullwidth is-primary control ${readyLoading &&
                        item.menu_order_id === currentOrderId &&
                        'is-loading'}`}
                      onClick={() => handleReadyOrder(item)}
                    >
                      {item.service_type === 'Delivery' && 'Order on its way'}
                      {item.service_type === 'Dinein' && 'Serve Order'}
                      {item.service_type === 'Pickup' && 'Order is ready'}
                    </OrderButton>
                  </div>
                </div>

                <div />
              </ActiveCards>
            );
          })}
        {!isLoading && confirmedOrders.length === 0 && (
          <>
            <div
              className="has-text-weight-semibold"
              style={{ justifyContent: 'center' }}
            >
              <div
                className="is-flex has-text-weight-semibold"
                style={{ justifyContent: 'center' }}
              >
                <p>No Scheduled Orders yet!</p>
              </div>
              <div
                className="is-flex has-text-weight-medium"
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <p className="has-text-centered">
                  To review previous orders, go to order history
                </p>
                <br />
                <br />
                <br />
              </div>
            </div>
          </>
        )}
      </Container>
      {currentOrder && isActiveOrderDetailsModal && (
        <OrderDetails
          dueTimeToBeDisplayedInOrderDetailsModal={totalTimeDueActiveCard2(
            currentOrder.estimates,
            currentOrder?.received?.created_at,
          )}
          delayTimeToBeDisplayedInOrderDetailsModal={totalTimeDelay(
            currentOrder.estimates,
            currentOrder?.received?.created_at,
          )}
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          order={currentOrder}
          setConfirmedOrders={setConfirmedOrders}
          confirmedOrders={confirmedOrders}
          handleAfterDelayOrder={handleAfterDelayAndAdjustOrder}
          handleAfterAdjustment={handleAfterDelayAndAdjustOrder}
          orderComponent="Orders"
        />
      )}
    </>
  );
};

export default ScheduledTab;
