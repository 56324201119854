import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';
import { Heading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({ location }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Add/Edit Company</Heading>
      </Level.Item>
      <Level.Item align="right">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/companies' ? 'create_company' : ''
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Link to="/add-company" className="button is-primary">
                  <span className="has-text-weight-semibold">
                    Add a Company
                  </span>
                </Link>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Header);
