import React from 'react';
import { Level } from 'rbx';
import styled from 'styled-components';
import { Heading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({ history, showSubmit = false }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Service Audit</Heading>
      </Level.Item>
      {showSubmit && (
        <Level.Item
          align="right"
          style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
        >
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-grey">
              <span className="has-text-weight-semibold">Cancel</span>
            </a>
          </Level.Item>
        </Level.Item>
      )}
    </Level>
  </Wrapper>
);

export default Header;
