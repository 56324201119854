import React from 'react';
import { Link } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({
  loading,
  handleSubmit,
  goToStepOne,
  goToStepTwo,
  step,
  onSubmit,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        {step === 2 && (
          <Level.Item>
            <a
              onClick={() => (step === 2 ? goToStepOne() : goToStepTwo())}
              className="button is-primary"
            >
              <span>Cancel</span>
            </a>
          </Level.Item>
        )}
      </Level.Item>

      {step === 2 && (
        <Level.Item align="right">
          <Level.Item>
            <button
              className="button is-primary"
              onClick={handleSubmit}
              onSubmit={values => {
                onSubmit(values);
              }}
              loading={loading}
            >
              <span>Update</span>
            </button>
          </Level.Item>
        </Level.Item>
      )}
    </Level>
  </Wrapper>
);

export default Footer;
