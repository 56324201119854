/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
// import Loading from 'react-loading';
import * as yup from 'yup';
// import { toast } from 'react-toastify';

import { Input } from '../../../components/elements';

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const FormSection = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 0px !important;
`;

const ErrorDiv = styled.div`
  width: 100%;
  height: 10px;
  margin-bottom: 2px;
  position: relative;
  bottom: 20px;
`;

const getContainerClassNames = isActiveModal =>
  isActiveModal ? 'modal is-active' : 'modal';

const TagsModal = ({
  isActiveModal,
  onClose,
  onSubmitHandlerFromParent = () => {},
  initialValues,
}) => {
  const [tagName, setTagName] = React.useState(initialValues?.tag_name ?? '');

  const [isError, setIsError] = React.useState(false);

  const [errors, setErrors] = React.useState([]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    setIsError(false);
    setErrors([]);
  }, [tagName]);

  const onSubmit = async () => {
    const validationSchema = yup.object().shape({
      tag_name: yup
        .string()
        .test(
          'noSpaces',
          'Please enter a valid Tag Name',
          value => value && value.trim().length > 0,
        )
        .required('Tag Name is required'),
    });

    try {
      setIsSubmitting(true);
      await validationSchema.validate(
        { tag_name: tagName },
        { abortEarly: false },
      );
      setIsError(false);
      setErrors([]);
      onSubmitHandlerFromParent({ tagName });
      setIsSubmitting(false);
      onClose();
    } catch (err) {
      setIsError(true);
      setErrors(err.errors);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={getContainerClassNames(isActiveModal)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '60%', height: '60vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">Add a Tag</ModalTitle>
          <button
            onClick={onClose}
            className="delete"
            aria-label="close"
            type="button"
          />
        </header>

        <FormSection className="modal-card-body">
          <ErrorDiv>
            {isError && <p className="help is-danger is-size-4">{errors[0]}</p>}
          </ErrorDiv>
          <Content>
            <Input
              label="Tag Name"
              name="tag_name"
              value={tagName}
              onChange={e => setTagName(e.target.value)}
            />
          </Content>
          <div className="mt-3">
            <span className="pr-2">
              <button
                type="button"
                className="button is-grey"
                onClick={onClose}
              >
                <span className="has-text-weight-semibold">Cancel</span>
              </button>
            </span>
            <button
              type="button"
              className={`button is-primary ${isSubmitting &&
                'is-loading'} menu-designer-submit-button`}
              onClick={e => {
                e.stopPropagation();
                onSubmit();
              }}
            >
              <span className="has-text-weight-semibold">Submit</span>
            </button>
          </div>
        </FormSection>
      </div>
    </div>
  );
};

export default TagsModal;
