import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Column, Label } from 'rbx';
import { trim } from 'lodash';

import {
  Input,
  Select,
  CheckBox,
  Radio,
  TextArea,
} from '../../../components/elements';
import { statusEnum, sellTypeEnum, sellTypeValue } from '../../../utils/enums';

import PriceVariants from './PriceVariants';

const Field = styled.div`
  align-items: center;
`;

const LabelWrapper = styled.div`
  padding-right: 1rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 7px;
`;

const Information = props => {
  const {
    isLinkExist,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
  } = props;

  return (
    <Fragment>
      <Column.Group multiline vcentered>
        <Column size="4">
          <Input
            label="Item Display Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={event => {
              setFieldValue('name', trim(event.target.value));
              setFieldValue('internal_name', trim(event.target.value));
            }}
            errors={errors.name}
            touched={touched.name}
            disableToastMessage
            required
          />
        </Column>
        <Column size="5">
          <Input
            label="Item Internal Name"
            optionalText="(Visible to only you - Max 50 Characters)"
            maxLength="50"
            name="internal_name"
            value={values.internal_name}
            onChange={handleChange}
            onBlur={event => {
              setFieldValue('internal_name', trim(event.target.value));
            }}
            errors={errors.internal_name}
            touched={touched.internal_name}
            disableToastMessage
            required
          />
        </Column>
        <Column className="is-one-quarter">
          <Select
            label="Status"
            value={{ label: values.status, value: values.status }}
            options={statusEnum}
            onChange={value => setFieldValue('status', value.value)}
            required
          />
        </Column>
        <Column size="4">
          <Field className="is-flex">
            <Select
              label="Item Type(selling type)"
              value={{
                label: sellTypeValue[values.selling_type],
                value: sellTypeValue[values.selling_type],
              }}
              options={sellTypeEnum}
              onChange={value => {
                if (value.value === 'ITEM' || value.value === 'DEAL') {
                  setFieldValue('sell_item_on_its_own', true);
                } else {
                  setFieldValue('sell_item_on_its_own', false);
                  setFieldValue('popular', false);
                  setFieldValue('popular_addon', false);
                }
                setFieldValue('selling_type', value.value);
              }}
              required
            />
          </Field>
        </Column>
        <Column size="4">
          {(values.selling_type === 'ITEM' ||
            values.selling_type === 'DEAL') && (
            <Field className="is-flex">
              <LabelWrapper>
                <Label>Display in Popular Menu Category</Label>
              </LabelWrapper>
              <CheckBox
                value={values.popular}
                onChange={value => setFieldValue('popular', value)}
              />
            </Field>
          )}
        </Column>
        <Column size="4">
          {(values.selling_type === 'ITEM' ||
            values.selling_type === 'DEAL') && (
            <Field className="is-flex">
              <LabelWrapper style={{ justifyContent: 'space-around' }}>
                <Label>Set Item as a default add-On</Label>
              </LabelWrapper>
              <CheckBox
                value={values.popular_addon}
                onChange={value => setFieldValue('popular_addon', value)}
              />
            </Field>
          )}
        </Column>
        {/* <Column size="4">
          <Field className="is-flex">
            <LabelWrapper>
              <Label>Sell item on its own</Label>
            </LabelWrapper>
            <Flex>
              <Radio
                label="Yes"
                value={values.sell_item_on_its_own === true}
                disabled
              />
              &nbsp;&nbsp;
              <Radio
                label="No"
                value={values.sell_item_on_its_own === false}
                disabled
              />
            </Flex>
          </Field>
        </Column> */}
      </Column.Group>
      <hr />
      {isLinkExist && (
        <p className="has-text-weight-semibold has-text-warning">
          Note: You can&apos;t update item price and variant price of this item
          because item override is available in section or modifiers.
          <br />
          <br />
        </p>
      )}
      <PriceVariants isLinkExist={isLinkExist} {...props} />
      <Column.Group>
        <Column>
          <TextArea
            label="Descriptions"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            optionalText="(Max 250 Characters)"
            maxLength="250"
            errors={errors.description}
            touched={touched.description}
            disableToastMessage
          />
        </Column>
      </Column.Group>
      <Column.Group>
        <Column>
          <Field className="is-flex">
            <LabelWrapper>
              <Label>Item stock</Label>
            </LabelWrapper>
            <Flex>
              <Radio
                label="Available"
                value={values.availability_status === 'AVAILABLE'}
                onChange={() =>
                  setFieldValue('availability_status', 'AVAILABLE')
                }
              />
              &nbsp;&nbsp;&nbsp;
              <Radio
                label="Unavailable indefinitely"
                value={values.availability_status === 'UNAVAILABLE'}
                onChange={() =>
                  setFieldValue('availability_status', 'UNAVAILABLE')
                }
              />
            </Flex>
          </Field>
        </Column>
      </Column.Group>
    </Fragment>
  );
};

export default Information;
