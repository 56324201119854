import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';
import Can from '../../../../utils/Can';
import { ACLSConsumer } from '../../../../utils/aclsContext';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({ history, loading, onSubmit, status, location }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/place-photo-approval'
                  ? 'update_image_review'
                  : 'update_image_review'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item>
                  <button
                    className={`button is-primary ${loading && 'is-loading'}`}
                    onClick={() => onSubmit('APPROVED')}
                    disabled={status === 'APPROVED'}
                  >
                    <span>Submit</span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
