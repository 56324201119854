import React from 'react';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { first } from 'lodash';
import { SubHeading, Select } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const SelectWrapper = styled.div`
  width: 10rem;
`;

const options = [
  {
    label: 'Paypal',
    value: 'paypal',
  },
  {
    label: 'Credit Card',
    value: 'credit-card',
  },
  {
    label: 'Debit Card',
    value: 'debit-card',
  },
];

const Header = ({
  heading,
  subHeading1,
  subHeading2,
  subHeading3,
  paymentType,
  setPaymentType,
  step,
  totalQuantity,
}) => {
  const paymentTypeValue = options.filter(item => item.value === paymentType);
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Title size={1}>{heading}</Title>
        </Level.Item>
        {step === 2 && totalQuantity !== 0 && (
          <Level.Item align="right">
            <Level.Item>
              <SelectWrapper>
                <Select
                  value={
                    paymentTypeValue.length !== 0 && first(paymentTypeValue)
                  }
                  options={options}
                  placeholder="Select Payment"
                  name="payment"
                  onChange={value => setPaymentType(value.value)}
                />
              </SelectWrapper>
            </Level.Item>
          </Level.Item>
        )}
      </Level>
      <SubHeading className="is-size-3">{subHeading1}</SubHeading>
      <SubHeading className="is-size-3">{subHeading2}</SubHeading>
      <SubHeading className="is-size-4 has-text-weight-semibold">
        {subHeading3}
      </SubHeading>
    </Wrapper>
  );
};

export default Header;
