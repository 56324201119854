import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

import {
  GoogleMap,
  Circle,
  useLoadScript,
  Polygon,
  Marker,
} from '@react-google-maps/api';
import config from '../../../../utils/config';

const Container = styled.div`
  height: 46vh;
  .map {
    height: 100%;
  }
`;

const defaultCenterLocation = {
  lat: 52.52047739093263,
  lng: 13.36653284549709,
};

const MapView = ({
  initialRadius,
  initialLocation,
  centerLocation,
  onChange,
  otherZones,
}) => {
  const [radius, setRadius] = useState(initialRadius * 1000);
  const circleRef = useRef(null);

  useEffect(() => {
    if (circleRef.current) {
      setRadius(initialRadius * 1000);
    }
  }, [initialRadius]);

  const onEdit = useCallback(() => {
    if (circleRef.current) {
      const radiusValue = circleRef.current.getRadius();
      const latValue = circleRef.current.getCenter().lat();
      const lngValue = circleRef.current.getCenter().lng();
      const location = { latitude: latValue, longitude: lngValue };
      if (radiusValue !== radius) {
        onChange({ location, radius: radiusValue / 1000 });
      }
      setRadius(radiusValue);
    }
  }, []);

  const onLoad = useCallback(
    circle => {
      circleRef.current = circle;
    },
    [onEdit],
  );

  const onUnmount = useCallback(() => {
    circleRef.current = null;
  }, []);

  return (
    <Container>
      <GoogleMap
        mapContainerClassName="map"
        center={centerLocation}
        zoom={12}
        version="weekly"
        on
      >
        <Marker position={centerLocation} animation="DROP" />
        <Circle
          options={{ zIndex: 200 }}
          editable
          draggable
          center={initialLocation}
          radius={radius}
          onMouseUp={onEdit}
          onDragEnd={onEdit}
          onRadiusChanged={onEdit}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
        {otherZones.map(({ color_code, delivery_area }) => {
          console.log('43827482357r', delivery_area);
          return (
            <>
              {delivery_area.area_type === 'CIRCLE' && (
                <Circle
                  options={{
                    strokeColor: chroma(color_code).brighten(2),
                    fillColor: chroma(color_code).brighten(2),
                  }}
                  editable={false}
                  draggable={false}
                  center={{
                    lat: delivery_area.area_circle.location.latitude,
                    lng: delivery_area.area_circle.location.longitude,
                  }}
                  radius={delivery_area.area_circle.radius * 1000}
                />
              )}
              {delivery_area.area_type === 'POLYGON' && (
                <Polygon
                  options={{
                    strokeColor: chroma(color_code).brighten(2),
                    fillColor: chroma(color_code).brighten(2),
                  }}
                  editable={false}
                  draggable={false}
                  path={delivery_area.area_polygon}
                />
              )}
            </>
          );
        })}
      </GoogleMap>
    </Container>
  );
};

const CircularMapSelection = ({
  initialRadius = 1,
  initialLocation = defaultCenterLocation,
  centerLocation = defaultCenterLocation,
  onChange = () => {},
  otherZones = [],
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleApiKey,
    language: 'en',
    region: 'us',
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.{loadError}</div>;
  }

  if (!isLoaded) {
    return 'Loading....';
  }
  return (
    <MapView
      initialRadius={initialRadius}
      initialLocation={initialLocation}
      centerLocation={centerLocation}
      onChange={onChange}
      otherZones={otherZones}
    />
  );
};

export default CircularMapSelection;
