import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import client from '../../../utils/apolloClient';

const deleteGoogleOrderFeedMutation = gql`
  mutation MyMutation($googleOrderFeedId: String!, $userId: String!) {
    delete_google_order_feed(
      input: { google_order_feed_id: $googleOrderFeedId, user_id: $userId }
    ) {
      google_order_feed_id
    }
  }
`;

const GoogleOrderFeedDeleteButton = ({ googleOrderFeedId }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteGoogleOrderFeedMutation}
      onCompleted={({ delete_google_order_feed }) => {
        if (delete_google_order_feed.error) {
          delete_google_order_feed.error.map(item =>
            toast.error(item.description),
          );
        } else {
          toast.success('Google Order Feed deleted successfully!');
          window.location.reload(true);
        }
      }}
    >
      {(delete_google_order_feed, { loading }) => (
        <button
          className={`button ${loading && 'is-loading'}`}
          onClick={() => {
            swal({
              title: 'Are you sure?',
              // eslint-disable-next-line max-len
              text: `Are you sure you want to delete this Google Order Feed?`,
              buttons: ['Cancel', 'Yes'],
              dangerMode: true,
            }).then(willDelete => {
              if (willDelete) {
                delete_google_order_feed({
                  variables: {
                    userId,
                    googleOrderFeedId,
                  },
                });
              }
            });
          }}
        >
          <i className="fas fa-trash has-text-grey" />
        </button>
      )}
    </Mutation>
  );
};

export default GoogleOrderFeedDeleteButton;
