import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
// import uuidv4 from 'uuid/v4';
import PlaceDashboard from './PlaceDashboard';
import PlaceFollowers from './PlaceFollowers';
import PlaceReviewers from './PlaceReviewers';
import PlaceSocial from './PlaceSocial';
import UserUploadedPlaceImg from './UserUploadedPlaceImg';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Place Dashboard',
  },
  {
    key: 1,
    name: 'Place Followers',
  },
  {
    key: 2,
    name: 'Place Reviewers',
  },
  {
    key: 3,
    name: 'Place Social',
  },
  {
    key: 4,
    name: 'User Uploaded Place Images',
  },
];

const Form = props => {
  const { place } = props;
  const [activeTab, setActiveTab] = useState(0);
  const prev = () =>
    activeTab === 0 ? setActiveTab(4) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 4 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  return (
    <React.Fragment>
      <Header
        heading={place.name}
        subHeading={`${place.city}, ${place.post_code} | ${place.country} (${place.status} / ${place.claim_status})`}
        next={next}
        prev={prev}
        activeTab={activeTab}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>

        <Container>
          {activeTab === 0 && <PlaceDashboard {...props} />}
          {activeTab === 1 && <PlaceFollowers {...props} />}
          {activeTab === 2 && <PlaceReviewers {...props} />}
          {activeTab === 3 && <PlaceSocial {...props} />}
          {activeTab === 4 && <UserUploadedPlaceImg {...props} />}
        </Container>
      </Box>
      <hr />
      <Footer next={next} prev={prev} activeTab={activeTab} />
    </React.Fragment>
  );
};

const PlaceActivity = withFormik({
  mapPropsToValues: ({ place }) => ({
    default_image_url: place.default_image_url,
  }),

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'PlaceActivity',
})(Form);

export default PlaceActivity;
