import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Notification = styled.button`
  &&& {
    background-color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 25%;
  }
`;

const OnButton = styled.button`
  &&& {
    background-color: #65b44b;
    font-weight: 600;
    border-top-left-radius: 290486px;
    border-top-right-radius: 290486px;
    border-bottom-right-radius: 290486px;
    border-bottom-left-radius: 290486px;
    border: none;
  }
`;

const OffButton = styled.button`
  &&& {
    background-color: red;

    font-weight: 600;
    border-top-left-radius: 290486px;
    border-top-right-radius: 290486px;
    border-bottom-right-radius: 290486px;
    border-bottom-left-radius: 290486px;
    border: none;
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:first-child) {
    margin-bottom: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-left: 10rem;
  cursor: pointer;
`;

const Header = ({
  setIsActiveStartStopModal,
  takeOrder,
  placeName,
  placeLocation,
  receivedOrdersLength,
}) => (
  <Wrapper>
    <Level>
      <div>
        <SubHeading className="is-size-2 has-text-weight-medium	">
          {placeName}
        </SubHeading>
        <SubHeading className="is-size-2">{placeLocation}</SubHeading>
      </div>

      {receivedOrdersLength !== 0 && (
        <Notification
          type="button"
          className="button is-primary control has-text-weight-semibold"
        >
          <i className="fa fa-bell" style={{ fontSize: '15px' }} />
          <p
            className="is-size-2 has-text-weight-semibold"
            style={{ paddingLeft: '8px' }}
          >
            {receivedOrdersLength} New Orders
          </p>
        </Notification>
      )}
      <Level.Item align="right">
        <div>
          {takeOrder === true && (
            <Flex onClick={() => setIsActiveStartStopModal(true)}>
              <OnButton type="button" className="button control">
                <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                  Open
                </p>
              </OnButton>
              <p className="is-size-3 has-text-weight-semibold has-text-primary">
                Change
              </p>
            </Flex>
          )}
          {takeOrder === false && (
            <Flex onClick={() => setIsActiveStartStopModal(true)}>
              <OffButton type="button" className="button control">
                <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                  Closed
                </p>
              </OffButton>
              <p className="is-size-3 has-text-weight-semibold has-text-primary">
                Change
              </p>
            </Flex>
          )}
        </div>
      </Level.Item>
    </Level>
  </Wrapper>
);
export default withRouter(Header);
