import React from 'react';
import { Link } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => (
  <>
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Title className="is-size-2">Manage Table Booking Settings</Title>
        </Level.Item>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action="create_menu"
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item align="right">
                  <Link
                    to="/add-table-booking-settings"
                    className="button is-primary"
                  >
                    <span className="has-text-weight-semibold">
                      Add Table Booking Setting
                    </span>
                  </Link>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
      </Level>
    </Wrapper>
  </>
);

export default Header;
