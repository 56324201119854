import gql from 'graphql-tag';

import { forEach } from 'lodash';

import client from '../../../utils/apolloClient';

const searchOrdersQuery = gql`
  query searchOrders($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
        status
        place_id
        order_reference
        service_type
        table_number
        table_name
        pickup_method
        vehicle_information {
          car_detail
          license_plate
        }
        refunds {
          refund_id
          refund_amount
          refund_reason
          status
          refund_from_place
          refund_application_fee
          created_at
          created_by
          note {
            value
          }
        }
        billing {
          delivery_fee_discount
          sub_total
          order_total
          deal_discount
          payment_gateway_fee
          adjustment_amount
          order_amount
          delivery_fee
          charge
          charges_list {
            name
            value
            is_payment_gateway_fee
          }
          surcharge_list {
            name
            charge
            menu_fee_id
          }
        }
        estimates {
          type
          value_in_mins
        }
        service_type_setting {
          display_table_number
          allow_pickup_options
          pickup_method
          pickup_instructions
          include_vehicle_information
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const actionOrderMutation = gql`
  mutation actionOrder($input: NavigateMenuOrderInput) {
    action_menu_order(input: $input) {
      menu_id
      status
      error {
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getReceivedOrders = ({ placeId, status }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchOrdersQuery,
        variables: {
          input: {
            filter: {
              menu_order_filter: {
                place_id: placeId,
                status,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const handleOrderAction = ({ menuId, userId, action }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: actionOrderMutation,
        variables: {
          input: {
            menu_order_id: menuId,
            action,
            user_id: userId,
          },
        },
      })
      .then(({ data }) => {
        console.log('data', data);
        resolve(data);
        // if (
        //   data.search_menu_orders &&
        //   data.search_menu_orders.menu_order_listing
        // ) {
        //   resolve(data.search_menu_orders.menu_order_listing);
        // } else {
        //   resolve([]);
        // }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const fetchPlaceHours = gql`
  query fetchPlaceAPI($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      hours
      hours_pickup
      hours_dinein
      hours_delivery
    }
  }
`;

const fetchPlaceHoursFromBackend = async ({ placeId }) => {
  const data = await client.clientPrivate.query({
    client: client.clientPrivate,
    query: fetchPlaceHours,
    variables: {
      placeId,
    },
    fetchPolicy: 'network-only',
  });
  if (data.data && data.data.fetch_place) {
    return data.data.fetch_place;
  }
  return null;
};

export const placeOrderStatus = async placeId => {
  const calculateIsOpen = ({ place }) => {
    let isPlaceOpen = false;
    const uniqHours = [
      place.hours_pickup,
      place.hours_dinein,
      place.hours_delivery,
    ];
    forEach(uniqHours, item => {
      if (item === 'OPENED') {
        isPlaceOpen = true;
      }
    });
    return isPlaceOpen;
  };

  const place = await fetchPlaceHoursFromBackend({ placeId });
  if (place) {
    const isOpen = calculateIsOpen({ place });
    return { placeId, isOpen };
  }
  return { placeId, isOpen: false };
};

export const placesOrderStatus = async placeIds => {
  const data = await Promise.all(
    placeIds.map(placeId => placeOrderStatus(placeId)),
  );
  const temp = {};
  data.forEach(ele => {
    temp[ele.placeId] = ele.isOpen;
  });
  return temp;
};
