import React from 'react';
import lowerCase from 'lodash/lowerCase';
import styled from 'styled-components';
import { Label } from 'rbx';

import {
  CheckBox,
  Radio,
  Input,
  CreatableSelectInputs,
  Select,
} from '../../../../components/elements';
import AutoCancelCounter from './AutoCancelCounter';
import OrderSettingsModal from './OrderSettingsModal';
import MenuModal from './MenuModal';

const Container = styled.div`
  display: flex;
  .left-column {
    width: 50%;
  }
  .right-column {
    width: 50%;
  }
`;

const ChangeButton = styled(Label)`
  color: #00aeef;
  padding-left: 60px;
  cursor: pointer;
`;

const OrderSettingsField = styled.div`
  font-size: 14px;
  background-color: #e2e2e2;
  color: #737373;
  padding: 10px;
  borderradius: 4px;
  min-height: 42px;
`;

const orderSettingsTextContent = orderSettings => {
  const text = {
    order_number: 'Order Number',
    delivery_details: 'Delivery Details',
    pickup_details: 'Pickup Details',
    customer_email: 'Customer Email',
    order_items: 'Order Items',
    delivery_fees: 'Delivery Fees',
    total: 'Total',
    payment_details: 'Payment Details',
  };
  const temp = [];
  Object.keys(orderSettings).forEach(ele => {
    if (orderSettings[ele]) {
      temp.push(text[ele]);
    }
  });
  return temp.join(', ').trimEnd();
};

const clone = objToClone => {
  const orderSettings = objToClone.order_settings;
  const newObj = {};
  const newOrderSettings = {};
  Object.keys(orderSettings).forEach(ele => {
    newOrderSettings[ele] = !!orderSettings[ele];
  });
  Object.assign(
    newObj,
    { name: objToClone.name },
    { menu_id: objToClone.menu_id },
    { api_key: objToClone.api_key },
    { order_settings: newOrderSettings },
  );

  return newObj;
};

const OtherSettings = ({ values, setFieldValue, errors, touched }) => {
  const [
    orderSettingModalIsActive,
    setOrderSettingModalIsActive,
  ] = React.useState(false);

  const [menuModalIsActive, setMenuModalIsActive] = React.useState(false);

  const [menuNames, setMenuNames] = React.useState([]);

  const defaultDeliveryIntegrationObj = React.useMemo(
    () => clone(values.delivery_integration),
    [],
  );
  console.log('value', values, errors);

  const defaultMenuOptions = [
    { value: 'KRAVEIN', label: 'KRAVEIN' },
    { value: 'DELIVERECT', label: 'DELIVERECT' },
  ];

  const defaultMenuValue = {
    KRAVEIN: [{ value: 'KRAVEIN', label: 'KRAVEIN' }],
    DELIVERECT: [{ value: 'DELIVERECT', label: 'DELIVERECT' }],
  }[values.default_menu];

  return (
    <Container>
      <div className="left-column">
        <div style={{ width: '70%', marginBottom: '2rem' }}>
          <CreatableSelectInputs
            label="Additional Email"
            value={values.addition_emails.map(item => ({
              value: item,
              label: item,
            }))}
            suggestions={[]}
            onChange={value => {
              if (value) {
                const temp = [...value].map(ele => ele.value);
                setFieldValue('addition_emails', temp);
              } else {
                setFieldValue('addition_emails', []);
              }
            }}
            errors={
              Array.isArray(errors.addition_emails)
                ? errors.addition_emails.filter(ele => ele)[0]
                : errors.addition_emails
            }
            touched={touched.addition_emails}
          />
          <Select
            label="Default Menu"
            options={defaultMenuOptions}
            value={defaultMenuValue}
            onChange={value => setFieldValue('default_menu', value.value)}
            disableToastMessage
            required
          />
        </div>
        <Label className="is-flex">
          <span style={{ width: '130px' }}>Auto Confirm Order</span>
          <CheckBox
            value={values.auto_confirm}
            onChange={value => {
              setFieldValue('auto_confirm', value);
            }}
          />
        </Label>
        <Label className="is-flex">
          <span style={{ width: '130px' }}>Auto Ready Order</span>
          <CheckBox
            value={values.auto_ready}
            onChange={value => {
              setFieldValue('auto_ready', value);
            }}
          />
        </Label>
        <div style={{ display: 'flex' }}>
          <Label className="is-flex">
            <span style={{ width: '130px' }}>Auto Cancel Order</span>
            <CheckBox
              value={values.is_auto_cancel}
              onChange={value => {
                setFieldValue('is_auto_cancel', value);
              }}
            />
          </Label>
          &nbsp;
          {values.is_auto_cancel && (
            <AutoCancelCounter values={values} setFieldValue={setFieldValue} />
          )}
        </div>
        <br />
        {lowerCase(values.service_type) === 'delivery' && (
          <>
            <Label className="is-flex">Delivery Channel</Label>
            <div style={{ display: 'flex' }}>
              <Radio
                label="Restaurant"
                value={values.delivery_channel === 'RESTAURANT'}
                onChange={value => {
                  if (value) {
                    setFieldValue('delivery_channel', 'RESTAURANT');
                  } else {
                    setFieldValue('delivery_channel', 'KRAVEIN');
                  }
                }}
              />
              &nbsp;
              <Radio
                label="KRAVEiN"
                value={values.delivery_channel === 'KRAVEIN'}
                onChange={value => {
                  if (value) {
                    setFieldValue('delivery_channel', 'KRAVEIN');
                  } else {
                    setFieldValue('delivery_channel', 'RESTAURANT');
                  }
                }}
              />
            </div>
          </>
        )}
        <Label className="is-flex">
          <span style={{ width: '140px' }}>Allow users to cancel orders</span>
          <CheckBox
            value={values.allow_order_cancellation}
            onChange={value => {
              setFieldValue('allow_order_cancellation', value);
            }}
          />
        </Label>
      </div>
      {lowerCase(values.service_type) === 'delivery' && (
        <>
          {orderSettingModalIsActive && (
            <OrderSettingsModal
              isActive={orderSettingModalIsActive}
              onClose={() => setOrderSettingModalIsActive(false)}
              deliveryIntegration={values.delivery_integration}
              setFieldValue={setFieldValue}
            />
          )}
          <MenuModal
            isActive={menuModalIsActive}
            onClose={() => setMenuModalIsActive(false)}
            deliveryIntegration={values.delivery_integration}
            setFieldValue={setFieldValue}
            setMenuNames={setMenuNames}
            serviceSettingId={values.service_type_setting_id}
          />
          <div className="right-column">
            <Label>Delivery Partner Integration Settings:</Label>
            <Label>Delivery Integration:</Label>
            <div style={{ width: '70%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Radio
                  label="None"
                  value={values.delivery_integration.name !== 'SHIPDAY'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('delivery_integration', {
                        ...clone(defaultDeliveryIntegrationObj),
                        name: 'None',
                      });
                    }
                  }}
                />
                <Radio
                  label="Shipday"
                  value={values.delivery_integration.name === 'SHIPDAY'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('delivery_integration', {
                        ...clone(defaultDeliveryIntegrationObj),
                        name: 'SHIPDAY',
                      });
                    }
                  }}
                />
              </div>
              {values.delivery_integration.name === 'SHIPDAY' && (
                <>
                  <Input
                    label="API Key"
                    value={values.delivery_integration.api_key}
                    onChange={e => {
                      setFieldValue('delivery_integration', {
                        ...values.delivery_integration,
                        api_key: e.target.value,
                      });
                    }}
                    disableToastMessage
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Label>Order Setting</Label>
                    <ChangeButton
                      onClick={() => setOrderSettingModalIsActive(true)}
                    >
                      Change
                    </ChangeButton>
                  </div>
                  <OrderSettingsField>
                    {orderSettingsTextContent(
                      values.delivery_integration.order_settings,
                    )}
                  </OrderSettingsField>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '10px',
                    }}
                  >
                    <Label>Menus</Label>
                    <ChangeButton onClick={() => setMenuModalIsActive(true)}>
                      Change
                    </ChangeButton>
                  </div>
                  <OrderSettingsField>
                    {menuNames.join(', ').trimEnd()}
                  </OrderSettingsField>
                </>
              )}
            </div>
            <div>
              <br />
              <Label>Deliverect Settings</Label>
              <div style={{ width: '70%' }}>
                <Input
                  label="Platform"
                  value={values.platform}
                  // onChange={e => {
                  //   setFieldValue('delivery_integration', {
                  //     ...values.delivery_integration,
                  //     api_key: e.target.value,
                  //   });
                  // }}
                  disabled
                />
                <Input
                  label="Channel Link Id"
                  value={values.channel_link_id}
                  // onChange={e => {
                  //   setFieldValue('delivery_integration', {
                  //     ...values.delivery_integration,
                  //     api_key: e.target.value,
                  //   });
                  // }}
                  disabled
                />
                <Label className="is-flex">
                  <span style={{ width: '140px' }}>
                    Do not send to Deleverect POS
                  </span>
                  <CheckBox
                    value={values.not_allow_order_to_create_on_deliverect}
                    // onChange={value => {
                    //   setFieldValue('allow_order_cancellation', value);
                    // }}
                  />
                </Label>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default OtherSettings;
