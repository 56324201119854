import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Column, Level, Table } from 'rbx';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';
import PlaceReviewersList from './PlaceReviewersList';
import {
  Input,
  Table as CustomTable,
  Loading,
  Pagination,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaceReviewers($input: SearchInput) {
    search_place_review_activity(input: $input) {
      total_size
      total_pages
      place_review_listing {
        place_id
        audit {
          updated_by
          updated_at
          created_by
          created_at
        }
      }
      place_review_aggregation {
        total_review_by_user {
          user
          count
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const Container = styled.div``;

const Summary = ({ place, props }) => {
  const [reviewData, setReviewData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(3);
  const input = {};
  const place_review_filter = {};
  Object.assign(place_review_filter);
  Object.assign(
    input,
    { from: pageFrom, size: listSize },
    {
      filter: {
        place_review_filter: { place_id: place.place_id },
      },
    },
  );
  return (
    <React.Fragment>
      <Level>
        <Column size={3}>
          <Input label="Total Reviews" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Total Reviewers" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Average Rating" placeholder="4.22" disabled />
        </Column>
      </Level>
      {totalElements > 0 && (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Login
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Reviewed On
                </span>
                <br />
              </div>
            </Table.Heading>

            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Rating
                </span>
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>

        <Query
          client={client.clientPrivate}
          query={placesQuery}
          variables={{ input }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }

            const { search_place_review_activity } = data;

            if (isNull(search_place_review_activity)) {
              return 'no data found';
            }

            return (
              <React.Fragment>
                <View
                  reviewData={reviewData}
                  setReviewData={setReviewData}
                  search_place_review_activity={search_place_review_activity}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </CustomTable>
    </React.Fragment>
  );
};

const View = ({
  search_place_review_activity,
  setPageCount,
  setTotalElements,
  setReviewData,
  reviewData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_place_review_activity.total_pages);
    setTotalElements(search_place_review_activity.total_size);

    if (search_place_review_activity.place_review_listing) {
      setReviewData(search_place_review_activity.place_review_listing);
      const userFilter = search_place_review_activity.place_review_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_place_review_activity.total_pages]);

  return (
    <tbody>
      {reviewData.map(review => (
        <PlaceReviewersList review={review} users={users} />
      ))}
    </tbody>
  );
};

export default Summary;
