import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:first-child) {
    margin-bottom: 0;
  }
`;

const Header = ({ placeName, placeLocation }) => (
  <Wrapper>
    <Title size={2}>Menu & Custom Hours</Title>
    <Level>
      <div>
        <SubHeading className="is-size-2 has-text-weight-medium">
          {placeName}
        </SubHeading>
        <SubHeading className="is-size-2">{placeLocation}</SubHeading>
      </div>

      <Level.Item />
    </Level>
  </Wrapper>
);

export default withRouter(Header);
