import gql from 'graphql-tag';
import uuid from 'uuid';
import { omit, forEach } from 'lodash';
import client from '../../../utils/apolloClient';

const searchServiceQuery = gql`
  query searchService($input: SearchInput) {
    search_service_type(input: $input) {
      service_type_listing {
        name
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getServiceType = () =>
  new Promise((resolve, reject) => {
    const filter = { service_type_filter: { status: 'ACTIVE' } };
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchServiceQuery,
        variables: {
          input: {
            filter,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_service_type &&
          data.search_service_type.service_type_listing
        ) {
          resolve(data.search_service_type.service_type_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const dayEstimateValueParser = estimates => {
  const data = estimates.map(item => ({ id: uuid(), ...item }));
  return data;
};

export const reverseDayEstimateValueParser = estimates => {
  const data = estimates.map(item => omit(item, ['id', '__typename']));
  return data;
};

export const removeTypenameValueParser = values => {
  const data = values.map(item => omit(item, '__typename'));
  return data;
};

export const removeTypenameValueParserInZoneSetting = values => {
  const data = values.map(item => omit(item, '__typename'));
  const final = [];
  forEach(data, item => {
    const area_circle = {};
    const area_polygon = [];
    const areaCircleLocation =
      item.delivery_area &&
      item.delivery_area.area_circle &&
      omit(item.delivery_area.area_circle.location, '__typename');
    const areaCircleRadius =
      item.delivery_area &&
      item.delivery_area.area_circle &&
      item.delivery_area.area_circle.radius;
    Object.assign(
      area_circle,
      areaCircleLocation && {
        location: areaCircleLocation,
        radius: areaCircleRadius,
      },
    );
    if (item.delivery_area && item.delivery_area.area_polygon) {
      forEach(item.delivery_area.area_polygon, item2 => {
        const temp = omit(item2, '__typename');
        area_polygon.push(temp);
      });
    }
    const finalTemp = {
      area_type: item.delivery_area ? item.delivery_area.area_type : null,
      area_circle,
      area_polygon,
    };
    final.push({ ...item, delivery_area: finalTemp });
  });
  return final;
};

const serviceTypeSettingOpenClose = gql`
  query serviceTypeSettingOpenClose(
    $input: NavigateServiceTypeSettingOpenCloseInput
  ) {
    fetch_service_type_setting_open_close(input: $input) {
      hours_delivery
      hours_dinein
      hours_pickup
    }
  }
`;

export const getServiceTypeSettingOpenClose = service_type_setting_id =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: serviceTypeSettingOpenClose,
        variables: {
          input: {
            service_type_setting_id,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const serviceTypeOpenCloseMutation = gql`
  mutation serviceTypeOpenCloseMutation(
    $input: ServiceTypeSettingOpenCloseInput
  ) {
    update_service_type_setting_open_close(input: $input) {
      service_type_setting_id
      hours_dinein
      hours_pickup
      hours_delivery
    }
  }
`;

export const updateServiceTypeOpenClose = (service_type_setting_id, hours) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        client: client.clientPrivate,
        mutation: serviceTypeOpenCloseMutation,
        variables: {
          input: {
            service_type_setting_id,
            hours,
          },
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
