import React from 'react';
import styled from 'styled-components';

const StatisticSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1px;
  padding: 15px;
  color: #505050;
`;

const StatisticSectionItems = styled.div`
  font-size: 12px;
  text-align: left;
`;

const formatDateProp = dateObj => {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return dateObj.toLocaleDateString('en-US', options);
};

const StatisticSection = ({
  dateFilter,
  totalBookings,
  totalGuestCount,
  totalSeated,
  totalCancelled,
}) => (
  <StatisticSectionContainer>
    <StatisticSectionItems>{formatDateProp(dateFilter)}</StatisticSectionItems>
    <StatisticSectionItems>
      Total Booking: {totalBookings ?? 0}
    </StatisticSectionItems>
    <StatisticSectionItems>
      Guests: {totalGuestCount ?? 0}
    </StatisticSectionItems>
    <StatisticSectionItems>Seated: {totalSeated ?? 0}</StatisticSectionItems>
    <StatisticSectionItems>
      Cancelled: {totalCancelled ?? 0}
    </StatisticSectionItems>
  </StatisticSectionContainer>
);

export default StatisticSection;
