/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import { CheckBox } from '../../../components/elements';

const Content = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  gap: 20px;
`;

const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const FilterHeading = styled.h1`
  font-size: 10px;
  font-weight: 600;
  padding-bottom: 12px;
`;

const FormSection = styled.section``;

const TagName = styled.div`
  border: 1px solid #e2e2e2;
  font-size: 8px;
  padding: 0.2rem;
  color: #505050;
  font-weight: 600;
`;

const getContainerClassNames = isActiveModal =>
  isActiveModal ? 'modal is-active' : 'modal';

const StatusFilterModal = ({
  initialGuestStatusFilter,
  initialBookingStatusFilter,
  isActiveModal,
  onClose,
  changeRoute,
}) => {
  const [bookingStatusFilter, setBookingStatus] = React.useState(
    initialBookingStatusFilter,
  );
  const [guestStatusFilter, setGuestStatus] = React.useState(
    initialGuestStatusFilter,
  );

  return (
    <div className={getContainerClassNames(isActiveModal)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '60%', height: '60vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">Status</ModalTitle>
          <button
            onClick={() => {
              changeRoute({ guestStatusFilter, bookingStatusFilter });
              onClose();
            }}
            className="delete"
            aria-label="close"
            type="button"
          />
        </header>
        <FormSection className="modal-card-body">
          <div className="is-flex">
            <div>
              <FilterHeading>Booking Status</FilterHeading>
              <Content>
                <TagName>
                  <span>Confirmed</span>
                </TagName>
                <div>
                  <CheckBox
                    value={bookingStatusFilter === 'Confirmed'}
                    onChange={() => {
                      if (bookingStatusFilter === 'Confirmed') {
                        setBookingStatus();
                      } else {
                        setBookingStatus('Confirmed');
                      }
                    }}
                  />
                </div>
                <TagName>
                  <span>Waitlisted</span>
                </TagName>
                <div>
                  <CheckBox
                    value={bookingStatusFilter === 'Waitlisted'}
                    onChange={() => {
                      if (bookingStatusFilter === 'Waitlisted') {
                        setBookingStatus();
                      } else {
                        setBookingStatus('Waitlisted');
                      }
                    }}
                  />
                </div>
                <TagName>
                  <span>Modified</span>
                </TagName>
                <div>
                  <CheckBox
                    value={bookingStatusFilter === 'Modified'}
                    onChange={() => {
                      if (bookingStatusFilter === 'Modified') {
                        setBookingStatus();
                      } else {
                        setBookingStatus('Modified');
                      }
                    }}
                  />
                </div>
                <TagName>
                  <span>Received</span>
                </TagName>
                <div>
                  <CheckBox
                    value={bookingStatusFilter === 'Received'}
                    onChange={() => {
                      if (bookingStatusFilter === 'Received') {
                        setBookingStatus();
                      } else {
                        setBookingStatus('Received');
                      }
                    }}
                  />
                </div>
                <TagName>
                  <span>Cancelled</span>
                </TagName>
                <div>
                  <CheckBox
                    value={bookingStatusFilter === 'Cancelled'}
                    onChange={() => {
                      if (bookingStatusFilter === 'Cancelled') {
                        setBookingStatus();
                      } else {
                        setBookingStatus('Cancelled');
                      }
                    }}
                  />
                </div>
              </Content>
            </div>
            <div>
              <FilterHeading>Guest Status</FilterHeading>
              <Content>
                <TagName>
                  <span>Cancelled</span>
                </TagName>
                <div>
                  <CheckBox
                    value={guestStatusFilter === 'Cancelled'}
                    onChange={() => {
                      if (guestStatusFilter === 'Cancelled') {
                        setGuestStatus();
                      } else {
                        setGuestStatus('Cancelled');
                      }
                    }}
                  />
                </div>

                <TagName>
                  <span>Upcoming</span>
                </TagName>
                <div>
                  <CheckBox
                    value={guestStatusFilter === 'Upcoming'}
                    onChange={() => {
                      if (guestStatusFilter === 'Upcoming') {
                        setGuestStatus();
                      } else {
                        setGuestStatus('Upcoming');
                      }
                    }}
                  />
                </div>
                <TagName>
                  <span>Checked in</span>
                </TagName>
                <div>
                  <CheckBox
                    value={guestStatusFilter === 'Checked in'}
                    onChange={() => {
                      if (guestStatusFilter === 'Checked in') {
                        setGuestStatus();
                      } else {
                        setGuestStatus('Checked in');
                      }
                    }}
                  />
                </div>
                <TagName>
                  <span>Seated</span>
                </TagName>
                <div>
                  <CheckBox
                    value={guestStatusFilter === 'Seated'}
                    onChange={() => {
                      if (guestStatusFilter === 'Seated') {
                        setGuestStatus();
                      } else {
                        setGuestStatus('Seated');
                      }
                    }}
                  />
                </div>

                <TagName>
                  <span>No Show</span>
                </TagName>
                <div>
                  <CheckBox
                    value={guestStatusFilter === 'No Show'}
                    onChange={() => {
                      if (guestStatusFilter === 'No Show') {
                        setGuestStatus();
                      } else {
                        setGuestStatus('No Show');
                      }
                    }}
                  />
                </div>
              </Content>
            </div>
          </div>
        </FormSection>
      </div>
    </div>
  );
};

export default StatusFilterModal;
