import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import { Loading, Message } from '../../../components/elements';
import client from '../../../utils/apolloClient';

import { useCurrentTimeZone } from '../UserPromo/DealForm/helpers';

import Form from '../UserPromo/DealForm/Form';

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      date_of_birth
      gender
      description
      status
      user_handle
      external_payment_id
      visibility
      rating
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      access {
        place_id
      }
      address {
        address_id
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const updateDealMutation = gql`
  mutation updateDeal($input: PlaceMenuDealInput) {
    update_place_menu_deal(input: $input) {
      deal_id
      error {
        description
      }
    }
  }
`;

const UpdateForm = ({ history, updateInput }) => {
  const [timeZone, setTimeZone] = React.useState('');

  useCurrentTimeZone({
    userId: updateInput.userId,
    placeId: updateInput.dealsData.place_id,
    stateSetterFn: value => setTimeZone(value),
  });

  if (timeZone === '') {
    return null;
  }

  return (
    <Query
      client={client.clientPrivate}
      query={userQuery}
      variables={{
        userId: updateInput.userId,
        user_handle: '',
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <Message>error</Message>;
        }

        const { fetch_user } = data;

        return (
          <>
            <Mutation
              client={client.clientPrivate}
              mutation={updateDealMutation}
              onCompleted={({ update_place_menu_deal }) => {
                if (update_place_menu_deal.error) {
                  update_place_menu_deal.error.map(item =>
                    toast.error(item.description),
                  );
                } else {
                  swal(
                    'Great!',
                    'User Menu Deal has been updated successfully!',
                    'success',
                  ).then(() => {
                    history.push('/user-promos');
                  });
                }
              }}
            >
              {(
                update_place_menu_deal,
                { loading: updateDealLoading, error: updateDealError },
              ) => (
                <Form
                  isUpdateForm
                  loading={updateDealLoading}
                  error={updateDealError}
                  user={fetch_user}
                  deal={updateInput.dealsData}
                  currentTimeZone={timeZone}
                  onSubmit={input => {
                    update_place_menu_deal({ variables: { input } });
                  }}
                />
              )}
            </Mutation>
          </>
        );
      }}
    </Query>
  );
};

export default UpdateForm;
