import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({
  action,
  loading,
  handleApproveAction,
  flag,
  history,
  handleRejectAction,
  location,
  status,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>

      <Level.Item align="right">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/flagged-review'
                  ? 'action_flag'
                  : 'action_flag'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  {status !== 'REJECTED' && (
                    <Level.Item>
                      <button
                        className={`button is-danger ${action === 'REJECTED' &&
                          loading &&
                          'is-loading'}`}
                        onClick={handleRejectAction}
                        disabled={flag.status === 'REJECTED'}
                      >
                        <span>Reject</span>
                      </button>
                    </Level.Item>
                  )}
                </>
              )}
            />
          )}
        </ACLSConsumer>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/flagged-review'
                  ? 'action_flag'
                  : 'action_flag'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <>
                  {status !== 'APPROVED' && (
                    <Level.Item>
                      <button
                        className={`button is-primary ${action === 'APPROVED' &&
                          loading &&
                          'is-loading'}`}
                        onClick={() => handleApproveAction()}
                        disabled={flag.status === 'APPROVED'}
                      >
                        <span>Unpublish and Inactivate</span>
                      </button>
                    </Level.Item>
                  )}
                </>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
