import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import client from '../../../../utils/apolloClient';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  margin: 1rem 0rem;
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.lightGreyAlpha : theme.white};
`;

const Item = styled(Flex)`
  padding: 1rem 0.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.lightGreyBeta};
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.lightGreyBeta : theme.white};
`;

const IconWrapper = styled.span`
  padding-right: 1rem;
`;

// const deleteMenuSectionMutation = gql`
//   mutation deleteMenuSectionLink($input: [NavigateMenuSectionInput]) {
//     delete_menu_section(input: $input) {
//       menu_section_id
//       error {
//         description
//       }
//     }
//   }
// `;

const updateMenuSectionMutation = gql`
  mutation updateItemLink($input: [MenuSectionInput]) {
    update_menu_section(input: $input) {
      menu_section_id
      error {
        description
      }
    }
  }
`;

const Row = ({ item, index }) => (
  // const [deleteSectionLink, { loading }] = useMutation(
  //   deleteMenuSectionMutation,
  //   {
  //     client: client.clientPrivate,
  //     variables: {
  //       input: [{ user_id: userId, menu_section_id: item.menu_section_id }],
  //     },
  //     onCompleted: () => onDelete(item.menu_section_id),
  //   },
  // );

  <Draggable
    key={item.item_link_id}
    draggableId={item.menu_section_id}
    index={index}
  >
    {(provided2, snapshot2) => (
      <Item
        ref={provided2.innerRef}
        {...provided2.draggableProps}
        {...provided2.dragHandleProps}
        isDragging={snapshot2.isDragging}
      >
        <Flex>
          <IconWrapper>
            <i className="fas fa-arrows-alt" />
          </IconWrapper>
          <p className="is-size-2 has-text-weight-semibold">{item.name}</p>
        </Flex>
        {/* <button
            type="button"
            className={`button ${loading && 'is-loading'}`}
            onClick={() => {
              deleteSectionLink();
            }}
          >
            <i className="far fa-trash-alt" />
          </button> */}
      </Item>
    )}
  </Draggable>
);
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Items = ({ items, onDelete }) => {
  const { userId } = useStoreState(state => state.auth);
  const [itemsData, setItemsData] = useState(items);

  const [updateMenuSection] = useMutation(updateMenuSectionMutation, {
    client: client.clientPrivate,
    onCompleted: () => {},
  });

  useEffect(() => {
    setItemsData(items);
  }, [items]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      itemsData,
      result.source.index,
      result.destination.index,
    );
    setItemsData(newItems);
    updateMenuSection({
      variables: {
        input: newItems.map((item, index) => ({
          user_id: userId,
          menu_section_id: item.menu_section_id,
          display_order: index,
        })),
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {itemsData.map((item, index) => (
              <Row
                item={item}
                index={index}
                onDelete={onDelete}
                userId={userId}
              />
            ))}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Items;
