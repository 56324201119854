import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
// import moment from 'moment';
import { first } from 'lodash';
import { Input, Radio, Select } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const discount_types = [
  { value: '', label: 'All' },
  { value: 'listing_only', label: 'Listing Only' },
  { value: 'flat_amount_discount', label: 'Flat Amount Discount' },
  { value: 'percentage_discount', label: 'Percentage Discount' },
  { value: 'delivery_fee_discount', label: 'Delivery Fee Discount' },
  { value: 'buy_one_get_one_promo', label: 'Buy One Get One Promo' },
  { value: 'specific_item_discount', label: 'Specific Item Discount' },
];

const Search = ({
  status,
  setStatus,
  setPlaceName,
  placeName,
  voucherNameSearch,
  handleVoucherNameSearch,
  setDiscountType,
  discountType,
  setLocation,
  dealStatus,
  defaultPlace,
  setDealStatus,
}) => {
  const [city, setCity] = useState('');
  const discountTypeValue = discount_types.filter(
    item => item.value === discountType,
  );

  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="ACTIVE"
              value={dealStatus === 'ACTIVE'}
              onChange={() => {
                setDealStatus('ACTIVE');
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="INACTIVE"
              value={dealStatus === 'INACTIVE'}
              onChange={() => {
                setDealStatus('INACTIVE');
              }}
            />
          </Level.Item>
        </Level.Item>

        <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Place Deal Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Current"
              value={status === 'CURRENT'}
              onChange={() => {
                setStatus('CURRENT');
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Past"
              value={status === 'PAST'}
              onChange={() => {
                setStatus('PAST');
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Future"
              value={status === 'FUTURE'}
              onChange={() => {
                setStatus('FUTURE');
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-3">
            <Input
              label="Place Name"
              type="text"
              value={placeName}
              onChange={event => setPlaceName(event.target.value)}
              placeholder="Start entering place name..."
              key={defaultPlace}
            />
          </div>
          <div className="column is-3">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.name);
                    setCity(data.name);
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column is-3">
            <Input
              label="Deal Name"
              type="text"
              value={voucherNameSearch}
              onChange={event => handleVoucherNameSearch(event.target.value)}
              placeholder="Start entering voucher name..."
            />
          </div>
          <div className="column is-3">
            <Select
              label="Discount Type"
              options={discount_types}
              value={discountTypeValue.length !== 0 && first(discountTypeValue)}
              onChange={({ value }) => setDiscountType(value)}
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
