import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { first } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { Select } from '../../../../components/elements';
import { handleOrderStartStopAction } from '../helpers';
import WeeklyHours from './WeeklyHours';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const FlexItem1 = styled.div`
  width: 20%;
`;

const FlexItem2 = styled.div`
  width: 80%;
`;

const OnButton = styled.button`
  &&& {
    background-color: #65b44b;
    font-weight: 600;
    border-top-left-radius: 290486px;
    border-top-right-radius: 290486px;
    border-bottom-right-radius: 290486px;
    border-bottom-left-radius: 290486px;
    border: none;
  }
`;

const OffButton = styled.button`
  &&& {
    background-color: red;
    font-weight: 600;
    border-top-left-radius: 290486px;
    border-top-right-radius: 290486px;
    border-bottom-right-radius: 290486px;
    border-bottom-left-radius: 290486px;
    border: none;
  }
`;

const OrderButton = styled.button`
  &&& {
    background-color: ${({ theme, takeOrder }) =>
      takeOrder ? '#65b44b' : theme.danger};
    font-weight: 600;
    width: 100%;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const Menu = ({ menus, fetchMenus, refetchPlace, customHours, place }) => {
  const [selectedMenu, setSelectedMenu] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useStoreState(state => state.auth);

  const orderAction = async action => {
    setIsLoading(true);
    try {
      await handleOrderStartStopAction({
        userId,
        action,
        menuId: selectedMenu.menu_id,
      });
      fetchMenus();
      refetchPlace();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (menus.length !== 0) {
      const filterMenu = selectedMenu
        ? menus.filter(item => item.menu_id === selectedMenu.menu_id)
        : [];
      if (filterMenu.length !== 0) {
        setSelectedMenu(first(filterMenu));
      } else {
        setSelectedMenu(first(menus));
      }
    }
  }, [menus]);

  return (
    <div>
      <Flex>
        <FlexItem1>
          <h3 className="has-text-weight-semibold is-size-1">Menu</h3>
        </FlexItem1>
        <FlexItem2>
          <Select
            placeholder="Select Menu"
            value={selectedMenu ? { ...selectedMenu } : null}
            options={menus}
            onChange={value => {
              setSelectedMenu(value);
            }}
            isLoading={false}
          />
        </FlexItem2>
      </Flex>
      <br />
      <Flex>
        <FlexItem1 style={{ width: '30%' }}>
          <h3 className="has-text-weight-semibold is-size-4">
            Order Online Status
          </h3>
        </FlexItem1>
        <FlexItem2 style={{ width: '20%' }}>
          {selectedMenu && selectedMenu.hours === 'STOPPED' && (
            <OffButton type="button" className="button control">
              <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                OFF
              </p>
            </OffButton>
          )}
          {selectedMenu && selectedMenu.hours === 'CLOSED' && (
            <OffButton type="button" className="button control">
              <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                Closed
              </p>
            </OffButton>
          )}
          {selectedMenu && selectedMenu.hours === 'OPENED' && (
            <OnButton type="button" className="button control">
              <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                Open
              </p>
            </OnButton>
          )}
        </FlexItem2>
        <FlexItem2 style={{ width: '50%' }}>
          <OrderButton
            type="button"
            className={`button is-primary control ${isLoading && 'is-loading'}`}
            takeOrder={selectedMenu && selectedMenu.hours === 'STOPPED'}
            onClick={() => {
              if (selectedMenu) {
                orderAction(
                  selectedMenu.hours === 'STOPPED' ? 'RESUME' : 'PAUSE',
                );
              }
            }}
            disabled={selectedMenu && selectedMenu.hours === 'CLOSED'}
          >
            {selectedMenu && selectedMenu.hours === 'STOPPED'
              ? 'Resume new orders'
              : 'Stop taking orders'}
          </OrderButton>
        </FlexItem2>
      </Flex>
      <br />
      {selectedMenu && (
        <WeeklyHours
          menu={selectedMenu}
          customHours={customHours}
          place={place}
        />
      )}
    </div>
  );
};

export default Menu;
