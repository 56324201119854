import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import moment from 'moment';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import { Loading, Pagination, Table, Box } from '../../../components/elements';

const imageReviewsQuery = gql`
  query searchImageReviews($input: SearchInput) {
    search_image_review(input: $input) {
      total_pages
      total_size
      image_review_listing {
        image_id
        object_id
        object_type
        parent_name
        parent_id
        parent_type
        upload_type
        status
        approval_status
        url
        link
        place {
          name
          city
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        note {
          type
          date
          value
        }
        approval_status
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const listSize = 10;

const PhotoApprovals = props => {
  const routeState = qs.parse(props.location.search);
  const { status, placeName, reviewOn, page } = routeState;

  const placeNameFilter = placeName || '';
  const statusFilter = status || 'SUBMITTED';
  const reviewOnFilter = reviewOn || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [uploadedAtSort, setUploadedAtSort] = useState('AES');
  const [uploaderSort, setUploaderSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();

  const input = { sort, from: currentPage * listSize, size: listSize };

  const image_review_filter = {
    approval_status: statusFilter,
    object_type: 'PLACE',
  };

  Object.assign(
    image_review_filter,
    placeNameFilter && { place_name: placeNameFilter },
    reviewOnFilter && {
      date_range: {
        start_date: moment(reviewOnFilter).format('YYYY-MM-DD'),
        end_date: moment(reviewOnFilter)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      },
    },
  );

  Object.assign(input, {
    filter: {
      image_review_filter,
    },
  });
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={statusFilter}
          reviewOn={reviewOnFilter}
          placeName={placeNameFilter}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              props.history.push(
                `/place-photo-approvals${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    {/* <a
                      onClick={() => {
                        setUploaderSort(uploaderSort === 'AES' ? 'DES' : 'AES');
                      }}
                    >
                      <i
                        className={
                          uploaderSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '} */}
                    Updated By
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUploadedAtSort(
                          uploadedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          uploadedAtSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          uploadedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Review Status
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            client={client.clientPrivate}
            query={imageReviewsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_image_review } = data;
              if (
                isNull(search_image_review) ||
                search_image_review.image_review_listing.length === 0
              ) {
                return 'NO RECORDS FOUND';
              }

              return (
                <React.Fragment>
                  <View
                    search_image_review={search_image_review}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default PhotoApprovals;

const View = ({ search_image_review, setPageCount, setTotalElements }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setPageCount(search_image_review.total_pages);
    setTotalElements(search_image_review.total_size);
    if (search_image_review.image_review_listing) {
      const userFilter = search_image_review.image_review_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_image_review.total_pages]);

  const reviewsData = search_image_review.image_review_listing;

  return (
    <tbody>
      {reviewsData.map(review => (
        <ListItem review={review} users={users} />
      ))}
    </tbody>
  );
};
