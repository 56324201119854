import React, { useState } from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {
  Select,
  Input,
  TextArea,
  CreatableSelectInputs,
  BoxWithHeader,
  SingleImageUpload,
} from '../../../components/elements';
import DealTimeDatePicker from './DealTimeDatePicker';
import options from '../../../utils/optionsHelpers';

const ChangeWrapper = styled.div`
  padding-left: 600%;
  &&& {
    a {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const ListingOnly = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  touched,
  dealType,
}) => {
  const [changeServiceUrl, setChangeServiceUrl] = useState(true);
  return (
    <>
      <Column.Group>
        <Column size="4">
          <BoxWithHeader title="Photos">
            <SingleImageUpload
              onChange={image => setFieldValue('image_url', image.url)}
            />
          </BoxWithHeader>
        </Column>
        <Column size="8">
          <Column.Group multiline gapSize={4}>
            <Column size="half">
              <Input
                label="Discount Type"
                value={dealType}
                disabled
                optionalText="( Max 26 characters )"
                required
              />
            </Column>
            <Column size="half">
              <Select
                label="Deal Status"
                value={{ label: values.status, value: values.status }}
                options={options.statusOptions}
                onChange={value => setFieldValue('status', value.value)}
              />
            </Column>
            <Column size="half">
              <DealTimeDatePicker
                label="Start Date"
                timeLabel="Start Time"
                date={values.startDate}
                time={values.startTime}
                onDateChange={value => {
                  setFieldValue('startDate', value);
                  setFieldValue('endDate', value);
                }}
                onTimeChange={value => {
                  setFieldValue('startTime', value ? moment(value) : null);
                  setFieldValue('endTime', null);
                }}
                minDate={new Date()}
                errors={errors.startDate}
                touched={touched.startDate}
                required
              />
            </Column>
            <Column size="half">
              <DealTimeDatePicker
                label="End Date"
                timeLabel="End Time"
                date={values.endDate}
                time={values.endTime}
                onDateChange={value => {
                  setFieldValue('endDate', value);
                }}
                onTimeChange={value => {
                  setFieldValue('endTime', value ? moment(value) : null);
                }}
                minDate={new Date(values.startDate)}
                errors={errors.startDate}
                touched={touched.startDate}
                required
              />
            </Column>

            <Column size="8">
              <Input label="Deal / Voucher URL" disabled={changeServiceUrl}>
                <ChangeWrapper>
                  <a onClick={() => setChangeServiceUrl(!changeServiceUrl)}>
                    Change
                  </a>
                </ChangeWrapper>
              </Input>
            </Column>
          </Column.Group>
        </Column>
      </Column.Group>
      <Column.Group multiline gapSize={4}>
        <Column size="full">
          <Input
            label="Enter Deal / Voucher Tagline"
            optionalText="( Min 10 and Max 50 Characters )"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.name}
            touched={touched.name}
            required
            disableToastMessage
          />
        </Column>
        <Column size="full">
          <TextArea
            label="Deal / Voucher Description"
            optionalText="( Min 26 and Max 500 Characters )"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.description}
            touched={touched.touched}
            disableToastMessage
          />
        </Column>
        <Column size="full">
          <CreatableSelectInputs
            label="Place Menu Deal Tags"
            onChange={value => {
              const newValue = value.map(item => item.value);
              setFieldValue('tag', newValue);
            }}
            onBlur={handleBlur}
            disableToastMessage
          />
        </Column>
      </Column.Group>
    </>
  );
};

export default ListingOnly;
