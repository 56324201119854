import { forEach, toNumber, isNull } from 'lodash';
import gql from 'graphql-tag';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import client from '../../../utils/apolloClient';

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        place {
          place_id
        }
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        name
        internal_name
        slug
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getMenus = ({ placeId, userId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            filter: {
              menu_filter: {
                status: 'ACTIVE',
                service_types: ['Pickup', 'Dinein', 'Delivery'],
              },
              place_filter: { place_id: [placeId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menus && data.search_menus.menu_listing) {
          resolve(data.search_menus.menu_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const convertModifierPriceInDecimals = modifiers => {
  const data = [];
  forEach(modifiers, item => {
    const temp = [];
    forEach(item.override_variant, item1 => {
      const temp2 = [];
      forEach(item1.variants, item2 => {
        temp2.push({
          ...item2,
          variant_price: parseFloat(item2.variant_price).toFixed(2),
        });
      });
      temp.push({ ...item1, variants: temp2 });
    });
    data.push({
      ...item,
      override_price: parseFloat(item.override_price).toFixed(2),
      override_variant: temp,
    });
  });
  return data;
};

export const parseInputModifier = modifier => {
  const finalModifier = modifier.map(item => {
    const data = [];
    forEach(item.override_variant, item2 => {
      const temp = [];
      forEach(item2.variants, items3 => {
        temp.push({
          variant_name: items3.variant_name,
          variant_price: toNumber(items3.variant_price),
        });
      });
      data.push({ prompt: item2.prompt, variants: temp });
    });
    return {
      menu_item_id: item.menu_item_id,
      override_price: toNumber(item.override_price),
      override_variant: data,
    };
  });
  return finalModifier;
};

export const addNameInModifier = (modifier, fetchItems) => {
  const data = [];
  forEach(modifier, item => {
    const temp = [];
    const currentModifiers = fetchItems.filter(
      edge => edge.menu_item_id === item.menu_item_id,
    )[0];

    forEach(item.override_variant, item1 => {
      const temp2 = [];
      forEach(item1.variants, item2 => {
        temp2.push({
          ...item2,
          variant_price: parseFloat(item2.variant_price).toFixed(2),
        });
      });
      temp.push({ ...item1, variants: temp2 });
    });
    data.push({
      ...item,
      name: currentModifiers.name,
      override_price: parseFloat(item.override_price).toFixed(2),
      override_variant: temp,
    });
  });
  return data;
};

export const getDateofLocation = (iso_date, latitude, longitude) => {
  const time_zone = tzLookup(parseFloat(latitude), parseFloat(longitude));
  console.log('time_zone', time_zone);
  return moment(iso_date).tz(time_zone);
};

export const parseMenuItemAtSubmission = menuItemObj => {
  if (menuItemObj.variant) {
    const data = {
      item_link_id: menuItemObj.item_link_id,
      menu_item_id: menuItemObj.menu_item_id,
      name: menuItemObj.name,
    };

    const temp = [];
    forEach(menuItemObj.variant, item => {
      const temp2 = [];
      forEach(item.variants, item2 => {
        const newVariant = {
          variant_id: item2.variant_id,
          variant_name: item2.variant_name,
          type: item2.value === 0 ? '' : item2.type,
          value: item2.value === 0 ? null : item2.value,
          savings: item2.value === 0 ? null : item2.savings,
          new_price: item2.value === 0 ? null : item2.new_price,
        };
        temp2.push(newVariant);
      });
      temp.push({
        prompt_id: item.prompt_id,
        prompt: item.prompt,
        variants: temp2,
      });
    });

    data.variant = temp;
    return data;
  }
  return {
    item_link_id: menuItemObj.item_link_id,
    menu_item_id: menuItemObj.menu_item_id,
    name: menuItemObj.name,
    type: menuItemObj.value === 0 ? '' : menuItemObj.type,
    value: menuItemObj.value === 0 ? null : menuItemObj.value,
    savings: menuItemObj.value === 0 ? null : menuItemObj.savings,
    new_price: menuItemObj.value === 0 ? null : menuItemObj.new_price,
  };
};

const searchLinksQuery = gql`
  query searchLinks($linksInput: SearchInput, $itemsInput: SearchInput) {
    search_menu_item_links(input: $linksInput) {
      menu_item_link_listing {
        place_id
        item_link_id
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_name
            variant_price
            availability_date
          }
        }
        menu {
          name
        }
        price
        menu_section {
          menu_section_id
          name
        }
        menu_item {
          menu_item_id
          name
          price
          variant {
            prompt
            prompt_id
            variants {
              variant_id
              variant_name
              variant_price
            }
          }
        }
      }
    }

    search_menu_items(input: $itemsInput) {
      menu_item_listing {
        place_id
        menu_item_id
        name
        price
        variant {
          prompt
          prompt_id
          variants {
            variant_id
            variant_name
            variant_price
            availability_date
          }
        }
      }
    }
  }
`;

export const getLinksAndMenuItem = (linksInput, itemsInput) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchLinksQuery,
        variables: {
          linksInput,
          itemsInput,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const parseLinkAndMenuItem = menuItem => link => {
  const menuItemObj = menuItem.find(
    ele => ele.menu_item_id === link.menu_item?.menu_item_id,
  );

  const data = {
    item_link_id: link.item_link_id,
    menu_item_id: link.menu_item?.menu_item_id,
    name: link.menu_item?.name,
    menu_item_price: link.menu_item?.price || menuItemObj.price,
    menu_section_name: link.menu_section?.name,
  };

  if (isNull(link.variant)) {
    return {
      ...data,
      type: 'PERCENTAGE',
      value: 0,
      new_price: link.menu_item?.price || menuItemObj.price,
      savings: 0,
    };
  }

  const itemVariantsFromMenuItemObj = menuItemObj?.variant[0]?.variants;
  const temp = [];
  forEach(link.menu_item.variant, item => {
    const temp2 = [];
    forEach(item.variants, item2 => {
      const newVariant = {
        variant_id: item2.variant_id,
        variant_name: item2.variant_name,
        variant_price:
          item2.variant_price ||
          itemVariantsFromMenuItemObj?.find(
            ele => ele.variant_id === item2.variant_id,
          ).price,
        type: 'PERCENTAGE',
        value: 0,
        savings: 0,
        new_price:
          item2.variant_price ||
          itemVariantsFromMenuItemObj?.find(
            ele => ele.variant_id === item2.variant_id,
          ).price,
      };
      temp2.push(newVariant);
    });
    temp.push({
      prompt_id: item.prompt_id,
      prompt: item.prompt,
      variants: temp2,
    });
  });

  data.variant = temp;
  return data;
};

const fetchServiceTypeSettingQuery = gql`
  query fetchServiceTypeSettingQuery($id: String!) {
    fetch_service_type_setting(input: { service_type_setting_id: $id }) {
      delivery_channel
    }
  }
`;

export const getDeliveryChannel = id =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: fetchServiceTypeSettingQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data &&
          data.fetch_service_type_setting &&
          data.fetch_service_type_setting.delivery_channel
        ) {
          resolve(data.fetch_service_type_setting.delivery_channel);
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
