import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

import Select from '../../../../components/elements/Select';

import Input from '../../../../components/elements/Input';
import BasicTextEditor from './BasicTextEditor';
import { createSesTemplate } from './helpers';
import basicTemplate from './basicTemplate';

const Container = styled.div`
  width: 80%;
  padding: 2rem;
`;

const baseTemplateValues = [
  { value: 'EMPTY_TEMPLATE', label: 'Empty Template' },
  { value: 'BASIC_TEMPLATE', label: 'Basic' },
];

const TemplateBody = ({ notificationTemplateId }) => {
  const { userId } = useStoreState(state => state.auth);
  const [subject, setSubject] = React.useState('');
  const [html, setHtml] = React.useState('');
  const [templateName, setTemplateName] = React.useState(
    notificationTemplateId,
  );
  const [submitting, setSubmitting] = React.useState(false);
  const [baseTemplate, setBaseTemplate] = React.useState({
    value: 'EMPTY_TEMPLATE',
    label: 'Empty',
  });

  const handleChangeInTextEditor = v => {
    setHtml(v);
  };

  console.log({
    html,
    subject,
    template_name: templateName,
    user_id: userId,
  });

  const createTemplate = async () => {
    let h;
    if (baseTemplate.value === 'EMPTY_TEMPLATE') {
      h = html;
    } else {
      h = basicTemplate.replace('{{ENTER_HERE}}', html);
    }

    setSubmitting(true);
    if (templateName.length === 0) {
      toast.error('Please enter valid template name');
      setSubmitting(false);
    } else if (subject.length === 0) {
      toast.error('Please enter valid subject line');
      setSubmitting(false);
    } else if (html.length === 0) {
      toast.error('Please enter content');
      setSubmitting(false);
    } else {
      const res = await createSesTemplate({
        html: h,
        subject,
        template_name: templateName,
        user_id: userId,
      });
      if (res) {
        toast.error(res);
      } else {
        swal('Successfully created the template!');
      }
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <div className="mb-6">
        <Select
          label="Select Base Template"
          options={baseTemplateValues}
          value={[baseTemplate]}
          onChange={value => {
            setBaseTemplate(value);
          }}
        />
      </div>
      <div className="mb-6">
        <Input
          label="Template Name"
          value={templateName}
          onChange={e => setTemplateName(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <Input
          label="Email Subject Line"
          value={subject}
          onChange={e => setSubject(e.target.value)}
        />
      </div>
      <BasicTextEditor handleChange={handleChangeInTextEditor} />
      <button
        type="button"
        className={`button is-primary ${submitting && 'is-loading'}`}
        onClick={createTemplate}
      >
        Create Template
      </button>
    </Container>
  );
};

export default TemplateBody;
