import React from 'react';

import { withRouter } from 'react-router-dom';

import Layout from '../../../components/global/Layout';
import PlacesListing from './PlacesListing';

const Orders = props => (
  <Layout>
    <PlacesListing heading="Orders" {...props} />
  </Layout>
);

export default withRouter(Orders);
