import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = ({
  placeNameSearchInput,
  setPlaceNameSearchInput,
  setItemNameSearchInput,
  itemNameSearchInput,
  setLocation,
}) => {
  const [city, setCity] = useState('');
  return (
    <Wrapper>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <div className="field">
              <Input
                label="Item Name"
                type="text"
                value={itemNameSearchInput}
                onChange={event => setItemNameSearchInput(event.target.value)}
                placeholder="Start entering item name..."
              />
            </div>
          </div>

          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeNameSearchInput}
              onChange={event => setPlaceNameSearchInput(event.target.value)}
              placeholder="Start entering place name..."
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label"> City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.location);
                    setCity(data.name);
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
