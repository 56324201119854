import gql from 'graphql-tag';
import client from '../../../../../utils/apolloClient';

const searchMenuFeesQuery = gql`
  query searchMenuFeesQuery($menuId: String) {
    search_menu_fees(input: { filter: { menu_filter: { menu_id: $menuId } } }) {
      menu_fees {
        user_id
        place_id
        menu_fee_id
        menu_id
        display_name
        internal_name
        is_editable
        fee_type
        value
        payment_method
        period
        start_date
        start_time
        end_date
        end_time
        status
        days {
          day
          is_active
        }
      }
    }
  }
`;

export const fetchMenuFees = ({ menuId }) =>
  new Promise(resolve => {
    client.clientPrivate
      .query({
        query: searchMenuFeesQuery,
        variables: {
          menuId,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (Array.isArray(data?.search_menu_fees?.menu_fees)) {
          resolve(data.search_menu_fees.menu_fees);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        resolve([]);
      });
  });

export default {};
