import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({ location }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">Item Dictionary</Title>
      </Level.Item>
      <Level.Item align="right">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/item-dictionaries'
                  ? 'create_item_dictionary'
                  : 'create_item_dictionary'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Link to="/add-item-dictionary" className="button is-primary">
                  <span className="has-text-weight-semibold">
                    Add Item Dictionary
                  </span>
                </Link>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Header);
