import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';

const deleteEventMutation = gql`
  mutation delete_event($input: NavigateEventInput) {
    delete_event(input: $input) {
      event_id
      error {
        description
      }
    }
  }
`;

const DeleteEventAction = ({ eventId }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteEventMutation}
      variables={{
        input: {
          user_id: userId,
          event_id: eventId,
        },
      }}
      onCompleted={() => {
        swal('Poof! Your event has been deleted!', {
          icon: 'success',
        });
      }}
    >
      {delete_event => (
        <a
          onClick={() => {
            swal('Are you sure you want to do this?', {
              buttons: ['Cancel', true],
            }).then(willDelete => {
              if (willDelete) {
                delete_event();
              }
            });
          }}
        >
          <span>
            <i className="fas fa-trash has-text-grey" />
          </span>
        </a>
      )}
    </Mutation>
  );
};

export default DeleteEventAction;
