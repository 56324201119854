import React, { useState } from 'react';
import styled from 'styled-components';
import { Column, Title, Level, Table } from 'rbx';
import { Query } from 'react-apollo';
import { isNull } from 'lodash';
import gql from 'graphql-tag';
import Footer from './Footer';
import SocialList from './SocialList';
import Search from './Search';
import {
  Input,
  TextArea,
  TagInputs,
  CheckBox,
  Table as CustomTable,
  CustomInput,
  CustomSelect,
  Loading,
  Pagination,
} from '../../../components/elements';

const searchSocial = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_count {
          type
          count
        }
        total_social_per_object_id {
          total_social_count {
            type
            user {
              user_id
              time
            }
          }
        }
      }
    }
  }
`;

const Social = ({ item }) => {
  const [socialCount, setSocialCount] = useState({
    tried: 0,
    love: 0,
    bookmark: 0,
    share: 0,
  });

  const [filterType, setFilterType] = useState('tried');
  const [user, setUser] = useState({
    tried: [],
    love: [],
    bookmark: [],
    share: [],
  });

  const social_filter = {
    object_id: item.item_id,
    object_type: 'ITEM',
  };
  return (
    <React.Fragment>
      <Level>
        <Column size={3}>
          <Input label="Tried" value={socialCount.tried} disabled />
        </Column>
        <Column size={3}>
          <Input label="Love It" value={socialCount.love} disabled />
        </Column>

        <Column size={3}>
          <Input label="Shares" value={socialCount.share} disabled />
        </Column>
        <Column size={3}>
          <Input label="Bookmarks" value={socialCount.bookmark} disabled />
        </Column>
      </Level>
      <Search filterType={filterType} setFilterType={setFilterType} />
      {/* <Pagination /> */}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Login
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Social Type
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Created At
                </span>
                <br />
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Query
          query={searchSocial}
          variables={{
            input: {
              filter: {
                social_filter,
              },
            },
          }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }

            const { search_social } = data;
            if (isNull(search_social)) {
              return 'no data found';
            }

            return (
              <SocialList
                setSocialCount={setSocialCount}
                socialFilter={social_filter}
                users={user[filterType]}
                setUser={setUser}
                search_social={search_social}
              />
            );
          }}
        </Query>
      </CustomTable>
    </React.Fragment>
  );
};

export default Social;
