import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
  position: fixed;
  bottom: 0;
  width: 84%;
  background: #ffff;
`;

const Footer = ({ loading, dirty, history }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button
            type="submit"
            className={`button is-primary ${loading && 'is-loading'}`}
            disabled={!dirty}
          >
            <span>Submit</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
