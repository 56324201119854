import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
import { Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import ClaimForm from './ClaimForm';
import PlaceSelection from '../../../components/PlaceSelection';

const createPlaceClaimMutation = gql`
  mutation create_place_claimant($input: PlaceClaimantInput) {
    create_place_claimant(input: $input) {
      claimant_id
      error {
        description
      }
    }
  }
`;

const actionOnPlaceClaimMutation = gql`
  mutation action_place_claimant($input: ActionPlaceClaimantInput) {
    action_place_claimant(input: $input) {
      claimant_id
      status
      error {
        description
      }
    }
  }
`;

const PlaceClaimAdd = ({ history }) => {
  const [action, setAction] = useState('');
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);

  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      {placeSelection ? (
        <Mutation
          client={client.clientPrivate}
          mutation={actionOnPlaceClaimMutation}
          onCompleted={() => {
            swal(
              'Thank You!',
              'Place claim has been submitted and approved successfully!',
              'success',
            );
            history.goBack();
          }}
        >
          {(
            action_place_claimant,
            { loading: approveClaimLoading, error: approveClaimError },
          ) => (
            <Mutation
              client={client.clientPrivate}
              mutation={createPlaceClaimMutation}
              onCompleted={({ create_place_claimant }) => {
                if (create_place_claimant.error !== null) {
                  create_place_claimant.error.map(item =>
                    toast.error(item.description),
                  );
                }
                if (action === 'submit-approve-claim') {
                  action_place_claimant({
                    variables: {
                      input: {
                        user_id: userId,
                        place_id: place.place_id,
                        claimant_id: create_place_claimant.claimant_id,
                        status: 'APPROVED',
                      },
                    },
                  });
                } else {
                  swal(
                    'Nice!',
                    'Place claim has been submitted  successfully!',
                    'success',
                  );
                  history.goBack();
                }
              }}
            >
              {(
                create_place_claimant,
                { loading: createClaimLoading, error: createClaimError },
              ) => (
                <React.Fragment>
                  {(approveClaimError || createClaimError) && (
                    <Message type="error">{`${(approveClaimError &&
                      approveClaimError.message) ||
                      (approveClaimError &&
                        approveClaimError.message)}`}</Message>
                  )}
                  <ClaimForm
                    error={createClaimError || approveClaimError}
                    loading={createClaimLoading || approveClaimLoading}
                    place={place}
                    action={action}
                    setAction={setAction}
                    createForm
                    onSubmit={value => {
                      const input = {
                        user_id: userId,
                        claimant_id: value.claimant_id,
                        object_id: value.place_id,
                        object_type: value.object_type,
                        contact_user_id: value.contact_user_id,
                        contact_full_name: value.contact_full_name,
                        contact_phone: value.contact_phone,
                        contact_email: value.contact_email,
                        contact_role: value.contact_role,
                        contact_business_url: value.contact_business_url,
                        contact_linked_in_url: value.contact_linked_in_url,
                        contact_facebook_url: value.contact_facebook_url,
                        service_type: value.service_type,
                        agree_terms_and_conditions:
                          value.agree_terms_and_conditions,
                        verification_mode: value.verification_mode,
                        verification_note: [
                          {
                            type: 'sms',
                            value: value.verification_note,
                            date: moment().toISOString(),
                          },
                        ],
                      };

                      create_place_claimant({ variables: { input } });
                    }}
                  />
                </React.Fragment>
              )}
            </Mutation>
          )}
        </Mutation>
      ) : (
        <PlaceSelection
          heading="Select Place to add claim"
          claimStatus="UNCLAIMED"
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default withRouter(PlaceClaimAdd);
