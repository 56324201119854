import React, { useState, useEffect } from 'react';
import { Table } from 'rbx';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';
import client from '../../../utils/apolloClient';
import {
  Table as CustomTable,
  // CustomInput,
  Pagination,
  Loading,
  Message,
} from '../../../components/elements';
import ReviewLikeList from './ReviewLikeList';

const searchSocial = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      total_size
      total_pages
      social_listing {
        object_id
        object_type
        type
        audit {
          updated_by
          updated_at
          created_at
          created_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const ReviewLikes = ({ review }) => {
  const objectId = review.item_review_id;

  const socialFilter = {
    object_id: objectId,
    type: 'LIKE',
  };

  const [reviewData, setReviewData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const input = {};
  Object.assign(input, { from: pageFrom, size: listSize });
  return (
    <React.Fragment>
      {totalElements > 0 && (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          currentPage={currentPage}
          totalElements={totalElements}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Login
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>Updated At</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Query
          query={searchSocial}
          variables={{ input: { filter: { social_filter: socialFilter } } }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>{error.message}</Message>;
            }
            const { search_social } = data;
            

            return (
              <React.Fragment>
                <View
                  reviewData={reviewData}
                  setReviewData={setReviewData}
                  search_social={search_social}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </CustomTable>
    </React.Fragment>
  );
};

export default ReviewLikes;

const View = ({
  search_social,
  setPageCount,
  setTotalElements,
  setReviewData,
  reviewData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_social.total_pages);
    setTotalElements(search_social.total_size);
    if (search_social.social_listing) {
      setReviewData(search_social.social_listing);

      const userFilter = search_social.social_listing.map(
        item => item.audit.updated_by,
      );

      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_social.total_pages]);
  return (
    <Table.Body>
      {reviewData &&
        reviewData.map(social => (
          <ReviewLikeList social={social} users={users} />
        ))}
    </Table.Body>
  );
};
