import React, { useState, useEffect } from 'react';
import { Table } from 'rbx';
import { first, isNull, uniq } from 'lodash';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';

import LikeList from './LikeList';
import {
  Table as CustomTable,
  CustomInput,
  Pagination,
  Loading,
  Message,
} from '../../../components/elements';

const searchSocialQuery = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      total_size
      total_pages
      social_listing {
        social_id
        object_id
        type
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const Likes = ({ match }) => {
  const [socialData, setSocialData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const socialFilter = {
    object_id: match.params.feedId,
  };

  const input = {};
  const social_filter = {};
  Object.assign(input, social_filter, { from: pageFrom, size: listSize });
  return (
    <React.Fragment>
      <br />
      {totalElements > 0 ? (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      ) : (
        ' '
      )}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
                {/* <br />
                <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Login
                </span>
                {/* <br />
                <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>Updated At</Table.Heading>
          </Table.Row>
        </Table.Head>

        <Query
          query={searchSocialQuery}
          variables={{ input: { filter: { social_filter: socialFilter } } }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }

            const { search_social } = data;
            if (isNull(search_social)) {
              return 'no data found';
            }

            return (
              <React.Fragment>
                <View
                  socialData={socialData}
                  setSocialData={setSocialData}
                  search_social={search_social}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </CustomTable>
    </React.Fragment>
  );
};

const View = ({
  search_social,
  setPageCount,
  setTotalElements,
  setSocialData,
  socialData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_social.total_pages);
    setTotalElements(search_social.total_size);

    if (search_social.social_listing) {
      setSocialData(search_social.social_listing);

      const userFilter = search_social.social_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_social.total_pages]);

  return (
    <tbody>
      {socialData.map(social => (
        <tr key={social.social_id}>
          <LikeList social={social} users={users} />
        </tr>
      ))}
    </tbody>
  );
};

export default withRouter(Likes);
