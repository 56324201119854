import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { first } from 'lodash';
import styled from 'styled-components';
import { Loading, Message } from '../../../components/elements';
import ItemDetails from './ItemDetails';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      status
      email
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const itemReviewQuery = gql`
  query itemReview($input: NavigateItemReviewInput) {
    fetch_item_review(input: $input) {
      item_id
      item {
        name
      }
      content
      status
      audit {
        created_at
        created_by
      }
    }
  }
`;

const ItemReview = ({ flag }) => (
  <Container>
    <div>
      <Query
        query={itemReviewQuery}
        variables={{
          input: { item_review_id: flag.object_id },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading: itemLoading, error: itemError }) => {
          if (itemLoading) {
            return <Loading />;
          }
          if (itemError) {
            return <Message>{itemError.message}</Message>;
          }
          const item = first(data.fetch_item_review);

          return (
            <Query
              client={client.clientPrivate}
              query={userQuery}
              variables={{ userId: item.audit.created_by }}
              fetchPolicy="network-only"
            >
              {({ data: userData, loading: userLoading, error: userError }) => {
                if (userError) {
                  return '';
                }
                const { fetch_user } = userData;

                return (
                  <ItemDetails flag={flag} item={item} userData={fetch_user} />
                );
              }}
            </Query>
          );
        }}
      </Query>
    </div>
  </Container>
);
export default ItemReview;
