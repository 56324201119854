import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .order-setting-checkbox-container {
    display: flex;

    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #ccc;
    color: ${({ disableAndChecked }) =>
      disableAndChecked ? '#b0b0b0' : '#0D0D0D'};
    font-size: 16px;
    font-weight: 400;
  }
  .order-setting-checkbox-container span {
    width: 75%;
  }
  .round-checkbox {
    position: relative;
  }

  .round-checkbox label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
  }

  .round-checkbox label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 3px;
    left: 4.5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 8px;
  }

  .round-checkbox input[type='checkbox'] {
    visibility: hidden;
  }

  .round-checkbox input[type='checkbox']:checked + label {
    background-color: ${({ disableAndChecked }) =>
      disableAndChecked ? '#b0b0b0' : '#00aeef'};
    border-color: ${({ disableAndChecked }) =>
      disableAndChecked ? '#b0b0b0' : '#00aeef'};
  }

  .round-checkbox input[type='checkbox']:checked + label:after {
    opacity: 1;
  }

  .round-checkbox-container {
    margin: 0 auto;
  }
`;

const OrderSettingCheckbox = ({
  orderSetting,
  disableAndChecked = false,
  checked,
  onChange,
}) => (
  <Container disableAndChecked={disableAndChecked}>
    <div className="order-setting-checkbox-container">
      <span>{orderSetting}</span>
      <div className="round-checkbox-container">
        <div
          className="round-checkbox"
          onClick={() => (!checked ? onChange(true) : onChange(false))}
        >
          {disableAndChecked ? (
            <input type="checkbox" checked disabled id="checkbox" />
          ) : (
            <input
              type="checkbox"
              id="checkbox"
              checked={checked}
              onChange={() => {}} // not good
            />
          )}
          <label htmlFor="checkbox" />
        </div>
      </div>
    </div>
  </Container>
);

export default OrderSettingCheckbox;
