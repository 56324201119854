/* eslint-disable max-len */
import React from 'react';

const AddDishPhoto = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 1.75V3.25H13.25V4.25H11.75V5.745C11.75 5.745 10.755 5.75 10.75 5.745V4.25H9.25C9.25 4.25 9.255 3.255 9.25 3.25H10.75V1.75H11.75ZM10.75 11.25H3.75V4.25H8.25V3.25H3.75C3.2 3.25 2.75 3.7 2.75 4.25V11.25C2.75 11.8 3.2 12.25 3.75 12.25H10.75C11.3 12.25 11.75 11.8 11.75 11.25V6.75H10.75V11.25ZM6.855 9.665L5.875 8.485L4.5 10.25H10L8.23 7.895L6.855 9.665Z"
      fill="#00AEEF"
    />
  </svg>
);

export default AddDishPhoto;
