import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Span = styled.span`
  padding-left: 2px;
  color: #ff0000;
`;

const RightSpan = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ disabled }) => (disabled ? '#b0b0b0' : '#505050')};
  padding-top: 2px;
`;

const CheckBoxVariant2 = ({ required, label, value, onChange, disabled }) => {
  const [enable, setEnable] = useState(value);
  useEffect(() => {
    setEnable(value);
  }, [value]);
  return (
    <div className={classNames('control', { disabled })}>
      <input type="checkbox" className="is-hidden" />
      <a
        onClick={
          disabled
            ? null
            : () => {
                // setEnable(!enable);
                onChange(!enable);
              }
        }
        className="has-text-weight-semibold label is-flex"
      >
        <span>
          <i
            id="CheckBox"
            className={
              enable ? 'fas fa-check-square has-text-info' : 'far fa-square'
            }
            style={{ color: disabled ? '#b0b0b0' : '#505050' }}
          />
        </span>
        &nbsp;&nbsp;
        <RightSpan disabled={disabled}>
          {label}
          {required && <Span>*</Span>}
        </RightSpan>
      </a>
    </div>
  );
};

export default CheckBoxVariant2;
