import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Label } from 'rbx';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { sortBy } from 'lodash';
import MenuList from './MenuList';

import { Loading, Message } from '../../../../components/elements';
import client from '../../../../utils/apolloClient';

const Container = styled.div`
  .acc_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: ${({ theme }) => theme.lightGreyAlpha};
  }
`;

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        place {
          place_id
        }
        service_type_setting {
          service_type_setting_id
        }
        service_type
        menu_id
        hours
        name
        status
        internal_name
        slug
        display_order
        description
      }
    }
  }
`;

const updateMenuMutation = gql`
  mutation updateMenu($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const Main = ({ menus, forOpenClose }) => {
  const { userId } = useStoreState(state => state.auth);
  const [menusData, setMenusData] = useState([]);

  const [updateMenuAction] = useMutation(updateMenuMutation, {
    client: client.clientPrivate,
  });

  const onHandleReorder = values => {
    const input = values.map((value, index) => ({
      menu_id: value.menu_id,
      place_id: value.place_id,
      user_id: userId,
      display_order: index,
    }));
    setMenusData(values);
    const menusDataClone = [...menusData];
    swal({
      title:
        'Are you sure, you would like to change the order of the Menu listing?',
      text: '',
      icon: '',
      buttons: true,
    }).then(willUpdate => {
      if (willUpdate) {
        updateMenuAction({ variables: { input } });
        return;
      }
      setMenusData(menusDataClone); // Revert to Previous Order
    });
  };

  useEffect(() => {
    setMenusData(menus);
  }, []);

  return (
    <Container>
      <Label className="title is-size-3">Menus</Label>
      <MenuList
        onHandleReorder={onHandleReorder}
        menus={menusData}
        forOpenClose={forOpenClose}
      />
    </Container>
  );
};

const Menus = ({ serviceSettingId, forOpenClose = false }) => {
  const { userId } = useStoreState(state => state.auth);
  const { data, loading, error } = useQuery(searchMenusQuery, {
    variables: {
      input: {
        filter: {
          menu_filter: {
            service_type_setting_id: serviceSettingId,
          },
        },
        user_id: userId,
      },
    },
    client: client.clientPrivate,
  });
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Message>{error.message}</Message>;
  }
  const menus = data.search_menus.menu_listing;
  return (
    <Main menus={sortBy(menus, 'display_order')} forOpenClose={forOpenClose} />
  );
};

export default Menus;
