import React, { useState, useEffect } from 'react';
import { Column } from 'rbx';
import { isUndefined, isNull, first } from 'lodash';
import gql from 'graphql-tag';
import moment from 'moment';
import client from '../../../utils/apolloClient';
import { Input, MobileInput } from '../../../components/elements';

const userQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      rating
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const ClaimedUserLoginDetails = ({ values, claimedBy, claimedAt }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    client.clientPrivate
      .query({
        query: userQuery,
        variables: {
          userId: claimedBy,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setUser(data.fetch_user);
        }
      });
  }, []);
  console.log('user', user);
  const contactArray =
    user && user.contact && !isNull(user.contact)
      ? user.contact.filter(item => item.type === 'mobile')
      : [];

  const contactMobile =
    contactArray.length !== 0 ? first(contactArray).value : 0;
  return (
    <>
      {isUndefined(user) || user.user_id === null ? (
        <fieldset className="box">
          <legend className="label">Claimed User Login Details</legend>
          <Column.Group multiline vcentered gapSize={8}>
            <Column size="half">
              <Input label="User Email" disabled />
            </Column>
            <Column size="half">
              <Input label="User Name" disabled />
            </Column>
            <Column size="half">
              <Input label="Claim Date & Time" disabled />
            </Column>
            <Column size="half">
              <MobileInput label="User Phone" disabled />
            </Column>
          </Column.Group>
        </fieldset>
      ) : (
        <fieldset className="box">
          <legend className="label">Claimed User Login Details</legend>
          <Column.Group multiline vcentered gapSize={8}>
            <Column size="half">
              <Input label="User Email" value={user && user.email} disabled />
            </Column>
            <Column size="half">
              <Input
                label="User Name"
                value={`${user && user.first_name} ${user && user.last_name}`}
                disabled
              />
            </Column>
            <Column size="half">
              <Input
                label="Claim Date & Time"
                value={moment(claimedAt).format('lll')}
                disabled
              />
            </Column>
            <Column size="half">
              <MobileInput label="User Phone" value={contactMobile} disabled />
            </Column>
          </Column.Group>
        </fieldset>
      )}
    </>
  );
};

export default ClaimedUserLoginDetails;
