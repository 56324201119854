import React from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';

import Input from '../../../components/elements/Input';
import KeyValueTable from './KeyValueTable';

const Container = styled.div`
  width: 60%;
  padding: 2rem;
`;

const BASE_URL = `http://localhost:5000/api`;

const encodeData = data => encodeURIComponent(btoa(JSON.stringify(data)));

const sendEmail = async ({ data, template }) =>
  fetch(`${BASE_URL}/send-email?data=${encodeData(data)}&template=${template}`);

const TestEmailTemplate = ({ notificationTemplateId }) => {
  // const [sentTo, setSentTo] = React.useState('');
  const keyValuePairs = React.useRef();
  const [templateName, setTemplateName] = React.useState(
    notificationTemplateId,
  );

  const handleKeyValuePairChange = v => {
    keyValuePairs.current = v;
  };

  const [isSubmit, setIsSubmit] = React.useState(false);

  return (
    <Container>
      <div className="mb-6">
        <Input
          label="Template Name"
          value={templateName}
          onChange={e => setTemplateName(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <Input
          label="Sent to Email ID"
          value="noreply@kravein.com.au"
          disabled
          // onChange={e => setSentTo(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <KeyValueTable handleKeyValuePairChange={handleKeyValuePairChange} />
      </div>
      <div className="mb-6">
        <button
          type="button"
          className={`button is-primary ${isSubmit && 'is-loading'}`}
          onClick={async () => {
            setIsSubmit(true);
            const data = {};
            keyValuePairs.current.forEach(ele => {
              data[ele.key] = ele.value;
            });
            await sendEmail({ data, template: notificationTemplateId });
            swal('Successfully sent email');
            setIsSubmit(false);
          }}
        >
          Sent Test Email
        </button>
      </div>
    </Container>
  );
};

export default TestEmailTemplate;
