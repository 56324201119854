import React from 'react';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    color: {
      ...DefaultTheme.reactDates.color,
      highlighted: {
        backgroundColor: '#00AEEF',
        backgroundColor_active: '#00aeef',
        backgroundColor_hover: '#58D68D',
        color: '#186A3B',
        color_active: '#186A3B',
        color_hover: '#186A3B',
      },
    },
  },
});

// import ImageIcon from './ImageIcon';

const DatePicker = ({ value, onChange }) => {
  const [date, setDate] = React.useState(value || null);
  const [focusedInput, setFocusedInput] = React.useState(false);

  const onDatesChange = item => {
    setDate(item);
    setFocusedInput(false);
    onChange(item);
  };

  const onFocusChange = ({ focused }) => {
    setFocusedInput(focused);
  };

  return (
    <SingleDatePicker
      placeholder="Select date..."
      date={date}
      onDateChange={onDatesChange}
      focused={focusedInput}
      onFocusChange={onFocusChange}
      numberOfMonths={1}
      // showDefaultInputIcon
    />
  );
};

export default DatePicker;
