import React from 'react';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import './theme/bulma.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';

// Call it once in your app. At the root of your app is the best place
toast.configure();

ReactDOM.render(<App />, document.getElementById('root'));
