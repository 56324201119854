import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';
import Search from './Search';
import { SubHeading, Heading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({ isActiveTab }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Food Order Activity</Heading>
      </Level.Item>
    </Level>
    <Search />
    {isActiveTab !== 'ActivityDashboard' && (
      <>
        <SubHeading className="is-size-2 has-text-weight-semibold">
          Stokehouse St Kilda
        </SubHeading>
        <SubHeading className="is-size-3">
          St Kilda, 3182 | Australia
        </SubHeading>
      </>
    )}
  </Wrapper>
);

export default withRouter(Header);
