/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Column } from 'rbx';
import * as yup from 'yup';

import { FaAngleLeft } from 'react-icons/fa';
import { TextArea, CheckBox } from '../../../components/elements';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 18px;
`;

const Button = styled.button`
  &&& {
    background-color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 55%;
    height: 6vh;
  }
`;

// const VerityWrapper = styled.div`
//   padding-left: 1.5rem;
//   padding-top: 0.5px;
//   color: ${({ theme }) => theme.specialGrey};
// `;

const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

const Form = ({ isActive, onClose, handleSubmit, resetForm }) => (
  <Container
    className={`modal ${isActive && 'is-active'}`}
    onSubmit={e => {
      e.preventDefault();
      handleSubmit();
    }}
  >
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <FaAngleLeft
          size={30}
          type="button"
          onClick={() => {
            onClose();
            resetForm();
          }}
        />
        <p
          className="modal-card-title is-flex"
          style={{ justifyContent: 'center' }}
        >
          Select Out Of Stock Items
        </p>
      </header>

      <section className="modal-card-body">
        <DetailsFormWrapper>
          <>
            <div className="field">
              <div className="control">
                <RadioWrapper>
                  <CheckBox label="1x   Thin Crust Pizza" />
                  <p>$15.65</p>
                </RadioWrapper>
                {/* <VerityWrapper>
                  <p>Starters</p>
                </VerityWrapper> */}

                <div className="is-flex">
                  <RadioWrapper>
                    <CheckBox label="1x   10'' Small Pizza" />
                    <p>$15.65</p>
                  </RadioWrapper>
                </div>
                <div className="is-flex">
                  <RadioWrapper>
                    <CheckBox label="1x   Substitute Whole Wheat Crust" />
                    <p>$15.65</p>
                  </RadioWrapper>
                </div>
                <div className="is-flex">
                  <RadioWrapper>
                    <CheckBox label="1x   Sasuage" />
                    <p>$15.65</p>
                  </RadioWrapper>
                </div>
              </div>
            </div>
          </>
          <hr />
          <>
            <div className="field">
              <div className="control">
                <RadioWrapper>
                  <CheckBox label="1x   Thin Crust Pizza" />
                  <p>$15.65</p>
                </RadioWrapper>
                {/* <VerityWrapper>
                  <p>Starters</p>
                </VerityWrapper> */}

                <div className="is-flex">
                  <RadioWrapper>
                    <CheckBox label="1x   10'' Small Pizza" />
                    <p>$15.65</p>
                  </RadioWrapper>
                </div>
                <div className="is-flex">
                  <RadioWrapper>
                    <CheckBox label="1x   Substitute Whole Wheat Crust" />
                    <p>$15.65</p>
                  </RadioWrapper>
                </div>
                <div className="is-flex">
                  <RadioWrapper>
                    <CheckBox label="1x   Sasuage" />
                    <p>$15.65</p>
                  </RadioWrapper>
                </div>
              </div>
            </div>
          </>
          <hr />
          <br />

          <Column.Group>
            <div className="column">
              <TextArea
                label="Note to the customer"
                placeholder="Add a note and mention anything specific such as no nuts or cross contamination, less spicy"
                required
              />
              Characters left (0/144)
            </div>
          </Column.Group>
          <br />
          <div className="is-flex" style={{ justifyContent: 'center' }}>
            <Button className="button is-primary" onClick={() => {}}>
              <Text>CONFIRM</Text>
            </Button>
          </div>
        </DetailsFormWrapper>
      </section>
    </div>
  </Container>
);

const OutOfStockForm = withFormik({
  mapPropsToValues: () => ({}),

  validationSchema: yup.object().shape({}),

  handleSubmit: () => {},

  displayName: 'OutOfStockForm',
})(Form);

export default OutOfStockForm;
