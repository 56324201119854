import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

import {
  GoogleMap,
  Polygon,
  useLoadScript,
  Circle,
  Marker,
} from '@react-google-maps/api';
import config from '../../../../utils/config';

const Container = styled.div`
  height: 46vh;
  .map {
    height: 100%;
  }
`;

const defaultPath = [
  { lat: 52.52549080781086, lng: 13.398118538856465 },
  { lat: 52.48578559055679, lng: 13.36653284549709 },
  { lat: 52.48871246221608, lng: 13.44618372440334 },
];

const defaultCenterLocation = {
  lat: 52.52047739093263,
  lng: 13.36653284549709,
};

const MapView = ({ initialPath, centerLocation, onChange, otherZones }) => {
  const [path, setPath] = useState(initialPath);
  const polygonRef = useRef(null);
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
      setPath(nextPath);
      onChange(
        nextPath.map(item => ({ latitude: item.lat, longitude: item.lng })),
      );
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
    },
    [onEdit],
  );

  const onUnmount = useCallback(() => {
    polygonRef.current = null;
  }, []);

  return (
    <Container>
      <GoogleMap
        mapContainerClassName="map"
        center={centerLocation}
        zoom={12}
        version="weekly"
        on
      >
        <Marker position={centerLocation} animation="DROP" />
        {otherZones.map(({ color_code, delivery_area }) => (
          <>
            {delivery_area.area_type === 'CIRCLE' && (
              <Circle
                options={{
                  strokeColor: chroma(color_code).brighten(2),
                  fillColor: chroma(color_code).brighten(2),
                }}
                editable={false}
                draggable={false}
                center={{
                  lat: delivery_area.area_circle.location.latitude,
                  lng: delivery_area.area_circle.location.longitude,
                }}
                radius={delivery_area.area_circle.radius * 1000}
              />
            )}
            {delivery_area.area_type === 'POLYGON' && (
              <Polygon
                options={{
                  strokeColor: chroma(color_code).brighten(2),
                  fillColor: chroma(color_code).brighten(2),
                }}
                editable={false}
                draggable={false}
                path={delivery_area.area_polygon}
              />
            )}
          </>
        ))}
        <Polygon
          options={{ zIndex: 200 }}
          editable
          draggable
          path={path}
          onMouseUp={onEdit}
          onDragEnd={onEdit}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
      </GoogleMap>
    </Container>
  );
};

const PolygonMapSelection = ({
  otherZones = [],
  initialPath = defaultPath,
  centerLocation = defaultCenterLocation,
  onChange = () => {},
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleApiKey,
    language: 'en',
    region: 'us',
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.{loadError}</div>;
  }

  if (!isLoaded) {
    return 'Loading....';
  }
  return (
    <MapView
      initialPath={initialPath}
      centerLocation={centerLocation}
      onChange={onChange}
      otherZones={otherZones}
    />
  );
};

export default PolygonMapSelection;
