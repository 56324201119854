import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 60%;
    margin: 1rem 0rem;
  }
`;

const TbButton = ({ innerText, onClick }) => (
  <Button type="button" className="button control" onClick={onClick}>
    {innerText}
  </Button>
);

export default TbButton;
