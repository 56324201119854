import React from 'react';
import { Link } from 'react-router-dom';
import { first, isNull } from 'lodash';
import moment from 'moment';

const ListItem = ({ user }) => {
  const filterContact =
    !isNull(user.contact) &&
    user.contact.length !== 0 &&
    first(user.contact.filter(item => item.type === 'mobile'));
  console.log('user data', user);
  return (
    <tr>
      <td>
        <Link to={`/admin-user-activity/${user.user_id}`}>{user.email}</Link>
      </td>
      <td>
        {user.first_name} {user.last_name}
      </td>
      <td>
        {user.role && user.role.length !== 0
          ? user.role.map(
              (item, index) =>
                `${item} ${user.role.length - 1 === index ? '' : ','}`,
            )
          : '--'}
      </td>
      <td>-</td>
      <td>
        {!isNull(user.contact) && user.contact.length !== 0 && filterContact
          ? filterContact.value
          : '-'}
      </td>
      <td>
        {user.last_login && user.last_login !== '-'
          ? moment(user.last_login).format('MMM DD, YYYY h:mmA')
          : '-'}
      </td>
    </tr>
  );
};
export default ListItem;
