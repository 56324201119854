import { isArray, isString, intersection } from 'lodash';
import config from './config';

const check = (action, pageActions) => {
  let apiActionPermissions = [];

  if (isArray(action)) {
    apiActionPermissions = intersection(action, pageActions);
  }
  if (isString(action)) {
    apiActionPermissions = pageActions.filter(item => item === action);
  }

  if (!config.enableAcls || apiActionPermissions.length !== 0) {
    return true;
  }
  return false;
};

const CanPage = props =>
  check(props.action, props.pageActions) ? props.yes() : props.no();

CanPage.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default CanPage;
