import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { ACLSConsumer } from '../../utils/aclsContext';
import Can from '../../utils/Can';

const Wrapper = styled.div`
  &&& {
    a {
      width: fit-content;
    }
    .disabled a span {
      color: #a8a8a8 !important;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

const Span = styled.span`
  padding-left: 2px;
  color: #ff0000;
`;
const CheckBox = ({
  required,
  label,
  value,
  onChange,
  disabled,
  secondary,
  action,
}) => {
  const [enable, setEnable] = useState(value);
  useEffect(() => {
    setEnable(value);
  }, [value]);
  return (
    <Wrapper className="field">
      {action ? (
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={action}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <div className={classNames('control', { disabled })}>
                  <input type="checkbox" className="is-hidden" />
                  <a
                    onClick={
                      disabled
                        ? null
                        : () => {
                            setEnable(!enable);
                            onChange(!enable);
                          }
                    }
                    className="has-text-weight-semibold label is-flex"
                  >
                    <span className="has-text-grey">
                      <i
                        id="CheckBox"
                        className={
                          enable
                            ? 'fas fa-check-square has-text-info'
                            : 'far fa-square'
                        }
                      />
                    </span>
                    &nbsp; &nbsp;
                    <span
                      className={
                        secondary
                          ? 'has-text-grey has-text-weight-bold'
                          : 'has-text-grey has-text-weight-normal'
                      }
                    >
                      {label}
                      {required && <Span>*</Span>}
                    </span>
                  </a>
                </div>
              )}
              no={() => (
                <div className="control is-disabled">
                  <input type="checkbox" className="is-hidden" />
                  <a className="has-text-weight-semibold label is-flex">
                    <span className="has-text-grey">
                      <i
                        className={
                          enable
                            ? 'fas fa-check-square has-text-info'
                            : 'far fa-square'
                        }
                      />
                    </span>
                    &nbsp; &nbsp;
                    <span
                      className={
                        secondary
                          ? 'has-text-grey has-text-weight-bold'
                          : 'has-text-grey has-text-weight-normal'
                      }
                    >
                      {label}
                      {required && <Span>*</Span>}
                    </span>
                  </a>
                </div>
              )}
            />
          )}
        </ACLSConsumer>
      ) : (
        <div className={classNames('control', { disabled })}>
          <input type="checkbox" className="is-hidden" />
          <a
            onClick={
              disabled
                ? null
                : () => {
                    // setEnable(!enable);
                    onChange(!enable);
                  }
            }
            className="has-text-weight-semibold label is-flex"
          >
            <span className="has-text-grey">
              <i
                id="CheckBox"
                className={
                  enable ? 'fas fa-check-square has-text-info' : 'far fa-square'
                }
              />
            </span>
            &nbsp; &nbsp;
            <span
              className={
                secondary
                  ? 'has-text-grey has-text-weight-bold'
                  : 'has-text-grey has-text-weight-normal'
              }
            >
              {label}
              {required && <Span>*</Span>}
            </span>
          </a>
        </div>
      )}
    </Wrapper>
  );
};

CheckBox.defaultProps = {
  label: '',
  value: false,
  onChange: () => {},
};

CheckBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckBox;
