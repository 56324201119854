import tzLookup from 'tz-lookup';
import momentTimezone from 'moment-timezone';
import moment from 'moment';

async function getDaysEndTime(dateNow, daysToAdd, timeToAdd) {
  const reqDate = new Date();
  reqDate.setTime(dateNow.getTime() + daysToAdd * 86400000 + timeToAdd);
  return reqDate;
}

async function getElapsedTime(zoneTime) {
  return (
    zoneTime.hours() * 60 * 60 * 1000 +
    zoneTime.minutes() * 60 * 1000 +
    zoneTime.seconds() * 1000 +
    zoneTime.milliseconds()
  );
}

async function getRemainingTime(zoneTime) {
  const time_end = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;
  return time_end - (await getElapsedTime(zoneTime));
}

export const getZoneTime = (date, latitude, longitude) => {
  const lat = parseFloat(latitude).toFixed(5);
  const long = parseFloat(longitude).toFixed(5);
  const { time_zone } = tzLookup(lat, long);
  const time = momentTimezone.tz(date, time_zone);
  return moment(time).toISOString();
};

export const getTodayDateRange = async (latitude, longitude) => {
  const startDate = new Date();
  const zoneTime = await getZoneTime(
    startDate.toISOString(),
    latitude,
    longitude,
  );
  const remainingTimeMs = await getRemainingTime(zoneTime);
  const endDate = await getDaysEndTime(startDate, 0, remainingTimeMs);

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

export const getThisWeekDateRange = async (latitude, longitude) => {
  const dayToThisWeek = [0, 6, 5, 4, 3, 2, 1];
  const startDate = new Date();
  const zoneTime = await getZoneTime(
    startDate.toISOString(),
    latitude,
    longitude,
  );
  const remaining_time_ms = await getRemainingTime(zoneTime);
  const endDate = await getDaysEndTime(
    startDate,
    dayToThisWeek[zoneTime.day()],
    remaining_time_ms,
  );
  return {
    start_date: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

export const getThisWeekendDateRange = async (latitude, longitude) => {
  const dayToWeekendStart = [0, 4, 3, 2, 1, 0, 0];
  const dayToWeekendEnd = [0, 6, 5, 4, 3, 2, 1];
  const dateNow = new Date();
  const zoneTime = await getZoneTime(
    dateNow.toISOString(),
    latitude,
    longitude,
  );
  const remaining_time_ms = await getRemainingTime(zoneTime);
  const startDate = await getDaysEndTime(
    dateNow,
    dayToWeekendStart[zoneTime.day()],
    dayToWeekendEnd[zoneTime.day()] ? -(await getElapsedTime(zoneTime)) : 0,
  );
  const endDate = await getDaysEndTime(
    dateNow,
    dayToWeekendEnd[zoneTime.day()],
    remaining_time_ms,
  );
  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

export const getAllExactEventFilter = async (latitude, longitude) => {
  const today = await getTodayDateRange(latitude, longitude);
  const weekend = await getThisWeekendDateRange(latitude, longitude);
  const thisWeek = await getThisWeekDateRange(latitude, longitude);
  const currentTimeZoneTime = await getZoneTime(
    moment().toISOString(),
    latitude,
    longitude,
  );
  const data = [
    {
      name: 'today',
      description: 'Today',
      range: {
        start_date_range: {
          end_date: moment(today.startDate)
            .utc()
            .toISOString(),
        },
        end_date_range: {
          start_date: moment(today.endDate)
            .utc()
            .toISOString(),
        },
      },
    },
    {
      name: 'this-week',
      description: 'This Week',
      range: {
        start_date_range: {
          end_date: moment(thisWeek.startDate)
            .utc()
            .toISOString(),
        },
        end_date_range: {
          start_date: moment(thisWeek.endDate)
            .utc()
            .toISOString(),
        },
      },
    },
    {
      name: 'weekend',
      description: 'Weekend',
      range: {
        start_date_range: {
          end_date: moment(weekend.startDate)
            .utc()
            .toISOString(),
        },
        end_date_range: {
          start_date: moment(weekend.endDate)
            .utc()
            .toISOString(),
        },
      },
    },

    {
      name: 'upcoming-event',
      description: 'Upcoming Events',
      range: {
        start_date_range: {
          start_date: moment(currentTimeZoneTime)
            .add(1, 'day')
            .utc()
            .toISOString(),
          end_date: moment(currentTimeZoneTime)
            .add(6, 'months')
            .utc()
            .toISOString(),
        },
        end_date_range: '',
      },
    },
  ];
  return data;
};
