import React from 'react';

import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';

const AddTableBookingSettings = ({ history }) => (
  <Layout>
    <PlaceSelection
      claimStatus="NONE"
      hasServiceSetting
      heading="Select Place to add table booking settings"
      handleSelect={value => {
        history.push(`/table-booking-setting/${value.place_id}`);
      }}
    />
  </Layout>
);

export default AddTableBookingSettings;
