import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
// import * as localForage from 'localforage';

import config from './config';

const poolData = {
  UserPoolId: config.userPoolConfig.UserPoolId,
  ClientId: config.userPoolConfig.ClientId,
};

export const cognitoSignUp = (
  firstName,
  lastName,
  displayName,
  username,
  email,
  password,
) =>
  new Promise((resolve, reject) => {
    const attribute = [];
    const emailData = { Name: 'email', Value: email };
    const nameData = { Name: 'name', Value: `${firstName} ${lastName}` };
    const customData = { Name: 'custom:user_id', Value: username };

    const attributeEmail = new CognitoUserAttribute(emailData);
    const attributeName = new CognitoUserAttribute(nameData);
    const customDataName = new CognitoUserAttribute(customData);

    attribute.push(attributeName);
    attribute.push(attributeEmail);
    attribute.push(customDataName);

    const userPool = new CognitoUserPool(poolData);

    userPool.signUp(username, password, attribute, null, (err, result) => {
      if (err) {
        reject(err);
      } else {
        const userId = result.userSub;
        resolve({ userId, username });
      }
    });
  });

export const cognitoSignIn = (username, password) =>
  new Promise((resolve, reject) => {
    console.log('poolData', config);
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: username,
      Pool: userPool,
    };
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();
        const { payload } = result.getIdToken();
        const tokens = { accessToken, idToken, refreshToken, payload };
        resolve(tokens);
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
// export const refreshTokenFunction = (token, username) => {};
export const refreshTokenFunction = (token, username) =>
  new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: username,
      Pool: userPool,
    };
    const args = { getToken: () => token };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.refreshSession(args, (err, session) => {
      if (err) {
        reject(err);
        // localForage.clear();
        window.location.reload();
      } else {
        const accessToken = session.getAccessToken().getJwtToken();
        const idToken = session.getIdToken().getJwtToken();
        const refreshToken = session.getRefreshToken().getToken();
        const { payload } = session.getIdToken();
        const tokens = { accessToken, idToken, refreshToken, payload };
        resolve(tokens);
      }
    });
  });

export const resetPasswordRequest = email =>
  new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    // call forgotPassword on cognitoUser
    cognitoUser.forgotPassword({
      onSuccess(result) {
        console.log(`call result: ${result}`);
        resolve(result);
      },
      onFailure(err) {
        console.log(`fail result: ${err}`);
        reject(err);
      },
    });
  });

export const confirmPassword = (email, verificationCode, newPassword) =>
  new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        console.log('call result success: ');
        resolve();
      },
      onFailure(err) {
        console.log(`fail result: ${err}`);
        reject(err);
      },
    });
  });

export const changePassword = (email, oldPassword, newPassword) =>
  new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const authenticationData = {
      Username: email,
      Password: oldPassword,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess() {
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            reject(err);
          } else {
            console.log(`call result: ${result}`);
            resolve(result);
          }
        });
      },
      onFailure(err) {
        console.log('Sign In error : ', err.message, err.stack);
        reject(err);
      },
    });
  });

const getCognitoUser = username => {
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: username,
    Pool: userPool,
  };
  return new CognitoUser(userData);
};

const getTokens = session => ({
  accessToken: session.getAccessToken().getJwtToken(),
  idToken: session.getIdToken().getJwtToken(),
  refreshToken: session.getRefreshToken().getToken(),
});

export const checkTokenExpiration = () =>
  new Promise((resolve, reject) => {
    const username = window.localStorage.getItem('username');
    const idToken = window.localStorage.getItem('token');
    const accessToken = window.localStorage.getItem('accessToken');
    const refreshToken = window.localStorage.getItem('refreshToken');
    const AccessToken = new CognitoAccessToken({ AccessToken: accessToken });
    const IdToken = new CognitoIdToken({ IdToken: idToken });
    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });
    const sessionData = {
      IdToken,
      AccessToken,
      RefreshToken,
    };

    const cachedSession = new CognitoUserSession(sessionData);
    const cognitoUser = getCognitoUser(username);
    const calculateClockDrift = cachedSession.calculateClockDrift();
    const isValid = calculateClockDrift <= 3600;
    // const isValid = false;
    if (isValid) {
      console.log('calculateClockDrift', calculateClockDrift);
      resolve(true);
    } else {
      cognitoUser.refreshSession(RefreshToken, (err, session) => {
        if (err) {
          window.localStorage.clear();
          window.location.reload();
          reject(err);
        }
        console.log('session', session);
        const tokens = getTokens(session);
        window.localStorage.setItem('token', tokens.idToken);
        window.localStorage.setItem('accessToken', tokens.accessToken);
        window.localStorage.setItem('refreshToken', tokens.refreshToken);
        resolve(true);
      });
    }
  });
