import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { FieldArray } from 'formik';
import swal from 'sweetalert';
import uuid from 'uuid';
import { CheckBox, SelectTime } from '../../../components/elements';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
    .column {
      padding: 0.8rem 0.5rem;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const AddButton = styled.a`
  color: ${({ theme }) => theme.primaryColor};
  position: relative;
  bottom: 10px;
`;

const RemoveButton = styled.a`
  color: ${({ theme }) => theme.secondaryColor};
`;

const PlusOneDay = styled.div`
  position: absolute;
  font-weight: 600;
  color: green;
  text-align: center;
  padding-left: 9px;
`;

const Hours = ({
  serviceType,
  loading = false,
  isActive,
  onClose,
  setFieldValue: parentSetFieldValue,
  values: parentValues,
}) => (
  <Container className={`modal ${isActive && 'is-active'}`}>
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <div>
          <p className="modal-card-title">
            Menu Hours for{' '}
            {serviceType && serviceType.length !== 0 && serviceType[0].label} -{' '}
            {parentValues && parentValues.name}
          </p>
          <br />
          <p className="is-size-3">When is the menu available ?</p>
        </div>
        <CloseButtonWrapper>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </CloseButtonWrapper>
      </header>

      <section className="modal-card-body">
        <DetailsFormWrapper>
          <Column.Group multiline gapSize={1}>
            <FieldArray
              name="hours"
              render={helpers => {
                const { values, setFieldValue } = helpers.form;
                return values.hours.map((hour, index) => (
                  <>
                    <Column size="1">
                      <CheckBox
                        value={hour.is_active}
                        onChange={value => {
                          setFieldValue(`hours.${index}.is_active`, value);
                          if (!value) {
                            setFieldValue(`hours.${index}.open24Hours`, false);
                            setFieldValue(`hours.${index}.option`, [
                              {
                                id: uuid(),
                                start: '',
                                end: '',
                              },
                            ]);
                          }
                        }}
                      />
                    </Column>
                    <Column size="2">
                      <p className="is-size-5 has-text-weight-semibold">
                        {hour.day}
                      </p>
                    </Column>
                    <Column size="9">
                      <Column.Group multiline vcentered gapSize={1}>
                        {hour.option.map((option, index2) => (
                          <>
                            <Column size="4">
                              <SelectTime
                                value={option.start}
                                closed={!hour.is_active}
                                disabled={hour.open24Hours || !hour.is_active}
                                onChange={value => {
                                  setFieldValue(
                                    `hours.${index}.option.${index2}.start`,
                                    value.time,
                                  );
                                  const start_in_sec =
                                    value.hours * 3600 + value.minutes * 60;
                                  setFieldValue(
                                    `hours.${index}.option.${index2}.start_in_sec`,
                                    start_in_sec,
                                  );
                                }}
                              />
                            </Column>
                            <Column size="4">
                              <SelectTime
                                value={option.end}
                                closed={!hour.is_active}
                                disabled={
                                  hour.open24Hours ||
                                  option.start === '' ||
                                  !hour.is_active
                                }
                                onChange={value => {
                                  setFieldValue(
                                    `hours.${index}.option.${index2}.end`,
                                    value.time,
                                  );
                                  const end_in_sec =
                                    value.hours * 3600 + value.minutes * 60;
                                  if (end_in_sec <= option.start_in_sec) {
                                    setFieldValue(
                                      `hours.${index}.option.${index2}.end_in_sec`,
                                      end_in_sec + 86340,
                                    );
                                  } else {
                                    setFieldValue(
                                      `hours.${index}.option.${index2}.end_in_sec`,
                                      end_in_sec,
                                    );
                                  }
                                }}
                              >
                                {hour.is_active &&
                                  option.end_in_sec &&
                                  option.end_in_sec > 86340 && (
                                    <PlusOneDay>
                                      <p>+1 day</p>
                                    </PlusOneDay>
                                  )}
                              </SelectTime>
                            </Column>
                            <Column size="4">
                              {hour.option.length === 1 ? (
                                <CheckBox
                                  label="Open 24 Hrs"
                                  value={hour.open24Hours}
                                  disabled={!hour.is_active}
                                  onChange={value => {
                                    setFieldValue(
                                      `hours.${index}.open24Hours`,
                                      value,
                                    );
                                    if (value) {
                                      setFieldValue(`hours.${index}.option`, [
                                        {
                                          id: uuid(),
                                          start: '12:00 AM',
                                          end: '11:59 PM',
                                          start_in_sec: 0,
                                          end_in_sec: 86340,
                                        },
                                      ]);
                                    } else {
                                      setFieldValue(`hours.${index}.option`, [
                                        {
                                          id: uuid(),
                                          start: '',
                                          end: '',
                                        },
                                      ]);
                                    }
                                  }}
                                />
                              ) : (
                                <RemoveButton
                                  onClick={() => {
                                    const customOption = hour.option.filter(
                                      item => item.id !== option.id,
                                    );
                                    setFieldValue(
                                      `hours.${index}.option`,
                                      customOption,
                                    );
                                  }}
                                >
                                  Remove hours
                                </RemoveButton>
                              )}
                            </Column>
                          </>
                        ))}
                        {!hour.open24Hours && hour.is_active && (
                          <Column size="4">
                            <AddButton
                              onClick={() => {
                                const option = hour.option.concat({
                                  id: uuid(),
                                  type: '',
                                  start: '',
                                  end: '',
                                });
                                setFieldValue(`hours.${index}.option`, option);
                              }}
                            >
                              <p className="is-size-3">Add more hours</p>
                            </AddButton>
                          </Column>
                        )}
                      </Column.Group>
                    </Column>
                  </>
                ));
              }}
            />
          </Column.Group>
        </DetailsFormWrapper>
      </section>
      <footer className="modal-card-foot">
        <button
          type="button"
          className="button"
          aria-label="close"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`button is-primary ${loading && 'is-loading'}`}
          aria-label="close"
          onClick={() => {
            const isAllClosed = parentValues.hours.every(hour => {
              if (!hour.is_active) {
                return true;
              }

              return hour.option.some(
                hourOption => !hourOption.start || !hourOption.end,
              );
            });
            if (isAllClosed) {
              swal(
                'Oops!',
                'Menu hours should not be Closed for all days !!',
                'warning',
              );
              return;
            }
            parentSetFieldValue('menu_hour', parentValues.hours);
            parentSetFieldValue('isHoursDirty', true);
            onClose();
          }}
        >
          Submit
        </button>
      </footer>
    </div>
  </Container>
);

export default Hours;
