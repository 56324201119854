import React, { useState } from 'react';
import styled from 'styled-components';
import Loading from 'react-loading';

export const CategoryBtnContainer = styled.div`
  font-size: 12px;
  border-right: 0.5px solid #eeeeee;
  background-color: ${({ activeBtn, categoryId }) =>
    activeBtn === categoryId ? 'rgba(0, 174, 239, 0.28)' : 'white'};
  height: 50px;
  position: relative;
  border-bottom: ${({ isLast }) => (!isLast ? '1px solid #eeeeee' : undefined)};
`;

const DistributeSpaceBetweenContent = styled.p`
  display: flex;
  justify-content: space-between;
`;

export const InnerFlexContainer = styled(DistributeSpaceBetweenContent)`
  font-size: 12px;
  cursor: pointer;
  width: 100%;
  padding: 0 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const LeftSpan = styled.span`
  display: inline-block;
  width: 75%;
  vertical-align: middle;
  line-height: normal;
  overflow-wrap: break-word;
`;

const TrashIconSpan = styled.span`
  font-weight: 800;
  color: #a8a8a8;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TrashIcon = ({ onClick }) => (
  <TrashIconSpan
    onClick={e => {
      e.stopPropagation();
      onClick();
    }}
  >
    <i
      className="far fa-trash-alt"
      style={{ color: '#737373', paddingLeft: '12px' }}
    />
  </TrashIconSpan>
);

const CategoryBtn = ({
  categoryName,
  onClick,
  isLast,
  activeBtn,
  categoryId,
  editCategories,
  onTrashIconClick,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <CategoryBtnContainer
      categoryId={categoryId}
      isLast={isLast}
      activeBtn={activeBtn}
    >
      <InnerFlexContainer onClick={onClick}>
        {editCategories && (
          <span
            style={{
              fontWeight: '800',
              marginRight: '6px',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <i className="fas fa-arrows-alt" />
          </span>
        )}

        <LeftSpan
          style={{
            color: '#0D0D0D',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          {categoryName}
          {/* abcdefghijklmnopqrstuvwxyzabcdefghi */}
        </LeftSpan>
        {editCategories && (
          <>
            {isDeleting && (
              <Loading type="spin" color="#363636" width="12px" height="12px" />
            )}
            {!isDeleting && (
              <TrashIcon
                onClick={async () =>
                  onTrashIconClick(categoryId, setIsDeleting)
                }
              />
            )}
          </>
        )}
        {!editCategories && (
          <span
            style={{
              fontWeight: '800',
              color: '#a8a8a8',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <i className="fa fa-angle-right" />
          </span>
        )}
      </InnerFlexContainer>
    </CategoryBtnContainer>
  );
};

export default CategoryBtn;
