/* eslint-disable no-shadow */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import { toPng } from 'html-to-image';
import useOnClickOutside from './useOnClickOutside';
import { Input, TextArea } from '../../../../components/elements';

const Container = styled.div``;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const FlexRow2 = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
`;

const Card = styled.div`
  border: 1px solid #e6e6e6;
  padding: 2rem 1rem;
  background-color: #ffffff;
`;

const TopContent = styled.div`
  border: 1px solid #000000;
  padding: 1rem;
  margin: 2rem 0rem;
  display: flex;
  border-radius: 6px;
`;

const BottomContent = styled.div`
  padding: 0rem 1rem;
`;
const QRCodeWrapper = styled.div`
  background-color: #ffffff;
`;

const Centered = styled.p`
  text-align: center;
`;

const LogoContainer = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: white;
  background-image: url('/images/kravein-logo.png');
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const first = a => (a ? a[0] : null);
const toNumber = an => first((an ?? '').match(/\d+/));

const TableQRCode = ({
  menuName,
  placeName,
  qrCodeValue,
  siteLabel,
  count,
  setSiteLabel,
  setCount,
}) => {
  const popRef = useRef();
  const [isActivePop, setIsActivePop] = useState(false);
  useOnClickOutside(popRef, () => setIsActivePop(false));
  // const [count, setCount] = useState(null);
  const [countFrom, setCountFrom] = useState(null);
  const [countTo, setCountTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadMore, setDownloadMore] = useState(false);

  // const [siteLabel, setSiteLabel] = useState('Table');
  const [topContent, setTopContent] = useState(
    'Order from your device and we will deliver to you',
  );
  const [bottomContent, setBottomContent] = useState(
    'Point your phone camera to scan the code and open our menu.',
  );

  const ref = React.createRef();

  const onDownloadPNG = () => {
    setLoading(true);
    setIsActivePop(false);
    const node = document.getElementById('qr-code-panel');
    toPng(node, { quality: 1 }).then(dataUrl => {
      const link = document.createElement('a');
      link.download = `qr-code-${count}.png`;
      link.href = dataUrl;
      link.click();
      setLoading(false);
    });
  };

  const onDownloadQRCodePNG = () => {
    setLoading(true);
    setIsActivePop(false);
    const node = document.getElementById('qr-code-table');
    toPng(node, { quality: 1 }).then(dataUrl => {
      const link = document.createElement('a');
      link.download = `${siteLabel}-${count}-qr-code.png`;
      link.href = dataUrl;
      link.click();
      if (countTo) {
        const alphaNumericRegex = /[a-zA-Z]/;
        const isAlphaNumeric = alphaNumericRegex.test(count);
        if (isAlphaNumeric) {
          const alpha = count.match(/[a-zA-z]+/);
          const numeric = count.match(/\d+/);
          const alphaIndex = alpha.index;
          const numericIndex = numeric.index;
          const a = alpha[0];
          const n = parseInt(numeric[0], 10) + 1;
          if (alphaIndex < numericIndex) {
            setDownloadMore(true);
            setCount(`${a}${n}`);
          } else {
            setDownloadMore(true);
            setCount(`${n}${a}`);
          }
        } else {
          setDownloadMore(true);
          setCount(`${parseInt(count, 10) + 1}`);
        }
      }
      setLoading(false);
    });
  };

  const qrCodeValueFromCount = (qrCodeValue, siteLabel, count) => {
    let url = qrCodeValue;
    if (siteLabel) {
      url = count ? `${qrCodeValue}&table=${siteLabel}-${count}` : qrCodeValue;
    } else {
      url = count ? `${qrCodeValue}&table=table-${count}` : qrCodeValue;
    }
    return url;
  };

  console.group('the small qr code value @ menu');
  console.log(qrCodeValueFromCount(qrCodeValue, siteLabel, count));
  console.groupEnd();

  React.useEffect(() => {
    if (
      downloadMore &&
      count !== countFrom &&
      parseInt(toNumber(count), 10) <= parseInt(toNumber(countTo), 10)
    ) {
      onDownloadQRCodePNG();
    }
  }, [downloadMore, count]);

  React.useEffect(() => {
    if (parseInt(toNumber(count), 10) === parseInt(toNumber(countTo), 10) + 1) {
      setCount(countFrom);
    }
  }, [count]);

  return (
    <Container>
      <p>
        Generate unique QR codes as per your need such as table or booth for
        your menu. For example, You can generate a QR Code that will open your
        menu with the table number. Printed size is 4&quot; x 6&quot;
      </p>
      <br />
      <div className="columns is-8">
        <div className="column is-6">
          <Input
            type="text"
            label="Site Label"
            optionalText="(Max 10 chars)"
            value={siteLabel}
            onChange={e => {
              if (e.target.value.length <= 10) {
                setSiteLabel(e.target.value);
              }
            }}
          />
          <FlexRow>
            <Input
              type="text"
              label="Site No From"
              optionalText="(Max 5 chars)"
              onChange={e => {
                if (e.target.value.length <= 5) {
                  const siteNo = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                  // siteNo = siteNo.toLowerCase();
                  setCount(siteNo);
                  setCountFrom(siteNo);
                }
              }}
              value={count}
            />
            <Input
              type="text"
              label="Site No To"
              optionalText="(Max 5 chars)"
              onChange={e => {
                if (e.target.value.length <= 5) {
                  const siteNo = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                  // siteNo = siteNo.toLowerCase();
                  setCountTo(siteNo);
                }
              }}
              value={countTo}
              // disabled
            />
          </FlexRow>

          <TextArea
            label="Top Content"
            value={topContent}
            rows={3}
            onChange={e => {
              setTopContent(e.target.value);
            }}
          />
          <TextArea
            label="Bottom Content"
            value={bottomContent}
            onChange={e => {
              setBottomContent(e.target.value);
            }}
            rows={3}
          />
          <FlexRow>
            <div
              ref={popRef}
              className={`dropdown is-up ${isActivePop && 'is-active'}`}
            >
              <div className="dropdown-trigger">
                <button
                  type="button"
                  className={`button is-primary ${loading && 'is-loading'}`}
                  aria-haspopup="true"
                  aria-controls="dropdown-menu7"
                  onClick={() => setIsActivePop(true)}
                  disabled={!count}
                >
                  <span>Download</span>
                </button>
              </div>
              <div className="dropdown-menu " id="dropdown-menu7" role="menu">
                <div className="dropdown-content">
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      if (parseInt(count, 10) === parseInt(countTo, 10)) {
                        return;
                      }
                      setLoading(true);
                      setIsActivePop(false);
                      html2canvas(ref.current, {
                        logging: false,
                        useCORS: true,
                      }).then(canvas => {
                        const imgData = canvas.toDataURL();

                        const pdf = new JsPdf('p', 'mm', 'a4');
                        const pageWidth = pdf.internal.pageSize.getWidth();
                        const pageHeight = pdf.internal.pageSize.getHeight();

                        const widthRatio = pageWidth / canvas.width;
                        const heightRatio = pageHeight / canvas.height;
                        const ratio =
                          widthRatio > heightRatio ? heightRatio : widthRatio;

                        const canvasWidth = canvas.width * ratio;
                        const canvasHeight = canvas.height * ratio;

                        const marginX = (pageWidth - canvasWidth) / 2;
                        const marginY = (pageHeight - canvasHeight) / 2;

                        pdf.addImage(
                          imgData,
                          'JPEG',
                          marginX + 10,
                          marginY,
                          canvasWidth - 20,
                          canvasHeight,
                        );
                        pdf.save(`table-${count}.pdf`);

                        setLoading(false);
                      });
                    }}
                  >
                    Download as pdf
                  </a>

                  <a className="dropdown-item" onClick={onDownloadPNG}>
                    Download as png
                  </a>
                  <a className="dropdown-item" onClick={onDownloadQRCodePNG}>
                    Download QR code
                  </a>
                </div>
              </div>
            </div>
          </FlexRow>
        </div>
        <div className="column is-5">
          <Card id="qr-code-panel" ref={ref}>
            <Centered className="is-size-5 has-text-weight-bold is-uppercase">
              {placeName}
            </Centered>
            <Centered className="is-size-3 has-text-weight-semibold">
              {menuName}
            </Centered>
            <TopContent>
              <Centered className="has-text-weight-semibold">
                {topContent}
              </Centered>
            </TopContent>

            <FlexRow2>
              <QRCodeWrapper style={{ position: 'relative' }}>
                <LogoContainer />

                <QRCode
                  value={qrCodeValueFromCount(qrCodeValue, siteLabel, count)}
                  size={100}
                />
              </QRCodeWrapper>
              <BottomContent className="has-text-weight-semibold is-size-4">
                {bottomContent}
              </BottomContent>
            </FlexRow2>
            <p className="has-text-weight-semibold">
              {siteLabel} {count}
            </p>
          </Card>
        </div>
      </div>
    </Container>
  );
};

export default TableQRCode;
