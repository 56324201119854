import React from 'react';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';

import EmailNotificationForm from './EmailNotificationForm';
import Layout from '../../../components/global/Layout';
import { fetchNotificationTemplate } from './helpers';

const UpdateEmailNotificationTemplate = ({ history, match }) => {
  const { notificationId } = match.params;
  const { userId } = useStoreState(state => state.auth);

  const [loading, setLoading] = React.useState(false);
  const [notification, setNotification] = React.useState();

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      try {
        const res = await fetchNotificationTemplate({
          userId,
          notificationId,
        });
        if (res) {
          setNotification(res);
        } else {
          toast.error('Could Not Fetch Notification Template!');
        }
        setLoading(false);
      } catch {
        setLoading(false);
        toast.error('Could Not Fetch Notification Template!');
      }
    };
    fetchFunc();
  }, []);

  if (loading) {
    return (
      <Layout>
        <span style={{ color: '#00AEEF', padding: '20px' }}>Loading...</span>
      </Layout>
    );
  }

  return notification ? (
    <EmailNotificationForm
      isUpdate
      notificationTemplateId={notification.notification_template_id}
      notificationId={notification.notification_id}
      notificationPlatfrom={notification.notification_platform}
      status={notification.status}
      name={notification.name}
      history={history}
      userId={userId}
      role={notification.user_role}
      apiAction={notification.api_action}
      actionStatus={notification.action_status}
      bcc={notification.bcc}
      cc={notification.cc}
      to={notification.to}
      replyTo={notification.reply_to}
      serviceType={notification.service_type}
    />
  ) : (
    <></>
  );
};

export default UpdateEmailNotificationTemplate;
