import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import uuid from 'uuid';
import randomColor from 'randomcolor';
import { forEach } from 'lodash';
import ZoneSelectionModal from './ZoneSelectionModal';
import MultipleMapZone from './MultipleMapZone';

const AddItemsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 40%;
  }
`;

const Content = styled.div`
  padding: 1rem 0rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  padding: 1rem 0rem;
  span {
    color: #848181;
  }
`;

const Flex2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Color = styled.div`
  height: 1rem;
  width: 1rem;
  background: ${({ color }) => color};
  border-radius: 4px;
  margin-right: 1rem;
`;

const Buttons = styled.div`
  &&& {
    margin-left: 1rem;
  }
`;

const parseZone = values => {
  const final = [];
  forEach(values, item => {
    const path = item.delivery_area.area_polygon.map(item2 => ({
      lat: item2.latitude,
      lng: item2.longitude,
    }));
    const delivery_area = {
      area_type: item.delivery_area.area_type,
      area_circle: item.delivery_area.area_circle,
      area_polygon: path,
    };
    final.push({ ...item, delivery_area });
  });
  return final;
};

const ZoneSetting = ({ place, values, setFieldValue }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);

  const latitude = parseFloat(place.latitude);
  const longitude = parseFloat(place.longitude);

  const remove = id => {
    const newSetting = values.zone_setting.filter(item => item.id !== id);
    const updateZoneName = newSetting.map((item, index) => ({
      ...item,
      name: `Zone ${index + 1}`,
    }));
    setFieldValue('zone_setting', updateZoneName);
  };

  const zoneSetting = parseZone(values.zone_setting);
  const zoneSettingExceptSelected = selectedZone
    ? parseZone(values.zone_setting.filter(item => item.id !== selectedZone.id))
    : parseZone(values.zone_setting);

  return (
    <div className="columns">
      <div className="column">
        <FieldArray
          name="zone_setting"
          render={helper => (
            <>
              <AddItemsButton
                type="button"
                className="button is-medium"
                onClick={() => {
                  setIsActiveModal(true);
                }}
              >
                Add delivery zone
              </AddItemsButton>
              <Content>
                {values.zone_setting.map((zone, index) => (
                  <Flex2>
                    <Flex>
                      <Color color={zone.color_code} />
                      <div>
                        <p>
                          {zone.name}, <span>Delivery Fee</span> - $
                          {zone.delivery_fee}, <span>Delivery Estimate</span> -
                          {zone.delivery_estimate} Min
                        </p>
                      </div>
                    </Flex>
                    <Buttons className="field has-addons">
                      <p className="control">
                        <button
                          type="button"
                          className="button"
                          onClick={() => {
                            setSelectedZone({ ...zone, index });
                            setIsActiveModal(true);
                          }}
                        >
                          <span className="icon is-small">
                            <i className="fas fa-edit" />
                          </span>
                        </button>
                      </p>
                      <p className="control">
                        <button
                          type="button"
                          className="button"
                          onClick={() => remove(zone.id)}
                        >
                          <span className="icon is-small">
                            <i className="fas fa-trash" />
                          </span>
                        </button>
                      </p>
                    </Buttons>
                  </Flex2>
                ))}
              </Content>

              {setIsActiveModal && (
                <ZoneSelectionModal
                  zoneSettingExceptSelected={zoneSettingExceptSelected}
                  zone={selectedZone}
                  isActive={isActiveModal}
                  onClose={() => {
                    setIsActiveModal(false);
                    setSelectedZone(null);
                  }}
                  placeLocation={{
                    latitude,
                    longitude,
                  }}
                  onSubmit={(input, { resetForm }) => {
                    const value = {
                      id: selectedZone ? selectedZone.id : uuid(),
                      name: selectedZone
                        ? selectedZone.name
                        : `Zone ${values.zone_setting.length + 1}`,
                      delivery_area: {
                        area_type: input.area_type,
                        area_circle:
                          input.area_type === 'CIRCLE'
                            ? {
                                ...input.area_circle,
                                radius: `${input.area_circle.radius}km`,
                              }
                            : null,
                        area_polygon:
                          input.area_type === 'POLYGON'
                            ? input.area_polygon.concat(input.area_polygon[0])
                            : [],
                      },
                      delivery_fee: parseFloat(input.delivery_fee),
                      delivery_estimate: parseInt(input.delivery_estimate, 10),
                      color_code: selectedZone
                        ? selectedZone.color_code
                        : randomColor({
                            luminosity: 'dark',
                          }),
                    };
                    if (selectedZone) {
                      setFieldValue(
                        `zone_setting.${selectedZone.index}`,
                        value,
                      );
                      setIsActiveModal(false);
                      resetForm();
                      setSelectedZone(null);
                    } else {
                      helper.push(value);
                      setIsActiveModal(false);
                      resetForm();
                    }
                  }}
                />
              )}
            </>
          )}
        />
      </div>
      <div className="column is-7">
        {!isActiveModal && (
          <MultipleMapZone
            zone={zoneSetting}
            centerLocation={{ lat: latitude, lng: longitude }}
          />
        )}
      </div>
    </div>
  );
};

export default ZoneSetting;
