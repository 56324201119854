import React from 'react';
import { first, uniq } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

const PlaceList = ({ place, users = [], history }) => {
  const userArray = users.filter(
    item => item.user_id === place.audit.updated_by,
  );

  const serviceTypesArray =
    place.service_type && place.service_type.length !== 0
      ? place.service_type.map(item => item.name)
      : [];

  const serviceTypes = uniq(serviceTypesArray).filter(
    ele =>
      ele === 'Pickup' ||
      ele === 'Delivery' ||
      ele === 'Dinein' ||
      ele === 'Table Booking' ||
      ele === 'Table booking',
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <React.Fragment>
      <td>
        <a
          onClick={() =>
            history.push(`/item-availability-detail/${place.place_id}`, {
              data: place,
            })
          }
        >
          {place.name}
        </a>
      </td>
      <td>{place.city}</td>
      <td>
        {serviceTypes.length !== 0
          ? serviceTypes.map(
              (item, index) =>
                `${item} ${serviceTypes.length - 1 === index ? '' : ','}`,
            )
          : '--'}
      </td>
      <td>{user ? <p> {user.full_name}</p> : place.audit.updated_by}</td>
      <td>{moment(place.audit.updated_at).format('lll')}</td>
      <td>-</td>
    </React.Fragment>
  );
};

export default withRouter(PlaceList);
