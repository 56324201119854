import React, { useState, useEffect, useCallback } from 'react';
import { useStoreState } from 'easy-peasy';
import { Column } from 'rbx';
import { omit } from 'lodash';
import uuidv4 from 'uuid/v4';

import { toast } from 'react-toastify';
import { BoxWithHeader, Select } from '../../../components/elements';
import { getMenus, getSections, checkLinkIsAlreadyExist } from './helpers';

const AddNewMenuAndSection = ({
  links,
  placeId,
  itemId,
  // price,
  variant,
  onSubmit,
}) => {
  const [selectedMenu, setSelectedMenu] = useState();
  const [selectSection, setSelectSection] = useState();
  const [menuSuggestions, setMenuSuggestions] = useState([]);
  const [sectionSuggestions, setSectionSuggestions] = useState([]);
  const [menuLoading, setMenuLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);
  const { userId } = useStoreState(state => state.auth);

  const createItemLink = () => {
    onSubmit({
      item_link_id: uuidv4(),
      place_id: placeId,
      menu_item_id: itemId,
      service_type_setting: {
        service_type_setting_id:
          selectedMenu &&
          selectedMenu.service_type_setting.service_type_setting_id,
        service_type: selectedMenu && selectedMenu.service_type,
      },
      price: null,
      variant:
        variant && variant.length !== 0
          ? variant.map(item =>
              omit(
                {
                  ...item,
                  variants: item.variants.map(variants => ({
                    ...variants,
                    variant_price: null,
                  })),
                },
                'id',
              ),
            )
          : null,
      menu: {
        menu_id: selectedMenu && selectedMenu.menu_id,
        name: selectedMenu && selectedMenu.name,
      },

      menu_section: {
        menu_section_id: selectSection && selectSection.menu_section_id,
        name: selectSection && selectSection.name,
      },
    });
    setSelectedMenu();
    setSelectSection();
  };

  const fetchMenusData = useCallback(async () => {
    setMenuLoading(true);
    try {
      const list = await getMenus({ placeId, userId });

      setMenuSuggestions(
        list.map(item => ({
          ...item,
          label: `${item.service_type} - ${item.name}`,
          value: item.menu_id,
        })),
      );
      setMenuLoading(false);
    } catch (error) {
      console.error(error);
      setMenuLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMenusData();
  }, []);

  const fetchSectionsData = useCallback(async () => {
    setSectionLoading(true);
    try {
      const list = await getSections({ menuId: selectedMenu.menu_id, userId });
      setSectionSuggestions(
        list.map(item => ({
          ...item,
          label: item.name,
          value: item.menu_section_id,
        })),
      );
      setSectionLoading(false);
    } catch (error) {
      setSectionLoading(false);
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (selectedMenu) {
      fetchSectionsData();
    }
  }, [selectedMenu]);

  return (
    <BoxWithHeader
      header={
        <Column.Group vcentered>
          <Column size="4">
            <Select
              placeholder="Select Menu"
              value={selectedMenu ? { ...selectedMenu } : null}
              options={menuSuggestions}
              onChange={value => {
                setSelectSection();
                setSelectedMenu(value);
              }}
              isLoading={menuLoading}
            />
          </Column>
          <Column size="4">
            <Select
              placeholder="Select Menu Category"
              value={selectSection ? { ...selectSection } : null}
              options={sectionSuggestions}
              onChange={value => {
                setSelectSection(value);
              }}
              isLoading={sectionLoading}
              disabled={!selectedMenu}
            />
          </Column>
          {/* <Column size="2">
            <CheckBox label="Price Override" />
          </Column> */}
          <Column size="4">
            <button
              type="button"
              className="button is-primary"
              onClick={() => {
                if (
                  checkLinkIsAlreadyExist(links, {
                    service_type_setting_id:
                      selectedMenu &&
                      selectedMenu.service_type_setting.service_type_setting_id,
                    menu_id: selectedMenu && selectedMenu.menu_id,
                    menu_section_id:
                      selectSection && selectSection.menu_section_id,
                  })
                ) {
                  toast.error(
                    'Menu item is already exist in this menu and section please select another one',
                  );
                } else {
                  createItemLink();
                }
              }}
              disabled={!selectSection}
            >
              Assign Item In Menu
            </button>
          </Column>
        </Column.Group>
      }
    />
  );
};

export default AddNewMenuAndSection;
