import React from 'react';
import { Column } from 'rbx';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import { Table, Input } from '../../../components/elements';
import { pollFoodOrdersQuery, parseUrl, getMenusObj } from './helpers';
import URLS from './urls';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

const StatusCell = ({ idx, url, checkStatus = false, onCompletion }) => {
  const [loading, setLoading] = React.useState(false);
  const [isWorking, setIsWorking] = React.useState(null);
  const [startCheck, setStartCheck] = React.useState(false);

  if (checkStatus && !startCheck) {
    setStartCheck(true);
    setLoading(true);
    setIsWorking(null);
  }

  React.useEffect(() => {
    const func = async () => {
      setLoading(true);
      if (idx > 30) {
        await delay(6000);
      }

      if (idx > 60) {
        await delay(6000);
      }

      if (idx > 90) {
        await delay(6000);
      }

      if (idx > 120) {
        await delay(6000);
      }

      const parsedUrl = parseUrl(url);
      const [serviceType, slug] = parsedUrl;
      const res = await pollFoodOrdersQuery({
        serviceType,
        slug,
      });
      setIsWorking(res);
      setLoading(false);
      setStartCheck(false);
      onCompletion();
    };
    if (startCheck) {
      func();
    }
  }, [startCheck]);

  if (loading) {
    return (
      <button
        style={{ fontSize: '8px' }}
        className="button is-loading is-rounded"
      >
        Loading
      </button>
    );
  }

  if (isWorking === null) {
    return (
      <button
        style={{ fontSize: '8px' }}
        onClick={() => setStartCheck(true)}
        className="button  is-rounded"
      >
        Run Test
      </button>
    );
  }

  if (isWorking) {
    return (
      <button
        style={{ fontSize: '8px' }}
        className="button is-success is-rounded"
      >
        Working
      </button>
    );
  }
  return (
    <button style={{ fontSize: '8px' }} className="button is-danger is-rounded">
      Not Working
    </button>
  );
};

const capitalizeWords = str =>
  str.replace(/\b\w/g, match => match.toUpperCase());

const MenuCell = ({ url }) => {
  const [menuObj, setMenuObj] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const func = async () => {
    setLoading(true);
    const parsedUrl = parseUrl(url);
    const [serviceType, slug] = parsedUrl;
    const obj = await getMenusObj({ serviceType, slug });
    setMenuObj(obj);
    setLoading(false);
  };

  return (
    <>
      <button
        type="button"
        style={{ fontSize: '8px' }}
        className={`button ${loading && 'is-loading'} is-rounded`}
        onClick={func}
      >
        Open Close Status
      </button>
      {menuObj && (
        <pre>
          <code>{JSON.stringify(menuObj, null, 2)}</code>
        </pre>
      )}
    </>
  );
};

const Search = ({ setUrl, setCheckArray }) => {
  const [placename, setPlacename] = React.useState('');
  return (
    <Column size="6">
      <Input
        label="Place Name"
        type="text"
        id="place_name_url_health_1"
        value={placename}
        onChange={pname => {
          setPlacename(pname.target.value);
          if (!pname.target.value) {
            setUrl(URLS);
            setCheckArray(new Array(URLS.length).fill(0));
          }
        }}
        placeholder="Search a place"
      />
      <button
        type="button"
        className="button is-primary"
        onClick={async () => {
          const u =
            placename === ''
              ? URLS
              : URLS.filter(item =>
                  capitalizeWords(parseUrl(item)[1].replace(/-/g, ' '))
                    .toLowerCase()
                    .includes(placename.toLowerCase()),
                );

          setUrl(u);
          setCheckArray(new Array(u.length).fill(0));
        }}
      >
        <span className="has-text-weight-semibold">Search</span>
      </button>
    </Column>
  );
};

const Index = () => {
  const [urlsArray, setUrlsArray] = React.useState(URLS);
  const [checkArray, setCheckArray] = React.useState(
    new Array(URLS.length).fill(0),
  );

  return (
    <Layout>
      <Header />
      <div style={{ padding: '5px', marginBottom: '50px' }}>
        <Search setUrl={setUrlsArray} setCheckArray={setCheckArray} />
        <div style={{ paddingTop: '25px' }} />
        <button
          type="button"
          className="button is-fullwidth"
          onClick={() => setCheckArray(new Array(urlsArray.length).fill(1))}
        >
          Run All Tests
        </button>
        <div style={{ paddingTop: '25px' }} />

        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>URLs</th>
              <th>Status</th>
              <th>Menus</th>
            </tr>
          </thead>
          <tbody>
            {urlsArray.map((ele, idx) => (
              <tr key={ele}>
                <td key={ele} style={{ whiteSpace: 'nowrap' }}>
                  {capitalizeWords(parseUrl(ele)[1].replace(/-/g, ' '))}
                  {', '}
                  {parseUrl(ele)[0]}
                </td>
                <td
                  key={ele}
                  style={{ color: '#00AEEF', whiteSpace: 'nowrap' }}
                >
                  <a href={ele} target="_blank" rel="noreferrer">
                    {ele}
                  </a>
                </td>
                <td>
                  <StatusCell
                    key={ele}
                    idx={idx}
                    url={ele}
                    checkStatus={checkArray[idx] === 1}
                    onCompletion={() =>
                      setCheckArray(prev => {
                        const temp = [...prev];
                        temp[idx] = 0;
                        return temp;
                      })
                    }
                  />
                </td>
                <td>
                  <MenuCell url={ele} key={ele} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div style={{ padding: '5px', marginBottom: '50px' }} />
    </Layout>
  );
};

export default Index;
