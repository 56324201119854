import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';

import { ErrorMessage, Loading } from '../../../components/elements';
import Form from './Form';

const customHourQuery = gql`
  query customHour($input: FetchCustomHourInput) {
    fetch_custom_hour(input: $input) {
      place {
        name
      }
      custom_hour_id
      place_id
      menu_id
      object_type
      day
      closed_all_day
      description
      option {
        start
        end
        start_in_sec
        end_in_sec
      }
    }
  }
`;

const updateCustomHourMutation = gql`
  mutation updateCustomHour($input: UpdateCustomHourInput) {
    update_custom_hour(input: $input) {
      custom_hour_id
      error {
        description
      }
    }
  }
`;

const Update = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const { hourId } = match.params;

  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={customHourQuery}
        variables={{ input: { custom_hour_id: hourId } }}
      >
        {({ data, loading: fetchLoading, error: fetchError }) => {
          if (fetchLoading) {
            return <Loading />;
          }
          if (fetchError) {
            return <ErrorMessage message={fetchError.message} />;
          }
          const hour = data.fetch_custom_hour;
          return (
            <Mutation
              client={client.clientPrivate}
              mutation={updateCustomHourMutation}
              onCompleted={({ update_custom_hour }) => {
                if (!isNull(update_custom_hour.error)) {
                  update_custom_hour.error.map(item =>
                    toast.error(item.description),
                  );
                } else {
                  swal(
                    'Great!',
                    'Custom Hour updated successfully!',
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }
              }}
            >
              {(update_custom_hour, { loading, error }) => (
                <>
                  {error && <ErrorMessage message={error.message} />}
                  <Form
                    isUpdate
                    userId={userId}
                    hour={hour}
                    loading={loading}
                    history={history}
                    placeName={hour.place.name}
                    onSubmit={values => {
                      const input = omit(values, 'place_id', 'object_type');

                      const inputsArray = {
                        user_id: input.user_id,
                        custom_hour_id: hour.custom_hour_id,
                      };

                      Object.assign(
                        inputsArray,
                        !isEqual(input.option, hour.option) && {
                          option: input.option,
                        },
                        !isEqual(input.description, hour.description) && {
                          description: input.description,
                        },
                      );
                      update_custom_hour({
                        variables: {
                          input: inputsArray,
                        },
                      });
                    }}
                  />
                </>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default Update;
