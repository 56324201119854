import React from 'react';
import Layout from '../../../components/global/Layout';
import Form from './Form';

const Payments = () => (
  <Layout>
    <Form />
  </Layout>
);

export default Payments;
