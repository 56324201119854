import React from 'react';
import { Link } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({ loading, handleSubmit, prev, next, activeTab }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        {activeTab !== 0 && (
          <Level.Item>
            <button onClick={prev} className="button is-light">
              <span>Back</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
      <Level.Item align="right">
        {activeTab !== 5 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Footer;
