import React, { useState } from 'react';
import styled from 'styled-components';
import { Column, Title, Level, Table } from 'rbx';
// import { Query } from 'react-apollo';
// import { isNull } from 'lodash';
import gql from 'graphql-tag';
import Footer from './Footer';
// import ListItem from './ListItem';
import Search from './Search';
import {
  Input,
  TextArea,
  TagInputs,
  CheckBox,
  Table as CustomTable,
  CustomInput,
  CustomSelect,
  Loading,
  Pagination,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_place_claim(input: $input) {
      place_id
      claimant_id
      name
      city
      status
      claim_status
    }
  }
`;

const Container = styled.div``;

const Social = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  createForm,
  props,
}) => {
  const [statusFilter, setStatusFilter] = useState('LIKE COUNT');
  return (
    <React.Fragment>
      <Level>
        <Column size={3}>
          <Input label="Likes" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Shares" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Bookmarks" placeholder="22" disabled />
        </Column>
      </Level>
      <Search statusFilter={statusFilter} setStatusFilter={setStatusFilter} />
      {/* <Pagination /> */}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Event Name
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Place Name
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> City / Suburb
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Social Type
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Updated At
                </span>
                <br />
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell>David</Table.Cell>
            <Table.Cell>Raj_mallampati@hotmail.com</Table.Cell>
            <Table.Cell>05/06/2018 12:29:21 AM</Table.Cell>
          </Table.Row>
        </Table.Body>
      </CustomTable>
      {/* <Query
        query={placesQuery}
        variables={{ input: { filter: { place_filter: {} } } }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }

          const { search_place_claim } = data;
          if (isNull(search_place_claim)) {
            return 'no data found';
          }

          return (
            <tbody>
              {search_place_claim.map(claim => (
                <ListItem claim={claim} />
              ))}
            </tbody>
          );
        }}
      </Query> 
   */}
    </React.Fragment>
  );
};

export default Social;
