import React from 'react';
import { Link } from 'react-router-dom';
import { first, replace } from 'lodash';
import moment from 'moment';

import DeleteEventAction from './DeleteEventAction';

const EventList = ({ event }) => (
  <React.Fragment>
    <td>
      <Link to={`/event-activity/${event.event_id}`}>{event.name}</Link>
    </td>
    <td>{`${event.place_name}`}</td>
    <td>{`${event.city}`}</td>
    <td className="is-capitalized">
      {replace(first(event.listing_type), '_', ' ')}
    </td>
    {/* <td>{`${event.place_name}`}</td> */}
    <td>{`${moment(event.start).format('lll')}`}</td>

    <td>{event.status}</td>
    <td>
      <DeleteEventAction eventId={event.event_id} />
    </td>
  </React.Fragment>
);

export default EventList;
