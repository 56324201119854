import React, { useEffect } from 'react';
import { replace } from 'lodash';
import { toast } from 'react-toastify';
import { Column } from 'rbx';
import { getIn, Field } from 'formik';

import { Input, Select, MobileInput } from '../../../components/elements';

const Toast = ({ error, error2 }) => {
  useEffect(() => {
    toast.error(error);
  }, [error]);
  return <div className="has-text-danger	is-size-5">{error2}</div>;
};

const ErrorMessage = ({ name, message, ticketName }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <Toast error={`${message} ${error} in ${ticketName}`} error2={error} />
      ) : null;
    }}
  />
);

const AttendeeInputs = ({
  attributes,
  ticketIndex,
  attendeeIndex,
  handleChange,
  handleBlur,
  setFieldValue,
  ticketName,
}) => (
  <React.Fragment>
    <Column.Group multiline vcentered gapSize={8}>
      {attributes.map((item, index) => {
        if (item.name === 'name') {
          return (
            <Column size="half">
              <Input
                label="Attendee Name"
                name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                value={item.values}
                onChange={handleChange}
                onBlur={handleBlur}
                required={item.required}
              />
              <ErrorMessage
                name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                message="Attendee Name is"
                ticketName={ticketName}
              />
            </Column>
          );
        }
        if (item.name === 'mobile') {
          return (
            <Column size="half">
              <MobileInput
                label="Mobile Number"
                required={item.required}
                value={item.values}
                onChange={value =>
                  setFieldValue(
                    `ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`,
                    replace(value, ' ', ''),
                  )
                }
                onBlur={handleBlur}
              />
              <ErrorMessage
                name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                message="Attendee Mobile Number is"
                ticketName={ticketName}
              />
            </Column>
          );
        }
        if (item.name === 'email') {
          return (
            <Column size="half">
              <Input
                label="Email Address"
                required={item.required}
                name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                value={item.values}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                message="Attendee Email Address is"
                ticketName={ticketName}
              />
            </Column>
          );
        }
        if (item.name === 'gender') {
          return (
            <Column size="half">
              <Select
                label="Gender"
                options={[
                  { value: 'MALE', label: 'MALE' },
                  { value: 'FEMALE', label: 'FEMALE' },
                  { value: 'OTHER', label: 'OTHER' },
                ]}
                value={[{ value: item.values, label: item.values }]}
                onChange={value =>
                  setFieldValue(
                    `ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`,
                    value.value,
                  )
                }
                required={item.required}
              />
              <ErrorMessage
                name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                message="Attendee Gender is"
                ticketName={ticketName}
              />
            </Column>
          );
        }
        if (item.name === 'age') {
          return (
            <Column size="half">
              <Input
                label="Age"
                type="number"
                required={item.required}
                name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                value={item.values}
                onChange={event => {
                  const value = parseFloat(event.target.value).toFixed(0);

                  if (
                    (value <= 120 && value > 0 && event.target.value !== 'e') ||
                    event.target.value === ''
                  ) {
                    setFieldValue(
                      `ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`,
                      value,
                    );
                  }
                }}
                onBlur={handleBlur}
                min="1"
                max="120"
              />
              <ErrorMessage
                name={`ticketSelections.${ticketIndex}.attendees.${attendeeIndex}.attributes.${index}.values`}
                message="Attendee Age is"
                ticketName={ticketName}
              />
            </Column>
          );
        }
        return (
          <div>
            <p className="is-size-4 has-text-weight-semibold">
              No Additional data required
            </p>
          </div>
        );
      })}
    </Column.Group>
  </React.Fragment>
);

export default AttendeeInputs;
