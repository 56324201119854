/* eslint-disable arrow-body-style */
import React from 'react';
import styled from 'styled-components';
import Loading from 'react-loading';
import * as yup from 'yup';
import { toast } from 'react-toastify';

const DistributeSpaceBetweenContent = styled.p`
  display: flex;
  justify-content: space-between;
`;

const LeftSpan = styled.span`
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FatButtonWithRoundBorders = styled.button`
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  border-radius: 500px;
  border: 1px solid #00aeef;
  letter-spacing: 2px;
  min-width: 160px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 17px 48px;
  color: #00aeef;
  background-color: white;
  height: 48px;
`;

const ModalTitle = styled.h2`
  color: #505050;
  font-weight: 600;
  font-size: 24px;
  padding-left: 1rem;
`;

const HalfWidth = styled.div`
  width: 50%;
  position: relative;
`;

const InputWrapper = styled.div`
  padding: 1rem;
  width: 100%;
`;

const CreateANewCategoryBtn = ({ onClick }) => (
  <FatButtonWithRoundBorders type="button" onClick={onClick}>
    Create a New Category
  </FatButtonWithRoundBorders>
);

const TableUnit = ({ category, isLast, addBtnClickHandler }) => (
  <>
    <div
      style={{
        padding: '1rem',
        fontSize: '12px',
      }}
    >
      <DistributeSpaceBetweenContent>
        <LeftSpan style={{ color: '#0D0D0D', fontWeight: '600' }}>
          {category.name}
        </LeftSpan>
        <span
          style={{ fontWeight: '600', color: '#00AEEF', cursor: 'pointer' }}
          onClick={() => addBtnClickHandler(category)}
        >
          Add
        </span>
      </DistributeSpaceBetweenContent>
    </div>
    {!isLast && (
      <div
        style={{
          width: '100%',
          height: '0',
          borderTop: '1px solid  #e2e2e2',
        }}
      />
    )}
  </>
);
const Table = ({ categories, addBtnClickHandler }) =>
  categories.map((ele, index) => (
    <TableUnit
      addBtnClickHandler={addBtnClickHandler}
      category={ele}
      key={ele}
      isLast={index + 1 === categories.length}
    />
  ));

const AddNewCategoryForm = ({ submitHandler, addingCategory }) => {
  const [categoryName, setCategoryName] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const categoryNameValidationSchema = yup.object({
    value: yup
      .string()
      .nullable()
      .test('no-only-spaces', 'Please enter a valid category name!', value =>
        /\S/.test(value),
      )
      .min(3, 'Please enter atleast 3 characters!')
      .max(35, 'Please enter less than 35 characters'),
  });

  return (
    <div style={{ width: '50%', padding: '1rem' }}>
      <h2
        style={{
          color: '#505050',
          fontWeight: 600,
          fontSize: '18px',
        }}
      >
        Create a New Category
      </h2>
      <br />
      <form id="add-new-menu-category">
        <label
          htmlFor="menu-category-name"
          style={{
            fontWeight: 600,
            fontSize: '12px',
            color: '#000000',
          }}
        >
          Menu Category Name
        </label>
        <br />
        <input
          className="input is-primary"
          type="text"
          placeholder="Cocktails"
          id="menu-category-name"
          style={{ padding: '1.2rem 1.2rem' }}
          value={categoryName}
          onChange={e => setCategoryName(e.target.value)}
        />
        <br />
        <br />
        <button
          className={`button is-primary is-fullwidth ${
            isSubmitting ? 'is-loading' : ''
          }`}
          style={{
            padding: '1.2rem 0',
            fontSize: '16px',
            fontWeight: 600,
          }}
          type="button"
          onClick={async e => {
            e.preventDefault();
            setIsSubmitting(true);
            try {
              categoryNameValidationSchema.validateSync(
                { value: categoryName },
                {
                  abortEarly: false,
                },
              );
              await submitHandler(categoryName);
              setIsSubmitting(false);
            } catch (error) {
              toast.error(error.errors[0]);
              setIsSubmitting(false);
            }
          }}
          disabled={addingCategory || isSubmitting}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const ModalContent = ({
  loading,
  categories,
  categoriesFetcher,
  addCategoryFormActive,
  setAddCategoryFormActive,
  submitHandler,
  addBtnClickHandler,
}) => {
  const [addingCategory, setAddingCategory] = React.useState(false);
  if (categories.length === 0) {
    return (
      <>
        <section style={{ padding: '1rem' }} className="modal-card-body">
          <CreateANewCategoryBtn
            onClick={() => setAddCategoryFormActive(true)}
          />
          {addCategoryFormActive && (
            <AddNewCategoryForm
              addingCategory={addingCategory}
              submitHandler={submitHandler}
            />
          )}
        </section>
      </>
    );
  }

  return (
    <section
      style={{ display: 'flex', padding: '1rem' }}
      className="modal-card-body"
    >
      <HalfWidth style={{ borderRight: '1px solid #e2e2e2' }}>
        <InputWrapper>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input is-rounded"
                type="search"
                placeholder="Search for a category"
                onChange={e => categoriesFetcher({ query: e.target.value })}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search" />
              </span>
            </p>
          </div>
        </InputWrapper>
        {loading && (
          <div
            style={{ position: 'absolute', padding: '20px 30px', left: '25%' }}
          >
            <Loading type="spin" color="#363636" />
          </div>
        )}
        <Table
          categories={categories}
          addBtnClickHandler={async category => {
            setAddingCategory(true);
            await addBtnClickHandler(category);
            setAddingCategory(false);
          }}
        />
      </HalfWidth>

      {!addCategoryFormActive && (
        <HalfWidth
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingLeft: '0.2rem' }}>
            <CreateANewCategoryBtn
              onClick={() => setAddCategoryFormActive(true)}
            />
          </div>
        </HalfWidth>
      )}
      {addCategoryFormActive && (
        <AddNewCategoryForm
          addingCategory={addingCategory}
          submitHandler={submitHandler}
        />
      )}
    </section>
  );
};

const getContainerClassNames = addCategoryModalIsActive =>
  addCategoryModalIsActive ? 'modal is-active' : 'modal';

const AddCategoryModalUI = ({
  addCategoryModalIsActive = false,
  setAddCategoryModalIsActive,
  loading,
  categories,
  categoriesFetcher,
  setAddCategoryFormActive,
  addCategoryFormActive,
  submitHandler,
  addBtnClickHandler,
}) => {
  return (
    <div className={getContainerClassNames(addCategoryModalIsActive)}>
      <div
        className="modal-background"
        style={{ backgroundColor: 'rgba(13, 13, 13, 0.28)' }}
      />
      <div className="modal-card" style={{ width: '70%', height: '80vh' }}>
        <header className="modal-card-head">
          <ModalTitle className="modal-card-title">Add a Category</ModalTitle>
          <button
            onClick={() => {
              setAddCategoryFormActive(false);
              setAddCategoryModalIsActive(false);
            }}
            className="delete"
            aria-label="close"
            type="button"
          />
        </header>
        <ModalContent
          addCategoryFormActive={addCategoryFormActive}
          setAddCategoryFormActive={setAddCategoryFormActive}
          categoriesFetcher={categoriesFetcher}
          loading={loading}
          categories={categories}
          submitHandler={submitHandler}
          addBtnClickHandler={addBtnClickHandler}
        />
      </div>
    </div>
  );
};

export default AddCategoryModalUI;
