import React from 'react';
import styled from 'styled-components';
// import { capitalize } from 'lodash';

const Container = styled.div``;

const Flex = styled.div`
  justify-content: space-between;
  padding: 0.5rem 0rem;
  padding-right: 50%;
`;

const HoursButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 60%;
    margin: 1rem 0rem;
  }
`;

const sorter = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};

const sort = hours => {
  hours.sort((a, b) => {
    const day1 = a.day;
    const day2 = b.day;
    return sorter[day1] - sorter[day2];
  });
  return hours;
};

const WeeklyHours = ({ hours: hour, onClick, name }) => {
  const hours = sort(hour);

  return (
    <Container>
      <HoursButton type="button" className="button control" onClick={onClick}>
        Add / Edit hours
      </HoursButton>
      {hours.map(item => (
        <Flex className="is-flex">
          <p className="is-size-4 has-text-weight-semibold">{item.day}</p>
          <div className="is-size-4">
            {item.option
              ? item.option.map(option => {
                  if (
                    option.start_in_sec === 0 &&
                    option.end_in_sec === 86340
                  ) {
                    return <p className="is-size-4">Open 24 Hours</p>;
                  }
                  return (
                    <p className="is-size-4 is-uppercase">
                      {option.start.length !== 0
                        ? `${option.start} - ${option.end}`
                        : 'Closed'}
                    </p>
                  );
                })
              : 'Closed'}
            {item.option.length === 0 && 'Closed'}
          </div>
        </Flex>
      ))}
    </Container>
  );
};

export default WeeklyHours;
