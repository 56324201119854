import React from 'react';
import TimePicker from 'rc-time-picker';
import styled from 'styled-components';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { range } from 'lodash';

const Wrapper = styled.div`
  &&& {
    .rc-time-picker-input {
      color: #0d0d0d;
      @media screen and (max-width: 1023px) {
        font-size: 0.7rem;
        height: 28.5px;
      }
      @media screen and (min-width: 1024px) {
        font-size: 0.7rem;
        height: 28.5px;
      }
      @media screen and (min-width: 1216px) {
        font-size: 0.7rem;
        height: 29px;
      }
      @media screen and (min-width: 1408px) {
        font-size: 0.8rem;
        height: 29px;
      }
    }
  }
`;
// function disabledHours() {
//   return [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23];
// }

// function disabledMinutes() {
//   return [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23];
// }

function disabledSeconds() {
  return range(0, 60);
}

const MyTimePicker = ({ ...props }) => (
  <Wrapper>
    <TimePicker
      id="time-picker"
      name="time-picker"
      // clearIcon
      showSecond={false}
      use12Hours
      defaultOpenValue={moment('12:00 AM')}
      defaultValue={moment('12:00 AM')}
      disabledSeconds={disabledSeconds}
      autoComplete="off"
      // disabledMinutes={disabledMinutes}
      // disabledHours={disabledHours}
      {...props}
    />
  </Wrapper>
);

export default MyTimePicker;
