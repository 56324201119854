import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../../../components/elements';
import OrderDetails from './OrderDetails';

const ActivityDetails = () => {
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a> */}
                  Order No.
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Service
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Order Date
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Delivery
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Promo
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  GST
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Sales
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Payment
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Kravein Fee
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Processing
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  {/* <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '} */}
                  Payout
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Status
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Date
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link
                onClick={() => setIsActiveOrderDetailsModal(true)}
                style={{ color: '#00aeef' }}
              >
                6452W322ER343
              </Link>
            </td>
            <td>Delivery</td>
            <td>Mar 30 9:31 AM</td>
            <td>($0.00)</td>
            <td>($0.00)</td>
            <td>($0.00)</td>
            <td>Cash</td>
            <td>$230</td>
            <td>$30.00</td>
            <td>$0.00</td>
            <td>$230.00</td>
            <td>Paid</td>
            <td>Mar 30 9:31 AM</td>
            <td />
          </tr>
        </tbody>
      </Table>
      <OrderDetails
        isActiveOrderDetailsModal={isActiveOrderDetailsModal}
        setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
      />
    </>
  );
};

export default ActivityDetails;
