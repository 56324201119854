import React from 'react';

import Layout from '../../../components/global/Layout';

import PlaceListing from './PlacesListing';

const ItemAvailability = () => (
  <Layout>
    <PlaceListing heading="Item Availability" />
  </Layout>
);

export default ItemAvailability;
