import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const placeQuery = gql`
  query fetch_place($placeId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      url {
        uri
        display_order
      }
      social {
        type
        logo
        description
        value
        display
      }
      latitude
      longitude
      service_type {
        name
        description
        display_order
      }
      default_url
      location
    }
  }
`;

export const fetchPlaceData = async ({
  // userId,
  placeId,
}) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: placeQuery,
      variables: {
        // userId,
        placeId,
      },
      fetchPolicy: 'network-only',
    });
    if (data?.data?.fetch_place) {
      return data.data.fetch_place;
    }
    return null;
  } catch {
    return null;
  }
};

const createGoogleOrderFeedMutation = gql`
  mutation createGoogleOrderFeedMutation($input: GoogleOrderFeedInput) {
    create_google_order_feed(input: $input) {
      google_order_feed_id
      error {
        description
      }
    }
  }
`;

export const createGoogleOrderFeed = async ({ input }) => {
  try {
    const {
      data: { create_google_order_feed },
    } = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createGoogleOrderFeedMutation,
      variables: { input },
    });
    return (create_google_order_feed?.error ?? [])[0]?.description;
  } catch (error) {
    return error;
  }
};

const updateGoogleOrderFeedMutation = gql`
  mutation updateGoogleOrderFeedMutation($input: GoogleOrderFeedInput) {
    update_google_order_feed(input: $input) {
      google_order_feed_id
      error {
        description
      }
    }
  }
`;

export const updateGoogleOrderFeed = async ({ input }) => {
  try {
    const {
      data: { update_google_order_feed },
    } = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateGoogleOrderFeedMutation,
      variables: { input },
    });
    return (update_google_order_feed?.error ?? [])[0]?.description;
  } catch (error) {
    return error;
  }
};

const searchGoogleOrderFeedQuery = gql`
  query searchGoogleOrderFeedQuery($input: SearchInput) {
    search_google_order_feed(input: $input) {
      google_order_feed_listing {
        service_types
        google_order_feed_id
      }
      total_pages
      total_size
    }
  }
`;

export const googleOrderFeed = async ({
  sort = 'UPDATED_ASC',
  from = 0,
  size = 10,
  placeId,
}) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchGoogleOrderFeedQuery,
      variables: {
        input: {
          sort,
          from,
          size,
          filter: {
            google_order_feed_filter: {
              place_id: [placeId],
            },
          },
        },
      },
      fetchPolicy: 'network-only',
    });
    if (
      Array.isArray(
        data?.data?.search_google_order_feed?.google_order_feed_listing,
      ) &&
      data.data.search_google_order_feed.google_order_feed_listing[0]
    ) {
      return data.data.search_google_order_feed.google_order_feed_listing[0];
    }
    return null;
  } catch {
    return null;
  }
};

export default {};
