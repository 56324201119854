import React from 'react';

import OrderDetailsForm from './OrderDetailsForm';

const OrderDetails = ({
  values,
  isActiveOrderDetailsModal,
  setIsActiveOrderDetailsModal,
}) => (
  <OrderDetailsForm
    values={values}
    isActive={isActiveOrderDetailsModal}
    onClose={() => setIsActiveOrderDetailsModal(false)}
  />
);
export default OrderDetails;
