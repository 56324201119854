import React from 'react';
import CancelForm from './CancelForm';

const Orders = ({
  isActiveCancelModal,
  setActiveCancelModal,
  handleCancelOrder,
  order,
}) => (
  <CancelForm
    isActive={isActiveCancelModal}
    handleCancelOrder={handleCancelOrder}
    order={order}
    onClose={() => setActiveCancelModal(false)}
  />
);
export default Orders;
