import React from 'react';
// import { Link } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">Place Photo Approvals</Title>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Header;
