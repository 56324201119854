import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { first } from 'lodash';

const CheckInList = ({ review, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === review.audit.created_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <tr>
      <td>
        <Link
          to={`/place-review/${review.place_id}/${review.place_review_id}?status=${review.status}`}
        >
          {review.place.name}
        </Link>
      </td>
      <td>{review.place.city}</td>
      {/* <td>{review.status}</td> */}
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip="" data-for={user.full_name}>
              {user.full_name}
            </p>
            <ReactTooltip
              id={user.full_name}
              place="top"
              type="dark"
              effect="float"
            >
              <span>
                <p>email: {user.email}</p>
                <p>objectId: {user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          review.audit.updated_by
        )}
      </td>
      <td>{moment(review.audit.updated_at).format('lll')}</td>
    </tr>
  );
};

export default CheckInList;
