import React from 'react';
import { Tab } from 'rbx';

import { Tabs } from '../../../components/elements';

const MenuCustomHoursTabs = ({ isActiveTab, setIsActiveTab }) => (
  <Tabs className="tabs  is-fullwidth">
    <Tab
      active={isActiveTab === 'customHours'}
      onClick={() => setIsActiveTab('customHours')}
    >
      MENU CUSTOM HOURS
    </Tab>
    <Tab
      active={isActiveTab === 'menuHours'}
      onClick={() => setIsActiveTab('menuHours')}
    >
      MENU HOURS
    </Tab>
  </Tabs>
);

export default MenuCustomHoursTabs;
