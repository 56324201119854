import React from 'react';
import styled from 'styled-components';
import { Title, Column } from 'rbx';
import Menus from './Menus';
import Input from '../../../components/elements/InputAddOn';
import { Select } from '../../../components/elements';

const FormWrapper = styled.div`
  width: 80vw;
  margin-bottom: 2rem;
`;

const TitleWrapper = styled.div`
  padding-right: 0.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ServiceTypeOpenClose = ({
  serviceSettingId,
  openClose,
  values,
  setFieldValue,
}) => {
  if (openClose) {
    return (
      <>
        <FormWrapper>
          <Column.Group>
            <Column size="2">
              <TitleWrapper>
                <Title className="label">Open / Close Status</Title>
              </TitleWrapper>
            </Column>
            <Column size="2">
              <Input
                disabled
                value={openClose.status ? openClose.status : 'CLOSED'}
              />
            </Column>
            <Column size="2">
              <TitleWrapper>
                <Title className="label">Change Status</Title>
              </TitleWrapper>
            </Column>
            <Column size="2">
              <Select
                name="service-type-open-close-status"
                options={[
                  { value: 'OPENED', label: 'OPENED' },
                  { value: 'CLOSED', label: 'CLOSED' },
                ]}
                onChange={value =>
                  setFieldValue('openClose', {
                    status: value.value,
                    touched: true,
                    service_type: openClose.service_type,
                  })
                }
                value={[
                  {
                    value: values.openClose.status || 'CLOSED',
                    label: values.openClose.status || 'CLOSED',
                  },
                ]}
                disableToastMessage
              />
            </Column>
          </Column.Group>
        </FormWrapper>
        <Menus serviceSettingId={serviceSettingId} forOpenClose />
      </>
    );
  }
  return (
    <span style={{ color: 'red' }}>
      Not Implemented For Custom Service Type!
    </span>
  );
};

export default ServiceTypeOpenClose;
