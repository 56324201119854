import React from 'react';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchTableBookingsQuery = gql`
  query searchTableBookingsQuery($input: SearchInput) {
    search_table_booking(input: $input) {
      table_booking_listing {
        address_line_1
        address_line_2
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        booking_date
        booking_reference
        booking_status
        booking_time
        cancelled {
          created_at
          created_by
          note {
            date
            type
            value
          }
          status
          user_data {
            email
            first_name
            last_name
          }
          user_type
        }
        city
        confirmed {
          created_at
          created_by
          status
          user_type
          note {
            date
            type
            value
          }
          user_data {
            email
            first_name
            last_name
          }
        }
        country
        error {
          code
          description
        }
        contact {
          contact_email
          contact_phone
          first_name
          last_name
        }
        guest_count
        guest_notes
        guest_status
        image_url
        latitude
        location
        longitude
        place {
          name
          place_id
          contact {
            display
            display_order
            is_primary
            type
            value
          }
          state
        }
        platform
        post_code
        received {
          created_at
          created_by
        }
        service_type
        service_type_setting_id
        state
        table_booking_id
        table_number
        tags
        user_id
        waitlisted {
          created_at
          created_by
          status
          user_type
        }
      }
      total_pages
      total_size
    }
  }
`;

const receivedCountsQuery = gql`
  query bookingCountsQuery($userId: String!) {
    search_table_booking(
      input: {
        user_id: $userId
        filter: { table_booking_filter: { booking_status: "RECEIVED" } }
      }
    ) {
      total_pages
      total_size
    }
  }
`;

const confirmedCountsQuery = gql`
  query bookingCountsQuery($userId: String!) {
    search_table_booking(
      input: {
        user_id: $userId
        filter: { table_booking_filter: { booking_status: "CONFIRMED" } }
      }
    ) {
      total_pages
      total_size
    }
  }
`;

const waitlistedCountsQuery = gql`
  query bookingCountsQuery($userId: String!) {
    search_table_booking(
      input: {
        user_id: $userId
        filter: { table_booking_filter: { booking_status: "WAITLISTED" } }
      }
    ) {
      total_pages
      total_size
    }
  }
`;

const cancelledCountsQuery = gql`
  query bookingCountsQuery($userId: String!) {
    search_table_booking(
      input: {
        user_id: $userId
        filter: { table_booking_filter: { booking_status: "CANCELED" } }
      }
    ) {
      total_pages
      total_size
    }
  }
`;

const upcomingCountsQuery = gql`
  query bookingCountsQuery($userId: String!) {
    search_table_booking(
      input: {
        user_id: $userId
        filter: { table_booking_filter: { guest_status: "UPCOMING" } }
      }
    ) {
      total_pages
      total_size
    }
  }
`;

// {user_id: "",
// size: 10,
// sort: "",
// from: 10,
// filter: {table_booking_filter:
// {booking_date: "",
// booking_status: "",
// date_range: {end_date: "", start_date: ""},
// guest_status: "",
// table_booking_id: "",
// user_id: ""},
// place_filter: {place_id: "", place_name: ""}},
// place_id: "", search_criteria: ""}

export const fetchTableBookingsData = async ({
  adminUserId,
  from,
  size = 50,
  bookingStatus = null,
  startDate = null,
  endDate = null,
  guestStatus = null,
  placeName = null,
}) => {
  const input = {
    user_id: adminUserId,
    size,
    from,
    filter: {
      table_booking_filter: {},
      place_filter: {},
    },
  };

  if (bookingStatus) {
    input.filter.table_booking_filter.booking_status = bookingStatus;
  }

  if (startDate && endDate === null) {
    input.filter.table_booking_filter.booking_date = startDate;
  }

  if (startDate && endDate) {
    input.filter.table_booking_filter.date_range = {
      end_date: endDate,
      start_date: startDate,
    };
  }

  if (guestStatus) {
    input.filter.table_booking_filter.guest_status = guestStatus;
  }

  if (placeName) {
    input.filter.place_filter.place_name = placeName;
  }

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTableBookingsQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_booking &&
      Array.isArray(data.data.search_table_booking?.table_booking_listing) &&
      data.data.search_table_booking?.table_booking_listing.length !== 0
    ) {
      return data.data.search_table_booking;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchBookingsCount = async ({ adminUserId, query }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query,
      variables: {
        userId: adminUserId,
      },
      fetchPolicy: 'network-only',
    });

    if (data && data?.data) {
      return data.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useTableBookingsData = ({
  adminUserId,
  from,
  size = 50,
  bookingStatus = null,
  startDate = null,
  endDate = null,
  guestStatus = null,
  placeName = null,
}) => {
  const [
    loadingTableBookingsData,
    setLoadingTableBookingsData,
  ] = React.useState(false);
  const [tableBookingsData, setTableBookingsData] = React.useState([]);
  const [totalSize, setTotalSize] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoadingTableBookingsData(true);
      setTableBookingsData([]);
      setTotalSize(0);
      setTotalPages(0);
      try {
        const res = await fetchTableBookingsData({
          adminUserId,
          from,
          size,
          bookingStatus,
          startDate,
          endDate,
          guestStatus,
          placeName,
        });
        if (res && res?.table_booking_listing) {
          setTableBookingsData(res.table_booking_listing);
          setTotalSize(res.total_size);
          setTotalPages(res.total_pages);
        } else {
          setTotalSize(0);
          setTotalPages(0);
        }
      } catch {
        setLoadingTableBookingsData(false);
        setTotalSize(0);
        setTotalPages(0);
      }
      setLoadingTableBookingsData(false);
    };
    fetchFunc();
  }, [
    adminUserId,
    from,
    size,
    bookingStatus,
    startDate,
    endDate,
    guestStatus,
    placeName,
  ]);
  return [
    loadingTableBookingsData,
    tableBookingsData,
    totalSize,
    totalPages,
    async () => {
      try {
        const res = await fetchTableBookingsData({
          adminUserId,
          from,
          size,
          bookingStatus,
          startDate,
          endDate,
          guestStatus,
          placeName,
        });
        return res && res?.table_booking_listing
          ? res.table_booking_listing
          : [];
      } catch {
        // pass
      }
      return [];
    },
  ];
};

export const useBookingsCount = ({ adminUserId }) => {
  const [newBookingCount, setNewBookingCount] = React.useState(0);
  const [confirmedBookingCount, setConfirmedBookingCount] = React.useState(0);
  const [waitlistedBookingCount, setWaitlistedBookingCount] = React.useState(0);
  const [cancelledBookingCount, setCancelledBookingCount] = React.useState(0);
  const [upcomingBookingCount, setUpcomingBookingCount] = React.useState(0);
  React.useEffect(() => {
    const fetchFunc = async () => {
      try {
        setNewBookingCount(0);
        setConfirmedBookingCount(0);
        setWaitlistedBookingCount(0);
        setCancelledBookingCount(0);
        setUpcomingBookingCount(0);
        const res1 = await fetchBookingsCount({
          adminUserId,
          query: receivedCountsQuery,
        });
        if (
          res1 &&
          res1?.search_table_booking &&
          res1.search_table_booking?.total_size
        ) {
          setNewBookingCount(res1.search_table_booking?.total_size);
        }
        const res2 = await fetchBookingsCount({
          adminUserId,
          query: confirmedCountsQuery,
        });
        if (
          res2 &&
          res2?.search_table_booking &&
          res2.search_table_booking?.total_size
        ) {
          setConfirmedBookingCount(res2.search_table_booking?.total_size);
        }
        const res3 = await fetchBookingsCount({
          adminUserId,
          query: waitlistedCountsQuery,
        });
        if (
          res3 &&
          res3?.search_table_booking &&
          res3.search_table_booking?.total_size
        ) {
          setWaitlistedBookingCount(res3.search_table_booking?.total_size);
        }
        const res4 = await fetchBookingsCount({
          adminUserId,
          query: cancelledCountsQuery,
        });
        if (
          res4 &&
          res4?.search_table_booking &&
          res4.search_table_booking?.total_size
        ) {
          setCancelledBookingCount(res4.search_table_booking?.total_size);
        }
        const res5 = await fetchBookingsCount({
          adminUserId,
          query: upcomingCountsQuery,
        });
        if (
          res5 &&
          res5?.search_table_booking &&
          res5.search_table_booking?.total_size
        ) {
          setUpcomingBookingCount(res5.search_table_booking?.total_size);
        }
      } catch {
        // pass
      }
    };
    fetchFunc();
  }, [adminUserId]);
  return [
    newBookingCount,
    setNewBookingCount,
    confirmedBookingCount,
    setConfirmedBookingCount,
    waitlistedBookingCount,
    setWaitlistedBookingCount,
    cancelledBookingCount,
    setCancelledBookingCount,
    upcomingBookingCount,
    setUpcomingBookingCount,
  ];
};

export default {};
