import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { first, isNull, forEach } from 'lodash';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import uuid from 'uuid';

import Items from './Items';
import SearchItemModal from './SearchItemModal';
import client from '../../../utils/apolloClient';
import { Loading } from '../../../components/elements';
import { getAllItemLink } from './helpers';

const Container = styled.div`
  width: 50%;
`;

const Header = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.lightGreyBeta};
  background-color: ${({ theme }) => theme.lightGreyAlpha};
  margin-bottom: 1rem;
`;

const createItemLinkMutation = gql`
  mutation createItemLink($input: [MenuItemLinkInput]) {
    create_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

const AddItemsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 40%;
  }
`;

const ItemsParent = ({ placeId, menuId, settingId, menuSectionId }) => {
  const { userId } = useStoreState(state => state.auth);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [items, setItems] = useState([]);
  const [itemLinkLoading, setItemLinkLoading] = useState(false);
  const [tempItems, setTempItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setItemLinkLoading(true);
      try {
        const linksItems = await getAllItemLink({
          menu_section_id: menuSectionId,
          menu_id: menuId,
          service_type_setting_id: settingId,
        });
        const finalLinkItems = linksItems.map(item => ({
          item_link_id: item.item_link_id,
          ...item.menu_item,
          display_order: item.display_order,
        }));

        setItems(finalLinkItems);
        setItemLinkLoading(false);
      } catch (error) {
        setItemLinkLoading(false);
      }
    };
    fetchData();
  }, []);

  const [createItemLink, { loading }] = useMutation(createItemLinkMutation, {
    client: client.clientPrivate,
    onCompleted: ({ create_menu_item_link }) => {
      forEach(create_menu_item_link, item => {
        if (!isNull(item.error)) {
          item.error.map(item2 => toast.error(item2.description));
        } else {
          setIsActiveModal(false);
          setItems(items.concat(tempItems));
          setTempItems([]);
        }
      });
    },
    onError: error => {
      console.log('error', error);
    },
  });

  return (
    <>
      <AddItemsButton
        type="button"
        className="button is-medium"
        onClick={() => setIsActiveModal(true)}
      >
        Add Items
      </AddItemsButton>
      {itemLinkLoading && <Loading />}
      <Items
        items={items}
        onDelete={id =>
          setItems(items.filter(item => item.item_link_id !== id))
        }
      />
      <SearchItemModal
        placeId={placeId}
        isCreateLinkLoading={loading}
        initialsItems={items}
        isActive={isActiveModal}
        onClose={() => setIsActiveModal(false)}
        onSubmit={values => {
          // eslint-disable-next-line arrow-body-style
          const inputs = values.map(item => {
            // let tempVariant = [];
            // if (Array.isArray(item.variant) && item.variant[0]) {
            //   const variant = omit(item.variant[0], '__typename');
            //   variant.variants = variant.variants.map(vrnt =>
            //     omit(vrnt, '__typename'),
            //   );
            //   tempVariant = [variant];
            // }

            return {
              user_id: userId,
              service_type_setting_id: settingId,
              menu_id: menuId,
              menu_section_id: menuSectionId,
              menu_item_id: item.menu_item_id,
              item_link_id: uuid(),
              place_id: item.place_id,
              price: null,
              // variant: tempVariant,
              // availability_status: item.availability_status,
            };
          });
          setTempItems(values);
          createItemLink({ variables: { input: inputs } });
        }}
      />
    </>
  );
};

const AssignMenu = ({ values }) => (
  <Container>
    {values.menu_id.map(menuId => {
      const menu = first(values.menus.filter(item => item.menu_id === menuId));
      if (!menu) {
        return null;
      }

      return (
        <>
          <Header>
            <p className="is-size-2 has-text-weight-semibold">
              {menu.service_type} - {menu.name}
            </p>
          </Header>
          <ItemsParent
            placeId={values.place_id}
            menuId={menuId}
            menuSectionId={values.menu_section_id}
            settingId={
              menu.service_type_setting &&
              menu.service_type_setting.service_type_setting_id
            }
          />
        </>
      );
    })}
  </Container>
);

export default AssignMenu;
