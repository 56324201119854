import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import {
  totalEstTimeTakenActiveOrder,
  totalTime,
  // prepTime,
  textHelper,
} from '../timeHelpers';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.secondaryColor};
  margin-bottom: 1rem;
`;

const Spacing = styled.div`
  padding: 0.05rem 0rem;
`;

const Timing = ({ order, receivedTime }) => {
  const due = totalEstTimeTakenActiveOrder(order.estimates, receivedTime);
  const delayInMin = totalTime(
    order.estimates.filter(
      estimate_item => estimate_item.type === 'delayed_estimate',
    ),
  );

  return (
    <Container>
      <div>
        <Spacing>
          <p className="is-size-4">
            Received at {moment(receivedTime).format('LT')}{' '}
            {moment(receivedTime).format('DD/MM/YY')}
            {moment().format('DD/MM') ===
              moment(order.audit.created_at).format('DD/MM') && ` ( Today ) `}
          </p>
          {order.status === 'RECEIVED' && !order?.is_scheduled && (
            <p className="is-size-4" style={{ color: '#ee2a7b' }}>
              {due && `Order is ASAP (${due})`}
            </p>
          )}
        </Spacing>

        {order.estimated_completion_time && (
          <Spacing>
            <p className="is-size-4" style={{ color: '#ee2a7b' }}>
              Order is {order.status === 'RECEIVED' ? 'scheduled' : 'scheduled'}{' '}
              for{' '}
              {delayInMin > 0
                ? moment(order.estimated_completion_time)
                    .add(delayInMin, 'minutes')
                    .format('llll')
                : moment(order.estimated_completion_time).format('llll')}
              {order.is_scheduled && <span> (S)</span>}
            </p>
          </Spacing>
        )}
        <Spacing>
          {order.status !== 'COMPLETE' && delayInMin > 0 && (
            <p className="is-size-4" style={{ color: '#ee2a7b' }}>
              Your order is delayed by {textHelper(Math.abs(delayInMin))}
            </p>
          )}
        </Spacing>
        {order.status === 'COMPLETE' && (
          <Spacing>
            <p className="is-size-4">
              Completed at {moment(order?.complete?.created_at).format('LT')}{' '}
              {moment(order?.complete?.created_at).format('DD/MM/YY')}
              {moment().format('DD/MM') ===
                moment(order.audit.created_at).format('DD/MM') && ` ( Today ) `}
            </p>
          </Spacing>
        )}
      </div>
    </Container>
  );
};

export default Timing;
