import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';

const deletePlaceMutation = gql`
  mutation delete_feed($input: NavigateFeedInput) {
    delete_feed(input: $input) {
      feed_id
    }
  }
`;

const DeletePlaceMutation = ({ feedId, refetch }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Mutation
      mutation={deletePlaceMutation}
      client={client.clientPrivate}
      variables={{
        input: {
          user_id: userId,
          feed_id: feedId,
        },
      }}
      onCompleted={() => {
        swal('Poof! The place post has been deleted!', {
          icon: 'success',
        }).then(() => {
          refetch();
        });
      }}
    >
      {delete_place => (
        <a
          onClick={() => {
            swal('Are you sure you want to do this?', {
              buttons: ['Cancel', true],
            }).then(willDelete => {
              if (willDelete) {
                delete_place();
              }
            });
          }}
        >
          <span>
            <i className="fas fa-trash has-text-grey" />
          </span>
        </a>
      )}
    </Mutation>
  );
};

export default DeletePlaceMutation;
