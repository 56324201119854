/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Column } from 'rbx';
import * as yup from 'yup';
import swal from 'sweetalert';
import moment from 'moment';

import { FaAngleLeft } from 'react-icons/fa';
import { Radio, TextArea } from '../../../components/elements';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: center;
      background: white;
      border-top: 0px;
      .button {
        font-weight: 600;
      }
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const Wrapper = styled.div`
  padding-top: 0.8rem;
`;

const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

const Form = ({
  isActive,
  onClose,
  handleSubmit,
  resetForm,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
  touched,
}) => (
  <Container className={`modal ${isActive && 'is-active'}`}>
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <p
          className="modal-card-title is-flex"
          style={{ justifyContent: 'center' }}
        >
          Cancel Order
        </p>

        <CloseButtonWrapper>
          <FaAngleLeft
            size={30}
            type="button"
            onClick={() => {
              onClose();
              resetForm();
            }}
          />
        </CloseButtonWrapper>
      </header>

      <section className="modal-card-body">
        <DetailsFormWrapper>
          <p className="is-size-4" style={{ paddingTop: '10px' }}>
            We strongly recommend to avoid cancelling an order as it can cause a
            negative customer experience and affect your place rating. Please
            reach out to the customer to work out a solution and only cancel on
            unique situations.
          </p>
          <br />
          <>
            <div className="field">
              <div className="control">
                <RadioWrapper>
                  <Radio
                    label="Out of an item (or items)"
                    value={values.type === 'Out of an item (or items)'}
                    onChange={() =>
                      setFieldValue('type', 'Out of an item (or items)')
                    }
                  />
                </RadioWrapper>
                <div className="is-flex">
                  <RadioWrapper>
                    <Wrapper>
                      <Radio
                        label="Cant fullfill instructions"
                        value={values.type === 'Cant fullfil instructions'}
                        onChange={() =>
                          setFieldValue('type', 'Cant fullfil instructions')
                        }
                      />
                    </Wrapper>
                  </RadioWrapper>
                </div>
                <div className="is-flex">
                  <RadioWrapper>
                    <Wrapper>
                      <Radio
                        label="Problem in restaurant"
                        value={values.type === 'Problem in restaurant'}
                        onChange={() =>
                          setFieldValue('type', 'Problem in restaurant')
                        }
                      />
                    </Wrapper>
                  </RadioWrapper>
                </div>
                <div className="is-flex">
                  <RadioWrapper>
                    <Wrapper>
                      <Radio
                        label="Other"
                        value={values.type === 'Other'}
                        onChange={() => setFieldValue('type', 'Other')}
                      />
                    </Wrapper>
                  </RadioWrapper>
                </div>
              </div>
            </div>
          </>
          <br />

          <Column.Group>
            <div className="column">
              <TextArea
                name="value"
                label="Note to the customer"
                value={values.value}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                errors={errors.value}
                touched={touched.value}
                disableToastMessage
                placeholder="Add a note and mention anything specific such as no nuts or cross contamination, less spicy"
              />
              Characters left ({values.value.length}/144)
            </div>
          </Column.Group>
        </DetailsFormWrapper>
      </section>
      <footer className="modal-card-foot">
        <button
          className="button is-large is-fullwidth is-primary"
          onClick={handleSubmit}
        >
          CANCEL ORDER
        </button>
      </footer>
    </div>
  </Container>
);

const CancelForm = withFormik({
  mapPropsToValues: () => ({
    type: 'Out of an item (or items)',
    value: '',
  }),

  validationSchema: yup.object().shape({
    value: yup
      .string()
      .required('Description is required!')
      .min(5, 'Please enter at least 5 characters')
      .max(144, 'Description cannot be greater than 144 characters'),
  }),

  handleSubmit: (values, { props }) => {
    swal({
      title: 'Are you sure?',
      // eslint-disable-next-line max-len
      text: `Are you sure you absolutely cannot prepare the order. If you still would like to cancel this order, please confirm below or cancel to go back`,
      buttons: ['Cancel', 'Confirm'],
    }).then(isConfirm => {
      if (isConfirm) {
        const note = {
          type: values.type,
          value: values.value,
          date: moment().toISOString(),
        };
        if (props.order.status === 'RECEIVED') {
          props.handleCancelOrder(
            props.order.menu_order_id,
            'cancelreceived',
            note,
          );
          props.onClose();
        }
        if (props.order.status === 'CONFIRMED') {
          props.handleCancelOrder(
            props.order.menu_order_id,
            'cancelconfirmed',
            note,
          );
          props.onClose();
        }
        if (props.order.status === 'READY') {
          props.handleCancelOrder(
            props.order.menu_order_id,
            'cancelready',
            note,
          );
          props.onClose();
        }
      }
    });
  },

  displayName: 'CancelForm',
})(Form);

export default CancelForm;
