import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import { PageHeader } from '../../../components/elements';

const Header = ({ loading, handleSubmit, history, dirty }) => (
  <PageHeader title="Add Item Dictionary">
    <Level.Item align="right">
      <Level.Item>
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>

      <Level.Item>
        <button
          className={`button is-primary ${loading && 'is-loading'}`}
          onClick={handleSubmit}
          disabled={!dirty}
        >
          <span>Submit</span>
        </button>
      </Level.Item>
    </Level.Item>
  </PageHeader>
);

export default withRouter(Header);
