import React from 'react';
import { Level } from 'rbx';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 1rem;
  padding-bottom: 1rem;
  border-top: 2px solid #e0e0e0;
`;

const Footer = ({ history }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Back</span>
          </a>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
