import React from 'react';
import styled from 'styled-components';
import { Column, Button, Icon } from 'rbx';
import { FieldArray, Field, getIn } from 'formik';
import { isNaN, isNull, lastIndexOf } from 'lodash';
import swal from 'sweetalert';
import uuid from 'uuid';

import {
  BoxWithHeader,
  Input,
  Radio,
  InputAddOn,
  Select,
} from '../../../components/elements';

const Container = styled.div`
  margin-bottom: 1rem;
`;
const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

const VariantContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.lightGreyAlpha};
  padding: 0.5rem 0rem;
`;
const AddButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
  }
`;

const RemoveButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.secondaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.secondaryColor};
    font-weight: 600;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 35px;
`;

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <div className="has-text-danger	is-size-5">{error}</div>
      ) : null;
    }}
  />
);

const Variants = ({
  values,
  isLinkExist,
  helpers,
  variant,
  index,
  parentIndex,
  onDeleteChildVariant,
  isActiveChildAdd,
  isHideDeleteButtonForFirstElement,
  parentVariant,
}) => (
  <Column.Group vcentered>
    <Column size="4">
      {console.log('values', values)}
      <Input
        label="Variants Name"
        optionalText="(Max 35 Character)"
        name={`variant.${parentIndex}.variants.${index}.variant_name`}
        value={variant.variant_name}
        onChange={e => {
          if (
            e.target.value === '' ||
            (e.target.value && e.target.value.length <= 35)
          ) {
            helpers.form.setFieldValue(
              `variant.${parentIndex}.variants.${index}.variant_name`,
              e.target.value,
            );

            // Update the name in links
            helpers.form.setFieldValue(
              'updateLinks',
              values.updateLinks.map(link => ({
                ...link,
                variant: link.variant
                  ? link.variant.map(eachvariant => ({
                      ...eachvariant,
                      variants: eachvariant.variants.map(vari => ({
                        ...vari,
                        variant_name:
                          vari.variant_id == variant.variant_id
                            ? e.target.value
                            : vari.variant_name,
                      })),
                    }))
                  : [],
              })),
            );
            helpers.form.setFieldValue(
              'createLinks',
              values.createLinks.map(link => ({
                ...link,
                variant: link.variant
                  ? link.variant.map(eachvariant => ({
                      ...eachvariant,
                      variants: eachvariant.variants.map(vari => ({
                        ...vari,
                        variant_name:
                          vari.variant_id == variant.variant_id
                            ? e.target.value
                            : vari.variant_name,
                      })),
                    }))
                  : [],
              })),
            );
          }
        }}
        onBlur={helpers.form.handleBlur}
      />
      <ErrorMessage
        name={`variant.${parentIndex}.variants.${index}.variant_name`}
      />
    </Column>
    <Column size="2">
      <InputAddOn
        label="Price($)"
        type="number"
        isLeft
        addonsText="$"
        min="0"
        step="0.01"
        value={variant.variant_price}
        onChange={e => {
          if (e.target.value === '') {
            helpers.form.setFieldValue(
              `variant.${parentIndex}.variants.${index}.variant_price`,
              null,
            );
          } else if (!isNaN(parseFloat(e.target.value))) {
            helpers.form.setFieldValue(
              `variant.${parentIndex}.variants.${index}.variant_price`,
              parseFloat(e.target.value),
            );
          }
        }}
        onBlur={e => {
          if (e.target.value === '') {
            helpers.form.setFieldValue(
              `variant.${parentIndex}.variants.${index}.variant_price`,
              null,
            );
          } else if (!isNaN(parseFloat(e.target.value))) {
            helpers.form.setFieldValue(
              `variant.${parentIndex}.variants.${index}.variant_price`,
              parseFloat(e.target.value).toFixed(2),
            );
          }
        }}
        placeholder="0.00"
      />
      <ErrorMessage
        name={`variant.${parentIndex}.variants.${index}.variant_price`}
      />
    </Column>
    <Column size="3">
      <CheckBoxWrapper>
        <Radio
          label="Available"
          value={variant.availability_status === 'AVAILABLE'}
          onChange={() => {
            helpers.form.setFieldValue(
              `variant.${parentIndex}.variants.${index}.availability_status`,
              'AVAILABLE',
            );
          }}
          disabled={values.availability_status === 'UNAVAILABLE'}
        />
        <Radio
          label="Unavailable indefinitely"
          value={variant.availability_status === 'UNAVAILABLE'}
          onChange={() => {
            helpers.form.setFieldValue(
              `variant.${parentIndex}.variants.${index}.availability_status`,
              'UNAVAILABLE',
            );
          }}
          disabled={values.availability_status === 'UNAVAILABLE'}
        />
      </CheckBoxWrapper>
    </Column>
    <Column size="3">
      <ButtonWrapper>
        <Button.Group hasAddons>
          {!isHideDeleteButtonForFirstElement && (
            <Button
              type="button"
              onClick={onDeleteChildVariant}
              disabled={isLinkExist}
            >
              <Icon size="small">
                <i className="fas fa-trash has-text-grey" />
              </Icon>
            </Button>
          )}
          {isActiveChildAdd && (
            <Button
              type="button"
              onClick={() => {
                const variant_id = uuid();
                helpers.form.setFieldValue(
                  'updateLinks',
                  helpers.form.values.updateLinks.map(link => ({
                    ...link,
                    variant: link.variant
                      ? link.variant.map(eachvariant => ({
                          ...eachvariant,
                          variants:
                            eachvariant.prompt_id == parentVariant.prompt_id
                              ? eachvariant.variants.concat({
                                  variant_id,
                                  variant_name: '',
                                  variant_price: null,
                                  availability_status: 'AVAILABLE',
                                })
                              : eachvariant.variants,
                        }))
                      : [],
                  })),
                );
                helpers.form.setFieldValue(
                  'createLinks',
                  helpers.form.values.createLinks.map(link => ({
                    ...link,
                    variant: link.variant
                      ? link.variant.map(eachvariant => ({
                          ...eachvariant,
                          variants:
                            eachvariant.prompt_id == parentVariant.prompt_id
                              ? eachvariant.variants.concat({
                                  variant_id,
                                  variant_name: '',
                                  variant_price: null,
                                  availability_status: 'AVAILABLE',
                                })
                              : eachvariant.variants,
                        }))
                      : [],
                  })),
                );
                helpers.push({
                  variant_id,
                  variant_name: '',
                  variant_price: null,
                  availability_status: 'AVAILABLE',
                });
              }}
              disabled={isLinkExist}
            >
              <Icon size="small">
                <i className="fas fa-plus has-text-grey" />
              </Icon>
            </Button>
          )}
        </Button.Group>
      </ButtonWrapper>
    </Column>
  </Column.Group>
);

const ParentVariant = ({
  parentHelpers,
  parentVariant,
  parentIndex,
  onDeleteParentVariant,
  isLinkExist,
  // isActiveParentAdd,
}) => (
  <FieldArray name={`variant.${parentIndex}.variants`}>
    {helpers => (
      <BoxWithHeader title="Variant">
        <Column.Group vcentered>
          <Column size="6">
            <Input
              label="Variants Prompt"
              optionalText="(Max 50 Characters e.g what size of pizza would you like?)"
              name={`variant.${parentIndex}.prompt`}
              value={parentVariant.prompt}
              onChange={e => {
                if (
                  e.target.value === '' ||
                  (e.target.value && e.target.value.length <= 50)
                ) {
                  helpers.form.setFieldValue(
                    `variant.${parentIndex}.prompt`,
                    e.target.value,
                  );

                  // Update Prompt Values in Link As Well
                  helpers.form.setFieldValue(
                    'updateLinks',
                    helpers.form.values.updateLinks.map(link => ({
                      ...link,
                      variant: link.variant.map(variant => {
                        if (
                          variant.prompt_id ==
                          helpers.form.values.variant[parentIndex].prompt_id
                        ) {
                          return {
                            ...variant,
                            prompt: e.target.value,
                          };
                        }
                        return variant;
                      }),
                    })),
                  );

                  helpers.form.setFieldValue(
                    'createLinks',
                    helpers.form.values.createLinks.map(link => ({
                      ...link,
                      variant: link.variant.map(variant => {
                        if (
                          variant.prompt_id ==
                          helpers.form.values.variant[parentIndex].prompt_id
                        ) {
                          return {
                            ...variant,
                            prompt: e.target.value,
                          };
                        }
                        return variant;
                      }),
                    })),
                  );
                }
              }}
              onBlur={parentHelpers.form.handleBlur}
              required
              disabled={isLinkExist}
            />
            <ErrorMessage name={`variant.${parentIndex}.prompt`} />
          </Column>
          <Column>
            <ButtonWrapper>
              <RemoveButton
                type="button"
                className="button"
                onClick={onDeleteParentVariant}
                disabled={isLinkExist}
              >
                Remove Price Variants ( Size, Colour etc )
              </RemoveButton>
            </ButtonWrapper>
          </Column>
          <Column>
            <ButtonWrapper>
              {/* {isActiveParentAdd && (
                <AddButton
                  type="button"
                  className="button"
                  onClick={() => {
                    const input = {
                      id: uuid(),
                      prompt: '',
                      variants: [
                        {
                          id: uuid(),
                          variant_name: '',
                          variant_price: null,
                          availability_status: '',
                        },
                      ],
                    };
                    parentHelpers.push(input);
                  }}
                >
                  Add Another Price Variants
                </AddButton>
              )} */}
            </ButtonWrapper>
          </Column>
        </Column.Group>
        <VariantContainer>
          {parentVariant &&
            parentVariant.variants &&
            parentVariant.variants.length > 0 &&
            parentVariant.variants.map((variant, index) => (
              <Variants
                values={helpers.form.values}
                isLinkExist={isLinkExist}
                parentVariant={parentVariant}
                helpers={helpers}
                variant={variant}
                index={index}
                parentIndex={parentIndex}
                isActiveChildAdd={
                  lastIndexOf(parentVariant.variants) === index + 1
                }
                isHideDeleteButtonForFirstElement={
                  lastIndexOf(parentVariant.variants) === 1
                }
                onDeleteChildVariant={() => {
                  const variants = parentVariant.variants.filter(
                    item => item.variant_id !== variant.variant_id,
                  );
                  parentHelpers.form.setFieldValue(
                    `variant.${parentIndex}.variants`,
                    variants,
                  );
                  // Remove Each Variant from the link ....
                  parentHelpers.form.setFieldValue(
                    `updateLinks`,
                    parentHelpers.form.values.updateLinks.map(link => ({
                      ...link,
                      variant: link.variant
                        ? link.variant.map(eachvariant => ({
                            ...eachvariant,
                            variants: eachvariant.variants.filter(
                              ({ variant_id }) =>
                                variant_id != variant.variant_id,
                            ),
                          }))
                        : [],
                    })),
                  );
                  parentHelpers.form.setFieldValue(
                    `createLinks`,
                    parentHelpers.form.values.createLinks.map(link => ({
                      ...link,
                      variant: link.variant
                        ? link.variant.map(eachvariant => ({
                            ...eachvariant,
                            variants: eachvariant.variants.filter(
                              ({ variant_id }) =>
                                variant_id != variant.variant_id,
                            ),
                          }))
                        : [],
                    })),
                  );
                }}
              />
            ))}
        </VariantContainer>
      </BoxWithHeader>
    )}
  </FieldArray>
);

const PriceVariants = props => (
  <FieldArray name="variant">
    {helpers => {
      const { values, setFieldValue, touched, errors } = helpers.form;
      return (
        <Container>
          <Column.Group vcentered>
            <Column size="3">
              {console.log(values)}
              <InputAddOn
                label="Item Price"
                isLeft
                addonsText="$"
                type="number"
                min="0"
                step="0.01"
                value={values.price === null ? '' : values.price}
                onChange={e => {
                  if (e.target.value === '') {
                    setFieldValue('price', null);
                  } else if (!isNaN(parseFloat(e.target.value))) {
                    setFieldValue('price', parseFloat(e.target.value));
                  }
                }}
                onBlur={e => {
                  if (e.target.value === '') {
                    setFieldValue('price', 'null');
                    return;
                  } else if (!isNaN(parseFloat(e.target.value))) {
                    setFieldValue(
                      'price',
                      parseFloat(e.target.value).toFixed(2),
                    );
                    let someOverrideInCreate = values.createLinks.some(
                      link => !isNull(link.price),
                    );
                    let someOverrideInUpdate = values.updateLinks.some(
                      link => !isNull(link.price),
                    );
                    if (
                      parseFloat(e.target.value).toFixed(2) !=
                        values.dummy_price &&
                      (someOverrideInCreate || someOverrideInUpdate)
                    ) {
                      swal('', 'This item has price overrides!', 'info');
                      setFieldValue(
                        'dummy_price',
                        parseFloat(e.target.value).toFixed(2),
                      );
                    }
                  }
                }}
                placeholder="0.00"
                errors={errors.price}
                touched={touched.price}
                disabled={values.variant.length !== 0}
                disableToastMessage
                required
              />
            </Column>
            <Column size="3">
              <Select
                label="Item Tax"
                value={{ label: values.tax, value: values.tax }}
                options={[
                  { label: 'GST', value: 'GST' },
                  { label: 'WET', value: 'WET' },
                  { label: 'NA', value: 'NA' },
                ]}
                onChange={value => props.setFieldValue('tax', value.value)}
                errors={props.errors.tax}
                touched={props.touched.tax}
                disableToastMessage
                required
              />
            </Column>
            <Column>
              <ButtonWrapper>
                {values.variant && values.variant.length === 0 && (
                  <AddButton
                    type="button"
                    className="button"
                    onClick={() => {
                      helpers.form.setFieldValue('price', null);
                      const input = {
                        id: uuid(),
                        prompt_id: uuid(),
                        prompt: '',
                        variants: [
                          {
                            variant_id: uuid(),
                            variant_name: '',
                            variant_price: null,
                            availability_status: 'AVAILABLE',
                          },
                        ],
                      };
                      helpers.push(input);
                      helpers.form.setFieldValue(
                        'updateLinks',
                        helpers.form.values.updateLinks.map(link => ({
                          ...link,
                          price: null,
                          variant: [
                            {
                              prompt_id: input.prompt_id,
                              prompt: '',
                              variants: input.variants,
                            },
                          ],
                        })),
                      );
                      helpers.form.setFieldValue(
                        'createLinks',
                        helpers.form.values.createLinks.map(link => ({
                          ...link,
                          price: null,
                          variant: [
                            {
                              prompt_id: input.prompt_id,
                              prompt: '',
                              variants: input.variants,
                            },
                          ],
                        })),
                      );
                    }}
                    disabled={props.isLinkExist}
                  >
                    Add Price Variants ( Size, Colour etc )
                  </AddButton>
                )}
              </ButtonWrapper>
            </Column>
          </Column.Group>
          {values.variant &&
            values.variant.length > 0 &&
            values.variant.map((variant, index) => (
              <ParentVariant
                isLinkExist={props.isLinkExist}
                parentHelpers={helpers}
                parentVariant={variant}
                parentIndex={index}
                isActiveParentAdd={lastIndexOf(values.variant) === index + 1}
                onDeleteParentVariant={() => {
                  props.setValues({
                    ...props.values,
                    variant: values.variant.filter(
                      item => item.id !== variant.id,
                    ),
                    updateLinks: values.updateLinks.map(link => ({
                      ...link,
                      variant: [],
                    })),
                    createLinks: values.createLinks.map(link => ({
                      ...link,
                      variant: [],
                    })),
                  });
                }}
              />
            ))}
        </Container>
      );
    }}
  </FieldArray>
);

export default PriceVariants;
