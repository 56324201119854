import React from 'react';
import { Tab, Level } from 'rbx';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';

import Layout from '../../components/global/Layout';
import { Tabs, Heading } from '../../components/elements';
import {
  fetchAggregateMenuOrderDrilldown,
  fetchAggregateMenuOrderRollup,
  transformDrilldownData,
  transformRollupData,
} from './helpers';
import RollupTable from './RollupTable';
import DrilldownTable from './DrilldownTable';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Dashboard</Heading>
      </Level.Item>
    </Level>
  </Wrapper>
);

const Home = () => {
  const { userId } = useStoreState(state => state.auth);
  const [loading, setLoading] = React.useState(true);
  const [rollupTableData, setRollupTableData] = React.useState(null);
  const [drilldownTableData, setDrilldownTableData] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState('TODAY');

  React.useEffect(() => {
    const fetchPageData = async () => {
      setLoading(true);
      const [
        aggregateMenuOrderRollupData,
        aggregateMenuOrderDrilldownData,
      ] = await Promise.all([
        fetchAggregateMenuOrderRollup({
          userId,
          rangeType: activeTab,
        }),
        fetchAggregateMenuOrderDrilldown({ userId, rangeType: activeTab }),
      ]);
      console.log('aggregate data...');
      console.log('aggregateMenuOrderRollupData', aggregateMenuOrderRollupData);
      console.log(
        'aggregateMenuOrderDrilldownData',
        aggregateMenuOrderDrilldownData,
      );

      if (aggregateMenuOrderRollupData) {
        try {
          const transformData1 = transformRollupData(
            aggregateMenuOrderRollupData,
          );
          setRollupTableData(transformData1);
        } catch {
          setLoading(false);
        }
      } else {
        setRollupTableData(null);
      }

      if (aggregateMenuOrderDrilldownData) {
        try {
          const transformData2 = transformDrilldownData(
            aggregateMenuOrderDrilldownData,
          );
          setDrilldownTableData(transformData2);
        } catch {
          setLoading(false);
        }
      } else {
        setDrilldownTableData(null);
      }

      setLoading(false);
    };
    fetchPageData();
  }, [activeTab]);

  return (
    <Layout>
      <Header />
      <RollupTable loading={loading} rollupTableData={rollupTableData} />
      <br />
      <Tabs className="tabs is-medium is-fullwidth">
        <Tab
          active={activeTab === 'TODAY'}
          onClick={() => setActiveTab('TODAY')}
        >
          TODAY
        </Tab>
        <Tab
          active={activeTab === 'YESTERDAY'}
          onClick={() => setActiveTab('YESTERDAY')}
        >
          YESTERDAY
        </Tab>
        <Tab active={activeTab === 'WEEK'} onClick={() => setActiveTab('WEEK')}>
          THIS WEEK
        </Tab>
        <Tab
          active={activeTab === 'MONTH'}
          onClick={() => setActiveTab('MONTH')}
        >
          THIS MONTH
        </Tab>
        <Tab active={activeTab === 'YEAR'} onClick={() => {}}>
          THIS YEAR
        </Tab>
      </Tabs>

      <DrilldownTable
        loading={loading}
        drilldownTableData={drilldownTableData}
      />
    </Layout>
  );
};

export default Home;
