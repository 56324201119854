import React, { useState, useEffect } from 'react';
import { Column } from 'rbx';
import gql from 'graphql-tag';
import moment from 'moment';
import { isUndefined } from 'lodash';
import { Input, MobileInput } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      rating
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;
const ClaimedUserLoginDetails = ({ createdBy, createdAt }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    client.clientPrivate
      .query({
        query: userQuery,
        variables: { userId: createdBy },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setUser(data.fetch_user);
        }
      });
  }, []);
  return (
    <fieldset className="box">
      <legend className="label">Claimed User Login Details</legend>
      {isUndefined(user) ? (
        <Column.Group multiline vcentered gapSize={8}>
          <Column size="half">
            <Input label="Claimed by FullName" disabled />
          </Column>
          <Column size="half">
            <Input label="Claimed by Email" disabled />
          </Column>
          <Column size="half">
            <MobileInput label="Claimed by Phone" disabled />
          </Column>
          <Column size="half">
            <Input label="Claim Date & Time" disabled />
          </Column>
        </Column.Group>
      ) : (
        <Column.Group multiline vcentered gapSize={8}>
          <Column size="half">
            <Input
              label="Claimed by FullName"
              value={`${user && user.first_name} ${user && user.last_name}`}
              disabled
            />
          </Column>
          <Column size="half">
            <Input
              label="Claimed by Email"
              value={user && user.email}
              disabled
            />
          </Column>
          <Column size="half">
            <MobileInput label="Claimed by Phone" disabled />
          </Column>
          <Column size="half">
            <Input
              label="Claim Date & Time"
              value={moment(createdAt).format('lll')}
              disabled
            />
          </Column>
        </Column.Group>
      )}
    </fieldset>
  );
};

export default ClaimedUserLoginDetails;
