import { useStoreActions } from 'easy-peasy';
import React from 'react';

const PlaceList = ({ place, handleSelect }) => {
  const { setDefaultPlaceId } = useStoreActions(state => state.menu);
  return (
    <React.Fragment>
      <td>
        <a
          onClick={() => {
            setDefaultPlaceId(place.place_id);
            handleSelect(place);
          }}
        >
          {place.name}
        </a>
      </td>
      <td>{place.city}</td>
      <td>
        {place.state}, {place.post_code}
      </td>
      <td>{`${place.status}`}</td>
    </React.Fragment>
  );
};

export default PlaceList;
