import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';
import { useStoreActions } from 'easy-peasy';

import Delete from './Delete';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const CheckInList = ({ group }) => {
  const { setDefaultPlaceId } = useStoreActions(state => state.menu);
  return (
    <React.Fragment>
      <td>
        <Link
          onClick={() => setDefaultPlaceId(group.place.place_id)}
          to={`/update-modifiers-group/${group.modifier_group_id}`}
        >
          {group.name}
        </Link>
      </td>
      <td>{group.internal_name}</td>
      <td>{group.place.name}</td>
      <td>{group.place.city}</td>
      <td>{group.platform}</td>

      <td>
        <ReactTooltip html place="top" type="dark" effect="float" />
        <p
          data-tip={`<span>
          <p>email: ${group.updated_by_user.email}</p>
          <p>objectId: ${group.updated_by_user.user_id}</p>
        </span>`}
          data-html
        >
          {group.updated_by_user.display_name}
        </p>
      </td>
      <td> {moment(group.audit.updated_at).format('lll')} </td>
      <td>-</td>
      <td>-</td>
      <td>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action="delete_menu_modifier_group"
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => <Delete groupId={group.modifier_group_id} />}
            />
          )}
        </ACLSConsumer>
      </td>
    </React.Fragment>
  );
};

export default CheckInList;
