import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import { Table } from 'rbx';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import client from '../../../utils/apolloClient';

import {
  Table as CustomTable,
  // CustomInput,
  Loading,
  Message,
  Pagination,
} from '../../../components/elements';
import ReviewCommentList from './ReviewCommentList';

const searchComment = gql`
  query searchComments($input: SearchInput) {
    search_comments(input: $input) {
      total_size
      total_pages
      comment_listing {
        content
        object_id
        parent_id
        parent_type
        status
        audit {
          updated_by
          updated_at
          created_at
          created_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const ReviewLikes = ({ reivew }) => {
  const objectId = reivew.itemId;
  const commentFilter = {
    object_id: objectId,
    object_type: 'ITEM_REVIEW',
    parent_id: reivew.item_id,
  };

  const [reviewData, setReviewData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const [selectedComment, setSelectedComment] = useState();

  const input = {};
  Object.assign(input, { from: pageFrom, size: listSize });
  return (
    <React.Fragment>
      <br />
      {totalElements > 0 && (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          currentPage={currentPage}
          totalElements={totalElements}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}

      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Login
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Updated At
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>Comment Content</Table.Heading>
            <Table.Heading>Status</Table.Heading>
            <Table.Heading>Action</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Query
          query={searchComment}
          variables={{ input: { filter: { comment_filter: commentFilter } } }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>{error.message}</Message>;
            }
            const { search_comments } = data;

            return (
              <React.Fragment>
                <View
                  reviewData={reviewData}
                  setReviewData={setReviewData}
                  search_comments={search_comments}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </CustomTable>
    </React.Fragment>
  );
};

export default ReviewLikes;

const View = ({
  search_comments,
  setPageCount,
  setTotalElements,
  setReviewData,
  reviewData,
  goToStepTwo,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_comments.total_pages);
    setTotalElements(search_comments.total_size);
    if (search_comments.comment_listing) {
      setReviewData(search_comments.comment_listing);

      const userFilter = search_comments.comment_listing.map(
        item => item.audit.updated_by,
      );

      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_comments.total_pages]);
  return (
    <Table.Body>
      {reviewData &&
        reviewData.map(comment => (
          <ReviewCommentList
            comment={comment}
            users={users}
            goToStepTwo={goToStepTwo}
          />
        ))}
    </Table.Body>
  );
};
