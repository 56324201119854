import { first, differenceBy, isNull } from 'lodash';

export const eventListingVisibilityParserFromApi = parseValue => {
  let final = {};
  if (parseValue === 'private_listing') {
    final = { listing: 'event-listing', visibility: 'private' };
  }
  if (parseValue === 'private_tickets') {
    final = { listing: 'event with tickets', visibility: 'private' };
  }
  if (parseValue === 'private_registration') {
    final = { listing: 'event-registration', visibility: 'private' };
  }
  if (parseValue === 'public_listing') {
    final = { listing: 'event-listing', visibility: 'public' };
  }
  if (parseValue === 'public_tickets') {
    final = { listing: 'event with tickets', visibility: 'public' };
  }
  if (parseValue === 'public_registration') {
    final = { listing: 'event-registration', visibility: 'public' };
  }
  return final;
};

export const eventListingVisibilityParserFromInput = (
  listingValue,
  visibilityValue,
) => {
  let listing_type = '';
  if (listingValue === 'event-listing' && visibilityValue === 'private') {
    listing_type = 'private_listing';
  }
  if (listingValue === 'event with tickets' && visibilityValue === 'private') {
    listing_type = 'private_tickets';
  }
  if (listingValue === 'event-registration' && visibilityValue === 'private') {
    listing_type = 'private_registration';
  }
  if (listingValue === 'event-listing' && visibilityValue === 'public') {
    listing_type = 'public_listing';
  }
  if (listingValue === 'event with tickets' && visibilityValue === 'public') {
    listing_type = 'public_tickets';
  }
  if (listingValue === 'event-registration' && visibilityValue === 'public') {
    listing_type = 'public_registration';
  }
  return listing_type;
};

export const placeEventDefaultFeeParserForAbsorbAndPassOn = fees => {
  const absorbOnline = fees.filter(item => item.name === 'online_absorb_fee');

  const absorbOffline = fees.filter(item => item.name === 'offline_absorb_fee');

  const passOnOnline = fees.filter(item => item.name === 'online_passon_fee');

  const passOnOffline = fees.filter(item => item.name === 'offline_passon_fee');

  return {
    absorbOnline: absorbOnline.length !== 0 ? first(absorbOnline) : null,
    absorbOffline: absorbOffline.length !== 0 ? first(absorbOffline) : null,
    passOnOnline: passOnOnline.length !== 0 ? first(passOnOnline) : null,
    passOnOffline: passOnOffline.length !== 0 ? first(passOnOffline) : null,
  };
};

const attendeesDefault = [
  {
    name: 'name',
    label: 'Name',
    required: false,
    values: '',
    validation: '',
    isBelong: false,
  },
  {
    name: 'email',
    label: 'Email',
    required: false,
    values: '',
    validation: '',
    isBelong: false,
  },
  {
    name: 'age',
    label: 'Age',
    required: false,
    values: '',
    validation: '',
    isBelong: false,
  },
  {
    name: 'gender',
    label: 'Gender',
    required: false,
    values: '',
    validation: '',
    isBelong: false,
  },
  {
    name: 'mobile',
    label: 'Mobile',
    required: false,
    values: '',
    validation: '',
    isBelong: false,
  },
];

export const eventAttendeesHandler = attendees => {
  const addIsBelong = attendees.map(item => ({ ...item, isBelong: true }));
  let att = [];
  const exceptCommonAttendees = differenceBy(
    attendeesDefault,
    addIsBelong,
    'name',
  );
  if (isNull(addIsBelong)) {
    att = attendeesDefault;
  } else {
    const mergeAttendees = addIsBelong.concat(exceptCommonAttendees);
    att = mergeAttendees;
  }
  return att;
};
