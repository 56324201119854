import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import Input from '../../../components/elements/InputAddOn';
import { Select } from '../../../components/elements';

const Container = styled.div`
  max-width: 80%;
  margin: 2rem;
`;

const MenuStatus = ({ setFieldValue, openCloseStatus, values }) => (
  <Container>
    <Column.Group>
      <Column size={6}>
        <p className="title">Menu Hours</p>
      </Column>
      <Column size={3}>
        <p className="title">Open / Closed Status</p>
      </Column>
      <Column size={3}>
        <p className="title">Change Status</p>
      </Column>
    </Column.Group>
    {openCloseStatus && (
      <Column.Group>
        <Column size={1}>
          <p className="is-size-5 has-text-weight-semibold">
            {openCloseStatus.day}
          </p>
        </Column>
        <Column size={5}>
          <div className="is-size-4">
            {openCloseStatus.option
              ? openCloseStatus.option.map(option => {
                  if (
                    option.start_in_sec === 0 &&
                    option.end_in_sec === 86340
                  ) {
                    return <p className="is-size-4">Open 24 Hours</p>;
                  }
                  return (
                    <p className="is-size-4 is-uppercase">
                      {option.start.length !== 0
                        ? `${option.start} - ${option.end}`
                        : 'Closed'}
                    </p>
                  );
                })
              : 'Closed'}
            {openCloseStatus?.option?.length === 0 && 'Closed'}
          </div>
        </Column>
        <Column size={3}>
          <Input disabled value={openCloseStatus.hours || 'CLOSED'} />
        </Column>
        <Column size={3}>
          <Select
            options={[
              { value: 'OPENED', label: 'OPENED' },
              { value: 'CLOSED', label: 'CLOSED' },
            ]}
            onChange={value =>
              setFieldValue('openCloseHours', {
                hours: value.value,
                touched: true,
              })
            }
            value={[
              {
                value: values.openCloseHours.hours || 'CLOSED',
                label: values.openCloseHours.hours || 'CLOSED',
              },
            ]}
          />
        </Column>
      </Column.Group>
    )}
  </Container>
);

export default MenuStatus;
