import React from 'react';
import { Column } from 'rbx';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { filter, first, isUndefined } from 'lodash';
import client from '../../../utils/apolloClient';
import { Input } from '../../../components/elements';

const searchSocial = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_count {
          type
          count
        }
      }
    }
  }
`;

const searchComments = gql`
  query searchComments($input: SearchInput) {
    search_comments(input: $input) {
      total_size
    }
  }
`;

const searchFlags = gql`
  query searchFlags($input: SearchInput) {
    search_flags(input: $input) {
      total_size
    }
  }
`;

// const Wrapper = styled.div`
//   padding: 1rem;
//   .level:not(:last-child) {
//     margin-bottom: 0;
//   }
// `;

const SocialCounts = ({ review }) => {
  const socialFilter = {
    object_id: review.item_review_id,
    object_type: 'ITEM_REVIEW',
  };

  return (
    <Query
      query={searchSocial}
      variables={{ input: { filter: { social_filter: socialFilter } } }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (error) {
          return (
            <Column.Group vcentered multiline gapSize={8}>
              <Column size={3}>
                <Input label="Like Count" placeholder="0" disabled />
              </Column>
              <Column size={3}>
                <Input label="Comment count" placeholder="0" disabled />
              </Column>
              <Column size={3}>
                <Input label="Flag count" placeholder="0" disabled />
              </Column>
            </Column.Group>
          );
        }

        const { search_social } = data;
        if (isUndefined(search_social)) {
          return (
            <Column.Group vcentered multiline gapSize={8}>
              <Column size={3}>
                <Input label="Like Count" placeholder="0" disabled />
              </Column>
              <Column size={3}>
                <Input label="Comment count" placeholder="0" disabled />
              </Column>
              <Column size={3}>
                <Input label="Flag count" placeholder="0" disabled />
              </Column>
            </Column.Group>
          );
        }
        const { social_aggregation } = search_social;

        const likeCountArray =
          social_aggregation.total_social_count &&
          social_aggregation.total_social_count.length !== 0
            ? filter(
                social_aggregation.total_social_count,
                item => item.type === 'LIKE',
              )
            : [];

        const likeCount =
          likeCountArray.length !== 0 ? first(likeCountArray).count : 0;

        const commentFilter = {
          object_id: review.item_review_id,
          object_type: 'ITEM_REVIEW',
        };
        const objectId = review.item_review_id;
        const flagFilter = {
          object_id: objectId,
        };

        return (
          <Query
            query={searchComments}
            variables={{ input: { filter: { comment_filter: commentFilter } } }}
            fetchPolicy="network-only"
          >
            {({ data: comment, error: commentError }) => {
              if (commentError) {
                return (
                  <Column.Group vcentered multiline gapSize={8}>
                    <Column size={3}>
                      <Input label="Like Count" placeholder="0" disabled />
                    </Column>
                    <Column size={3}>
                      <Input label="Comment count" placeholder="0" disabled />
                    </Column>
                    <Column size={3}>
                      <Input label="Flag count" placeholder="0" disabled />
                    </Column>
                  </Column.Group>
                );
              }
              const { search_comments } = comment;

              return (
                <Query
                  client={client.clientPrivate}
                  query={searchFlags}
                  variables={{
                    input: { filter: { flag_filter: flagFilter } },
                  }}
                  fetchPolicy="network-only"
                >
                  {({ data: flag, error: flagError }) => {
                    if (flagError) {
                      return (
                        <Column.Group vcentered multiline gapSize={8}>
                          <Column size={3}>
                            <Input
                              label="Like Count"
                              placeholder="0"
                              disabled
                            />
                          </Column>
                          <Column size={3}>
                            <Input
                              label="Comment count"
                              placeholder="0"
                              disabled
                            />
                          </Column>
                          <Column size={3}>
                            <Input
                              label="Flag count"
                              placeholder="0"
                              disabled
                            />
                          </Column>
                        </Column.Group>
                      );
                    }
                    const { search_flags } = flag;
                    return (
                      <Column.Group vcentered multiline gapSize={8}>
                        <Column size={3}>
                          <Input
                            label="Like Count"
                            value={likeCount}
                            disabled
                          />
                        </Column>

                        {isUndefined(search_comments) ? (
                          <Column size={3}>
                            <Input
                              label="Comment count"
                              placeholder="0"
                              disabled
                            />
                          </Column>
                        ) : (
                          <Column size={3}>
                            <Input
                              label="Comment count"
                              value={search_comments.total_size}
                              disabled
                            />
                          </Column>
                        )}

                        {isUndefined(search_flags) ? (
                          <Column size={3}>
                            <Input
                              label="Flag count"
                              placeholder="0"
                              disabled
                            />
                          </Column>
                        ) : (
                          <Column size={3}>
                            <Input
                              label="Flag count"
                              value={search_flags.total_size}
                              disabled
                            />
                          </Column>
                        )}
                      </Column.Group>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default SocialCounts;
