import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import { omit } from 'lodash';
import { Input, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = ({
  status,
  placeName,
  vendorName,
  city,
  routeState,
  history,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/place-postings/${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };

  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="ACTIVE"
                name="Type"
                value={status === 'ACTIVE'}
                onChange={() =>
                  history.push(
                    `/place-postings/${qs.stringify(
                      {
                        status: 'ACTIVE',
                      },
                      true,
                    )}`,
                  )
                }
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="INACTIVE"
                name="Type"
                value={status === 'INACTIVE'}
                onChange={() =>
                  history.push(
                    `/place-postings/${qs.stringify(
                      {
                        status: 'INACTIVE',
                      },
                      true,
                    )}`,
                  )
                }
              />
            </div>
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              id="PlaceManagement_PlacePosting_SearchPlaceName"
              value={placeName}
              onChange={place => {
                handlePush({ placeName: place.target.value }, 'placeName');
              }}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-4">
            <Input
              label="Company Name"
              id="PlaceManagement_PlacePosting_SearchCompanyName"
              type="text"
              value={vendorName}
              onChange={event => {
                handlePush({ vendorName: event.target.value }, 'vendorName');
              }}
              placeholder="Start entering company name..."
            />
          </div>
          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={value => {
                    handlePush({ city: value.name }, 'city');
                  }}
                  onHandleClear={() => handlePush({ city: '' }, 'city')}
                  placeholder="Start entering company name..."
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
