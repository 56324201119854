import React from 'react';
import { Column } from 'rbx';

import moment from 'moment';
import { Input, MobileInput } from '../../../components/elements';

const ListingAudit = ({ values }) => (
  <fieldset className="box">
    <legend className="label">Listed by user details </legend>
    <Column.Group multiline vcentered>
      <Column size="3">
        <Input
          label="User FullName"
          value={values.listing_audit.listing_contact_name}
          disabled
        />
      </Column>
      <Column size="3">
        <MobileInput
          label="Contact Phone"
          value={values.listing_audit.listing_contact_phone}
          disabled
        />
      </Column>
      <Column size="3">
        <Input
          label="Contact Email"
          value={values.listing_audit.listing_contact_email}
          disabled
        />
      </Column>
      <Column size="3">
        <Input
          label="Created On"
          value={moment(values.audit.created_at).format('lll')}
          disabled
        />
      </Column>
    </Column.Group>
  </fieldset>
);

export default ListingAudit;
