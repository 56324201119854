import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import ItemWithVariant from './ItemWithVariant';
import ItemWithoutVariant from './ItemWithoutVariant';
import ItemNameAndRemoveButton from './ItemNameAndRemoveButton';

const initialItemLink = (menu_item, links, item_link_id) => {
  const comparisonFunc = ele => ele.item_link_id === item_link_id;
  return menu_item.find(comparisonFunc) || links.find(comparisonFunc);
};

const TableRows = ({
  item_link_id,
  links,
  setFieldValue,
  menu_item,
  selectedLinkIds,
}) => {
  const [itemLink, setItemLink] = useState(() =>
    initialItemLink(menu_item, links, item_link_id),
  );

  const onTableValueChange = () => {
    let modifiedMenuItem;

    if (menu_item) {
      const menuItemIndex = menu_item.findIndex(
        ele => ele.item_link_id === item_link_id,
      );
      if (menuItemIndex !== -1) {
        modifiedMenuItem = [...menu_item];
        modifiedMenuItem[menuItemIndex] = itemLink;
      } else {
        modifiedMenuItem = [itemLink, ...menu_item];
      }
      setFieldValue('menu_item', modifiedMenuItem);
    } else {
      setFieldValue('menu_item', [itemLink]);
    }
  };

  useEffect(() => onTableValueChange(), [itemLink]);

  if (itemLink.variant) {
    const variantAtRootLevel = cloneDeep(itemLink.variant);
    const itemVariants = variantAtRootLevel[0].variants;
    return (
      <>
        <ItemNameAndRemoveButton
          itemLink={itemLink}
          menu_item={menu_item}
          item_link_id={item_link_id}
          setFieldValue={setFieldValue}
          selectedLinkIds={selectedLinkIds}
        />
        {itemVariants.map((itemVariant, itemVariantIndex) => (
          <ItemWithVariant
            key={itemVariant.variant_id}
            variantAtRootLevel={variantAtRootLevel}
            itemVariant={itemVariant}
            itemVariantIndex={itemVariantIndex}
            setItemLink={setItemLink}
          />
        ))}
      </>
    );
  }

  return (
    <ItemWithoutVariant
      setFieldValue={setFieldValue}
      menu_item={menu_item}
      item_link_id={item_link_id}
      setItemLink={setItemLink}
      itemLink={itemLink}
      selectedLinkIds={selectedLinkIds}
    />
  );
};

export default TableRows;
