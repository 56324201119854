/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { Radio, ReactDateTimePicker } from '../../../../components/elements';

const Container = styled.div``;

const RadioStyled = styled.div`
  && .has-text-info {
    color: ${({ theme, statusUnavailable }) =>
      statusUnavailable ? theme.waterMarkGrey : theme.primaryColor} !important;
  }
`;

const Filler = styled.div`
  width: 165px;
`;
const Form = ({
  rootIsAvailable = true,
  availabilityStatus,
  availability_date = null,
  minDate,
  onSubmit = () => {},
}) => {
  const [availability, setAvailability] = useState('AVAILABLE');
  const [availableFromDate, setAvailableFromDate] = useState(availability_date);

  useEffect(() => {
    setAvailability(availabilityStatus);
  }, []);

  useEffect(() => {
    if (new Date(availableFromDate) < new Date(minDate)) {
      setAvailableFromDate(null);
    }
  }, [minDate]);

  return (
    <>
      <Container>
        <Radio
          label="Available"
          value={rootIsAvailable ? availability === 'AVAILABLE' : false}
          onChange={() => {
            setAvailability('AVAILABLE');
            setAvailableFromDate(null);
            onSubmit(null);
          }}
          disabled={!rootIsAvailable}
        />
      </Container>

      <div>
        <Radio
          label="Available from date"
          value={rootIsAvailable ? availability === 'UNAVAILABLE' : false}
          onChange={() => {
            setAvailability('UNAVAILABLE');
          }}
          disabled={!rootIsAvailable}
        />
      </div>
      <div>
        <ReactDateTimePicker
          value={rootIsAvailable ? availableFromDate : null}
          minDate={
            minDate
              ? moment(minDate)
                  .add(1, 'days')
                  .toDate()
              : moment()
                  .add(1, 'days')
                  .toDate()
          }
          onChange={date => {
            setAvailableFromDate(moment(date).format('YYYY-MM-DD'));
            setAvailability('UNAVAILABLE');
            onSubmit(date);
          }}
          disabled={!rootIsAvailable}
        />
      </div>
      {!rootIsAvailable ? (
        <RadioStyled statusUnavailable={rootIsAvailable}>
          <Radio
            label="Unavailable Indefinitely"
            value={!rootIsAvailable}
            disabled
          />
        </RadioStyled>
      ) : (
        <Filler />
      )}
    </>
  );
};

export default Form;
