import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { Input } from '../../../components/elements';

const ChangeWrapper = styled.div`
  padding-left: 50%;
  &&& {
    a {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const Form = () => (
  <>
    <Header />
    <Column>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={8}>
          <Input label="Account Name (exactly as it appears on your bank account)" />
        </Column>
      </Column.Group>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={4}>
          <Input label="BSB Number" required />
        </Column>
        <Column size={4}>
          <Input label="Bank Name" />
        </Column>
      </Column.Group>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size="8">
          <Input label="Bank Account Number" required>
            <ChangeWrapper>
              <a>Update</a>
            </ChangeWrapper>
          </Input>
        </Column>
      </Column.Group>
    </Column>
    <hr />
    <Footer />
  </>
);

export default Form;
