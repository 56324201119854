import React from 'react';
import styled from 'styled-components';
import { Level } from 'rbx';
import { Link } from 'react-router-dom';

const Container = styled.div`
  background: #fff;
  bottom: 0;
  box-shadow: 0 -5px 5px -6px #999;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  padding: 1rem 1rem;
  z-index: 100;
`;

const Footer = props => {
  const { handleSubmit, loading } = props;
  return (
    <Container>
      <Level>
        <Level.Item align="left" />
        <Level.Item align="right">
          <Level.Item>
            <Link to="/users" className="button is-light">
              <span>Cancel</span>
            </Link>
          </Level.Item>
          <Level.Item>
            <button
              className={`button is-primary ${loading && 'is-loading'}`}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </Level.Item>
        </Level.Item>
      </Level>
    </Container>
  );
};

export default Footer;
