import React from 'react';
import OutOfStockForm from './OutOfStockForm';

const Orders = ({
  values,
  isActiveOutOfStockModal,
  setActiveOutOfStockModal,
}) => (
  <OutOfStockForm
    values={values}
    isActive={isActiveOutOfStockModal}
    onClose={() => setActiveOutOfStockModal(false)}
  />
);
export default Orders;
