import React from 'react';
import { Link } from 'react-router-dom';
import { first, replace } from 'lodash';
import moment from 'moment';

const EventList = ({ events, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === events.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <React.Fragment>
      <td>
        <Link to={`/manage-event/${events.event_id}`}>{events.name}</Link>
      </td>
      <td>{`${events.place_name}`}</td>
      <td>{`${events.city}`}</td>
      <td className="is-capitalized">
        {replace(first(events.listing_type), '_', ' ')}
      </td>

      <td>{`${moment(events.start).format('lll')}`}</td>
      <td>
        {user ? (
          <React.Fragment>
            <p>{user.full_name}</p>
          </React.Fragment>
        ) : (
          events.audit.updated_by
        )}
      </td>
      <td>{moment(events.audit.updated_at).format('ll')}</td>
      {/* <td>{events.status}</td> */}
    </React.Fragment>
  );
};

export default EventList;
