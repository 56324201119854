import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, Column, Label, Field } from 'rbx';

import { withFormik } from 'formik';
import * as yup from 'yup';
import uuidv4 from 'uuid/v4';
import { isNull, isNil, omit } from 'lodash';
import {
  getServiceTypeSettings,
  defaultHours,
  addIdInputHours,
  parseFinalInputHours,
  getDisplayMenuHours,
} from './helper';
import {
  placeBusinessHoursHandler,
  menuHourSorter,
} from '../../../utils/helpers';
import {
  Box,
  Tabs,
  Select,
  Input,
  InputErrorMessage,
} from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import Hours from './Hours';
import MenuSection from './MenuSection';

import QrCodes from './QRCodes';
import WeeklyHours from './WeeklyHours';
import MenuOpenClose from './MenuOpenClose';
import Toggle from './Designer/Toggle';

import { config } from '../../../utils/config';

import { fetchMenuListingAndSetState } from './Designer/helpers';
import MenuPageHeader from '../../../components/MenuPageHeader';

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveHourModal, setIsActiveHourModal] = useState(false);
  const [tab, setTab] = useState(0);

  const {
    loading,
    dirty,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    menu,
    isEdit = false,
    openCloseStatus,
    setErrors,
    serviceTypeSettingId,
    userId,
    setMenuListing = () => {},
    setMenuListingLoading = () => {},
    onToggle,
  } = props;

  const [placeData, setPlaceData] = useState(props?.place);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getServiceTypeSettings({ placeId: values.place_id });
      setIsLoading(false);
      const newData = data.map(item => ({
        ...item,
        value: item.service_type_setting_id,
        label: item.service_type,
      }));

      setSettings(newData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchMenuListingAndSetState({
      serviceTypeSettingId,
      userId,
      setMenuListing,
      setMenuListingLoading,
    });
  }, []);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        if (!isEdit) {
          handleSubmit();
        } else if (
          values.isCustom &&
          values.display_menu_hours &&
          values.hours === defaultHours
        ) {
          setErrors({
            hours: 'Hours is required, Please put atleast one day hours ',
          });
        } else if (!values.isCustom && values.hours === defaultHours) {
          setErrors({
            hours: 'Hours is required, Please put atleast one day hours ',
          });
        } else {
          handleSubmit();
        }
      }}
    >
      <Header
        isEdit={isEdit}
        loading={loading}
        dirty={dirty}
        menuName={values.name}
      >
        <MenuPageHeader
          placeData={placeData}
          setPlaceData={setPlaceData}
          placeId={values.place_id}
          platform={menu?.platform}
        />
      </Header>
      <Box>
        <Tabs className="tabs is-medium">
          <Tab active={tab === 0} onClick={() => setTab(0)}>
            Menu Designer
          </Tab>
          {isEdit && (
            <>
              <Tab active={tab === 1} onClick={() => setTab(1)}>
                Menu Categories
              </Tab>
              <Tab active={tab === 2} onClick={() => setTab(2)}>
                QR Codes
              </Tab>
              <Tab active={tab === 3} onClick={() => setTab(3)}>
                Open / Closed Status
              </Tab>
            </>
          )}
        </Tabs>
        {tab === 0 && (
          <Container>
            {isActiveHourModal && (
              <Hours
                serviceType={settings.filter(
                  item => item.value === values.service_type_setting_id,
                )}
                isActive={isActiveHourModal}
                onClose={() => {
                  setIsActiveHourModal(false);
                }}
                {...props}
              />
            )}

            <Column.Group multiline gapSize={8}>
              {!isEdit ? (
                <>
                  <Column size="4">
                    <Select
                      label="Service Type"
                      name="service_type_setting_id"
                      options={settings}
                      value={settings.filter(
                        item => item.value === values.service_type_setting_id,
                      )}
                      onChange={value => {
                        setFieldValue('service_type_setting_id', value.value);
                        setFieldValue('isCustom', value.is_custom_service);
                        if (value.is_custom_service) {
                          getDisplayMenuHours({
                            service_type_setting_id: value.value,
                          }).then(data => {
                            if (!isNull(data)) {
                              setFieldValue('display_menu_hours', data);
                            }
                          });
                        }
                      }}
                      disableToastMessage
                      errors={errors.service_type_setting_id}
                      touched={touched.service_type_setting_id}
                      required
                      isLoading={isLoading}
                      disabled={loading}
                    />
                  </Column>
                  <Column size="4">
                    <Input
                      label="Menu Name"
                      id="MenuDesigner_Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      optionalText="( Max 26 characters )"
                      maxLength="26"
                      required
                      errors={errors.name}
                      touched={touched.name}
                      disableToastMessage
                      disabled={loading}
                    />
                  </Column>
                </>
              ) : (
                <>
                  <Column size="4">
                    <Input
                      label="Menu Name"
                      id="MenuDesigner_Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      optionalText="( Max 26 characters )"
                      maxLength="26"
                      required
                      errors={errors.name}
                      touched={touched.name}
                      disableToastMessage
                    />
                  </Column>
                  <Column size="4">
                    <Select
                      label="Service Type"
                      name="service_type_setting_id"
                      options={settings}
                      value={settings.filter(
                        item => item.value === values.service_type_setting_id,
                      )}
                      onChange={value => {
                        setFieldValue('service_type_setting_id', value.value);
                        setFieldValue('isCustom', value.is_custom_service);
                        if (value.is_custom_service) {
                          getDisplayMenuHours({
                            service_type_setting_id: value.value,
                          }).then(data => {
                            if (!isNull(data)) {
                              setFieldValue('display_menu_hours', data);
                            }
                          });
                        }
                      }}
                      disableToastMessage
                      errors={errors.service_type_setting_id}
                      touched={touched.service_type_setting_id}
                      required
                      isLoading={isLoading}
                      disabled={isEdit}
                    />
                  </Column>
                  <Column size="4">
                    <Select
                      label="Menu Status"
                      options={[
                        { value: 'ACTIVE', label: 'ACTIVE' },
                        { value: 'INACTIVE', label: 'INACTIVE' },
                      ]}
                      value={[
                        {
                          value: values.status,
                          label: values.status,
                        },
                      ]}
                      onChange={value => setFieldValue('status', value.value)}
                      disableToastMessage
                      required
                    />
                  </Column>
                </>
              )}
              {!!placeData && isEdit && (
                <>
                  <Column size="8">
                    <Field>
                      <Label>Publish Menu</Label>
                      <Toggle
                        checked={values.publish_menu}
                        onToggle={value => {
                          setFieldValue('publish_menu', value);
                          onToggle({
                            menu_id: values.menu_id,
                            user_id: values.user_id,
                            place_id: values.place_id,
                            publish_menu: value,
                          });
                        }}
                        disabled={values.status === 'INACTIVE'}
                      />
                    </Field>
                  </Column>
                  <br />
                </>
              )}
              {isEdit && (
                <Column size="6">
                  <WeeklyHours
                    hours={values.hours}
                    onClick={() => setIsActiveHourModal(true)}
                  />
                  <InputErrorMessage
                    errors={errors.hours}
                    touched={errors.hours}
                    setErrors={setErrors}
                  />
                </Column>
              )}
            </Column.Group>
          </Container>
        )}
        {tab === 1 && (
          <Container>
            <MenuSection placeId={values.place_id} menuId={values.menu_id} />
          </Container>
        )}
        {tab === 2 && menu && (
          <QrCodes
            menuName={values.name}
            placeName={menu.place_name}
            // eslint-disable-next-line max-len
            qrCodeValue={`${config.customerDomain}/order-food/${menu.service_type_setting.service_type}/${menu.place_slug}?menuId=${values.menu_id}`}
          />
        )}
        {isEdit && tab === 3 && menu && (
          <MenuOpenClose
            setFieldValue={setFieldValue}
            values={values}
            openCloseStatus={openCloseStatus}
          />
        )}
      </Box>
      {isEdit && <Footer loading={loading} dirty={dirty} />}
    </form>
  );
};

const MenuForm = withFormik({
  mapPropsToValues: ({
    menu,
    place,
    userId,
    openCloseStatus,
    display_menu_hours,
    is_custom_service,
  }) => ({
    user_id: userId,
    place_id: menu && !isNull(menu.place_id) ? menu.place_id : place.place_id,
    service_type_setting_id:
      menu && !isNull(menu.service_type_setting.service_type_setting_id)
        ? menu.service_type_setting.service_type_setting_id
        : '',
    menu_id: menu && !isNull(menu.menu_id) ? menu.menu_id : uuidv4(),
    name: menu && !isNull(menu.name) ? menu.name : '',
    approval_status:
      menu && !isNull(menu.status) ? menu.approval_status : 'APPROVED',
    status: menu && !isNull(menu.status) ? menu.status : 'ACTIVE',
    description: menu && !isNull(menu.description) ? menu.description : '',
    isCustom: !isNull(is_custom_service) ? is_custom_service : false,
    hours:
      menu && !isNull(menu.menu_hour) && menu.menu_hour.length !== 0
        ? placeBusinessHoursHandler(addIdInputHours(menu.menu_hour))
        : defaultHours,
    menu_hour:
      menu && !isNull(menu.menu_hour) && menu.menu_hour.length !== 0
        ? placeBusinessHoursHandler(addIdInputHours(menu.menu_hour))
        : defaultHours,
    isHoursDirty: false,
    openCloseHours: {
      hours: openCloseStatus?.hours,
      touched: openCloseStatus?.touched,
    },
    display_menu_hours: !isNull(display_menu_hours) ? display_menu_hours : true,
    publish_menu: menu && !isNil(menu.publish_menu) ? menu.publish_menu : false,
  }),

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(26, 'Menu Name should not be greater than 26 characters')
      .required('Menu Name is required!'),
    description: yup
      .string()
      .min(5, 'Please enter at least 5 characters')
      .max(288, 'Menu Description cannot be greater than 288 characters'),
    service_type_setting_id: yup.string().required('Service Type is required!'),
  }),
  validateOnBlur: false,
  validateOnChange: false,

  handleSubmit: (values, { props }) => {
    const input = omit(values, [
      'hours',
      'menu_hour',
      'isCustom',
      'isHoursDirty',
      'display_menu_hours',
    ]);
    if (props.menu) {
      Object.assign(
        input,
        values.isHoursDirty && {
          menu_hour: menuHourSorter(parseFinalInputHours(values.menu_hour)),
        },
      );
    } else {
      Object.assign(input, {
        menu_hour: menuHourSorter(parseFinalInputHours(values.menu_hour)),
      });
    }

    props.onSubmit({
      ...input,
    });
  },

  displayName: 'GlobalMenuForm',
})(Form);

export default MenuForm;
