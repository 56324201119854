import React from 'react';
import { Column, Level } from 'rbx';
import { capitalize } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Input } from '../../../components/elements';

const ListingTypeView = ({ values, location }) => (
  <React.Fragment>
    {location.pathname !== '/add-event' && (
      <Column.Group>
        <Column size={6}>
          <Level>
            <Level.Item align="left">
              <Level.Item>
                <div className="label">Listing Type</div>
              </Level.Item>
              <Level.Item>
                <Input value={capitalize(values.listing)} disabled />
              </Level.Item>
            </Level.Item>
          </Level>
        </Column>
        <Column size={6}>
          <Level>
            <Level.Item align="left">
              <Level.Item>
                <div className="label">Listing Visibility</div>
              </Level.Item>
              <Level.Item>
                <Input value={capitalize(values.visibility)} disabled />
              </Level.Item>
            </Level.Item>
          </Level>
        </Column>
      </Column.Group>
    )}
  </React.Fragment>
);

export default withRouter(ListingTypeView);
