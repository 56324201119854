import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { first } from 'lodash';
import ReactTooltip from 'react-tooltip';

import Delete from './Delete';

const PlaceList = ({ place, users = [] }) => {
  const [inactive, setInactive] = useState(false);

  const userArray =
    users && users.length !== 0
      ? users.filter(item => item.user_id === place.audit.updated_by)
      : [];

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  if (inactive) {
    return null;
  }

  return (
    <React.Fragment>
      <td>
        <Link to={`/update-place/${place.place_id}`}>{place.name}</Link>
      </td>
      <td>{place.city}</td>
      <td>{`${place.status}`}</td>
      <td>{place.claim_status}</td>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user.full_name}>{user.full_name}</p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user && user.email}</p>
                <p>objectId: {user && user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          place.audit.updated_by
        )}
      </td>
      <td>{moment(place.audit.updated_at).format('lll')}</td>
      <td>
        <Delete
          placeId={place.place_id}
          onCompleted={() => setInactive(true)}
        />
      </td>
    </React.Fragment>
  );
};

export default PlaceList;
