import React from 'react';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { normalizePlaceData } from '../../utils/helpers';

const API_KEY = 'AIzaSyBuhByC34DbXmvZlJYC3F-aCOMgb9E55iU';

const GoogleSuggest = ({ label, onSelect, ...props }) => {
  const [search, setSearch] = React.useState('');
  const [value, setValue] = React.useState('');

  const handleInputChange = e => {
    setSearch(e.target.value);
    setValue(e.target.value);
  };

  const handleSelectSuggest = async suggest => {
    const data = await normalizePlaceData(suggest);
    console.log('data', data);
    setSearch('');
    setValue(suggest.formatted_address);
    onSelect(data);
  };

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <ReactGoogleMapLoader
          params={{
            key: API_KEY,
            libraries: 'places,geocode',
          }}
          render={googleMaps =>
            googleMaps && (
              <div>
                <ReactGooglePlacesSuggest
                  autocompletionRequest={{
                    input: search,
                    componentRestrictions: { country: ['au'] },
                  }}
                  googleMaps={googleMaps}
                  onSelectSuggest={handleSelectSuggest}
                >
                  <input
                    className="input"
                    type="text"
                    value={value}
                    placeholder="Search a location"
                    onChange={handleInputChange}
                    {...props}
                  />
                </ReactGooglePlacesSuggest>
              </div>
            )
          }
        />
      </div>
    </div>
  );
};

export default GoogleSuggest;
