import React from 'react';
import { Column } from 'rbx';
import Input from '../../../components/elements/Input';
import Select from '../../../components/elements/Select';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const PrimaryDetailsForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  <div>
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={6}>
        <Input
          action={['update_company', 'create_company']}
          label="Registered Company Name"
          name="name"
          id="Financial_Management_PlaceCompany_RegisteredCompanyName"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.name && touched.name && 'is-danger'}
          optionalText="(Max 100 Characters)"
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      <Column size={3}>
        <Select
          action={['update_company', 'create_company']}
          label="Status"
          options={status}
          value={[{ value: values.status, label: values.status }]}
          onChange={({ value }) => setFieldValue('status', value)}
          errors={errors.status}
          touched={touched.status}
          required
        />
      </Column>
    </Column.Group>
  </div>
);

export default PrimaryDetailsForm;
