import { transform, isEqual, isObject } from 'lodash';

function changes(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? changes(value, base[key])
          : value;
    }
  });
}

function findDirtyFields(object, base) {
  return changes(object, base);
}

export default findDirtyFields;
