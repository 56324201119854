import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../../../components/elements';
// import OrderDetails from './OrderDetails';

const PaymentsDetails = () => {
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>
                  Place Name
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Payment Date
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Payment ID</span>
              </div>
            </th>
            <th>
              <div>
                <span>Frequency</span>
              </div>
            </th>

            <th>
              <div>
                <span>Payment Period</span>
              </div>
            </th>

            <th>
              <div>
                <span>Earning</span>
              </div>
            </th>

            <th>
              <div>
                <span>Fee</span>
              </div>
            </th>

            <th>
              <div>
                <span>Total Payout</span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <a onClick={() => {}}>
                    <i className="fas fa-sort-amount-down" />
                  </a>{' '}
                  Status
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link to="/payments-activity" style={{ color: '#00aeef' }}>
                Stakehouse, St Kilda
              </Link>
            </td>
            <td>25/03/2020</td>
            <td>KNFV5E2SB477VA5</td>
            <td>Weekly</td>
            <td>Mar 30 9:31 AM</td>
            <td>$5430.00</td>
            <td>$230</td>
            <td>$5200.00</td>
            <td>Paid</td>
          </tr>
        </tbody>
      </Table>
      {/* <OrderDetails
        isActiveOrderDetailsModal={isActiveOrderDetailsModal}
        setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
      /> */}
    </>
  );
};

export default PaymentsDetails;
