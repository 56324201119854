/* eslint-disable no-plusplus */
import React from 'react';
import { Label, Column, Button, Textarea } from 'rbx';
// import styled from 'styled-components';
import { intersectionBy, lowerCase } from 'lodash';
import { withRouter } from 'react-router-dom';
import { createObjFromArrayCapitalize } from '../../../utils/helpers';
import { config } from '../../../utils/config';
import {
  Select,
  Input,
  TextArea,
  CheckBox,
  TagInputs,
  CreatableSelectInputs,
  MobileInput,
  BoxWithHeader,
  SingleImageUpload,
  InputErrorMessage,
} from '../../../components/elements';

import options from '../../../utils/optionsHelpers';

// import orderOnline from './order-online.png';

// const ChangeWrapper = styled.div`
//   padding-left: 500%;
//   &&& {
//     a {
//       color: ${({ theme }) => theme.primaryColor};
//     }
//   }
// `;

const LinkButton = props => (
  <Button {...props} className={`${props.className} link-button`} />
);

const orderOnlineButtonSelectionOptionsDev = [
  {
    label: 'button1',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-Button-590.svg',
  },
  {
    label: 'button2',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-Button-591.svg',
  },
  {
    label: 'button3',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-Button-592.svg',
  },
  {
    label: 'button4',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-Button-593.svg',
  },
  {
    label: 'button5',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-01.svg',
  },
  {
    label: 'button6',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-02.svg',
  },
  {
    label: 'button7',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-03.svg',
  },
  {
    label: 'button8',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-04.svg',
  },
];

const orderOnlineButtonSelectionOptionsProd = [
  {
    label: 'button1',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-Button-590.svg',
  },
  {
    label: 'button2',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-Button-591.svg',
  },
  {
    label: 'button3',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-Button-592.svg',
  },
  {
    label: 'button4',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-Button-593.svg',
  },
  {
    label: 'button5',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-01.svg',
  },
  {
    label: 'button6',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-02.svg',
  },
  {
    label: 'button7',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-03.svg',
  },
  {
    label: 'button8',
    value:
      'https://s3.amazonaws.com/development.portal.kravein.com.au/images/order/Order-Online-04.svg',
  },
];

const orderOnlineButtonSelectionOptions =
  config.customer === 'https://dev11.portal.kravein.com.au/'
    ? orderOnlineButtonSelectionOptionsDev
    : orderOnlineButtonSelectionOptionsProd;

const domainString =
  config.customer === 'https://dev11.portal.kravein.com.au/'
    ? 'https://dev11.portal.kravein.com.au/'
    : 'https://www.kravein.com.au/';

// Default Options
const paymentMethods = ['cash', 'card'];
const excludeServiceType = ['Pickup', 'Dinein', 'Delivery'];

const urlToOrderFood = ({ values }) => {
  if (
    values?.service_type &&
    values?.slug &&
    (values.service_type === 'Pickup' ||
      values.service_type === 'Delivery' ||
      values.service_type === 'Dinein')
  ) {
    return `${config.customer}order-food/${lowerCase(values.service_type)}/${
      values.slug
    }`;
  }
  return `${config.customer}order-food/${values?.service_type || '-'}/${
    values.slug
  }`;
};

const htmlCodeOrderFood = ({ values, orderOnlineButtonValue }) => {
  if (
    values?.service_type &&
    values?.slug &&
    (values.service_type === 'Pickup' ||
      values.service_type === 'Delivery' ||
      values.service_type === 'Dinein')
  ) {
    // eslint-disable-next-line max-len
    return `<a href="${domainString}order-food/${values.service_type}/${values.slug}"> <img src=${orderOnlineButtonValue.value} alt="KRAVEiN Order Online Button"></a>`;
  }
  // eslint-disable-next-line max-len
  return `<a href="${domainString}order-food/${values.service_type}/${values.slug}"> <img src=${orderOnlineButtonValue.value} alt="KRAVEiN Order Online Button"></a>`;
};

const Details = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  touched,
  onDeleteImage,
  serviceTypeSettingId,
  // history,
}) => {
  const [isCopied, setIsCopied] = React.useState(false); // Copy Order Link
  const [isCopiedHTML, setIsCopiedHTML] = React.useState(false); // Copy HTML Code
  const [toggleHTMl, setToggleHTML] = React.useState(false); // toggle button
  const [orderOnlineButtonValue, setOrderOnlineButtonValue] = React.useState(
    orderOnlineButtonSelectionOptions[0],
  );

  const htmlCode = React.useRef(
    htmlCodeOrderFood({ values, orderOnlineButtonValue }),
  );
  // values.payment_method
  const parsedPaymentOptions = createObjFromArrayCapitalize(paymentMethods);

  const handlePaymentMethod = value => {
    const previousValues = values.payment_method;
    console.log(previousValues, 'previousValues');
    console.log(value, 'currentValue');
    if (Array.isArray(value)) {
      const input = value.filter(
        // item => item.value !== 'credit-card',
        item => item.value,
      );
      const newValue = input.map(item => item.value);
      // const fixed = ['credit-card'];
      for (let i = 0; i < previousValues.length; i++) {
        if (
          excludeServiceType.includes(values.service_type) &&
          previousValues[i] === 'CARD' &&
          !newValue.includes('CARD')
        ) {
          return;
        }
      }
      setFieldValue('payment_method', newValue);
    } else if (
      value === null &&
      excludeServiceType.includes(values.service_type) &&
      previousValues.includes('CARD')
    ) {
      setFieldValue('payment_method', ['CARD']);
    } else {
      setFieldValue('payment_method', []);
    }
  };

  const copyLink = (e, text, type) => {
    navigator.clipboard.writeText(text).then(
      // eslint-disable-next-line prefer-arrow-callback
      function() {
        if (type === 'html') {
          setIsCopiedHTML(true);

          setTimeout(() => {
            setIsCopiedHTML(false);
          }, 1000 * 5);
        } else {
          setIsCopied(true);

          setTimeout(() => {
            setIsCopied(false);
          }, 1000 * 5);
        }
      },
      // eslint-disable-next-line prefer-arrow-callback
      function(err) {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };
  return (
    <>
      <Column.Group>
        <Column size="5">
          <BoxWithHeader title="Photos">
            <SingleImageUpload
              s3UploadPath={`service-setting/${serviceTypeSettingId}/`}
              imageValue={values.image_url}
              onChange={image => setFieldValue('image_url', image.url)}
              onDelete={() => {
                setFieldValue('image_url', null);
                if (onDeleteImage) {
                  onDeleteImage();
                }
              }}
            />
            <InputErrorMessage
              errors={errors.image_url}
              touched={touched.image_url}
            />
          </BoxWithHeader>
        </Column>
        <Column size="7">
          <Column.Group multiline gapSize={4}>
            <Column size="7">
              <Input
                label="Service Type Name"
                id="AddServiceType_ServiceTypeName"
                name="service_type"
                value={values.service_type}
                onChange={handleChange}
                onBlur={handleBlur}
                optionalText="( Max 26 characters )"
                maxLength="26"
                required
                errors={errors.service_type}
                touched={touched.service_type}
                disableToastMessage
                disabled={!values.is_custom_service}
              />
            </Column>
            <Column size="5">
              <Select
                label="Service Type Status"
                options={options.statusOptions}
                value={[
                  {
                    value: values.status,
                    label: values.status,
                  },
                ]}
                onChange={value => setFieldValue('status', value.value)}
                disableToastMessage
                required
              />
            </Column>
            <Column size="12">
              <Input
                label="Service Type URL"
                id="AddServiceType_ServiceTypeURL"
                value={urlToOrderFood({ values })}
                disabled
                rightSideAction={
                  <LinkButton
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      if (!isCopied) {
                        copyLink(e, urlToOrderFood({ values }));
                      }
                    }}
                  >
                    {isCopied ? 'copied' : 'Copy Link'}
                  </LinkButton>
                }
              />
            </Column>
            {values.is_custom_service && (
              <>
                <Column size="4">
                  <br />
                  <CheckBox
                    label="Private Menu"
                    value={values.is_private_menu}
                    onChange={value => setFieldValue('is_private_menu', value)}
                  />
                </Column>
                <Column size="4">
                  <br />
                  <CheckBox
                    label="Display Menu Hours"
                    value={values.display_menu_hours}
                    onChange={value =>
                      setFieldValue('display_menu_hours', value)
                    }
                  />
                </Column>
                <Column size="4">
                  <br />
                  <CheckBox
                    label="Display Prices"
                    value={values.display_price}
                    onChange={value => setFieldValue('display_price', value)}
                  />
                </Column>
              </>
            )}
            <Column size="7">
              <Input
                label="Primary Contact Email"
                id="AddServiceType_PrimaryContactEmail"
                name="primaryEmail"
                value={values.primaryEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.primaryEmail}
                touched={touched.primaryEmail}
                disableToastMessage
                required
              />
            </Column>
            <Column size="5">
              <MobileInput
                name="primaryMobile"
                label="Primary Contact Phone"
                value={values.primaryMobile}
                onChange={value => setFieldValue('primaryMobile', value)}
                required
              />
              <InputErrorMessage
                errors={errors.primaryMobile}
                touched={touched.primaryMobile}
              />
            </Column>
            <Column size="full">
              {!values.is_custom_service && (
                <TagInputs
                  label="Payment Method"
                  value={intersectionBy(
                    parsedPaymentOptions,
                    values.payment_method.map(item => ({
                      label: item,
                      value: item,
                    })),
                    'value',
                  )}
                  suggestions={parsedPaymentOptions}
                  onChange={value => handlePaymentMethod(value)}
                />
              )}
            </Column>
            <Column size="4">
              {/* <Label className="is-flex">Order Online button</Label>
              <a
                style={{
                  display: 'inline-block',
                  padding: 0,
                  margin: '10px',
                }}
                href={values.slug}
                target="_blank"
              >
                <img src="https://cdn.kravein.com.au/assets/order/order-online-button.svg" />
              </a> */}
              <Select
                label="Order Online Button"
                options={orderOnlineButtonSelectionOptions}
                value={orderOnlineButtonValue}
                onChange={value => setOrderOnlineButtonValue(value)}
                disableToastMessage
                required={false}
              />
              <a
                style={{
                  cursor: 'pointer',
                  marginTop: '1rem',
                }}
                href={urlToOrderFood({ values })}
              >
                <span>
                  <img src={orderOnlineButtonValue.value} alt="order-online" />
                </span>
              </a>
            </Column>
            <Column size="8" style={{ marginTop: '0.75rem' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button
                  type="button"
                  className="is-primary"
                  onClick={e => {
                    e.stopPropagation();
                    setToggleHTML(!toggleHTMl);
                  }}
                >
                  Get Button Code
                </Button>
                <LinkButton
                  type="button"
                  style={{
                    marginTop: 'calc(0.5em - 1px)',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    copyLink(e, htmlCode.current, 'html');
                  }}
                >
                  {isCopiedHTML ? 'Copied' : 'Copy HTML Code'}
                </LinkButton>
              </div>
              <br />
              {toggleHTMl && <Textarea disabled value={htmlCode.current} />}
            </Column>
          </Column.Group>
        </Column>
      </Column.Group>
      <Column.Group multiline gapSize={4}>
        <Column size="6">
          <Label className="is-flex">
            Service Message &nbsp;
            <CheckBox
              value={values.description_enable}
              onChange={() => {
                setFieldValue('description_enable', !values.description_enable);
              }}
            />
          </Label>
        </Column>

        <Column style={{ position: 'relative', bottom: '20px' }} size="full">
          <TextArea
            // label="Service Type Description"
            // id="AddServiceType_ServiceTypeDescription"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            // optionalText=""
            errors={errors.description}
            touched={touched.description}
            disableToastMessage
          />
          {values.description && (
            <div style={{ textAlign: 'right' }}>
              ( Min 5 and Max 288 Characters ) <br />
              {values.description.length > 288 || values.description.length < 5
                ? ''
                : `Characters left ${288 - values.description.length}`}
            </div>
          )}
        </Column>
        <Column size="full">
          <CreatableSelectInputs
            label="Service Type Keywords / Tags"
            name="description"
            value={values.tag.map(item => ({ label: item, value: item }))}
            onChange={value => {
              const newValue = value.map(item => item.value);
              setFieldValue('tag', newValue);
            }}
            onBlur={handleBlur}
          />
        </Column>
      </Column.Group>
    </>
  );
};

export default withRouter(Details);
