import React from 'react';
import styled from 'styled-components';
import { Column, Divider } from 'rbx';
import { first } from 'lodash';
import ListingAudit from './ListingAudit';
import ListingUserDetails from './ListingUserDetails';

import {
  Input,
  TextArea,
  Select,
  MobileInput,
  InputErrorMessage,
} from '../../../components/elements';

const Container = styled.div`
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;
const TIME_ZONE_OPTIONS = [
  { value: '+10', label: 'AEST' },
  { value: '+8', label: 'AWST' },
  { value: '+9.5', label: 'ACST' },
  { value: '+5.5', label: 'IST' },
];

const Details = ({
  values,

  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const timeZoneLabel = first(
    TIME_ZONE_OPTIONS.filter(item => item.value === values.timezone),
  );

  return (
    <Container>
      <div className="label">Place Information</div>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input
            action={['update_place', 'action_place']}
            label="Place Name"
            id="PlaceManagement_PlaceApproval_PlaceName"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.name}
            touched={touched.name}
          />
        </Column>

        <Column size={4}>
          <Input
            label="Listing Status"
            value={values.listing_status}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Street Address"
            name="address_line_1"
            id="PlaceManagement_PlaceApproval_StreetAddress"
            value={values.address_line_1}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.address_line_1}
            touched={touched.address_line_1}
            required
          />
        </Column>
        <Column size={4}>
          <Input label="Claim Status" value={values.claim_status} disabled />
        </Column>
        <Column size="half">
          <Column.Group vcentered gapSize={2}>
            <Column size={8}>
              <Input
                label="City / Suburb"
                name="city"
                id="PlaceManagement_PlaceApproval_City"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.city}
                touched={touched.city}
                required
              />
            </Column>
            <Column size={4}>
              <Input
                label="Postcode / Pin"
                name="post_code"
                id="PlaceManagement_PlaceApproval_Postcode"
                value={values.post_code}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.post_code}
                touched={touched.post_code}
                required
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Input
            label="State"
            name="state"
            id="PlaceManagement_PlaceApproval_State"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.state}
            touched={touched.state}
          />
        </Column>
        <Column size="half">
          <Column.Group vcentered gapSize={2}>
            <Column size={8}>
              <Input
                label="Country"
                name="country"
                id="PlaceManagement_PlaceApproval_Country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.country}
                touched={touched.country}
                required
              />
            </Column>
            <Column size={4}>
              <Select
                label="TimeZone"
                options={TIME_ZONE_OPTIONS}
                value={[
                  {
                    value: values.timezone,
                    label:
                      timeZoneLabel && timeZoneLabel.length !== 0
                        ? timeZoneLabel.label
                        : 'AEST',
                  },
                ]}
                onChange={({ value }) => setFieldValue('timezone', value)}
                required
                errors={errors.timezone}
                touched={touched.timezone}
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Column.Group vcentered gapSize={2}>
            <Column size={6}>
              <Input
                label="Latitude"
                name="latitude"
                id="PlaceManagement_PlaceApproval_Latitude"
                value={values.latitude}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.latitude}
                touched={touched.latitude}
                required
              />
            </Column>
            <Column size={6}>
              <Input
                label="Longitude"
                name="longitude"
                id="PlaceManagement_PlaceApproval_Longitude"
                value={values.longitude}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.longitude}
                touched={touched.longitude}
                required
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Input
            label="Additional Address Information"
            name="address_line_2"
            id="PlaceManagement_PlaceApproval_AdditionalAddressInformation"
            value={values.address_line_2}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Column>
        <Column size="half">
          <Input
            label="Relationship to the place"
            name="relationShip"
            id="PlaceManagement_PlaceApproval_RelationshipToThePlace"
            value={values.relationShip}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.relationShip}
            errors={errors.relationShip}
            required
          />
        </Column>
        <Column size="half">
          <Input
            action={['update_place', 'action_place']}
            label="Primary Contact Email"
            name="primaryEmail"
            id="PlaceManagement_PlaceApproval_PrimaryContactEmail"
            value={values.primaryEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.primaryEmail}
            errors={errors.primaryEmail}
          />
        </Column>
        <Column size="half">
          <MobileInput
            action={['update_place', 'action_place']}
            label="Primary Contact Phone"
            name="primaryMobile"
            id="PlaceManagement_PlaceApproval_PrimaryContactPhone"
            value={values.primaryMobile}
            onChange={value => setFieldValue('primaryMobile', value)}
            onBlur={handleBlur}
          />
          <InputErrorMessage
            touched={touched.primaryMobile}
            errors={errors.primaryMobile}
          />
        </Column>
        <Column size="half">
          <Input
            action={['update_place', 'action_place']}
            label="Business Website"
            name="businessSite"
            id="PlaceManagement_PlaceApproval_BusinessWebsite"
            value={values.businessSite}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.businessSite}
            touched={touched.businessSite}
          />
        </Column>
        <Column size="half">
          <Input
            action={['update_place', 'action_place']}
            label="Business Facebook Page"
            id="PlaceManagement_PlaceApproval_BusinessFacebookPage"
            name="facebookPage"
            value={values.facebookPage}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Column>
        {/* <Column size="full">
          <TextArea
            action={['update_place', 'action_place']}
            label="Place Description"
            placeholder="Business description"
            id="PlaceManagement_PlaceApproval_PlaceDescription"
            name="description"
            optionalText="(Min 10 and Max 8000 Characters)"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.description}
            touched={touched.description}
          />
          {values.description.length > 8000 || values.description.length < 10
            ? ''
            : `Characters left ${8000 - values.description.length}`}
        </Column> */}
      </Column.Group>
      <hr />
      <TextArea
        action={['update_place', 'action_place']}
        label="Listing Verification Comments"
        placeholder="Note"
        id="Verification_Approval_Description"
        name="notes"
        value={values.notes}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.notes}
        touched={touched.notes}
      />
      <Divider />
      <ListingAudit
        values={values}
        approvedBy={values.listing_audit.listing_approved_by}
        approvedAt={values.listing_audit.listing_approved_at}
      />
      <ListingUserDetails values={values} />
    </Container>
  );
};

export default Details;
