import React, { useState, useEffect } from 'react';
import { Table, Title } from 'rbx';
import { Query } from 'react-apollo';
import { sortBy, reverse } from 'lodash';
import gql from 'graphql-tag';
import moment from 'moment';
import Header from './Header';
import Footer from './Footer';
import GlobalFooter from './GlobalFooter';
import AttendeeList from './AttendeeList';
import EditAttendeeInfo from './EditAttendeeInfo';
import client from '../../../utils/apolloClient';

import {
  Pagination,
  Table as CustomTable,
  Loading,
  Message,
} from '../../../components/elements';

const ticketsQuery = gql`
  query tickets($input: SearchInput) {
    search_event_ticket_records(input: $input) {
      total_pages
      total_size
      ticket_record_listing {
        event_id
        ticket_id
        ticket_name
        ticket_number
        ticket_status
        booking_number
        attendee {
          attendee_name
          attendee_email
          attendee_age
          attendee_gender
          attendee_phone
        }
      }
    }
  }
`;

const Summary = ({ event }) => {
  const [placeData, setPlaceData] = useState([]);
  const [sort, setSort] = useState('CREATED_DESC');
  const [attendeeSort, setAttendeeSort] = useState('AES');
  const [emailSort, setEmailSort] = useState('AES');
  const [mobileSort, setMobileSort] = useState('AES');
  const [ageSort, setAgeSort] = useState('AES');
  const [ticketCategorySort, setTicketCategorySort] = useState('AES');
  const [bookingNoSort, setBookingNoSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(12);

  const [step, setStep] = useState(1);
  const [selectedAttendee, setSelectedAttendee] = useState();
  const input = {};
  Object.assign(
    input,
    { sort },
    { from: pageFrom, size: listSize },
    {
      filter: {
        event_ticket_record_filter: { event_id: event.event_id },
      },
    },
  );

  return (
    <React.Fragment>
      <>
        {step === 2 && (
          <Title as="p" size={2} subtitle className="has-text-weight-semibold">
            Edit Attendee Info
          </Title>
        )}
      </>

      <Header
        step={step}
        goToStepTwo={() => setStep(2)}
        goToStepOne={() => setStep(1)}
        event={event}
        subHeading3={moment(event.start.start).format('LLLL')}
      />
      <hr />
      {step === 1 && totalElements > 0 && (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}

      {step === 1 && (
        <CustomTable>
          <Table.Head>
            <Table.Row>
              <Table.Heading>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setAttendeeSort(attendeeSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          attendeeSort === 'AES'
                            ? 'ATTENDEE_NAME_DESC'
                            : 'ATTENDEE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          attendeeSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Name
                  </span>
                </div>
              </Table.Heading>
              <Table.Heading>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEmailSort(emailSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          emailSort === 'AES'
                            ? 'ATTENDEE_EMAIL_DESC'
                            : 'ATTENDEE_EMAIL_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          emailSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Email
                  </span>
                </div>
              </Table.Heading>
              <Table.Heading>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setMobileSort(mobileSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          mobileSort === 'AES'
                            ? 'ATTENDEE_PHONE_DESC'
                            : 'ATTENDEE_PHONE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          mobileSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Mobile
                  </span>
                </div>
              </Table.Heading>
              <Table.Heading>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setAgeSort(ageSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          ageSort === 'AES'
                            ? 'ATTENDEE_AGE_DESC'
                            : 'ATTENDEE_AGE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          ageSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Age
                  </span>
                </div>
              </Table.Heading>
              <Table.Heading>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setTicketCategorySort(
                          ticketCategorySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          ticketCategorySort === 'AES'
                            ? 'TICKET_NAME_DESC'
                            : 'TICKET_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          ticketCategorySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Ticket Category
                  </span>
                </div>
              </Table.Heading>
              <Table.Heading>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setBookingNoSort(
                          bookingNoSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          bookingNoSort === 'AES'
                            ? 'TICKET_NUMBER_DESC'
                            : 'TICKET_NUMBER_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          bookingNoSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Booking No
                  </span>
                </div>
              </Table.Heading>
              <Table.Heading>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        const status =
                          statusSort === 'AES'
                            ? sortBy(placeData, 'status')
                            : reverse(placeData);
                        setPlaceData(status);
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                </div>
              </Table.Heading>
              <Table.Heading>
                <div>
                  <span>Action</span>
                </div>
              </Table.Heading>
            </Table.Row>
          </Table.Head>

          <Query
            client={client.clientPrivate}
            query={ticketsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.message}</Message>;
              }

              const { search_event_ticket_records } = data;
              console.log('search_event_ticket_records', data);

              return (
                <React.Fragment>
                  <View
                    placeData={placeData}
                    setPlaceData={setPlaceData}
                    search_event_ticket_records={search_event_ticket_records}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                    goToStepTwo={() => {
                      setStep(2);
                      setSelectedAttendee(
                        search_event_ticket_records.ticket_record_listing,
                      );
                    }}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </CustomTable>
      )}
      {step === 2 && (
        <EditAttendeeInfo ticket={selectedAttendee} event={event} />
      )}

      {step !== 1 && (
        <Footer
          step={step}
          goToStepTwo={() => setStep(2)}
          goToStepOne={() => setStep(1)}
        />
      )}
      {step === 1 && <GlobalFooter />}
    </React.Fragment>
  );
};

export default Summary;

const View = ({
  search_event_ticket_records,
  goToStepTwo,
  setPageCount,
  // totalPages,
  setTotalElements,
  setPlaceData,
  placeData,
}) => {
  useEffect(() => {
    setPageCount(search_event_ticket_records.total_pages);
    setTotalElements(search_event_ticket_records.total_size);
    if (search_event_ticket_records.ticket_record_listing) {
      setPlaceData(search_event_ticket_records.ticket_record_listing);
    }
  }, [search_event_ticket_records.total_pages]);
  return (
    <tbody>
      {placeData &&
        placeData.map(ticket => (
          <tr key={ticket.event_id}>
            <AttendeeList ticket={ticket} goToStepTwo={goToStepTwo} />
          </tr>
        ))}
    </tbody>
  );
};
