/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

import { MdClose } from 'react-icons/md';
import DetailTable from './Table';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const SpecialText = styled.p`
  color: ${({ theme }) => theme.coolGrey};
  font-size: 0.8rem;
`;

// const TimeWrapper = styled.div`
//   flex-direction: column;
//   display: flex;
//   justify-content: flex-start;
//   .separator {
//     padding-right: 0.4px;
//   }
// `;

const Text = styled.div`
  color: ${({ theme }) => theme.dark};
  font-size: 0.8rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: space-between;
`;

const SpecialSpacing = styled.div`
  display: flex;
  justify-content: space-between;
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const OrderDetailsForm = props => {
  const { isActive, onClose, handleSubmit, resetForm, values } = props;

  return (
    <>
      <Container
        className={`modal ${isActive && 'is-active'}`}
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <div>
              <p className="modal-card-title">Order: 6452W322ER343</p>
            </div>

            <CloseButtonWrapper>
              <MdClose
                size={30}
                type="button"
                onClick={() => {
                  onClose();

                  // resetForm();
                }}
              />
            </CloseButtonWrapper>
          </header>

          <section className="modal-card-body">
            <DetailsFormWrapper>
              <>
                <SpecialSpacing>
                  <p className="is-size-3 has-text-weight-semibold">
                    Stokehouse St Kilda
                  </p>
                </SpecialSpacing>
                <SpecialText>
                  56 Saint Kilda | St Kilda | 3182 | Austraila
                </SpecialText>
                <SpecialText>Substitute Whole Wheat Crust</SpecialText>
                <SpecialSpacing>
                  <SpecialText>Delivered on Apr 23, 2020, 11:30pm</SpecialText>
                  <p className="is-size-3 has-text-weight-semibold">Delivery</p>
                </SpecialSpacing>
                <Flex>
                  <SpecialText>Payment Method: </SpecialText>
                  <Text>Card</Text>
                </Flex>
                <br />
                <p className="is-size-4 has-text-weight-semibold">
                  Robert Williams
                </p>
                <p className="is-size-4 has-text-weight-semibold">
                  234 High Street, St Kilda 3122
                </p>
                <p className="is-size-4 has-text-weight-semibold">
                  0438 392 203
                </p>
                <br />
              </>
              <DetailTable />
              <SpecialSpacing>
                <p className="is-size-4">Items subtotal (Including GST)</p>
                <p className="is-size-4">$15.00</p>
              </SpecialSpacing>
              <br />
              <SpecialSpacing style={{ paddingBottom: '8px' }}>
                <p className="is-size-4">Promo</p>
                <p className="is-size-4">(-$5.00)</p>
              </SpecialSpacing>

              <SpecialSpacing style={{ paddingBottom: '5px' }}>
                <p className="is-size-4">Ticket fee - Absorb (Including GST)</p>
                <p className="is-size-4">(-$2.00)</p>
              </SpecialSpacing>

              <SpecialSpacing style={{ paddingBottom: '5px' }}>
                <p className="is-size-4">Ticket fee - Passon (Including GST)</p>
                <p className="is-size-4">(-$0.50)</p>
              </SpecialSpacing>

              <SpecialSpacing style={{ paddingBottom: '5px' }}>
                <p className="is-size-4">Booking fee (Including GST)</p>
                <p className="is-size-4">(-$3.50)</p>
              </SpecialSpacing>

              <SpecialSpacing style={{ paddingBottom: '5px' }}>
                <p className="is-size-4">Transaction fee (Including GST)</p>
                <p className="is-size-4">(-$0.50)</p>
              </SpecialSpacing>
              <hr />
              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">Net Payout</p>
                <p className="is-size-3 has-text-weight-semibold">$11.50</p>
              </SpecialSpacing>
              <br />
              <Flex>
                <p className="is-size-3 has-text-weight-semibold">
                  Total Tickets
                </p>
                <p className="is-size-3 has-text-weight-semibold">{'  '} - 8</p>
              </Flex>

              <br />
            </DetailsFormWrapper>
          </section>
        </div>
      </Container>
    </>
  );
};

export default OrderDetailsForm;
