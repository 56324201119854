import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Button = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 80%;
    height: 6vh;
  }
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>Payments Activity</Title>
      </Level.Item>
    </Level>

    <Level>
      <Level.Item align="left">
        <>
          <SubHeading className="is-size-3 has-text-weight-semibold">
            Dave&apos;s Spicy Pot
          </SubHeading>
        </>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <Button
            type="button"
            className="button control"
            // onClick={() => setIsActiveOrderDetailsModal(true)}
          >
            Download PDF
          </Button>
        </Level.Item>

        <Level.Item>
          <Button
            type="button"
            className="button control"
            // onClick={() => setIsActiveOrderDetailsModal(true)}
          >
            Download CSV
          </Button>
        </Level.Item>
      </Level.Item>
    </Level>
    <SubHeading className="is-size-4">St Kilda, 2182 | Australia</SubHeading>
  </Wrapper>
);

export default withRouter(Header);
