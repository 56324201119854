import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';

import AddCategoryHoursButton from './AddCategoryHoursButton';
import HoursModal from './HoursModal';
import { fetchAllCategoriesOfThePlace } from './helpers';
import WeeklyHours from './WeeklyHours';

const Container = styled.div`
  padding: 0.15rem 1rem;
`;

const TimeBasedCategory = ({
  menuName,
  user_id,
  menu_id,
  place_id,
  serviceType,
}) => {
  const [isActiveModal, setIsActiveModal] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [category, setCategory] = React.useState();
  const [existingCategory, setExistingCategory] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const fetchFunc = async () => {
    setLoading(true);
    setCategory();
    setExistingCategory();
    try {
      const c = await fetchAllCategoriesOfThePlace({
        user_id,
        place_id,
        menu_id,
      });
      if (c) setCategories((c ?? []).filter(ele => ele.section_hour));
      if (c)
        setExistingCategory([
          ...(c ?? [])
            .filter(ele => !ele.section_hour)
            .map(ele => ({ value: ele, label: ele.name })),
          { value: 'CREATE_NEW_CATEGORY', label: 'CREATE NEW CATEGORY' },
        ]);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFunc();
  }, []);
  console.log('categories...', categories);

  if (loading) {
    return (
      <Container>
        <Column.Group>
          <Column size="6">
            <AddCategoryHoursButton onClick={() => {}} />
          </Column>
        </Column.Group>
        <span style={{ color: '#00AEEF', padding: '1.5rem' }}>Loading...</span>
      </Container>
    );
  }

  return (
    <Container>
      <Column.Group>
        <Column size="6">
          {isActiveModal && (
            <HoursModal
              existingCategory={existingCategory}
              menuName={menuName}
              user_id={user_id}
              menu_id={menu_id}
              place_id={place_id}
              serviceType={serviceType}
              isActive={isActiveModal}
              onClose={f => {
                if (f) {
                  setCategory();
                  setIsActiveModal(false);
                } else {
                  setCategory();
                  setIsActiveModal(false);
                  fetchFunc();
                }
              }}
              category={category}
            />
          )}
          <AddCategoryHoursButton onClick={() => setIsActiveModal(true)} />
          {categories.length !== 0 &&
            categories.map(ele => (
              <>
                <WeeklyHours
                  hours={ele.section_hour}
                  name={ele.name}
                  onClick={() => {
                    setCategory(ele);
                    setIsActiveModal(true);
                  }}
                />
                <br />
              </>
            ))}
        </Column>
      </Column.Group>
    </Container>
  );
};

export default TimeBasedCategory;
