import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { Box, Table, Loading, Pagination } from '../elements';
import Header from './Header';
import Search from './Search';
import PlaceList from './PlaceList';
import client from '../../utils/apolloClient';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      total_pages
      total_size
      place_listing {
        place_id
        display_order
        # is_open
        listing_status
        claim_status
        featured
        event_business
        item_review_place
        status
        approval_status
        voucher_count
        default_url
        price_range
        name
        trading_name
        tagline
        description
        slug
        # stars
        review_count
        like_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        social {
          type
          logo
          description
          value
          display
        }
      }
    }
  }
`;
// has_service_type_setting
const listSize = 20;
const Places = ({
  heading,
  handleSelect,
  listingStatus,
  claimStatus,
  // hasServiceSetting,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeData, setPlaceData] = useState([]);
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [postCodeSort, setPostCodeSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');

  const [page, setPage] = useState(0);
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const [pageCount, setPageCount] = useState(0);

  const [totalElements, setTotalElements] = useState();

  const [vendorNameSearchInput, setVendorNameSearchInput] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [cityFilter, setCityFilter] = useState(null);
  const input = { user_id: userId };
  const place_filter = { status: 'ACTIVE' };
  Object.assign(
    place_filter,
    vendorNameSearchInput !== '' && { vendor_name: vendorNameSearchInput },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    cityFilter && { city: cityFilter },
    { listing_status: listingStatus },
    // { event_business: eventBusiness },
    // hasServiceSetting && { has_service_type_setting: true },
    claimStatus !== 'NONE' && { claim_status: claimStatus },
  );

  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    {
      filter: {
        place_filter,
      },
    },
  );

  return (
    <React.Fragment>
      <Header heading={heading} />
      <Box>
        <Search
          vendorNameSearchInput={vendorNameSearchInput}
          setVendorNameSearchInput={setVendorNameSearchInput}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          setCityFilter={setCityFilter}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => setPage(value.selected + 1)}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPostCodeSort(postCodeSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          postCodeSort === 'AES'
                            ? 'PLACE_POSTCODE_DESC'
                            : 'PLACE_POSTCODE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          postCodeSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Post Code
                  </span>
                </div>
              </th>
              <th>
                {' '}
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            client={client.clientPrivate}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }
              const { search_places } = data;

              return (
                <React.Fragment>
                  <View
                    placeData={placeData}
                    setPlaceData={setPlaceData}
                    search_places={search_places}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                    handleSelect={handleSelect}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </React.Fragment>
  );
};

Places.defaultProps = {
  heading: '',
  listingStatus: 'APPROVED',
  claimStatus: 'CLAIMED',
  handleSelect: () => {},
};

Places.propTypes = {
  heading: PropTypes.string,
  listingStatus: PropTypes.string,
  claimStatus: PropTypes.string,
  handleSelect: PropTypes.func,
};

export default Places;

const View = ({
  search_places,
  setPageCount,
  setTotalElements,
  setPlaceData,
  placeData,
  handleSelect,
}) => {
  useEffect(() => {
    setPageCount(search_places.total_pages);
    setTotalElements(search_places.total_size);
    if (search_places.place_listing) {
      setPlaceData(search_places.place_listing);
    }
  }, [search_places.total_pages]);
  return (
    <tbody>
      {placeData &&
        placeData.map(place => (
          <tr>
            <PlaceList handleSelect={handleSelect} place={place} />
          </tr>
        ))}
    </tbody>
  );
};
