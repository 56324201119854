import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import { omit } from 'lodash';
import {
  Input,
  Radio,
  ReactDateTimePicker,
} from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({ status, reviewOn, city, placeName, routeState, history }) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/place-reviews${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label"> Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Submitted"
              name="Type"
              value={status === 'SUBMITTED'}
              onChange={() => {
                history.push(
                  `/place-reviews${qs.stringify(
                    { status: 'SUBMITTED' },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>

          <Level.Item>
            <Radio
              label="Approved"
              name="Type"
              value={status === 'APPROVED'}
              onChange={() => {
                history.push(
                  `/place-reviews${qs.stringify({ status: 'APPROVED' }, true)}`,
                );
              }}
            />
          </Level.Item>

          <Level.Item>
            <Radio
              label="Rejected"
              name="Type"
              value={status === 'REJECTED'}
              onChange={() => {
                history.push(
                  `/place-reviews${qs.stringify({ status: 'REJECTED' }, true)}`,
                );
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              id="ReviewManagement_ReviewPlace_PlaceName"
              value={placeName}
              onChange={place => {
                handlePush({ placeName: place.target.value }, 'placeName');
              }}
              placeholder="Start entering place name..."
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={value => {
                    handlePush({ city: value.name }, 'city');
                  }}
                  onHandleClear={() => handlePush({ city: '' }, 'city')}
                  placeholder="Start entering company name..."
                />
              </div>
            </div>
          </div>

          <div className="column is-4">
            <ReactDateTimePicker
              label="Review On"
              value={reviewOn}
              onChange={value => {
                handlePush({ reviewOn: value }, 'reviewOn');
              }}
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
