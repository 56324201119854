import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-bottom: 1rem;
`;

const Header = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.lightGreyAlpha};
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  ${({ isChildren }) =>
    isChildren &&
    'border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;'}
`;
const Content = styled.div`
  padding: 1rem;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.secondaryColor};
`;

const BoxWithHeader = ({
  title,
  header,
  children,
  schedule,
  scheduleWithDelay,
  ready,
}) => {
  if (header) {
    return (
      <Container isChildren={children}>
        <Header>{header}</Header>
        {children}
      </Container>
    );
  }
  return (
    <Container>
      <Header>
        <div className="columns">
          <div className="column is-4">
            <p className="is-capitalized has-text-weight-semibold">{title}</p>
          </div>
          <div className="column">
            <p>
              {schedule}{' '}
              {scheduleWithDelay && (
                <span className="has-text-primary">{scheduleWithDelay}</span>
              )}
            </p>
          </div>
          <div className="column">
            <Text>{ready}</Text>
          </div>
        </div>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export default BoxWithHeader;
