import React from 'react';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { Label, Tab } from 'rbx';

import { Tabs, CheckBox, Radio, TextArea } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import Input from '../../../components/elements/Input';
import Select from '../../../components/elements/Select';
import Header from './Header';
import Footer from './Footer';

import {
  createNotificationTemplate,
  updateNotificationTemplate,
} from './helpers';

const Container = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 2rem 2rem 0 2rem;
`;

const FullWidthContainer = styled.div`
  width: 100%;
  padding: 2rem 2rem 0 2rem;
`;

// const statusValues = [
//   { value: 'ACTIVE', label: 'ACTIVE' },
//   { value: 'INACTIVE', label: 'INACTIVE' },
// ];

const notificationPlatfromValues = [{ value: 'SNS', label: 'SNS' }];

// const roleValues = [
//   { value: 'USER', label: 'USER' },
//   { value: 'KRAVEIN', label: 'KRAVEIN' },
//   { value: 'PARTNER', label: 'PARTNER' },
//   { value: 'RESELLER', label: 'RESELLER' },
// ];

const apiActionValues = [
  { value: 'action_menu_order', label: 'action_menu_order' },
  { value: 'create_place', label: 'create_place' },
  { value: 'table_booking_action', label: 'table_booking_action' },
  { value: 'item_image_admin', label: 'item_image_admin' },
  { value: 'place_image_admin', label: 'place_image_admin' },
  { value: 'profile_image_admin', label: 'profile_image_admin' },
  { value: 'claim_place_admin', label: 'claim_place_admin' },
  { value: 'create_place_review', label: 'create_place_review' },

  // { value: 'update_table_booking', label: 'update_table_booking' },
  // { value: 'create_table_booking', label: 'create_table_booking' },
  // { value: 'create_place_user', label: 'create_place_user' },
  // { value: 'create_place_admin', label: 'create_place_admin' },
  // { value: 'claim_place_admin', label: 'claim_place_admin' },
  // { value: 'data_correction_admin', label: 'data_correction_admin' },
  // { value: 'place_review_user', label: 'place_review_user' },
  // { value: 'place_review_admin', label: 'place_review_admin' },
  // { value: 'flag_admin', label: 'flag_admin' },
  // { value: 'place_image_admin', label: 'place_image_admin' },
  // { value: 'place_image_user', label: 'place_image_user' },
  // { value: 'password_reset_user', label: 'password_reset_user' },
  // { value: 'password_change_user', label: 'password_change_user' },
  // { value: 'place_image_admin', label: 'place_image_admin' },
  // { value: 'profile_image_admin', label: 'profile_image_admin' },
  // { value: 'contact_customer_admin', label: 'contact_customer_admin' },
  // { value: 'customer_feedback_admin', label: 'customer_feedback_admin' },
  // { value: 'item_review_admin', label: 'item_review_admin' },
  // { value: 'item_review_user', label: 'item_review_user' },
  // { value: 'flag_admin', label: 'flag_admin' },
  // { value: 'item_image_admin', label: 'item_image_admin' },
  // { value: 'order_received_partner', label: 'order_received_partner' },
  // { value: 'order_confirmed_user', label: 'order_confirmed_user' },
  // { value: 'order_cancelled', label: 'order_cancelled' },
  // { value: 'order_completed', label: 'order_completed' },
  // { value: 'table_created_partner', label: 'table_created_partner' },
  // { value: 'table_confirmed_user', label: 'table_confirmed_user' },
  // { value: 'table_cancelled_user', label: 'table_cancelled_user' },
  // { value: 'table_cancelled_partner', label: 'table_cancelled_partner' },
  // { value: 'table_waitlisted_user', label: 'table_waitlisted_user' },
];

const actionStatusValues = [
  { value: 'RECEIVED', label: 'RECEIVED' },
  { value: 'MODIFIED', label: 'MODIFIED' },
  { value: 'CANCELLED', label: 'CANCELLED' },
  { value: 'CONFIRMED', label: 'CONFIRMED' },
  { value: 'SUBMIT', label: 'SUBMIT' },
  { value: 'WAITLISTED', label: 'WAITLISTED' },
  { value: 'DRAFT', label: 'DRAFT' },
  { value: 'READY', label: 'READY' },
  { value: 'COMPLETED', label: 'COMPLETED' },
  { value: 'DELAYED', label: 'DELAYED' },
];

const Form = props => {
  const [activeTab, setActiveTab] = React.useState('tab1');
  const [userApp, setUserApp] = React.useState(true);
  const [partnerApp, setPartnerApp] = React.useState(true);

  const {
    values,
    errors,
    setFieldValue,
    history,
    isSubmitting,
    handleSubmit,
    // isUpdate,
  } = props;
  console.log(values);
  return (
    <Layout>
      <form>
        <Header
          showSubmit={activeTab === 'tab1'}
          history={history}
          loading={isSubmitting}
          handleSubmit={() => {
            if (errors.reply_to) {
              toast.error(errors.reply_to);
            } else {
              handleSubmit();
            }
          }}
        />
        <Tabs className="tabs is-medium is-fullwidth">
          <Tab
            onClick={() => {
              setActiveTab('tab1');
            }}
            active={activeTab === 'tab1'}
            style={{ cursor: activeTab === 'tab1' ? 'text' : 'pointer' }}
          >
            Notification Details
          </Tab>
        </Tabs>
        {activeTab === 'tab1' && (
          <>
            <Container>
              <div>
                <Input
                  label="Notification Title"
                  name="name"
                  id="EmailNotification__notification_title"
                  value={values.name}
                  onChange={e => setFieldValue('name', e.target.value)}
                  // optionalText="(Min 3 Characters and Max 50 Characters)"
                  errors={errors.name}
                  required
                />
                <div style={{ marginTop: '-8px' }}>
                  <span style={{ fontSize: '10px', color: 'black' }}>
                    Min 3 Characters and Max 50 Characters
                  </span>
                </div>
              </div>
              <div />
              <Select
                label="Platform"
                options={notificationPlatfromValues}
                id="EmailNotification__notificationPlatfrom"
                value={[values.notification_platform]}
                onChange={value =>
                  setFieldValue('notification_platform', value)
                }
                errors={errors.notification_platform}
                required
              />
              <div>
                <Label>Notification Channel</Label>
                <div className="is-flex">
                  <div className="mr-4">
                    <Radio
                      label="User App"
                      value={userApp}
                      onChange={value => {
                        setUserApp(value);
                      }}
                    />
                  </div>

                  <div className="mr-4">
                    <Radio
                      label="Partner App"
                      value={partnerApp}
                      onChange={value => {
                        setPartnerApp(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Container>

            <FullWidthContainer>
              <div>
                <Label>User Group</Label>
                <div className="is-flex">
                  <div className="mr-2">
                    <Radio
                      label="Workflow"
                      value={values.user_group === 'WORKFLOW'}
                      onChange={value => {
                        if (value) {
                          // pass
                        } else {
                          setFieldValue('user_group', 'WORKFLOW');
                        }
                      }}
                    />
                  </div>

                  <div className="mr-2">
                    <Radio label="All Active Users" value={false} />
                  </div>

                  <div className="mr-2">
                    <Radio label="Users By Place" value={false} />
                  </div>

                  <div className="mr-2">
                    <Radio label="Users By Location" value={false} />
                  </div>

                  <div className="mr-2">
                    <Radio label="Abandoned Cart Users" value={false} />
                  </div>

                  <div className="mr-2">
                    <Radio label="New Users" value={false} />
                  </div>

                  <div className="mr-2">
                    <Radio label="Users By Email Id" value={false} />
                  </div>
                </div>
              </div>
            </FullWidthContainer>
            {/* <Select
                label="Role"
                options={roleValues}
                id="EmailNotification__roleValues"
                value={[values.role]}
                onChange={value => setFieldValue('role', value)}
                errors={errors.role}
                required
              /> */}

            <Container>
              <Select
                label="Api action"
                options={apiActionValues}
                id="EmailNotification__apiActionValues"
                value={[values.apiAction]}
                onChange={value => setFieldValue('apiAction', value)}
                errors={errors.apiAction}
                required
              />
              <Select
                label="Action Status"
                options={actionStatusValues}
                id="EmailNotification__actionStatusValues"
                value={[values.actionStatus]}
                onChange={value => setFieldValue('actionStatus', value)}
                errors={errors.actionStatus}
                required
              />

              <div>
                <Label>Service Type</Label>
                <div className="is-flex">
                  <div className="mr-4">
                    <CheckBox
                      label="Pickup"
                      value={
                        !!values.service_type.find(ele => ele === 'Pickup')
                      }
                      onChange={() => {
                        const hasPickup = !!values.service_type.find(
                          ele => ele === 'Pickup',
                        );
                        if (hasPickup) {
                          setFieldValue(
                            'service_type',
                            values.service_type.filter(ele => ele !== 'Pickup'),
                          );
                        } else {
                          setFieldValue('service_type', [
                            ...values.service_type,
                            'Pickup',
                          ]);
                        }
                      }}
                      style={{ padding: '0 20px' }}
                    />
                  </div>
                  <div className="mr-4">
                    <CheckBox
                      label="Delivery"
                      value={
                        !!values.service_type.find(ele => ele === 'Delivery')
                      }
                      onChange={() => {
                        const hasDelivery = !!values.service_type.find(
                          ele => ele === 'Delivery',
                        );
                        if (hasDelivery) {
                          setFieldValue(
                            'service_type',

                            values.service_type.filter(
                              ele => ele !== 'Delivery',
                            ),
                          );
                        } else {
                          setFieldValue('service_type', [
                            ...values.service_type,
                            'Delivery',
                          ]);
                        }
                      }}
                      style={{ padding: '0 20px' }}
                    />
                  </div>

                  <div className="mr-4">
                    <CheckBox
                      label="Dinein"
                      value={
                        !!values.service_type.find(ele => ele === 'Dinein')
                      }
                      onChange={() => {
                        const hasDinein = !!values.service_type.find(
                          ele => ele === 'Dinein',
                        );
                        if (hasDinein) {
                          setFieldValue(
                            'service_type',
                            values.service_type.filter(ele => ele !== 'Dinein'),
                          );
                        } else {
                          setFieldValue('service_type', [
                            ...values.service_type,
                            'Dinein',
                          ]);
                        }
                      }}
                      style={{ padding: '0 20px' }}
                    />
                  </div>
                </div>
              </div>
            </Container>
            <FullWidthContainer className="mb-5">
              <TextArea
                label="Message Body"
                // id="AddServiceType_ServiceTypeDescription"
                name="Message Body"
                // value={values.description}
                // onChange={handleChange}
                // onBlur={handleBlur}
                // optionalText=""
                // errors={errors.description}
                // touched={touched.description}
                disableToastMessage
              />
            </FullWidthContainer>
          </>
        )}

        <Footer
          history={history}
          loading={isSubmitting}
          handleSubmit={handleSubmit}
          showSubmit={activeTab === 'tab1'}
        />
      </form>
    </Layout>
  );
};

const EmailNotificationForm = withFormik({
  mapPropsToValues: ({
    userId,
    name,
    status,
    notificationPlatfrom,
    role,
    apiAction,
    actionStatus,
    to,
    serviceType,
  }) => ({
    user_id: userId,
    channel: 'SMS',
    name: !isNil(name) ? name : '',
    status: !isNil(status)
      ? { value: status, label: status }
      : { value: 'ACTIVE', label: 'ACTIVE' },
    notification_platform: !isNil(notificationPlatfrom)
      ? { value: notificationPlatfrom, label: notificationPlatfrom }
      : { value: 'SNS', label: 'SNS' },
    role: !isNil(role)
      ? { value: role, label: role }
      : { value: 'USER', label: 'USER' },
    apiAction: !isNil(apiAction)
      ? { value: apiAction, label: apiAction }
      : { value: 'create_place', label: 'create_place' },
    actionStatus: !isNil(actionStatus)
      ? { value: actionStatus, label: actionStatus }
      : { value: 'SUBMITTED', label: 'SUBMITTED' },
    to: !isNil(to) ? to.join(';') : '',
    service_type:
      !isNil(serviceType) && Array.isArray(serviceType)
        ? serviceType
        : ['Pickup', 'Delivery', 'Dinein'],
    user_group: 'WORKFLOW',
  }),

  validate: values => {
    console.log(values);
    const errors = {};

    return errors;
  },

  validationSchema: yup.object().shape({}),

  handleSubmit: async (values, { props }) => {
    const nameValidationSchema = yup.object({
      value: yup
        .string()
        .min(3, 'Notification title should be at least 3 characters long')
        .max(50, 'Notification title should be less than 50 characters')
        .required('Notification title is required!'),
    });
    const data1 = { value: values.name };

    try {
      nameValidationSchema.validateSync(data1, { abortEarly: false });
    } catch (error) {
      toast.error(error.errors[0]);
      return;
    }

    const { user_id, name } = values;

    if (props.isUpdate) {
      const input = { user_id, notification_id: props.notificationId };

      if (props.notificationPlatfrom !== values.notification_platform.value) {
        Object.assign(input, {
          notification_platform: values.notification_platform.value,
        });
      }

      if (props.name !== values.name) {
        Object.assign(input, {
          name,
        });
      }

      if (props.status !== values.status.value) {
        Object.assign(input, {
          status: values.status.value,
        });
      }

      if (props.role !== values.role.value) {
        Object.assign(input, {
          user_role: values.role.value,
        });
      }

      if (props.apiAction !== values.apiAction.value) {
        Object.assign(input, {
          api_action: values.apiAction.value,
        });
      }

      if (props.actionStatus !== values.actionStatus.value) {
        Object.assign(input, {
          action_status: values.actionStatus.value,
        });
      }

      Object.assign(input, {
        to: values.to.split(';').filter(ele => ele),
      });

      Object.assign(input, {
        channel: values.channel,
      });

      Object.assign(input, {
        user_group: values.user_group,
      });

      const res = await updateNotificationTemplate({
        input,
      });

      if (res) {
        toast.error(res);
      } else {
        swal(
          'Great!',
          'You have successfully updated the email notification template',
          'success',
        ).then(() => props.history.goBack());
      }
    } else {
      const res = await createNotificationTemplate({
        input: {
          user_id,
          name,
          status: values.status.value,
          notification_platform: values.notification_platform.value,
          user_role: values.role.value,
          api_action: values.apiAction.value,
          action_status: values.actionStatus.value,
          to: values.to ? values.to.split(';').filter(ele => ele) : '',
          channel: values.channel,
          user_group: values.user_group,
        },
      });
      if (res) {
        toast.error(res);
      } else {
        swal(
          'Great!',
          'You have successfully created the email notification template',
          'success',
        ).then(() => props.history.goBack());
      }
    }
  },
  displayName: 'EmailNotificationForm',
})(Form);

export default EmailNotificationForm;
