/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React, { useRef, useState } from 'react';
import { truncate } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import Loading from 'react-loading';
import swal from 'sweetalert';

import client from '../../../../../utils/apolloClient';

import OverrideIcon from '../OverrideIcon';
import EmptyPhoto from './EmptyPhoto.svg';
import ItemOverrideModal from '../ItemOverrideModal';
import SingleImageUpload from './SingleImageUpload';

import {
  getItemDetailsFormData,
  getDataFromVariant,
  updateItemLink,
  editDispatchEnums,
} from '../helpers';
import { getLinks, transformLinks } from '../CategoriesSection/helper';

const deleteItemLinkMutation = gql`
  mutation deleteItemLink($input: [NavigateMenuItemLinkInput]) {
    delete_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

const updateItemLinkMutation = gql`
  mutation updateItemLink($input: [MenuItemLinkInput]) {
    update_menu_item_link(input: $input) {
      item_link_id
      error {
        description
      }
    }
  }
`;

const ItemCardUI = ({
  item,
  setItemDetailsFormData,
  activeItemCard,
  setActiveItemCard,
  setOverrideModalIsActive,
  setItemDetailsFormDataLoading,
  setItemsOfActiveCategory,
  itemRef,
  editMenuItems,
  overrideFn,
  itemDetailsFormData,
  editsDispatch,
  placeId,
  menuId,
  activeCategory,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const baseData = itemRef[item.item_link_id] ?? item;
  const randomRef = useRef('random string');
  const [removeOverrideIsLoading, setRemoveOverrideIsLoading] = useState(false);

  React.useEffect(
    () => () => {
      randomRef.current = null;
    },
    [],
  );

  const [deleteItemLink, { loading }] = useMutation(deleteItemLinkMutation, {
    client: client.clientPrivate,
    variables: {
      input: [{ user_id: userId, item_link_id: baseData.item_link_id }],
    },
    onCompleted: () =>
      setItemsOfActiveCategory(prev =>
        prev.filter(ele => ele.item_link_id !== baseData.item_link_id),
      ),
  });

  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      {editMenuItems && (
        <div style={{ marginTop: '40px', marginRight: '4px' }}>
          <i className="fas fa-arrows-alt" />
        </div>
      )}

      <div
        style={{
          width: '440px',
          height: '96px',
          display: 'flex',
          marginBottom: '15px',
        }}
      >
        <div
          style={{
            width: '415px',
            display: 'flex',
            height: '100%',
            border: `1px solid ${
              activeItemCard?.item_link_id === baseData.item_link_id
                ? '#00AEEF'
                : '#eeeeee'
            }`,
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={async () => {
            editsDispatch({ type: editDispatchEnums.STOP_EDIT_CATEGORIES });
            editsDispatch({ type: editDispatchEnums.STOP_EDIT_MENU_ITEMS });
            setActiveItemCard(baseData);
            setItemDetailsFormData(null);
            const data = await getItemDetailsFormData(
              baseData.menu_item_id,
              setItemDetailsFormDataLoading,
              menuId,
              activeCategory,
            );
            if (
              randomRef &&
              randomRef.current &&
              Array.isArray(data) &&
              data.length !== 0
            ) {
              setItemDetailsFormData(data);
            } else {
              setItemDetailsFormData(null);
            }
          }}
        >
          <SingleImageUpload
            s3UploadPath={`menu-item/${baseData.menu_item_id}/`}
            imageValue={baseData.image_url || EmptyPhoto}
            onChange={image => console.log(image)}
            onDelete={() => console.log('delete')}
            userId={userId}
            placeId={placeId}
            menuItemId={baseData.menu_item_id}
          />
          <div
            style={{
              width: '77%',
              height: '100%',
              paddingLeft: '6px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingRight: '6px',
            }}
          >
            <p
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '80%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#0D0D0D',
                }}
              >
                {baseData.name}
              </span>
              <span
                style={{
                  fontSize: '12px',
                  lineHeight: '20px',
                  fontWeight: '400',
                  color: '#EE2A7B',
                }}
              >
                {baseData.availability_date}
              </span>
            </p>
            {baseData.description && (
              <p
                style={{
                  fontSize: '10px',
                  fontWeight: '400',
                  color: '#737373',
                  lineHeight: '15px',
                }}
              >
                {truncate(baseData.description, { length: 131, omission: '' })}
              </p>
            )}
            <p
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  color: '#0D0D0D',
                  fontWeight: '400',
                  fontSize: '14px',
                }}
              >
                {`$${parseFloat(baseData.basePrice).toFixed(2)}`}
              </span>
              {baseData.overridePriceString && (
                <span
                  style={{
                    color: '#EE2A7B',
                    fontWeight: '400',
                    fontSize: '10px',
                  }}
                >
                  {baseData.overridePriceString}
                  <span
                    style={{
                      display: 'inline',
                      paddingLeft: '6px',
                      cursor: 'pointer',
                      position: 'relative',
                      top: '2px',
                    }}
                    onClick={async e => {
                      e.stopPropagation();
                      editsDispatch({
                        type: editDispatchEnums.STOP_EDIT_CATEGORIES,
                      });
                      editsDispatch({
                        type: editDispatchEnums.STOP_EDIT_MENU_ITEMS,
                      });

                      setRemoveOverrideIsLoading(true);
                      // setItemDetailsFormData(null);
                      setActiveItemCard(baseData);
                      swal('Would you like to remove the override fee?', {
                        buttons: ['Cancel', true],
                      }).then(async willDelete => {
                        if (willDelete) {
                          await overrideFn(baseData);
                          setRemoveOverrideIsLoading(false);
                          if (itemDetailsFormData) {
                            // setItemDetailsFormData(null);
                            const data = await getItemDetailsFormData(
                              baseData.menu_item_id,
                              setItemDetailsFormDataLoading,
                              menuId,
                              activeCategory,
                            );
                            if (
                              randomRef &&
                              randomRef.current &&
                              Array.isArray(data) &&
                              data.length !== 0
                            ) {
                              setItemDetailsFormData(data);
                            } else {
                              setItemDetailsFormData(null);
                            }
                          }
                        } else {
                          setRemoveOverrideIsLoading(false);
                        }
                      });
                    }}
                  >
                    {!removeOverrideIsLoading && <OverrideIcon />}
                    {removeOverrideIsLoading && (
                      <span
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        <Loading
                          type="spin"
                          color="#363636"
                          width="10px"
                          height="10px"
                        />
                      </span>
                    )}
                  </span>
                </span>
              )}
              <span
                style={{
                  color: '#00AEEF',
                  fontWeight: '400',
                  fontSize: '12px',
                  cursor: 'pointer',
                }}
                onClick={async e => {
                  e.stopPropagation();
                  editsDispatch({
                    type: editDispatchEnums.STOP_EDIT_CATEGORIES,
                  });
                  editsDispatch({
                    type: editDispatchEnums.STOP_EDIT_MENU_ITEMS,
                  });
                  setActiveItemCard(baseData);
                  setOverrideModalIsActive(true);
                }}
              >
                Override
              </span>
            </p>
          </div>
        </div>
        {editMenuItems && (
          <div style={{ position: 'relative', width: '25px' }}>
            <span
              style={{
                left: '15%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                padding: 0,
                cursor: 'pointer',
              }}
              onClick={() => deleteItemLink()}
            >
              {!loading && (
                <i className="far fa-trash-alt" style={{ color: '#737373' }} />
              )}
              {loading && (
                <span
                  style={{
                    display: 'inline-block',
                  }}
                >
                  <Loading
                    type="spin"
                    color="#363636"
                    width="10px"
                    height="10px"
                  />
                </span>
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ItemDetailsSection = ({
  itemsOfActiveCategory,
  setItemsOfActiveCategory,
  setItemDetailsFormData,
  userId,
  menuId,
  setItemDetailsFormDataLoading,
  activeCategory,
  editMenuItems,
  itemDetailsFormData,
  editsDispatch,
  placeId,
  itemRef,
  setItemRef,
}) => {
  const [activeItemCard, setActiveItemCard] = React.useState(null);
  const [overrideModalIsActive, setOverrideModalIsActive] = React.useState(
    false,
  );
  const [isSubmittingOverrides, setIsSubmittingOverrides] = React.useState(
    false,
  );

  const removeOverrideFn = async itemCard => {
    const { variantMergedWithOverrideData } = itemCard;
    let input;

    if (itemCard.price) {
      input = {
        price: null,
        variant: null,
        item_link_id: itemCard.item_link_id,
        menu_item_id: itemCard.menu_item_id,
        user_id: userId,
      };
    } else if (
      Array.isArray(variantMergedWithOverrideData) &&
      variantMergedWithOverrideData[0]
    ) {
      const variantWithoutOverrideData = variantMergedWithOverrideData;
      const transformedVariants = variantMergedWithOverrideData[0].variants.map(
        ele => {
          const newVariant = { ...ele };
          newVariant.variant_price = null;
          return newVariant;
        },
      );
      variantWithoutOverrideData[0].variants = transformedVariants;

      input = {
        price: null,
        variant: variantWithoutOverrideData,
        item_link_id: itemCard.item_link_id,
        menu_item_id: itemCard.menu_item_id,
        user_id: userId,
      };
    }
    let linkId;
    try {
      linkId = await updateItemLink({ menuItemLinkInput: input });
    } catch (error) {
      console.log(error);
    }
    try {
      let links = await getLinks({ menuId });
      links =
        Array.isArray(links) && links.length !== 0
          ? links.filter(
              lnk =>
                lnk.menu_section.menu_section_id === itemCard.menu_section_id,
            )
          : [];

      if (links.length !== 0) {
        const newItemFromLink = transformLinks(links).find(
          ele => ele.item_link_id === linkId,
        );

        setItemRef(prev => {
          const temp = { ...prev };
          temp[newItemFromLink.item_link_id] = newItemFromLink;
          return temp;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [updateMenuItem] = useMutation(updateItemLinkMutation, {
    client: client.clientPrivate,
    onCompleted: () => {},
  });

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      itemsOfActiveCategory,
      result.source.index,
      result.destination.index,
    );
    updateMenuItem({
      variables: {
        input: newItems.map((item, index) => ({
          user_id: userId,
          menu_item_id: item.menu_item_id,
          item_link_id: item.item_link_id,
          display_order: index,
        })),
      },
    });
    setItemsOfActiveCategory(newItems);
  };

  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={() => setItemDetailsFormData(null)}
      >
        <Droppable droppableId="droppable">
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ marginTop: '15px' }}
            >
              {itemsOfActiveCategory.map((item, index) => (
                <Draggable
                  key={`${item.item_link_id}${item.menu_item_id}`}
                  draggableId={item.item_link_id}
                  index={index}
                  isDragDisabled={!editMenuItems}
                >
                  {provided2 => (
                    <div
                      ref={provided2.innerRef}
                      {...provided2.draggableProps}
                      {...provided2.dragHandleProps}
                    >
                      <ItemCardUI
                        placeId={placeId}
                        activeCategory={activeCategory}
                        menuId={menuId}
                        key={`${item.item_link_id}${item.menu_item_id}`}
                        setItemDetailsFormData={setItemDetailsFormData}
                        item={item}
                        itemRef={itemRef}
                        activeItemCard={activeItemCard}
                        setActiveItemCard={setActiveItemCard}
                        setOverrideModalIsActive={setOverrideModalIsActive}
                        setItemDetailsFormDataLoading={
                          setItemDetailsFormDataLoading
                        }
                        setItemsOfActiveCategory={setItemsOfActiveCategory}
                        editMenuItems={editMenuItems}
                        overrideFn={removeOverrideFn}
                        itemDetailsFormData={itemDetailsFormData}
                        editsDispatch={editsDispatch}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {activeItemCard && overrideModalIsActive && (
        <ItemOverrideModal
          name={`${activeItemCard.name}`}
          variants={getDataFromVariant(
            activeItemCard.variantMergedWithOverrideData,
          )}
          itemVariant={activeItemCard.menuItemVariant ?? []}
          basePrice={activeItemCard.basePrice}
          price={activeItemCard.price}
          loading={isSubmittingOverrides}
          isActive={overrideModalIsActive}
          onClose={() => setOverrideModalIsActive(false)}
          onSubmit={async input => {
            setIsSubmittingOverrides(true);
            let variant = null;
            if (input.variants && input.variants.length !== 0) {
              variant = {
                prompt_id: activeItemCard.menuItemVariant[0].prompt_id,
                prompt: activeItemCard.menuItemVariant[0].prompt,
                variants: input.variants,
              };
            }

            const menuItemLinkInput = {
              price: input.price,
              variant,
              item_link_id: activeItemCard.item_link_id,
              menu_item_id: activeItemCard.menu_item_id,
              user_id: userId,
            };

            let linkId;

            try {
              linkId = await updateItemLink({ menuItemLinkInput });
            } catch (error) {
              console.log(error);
            }
            try {
              let links = await getLinks({ menuId });
              links =
                Array.isArray(links) && links.length !== 0
                  ? links.filter(
                      lnk =>
                        lnk.menu_section.menu_section_id ===
                        activeItemCard.menu_section_id,
                    )
                  : [];

              if (links.length !== 0) {
                const newItemFromLink = transformLinks(links).find(
                  ele => ele.item_link_id === linkId,
                );

                setItemRef(prev => {
                  const temp = { ...prev };
                  temp[newItemFromLink.item_link_id] = newItemFromLink;
                  return temp;
                });
              }
            } catch (error) {
              console.log(error);
            }
            setIsSubmittingOverrides(false);
            setOverrideModalIsActive(false);

            if (itemDetailsFormData) {
              // setItemDetailsFormData(null);
              const data = await getItemDetailsFormData(
                activeItemCard.menu_item_id,
                setItemDetailsFormDataLoading,
                menuId,
                activeCategory,
              );

              if (Array.isArray(data) && data.length !== 0) {
                setItemDetailsFormData(data);
              } else {
                setItemDetailsFormData(null);
              }
            }
          }}
        />
      )}
    </>
  );
};

export default ItemDetailsSection;
