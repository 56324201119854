import React, { useState } from 'react';
import styled from 'styled-components';
import { Title } from 'rbx';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import uuid from 'uuid';
import swal from 'sweetalert';
import omit from 'lodash/omit';

import { Box, Message } from '../../../components/elements';
import client from '../../../utils/apolloClient';
import { cognitoSignUp } from '../../../utils/auth';
import Layout from '../../../components/global/Layout';
import Form from './Form';

const Container = styled.div`
  padding: 1rem;
  padding-bottom: 10rem;
`;

const HeaderWrapper = styled.div`
  background-color: #fff;
  padding: 1.5rem 1rem;
`;

export const updateMutation = gql`
  mutation updateUser($input: UserInput) {
    update_user(input: $input) {
      user_id
      error {
        description
      }
    }
  }
`;

const View = ({ history }) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateUser, { error: updateError }] = useMutation(updateMutation, {
    client: client.clientPrivate,
  });

  return (
    <React.Fragment>
      <HeaderWrapper>
        <Title size={1} className="is-capitalized">
          Add New User
        </Title>
      </HeaderWrapper>
      {updateError && <Message>{updateError.message}</Message>}
      <Box>
        <Container>
          <Form
            loading={updateLoading}
            onSubmit={async (inputs, resetForm) => {
              setUpdateLoading(true);
              try {
                const userData = await cognitoSignUp(
                  inputs.first_name,
                  inputs.last_name,
                  inputs.display_name,
                  inputs.user_id,
                  inputs.email.toLowerCase(),
                  uuid(),
                );
                if (userData) {
                  updateUser({
                    variables: {
                      input: {
                        user_id: userData.username,
                        ...omit(inputs, ['email']),
                      },
                    },
                  });
                  swal('User created successfully!', '', 'success').then(() => {
                    setUpdateLoading(false);
                    history.goBack();
                    resetForm();
                  });
                } else {
                  setUpdateLoading(false);
                }
              } catch (err) {
                swal(
                  'Failed to create user.',
                  'Please try again!',
                  'error',
                ).then(() => {
                  setUpdateLoading(false);
                  resetForm();
                  history.go(0);
                });
                console.log(err);
                setUpdateLoading(false);
              }
            }}
          />
        </Container>
      </Box>
    </React.Fragment>
  );
};

const AddUser = props => (
  <Layout>
    <View {...props} />
  </Layout>
);

export default AddUser;
