import React from 'react';
import DelayOrderForm from './DelayOrderForm';

const Orders = ({
  order,
  isActiveDelayOrderModal,
  setActiveDelayOrderModal,
  handleDelayOrder,
}) => (
  <DelayOrderForm
    order={order}
    handleDelayOrder={handleDelayOrder}
    isActive={isActiveDelayOrderModal}
    onClose={() => setActiveDelayOrderModal(false)}
  />
);
export default Orders;
