import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import { Button, Level } from 'rbx';
import { concat, range, first, isNull, forEach, capitalize } from 'lodash';
import moment from 'moment';
import { CustomSelect, Table } from '../../../components/elements';
import ApplyVoucherForm from './ApplyVoucherForm';

const Wrapper = styled.div`
  &&& {
    .select select:not([multiple]) {
      padding-right: 4.5em;
    }
    .table-container:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;
const LevelWrapper = styled.div`
  padding: 0rem 1rem;
`;

const Flex = styled.div`
  padding-left: 1rem;
`;

const StatusWrapper = styled.span`
  background: #c7c6c6;
  padding: 6px 17px;
  width: auto;
`;

const StatusQuantityHandler = ({
  ticket,
  ticketBookingFee,
  ticketSelections,
  handleTicketSelection,
  remainingTickets,
  isCouponApplied,
}) => {
  const filterTicketSelection = ticketSelections.filter(
    item => item.id === ticket.ticket_id,
  );
  const quantity =
    filterTicketSelection.length !== 0
      ? first(filterTicketSelection).quantity
      : 0;
  const [noOfSelectedTicket, setNoOfSelectedTicket] = useState(quantity);

  const rangeOfSelectOptions = range(
    parseInt(ticket.minimum, 10),
    parseInt(ticket.maximum, 10) <= remainingTickets
      ? parseInt(ticket.maximum + 1, 10)
      : remainingTickets + 1,
  );

  const finalOptionRange = concat([0], rangeOfSelectOptions);
  const selectOptions = finalOptionRange.map(item => ({
    value: item,
    label: item,
  }));

  if (remainingTickets <= 0) {
    return <StatusWrapper>SOLD OUT</StatusWrapper>;
  }
  // if (moment(ticket.sale_start).isAfter(new Date())) {
  //   return <StatusWrapper>SALE NOT STARTED</StatusWrapper>;
  // }

  if (moment(ticket.sale_end).isBefore(new Date())) {
    return <StatusWrapper>SALES ENDED</StatusWrapper>;
  }

  return (
    <React.Fragment>
      <CustomSelect
        value={parseInt(noOfSelectedTicket, 10)}
        options={selectOptions}
        onChange={event => {
          const { value } = event.target;
          const ticketInput = {
            id: ticket.ticket_id,
            name: ticket.name,
            type: ticket.type,
            price: ticket.price,
            totalTicketPrice: parseFloat(ticket.price) * parseFloat(value),
            quantity: parseInt(value, 10),
            ticketBookingFee,
            attributes: ticket.attribute,
          };

          handleTicketSelection(ticketInput);
          setNoOfSelectedTicket(parseInt(value, 10));
        }}
        disabled={isCouponApplied}
      />
      <span className="is-size-5 has-text-grey">
        {remainingTickets - noOfSelectedTicket} Remaining
      </span>
    </React.Fragment>
  );

  // return <StatusWrapper>{ticket.status}</StatusWrapper>;
};

const TicketSelection = ({
  occurrence,
  event,
  tickets,
  subTotalPrice,
  setSubTotalPrice,
  totalQuantity,
  setTotalQuantity,
  ticketSelections,
  setTicketSelections,
  bookingFee,
  setBookingFee,
  discount,
  setDiscount,
  isCouponApplied,
  setIsCouponApplied,
  setCouponCode,
  onHandleConfirmBooking,
  eventBookingFee,
  bookingFeeOfEvent,
  setBookingFeeOfEvent,
  setCheckSelectionHaveAttendeeDetailsOrNot,
  checkSelectionHaveAttendeeDetailsOrNot,
  loading,
  back,
}) => {
  const [applyCouponLoading, handleApplyCouponLoading] = useState(false);
  const [isAppearCouponUI, setIsAppearCouponUI] = useState(false);
  const { userId } = useStoreState(state => state.auth);

  const handleTicketSelection = async ticket => {
    let newTickets = [];
    const removeExisted = await ticketSelections.filter(
      item => item.id !== ticket.id,
    );
    newTickets =
      ticket.quantity !== 0 ? removeExisted.concat(ticket) : removeExisted;
    await setTicketSelections(newTickets);
    let newPrice = 0;
    await newTickets.map(item => {
      newPrice += item.totalTicketPrice;
      return {};
    });
    let newQuantity = 0;
    await newTickets.map(item => {
      newQuantity += parseFloat(item.quantity);
      return {};
    });
    await setSubTotalPrice(newPrice);
    await setTotalQuantity(newQuantity);
    let newBookingFee = 0;
    await newTickets.map(item => {
      newBookingFee += item.ticketBookingFee * item.quantity;
      return {};
    });

    setBookingFee(newBookingFee);
    const checkingFreeOnlyOrPaidWithFee = [];
    await forEach(newTickets, item => {
      if (item.type === 'paid') {
        checkingFreeOnlyOrPaidWithFee.push(item);
      }
    });
    if (checkingFreeOnlyOrPaidWithFee.length === 0) {
      setBookingFeeOfEvent(0);
      setIsAppearCouponUI(false);
    } else {
      setBookingFeeOfEvent(eventBookingFee);
      setIsAppearCouponUI(true);
    }

    forEach(newTickets, item => {
      if (item.attributes.length !== 0) {
        setCheckSelectionHaveAttendeeDetailsOrNot(true);
      }
    });
  };

  return (
    <Wrapper>
      <Table>
        <thead>
          <tr>
            <th>Booking Type</th>
            <th>Price</th>
            <th>Sold</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map(ticket => {
            const checkBookingFee = ticket.fee.filter(
              item => item.name === 'offline_passon_fee',
            );
            const ticketBookingFee =
              checkBookingFee.length !== 0 ? first(checkBookingFee).value : 0;

            return (
              <tr key={ticket.ticket_id}>
                <td>
                  <p className="has-text-weight-semibold is-capitalized">
                    {ticket.name}
                  </p>
                  <p>
                    Purchase between {ticket.minimum} and {ticket.maximum}{' '}
                    ticket(s)
                  </p>
                  <p>
                    {capitalize(ticket.sales_method)} ticket status:{' '}
                    <span className="has-text-weight-semibold is-capitalized">
                      {ticket.status === 'sold_out' ||
                      ticket.status === 'SOLD_OUT'
                        ? 'SOLD OUT'
                        : ticket.status}
                    </span>
                  </p>
                </td>
                <td>
                  <p>
                    ${parseFloat(ticket.price).toFixed(2)}{' '}
                    {ticketBookingFee !== 0 && `(+ $${ticketBookingFee} FEE)`}
                  </p>
                  {moment(ticket.sale_start).isAfter(new Date()) && (
                    <p>
                      Online Sale start date{' '}
                      {moment(event.start).format('dddd')},
                      <br />
                      {moment(event.start).format('LL')}
                    </p>
                  )}
                </td>
                <td>
                  {isNull(ticket.sold) ? 0 : ticket.sold}/{ticket.quantity}
                </td>
                <td>
                  <StatusQuantityHandler
                    ticket={ticket}
                    ticketSelections={ticketSelections}
                    handleTicketSelection={handleTicketSelection}
                    ticketBookingFee={ticketBookingFee}
                    remainingTickets={ticket.quantity - ticket.sold}
                    isCouponApplied={isCouponApplied}
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <td />
            <td />
            <td />
            <td />
          </tr>
        </tbody>
      </Table>
      <Flex className="columns">
        <div className="column is-5">
          {isAppearCouponUI && (
            <React.Fragment>
              {totalQuantity !== 0 && (
                <ApplyVoucherForm
                  userId={userId}
                  isCouponApplied={isCouponApplied}
                  handleDisCount={(discountValue, couponCode) => {
                    setDiscount(discountValue);
                    setIsCouponApplied(true);
                    setCouponCode(couponCode);
                    handleApplyCouponLoading(false);
                  }}
                  removeDiscountCoupon={() => {
                    setDiscount(0);
                    setIsCouponApplied(false);
                  }}
                  handleOverrideDiscount={discountValue => {
                    setDiscount(discountValue);
                    setIsCouponApplied(true);
                    handleApplyCouponLoading(false);
                  }}
                  setDiscount={setDiscount}
                  applyCouponLoading={applyCouponLoading}
                  handleApplyCouponLoading={handleApplyCouponLoading}
                  subTotalPrice={subTotalPrice}
                  eventId={event.event_id}
                  eventOccurrenceId={occurrence.event_occurrence_id}
                  placeId={event.place_id}
                  tickets={ticketSelections}
                />
              )}
            </React.Fragment>
          )}
        </div>
        <div className="has-text-weight-semibold flex-item column is-4">
          {totalQuantity !== 0 && (
            <React.Fragment>Total Quantity : {totalQuantity}</React.Fragment>
          )}
        </div>
        <div className="has-text-weight-semibold flex-item column is-3">
          {totalQuantity !== 0 && (
            <React.Fragment>
              <span>Sub Total : ${parseFloat(subTotalPrice).toFixed(2)}</span>
              <br />
              <span>Ticket Fee : ${parseFloat(bookingFee).toFixed(2)}</span>
              <br />
              <span>
                Booking Fee : ${parseFloat(bookingFeeOfEvent).toFixed(2)}
              </span>
              <br />
              {discount !== 0 && (
                <span>
                  Voucher Discount : ${parseFloat(discount).toFixed(2)}
                </span>
              )}

              <br />
              <span>
                Order Total : $
                {parseFloat(
                  subTotalPrice + bookingFee - discount + bookingFeeOfEvent,
                ).toFixed(2)}
              </span>
              <br />
              <br />
              <Button
                color={`primary ${loading && 'is-loading'}`}
                onClick={onHandleConfirmBooking}
              >
                {checkSelectionHaveAttendeeDetailsOrNot
                  ? 'Confirm Booking'
                  : 'Checkout'}
              </Button>
            </React.Fragment>
          )}
        </div>
      </Flex>
      {totalQuantity !== 0 && <hr />}
      <LevelWrapper>
        <Level>
          <Level.Item align="left">
            <Level.Item>
              <button className="button is-light" onClick={back}>
                <span>Back</span>
              </button>
            </Level.Item>
          </Level.Item>
        </Level>
      </LevelWrapper>
    </Wrapper>
  );
};

export default TicketSelection;
