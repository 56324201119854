import React from 'react';
import { Column } from 'rbx';
import { FieldArray } from 'formik';

import ImageView from './ImageView';
import { getEncodedImage } from '../../../../utils/s3';

const PhotoReviews = ({ images, setFieldValue }) => (
  <Column.Group vcentered multiline gapSize={8}>
    <FieldArray
      name="images"
      render={() => (
        <>
          {images.map((item, index) => (
            <Column size={3} key={item.image_id}>
              <ImageView
                isRejected={false}
                imageSrc={getEncodedImage(item.url, 300, 300)}
                status={item.status}
                approvalStatus={item.approval_status}
                handleApprovalStatus={value =>
                  setFieldValue(`images.${index}.approval_status`, value)
                }
              />
            </Column>
          ))}
        </>
      )}
    />
  </Column.Group>
);

export default PhotoReviews;
