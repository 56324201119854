import React from 'react';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { Label, Tab } from 'rbx';

import { Tabs, CheckBox } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import Input from '../../../components/elements/Input';
import Select from '../../../components/elements/Select';
import Header from './Header';
import Footer from './Footer';

import {
  createNotificationTemplate,
  updateNotificationTemplate,
} from './helpers';

import TemplateBody from './TemplateBody';
import TestEmailTemplate from './TestEmailTemplate';

const Container = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 2rem;
`;

const statusValues = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const notificationPlatfromValues = [
  { value: 'SES', label: 'SES' },
  { value: 'SENDGRID', label: 'SENDGRID' },
];

const roleValues = [
  { value: 'ADMIN', label: 'ADMIN' },
  { value: 'KRAVEIN', label: 'KRAVEIN' },
  { value: 'PARTNER', label: 'PARTNER' },
  { value: 'USER', label: 'USER' },
  { value: 'RESELLER', label: 'RESELLER' },
];

const apiActionValues = [
  { value: 'action_menu_order', label: 'action_menu_order' },
  { value: 'create_place', label: 'create_place' },
  { value: 'create_place_review', label: 'create_place_review' },
  { value: 'table_booking_action', label: 'table_booking_action' },
  { value: 'item_image_admin', label: 'item_image_admin' },
  { value: 'place_image_admin', label: 'place_image_admin' },
  { value: 'profile_image_admin', label: 'profile_image_admin' },
  { value: 'claim_place_admin', label: 'claim_place_admin' },
  { value: 'google_opt_in', label: 'google_opt_in' },
  { value: 'google_opt_out', label: 'google_opt_out' },
  { value: 'create_flag', label: 'create_flag' },
  { value: 'item_review_admin', label: 'item_review_admin' },
  { value: 'item_review_user', label: 'item_review_user' },
  { value: 'create_item', label: 'create_item' },
  { value: 'customer_feedback_admin', label: 'customer_feedback_admin' },
  { value: 'contact_customer_admin', label: 'contact_customer_admin' },
  { value: 'data_correction_admin', label: 'data_correction_admin' },
  {
    value: 'place_errors_suggestions_admin',
    label: 'place_errors_suggestions_admin',
  },
  { value: 'contact_customer_admin', label: 'contact_customer_admin' },
  { value: 'customer_feedback_admin', label: 'customer_feedback_admin' },
  { value: 'profile_image_admin', label: 'profile_image_admin' },
  { value: 'flag_admin', label: 'flag_admin' },

  // { value: 'update_table_booking', label: 'update_table_booking' },
  // { value: 'create_table_booking', label: 'create_table_booking' },
  // { value: 'create_place_user', label: 'create_place_user' },
  // { value: 'create_place_admin', label: 'create_place_admin' },
  // { value: 'claim_place_admin', label: 'claim_place_admin' },
  // { value: 'data_correction_admin', label: 'data_correction_admin' },
  // { value: 'place_review_user', label: 'place_review_user' },
  // { value: 'place_review_admin', label: 'place_review_admin' },
  // { value: 'flag_admin', label: 'flag_admin' },
  // { value: 'place_image_admin', label: 'place_image_admin' },
  // { value: 'place_image_user', label: 'place_image_user' },
  // { value: 'password_reset_user', label: 'password_reset_user' },
  // { value: 'password_change_user', label: 'password_change_user' },
  // { value: 'place_image_admin', label: 'place_image_admin' },
  // { value: 'profile_image_admin', label: 'profile_image_admin' },
  // { value: 'contact_customer_admin', label: 'contact_customer_admin' },
  // { value: 'customer_feedback_admin', label: 'customer_feedback_admin' },
  // { value: 'item_review_admin', label: 'item_review_admin' },
  // { value: 'item_review_user', label: 'item_review_user' },
  // { value: 'flag_admin', label: 'flag_admin' },
  // { value: 'item_image_admin', label: 'item_image_admin' },
  // { value: 'order_received_partner', label: 'order_received_partner' },
  // { value: 'order_confirmed_user', label: 'order_confirmed_user' },
  // { value: 'order_cancelled', label: 'order_cancelled' },
  // { value: 'order_completed', label: 'order_completed' },
  // { value: 'table_created_partner', label: 'table_created_partner' },
  // { value: 'table_confirmed_user', label: 'table_confirmed_user' },
  // { value: 'table_cancelled_user', label: 'table_cancelled_user' },
  // { value: 'table_cancelled_partner', label: 'table_cancelled_partner' },
  // { value: 'table_waitlisted_user', label: 'table_waitlisted_user' },
];

const actionStatusValues = [
  { value: 'RECEIVED', label: 'RECEIVED' },
  { value: 'MODIFIED', label: 'MODIFIED' },
  { value: 'CANCELLED', label: 'CANCELLED' },
  { value: 'CONFIRMED', label: 'CONFIRMED' },
  { value: 'SUBMITTED', label: 'SUBMITTED' },
  { value: 'WAITLISTED', label: 'WAITLISTED' },
  { value: 'DRAFT', label: 'DRAFT' },
  { value: 'READY', label: 'READY' },
  { value: 'COMPLETE', label: 'COMPLETE' },
  { value: 'DELAYED', label: 'DELAYED' },
];

const Form = props => {
  const [activeTab, setActiveTab] = React.useState('tab1');
  const {
    values,
    errors,
    setFieldValue,
    history,
    isSubmitting,
    handleSubmit,
    isUpdate,
  } = props;
  console.log(values);
  return (
    <Layout>
      <form>
        <Header
          showSubmit={activeTab === 'tab1'}
          history={history}
          loading={isSubmitting}
          handleSubmit={() => {
            if (errors.reply_to) {
              toast.error(errors.reply_to);
            } else {
              handleSubmit();
            }
          }}
        />
        <Tabs className="tabs is-medium is-fullwidth">
          <Tab
            onClick={() => {
              setActiveTab('tab1');
            }}
            active={activeTab === 'tab1'}
            style={{ cursor: activeTab === 'tab1' ? 'text' : 'pointer' }}
          >
            Details
          </Tab>
          {isUpdate && values.notification_platform.value === 'SES' && (
            <>
              <Tab
                onClick={() => {
                  setActiveTab('tab2');
                }}
                active={activeTab === 'tab2'}
                style={{ cursor: activeTab === 'tab2' ? 'text' : 'pointer' }}
              >
                Template Body
              </Tab>
              <Tab
                onClick={() => {
                  setActiveTab('tab3');
                }}
                active={activeTab === 'tab3'}
                style={{ cursor: activeTab === 'tab3' ? 'text' : 'pointer' }}
              >
                Test Email Template
              </Tab>
            </>
          )}
        </Tabs>
        {activeTab === 'tab1' && (
          <>
            <Container>
              <div>
                <Input
                  label="Notification Title"
                  name="name"
                  id="EmailNotification__notification_title"
                  value={values.name}
                  onChange={e => setFieldValue('name', e.target.value)}
                  // optionalText="(Min 3 Characters and Max 50 Characters)"
                  errors={errors.name}
                  required
                />
                <div style={{ marginTop: '-8px' }}>
                  <span style={{ fontSize: '10px', color: 'black' }}>
                    Min 3 Characters and Max 50 Characters
                  </span>
                </div>
              </div>
              <Select
                label="Status"
                options={statusValues}
                id="EmailNotification__status"
                value={[values.status]}
                onChange={value => setFieldValue('status', value)}
                errors={errors.status}
                required
              />
              <Select
                label="Platform"
                options={notificationPlatfromValues}
                id="EmailNotification__notificationPlatfrom"
                value={[values.notification_platform]}
                onChange={value =>
                  setFieldValue('notification_platform', value)
                }
                errors={errors.notification_platform}
                required
              />
              <Input
                label="Notification Template ID"
                name="notification_template_id"
                id="EmailNotification__templateId"
                value={values.notification_template_id}
                onChange={e =>
                  setFieldValue('notification_template_id', e.target.value)
                }
                errors={errors.notification_template_id}
                required
              />
              <Select
                label="Role"
                options={roleValues}
                id="EmailNotification__roleValues"
                value={[values.role]}
                onChange={value => setFieldValue('role', value)}
                errors={errors.role}
                required
              />
              <Select
                label="Api action"
                options={apiActionValues}
                id="EmailNotification__apiActionValues"
                value={[values.apiAction]}
                onChange={value => setFieldValue('apiAction', value)}
                errors={errors.apiAction}
                required
              />
              <Select
                label="Action Status"
                options={actionStatusValues}
                id="EmailNotification__actionStatusValues"
                value={[values.actionStatus]}
                onChange={value => setFieldValue('actionStatus', value)}
                errors={errors.actionStatus}
                required
              />
              <Input
                label="Sent to Email ID"
                name="to"
                id="EmailNotification__to"
                value={values.to}
                onChange={e => setFieldValue('to', e.target.value)}
                errors={errors.to}
              />
              <Input
                label="Reply to Email IDs"
                name="reply_to"
                id="EmailNotification__reply_to"
                value={values.reply_to}
                onChange={e => setFieldValue('reply_to', e.target.value)}
                errors={errors.reply_to}
              />
              <Input
                label="Copy to Email IDs"
                name="cc"
                id="EmailNotification__cc"
                value={values.cc}
                onChange={e => setFieldValue('cc', e.target.value)}
                errors={errors.cc}
              />
              <Input
                label="BCC to Email IDs"
                name="bcc"
                id="EmailNotification__bcc"
                value={values.bcc}
                onChange={e => setFieldValue('bcc', e.target.value)}
                errors={errors.bcc}
              />
              {values.apiAction.value === 'action_menu_order' && (
                <div>
                  <Label>Service Type</Label>
                  <div style={{ display: 'flex' }}>
                    <CheckBox
                      label="Pickup"
                      value={
                        !!values.service_type.find(ele => ele === 'Pickup')
                      }
                      onChange={() => {
                        const hasPickup = !!values.service_type.find(
                          ele => ele === 'Pickup',
                        );
                        if (hasPickup) {
                          setFieldValue(
                            'service_type',
                            values.service_type.filter(ele => ele !== 'Pickup'),
                          );
                        } else {
                          setFieldValue('service_type', [
                            ...values.service_type,
                            'Pickup',
                          ]);
                        }
                      }}
                      style={{ padding: '0 20px' }}
                    />
                    &nbsp;
                    <CheckBox
                      label="Delivery"
                      value={
                        !!values.service_type.find(ele => ele === 'Delivery')
                      }
                      onChange={() => {
                        const hasDelivery = !!values.service_type.find(
                          ele => ele === 'Delivery',
                        );
                        if (hasDelivery) {
                          setFieldValue(
                            'service_type',

                            values.service_type.filter(
                              ele => ele !== 'Delivery',
                            ),
                          );
                        } else {
                          setFieldValue('service_type', [
                            ...values.service_type,
                            'Delivery',
                          ]);
                        }
                      }}
                      style={{ padding: '0 20px' }}
                    />
                    &nbsp;
                    <CheckBox
                      label="Dinein"
                      value={
                        !!values.service_type.find(ele => ele === 'Dinein')
                      }
                      onChange={() => {
                        const hasDinein = !!values.service_type.find(
                          ele => ele === 'Dinein',
                        );
                        if (hasDinein) {
                          setFieldValue(
                            'service_type',
                            values.service_type.filter(ele => ele !== 'Dinein'),
                          );
                        } else {
                          setFieldValue('service_type', [
                            ...values.service_type,
                            'Dinein',
                          ]);
                        }
                      }}
                      style={{ padding: '0 20px' }}
                    />
                  </div>
                </div>
              )}
            </Container>
          </>
        )}

        {activeTab === 'tab2' && (
          <TemplateBody
            notificationTemplateId={values.notification_template_id}
          />
        )}

        {activeTab === 'tab3' && (
          <TestEmailTemplate
            notificationTemplateId={values.notification_template_id}
          />
        )}

        <Footer
          history={history}
          loading={isSubmitting}
          handleSubmit={handleSubmit}
          showSubmit={activeTab === 'tab1'}
        />
      </form>
    </Layout>
  );
};

const EmailNotificationForm = withFormik({
  mapPropsToValues: ({
    userId,
    notificationTemplateId,
    name,
    status,
    notificationPlatfrom,
    role,
    apiAction,
    actionStatus,
    bcc,
    cc,
    to,
    replyTo,
    serviceType,
  }) => ({
    user_id: userId,
    notification_template_id: !isNil(notificationTemplateId)
      ? notificationTemplateId
      : '',
    name: !isNil(name) ? name : '',
    status: !isNil(status)
      ? { value: status, label: status }
      : { value: 'ACTIVE', label: 'ACTIVE' },
    notification_platform: !isNil(notificationPlatfrom)
      ? { value: notificationPlatfrom, label: notificationPlatfrom }
      : { value: 'SES', label: 'SES' },
    role: !isNil(role)
      ? { value: role, label: role }
      : { value: 'USER', label: 'USER' },
    apiAction: !isNil(apiAction)
      ? { value: apiAction, label: apiAction }
      : { value: 'create_place', label: 'create_place' },
    actionStatus: !isNil(actionStatus)
      ? { value: actionStatus, label: actionStatus }
      : { value: 'SUBMITTED', label: 'SUBMITTED' },
    bcc: !isNil(bcc) ? bcc.join(';') : '',
    cc: !isNil(cc) ? cc.join(';') : '',
    to: !isNil(to) ? to.join(';') : '',
    reply_to: !isNil(replyTo) ? replyTo : '',
    service_type:
      !isNil(serviceType) && Array.isArray(serviceType)
        ? serviceType
        : ['Pickup', 'Delivery', 'Dinein'],
  }),

  validate: values => {
    console.log(values);
    const errors = {};

    return errors;
  },

  validationSchema: yup.object().shape({
    reply_to: yup.string().email('Please provide valid reply to email'),
  }),

  handleSubmit: async (values, { props }) => {
    const nameValidationSchema = yup.object({
      value: yup
        .string()
        .min(3, 'Notification title should be at least 3 characters long')
        .max(50, 'Notification title should be less than 50 characters')
        .required('Notification title is required!'),
    });
    const data1 = { value: values.name };

    try {
      nameValidationSchema.validateSync(data1, { abortEarly: false });
    } catch (error) {
      toast.error(error.errors[0]);
      return;
    }

    const notificationTemplateIdValidationSchema = yup.object({
      note: yup.string().required('Notification Template Id is required!'),
    });
    const data2 = { note: values.notification_template_id };

    try {
      notificationTemplateIdValidationSchema.validateSync(data2, {
        abortEarly: false,
      });
    } catch (error) {
      toast.error(error.errors[0]);
      return;
    }

    const { user_id, notification_template_id, name } = values;

    if (props.isUpdate) {
      const input = { user_id, notification_id: props.notificationId };
      if (props.notificationTemplateId !== values.notification_template_id) {
        Object.assign(input, { notification_template_id });
      }

      if (props.notificationPlatfrom !== values.notification_platform.value) {
        Object.assign(input, {
          notification_platform: values.notification_platform.value,
        });
      }

      if (props.name !== values.name) {
        Object.assign(input, {
          name,
        });
      }

      if (props.status !== values.status.value) {
        Object.assign(input, {
          status: values.status.value,
        });
      }

      if (props.role !== values.role.value) {
        Object.assign(input, {
          user_role: values.role.value,
        });
      }

      Object.assign(input, {
        api_action: values.apiAction.value,
      });

      if (props.actionStatus !== values.actionStatus.value) {
        Object.assign(input, {
          action_status: values.actionStatus.value,
        });
      }

      Object.assign(input, {
        bcc: values.bcc.split(';').filter(ele => ele),
      });

      Object.assign(input, {
        cc: values.cc.split(';').filter(ele => ele),
      });

      Object.assign(input, {
        to: values.to.split(';').filter(ele => ele),
      });

      if (props.reply_to !== values.reply_to) {
        Object.assign(input, {
          reply_to: values.reply_to,
        });
      }

      if (values.apiAction.value === 'action_menu_order') {
        Object.assign(input, {
          service_type: values.service_type,
        });
      }

      Object.assign(input, {
        channel: 'EMAIL',
      });

      const res = await updateNotificationTemplate({
        input,
      });

      if (res) {
        toast.error(res);
      } else {
        swal(
          'Great!',
          'You have successfully updated the email notification template',
          'success',
        ).then(() => props.history.goBack());
      }
    } else {
      const input = {
        user_id,
        notification_template_id,
        channel: 'EMAIL',
        name,
        status: values.status.value,
        notification_platform: values.notification_platform.value,
        user_role: values.role.value,
        api_action: values.apiAction.value,
        action_status: values.actionStatus.value,
        bcc: values.bcc ? values.bcc.split(';').filter(ele => ele) : '',
        cc: values.cc ? values.cc.split(';').filter(ele => ele) : '',
        to: values.to ? values.to.split(';').filter(ele => ele) : '',
        reply_to: values.reply_to ? values.reply_to : '',
      };

      if (values.apiAction.value === 'action_menu_order') {
        Object.assign(input, {
          service_type: values.service_type,
        });
      }

      const res = await createNotificationTemplate({
        input,
      });
      if (res) {
        toast.error(res);
      } else {
        swal(
          'Great!',
          'You have successfully created the email notification template',
          'success',
        ).then(() => props.history.goBack());
      }
    }
  },
  displayName: 'EmailNotificationForm',
})(Form);

export default EmailNotificationForm;
