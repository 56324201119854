import React from 'react';
import { useStoreState } from 'easy-peasy';

import EmailNotificationForm from './EmailNotificationForm';

const CreateEmailNotificationTemplate = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <EmailNotificationForm history={history} isUpdate={false} userId={userId} />
  );
};

export default CreateEmailNotificationTemplate;
