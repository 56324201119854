import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  Message,
  // CustomInput,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_menu_deals(input: $input) {
      total_pages
      total_size
      menu_deal_listing {
        deal_id
        place_id
        menu_id
        deal_type
        promotion
        promo_code
        name
        service_type
        deal_type
        start
        end
        slug
        description
        tag
        is_published
        customer_type
        can_be_clubbed
        minimum_purchase_amount
        value
        maximum_discount_amount
        maximum_no_of_discounts
        maximum_discount
        maximum_no_of_discounts_per_user
        maximum_discount_per_user
        status
        menu {
          name
        }
        place {
          place_id
          name
          city
        }
        zone_setting {
          id
          override_delivery_fee
          savings
        }
        free_delivery
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          display_name
          email
        }
        updated_by_user {
          user_id
          display_name
          email
        }
      }
    }
  }
`;

const PlaceClaims = () => {
  const { userId } = useStoreState(state => state.auth);

  const [sort, setSort] = useState('UPDATED_DESC');
  const [voucherNameSort, setVoucherNameSort] = useState('AES');

  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');

  const [startDateSort, setStartDateSort] = useState('AES');
  const [endDateSort, setEndDateSort] = useState('AES');

  const [createdAtSort, setCreatedAtSort] = useState('AES');
  const [discountSort, setDiscountSort] = useState('AES');
  const [promoTypeSort, setPromoTypeSort] = useState('AES');

  const [status, setStatus] = useState('CURRENT');
  const [dealStatus, setDealStatus] = useState('ACTIVE');
  const [placeName, setPlaceName] = useState('');

  const { defaultPlace } = useStoreState(state => state.menu);

  const placeNameFilter = (() => {
    let temp = '';
    if (placeName) {
      temp = placeName;
    } else if (defaultPlace) {
      temp = defaultPlace.name;
    }
    return temp;
  })();

  const [voucherNameSearch, setVoucherNameSearch] = useState('');
  const [menuNameSearch, setMenuNameSearch] = useState('');

  const [page, setPage] = useState(0);
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(30);

  const [location, setLocation] = useState('');
  const [discountType, setDiscountType] = useState('');

  const input = { user_id: userId };
  const menu_deal_filter = {};
  const place_filter = {};

  Object.assign(
    place_filter,
    placeNameFilter && { place_name: placeNameFilter },
    location && location.length !== 0 && { city: location },
  );

  Object.assign(
    menu_deal_filter,
    { status: dealStatus },
    voucherNameSearch !== '' && { name: voucherNameSearch },
    discountType !== '' && { deal_type: discountType },
    { timeline: status },
  );

  Object.assign(
    input,
    { from: currentPage * listSize, size: listSize },

    { sort },

    {
      filter: {
        place_filter,
        menu_deal_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          location={location}
          setLocation={setLocation}
          status={status}
          setStatus={setStatus}
          dealStatus={dealStatus}
          setDealStatus={setDealStatus}
          placeName={placeNameFilter}
          setPlaceName={setPlaceName}
          voucherNameSearch={voucherNameSearch}
          handleVoucherNameSearch={setVoucherNameSearch}
          menuNameSearch={menuNameSearch}
          handleMenuNameSearch={setMenuNameSearch}
          discountType={discountType}
          setDiscountType={setDiscountType}
          defaultPlace={defaultPlace}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => setPage(value.selected + 1)}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setVoucherNameSort(
                          voucherNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          voucherNameSort === 'AES' ? 'NAME_DESC' : 'NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          voucherNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Deal name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setDiscountSort(discountSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          discountSort === 'AES'
                            ? 'DEAL_TYPE_DESC'
                            : 'DEAL_TYPE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          discountSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Discount type
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPromoTypeSort(
                          promoTypeSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          promoTypeSort === 'AES'
                            ? 'PROMOTION_DESC'
                            : 'PROMOTION_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          promoTypeSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Promo type
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>Promo code</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Service Type</span>
                </div>
              </th>
              <th>
                <div>
                  <span> Menu Name</span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_ASC'
                            : 'PLACE_NAME_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_ASC'
                            : 'PLACE_CITY_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStartDateSort(
                          startDateSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          startDateSort === 'AES' ? 'START_ASC' : 'START_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          startDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Start Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEndDateSort(endDateSort === 'AES' ? 'DES' : 'AES');
                        setSort(endDateSort === 'AES' ? 'END_ASC' : 'END_DESC');
                      }}
                    >
                      <i
                        className={
                          endDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    End Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>Updated By</span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCreatedAtSort(
                          createdAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          createdAtSort === 'AES'
                            ? 'UPDATED_ASC'
                            : 'UPDAETD_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          createdAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>Actions</th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.message}</Message>;
              }

              return (
                <React.Fragment>
                  <View
                    data={data}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ data, setPageCount, setTotalElements }) => {
  const { search_menu_deals } = data;
  useEffect(() => {
    setPageCount(search_menu_deals.total_pages);
    setTotalElements(search_menu_deals.total_size);
  }, [search_menu_deals]);

  return (
    <tbody>
      {search_menu_deals.menu_deal_listing &&
        search_menu_deals.menu_deal_listing.map(deal => (
          <ListItem deal={deal} />
        ))}
    </tbody>
  );
};

export default PlaceClaims;
