import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';

import { toast } from 'react-toastify';
import { isEqual } from 'lodash';

import tzLookup from 'tz-lookup';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import DealForm from '../PlaceDealAdd/DealForm';
import { Loading, Message } from '../../../components/elements';

const allNeededQuery = gql`
  query fetch_place($placeId: String, $dealId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      city
      post_code
      country
      latitude
      longitude
    }
    fetch_place_menu_deal(input: { deal_id: $dealId }) {
      mobile_only
      deal_id
      fee_type
      place_id
      menu_id
      menu_item {
        item_link_id
        menu_item_id
        type
        value
        name
        new_price
        savings
        variant {
          prompt
          prompt_id
          variants {
            new_price
            savings
            type
            value
            variant_id
            variant_name
          }
        }
      }
      deal_type
      promotion
      promo_code
      name
      service_type
      start
      end
      slug
      description
      tag
      is_published
      customer_type
      can_be_clubbed
      minimum_purchase_amount
      value
      maximum_discount_amount
      maximum_no_of_discounts
      maximum_discount
      maximum_no_of_discounts_per_user
      maximum_discount_per_user
      status
      zone_setting {
        id
        override_delivery_fee
        savings
      }
      free_delivery
    }
  }
`;

const updateDealMutation = gql`
  mutation updateDeal($input: PlaceMenuDealInput) {
    update_place_menu_deal(input: $input) {
      deal_id
      error {
        description
      }
    }
  }
`;

const PlaceClaimUpdate = ({ match, history }) => (
  <Layout>
    <Query
      query={allNeededQuery}
      variables={{
        placeId: match.params.placeId,
        dealId: match.params.dealId,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Message>error</Message>;
        }

        const { fetch_place: place, fetch_place_menu_deal } = data;
        return (
          <View history={history} place={place} deal={fetch_place_menu_deal} />
        );
      }}
    </Query>
  </Layout>
);

const View = ({ place, deal, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  useEffect(() => {
    const lat = parseFloat(place.latitude).toFixed(5);
    const long = parseFloat(place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    setCurrentTimeZone(timeZone);
    moment.tz.setDefault(timeZone);
    // console.log(timeZone)
  }, []);

  if (currentTimeZone === '') {
    return null;
  }

  if (currentTimeZone)
    return (
      <Mutation
        client={client.clientPrivate}
        mutation={updateDealMutation}
        onCompleted={({ update_place_menu_deal }) => {
          if (update_place_menu_deal.error) {
            update_place_menu_deal.error.map(item =>
              toast.error(item.description),
            );
          } else {
            swal(
              'Great!',
              'Place Deal had been updated successfully!',
              'success',
            ).then(() => {
              history.goBack();
            });
          }
        }}
      >
        {(
          update_place_menu_deal,
          { loading: dealLoading, error: dealError },
        ) => (
          <DealForm
            userId={userId}
            currentTimeZone={currentTimeZone}
            loading={dealLoading}
            error={dealError}
            place={place}
            deal={deal}
            onSubmit={input => {
              const final = { deal_id: input.deal_id };
              if (input.menu_item.length !== 0) {
                Object.assign(final, { menu_item: input.menu_item });
              }
              Object.assign(
                final,
                !isEqual(deal.user_id, input.user_id) && {
                  user_id: input.user_id,
                },
                !isEqual(deal.mobile_only, input.mobile_only) && {
                  mobile_only: input.mobile_only,
                },
                !isEqual(deal.fee_type, input.fee_type) && {
                  fee_type: input.fee_type,
                },
                !isEqual(deal.can_be_clubbed, input.can_be_clubbed) && {
                  can_be_clubbed: input.can_be_clubbed,
                },
                !isEqual(deal.customer_type, input.customer_type) && {
                  customer_type: input.customer_type,
                },
                !isEqual(deal.description, input.description) && {
                  description: input.description,
                },
                !isEqual(deal.free_delivery, input.free_delivery) && {
                  free_delivery: input.free_delivery,
                },
                !isEqual(deal.is_published, input.is_published) && {
                  is_published: input.is_published,
                },
                !isEqual(deal.maximum_discount, input.maximum_discount) && {
                  maximum_discount: input.maximum_discount,
                },
                !isEqual(
                  parseFloat(deal.maximum_discount_amount).toFixed(2),
                  input.maximum_discount_amount,
                ) && {
                  maximum_discount_amount: input.maximum_discount_amount,
                },
                !isEqual(
                  deal.maximum_discount_per_user,
                  input.maximum_discount_per_user,
                ) && {
                  maximum_discount_per_user: input.maximum_discount_per_user,
                },
                !isEqual(
                  deal.maximum_no_of_discounts,
                  input.maximum_no_of_discounts,
                ) && {
                  maximum_no_of_discounts: input.maximum_no_of_discounts,
                },
                !isEqual(
                  deal.maximum_no_of_discounts_per_user,
                  input.maximum_no_of_discounts_per_user,
                ) && {
                  maximum_no_of_discounts_per_user:
                    input.maximum_no_of_discounts_per_user,
                },
                !isEqual(
                  parseFloat(deal.minimum_purchase_amount).toFixed(2),
                  input.minimum_purchase_amount,
                ) && {
                  minimum_purchase_amount: input.minimum_purchase_amount,
                },
                !isEqual(deal.name, input.name) && {
                  name: input.name,
                },
                !isEqual(deal.promo_code, input.promo_code) && {
                  promo_code: input.promo_code,
                },
                !isEqual(deal.promotion, input.promotion) && {
                  promotion: input.promotion,
                },
                !isEqual(deal.slug, input.slug) && {
                  slug: input.slug,
                },
                !isEqual(deal.status, input.status) && {
                  status: input.status,
                },
                !isEqual(deal.tag, input.tag) && {
                  tag: input.tag,
                },
                !isEqual(parseFloat(deal.value).toFixed(2), input.value) && {
                  value: input.value,
                },
                !isEqual(deal.zone_setting, input.zone_setting) && {
                  zone_setting: input.zone_setting,
                },
                !isEqual(deal.start, input.start) && {
                  start: input.start,
                },
                !isEqual(deal.end, input.end) && {
                  end: input.end,
                },
              );
              update_place_menu_deal({ variables: { input: final } });
            }}
          />
        )}
      </Mutation>
    );
};

export default withRouter(PlaceClaimUpdate);
