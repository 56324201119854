import React from 'react';
import { lowerCase } from 'lodash';

import Setting from './Setting';

import DayEstimates from './DayEstimates';

const Settings = props => {
  const { values } = props;
  return (
    <>
      <Setting {...props} type={lowerCase(values.service_type)} />
      <DayEstimates {...props} type={lowerCase(values.service_type)} />
    </>
  );
};

export default Settings;
