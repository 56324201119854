import React from 'react';
import styled from 'styled-components';

import From from './Form';

const Item = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
`;

const SubItem = styled(Item)`
  padding-left: 1rem;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  padding-left: ${({ isModifierVariant }) =>
    isModifierVariant ? '4rem' : '1rem'};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.waterMarkGrey};
  pointer-events: ${({ availability }) =>
    availability === 'UNAVAILABLE' ? 'none' : 'all'};
  cursor: ${({ availability }) =>
    availability === 'UNAVAILABLE' ? 'not-allowed' : ''};
`;

const VariantItem = ({
  availabilityStatus,
  availability_date,
  rootIsAvailable,
  prompt,
  variant,
  minDate,
  onSubmit = () => {},
  isModifierVariant,
}) => (
  <>
    <ItemContainer
      availability={availabilityStatus}
      isModifierVariant={isModifierVariant}
    >
      <SubItem>
        <div>
          <h4 className="title is-4">{variant.variant_name}</h4>
          <h3 className="subtitle is-5">{prompt}</h3>
        </div>
      </SubItem>
      <From
        rootIsAvailable={rootIsAvailable}
        availability_date={availability_date}
        availabilityStatus={availabilityStatus}
        minDate={minDate}
        onSubmit={onSubmit}
      />
    </ItemContainer>
  </>
);

export default VariantItem;
