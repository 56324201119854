import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import { omit } from 'lodash';
import qs from 'querystringify';
import {
  Input,
  Radio,
  Select,
  ReactDateTimePicker,
} from '../../../components/elements';
// import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const object_types = [
  { value: 'All', label: 'All' },
  { value: 'PLACE_FEED', label: 'Place Feed' },
  { value: 'PLACE_REVIEW', label: 'Place Review' },
  { value: 'PLACE_REVIEW_COMMENT', label: 'Place Review Comment' },
  { value: 'ITEM_REVIEW', label: 'Item Review' },
  { value: 'ITEM_REVIEW_COMMENT', label: 'Item Review Comment' },
  { value: 'IMAGE', label: 'Image' },
];

const Search = ({
  placeName,
  status,
  reviewType,
  flagType,
  flagDate,
  routeState,
  history,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/flagged-reviews${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Submitted"
              name="Type"
              value={status === 'SUBMITTED'}
              onChange={() => {
                history.push(
                  `/flagged-reviews${qs.stringify(
                    { status: 'SUBMITTED' },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Approved"
              name="Type"
              value={status === 'APPROVED'}
              onChange={() => {
                history.push(
                  `/flagged-reviews${qs.stringify(
                    { status: 'APPROVED' },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Rejected"
              name="Type"
              value={status === 'REJECTED'}
              onChange={() => {
                history.push(
                  `/flagged-reviews${qs.stringify(
                    { status: 'REJECTED' },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-3">
            <Input
              label="Place Name"
              id="ReviewManagement_FlaggedReview_PlaceName"
              type="text"
              value={placeName}
              onChange={e =>
                handlePush({ placeName: e.target.value }, 'placeName')
              }
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-3">
            <Select
              label="Review Type"
              options={object_types}
              value={[{ value: reviewType, label: reviewType }]}
              onChange={({ value }) =>
                handlePush({ reviewType: value }, 'reviewType')
              }
            />
          </div>

          <div className="column is-3">
            <Input
              label="Flag Type"
              type="text"
              value={flagType}
              onChange={e =>
                handlePush({ flagType: e.target.value }, 'flagType')
              }
              placeholder="Start entering flag type..."
            />
          </div>

          <div className="column is-3">
            <ReactDateTimePicker
              label="Flag Date"
              value={flagDate}
              onChange={value => handlePush({ flagDate: value }, 'flagDate')}
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
