import React, { useState } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';

import { capitalize } from 'lodash';
import { Loading, Table } from '../../../components/elements';
import OrderDetails from '../Order/OrderDetails';

const ReferenceStyled = styled.div`
  color: #3273dc;
  cursor: pointer;
`;

const StatusStyled = styled.td`
  color: ${({ color }) => color};
  font-size: ${({ status }) => (status === 'CANCELLED' ? '' : '1.2rem')};
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const colorMap = {
  CANCELLED: 'red',
  DRAFT: 'grey',
};

const AesDes = ({ sort, setSort, aes, des }) => (
  <a onClick={() => setSort(sort === des ? aes : des)}>
    <i className={`fas fa-sort-amount-${sort === des ? 'up' : 'down'}`} />
  </a>
);

const getPaymentTitle = paymentObj => {
  const { payment_method_type, payment_method } = paymentObj;
  if (payment_method_type) {
    if (payment_method_type === 'G_PAY') {
      return 'Google Pay';
    }
    if (payment_method_type === 'APPLE_PAY') {
      return 'Apple Pay';
    }
    if (payment_method_type === 'CARD') {
      return 'Card';
    }
  }
  return capitalize(payment_method);
};

const All = ({
  orders,
  isLoading,
  sort,
  setSort,
  fetchMoreOrders,
  loadingMoreData,
  canLoadMoreData,
}) => {
  // const [isActiveModal, setIsActiveModal] = useState(false);
  const [isActiveOrderDetailsModal, setIsActiveOrderDetailsModal] = useState(
    false,
  );
  const [orderData, setOrderData] = useState();

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="ORDER_REFERENCE_ASC"
                    des="ORDER_REFERENCE_DESC"
                  />
                  Order No.
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="SERVICE_TYPE_ASC"
                    des="SERVICE_TYPE_DESC"
                  />
                  Type
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="CREATED_ASC"
                    des="CREATED_DESC"
                  />
                  Order Date
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Customer</span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="OVERALL_QUANTITY_ASC"
                    des="OVERALL_QUANTITY_DESC"
                  />
                  Items
                </span>
              </div>
            </th>

            <th>
              <div>
                <div>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="ORDER_TOTAL_ASC"
                    des="ORDER_TOTAL_DESC"
                  />
                  <span>Total</span>
                </div>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="PAYMENT_METHOD_ASC"
                    des="PAYMENT_METHOD_DESC"
                  />
                  Payment
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="STATUS_ASC"
                    des="STATUS_DESC"
                  />
                  Status
                </span>
              </div>
            </th>

            <th>
              <div>
                <span>
                  <AesDes
                    sort={sort}
                    setSort={setSort}
                    aes="UPDATED_ASC"
                    des="UPDATED_DESC"
                  />
                  Status Date
                </span>
              </div>
            </th>

            <th>
              <div>
                <span />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <Loading />}
          {!isLoading && orders.length === 0 && <div>No orders found</div>}
          {!isLoading &&
            orders.map(item => {
              const handleTimeLineTime = status => {
                const getStatus = () => {
                  if (status === 'RECEIVED') {
                    return 'received';
                  }
                  if (status === 'CONFIRMED') {
                    return 'confirmed';
                  }
                  if (status === 'READY') {
                    return 'ready';
                  }
                  if (status === 'COMPLETE') {
                    return 'complete';
                  }
                  if (status === 'CANCEllED') {
                    return 'cancelled';
                  }
                  return 'received';
                };
                const orderStatus = item[getStatus()];
                const time =
                  (orderStatus &&
                    moment(orderStatus.created_at).format('LLL')) ||
                  '---';
                return time;
              };
              return (
                <tr>
                  <td>
                    <ReferenceStyled
                      onClick={() => {
                        setOrderData(item);
                        // setIsActiveModal(true);
                        setIsActiveOrderDetailsModal(true);
                      }}
                    >
                      {item.order_reference}
                    </ReferenceStyled>
                  </td>
                  <td>{item.service_type}</td>
                  <td>{moment(item.audit.created_at).format('LLL')}</td>
                  <td>
                    {(item.contact && item.contact.contact_name) || '---'}
                  </td>
                  <td>{item.overall_quantity} items</td>
                  <td>${item.billing.order_total || 0}</td>
                  <td>{item.payment && getPaymentTitle(item.payment)}</td>
                  <td>{item.status}</td>
                  <td>{handleTimeLineTime(item.status)}</td>
                  <StatusStyled
                    status={item.status}
                    color={colorMap[item.status]}
                  >
                    {item.status !== 'CANCELLED' ? (
                      <i className="far fa-check-circle" />
                    ) : (
                      <i className="far fa-times-circle" />
                    )}
                    &nbsp;
                    {item.status === 'CANCELLED' &&
                      `Cancelled By ${item.cancelled.user_type}`}
                  </StatusStyled>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {!isLoading && canLoadMoreData && (
        <PaginationContainer>
          {loadingMoreData ? (
            <span>loading...</span>
          ) : (
            <ShowMoreButton type="button" onClick={fetchMoreOrders}>
              <span>Show More</span>
            </ShowMoreButton>
          )}
        </PaginationContainer>
      )}

      {orderData && isActiveOrderDetailsModal && (
        <OrderDetails
          // onClose={() => setIsActiveModal(false)}
          // isActive={isActiveModal}
          isActiveOrderDetailsModal={isActiveOrderDetailsModal}
          setIsActiveOrderDetailsModal={setIsActiveOrderDetailsModal}
          orderComponent="OrderHistory"
          order={orderData}
        />
      )}
    </>
  );
};

export default All;
