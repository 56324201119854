import React from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';
import qs from 'querystringify';
import { Level, Title } from 'rbx';
import { Input, Radio } from '../../../components/elements';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  status,
  login,
  firstName,
  displayName,
  routeState,
  history,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/admin-users${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };

  const isJustAPartner = roleArray => {
    const partner = (roleArray ?? []).filter(rl => rl.startsWith('partner'));
    const kravein = (roleArray ?? []).filter(rl => rl.startsWith('kravein'));

    let isPartner = false;

    if (kravein.length !== 0) {
      isPartner = false;
    } else if (partner.length !== 0) {
      isPartner = true;
    }

    return isPartner;
  };

  return (
    <Wrapper>
      <ACLSConsumer>
        {({ role, loading: securityLoading }) =>
          !securityLoading &&
          !isJustAPartner(role) && (
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <TitleWrapper>
                    <Title className="label">Status</Title>
                  </TitleWrapper>
                </Level.Item>
                <Level.Item>
                  <Radio
                    label="ACTIVE"
                    value={status === 'ACTIVE'}
                    onChange={() => handlePush({ status: 'ACTIVE' }, 'status')}
                  />
                </Level.Item>
                <Level.Item>
                  <Radio
                    label="INACTIVE"
                    value={status === 'INACTIVE'}
                    onChange={() =>
                      handlePush({ status: 'INACTIVE' }, 'status')
                    }
                    // disabled
                  />
                </Level.Item>
              </Level.Item>
            </Level>
          )
        }
      </ACLSConsumer>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Login"
              type="text"
              value={login}
              onChange={event =>
                handlePush({ login: event.target.value }, 'login')
              }
              placeholder="Start entering email..."
            />
          </div>
          <div className="column is-4">
            <Input
              label="Full Name"
              type="text"
              value={firstName}
              onChange={event =>
                handlePush({ firstName: event.target.value }, 'firstName')
              }
              placeholder="Start entering the full name..."
            />
          </div>
          <div className="column is-4">
            <Input
              label="Display Name"
              type="text"
              value={displayName}
              onChange={event =>
                handlePush({ displayName: event.target.value }, 'displayName')
              }
              placeholder="Start entering the display name..."
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
