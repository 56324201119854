import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import swal from 'sweetalert';
import { startCase } from 'lodash';
import styled from 'styled-components';
import { ACLSConsumer } from '../../../../utils/aclsContext';
import Can from '../../../../utils/Can';

import { config } from '../../../../utils/config';

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const PreviewButton = styled.button`
  background-color: #00aeef;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 12px;
  cursor: pointer;
`;

const Header = ({
  history,
  location,
  loading,
  // dirty,
  children,
  menuName,
  orderFoodSlug,
}) => (
  <Wrapper>
    <Level style={{ padding: 0, marginTop: 0, marginBottom: 0 }}>
      <Level.Item
        align="left"
        style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
      >
        <Title
          className="is-size-2"
          style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
        >
          Menu Designer
        </Title>
      </Level.Item>
      <Level.Item
        align="right"
        style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
      >
        <Level.Item style={{ padding: 0, marginTop: 0, marginBottom: 0 }}>
          <a onClick={() => history.goBack()} className="button is-grey">
            <span className="has-text-weight-semibold">Cancel</span>
          </a>
        </Level.Item>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/add-menu'
                  ? 'create_menu'
                  : 'update_menu'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item
                  style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
                >
                  <button
                    type="submit"
                    className={`button is-primary ${loading &&
                      'is-loading'} menu-designer-submit-button`}
                    // disabled={!dirty}
                  >
                    <span className="has-text-weight-semibold">Submit</span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
    <div className="is-flex">
      <p className="is-size-1">{startCase(menuName)}</p>
      <PreviewButton
        type="button"
        onClick={() => {
          if (orderFoodSlug) {
            window.open(`${config.customer}${orderFoodSlug}`, '_blank');
          } else {
            swal({
              text: `preview url ${config.customer}${orderFoodSlug} is not correct`,
              icon: 'error',
            });
          }
        }}
      >
        Preview Menu
      </PreviewButton>
    </div>
    {children}
  </Wrapper>
);

export default withRouter(Header);
