import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';

const Container = styled.div`
  margin: 0rem 1rem;
  padding: 1rem 2rem;
  ul {
    list-style-type: disc;
  }
`;

const GlobalErrors = ({ errors }) => (
  <Container className="notification">
    <ul>
      {map(errors).map(item => (
        <li className="has-text-danger">{item}</li>
      ))}
    </ul>
  </Container>
);

export default GlobalErrors;
