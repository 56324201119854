import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Tab, Column } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull, omit, toLower, isNil } from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import { Box, Tabs, Select, CheckBox } from '../../../../components/elements';
import ListingOnly from '../../../PlaceManagement/PlaceDealAdd/ListingOnly';

import Header from './Header';
import Footer from '../../../PlaceManagement/PlaceDealAdd/Footer';

import DealDetailDiscount from './DealDetailDiscount';
import {
  getMenus,
  parseMenuItemAtSubmission,
  getDeliveryChannel,
} from '../../../PlaceManagement/PlaceDealAdd/helpers';

import { getMenuTypeDeals } from '../../../../utils/fetchStatic';
import AssignPlaces from './AssignPlaces';
import { useCurrentTimeZone } from './helpers';

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const [dealTypes, setDealTypes] = useState([]);
  const [menus, setMenus] = useState([]);
  const [menusLoading, setMenusLoading] = useState(false);
  const [deliveryChannel, setDeliveryChannel] = useState(null);

  const {
    handleSubmit,
    place,
    values,
    setFieldValue,
    user,
    loading,
    isUpdateForm,
  } = props;
  const userId = user.user_id;

  const prev = () =>
    activeTab === 0 ? setActiveTab(2) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 2 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  useCurrentTimeZone({
    userId,
    placeId: values.place_id,
    stateSetterFn: value => setFieldValue('currentTimeZone', value),
    allowFetch: !isUpdateForm,
  });

  const getMenusData = useCallback(async () => {
    setMenus([]);
    setMenusLoading(true);
    const list = await getMenus({ placeId: values.place_id, userId });

    const deliveryMenu = list.find(ele => ele.service_type === 'Delivery');
    if (deliveryMenu) {
      console.log(deliveryMenu.service_type_setting.service_type_setting_id);
      try {
        const channel = await getDeliveryChannel(
          deliveryMenu.service_type_setting.service_type_setting_id,
        );
        if (channel) {
          setDeliveryChannel(channel);
        }
      } catch {
        // pass
      }
    }

    let final;
    if (values.deal_type === 'delivery_fee_discount') {
      final = list
        .map(item => ({
          label: `${item.service_type} - ${item.name}`,
          value: item.menu_id,
        }))
        .filter(ele => ele.label.includes('Delivery'));
    } else {
      final = list.map(item => ({
        label: `${item.service_type} - ${item.name}`,
        value: item.menu_id,
      }));
    }

    setMenus(final);
    setMenusLoading(false);
  }, [values.place_id, values.deal_type]);

  useEffect(() => {
    const fetchData = async () => {
      const dealTypesData = await getMenuTypeDeals();
      const types = dealTypesData.map(item => ({
        label: item.description,
        value: item.name,
      }));
      setDealTypes(types);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (values.place_id) {
      getMenusData();
    }
  }, [values.place_id, values.deal_type]);

  const dealTypeArray =
    dealTypes.length !== 0
      ? dealTypes.filter(item => item.value === toLower(values.deal_type))
      : [];

  const menuArray =
    menus.length !== 0
      ? menus.filter(item => item.value === values.menu_id)
      : [];

  const dealType = dealTypeArray.length !== 0 ? dealTypeArray[0].label : '';
  const menuName = menuArray.length !== 0 ? menuArray[0].label : '';

  React.useEffect(() => {
    if (values.minimum_purchase_amount === null || !values.value) {
      if (values.fee_type === 'FLAT') {
        setFieldValue('name', 'Save $0 off your Delivery Fee');
      } else if (values.fee_type === 'PERCENTAGE') {
        setFieldValue('name', 'Save 0% off your Delivery Fee');
      } else if (values.free_delivery) {
        setFieldValue('name', '$0 Delivery Fee');
      }
    } else if (
      values.free_delivery &&
      parseFloat(values.minimum_purchase_amount) > 0
    ) {
      setFieldValue(
        'name',
        `Free Delivery Over $${parseFloat(
          values.minimum_purchase_amount,
        ).toFixed(2)}`,
      );
    } else if (
      values.free_delivery &&
      parseFloat(values.minimum_purchase_amount) <= 0
    ) {
      setFieldValue('name', '$0 Delivery Fee');
    } else if (
      values.fee_type === 'FLAT' &&
      parseFloat(values.minimum_purchase_amount) > 0
    ) {
      setFieldValue(
        'name',
        `Spend $${parseFloat(values.minimum_purchase_amount).toFixed(
          2,
        )} and save upto $${parseFloat(values.value ?? 0).toFixed(
          2,
        )} off your Delivery Fee`,
      );
    } else if (
      values.fee_type === 'FLAT' &&
      parseFloat(values.minimum_purchase_amount) <= 0
    ) {
      setFieldValue(
        'name',
        `Save $${parseFloat(values.value ?? 0).toFixed(
          2,
        )} off your Delivery Fee`,
      );
    } else if (
      values.fee_type === 'PERCENTAGE' &&
      parseFloat(values.minimum_purchase_amount) > 0
    ) {
      setFieldValue(
        'name',
        `Spend $${parseFloat(values.minimum_purchase_amount).toFixed(
          2,
        )} and save ${parseFloat(values.value ?? 0).toFixed(
          2,
        )}% off your Delivery Fee`,
      );
    } else if (
      values.fee_type === 'PERCENTAGE' &&
      parseFloat(values.minimum_purchase_amount) <= 0
    ) {
      setFieldValue(
        'name',
        `Save ${parseFloat(values.value ?? 0).toFixed(
          2,
        )}% off your Delivery Fee`,
      );
    }
  }, [
    values.value,
    values.minimum_purchase_amount,
    values.fee_type,
    values.free_delivery,
  ]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (values.deal_type.toUpperCase() === 'SPECIFIC_ITEM_DISCOUNT') {
          if (values.menu_item.length === 0) {
            toast.error('Please Select Atleast One Item From The Menu');
            return;
          }
        }
        handleSubmit();
      }}
    >
      <Header
        onCancel={isUpdateForm ? '/user-promos' : '/add-user-promo'}
        heading="User Promo"
        userName={`${user.first_name} (${user.email})`}
        loading={loading}
      />
      {values.step === 1 && (
        <>
          <Container>
            <Column.Group vcentered multiline gapSize={8}>
              <Column size={4}>
                <Select
                  label="Select discount type"
                  options={dealTypes}
                  onChange={value => {
                    console.log('value.value', value.value);
                    setFieldValue('deal_type', value.value);
                    if (value.value === 'listing_only') {
                      setFieldValue('step', 2);
                    }
                    if (value.value === 'delivery_fee_discount') {
                      setFieldValue('free_delivery', true);
                      setFieldValue('name', '$0 Delivery Fee');
                      setFieldValue('value', 1);
                    }
                    if (value.value === 'flat_amount_discount') {
                      setFieldValue('name', 'Spend $0 and Save $0');
                    }
                    if (value.value === 'percentage_discount') {
                      setFieldValue('name', 'Spend $0 and Save 0%');
                    }
                  }}
                  required
                />
              </Column>

              {values.deal_type && (
                <Column size={4}>
                  <CheckBox
                    label="Enable Multiple Places"
                    value={false}
                    disabled
                  />
                </Column>
              )}

              {values.deal_type && (
                <Column size={4}>
                  <AssignPlaces
                    setFieldValue={setFieldValue}
                    userId={userId}
                    values={values}
                  />
                </Column>
              )}

              {values.deal_type &&
                values.place_id &&
                values.deal_type !== 'listing_only' && (
                  <Column size={4}>
                    <Select
                      label="Select menu"
                      name="menu"
                      options={menus}
                      onChange={value => {
                        setFieldValue('menu_id', value.value);
                        setFieldValue('step', 2);
                      }}
                      isLoading={menusLoading}
                      required
                    />
                  </Column>
                )}
            </Column.Group>
          </Container>
        </>
      )}

      {values.step === 2 && (
        <Box>
          <Tabs className="tabs is-medium">
            {values.deal_type === 'listing_only' && (
              <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
                Deal Details
              </Tab>
            )}
            {values.menu_id && (
              <>
                <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
                  Deal Details
                </Tab>
              </>
            )}
          </Tabs>

          <Container>
            {/* {values.deal_type === 'listing_only' && (
              <>
                {activeTab === 0 && (
                  <ListingOnly
                    {...props}
                    dealTypes={dealTypes}
                    dealType={dealType}
                    menuName={menuName}
                  />
                )}
              </>
            )} */}
            {values.menu_id && (
              <>
                {activeTab === 0 && (
                  <DealDetailDiscount
                    {...props}
                    dealType={dealType}
                    menuName={menuName}
                    menuId={values.menu_id}
                    place={place}
                    deliveryChannel={deliveryChannel}
                  />
                )}
              </>
            )}
          </Container>
        </Box>
      )}
      {values.step !== 1 && (
        <Footer
          activeTab={activeTab}
          loading={loading}
          prev={prev}
          next={next}
          onCancel={isUpdateForm ? '/user-promos' : '/add-user-promo'}
        />
      )}
    </form>
  );
};

const DealForm = withFormik({
  mapPropsToValues: ({
    deal,
    user,
    currentTimeZone: currentTimeZoneFromHigherComponent,
  }) => {
    const startDate = deal && !isNull(deal.start) ? moment(deal.start) : null;
    const endDate = deal && !isNull(deal.end) ? moment(deal.end) : null;
    return {
      currentTimeZone: currentTimeZoneFromHigherComponent || null,
      enableMultiPlaceSelection: false,
      step: deal && deal.deal_id ? 2 : 1,
      user_id: user.user_id,
      user_specific_deal: true,
      targeted_users: [user.user_id],
      mobile_only: deal ? deal.mobile_only : false,
      startDate: deal
        ? moment.tz(startDate, currentTimeZoneFromHigherComponent)
        : null,
      startTime: deal
        ? moment.tz(startDate, currentTimeZoneFromHigherComponent)
        : null,
      endDate: deal
        ? moment.tz(endDate, currentTimeZoneFromHigherComponent)
        : null,
      endTime: deal
        ? moment.tz(endDate, currentTimeZoneFromHigherComponent)
        : null,
      deal_id: deal && deal.deal_id ? deal.deal_id : uuidv4(),
      place_id: deal && deal.place_id ? deal.place_id : null,
      menu_id: deal && deal.menu_id ? deal.menu_id : null,
      menu_item: deal && deal.menu_item ? deal.menu_item : [],
      selectedLinkIds:
        deal && deal.menu_item
          ? deal.menu_item.map(ele => ele.item_link_id)
          : [],
      deal_type: deal && deal.deal_type ? deal.deal_type : null,
      promotion: deal && deal.promotion ? deal.promotion : 'AUTOMATIC',
      promo_code: deal && deal.promo_code ? deal.promo_code : '',
      name: deal && deal.name ? deal.name : '',
      service_type: deal && deal.service_type ? deal.service_type : [],
      slug: deal && deal.slug ? deal.slug : null,
      description: deal && deal.description ? deal.description : '',
      tag: deal && deal.tag ? deal.tag : [],
      is_published: deal && deal.is_published ? deal.is_published : true,
      customer_type:
        deal && deal.customer_type ? deal.customer_type : 'EXISTING',
      can_be_clubbed:
        deal && !isNil(deal.can_be_clubbed) ? deal.can_be_clubbed : false,
      minimum_purchase_amount:
        deal && !isNil(deal.minimum_purchase_amount)
          ? parseFloat(deal.minimum_purchase_amount).toFixed(2)
          : null,
      value:
        deal && !isNil(deal.value) ? parseFloat(deal.value).toFixed(2) : null,
      maximum_discount_amount:
        deal && !isNil(deal.maximum_discount_amount)
          ? parseFloat(deal.maximum_discount_amount).toFixed(2)
          : null,
      maximum_no_of_discounts:
        deal && !isNil(deal.maximum_no_of_discounts)
          ? deal.maximum_no_of_discounts
          : -1,
      maximum_discount:
        deal && !isNil(deal.maximum_discount)
          ? parseFloat(deal.maximum_discount).toFixed(2)
          : null,
      maximum_no_of_discounts_per_user:
        deal && !isNil(deal.maximum_no_of_discounts_per_user)
          ? deal.maximum_no_of_discounts_per_user
          : -1,
      maximum_discount_per_user:
        deal && !isNil(deal.maximum_discount_per_user)
          ? deal.maximum_discount_per_user
          : null,
      status: deal && deal.status ? deal.status : 'ACTIVE',
      zone_setting: deal && deal.zone_setting ? deal.zone_setting : null,
      free_delivery: deal && deal.free_delivery ? deal.free_delivery : null,
      fee_type: deal && deal.fee_type ? deal.fee_type : null,
    };
  },

  validate: values => {
    const errors = {};
    if (toLower(values.deal_type) === 'specific_item_discount') {
      return errors;
    }
    if (values.deal_type !== 'listing_only') {
      if (!values.minimum_purchase_amount) {
        errors.minimum_purchase_amount = 'Min Purchase is required';
      }
      if (!values.value) {
        errors.value = 'Discount is required';
      }
      if (
        values.deal_type === 'flat_amount_discount' &&
        values.minimum_purchase_amount
      ) {
        const priceOfSeventyPercent =
          (parseFloat(values.minimum_purchase_amount) * 70) / 100;
        if (
          values.minimum_purchase_amount !== '0.00' &&
          values.value > priceOfSeventyPercent
        ) {
          errors.value =
            'Discount amount cannot exceed 70% of minimum purchase';
        }
      }
      if (values.deal_type === 'percentage_discount' && values.value) {
        if (parseFloat(values.value) > 70) {
          errors.value = 'Discount amount cannot exceed 70%';
        }
      }
      if (
        values.deal_type === 'percentage_discount' &&
        !values.maximum_discount_amount
      ) {
        errors.maximum_discount_amount = 'Max Discount is required';
      }
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(4, 'At least 4 Characters Required!')
      .max(100, 'Not greater than 100 Characters!')
      .required('Deal tagline is required!'),

    description: yup
      .string()
      .min(26, 'At least 26 Characters Required!')
      .max(500, 'Not greater than 500 Characters!'),
    startDate: yup
      .string()
      .transform(v => (v === null ? '' : v))
      .required('Start date is required'),
    endDate: yup
      .string()
      .transform(v => (v === null ? '' : v))
      .required('End date is required'),
  }),

  handleSubmit: (values, { props }) => {
    const input = omit(values, [
      'service_type',
      'startDate',
      'endDate',
      'startTime',
      'endTime',
      'step',
      'promo_code',
      'promotion',
      'selectedLinkIds',
      'enableMultiPlaceSelection',
      'currentTimeZone',
    ]);
    if (input.menu_item.length !== 0) {
      input.menu_item = input.menu_item.map(parseMenuItemAtSubmission);
    }

    const customStartDate = moment(values.startDate).format('YYYY-MM-DD');
    const startTimeWhenValueIsNull = moment(values.startDate).isSame(moment())
      ? moment().format('HH:mm:ss')
      : '00:00:00';

    const customStartTime = !isNull(values.startTime)
      ? moment(values.startTime).format('HH:mm:ss')
      : startTimeWhenValueIsNull;

    const customEndDate = moment(values.endDate).format('YYYY-MM-DD');
    const customEndTime = !isNull(values.endTime)
      ? moment(values.endTime).format('HH:mm:ss')
      : '23:59:00';

    const startDate = moment
      .tz(`${customStartDate} ${customStartTime}`, values.currentTimeZone)
      .toISOString();

    const endDate = moment
      .tz(`${customEndDate} ${customEndTime}`, values.currentTimeZone)
      .toISOString();

    const inputs = {
      ...input,
    };
    Object.assign(
      inputs,
      props.deal
        ? props.deal.promo_code !== values.promo_code &&
            values.promotion === 'PROMOCODE' && {
              promo_code: values.promo_code,
              promotion: values.promotion,
            }
        : {
            promo_code: values.promo_code,
            promotion: values.promotion,
          },
      props.deal &&
        values.promotion === 'AUTOMATIC' && {
          promo_code: null,
          promotion: values.promotion,
        },
      props.deal
        ? props.deal.start !== startDate && { start: startDate }
        : { start: startDate },
      props.deal
        ? props.deal.end !== endDate && { end: endDate }
        : { end: endDate },
    );
    props.onSubmit(inputs);
  },

  displayName: 'DealForm',
})(Form);

export default DealForm;
