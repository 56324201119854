import React from 'react';
import { Table } from '../../../components/elements';

const BookingActivityTable = ({ loading, bookingActivityData }) => {
  console.log('BookingActivityTable...');
  console.log(bookingActivityData);
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>
                  <a>
                    <i
                    // className={
                    //   placeNameSort === 'AES'
                    //     ? 'fas fa-sort-amount-down'
                    //     : 'fas fa-sort-amount-up'
                    // }
                    />
                  </a>
                  Booking No
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>Place Name</span>
              </div>
            </th>
            <th>
              <span>Place Phone</span>
            </th>
            <th>
              <span>City/Suburb</span>
            </th>
            <th>
              <span>Booking Date</span>
            </th>
            <th>
              <span>Booking Time</span>
            </th>
            <th>
              <span>Guests</span>
            </th>
            <th>
              <span>Table</span>
            </th>
            <th>
              <span>Guest Name</span>
            </th>
            <th>
              <span>Phone</span>
            </th>
            <th>
              <span>Email</span>
            </th>
            <th>
              <span>Duration</span>
            </th>
            <th>
              <span>Created By</span>
            </th>
            <th>
              <span>Device Info</span>
            </th>
            <th>
              <span>Booking Count</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {bookingActivityData.map(bad => (
            <tr key={bad.table_booking_id}>
              <td
                style={{
                  cursor: 'pointer',
                  color: '#00AEEF',
                  whiteSpace: 'nowrap',
                }}
              >
                <span
                  onClick={() => {
                    const newTabUrl = `/add-modify-booking/${bad.place.place_id}/${bad.table_booking_id}`;
                    window.open(newTabUrl, '_blank');
                  }}
                >
                  {bad.booking_reference}
                </span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>{bad.place.name}</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>-</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>{bad.place.state}</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>{bad.booking_date}</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>{bad.booking_time}</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>{bad.guest_count}</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>{bad.table_number}</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>
                  {bad.contact.first_name} {bad.contact.last_name}
                </span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>{bad.contact.contact_phone}</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>{bad.contact.contact_email}</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>-</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>-</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>-</span>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <span>-</span>
              </td>
            </tr>
          ))}
          <></>
        </tbody>
      </Table>
      {loading && (
        <span
          style={{
            padding: '1rem',
            color: '#00AEEF',
            fontSize: '18px',
          }}
        >
          Loading...
        </span>
      )}
    </>
  );
};

export default BookingActivityTable;
