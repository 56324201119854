import React from 'react';
import { Column } from 'rbx';

import moment from 'moment';

import { Input } from '../../../../components/elements';

const CreatedUserDetails = ({ user, createdAt }) => (
  <fieldset className="box">
    <legend className="label">User Details</legend>
    <Column.Group multiline vcentered gapSize={8}>
      <Column size="half">
        <Input label="User Log-in Email" value={user && user.email} disabled />
      </Column>
      <Column size="half">
        <Input
          label="User Name"
          value={`${user.first_name} ${user.last_name}`}
          disabled
        />
      </Column>
      <Column size="half">
        <Input
          label="Review Date and Time"
          value={moment(createdAt).format('lll')}
          disabled
        />
      </Column>
      <Column size="half">
        <Input label="User Display Name" value={user.display_name} disabled />
      </Column>
    </Column.Group>
  </fieldset>
);

export default CreatedUserDetails;
