import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { isNull } from 'lodash';

import ReactDateTimePicker from '../../../../../components/elements/ReactDateTimePicker';
import TimePicker from '../../../../../components/global/TimePicker';
import InputErrorMessage from '../../../../../components/elements/InputErrorMessage';

const TimeWrapper = styled.div`
  padding-left: 0.5rem;
`;
const TimeLabel = styled.p`
  position: relative;
  left: 39%;
`;

const DateTimePicker = ({
  label,
  required,
  time,
  date,
  timeLabel,
  onDateChange,
  onTimeChange,
  errors,
  touched,
  disabled,
  timeProps,
  ...dateProps
}) => {
  const initialDateValue = !isNull(date) ? moment(date).format('L') : null;
  // const initialTimeValue = time !== null ? time : '';
  const initialTimeValue = !isNull(time) ? time : '';
  return (
    <div className="field">
      {label && (
        <label className="label is-flex">
          {label}
          {required && <span className="has-text-danger">*</span>}
          {timeLabel && <TimeLabel className="label">{timeLabel}</TimeLabel>}
        </label>
      )}

      <div className="control">
        <div className="is-flex">
          <ReactDateTimePicker
            value={initialDateValue}
            onChange={value => {
              onDateChange(moment(value).toISOString());
              if (value === null) {
                onTimeChange(null);
                onDateChange(null);
              } else {
                onDateChange(moment(value).toISOString());
              }
            }}
            disabled={disabled}
            {...dateProps}
          />
          {/* {console.log(initialTimeValue)} */}
          <TimeWrapper>
            <TimePicker
              placeholder="Select Time"
              value={initialTimeValue || ''}
              onChange={value => {
                onTimeChange(moment(value).toISOString());
              }}
              disabled={isNull(initialDateValue) || disabled}
              {...timeProps}
            />
          </TimeWrapper>
        </div>
      </div>
      <InputErrorMessage errors={errors} touched={touched} />
    </div>
  );
};

DateTimePicker.defaultProps = {
  time: moment().toNow(),
  date: moment().toNow(),
};

DateTimePicker.propTypes = {
  time: PropTypes.string,
  date: PropTypes.string,
};

export default DateTimePicker;
