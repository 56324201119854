import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import { hot } from 'react-hot-loader/root';
import { StoreProvider } from 'easy-peasy';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { makeRootValidatingTransform } from 'rbx/base/helpers';
import { DEFAULTS } from 'rbx/base/helpers/variables';
import { ThemeContext } from 'rbx/base/theme';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import Routes from './pages/Routes';
import bulmaTheme, { GlobalStyle } from './theme';
import client from './utils/apolloClient';
import store from './store';
import config from './utils/config';

const COLORS = [...DEFAULTS.colors, 'kr'];

const themeValue = {
  transform: makeRootValidatingTransform({ colors: COLORS }),
};

const persistor = persistStore(store);

Bugsnag.start({
  apiKey: config.bugsnagApiKey,
  releaseStage: config.bugsnagReleaseStage,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const App = () => (
  <ErrorBoundary>
    <ApolloProvider client={client.clientPublic}>
      <PersistGate loading={<div>Loading</div>} persistor={persistor}>
        <StoreProvider store={store}>
          <ThemeProvider theme={bulmaTheme}>
            <ThemeContext.Provider value={themeValue}>
              <Routes />
            </ThemeContext.Provider>
            <GlobalStyle />
          </ThemeProvider>
        </StoreProvider>
      </PersistGate>
    </ApolloProvider>
  </ErrorBoundary>
);

export default hot(App);
