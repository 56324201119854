import React from 'react';
import moment from 'moment';

import { Table, Loading } from '../../../components/elements';
import { createGoogleFeedLogsArray } from './helpers';

import { getGoogleLogsJSON } from '../../../utils/s3';

const GoogleFeedsLogTable = () => {
  const [logs, setLogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      const lg = await createGoogleFeedLogsArray();
      setLogs(lg);
      setLoading(false);
    };
    fetchFunc();
  }, []);

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <span>Date</span>
          </th>
          <th>
            <span>Status</span>
          </th>
          <th>
            <span>S3 Path (success)</span>
          </th>
          <th>
            <span>S3 Path (failure)</span>
          </th>
        </tr>
      </thead>
      {loading ? (
        <Loading />
      ) : (
        <>
          {logs.length !== 0 && (
            <tbody>
              {logs.map(ele => (
                <tr key={ele.date}>
                  <td>{moment(ele.date).format('MM-DD-YYYY')}</td>
                  <td>{ele.status}</td>
                  <td
                    style={{
                      cursor: 'pointer',
                      color: '#00AEEF',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <a
                      href={ele.sPath}
                      onClick={() =>
                        getGoogleLogsJSON(ele.sPath.split('/').pop())
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {ele.sPath}
                    </a>
                  </td>
                  <td
                    style={{
                      cursor: 'pointer',
                      color: '#00AEEF',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <a
                      href={ele.fPath}
                      onClick={() =>
                        getGoogleLogsJSON(ele.fPath.split('/').pop())
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {ele.fPath}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </>
      )}
    </Table>
  );
};

export default GoogleFeedsLogTable;
