import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  background: rgba(255, 200, 65, 0.1);
  margin-bottom: 1rem;
`;

const SpecialText = styled.p`
  color: ${({ theme }) => theme.coolGrey};
  font-size: 0.8rem;
`;
const ItemTotal = ({ order }) => (
  <Container>
    <SpecialText>Total Items</SpecialText>
    <SpecialText>{order.overall_quantity}</SpecialText>
  </Container>
);

export default ItemTotal;
