import React from 'react';
import { useStoreState } from 'easy-peasy';
import qs from 'querystringify';

import Layout from '../../../components/global/Layout';

import Header from './Header';

import TabSection from './TabSection';

import FilterSection from './FilterSection';

import StatisticSection from './StatisticSection';

import BookingManagerTable from './BookingManagerTable';

import { useTableBookingsData } from './helpers';

import { Pagination } from '../../../components/elements';

import TagFilterModal from './TagFilterModal';
import StatusFilterModal from './StatusFilters';
import ChartsScreen from './ChartsScreen';

const useRefState = refValue => {
  const initialiseRef = React.useRef(true);
  const [refState, setRefState] = React.useState();

  if (initialiseRef.current && refValue) {
    setRefState(refValue);
    initialiseRef.current = false;
  }
  return [refState, setRefState];
};

const TableBookingManager = ({ history, match, location }) => {
  const { userId: adminUserId } = useStoreState(state => state.auth);
  const [placeId, ,] = useRefState(match.params.placeId);

  const routeState = qs.parse(location.search);
  let { page, tab, tags, bookingStatusFilter, guestStatusFilter } = routeState;

  if (bookingStatusFilter) {
    // pass
  } else {
    bookingStatusFilter = '';
  }

  if (guestStatusFilter) {
    // pass
  } else {
    guestStatusFilter = '';
  }

  if (tags) {
    tags = atob(tags).split(',');
  } else {
    tags = [];
  }

  if (page) {
    page = parseInt(page, 10);
  } else {
    page = 1;
  }

  if (tab) {
    tab = parseInt(tab, 10);
  } else {
    tab = 0;
  }

  const [dateFilter, setDateFilter] = React.useState(new Date());
  React.useEffect(() => {
    if (tab !== 0) {
      setDateFilter(new Date());
    }
  }, [tab]);

  const [tagFilterModalActive, setTagFilterModalActive] = React.useState(false);
  const [
    statusFilterModalIsActive,
    setStatusFilterModalIsActive,
  ] = React.useState(false);
  const [
    chartsScreenModalIsActive,
    setChartsScreenModalIsActive,
  ] = React.useState(false);

  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  let bookingStatus = null;
  let guestStatus = null;

  if (tab === 0) {
    bookingStatus = bookingStatusFilter || null;
    guestStatus = guestStatusFilter || null;
  } else if (tab === 1) {
    bookingStatus = 'RECEIVED';
    guestStatus = null;
  } else if (tab === 2) {
    bookingStatus = null;
    guestStatus = 'UPCOMING';
  } else if (tab === 3) {
    bookingStatus = null;
    guestStatus = 'PREVIOUS';
  }

  const [
    tableBookingLoading,
    tableBookings,
    pageCount,
    totalElements,
  ] = useTableBookingsData({
    adminUserId,
    placeId,
    dateFilter:
      tab === 0
        ? dateFilter
            .toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
            .replaceAll('/', '-')
        : null,
    bookingStatus,
    guestStatus,
    tags,
    from: currentPage * 10,
  });

  const tableBookingAggregates = tableBookings.reduce(
    (prev, current) => {
      const temp = { ...prev };
      if (current.guest_count) {
        temp.guest_count += current.guest_count;
      }
      if (current.booking_status === 'CANCELLED') {
        temp.cancelled += 1;
      }
      if (current.guest_status === 'SEATED') {
        temp.seated += 1;
      }
      return temp;
    },
    {
      guest_count: 0,
      cancelled: 0,
      seated: 0,
    },
  );

  return (
    <Layout>
      <Header history={history} placeId={placeId} />
      <TabSection
        tab={tab}
        setTab={v => {
          history.push(
            `/table-booking-manager/${placeId}${qs.stringify(
              {
                ...routeState,
                tab: v,
                page: 0,
              },
              true,
            )}`,
          );
        }}
      />
      <FilterSection
        onClickAddBooking={() => history.push(`/add-modify-booking/${placeId}`)}
        tags={tags}
        bookingStatusFilter={bookingStatusFilter}
        guestStatusFilter={guestStatusFilter}
        openTagsFilterModal={() => setTagFilterModalActive(true)}
        openStatusFilterModal={() => setStatusFilterModalIsActive(true)}
        openChartsScreen={() => setChartsScreenModalIsActive(true)}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        disableDateSelector={tab !== 0}
      />
      <StatisticSection
        dateFilter={dateFilter}
        totalBookings={totalElements}
        totalGuestCount={tableBookingAggregates.guest_count}
        totalSeated={tableBookingAggregates.seated}
        totalCancelled={tableBookingAggregates.cancelled}
      />
      {totalElements > 0 && (
        <Pagination
          pageFrom={currentPage * 10}
          pageCount={pageCount}
          listSize={10}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            history.push(
              `/table-booking-manager/${placeId}${qs.stringify(
                {
                  ...routeState,
                  page: value.selected + 1,
                },
                true,
              )}`,
            );
          }}
        />
      )}
      <BookingManagerTable
        tableBookingLoading={tableBookingLoading}
        tableBookings={tableBookings}
        placeId={placeId}
        history={history}
      />
      {tagFilterModalActive && (
        <TagFilterModal
          initialTags={tags}
          isActiveModal={tagFilterModalActive}
          onClose={() => setTagFilterModalActive(false)}
          changeRoute={value => {
            history.push(
              `/table-booking-manager/${placeId}${qs.stringify(
                {
                  ...routeState,
                  tags: value,
                },
                true,
              )}`,
            );
          }}
        />
      )}

      {statusFilterModalIsActive && (
        <StatusFilterModal
          initialGuestStatusFilter={guestStatusFilter}
          initialBookingStatusFilter={bookingStatusFilter}
          isActiveModal={statusFilterModalIsActive}
          onClose={() => setStatusFilterModalIsActive(false)}
          changeRoute={values => {
            history.push(
              `/table-booking-manager/${placeId}${qs.stringify(
                {
                  ...routeState,
                  guestStatusFilter: values.guestStatusFilter,
                  bookingStatusFilter: values.bookingStatusFilter,
                },
                true,
              )}`,
            );
          }}
        />
      )}

      {chartsScreenModalIsActive && (
        <ChartsScreen
          isActiveModal={chartsScreenModalIsActive}
          onClose={() => setChartsScreenModalIsActive(false)}
        />
      )}
    </Layout>
  );
};

export default TableBookingManager;
