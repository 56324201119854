import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'rbx';
import { differenceBy, first } from 'lodash';
import { FieldArray } from 'formik';

import { Input, Select } from '../../../components/elements';
import { getTax } from '../../../utils/fetchStatic';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
  }
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const Wrapper = styled.div`
  padding-top: 2rem;
`;

const AddDeleteButton = ({ add, remove, showAddButton, hideDeleteButton }) => (
  <Button.Group hasAddons>
    {!hideDeleteButton && (
      <Button onClick={remove}>
        <Icon size="small">
          <i className="fas fa-trash has-text-grey" />
        </Icon>
      </Button>
    )}
    {showAddButton && (
      <Button onClick={add}>
        <Icon size="small">
          <i className="fas fa-plus has-text-grey" />
        </Icon>
      </Button>
    )}
  </Button.Group>
);

const TaxRow = ({
  tax,
  taxStore,
  index,
  item,
  setFieldValue,
  addTax,
  removeTax,
  helpers,
  values,
}) => {
  const currentTax = first(taxStore.filter(item1 => item1.name === item.type));
  if (!currentTax) {
    return '';
  }
  return (
    <div className="columns is-vcentered" key={index}>
      <div className="column is-3">
        <Select
          label="Tax Name"
          options={tax.map(item3 => ({
            label: item3.description,
            value: item3.name,
          }))}
          value={[{ value: currentTax.name, label: currentTax.description }]}
          onChange={value => setFieldValue(`tax.${index}.type`, value.value)}
          required
        />
      </div>
      <div className="column is-3">
        <Input label="Tax Code" value={currentTax.code} required disabled />
      </div>
      <div className="column is-3">
        <Input label="Tax Rate" value={currentTax.rate} required disabled />
      </div>
      <div className="column is-2">
        <Wrapper>
          <AddDeleteButton
            add={() => tax.length !== 0 && addTax(helpers, first(tax).name)}
            remove={() => {
              removeTax(item.type);
            }}
            showAddButton={index === values.tax.length - 1}
            hideDeleteButton={false}
          />
        </Wrapper>
      </div>
    </div>
  );
};

const TaxRowDefault = ({ addTax, helpers, tax }) => (
  <div className="columns is-vcentered">
    <div className="column is-3">
      <Select
        label="Tax Name"
        options={tax.map(item => ({
          label: item.description,
          value: item.name,
        }))}
        onChange={value => addTax(helpers, value.value)}
      />
    </div>
    <div className="column is-3">
      <Input label="Tax Code" required disabled />
    </div>
    <div className="column is-3">
      <Input label="Tax Rate" required disabled />
    </div>

    <div className="column is-2">
      <Wrapper>
        <AddDeleteButton
          add={() => addTax(helpers, first(tax).name)}
          remove={() => {}}
          showAddButton
          hideDeleteButton
        />
      </Wrapper>
    </div>
  </div>
);

const CompanyTaxDetails = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
}) => {
  const [taxStore, setTaxStore] = useState([]);
  const [tax, setTax] = useState([]);

  useEffect(() => {
    const fetchTax = async () => {
      const taxData = await getTax();
      const prevTaxes = values.tax.map(item => ({ name: item.type }));
      const newTaxData = differenceBy(taxData, prevTaxes, 'name');
      setTax(newTaxData);
      setTaxStore(taxData);
    };
    fetchTax();
  }, []);

  const addTax = (helpers, key) => {
    const currentTax = first(taxStore.filter(item => item.name === key));
    helpers.push({ type: currentTax.name, value: currentTax.rate });
    const newTaxes = tax.filter(item => item.name !== key);
    setTax(newTaxes);
  };

  const removeTax = type => {
    setValues({
      ...values,
      tax: values.tax.filter(item => item.type !== type),
    });
    console.log(
      'values.tax.filter(item => item.type !== type)',
      values.tax.filter(item => item.type !== type),
    );
    const prevTax = first(taxStore.filter(item => item.name === type));
    setTax(tax.concat(prevTax));
  };

  return (
    <Container>
      <FieldArray
        name="tax"
        render={helpers => (
          <React.Fragment>
            <fieldset className="box">
              {values.tax.map((item, index) => (
                <TaxRow
                  tax={tax}
                  taxStore={taxStore}
                  index={index}
                  item={item}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  addTax={addTax}
                  removeTax={removeTax}
                  helpers={helpers}
                  values={values}
                />
              ))}
              {values.tax.length === 0 && (
                <React.Fragment>
                  <TaxRowDefault
                    tax={tax}
                    taxStore={taxStore}
                    handleChange={handleChange}
                    addTax={addTax}
                    helpers={helpers}
                  />
                </React.Fragment>
              )}
            </fieldset>
          </React.Fragment>
        )}
      />
    </Container>
  );
};
export default CompanyTaxDetails;
