import React from 'react';
import { Column } from 'rbx';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { TextArea, Loading, Message } from '../../../components/elements';
import CommentUserDetails from './CommentUserDetails';

const commentQuery = gql`
  query search_comments($input: SearchInput) {
    search_comments(input: $input) {
      comment_listing {
        comment_id
        content
        status
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const Container = styled.div`
  margin-left: 0.8rem;
`;

const Comment = ({ flag }) => (
  <React.Fragment>
    <Query
      query={commentQuery}
      variables={{
        input: { filter: { comment_filter: { comment_id: flag.object_id } } },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Message>{error.message}</Message>;
        }
        const { search_comments } = data;

        return (
          <Container>
            <fieldset className="box">
              <legend className="label">Comment Details</legend>
              {search_comments.comment_listing.map(comment => (
                <Column.Group
                  multiline
                  vcentered
                  gapSize={8}
                  key={comment.comment_id}
                >
                  <CommentUserDetails
                    createdBy={comment.audit.created_by}
                    createdTime={comment.audit.created_at}
                  />
                  <Column size={11}>
                    <TextArea
                      label="Comment Content"
                      value={comment.content}
                      rows={4}
                      disabled
                    />
                  </Column>
                </Column.Group>
              ))}
            </fieldset>
            <br />
          </Container>
        );
      }}
    </Query>
  </React.Fragment>
);
export default Comment;
