import React, { useState, useEffect } from 'react';
import { Table } from 'rbx';
import { Query } from 'react-apollo';
import moment from 'moment';
import { first, sortBy, reverse } from 'lodash';
import gql from 'graphql-tag';
import BookingSummaryList from './BookingSummaryList';
import Header from './Header';
import GlobalFooter from './GlobalFooter';
import client from '../../../utils/apolloClient';
import BookingSummarySearch from './BookingSummarySearch';
import {
  Table as CustomTable,
  Loading,
  Message,
  Pagination,
} from '../../../components/elements';

const bookingsQuery = gql`
  query bookings($input: SearchInput) {
    search_event_bookings(input: $input) {
      total_pages
      total_size
      event_booking_listing {
        booking_id
        place_id
        event_id
        booking_number
        booking_status
        quantity
        total_attendee_count
        total_price
        total_absorb_fee
        discount
        timestamp
        total_fee
        url
        sales_method
        fee {
          name
          value
          service_type
        }
        payment {
          type
          amount
        }
        booking {
          purchased_by
          purchased_at
          booked_by
          booked_at
        }
        purchaser {
          first_name
          last_name
          email
        }
      }
    }
  }
`;

const Summary = ({ event }) => {
  const [eventData, setEventData] = useState([]);
  const [sort, setSort] = useState('PURCHASED_AT_DESC');
  const [dateSort, setDateSort] = useState('AES');
  const [bookingNumberSort, setBookingNumberSort] = useState('AES');
  const [purchasedBySort, setPurchasedBySort] = useState('AES');
  const [qtySort, setQtySort] = useState('AES');
  const [absorbFeeSort, setAbsorbFeeSort] = useState('AES');
  const [passOnFeeSort, setPassOnFeeSort] = useState('AES');
  const [priceSort, setPriceSort] = useState('AES');
  const [methodSort, setMethodSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');
  const [paymentTypeSort, setPaymentTypeSort] = useState('AES');
  const [bookingNumberSearchQuery, setBookingNumberSearchQuery] = useState('');
  const [purchasedBySearchInput, setPurchasedBySearchInput] = useState('');

  const [status, setStatus] = useState('ACTIVE');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(12);

  const input = {};
  const event_booking_filter = { event_id: event.event_id };

  Object.assign(
    event_booking_filter,
    purchasedBySearchInput !== '' && { purchased_by: purchasedBySearchInput },
    bookingNumberSearchQuery !== '' && {
      booking_number: bookingNumberSearchQuery,
    },

    // { end_date_filter: status },
  );

  Object.assign(
    input,

    { sort },
    { from: pageFrom, size: listSize },
    event_booking_filter !== {} && {
      filter: {
        event_booking_filter,
      },
    },
  );
  return (
    <React.Fragment>
      <Header
        event={event}
        subHeading3={moment(event.start.start).format('LLLL')}
      />
      <hr />

      <BookingSummarySearch
        status={status}
        setStatus={setStatus}
        purchasedBySearchInput={purchasedBySearchInput}
        setPurchasedBySearchInput={setPurchasedBySearchInput}
        bookingNumberSearchQuery={bookingNumberSearchQuery}
        setBookingNumberSearchQuery={setBookingNumberSearchQuery}
      />

      {totalElements > 0 && (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}

      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setBookingNumberSort(
                        bookingNumberSort === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        bookingNumberSort === 'AES'
                          ? 'BOOKING_NUMBER_DESC'
                          : 'BOOKING_NUMBER_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        bookingNumberSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Booking No
                </span>
              </div>
            </Table.Heading>

            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setQtySort(qtySort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        qtySort === 'AES' ? 'QUANTITY_DESC' : 'QUANTITY_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        qtySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Qty
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const absorbFee =
                        absorbFeeSort === 'AES'
                          ? sortBy(eventData, 'fee.value')
                          : reverse(eventData);
                      setEventData(absorbFee);
                      setAbsorbFeeSort(qtySort === 'AES' ? 'DES' : 'AES');
                    }}
                  >
                    <i
                      className={
                        absorbFeeSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Absorb Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const passOnFee =
                        passOnFeeSort === 'AES'
                          ? sortBy(eventData, 'fee.value')
                          : reverse(eventData);
                      setEventData(passOnFee);
                      setPassOnFeeSort(passOnFeeSort === 'AES' ? 'DES' : 'AES');
                    }}
                  >
                    <i
                      className={
                        passOnFeeSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Pass On Fee
                </span>
              </div>
            </Table.Heading>

            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setPriceSort(priceSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        priceSort === 'AES'
                          ? 'TOTAL_PRICE_DESC'
                          : 'TOTAL_PRICE_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        priceSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Price
                </span>
              </div>
            </Table.Heading>

            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setMethodSort(methodSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        methodSort === 'AES'
                          ? 'SALES_METHOD_DESC'
                          : 'SALES_METHOD_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        methodSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Method
                </span>
              </div>
            </Table.Heading>

            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const status =
                        statusSort === 'AES'
                          ? sortBy(eventData, 'status')
                          : reverse(eventData);
                      setEventData(status);
                      setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                    }}
                  >
                    <i
                      className={
                        statusSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Status
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const payment =
                        paymentTypeSort === 'AES'
                          ? sortBy(eventData, 'status')
                          : reverse(eventData);
                      setEventData(payment);
                      setPaymentTypeSort(
                        paymentTypeSort === 'AES' ? 'DES' : 'AES',
                      );
                    }}
                  >
                    <i
                      className={
                        paymentTypeSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                      //   onClick={() => {
                      //     setPaymentTypeSort(
                      //       paymentTypeSort === 'AES' ? 'DES' : 'AES',
                      //     );
                      //     setSort(
                      //       paymentTypeSort === 'AES'
                      //         ? 'PAYMENT_TYPE_DESC'
                      //         : 'PAYMENT_TYPE_ASC',
                      //     );
                      //   }}
                      // >
                      //   <i
                      //     className={
                      //       paymentTypeSort === 'AES'
                      //         ? 'fas fa-sort-amount-down'
                      //         : 'fas fa-sort-amount-up'
                      //     }
                    />
                  </a>{' '}
                  Payment Type
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const purchasedBy =
                        purchasedBySort === 'AES'
                          ? sortBy(eventData, 'booking.purchased_by')
                          : reverse(eventData);
                      setEventData(purchasedBy);
                      setPurchasedBySort(
                        purchasedBySort === 'AES' ? 'DES' : 'AES',
                      );
                    }}
                  >
                    <i
                      className={
                        purchasedBySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Purchased By
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setDateSort(dateSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        dateSort === 'AES'
                          ? 'PURCHASED_AT_DESC'
                          : 'PURCHASED_AT_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        dateSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Booking Date
                </span>
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Query
            client={client.clientPrivate}
            query={bookingsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.message}</Message>;
              }

              const { search_event_bookings } = data;

              const absorbFee = search_event_bookings.event_booking_listing.fee
                ? search_event_bookings.event_booking_listing.fee.filter(
                    item =>
                      item.name === 'offline_absorb_fee' ||
                      item.name === 'online_absorb_fee',
                  )
                : [];

              const passOnFee = search_event_bookings.event_booking_listing.fee
                ? search_event_bookings.event_booking_listing.fee.filter(
                    item =>
                      item.name === 'offline_passon_fee' ||
                      item.name === 'online_passon_fee',
                  )
                : [];

              const absorbFeeValue =
                absorbFee.length !== 0 ? first(absorbFee).value : 0;

              const passOnValue =
                passOnFee.length !== 0 ? first(passOnFee).value : 0;

              return (
                <React.Fragment>
                  <View
                    event={event}
                    bookings={first(
                      search_event_bookings.event_booking_listing,
                    )}
                    eventData={eventData}
                    setEventData={setEventData}
                    passOnValue={passOnValue}
                    absorbFeeValue={absorbFeeValue}
                    search_event_bookings={search_event_bookings}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table.Body>
      </CustomTable>

      <GlobalFooter />
    </React.Fragment>
  );
};

export default Summary;

const View = ({
  search_event_bookings,
  event,
  setPageCount,
  setTotalElements,
  setEventData,
  bookings,
  eventData,
  passOnValue,
  absorbFeeValue,
}) => {
  useEffect(() => {
    setPageCount(search_event_bookings.total_pages);
    setTotalElements(search_event_bookings.total_size);
    if (search_event_bookings.event_booking_listing) {
      setEventData(search_event_bookings.event_booking_listing);
    }
  }, [search_event_bookings.total_pages]);

  return (
    <React.Fragment>
      {eventData.map(booking => (
        <Table.Row>
          <BookingSummaryList
            booking={booking}
            event={event}
            bookings={bookings}
            passOnValue={passOnValue}
            absorbFeeValue={absorbFeeValue}
          />
        </Table.Row>
      ))}
    </React.Fragment>
  );
};
