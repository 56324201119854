import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { withFormik } from 'formik';

import * as yup from 'yup';
import gql from 'graphql-tag';

import { Input, TextArea, CustomSelect } from '../../../components/elements';

const Container = styled.div``;

const ticketRecordQuery = gql`
  query searchEventTicketRecord($ticketId: String) {
    search_event_ticket_records(
      input: {
        filter: { event_ticket_record_filter: { ticket_id: $ticketId } }
      }
    ) {
      ticket_record_listing {
        booking_id
        ticket_record_id
        booking_number
        ticket_number
        ticket_type
        sales_method
        attendee {
          attendee_name
          attendee_age
          attendee_email
          attendee_phone
          attendee_gender
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      first_name
      last_name
      email
    }
  }
`;

const updateTicketRecord = gql`
  mutation update_ticket_record($input: TicketRecordPerAttendeeInput) {
    update_ticket_record(input: $input) {
      place_id
      error {
        description
      }
    }
  }
`;

const Form = props => {
  const { values, handleChange, handleBlur } = props;
  console.log('values', values);
  return (
    <>
      <Container>
        <Column.Group vcentered multiline gapSize={8}>
          <Column size={4}>
            <Input label="Booking No" placeholder="hello" disabled />
          </Column>

          <Column size={4}>
            <Input label="Ticket No" placeholder="N0FZQRNG-p120Kx4H" disabled />
          </Column>
        </Column.Group>

        <hr />

        <Column.Group vcentered multiline gapSize={8}>
          <Column size={4}>
            <Input
              label="Booked By"
              name="bookedBy"
              value={values.bookedBy}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Email Id"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Date And Time"
              name="dateAndTime"
              value={values.dateAndTime}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Purchased By"
              name="purchasedBy"
              value={values.purchasedBy}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Ticket Type"
              name="ticketType"
              value={values.ticketType}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Ticket Sales Method"
              name="ticketSalesMethod"
              value={values.ticketSalesMethod}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>
        </Column.Group>
        <br />

        <Column.Group vcentered multiline gapSize={8}>
          <Column size={6}>
            <Input
              label="Attendee Name"
              name="name"
              type="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>

          <Column size={6}>
            <Input
              label="Attendee Mobile"
              type="number"
              name="mobile"
              value={values.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>

          <Column size={6}>
            <Input
              label="Attendee Email"
              type="email"
              name="attendeeEmail"
              value={values.attendeeEmail}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>

          <Column size={3}>
            <Input
              label="Attendee Age"
              name="age"
              type="number"
              value={values.age}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>

          <Column size={3}>
            <CustomSelect
              options={[
                { value: '', label: 'Gender' },
                { value: 'MALE ', label: 'MALE' },
                { value: 'FEMALE', label: 'FEMALE' },
                { value: 'OTHER', label: 'OTHER' },
              ]}
            />
          </Column>

          <Column size={8} rows={4}>
            <TextArea
              label="Attendee Notes"
              name="notes"
              value={values.notes}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>
        </Column.Group>

        <br />
      </Container>
    </>
  );
};

const EditAttendeeInfoForm = withFormik({
  mapPropsToValues: () => ({
    attendee_name: '',
    attendee_phone: '',
    attendee_email: '',
    attendee_age: '',
    attendee_gender: '',
    notes: '',
  }),
  validationSchema: yup.object().shape({
    mobile: yup.number(),
    // email: yup.string().email(),
    // age: yup.string().number(),
  }),
  handleSubmit: (values, { setSubmitting }) => {
    console.log('values', values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },
  displayName: 'EditAttendeeInfoForm',
})(Form);

// const EditAttendeeInfo = ({ match, history }) => {
//   const { userId } = useStoreState(state => state.auth);
//   return (
//     <Layout>
//       <Query
//         client={client.clientPrivate}
//         query={ticketRecordQuery}
//         variables={{ ticketId: match.params.ticketId }}
//       >
//         {({ data, loading, error }) => {
//           if (loading) {
//             return <Loading />;
//           }
//           if (error) {
//             return 'error';
//           }

//           const record = first(
//             data.search_event_ticket_records.ticket_record_listing,
//           );
//           return (
//             <Mutation
//               client={client.clientPrivate}
//               mutation={updateTicketRecord}
//               onCompleted={() => {
//                 swal(
//                   'Great!',
//                   'Attendee details updated successfully!!',
//                   'success',
//                 );
//               }}
//             >
//               {(update_ticket_record, { loading: updateLoading }) => (
//                 <EditAttendeeInfoForm
//                   loading={updateLoading}
//                   record={record}
//                   history={history}
//                   onSubmit={input => {
//                     console.log('input', input);
//                     update_ticket_record({
//                       variables: {
//                         input: {
//                           user_id: userId,
//                           ticket_record_id: record.ticket_record_id,
//                           attendee: {
//                             attendee_name: input.attendee_name,
//                             attendee_email: input.attendee_email,
//                             attendee_age: parseInt(input.attendee_age, 10),
//                             attendee_gender: input.attendee_gender,
//                             attendee_phone: input.attendee_phone,
//                           },
//                         },
//                       },
//                     });
//                   }}
//                 />
//               )}
//             </Mutation>
//           );
//         }}
//       </Query>
//     </Layout>
//   );
// };

export default EditAttendeeInfoForm;
