import React from 'react';

import { Column } from 'rbx';
import {
  Select,
  Input,
  CreatableSelectInputs,
} from '../../../components/elements';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

// const approvalStatus = [
//   { value: 'APPROVED', label: 'APPROVED' },
//   { value: 'SUBMITTED', label: 'SUBMITTED' },
//   { value: 'REJECTED', label: 'REJECTED' },
// ];

const components = {
  DropdownIndicator: null,
};

const placeTagInput = tagsValue => {
  const tags = [];
  tagsValue.map(item => {
    tags.push(item.value);
    return null;
  });
  return tags;
};

// const parseData = parseValue => {
//   const final = [];
//   forEach(parseValue, item => {
//     final.push({ value: item, label: item });
//   });
//   return final;
// };

const PrimaryDetailsForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  <div>
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={6}>
        <Input
          label="Event name"
          name="name"
          id="EventManagement_AddEditEvent_EventName"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          optionalText="( Min 10 & Max 75 Characters )"
          placeholder="Required ( eg.. Dinner Dance, Father Day Breakfast)"
          errors={errors.name}
          touched={touched.name}
        />
      </Column>
      <Column size={5}>
        <Select
          label="Status"
          options={status}
          value={[{ value: values.status, label: values.status }]}
          onChange={({ value }) => setFieldValue('status', value.toUpperCase())}
          required
        />
      </Column>

      <Column size={6}>
        <Input
          label="Venue Name"
          name="venue_name"
          id="EventManagement_AddEditEvent_VenueName"
          value={values.venue_name}
          onChange={handleChange}
          onBlur={handleBlur}
          optionalText="( Max 50 Character )"
          placeholder="Optional"
          errors={errors.venue_name}
          touched={touched.venue_name}
        />
      </Column>

      {/* <Column size={5}>
        <Select
          label="Approval Status"
          options={approvalStatus}
          value={[
            {
              value: values.approval_status,
              label: values.approval_status,
            },
          ]}
          onChange={({ value }) =>
            setFieldValue('approval_status', value.toUpperCase())
          }
          required
        />
      </Column> */}
      <Column size={5}>
        <Input
          label="Event Url"
          name="slug"
          value={values.slug}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={values.slug === ''}
          required
        />
      </Column>
      <Column size={11}>
        <CreatableSelectInputs
          components={components}
          label="Event Tags / Keywords"
          id="EventManagement_AddEditEvent_EventTags"
          suggestions={[]}
          value={values.tag.map(item => ({ value: item, label: item }))}
          onChange={value => {
            if (value) {
              setFieldValue('tag', placeTagInput(value));
            }
          }}
        />
      </Column>
    </Column.Group>
  </div>
);

export default PrimaryDetailsForm;
