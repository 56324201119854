/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import uuidv4 from 'uuid/v4';

import { cognitoSignIn } from '../utils/auth';
import client from '../utils/apolloClient';
import { updateMutation } from '../pages/UserManagement/Customer/Add';
import { requestPermission } from '../utils/firebase';

const fetchUserQuery = gql`
  query fetch_user($input: NavigateUserInput) {
    fetch_user(input: $input) {
      status
      user_id
      first_name
      last_name
      display_name
      email
      color_code
      preferred_username
      role
    }
  }
`;

const createUserLoginHistoryAPI = gql`
  mutation createUserLoginHistory($input: UserLoginHistoryInput) {
    create_user_login_history(input: $input) {
      user_id
    }
  }
`;

export const createUserLoginHistory = async ({ input }) => {
  try {
    const unique_id = uuidv4();
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(async data => {
        const ipAddress = data.ip;
        await client.clientPrivate.mutate({
          mutation: createUserLoginHistoryAPI,
          variables: {
            input: {
              app_type: 'ADMIN_APP',
              platform: 'WEB',
              logged_in: true,
              user_type: 'KRAVEIN',
              login_type: 'DIRECT',
              web_browser: window?.navigator?.userAgent,
              ...input,
              ip_address: ipAddress,
              unique_id,
            },
          },
          client: client.clientPrivate,
        });
      })
      .catch(async error => {
        await client.clientPrivate.mutate({
          mutation: createUserLoginHistoryAPI,
          variables: {
            input: {
              app_type: 'ADMIN_APP',
              platform: 'WEB',
              logged_in: true,
              user_type: 'KRAVEIN',
              login_type: 'DIRECT',
              web_browser: window?.navigator?.userAgent,
              ...input,
              ip_address: 'ERROR RETRIEVING IP ADDRESS',
              unique_id,
            },
          },
          client: client.clientPrivate,
        });
        console.error('Error:', error);
      });
    window.localStorage.setItem('history_unique_id', unique_id);
  } catch (error) {
    console.log(error);
  }
};

const updateUserLoginHistoryAPI = gql`
  mutation updateUserLoginHistory($input: UserLoginHistoryInput) {
    update_user_login_history(input: $input) {
      user_id
    }
  }
`;

const updateUserLoginHistory = async ({ userId }) => {
  let unique_id = '';
  try {
    unique_id = window.localStorage.getItem('history_unique_id');
  } catch {
    // pass
  }

  try {
    window.localStorage.removeItem('history_unique_id');
  } catch {
    // pass
  }

  try {
    await client.clientPrivate.mutate({
      mutation: updateUserLoginHistoryAPI,
      variables: {
        input: {
          logged_in: false,
          user_type: 'KRAVEIN',
          user_id: userId,
          unique_id,
        },
      },
      client: client.clientPrivate,
    });
  } catch (error) {
    console.log(error);
  }
};

export default {
  menu: {
    defaultPlace: null,
    setDefaultPlace: action((state, payload) => {
      state.defaultPlace = payload;
    }),
    defaultPlaceId: null,
    setDefaultPlaceId: action((state, payload) => {
      state.defaultPlaceId = payload;
    }),
  },
  auth: {
    userId: null,
    user: {},
    userStatus: 'ACTIVE',
    isLoggedIn: false,
    authTokens: {},
    loading: false,
    isError: { message: '', error: false },

    setAuthTokens: action((state, payload) => {
      state.authTokens = payload;
      state.isLoggedIn = true;
      state.userId = payload.payload['custom:user_id'];
    }),

    setError: action((state, payload) => {
      state.isError = payload;
    }),

    clearError: action(state => {
      state.isError = { message: '', error: false };
    }),

    setLoading: action((state, payload) => {
      state.loading = payload;
    }),

    updateUser: action((state, payload) => {
      state.user = payload;
    }),

    logIn: thunk(async (actions, payload) => {
      const { username, password, history } = payload;
      try {
        actions.setError({ message: '', error: false });
        actions.setLoading(true);
        const data = await cognitoSignIn(username, password);
        await window.localStorage.setItem('token', data.idToken);
        await window.localStorage.setItem('username', username);
        await window.localStorage.setItem('accessToken', data.accessToken);
        await window.localStorage.setItem('refreshToken', data.refreshToken);

        requestPermission(async token =>
          client.clientPrivate.mutate({
            mutation: updateMutation,
            variables: {
              input: {
                user_id: data.payload['custom:user_id'],
                device_token: {
                  device_token: token,
                  app_type: 'KRAVEIN',
                  logged_in: true,
                  platform: 'WEB',
                },
              },
            },
          }),
        );

        await client.clientPrivate
          .query({
            query: fetchUserQuery,
            variables: { input: { user_id: data.payload['custom:user_id'] } },
            fetchPolicy: 'network-only',
          })
          .then(async ({ data: result }) => {
            if (result.fetch_user && result.fetch_user.status === 'INACTIVE') {
              swal({ text: 'This user does not exist' }).then(async () => {
                await actions.logout();
                window.location.reload();
              });
            } else {
              createUserLoginHistory({
                input: {
                  email: result.fetch_user.email,
                  first_name: result.fetch_user.first_name,
                  last_name: result.fetch_user.last_name,
                  user_id: result.fetch_user.user_id,
                  unique_id: '',
                  ip_address: '',
                  build_number: '',
                  device_name: '',
                  model_number: '',
                  version_number: '',
                },
              });
              actions.updateUser(result.fetch_user);
              await actions.setAuthTokens(data);
              await actions.setLoading(false);
              history.push('/');
            }
          });
      } catch (err) {
        const error = {
          message: 'Please Enter valid email address and password',
          error: true,
        };
        await actions.setError(error);
        console.log('err', err);
        await actions.setLoading(false);
      }
    }),
    logout: action((state, payload) => {
      const { userId } = state;
      updateUserLoginHistory({ userId });
      state.user = null;
      state.loading = false;
      state.isError = { message: '', error: false };
      state.isLoggedIn = false;
      state.authTokens = {};
      state.token = null;
      state.userId = null;

      requestPermission(async token =>
        client.clientPrivate.mutate({
          mutation: updateMutation,
          variables: {
            input: {
              user_id: userId,
              device_token: {
                device_token: token,
                app_type: 'KRAVEIN',
                logged_in: false,
                platform: 'WEB',
              },
            },
          },
        }),
      );

      (payload ?? []).push('/login');
    }),
  },
};
