import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { capitalize, first, xorBy } from 'lodash';
import { Button, Icon } from 'rbx';

import { Select, Input, CheckBox } from './elements';
import { ACLSConsumer } from '../utils/aclsContext';
import Can from '../utils/Can';

const types = [
  {
    value: 'instagram',
    label: 'Instagram',
  },
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'twitter',
    label: 'Twitter',
  },
  {
    value: 'linkedin',
    label: 'Linkedin',
  },
  {
    value: 'youtube',
    label: 'Youtube',
  },
];

const Wrapper = styled.div`
  padding-top: 2rem;
`;

const AddDeleteButton = ({
  add,
  remove,
  showAddButton,
  hideDeleteButton,
  action,
}) => {
  if (action) {
    return (
      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action={action}
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => (
              <Wrapper>
                <Button.Group hasAddons>
                  {showAddButton && (
                    <Button onClick={add}>
                      <Icon size="small">
                        <i className="fas fa-plus has-text-grey" />
                      </Icon>
                    </Button>
                  )}

                  {!hideDeleteButton && (
                    <Button onClick={remove}>
                      <Icon size="small">
                        <i className="fas fa-trash has-text-grey" />
                      </Icon>
                    </Button>
                  )}
                </Button.Group>
              </Wrapper>
            )}
          />
        )}
      </ACLSConsumer>
    );
  }
  return (
    <Wrapper>
      <Button.Group hasAddons>
        {showAddButton && (
          <Button onClick={add}>
            <Icon size="small">
              <i className="fas fa-plus has-text-grey" />
            </Icon>
          </Button>
        )}

        {!hideDeleteButton && (
          <Button onClick={remove}>
            <Icon size="small">
              <i className="fas fa-trash has-text-grey" />
            </Icon>
          </Button>
        )}
      </Button.Group>
    </Wrapper>
  );
};

const Socials = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
  action,
}) => {
  const filterTypes = () => {
    const data = [];
    values.social.map(dta =>
      data.push({ value: dta.type, label: capitalize(dta.type) }),
    );
    const filters = xorBy(data, types, 'value');
    return filters;
  };

  const addSocial = helpers => {
    // const order = values.social.length + 1;
    helpers.push({
      type: first(filterTypes()).value,
      logo: '',
      description: '',
      value: '',
      // display_order: order.toString(8),
      display: false,
    });
  };

  const removeSocial = type => {
    setValues({
      ...values,
      social: values.social.filter(item => item.type !== type),
    });
  };

  return (
    <FieldArray
      name="social"
      render={helpers => (
        <React.Fragment>
          {values.social.length === 0 && (
            <div className="columns is-vcentered">
              <div className="column is-3">
                <Select
                  action={action}
                  label="Social Types"
                  options={filterTypes()}
                  onChange={() => addSocial(helpers)}
                />
              </div>
              <div className="column is-4">
                <Input action={action} label="Value" />
              </div>
              <div className="column">
                <AddDeleteButton
                  action={action}
                  add={() => addSocial(helpers)}
                  showAddButton
                  hideDeleteButton
                />
              </div>
            </div>
          )}
          {values.social.length !== 0 && (
            <React.Fragment>
              {values.social.map((item, index) => (
                <div className="columns is-vcentered" key={item.type}>
                  <div className="column is-3">
                    <Select
                      action={action}
                      label="Social Types"
                      options={filterTypes()}
                      value={[
                        { value: item.type, label: capitalize(item.type) },
                      ]}
                      onChange={value =>
                        setFieldValue(`social.${index}.type`, value.value)
                      }
                    />
                  </div>
                  <div className="column is-4">
                    <Input
                      action={action}
                      label={capitalize(item.type)}
                      name={`social.${index}.value`}
                      value={item.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="column is-2">
                    <Wrapper>
                      <CheckBox
                        action={action}
                        label="Display"
                        value={values.social[index].display}
                        onChange={value => {
                          setFieldValue(`social.${index}.display`, value);
                        }}
                      />
                    </Wrapper>
                  </div>
                  <div className="column">
                    <AddDeleteButton
                      action={action}
                      add={() => addSocial(helpers)}
                      remove={() => removeSocial(item.type)}
                      showAddButton={
                        values.social.length === 5
                          ? false
                          : index === values.social.length - 1
                      }
                      hideDeleteButton={false}
                    />
                  </div>
                </div>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    />
  );
};

export default Socials;
