/* eslint-disable react/no-array-index-key */
import React from 'react';
import Loading from 'react-loading';
import styled from 'styled-components';
import { Table } from '../../components/elements';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DrilldownTable = ({ drilldownTableData, loading }) => (
  <div style={{ padding: '10px' }}>
    <Table>
      <thead>
        <tr>
          {[
            'Venue',
            'All Orders',
            'All Sales',
            'App Fees',
            'Payout',
            'Misd Order',
            'Draft Order',
            'Avg Order',
            'Delivery',
            'Sales',
            'Dinein',
            'Sales',
            'Pickup',
            'Sales',
            'Del Fees',
            'Srv Fees',
            'K Fees',
            'F Fees',
            'B Fees',
          ].map((headerCellValue, index) => (
            <th style={{ fontWeight: 700 }} key={`${headerCellValue}-${index}`}>
              <div>
                <span>{headerCellValue}</span>
              </div>
            </th>
          ))}
        </tr>
      </thead>

      {!loading && drilldownTableData && (
        <tbody>
          {drilldownTableData.map((ddValue, index) => (
            <tr style={{ fontWeight: 600 }} key={`${ddValue.venue}-${index}`}>
              <td style={{ color: '#00AEEF', whiteSpace: 'nowrap' }}>
                {ddValue.venue}
              </td>
              <td>{ddValue.allOrders}</td>
              <td>{ddValue.allSales}</td>
              <td>{ddValue.appfee}</td>
              <td>{ddValue.payout}</td>
              <td>{ddValue.missedOrders}</td>
              <td>-</td>
              <td>{ddValue.averageSales}</td>
              <td>{ddValue.deliveryTotalOrders}</td>
              <td>{ddValue.deliverySales}</td>
              <td>{ddValue.dineinTotalOrders}</td>
              <td>{ddValue.dineinSales}</td>
              <td>{ddValue.pickupTotalOrders}</td>
              <td>{ddValue.pickupSales}</td>
              <td>{ddValue.delFees}</td>
              <td>{ddValue.srvfee}</td>
              <td>{ddValue.kFees}</td>
              <td>{ddValue.fFees}</td>
              <td>{ddValue.bFees}</td>
            </tr>
          ))}
        </tbody>
      )}
    </Table>
    {loading && (
      <Center>
        <Loading type="spin" color="#363636" width="60px" height="60px" />
      </Center>
    )}
  </div>
);

export default DrilldownTable;
