import gql from 'graphql-tag';
import uuid from 'uuid';
import { isNull, omit, forEach, first } from 'lodash';
import moment from 'moment';

import client from '../../../utils/apolloClient';

const searchServiceSettingsQuery = gql`
  query searchSettings($input: SearchInput) {
    search_service_type_setting(input: $input) {
      service_type_setting_listing {
        service_type_setting_id
        place_id
        service_type
        status
        approval_status
        is_custom_service
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getServiceTypeSettings = ({ placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchServiceSettingsQuery,
        variables: {
          input: {
            filter: {
              service_type_setting_filter: {
                place_id: placeId,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_service_type_setting &&
          data.search_service_type_setting.service_type_setting_listing
        ) {
          resolve(
            data.search_service_type_setting.service_type_setting_listing,
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const fetchDisplayMenuHours = gql`
  query fetchDisplayMenuHours($service_type_setting_id: String) {
    fetch_service_type_setting(
      input: { service_type_setting_id: $service_type_setting_id }
    ) {
      display_menu_hours
    }
  }
`;

export const getDisplayMenuHours = ({ service_type_setting_id }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: fetchDisplayMenuHours,
        variables: {
          service_type_setting_id,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.fetch_service_type_setting &&
          !isNull(data.fetch_service_type_setting.display_menu_hours)
        ) {
          resolve(data.fetch_service_type_setting.display_menu_hours);
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const initialTime = {
  id: uuid(),
  start: '',
  end: '',
  end_in_sec: null,
  start_in_sec: null,
};

export const defaultHours = [
  {
    day: 'MON',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'TUE',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'WED',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'THU',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'FRI',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'SAT',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
  {
    day: 'SUN',
    is_active: false,
    open24Hours: false,
    option: [initialTime],
  },
];

export const parseFinalInputHours = hours => {
  const temp = [];
  forEach(hours, item => {
    const hourFinal = omit(item, ['open24Hours', 'option', '__typename']);
    const temp2 = [];
    forEach(item.option, item2 => {
      const finalOption = omit(item2, ['id', '__typename']);
      if (
        finalOption.start &&
        finalOption.end &&
        finalOption.end.length !== 0 &&
        finalOption.start.length !== 0
      ) {
        temp2.push(finalOption);
      }
    });
    temp.push({ ...hourFinal, is_active: temp2.length !== 0, option: temp2 });
  });
  return temp;
};

const checkOpen24Hours = option => {
  if (option.length === 1) {
    const time = first(option);
    if (time.start_in_sec === 0 && time.end_in_sec === 86340) {
      return true;
    }
  }
  return false;
};

export const addIdInputHours = hours => {
  const temp = [];
  forEach(hours, item => {
    const hourFinal = omit(item, ['option', '__typename']);
    const temp2 = [];
    forEach(item.option, item2 => {
      const finalOption = omit(item2, ['__typename']);
      temp2.push({ id: uuid(), ...finalOption });
    });
    const open24Hours = checkOpen24Hours(temp2);
    temp.push({
      ...hourFinal,
      option: temp2,
      open24Hours,
    });
  });
  const final = temp.map(item => ({
    ...item,
    option:
      item.option.length !== 0
        ? item.option
        : [{ id: uuid(), start: '', end: '' }],
  }));
  return final;
};

const getCurrentDay = () => {
  const daysObj = {
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT',
    7: 'SUN',
  };
  return daysObj[moment().isoWeekday()];
};

const fetchMenuOpenClose = gql`
  query menuOpenClose($input: NavigateMenuOpenCloseInput) {
    fetch_menu_open_close(input: $input) {
      menu_id
      hours
      menu_hour {
        day
        option {
          type
          start
          end
          start_in_sec
          end_in_sec
        }
      }
    }
  }
`;

export const getOpenCloseStatus = menu_id =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: fetchMenuOpenClose,
        variables: {
          input: {
            menu_id,
            day: getCurrentDay(),
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.fetch_menu_open_close) {
          resolve({
            hours: data?.fetch_menu_open_close?.hours,
            day: data?.fetch_menu_open_close?.menu_hour?.day,
            option: data?.fetch_menu_open_close?.menu_hour?.option,
          });
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const updateMenuOpenClose = gql`
  mutation updateMenuOpenClose($input: MenuOpenCloseInput) {
    update_menu_open_close(input: $input) {
      menu_id
      hours
    }
  }
`;

export const updateOpenCloseStatus = (
  menu_id,
  hours,
  startCallback = () => {},
  endCallback = () => {},
) =>
  new Promise((resolve, reject) => {
    startCallback();
    client.clientPrivate
      .mutate({
        client: client.clientPrivate,
        mutation: updateMenuOpenClose,
        variables: {
          input: {
            menu_id,
            hours,
          },
        },
      })
      .then(({ data }) => {
        resolve(data);
        endCallback(true);
      })
      .catch(error => {
        reject(error);
        endCallback(false);
      });
  });
