import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { startCase } from 'lodash';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({
  history,
  loading,
  dirty,
  children,
  serviceType,
  activeTab,
  prev,
  next,
  values,
  location,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">Menu Service Settings</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-grey">
            <span className="has-text-weight-semibold">Cancel</span>
          </a>
        </Level.Item>
        {activeTab !== 0 && (
          <Level.Item>
            <button type="button" onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/add-menu-service-setting'
                  ? 'create_service_type_setting'
                  : 'update_service_type_setting'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item>
                  <button
                    type="submit"
                    className={`button is-primary ${loading && 'is-loading'}`}
                    disabled={!dirty}
                  >
                    <span className="has-text-weight-semibold">Submit</span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>

        {values.service_type === 'Delivery' ? (
          <>
            {activeTab !== 2 && (
              <Level.Item>
                <button
                  type="button"
                  onClick={next}
                  className="button is-primary"
                >
                  <span>Next Tab</span>
                </button>
              </Level.Item>
            )}
          </>
        ) : (
          <>
            {activeTab !== 1 && (
              <Level.Item>
                <button
                  type="button"
                  onClick={next}
                  className="button is-primary"
                >
                  <span>Next Tab</span>
                </button>
              </Level.Item>
            )}
          </>
        )}
      </Level.Item>
    </Level>
    <p className="is-size-2">{startCase(serviceType)}</p>
    {children}
  </Wrapper>
);

export default withRouter(Header);
