import React from 'react';
import styled from 'styled-components';
import { Field, Label } from 'rbx';

import {
  // CheckBox,
  // Radio,
  TextArea,
  CheckBox,
  InputAddOn,
  Radio,
  SelectTime,
  InputErrorMessage,
} from '../../../../components/elements';

const Container = styled.div`
  padding: 1rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
`;

const CateringMenuSetting = ({ values, setFieldValue, errors, touched }) => {
  console.log(errors);
  return (
    <Container>
      <br />
      <Field className="is-flex">
        <Label className="is-flex">
          Catering Menu&nbsp;&nbsp;{' '}
          <CheckBox
            onChange={() => {
              setFieldValue('is_catering_menu', !values.is_catering_menu);
              if (!values.is_catering_menu === false) {
                setFieldValue('schedule_order_in_advance', false);
                setFieldValue('is_note', false);
                setFieldValue('lead_time', 0);
                setFieldValue('schedule_order_duration', 0);
                setFieldValue('menu_cut_off_time', '');
                setFieldValue('notes', '');
              }
            }}
            value={values.is_catering_menu}
          />
        </Label>
      </Field>
      <InputAddOn
        label="Advance Order Days"
        type="number"
        min="0"
        step="1"
        disableToastMessage
        required
        value={values.schedule_order_duration}
        onChange={e => setFieldValue('schedule_order_duration', e.target.value)}
        disabled={values.is_catering_menu === false}
        errors={errors.schedule_order_duration}
        touched={touched.schedule_order_duration}
      />

      <GridContainer>
        <Label className="is-flex">Lead Type</Label>
        <Radio
          label="Days"
          value={values.lead_type === 'DAYS'}
          onChange={v => {
            if (v) {
              setFieldValue('lead_type', 'DAYS');
            } else {
              setFieldValue('lead_type', 'HOURS');
              setFieldValue('menu_cut_off_time', '');
            }
          }}
        />
        <Radio
          label="Hours"
          value={values.lead_type === 'HOURS'}
          onChange={v => {
            if (v) {
              setFieldValue('lead_type', 'HOURS');
              setFieldValue('menu_cut_off_time', '');
            } else {
              setFieldValue('lead_type', 'DAYS');
            }
          }}
        />
        <div />
        <div />
        <Label className="is-flex">Lead Time</Label>
        <InputAddOn
          type="number"
          min="0"
          step="1"
          onChange={e => setFieldValue('lead_time', e.target.value)}
          value={values.lead_time}
          disableToastMessage
          errors={errors.lead_time}
          touched={touched.lead_time}
          required
          disabled={values.is_catering_menu === false}
        />
        <Label className="is-flex">Cut Off Time</Label>
        <SelectTime
          value={values.menu_cut_off_time}
          onChange={e => setFieldValue('menu_cut_off_time', e.time)}
          disabled={
            values.is_catering_menu === false || values.lead_type === 'HOURS'
          }
        />
        <InputErrorMessage
          errors={errors.menu_cut_off_time}
          touched={touched.menu_cut_off_time}
          disableToastMessage
        />
        <div />
        <Label className="is-flex">Payment Options&nbsp;&nbsp;</Label>
        <Radio label="Full Payment" value />
        <div />
        <div />
        <div />
        <div />
        <Radio label="Partial Payment" value={false} disabled />
        <InputAddOn type="number" min="0" step="1" disabled />
      </GridContainer>

      <div>
        <Label className="is-flex">
          Publish Catering Terms and Conditions&nbsp;&nbsp;
          <CheckBox
            onChange={() => {
              setFieldValue('is_note', !values.is_note);
            }}
            value={values.is_note}
          />
        </Label>
        <TextArea
          name="notes"
          label="Catering Terms and Conditions"
          disableToastMessage
          value={values.notes}
          onChange={e => setFieldValue('notes', e.target.value)}
          disabled={values.is_catering_menu === false}
          errors={errors.notes}
          touched={touched.notes}
          optionalText="(Min 5 and Max 500 Characters)"
        />
        {values.notes && (
          <div style={{ textAlign: 'right' }}>
            {values.notes.length > 500 || values.notes.length < 5
              ? ''
              : `Characters left ${500 - values.notes.length}`}
          </div>
        )}
      </div>
    </Container>
  );
};

export default CateringMenuSetting;
