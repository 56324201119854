import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';

import styled from 'styled-components';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({
  loading,
  handleSubmit,
  next,
  prev,
  activeTab,
  location,
  history,
  dirty,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-light">
              <span>Cancel</span>
            </a>
          </Level.Item>
        </Level.Item>

        {activeTab !== 0 && location.pathname !== '/add-place-posting' && (
          <Level.Item>
            <button onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
      <Level.Item align="right">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/add-place-posting'
                  ? 'create_feed'
                  : 'update_feed'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item>
                  <button
                    className={`button is-primary ${loading && 'is-loading'}`}
                    onClick={handleSubmit}
                    disabled={!dirty}
                  >
                    <span>Submit</span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
        {activeTab !== 3 && location.pathname !== '/add-place-posting' && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next Tab</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
