import React, { useState } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';

import PlaceSuggestionInput from './PlaceSuggestionInput';
import { Input, Select } from '../../../components/elements';

const Container = styled.div`
  &&& {
    .modal-card-head,
    .modal-card-foot {
      justify-content: space-between;
    }
  }
`;

const AddItemModal = ({
  isActive,
  onClose,
  itemName,
  dictionaryStatus,
  loading,
  onSubmit,
}) => {
  const [place, setPlace] = useState(null);
  const [status, setStatus] = useState(dictionaryStatus);

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <form
        onSubmit={e => {
          e.preventDefault();
          if (place) {
            onSubmit({ place, status });
          }
        }}
      >
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add Item</p>
            <button
              type="button"
              className="delete"
              aria-label="close"
              onClick={onClose}
            />
          </header>
          <section className="modal-card-body">
            <Column.Group vcentered multiline gapSize={8}>
              <Column size={6}>
                <Input label="Item Name" value={itemName} disabled />
              </Column>

              <Column size={6}>
                <PlaceSuggestionInput
                  label="Search place"
                  onSelect={value => {
                    setPlace(value);
                  }}
                />
              </Column>
              <Column size={6}>
                <Input
                  label="Place Name"
                  value={place && place.name}
                  disabled
                />
              </Column>
              <Column size={6}>
                <Input
                  label="Suburb / City"
                  value={place && place.city}
                  disabled
                />
              </Column>
              <Column size={4}>
                <Select
                  label="Item Status"
                  options={[
                    { value: 'ACTIVE', label: 'ACTIVE' },
                    { value: 'INACTIVE', label: 'INACTIVE' },
                  ]}
                  value={[{ value: status, label: status }]}
                  onChange={({ value }) => {
                    setStatus(value);
                  }}
                  disabled
                />
              </Column>
            </Column.Group>
            <br />
          </section>

          <footer className="modal-card-foot">
            <button type="button" className="button is-light" onClick={onClose}>
              Cancel
            </button>
            <button
              type="submit"
              className={`button is-primary ${loading && 'is-loading'}`}
              onClick={() => {}}
            >
              Submit
            </button>
          </footer>
        </div>
      </form>
    </Container>
  );
};

export default AddItemModal;
