import React from 'react';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

const searchPlaceMenuServicesQuery = gql`
  query searchPlaceMenuServicesQuery($input: SearchInput) {
    search_place_menu_services(input: $input) {
      total_pages
      total_size
      search_place_menu_services_list {
        api_key
        auto_cancel {
          is_auto_cancel
          time_in_mins
        }
        auto_confirm
        auto_ready
        cancel_Time
        delivery_channel
        delivery_integration {
          api_key
          menu_id
          name
          order_settings {
            customer_email
            delivery_details
            delivery_fees
            order_items
            order_number
            payment_details
            pickup_details
            total
          }
        }
        menu_name
        name
        payment_method
        preparation_estimate
        schedule_in_advance
        schedule_order_when_closed
        service_type
        status
        zone_fee {
          color_code
          delivery_estimate
          delivery_fee
          id
          name
          delivery_area {
            area_circle {
              location {
                latitude
                longitude
              }
              radius
            }
            area_type
            area_polygon {
              latitude
              longitude
            }
          }
        }
        error {
          code
          description
        }
      }
    }
  }
`;

export const searchPlaceMenuServices = async ({
  adminUserId,
  city = null,
  name = null,
  serviceType = null,
  from,
  size,
}) => {
  const input = {
    user_id: adminUserId,
    size,
    from,
    filter: {
      place_menu_services_filter: {},
    },
  };

  if (city) {
    input.filter.place_menu_services_filter.city = city;
  }

  if (name) {
    input.filter.place_menu_services_filter.name = name;
  }

  if (serviceType && serviceType !== 'All') {
    input.filter.place_menu_services_filter.service_type = serviceType;
  }

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchPlaceMenuServicesQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_place_menu_services &&
      Array.isArray(
        data.data.search_place_menu_services?.search_place_menu_services_list,
      ) &&
      data.data.search_place_menu_services?.search_place_menu_services_list
        .length !== 0
    ) {
      return data.data.search_place_menu_services;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const usePlaceMenuServiceAudit = ({
  adminUserId,
  city = null,
  name = null,
  serviceType = null,
  from = 0,
  size = 50,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [placeMenuServiceAudit, setPlaceMenuServiceAudit] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState();

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      setPlaceMenuServiceAudit([]);
      if (adminUserId) {
        const res = await searchPlaceMenuServices({
          adminUserId,
          city,
          name,
          serviceType,
          from,
          size,
        });

        if (res) {
          setPlaceMenuServiceAudit(res.search_place_menu_services_list);
          setPageCount(res.total_pages);
          setTotalElements(res.total_size);
        } else {
          setPageCount(0);
          setTotalElements();
        }
      } else {
        // pass
      }

      setLoading(false);
    };
    fetchFunc();
  }, [adminUserId, city, name, serviceType, from, size]);

  return [
    loading,
    placeMenuServiceAudit,
    pageCount,
    totalElements,
    async ({ _adminUserId, _from, _size = 50 }) => {
      if (_adminUserId) {
        const res = await searchPlaceMenuServices({
          adminUserId: _adminUserId,
          from: _from,
          size: _size,
        });
        return res;
      }
      return null;
    },
  ];
};

export const downloadCSVFile = (csvData, fileName) => {
  const CSVFile = new Blob([csvData], { type: 'text/csv' });
  const temp_link = document.createElement('a');
  temp_link.download = fileName;
  const url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;
  temp_link.style.display = 'none';
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
};

export default {};
