import React, { useState, useCallback, useEffect } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import { Tab } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull, omit } from 'lodash';
import * as yup from 'yup';
import PlacePageHeader from '../../../components/PlacePageHeader';
import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import Details from './Details';
import ModifiersLinkItems from './ModifiersLinkItems';
import { parseInputModifier, getMenuItems } from './helpers';

import OtherSettings from './OtherSettings';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const [isActiveTab, setIsActiveTab] = useState('details');
  const {
    values,
    loading,
    dirty,
    setFieldValue,
    handleSubmit,
    saveAndAddMore = false,
    setSaveAndAddMore = () => {},
    isAdd = false,
  } = props;

  const fetchData = useCallback(async () => {
    const list = await getMenuItems({
      modifier_group_id: [values.modifier_group_id],
    });
    setFieldValue('linkedMenuItem', list);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <form
        onSubmit={event => {
          if (event.keyCode !== 13) {
            event.preventDefault();
            handleSubmit();
          }
        }}
      >
        <Header
          values={values}
          loading={loading}
          dirty={dirty}
          groupName={values.name}
          isAdd={isAdd}
          saveAndAddMore={saveAndAddMore}
          setSaveAndAddMore={setSaveAndAddMore}
          handleSubmit={handleSubmit}
        >
          <PlacePageHeader
            platform={values?.platform}
            place={props.place}
            placeId={values.place_id}
          />
        </Header>
        <Box>
          <Tabs className="tabs is-medium">
            <Tab
              active={isActiveTab === 'details'}
              onClick={() => setIsActiveTab('details')}
            >
              Modifier Details
            </Tab>
            <Tab
              active={isActiveTab === 'link-item'}
              onClick={() => setIsActiveTab('link-item')}
            >
              Items with this modifiers
            </Tab>
            {!isAdd && (
              <Tab
                active={isActiveTab === 'other-settings'}
                onClick={() => setIsActiveTab('other-settings')}
              >
                Other Settings
              </Tab>
            )}
          </Tabs>
          <Container>
            {isActiveTab === 'details' && <Details {...props} />}
            {isActiveTab === 'link-item' && <ModifiersLinkItems {...props} />}
            {isActiveTab === 'other-settings' && <OtherSettings {...props} />}
          </Container>
        </Box>
        <hr />
        <Footer
          values={values}
          loading={loading}
          dirty={dirty}
          isAdd={isAdd}
          saveAndAddMore={saveAndAddMore}
          setSaveAndAddMore={setSaveAndAddMore}
          handleSubmit={handleSubmit}
        />
      </form>
    </>
  );
};

const MenuItemForm = withFormik({
  mapPropsToValues: ({ place, userId, group }) => ({
    platform: group && !isNull(group.platform) ? group.platform : '',
    user_id: userId,
    channel_link_id:
      group && !isNull(group.channel_link_id) ? group.channel_link_id : '',
    deliverect_modifier_group_id:
      group && !isNull(group.deliverect_modifier_group_id)
        ? group.deliverect_modifier_group_id
        : '',
    deliverect_modifier_group_plu_id:
      group && !isNull(group.deliverect_modifier_group_plu_id)
        ? group.deliverect_modifier_group_plu_id
        : '',
    modifier_group_id:
      group && !isNull(group.modifier_group_id)
        ? group.modifier_group_id
        : uuidv4(),
    place_id:
      group && !isNull(group.place_id) ? group.place_id : place.place_id,

    name: group && !isNull(group.name) ? group.name : '',
    internal_name:
      group && !isNull(group.internal_name) ? group.internal_name : '',

    description: group && !isNull(group.description) ? group.description : '',
    status: group && !isNull(group.status) ? group.status : 'ACTIVE',
    modifier: [],
    modifierHelpers: group && !isNull(group.modifier) ? group.modifier : [],
    is_required:
      group && !isNull(group.is_required) ? group.is_required : false,
    selection_type:
      group && !isNull(group.selection_type) ? group.selection_type : 'CUSTOM',
    selection_quantity:
      group && !isNull(group.selection_quantity)
        ? group.selection_quantity
        : null,
    selection_quantity_from:
      group && !isNull(group.selection_quantity_from)
        ? group.selection_quantity_from
        : null,
    selection_quantity_to:
      group && !isNull(group.selection_quantity_to)
        ? group.selection_quantity_to
        : null,
    linkedMenuItem: [],
    meal_deal: group && !isNull(group.meal_deal) ? group.meal_deal : false,
    multiply_selection_quantity:
      group && !isNull(group.multiply_selection_quantity)
        ? group.multiply_selection_quantity
        : 1,
  }),

  // enableReinitialize: true,

  validate: values => {
    const errors = {};

    if (values.selection_type === 'CUSTOM') {
      if (!values.selection_quantity) {
        errors.selection_quantity = 'Value is required!';
      }
      if (
        values.selection_quantity &&
        values.selection_quantity < values.multiply_selection_quantity
      ) {
        errors.multiply_selection_quantity =
          'Value cannot be greater than custom selection';
      } else if (values.multiply_selection_quantity === '') {
        errors.multiply_selection_quantity = 'Value is required';
      } else if (values.multiply_selection_quantity < 1) {
        errors.multiply_selection_quantity = 'Value cannot be less than 1';
      }
      // if (values.selection_quantity > values.modifier.length) {
      //   errors.selection_quantity =
      //     'Maximum permitted per item should not be greater than maximum number of modifiers permitted total';
      // }
    } else if (values.selection_type === 'RANGE') {
      if (
        values.selection_quantity_to &&
        values.multiply_selection_quantity > values.selection_quantity_to
      ) {
        errors.multiply_selection_quantity =
          'Value cannot be greater than the maximum value of the range';
      }
      if (values.multiply_selection_quantity < 1) {
        errors.multiply_selection_quantity = 'Value cannot be less than 1';
      }
      if (values.multiply_selection_quantity === '') {
        errors.multiply_selection_quantity = 'Value is required!';
      }
      if (!values.selection_quantity_from) {
        errors.selection_quantity_from = 'Value is required!';
      }
      if (!values.selection_quantity_to) {
        errors.selection_quantity_to = 'Value is required!';
      }
      if (
        values.selection_quantity_from &&
        values.selection_quantity_from < 0
      ) {
        errors.selection_quantity_from = 'Value must me positive integer';
      }
      if (values.selection_quantity_to && values.selection_quantity_to < 0) {
        errors.selection_quantity_to = 'Value must me positive integer';
      }
      if (values.selection_quantity_from > values.selection_quantity_to) {
        errors.selection_quantity_to =
          'Maximum value should be more than the minimum value';
      }
      // if (values.selection_quantity_to > values.modifier.length) {
      //   errors.selection_quantity_to =
      //     'Maximum Value should be less than or equal to the total number of items selected';
      // }
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Modifier group name is required!'),
    internal_name: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Modifier group name is required!'),
  }),

  handleSubmit: (values, { props, resetForm, setFieldValue }) => {
    const input = omit(values, ['linkedMenuItem', 'modifierHelpers']);
    const inputs = {
      ...input,
    };

    if (props.group) {
      if (values.name === props.group.name) {
        delete inputs.name;
      } else {
        inputs.name = capitalizeFirstLetter(inputs.name);
      }
      if (values.internal_name === props.group.internal_name) {
        delete inputs.internal_name;
      }
    } else {
      inputs.name = capitalizeFirstLetter(inputs.name);
    }

    props.onSubmit(
      {
        ...inputs,
        modifier: parseInputModifier(values.modifier),
      },
      () => {
        resetForm();
        setFieldValue('modifier_group_id', uuidv4());
      },
    );
  },

  displayName: 'MenuItemForm',
})(Form);

export default MenuItemForm;
