import React from 'react';
import { Level } from 'rbx';
import styled from 'styled-components';

import { Heading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Manage Rewards</Heading>
      </Level.Item>
      <Level.Item align="right">
        {/* <Link to="/add-holiday-hours" className="button is-primary">
          <span className="has-text-weight-semibold">Add Manage Rewards</span>
        </Link> */}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Header;
