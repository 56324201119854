import React from 'react';
import { withFormik } from 'formik';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import { CheckBox } from '../../../components/elements';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import {
  fetchPlaceData,
  createGoogleOrderFeed,
  updateGoogleOrderFeed,
  googleOrderFeed,
} from './helpers';

const Container = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;

const Container2 = styled.div`
  padding: 10px;
  display: flex;
`;

const LeftSpan = styled.span``;

const RightSpan = styled.span`
  padding-left: 10px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  display: inline-block;
  position: relative;
  top: 5px;
`;

const Form = ({ history, values, setFieldValue, ...props }) => (
  <Layout>
    <Header
      history={history}
      loading={props.isSubmitting}
      handleSubmit={props.handleSubmit}
    />
    <Container>
      <div>
        <LeftSpan>Place Name</LeftSpan>
        <RightSpan>{values.place_name}</RightSpan>
      </div>

      <div>
        <LeftSpan>Latitude</LeftSpan>
        <RightSpan>{values.latitude}</RightSpan>
      </div>

      <div>
        <LeftSpan>Longitude</LeftSpan>
        <RightSpan>{values.longitude}</RightSpan>
      </div>

      <div>
        <LeftSpan>Street Address</LeftSpan>
        <RightSpan>{values.address}</RightSpan>
      </div>

      <div>
        <LeftSpan>Suburb/City</LeftSpan>
        <RightSpan>{values.city}</RightSpan>
      </div>

      <div>
        <LeftSpan>Pin/Post Code</LeftSpan>
        <RightSpan>{values.post_code}</RightSpan>
      </div>

      <div>
        <LeftSpan>Category</LeftSpan>
        <RightSpan>{values.category}</RightSpan>
      </div>

      <div>
        <LeftSpan>State</LeftSpan>
        <RightSpan>{values.state}</RightSpan>
      </div>
      <div>
        <LeftSpan>Country</LeftSpan>
        <RightSpan>{values.country}</RightSpan>
      </div>
      <div>
        <LeftSpan>Telephone</LeftSpan>
        <RightSpan>{values.telephone}</RightSpan>
      </div>
      <div>
        <LeftSpan>Website</LeftSpan>
        <RightSpan>{values.website}</RightSpan>
      </div>
    </Container>
    <br />
    <RightSpan>
      Select Applicable Service Types <span style={{ color: 'red' }}>*</span>
    </RightSpan>
    <Container2>
      {(props?.place?.service_type ?? [])
        ?.filter(ele => ele.name === 'Pickup' || ele.name === 'Delivery')
        .map(ele => (
          <div style={{ paddingLeft: '10px', display: 'inline' }}>
            <CheckBox
              key={ele.name}
              label={ele.name}
              name={ele.name}
              value={!!values.service_types.find(ele2 => ele2 === ele.name)}
              onChange={() => {
                if (values.service_types.find(ele2 => ele2 === ele.name)) {
                  setFieldValue(
                    'service_types',
                    values.service_types.filter(ele2 => ele2 !== ele.name),
                  );
                } else {
                  setFieldValue('service_types', [
                    ...values.service_types,
                    ele.name,
                  ]);
                }
              }}
            />
          </div>
        ))}
    </Container2>
  </Layout>
);

const GoogleFeedForm = withFormik({
  mapPropsToValues: ({ userId, place, googleFeed }) => ({
    user_id: userId,
    place_id: place.place_id,
    place_name: place.name,
    latitude: place.latitude,
    longitude: place.longitude,
    address: place.address_line_1,
    city: place.city,
    post_code: place.post_code,
    category: 'restaurant',
    state: place.state,
    country: place.country,
    telephone: (place.contact ?? []).find(ele => ele.type === 'phone_primary')
      ?.value,
    website: (place.contact ?? []).find(ele => ele.type === 'website')?.value,
    service_types: googleFeed
      ? googleFeed.service_types.filter(
          ele => ele === 'Pickup' || ele === 'Delivery',
        )
      : [],
    google_order_feed_id: googleFeed ? googleFeed.google_order_feed_id : null,
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { props }) => {
    if (values.service_types.length === 0) {
      toast.error('Atleast one service type must be selected!');
      return;
    }
    if (!values.website) {
      swal('Website is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.telephone) {
      swal('Telephone is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.place_name) {
      swal('Place name is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.latitude) {
      swal('Latitude is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.longitude) {
      swal('Longitude is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.address) {
      swal('address is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.city) {
      swal('City is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.post_code) {
      swal('Post Code is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.state) {
      swal('state is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (!values.country) {
      swal('country is missing please update the place').then(() => {
        props.history.push(`/update-place/${values.place_id}`);
      });
      return;
    }
    if (props.googleFeed) {
      const u = await updateGoogleOrderFeed({
        input: {
          user_id: values.user_id,
          place_id: values.place_id,
          service_types: values.service_types,
          google_order_feed_id: values.google_order_feed_id,
        },
      });
      if (u) {
        toast.error(u);
      } else {
        swal('Google Order Feed Updated!').then(() =>
          props.history.push(`/google-feeds`),
        );
      }
    } else {
      const c = await createGoogleOrderFeed({
        input: {
          user_id: values.user_id,
          place_id: values.place_id,
          service_types: values.service_types,
        },
      });
      if (c) {
        toast.error(c);
      } else {
        swal('Google Order Feed Created!').then(() =>
          props.history.push(`/google-feeds`),
        );
      }
    }
  },
})(Form);

const GoogleFeed = ({ history, match }) => {
  const { placeId } = match.params;
  const [place, setPlace] = React.useState();
  const { userId } = useStoreState(state => state.auth);
  const [loadingIntialData, setLoadingIntialData] = React.useState(false);
  const [googleFeed, setGoogleFeed] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoadingIntialData(true);
      const placeData = await fetchPlaceData({ placeId });
      const gf = await googleOrderFeed({ placeId });
      if (placeData) {
        setPlace(placeData);
      }
      if (gf) {
        setGoogleFeed(gf);
      }
      setLoadingIntialData(false);
    };
    fetchFunc();
  }, []);

  if (place) {
    return (
      <GoogleFeedForm
        history={history}
        place={place}
        userId={userId}
        googleFeed={googleFeed}
      />
    );
  }

  return (
    <Layout>
      <Header history={history} loading={false} handleSubmit={() => {}} />
      {loadingIntialData && (
        <span style={{ color: '#00AEEF', padding: '20px' }}>Loading...</span>
      )}
    </Layout>
  );
};

export default GoogleFeed;
