import React from 'react';
import { Column, Generic } from 'rbx';
import { config } from 'aws-sdk';
import Input from '../../../components/elements/Input';
import Select from '../../../components/elements/Select';
import CheckBox from '../../../components/elements/CheckBox';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const PrimaryDetailsForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  <div>
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={3}>
        <CheckBox
          action={['create_place']}
          label="Approved Listing"
          name="listing_status"
          disabled={values.event_business}
          value={values.listing_status === 'APPROVED'}
          onChange={value =>
            setFieldValue(
              'listing_status',
              value === true ? 'APPROVED' : 'UNLISTED',
            )
          }
        />
      </Column>
      <Column size={3}>
        <CheckBox
          action={['create_place']}
          label="Event Organizer only"
          name="event_business"
          value={values.event_business}
          onChange={value => {
            setFieldValue('event_business', value);
            setFieldValue('listing_status', 'APPROVED');
          }}
        />
      </Column>
      <Column size={3}>
        <CheckBox
          action={['create_place']}
          label="User Added Place via Item Review"
          disabled
          name="item_review_place"
          value={values.item_review_place}
          onChange={value => setFieldValue('item_review_place', value)}
        />
      </Column>
      {/* {values.default_image_url !== '' && (
        <Column size={3}>
          <figure className="image is-64x64">
            <img src={values.default_image_url} alt="place_image" />
          </figure>
        </Column>
      )} */}
    </Column.Group>

    <Column.Group vcentered multiline gapSize={8}>
      <Column size={6}>
        <Input
          action={['create_place']}
          label="Place name"
          name="name"
          id="PlaceManagement_AddEditPlace_name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.name && touched.name && 'is-danger'}
          optionalText="(Max 75 Characters)"
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      <Column size={2} textAlign="centered">
        <Generic>
          <CheckBox
            action={['create_place']}
            label="Featured"
            name="featured"
            id="PlaceManagement_AddEditPlace_featured"
            value={values.featured}
            onChange={value => setFieldValue('featured', value)}
            onBlur={handleBlur}
          />
        </Generic>
      </Column>
      <Column size={3}>
        <Select
          action={['create_place']}
          label="Status"
          options={status}
          id="PlaceManagement_AddEditPlace_status"
          value={[{ value: values.status, label: values.status }]}
          onChange={({ value }) => setFieldValue('status', value)}
          errors={errors.status}
          touched={touched.status}
          required
        />
      </Column>
      <Column size={6}>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action="create_place"
              apiActions={apiActions}
              pageActions={pageActions}
              no={() => (
                <div className="field">
                  <label className="label">Slug</label>
                  <div className="control">
                    <div className="field has-addons ">
                      <p className="control">
                        <a className="button is-static">{config.redirectUri}</a>
                      </p>
                      <p className="control" style={{ width: '100%' }}>
                        <input
                          className="input"
                          type="text"
                          name="slug"
                          value={values.slug}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '100%' }}
                          disabled
                        />
                      </p>
                    </div>
                  </div>
                </div>
              )}
              yes={() => (
                <div className="field">
                  <label className="label">Slug</label>
                  <div className="control">
                    <div className="field has-addons ">
                      <p className="control">
                        <a className="button is-static">{config.redirectUri}</a>
                      </p>
                      <p className="control" style={{ width: '100%' }}>
                        <input
                          className="input"
                          type="text"
                          name="slug"
                          value={values.slug}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ width: '100%' }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              )}
            />
          )}
        </ACLSConsumer>
      </Column>
      <Column size={5}>
        <Input label="Company Name " disabled />
      </Column>
    </Column.Group>
  </div>
);

export default PrimaryDetailsForm;
