import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Level, Title } from 'rbx';
import styled from 'styled-components';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  /* border-bottom: 1px solid #f6f6f6; */
`;

const Header = ({ location }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>Add / Edit Events</Title>
      </Level.Item>
      <Level.Item align="right">
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/events'
                  ? 'create_event'
                  : 'create_event'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Link to="/add-event" className="button is-primary">
                  <span className="has-text-weight-semibold">Add an Event</span>
                </Link>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Header);
