import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import swal from 'sweetalert';
import moment from 'moment-timezone';
import { FaAngleLeft } from 'react-icons/fa';
import { filter } from 'lodash';

import {
  scheduleCompletionTime,
  totalTimeDueActiveCard3,
  totalTimeDelay2,
} from './timeHelpers';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: center;
      background: white;
      border-top: 0px;
      .button {
        font-weight: 600;
      }
    }
  }
`;

const DetailsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    font-weight: 600;
    width: 30%;
  }
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: space-between;
`;

const SpecialSpacing = styled.div`
  display: flex;
  justify-content: space-between;
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const scheduleCompletionTimeText = (estimates, receivedAt) => {
  const delayEstimates = filter(
    estimates,
    edge => edge.type === 'delayed_estimate',
  );
  const time = scheduleCompletionTime(estimates, receivedAt);
  if (delayEstimates.length !== 0 && delayEstimates[0].value_in_mins > 0) {
    return moment(time).format('LT');
  }
  return null;
};

const scheduleTimeText = estimated_completion_time =>
  moment(estimated_completion_time).format('llll');

const Form = props => {
  const {
    order,
    isActive,
    onClose,
    handleSubmit,
    resetForm,
    values,
    setFieldValue,
  } = props;

  const [dueTime, setDueTime] = useState('');

  const schText = scheduleCompletionTimeText(
    order?.estimates,
    order?.received?.created_at,
  );

  useEffect(() => {
    const due = totalTimeDueActiveCard3(
      order?.estimates,
      order?.received?.created_at,
    );
    const delay = totalTimeDelay2(
      order?.estimates,
      order?.received?.created_at,
    );
    if (due) {
      setDueTime(due);
    } else if (delay) {
      setDueTime(delay);
    }
  }, [values.delay, order]);

  return (
    <>
      <Container
        className={`modal ${isActive && 'is-active'}`}
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <FaAngleLeft
              size={30}
              type="button"
              onClick={() => {
                onClose();

                resetForm();
              }}
            />
            <p className="modal-card-title" style={{ paddingLeft: '35%' }}>
              Delay Order
            </p>
          </header>

          <section className="modal-card-body">
            <DetailsFormWrapper>
              <p className="is-size-4" style={{ paddingTop: '10px' }}>
                If you want to delay your order due to some unavoidable
                circumstances , you can delay your order by adding additional
                time. We will notify the customer about the delay.
              </p>
              <br />
              <Flex>
                <p className="is-size-3 has-text-weight-semibold">
                  Scheduled time:
                </p>
                &nbsp;&nbsp;
                <p className="is-size-3">
                  {scheduleTimeText(order.estimated_completion_time)}
                  {schText && <>&nbsp;&nbsp;({schText})</>}
                </p>
              </Flex>
              <Flex>
                <p className="is-size-3 has-text-weight-semibold">Due time:</p>
                &nbsp;&nbsp;
                <p
                  className="is-size-3 has-text-weight-semibold"
                  style={{ color: '#ee2a7b' }}
                >
                  {dueTime}
                </p>
              </Flex>
              <Flex>
                <p className="is-size-3 has-text-weight-semibold">
                  Expected time:
                </p>
                &nbsp;&nbsp;
                <p className="is-size-3">
                  {schText ??
                    moment(order.estimated_completion_time).format('LT')}
                </p>
              </Flex>
              <br />
              <SpecialSpacing>
                <DetailsButton
                  type="button"
                  className={`button control ${values.delay === 10 &&
                    'is-primary'}`}
                  onClick={() => setFieldValue('delay', 10)}
                >
                  + 10 min
                </DetailsButton>

                <DetailsButton
                  type="button"
                  className={`button control ${values.delay === 20 &&
                    'is-primary'}`}
                  onClick={() => setFieldValue('delay', 20)}
                >
                  + 20 min
                </DetailsButton>

                <DetailsButton
                  type="button"
                  className={`button control ${values.delay === 30 &&
                    'is-primary'}`}
                  onClick={() => setFieldValue('delay', 30)}
                >
                  + 30 min
                </DetailsButton>
              </SpecialSpacing>
              <br />
              <SpecialSpacing>
                <DetailsButton
                  type="button"
                  className={`button control ${values.delay === 40 &&
                    'is-primary'}`}
                  onClick={() => setFieldValue('delay', 40)}
                >
                  + 40 min
                </DetailsButton>

                <DetailsButton
                  type="button"
                  className={`button control ${values.delay === 50 &&
                    'is-primary'}`}
                  onClick={() => setFieldValue('delay', 50)}
                >
                  + 50 min
                </DetailsButton>

                <DetailsButton
                  type="button"
                  className={`button control ${values.delay === 60 &&
                    'is-primary'}`}
                  onClick={() => setFieldValue('delay', 60)}
                >
                  + 60 min
                </DetailsButton>
              </SpecialSpacing>
              <br />
              <br />
              {/* <div>
                <TextArea
                  placeholder="note and mention anything specific such as no nuts or cross contamination , less spicy"
                  name="value"
                  label="Note to the customer"
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  errors={errors.value}
                  touched={touched.value}
                  disableToastMessage
                />
                Characters left ({values.value.length} / 144)
              </div> */}
            </DetailsFormWrapper>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-large is-fullwidth is-primary"
              onClick={handleSubmit}
            >
              CONFIRM DELAY
            </button>
          </footer>
        </div>
      </Container>
    </>
  );
};

const AdjustPriceForm = withFormik({
  mapPropsToValues: () => ({
    delay: 0,
    value: 'Your order has been delayed due to unavoidable circumstances',
  }),

  // validationSchema: yup.object().shape({
  //   value: yup
  //     .string()
  //     .required('Description is required!')
  //     .min(5, 'Please enter at least 5 characters')
  //     .max(144, 'Description cannot be greater than 144 characters'),
  // }),

  handleSubmit: (values, { props }) => {
    swal({
      title: 'Are you sure you want to delay the order?',
      // eslint-disable-next-line max-len
      text: `Please delay your order due to unavoidable circumstances only. Click 'cancel' to go back or click 'confirm' to delay this order. A notification will been sent to the customer informing them of the delay.`,
      buttons: ['Cancel', 'Confirm'],
    }).then(isConfirm => {
      if (isConfirm) {
        const note = {
          type: 'delay order',
          value: values.value,
          date: moment().toISOString(),
        };
        props.handleDelayOrder(
          props.order.menu_order_id,
          'delay',
          note,
          values.delay,
        );
        props.onClose();
      }
    });
  },

  displayName: 'AdjustPriceForm',
})(Form);

export default AdjustPriceForm;
