import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Column } from 'rbx';
import { getPlaces } from '../../AdminUser/helpers';
import { Input, AsyncTagInputs } from '../../../../components/elements';

const AssignPlaces = ({ placeId, setFieldValue, values = {} }) => {
  const [options, setOptions] = React.useState([]);
  const [places, setPlaces] = React.useState([]);
  const [value, setValue] = React.useState(null);

  const { userId } = useStoreState(state => state.auth);

  const fetchPlaces = async (inputValue, resolve) => {
    const place_filter = {
      listing_status: 'APPROVED',
      event_business: false,
      item_review_place: false,
      has_service_type_setting: true,
    };

    Object.assign(place_filter, inputValue && { place_name: inputValue });

    const allPlaces = await getPlaces({
      sort: 'PLACE_NAME_ASC',
      user_id: userId,
      filter: {
        place_filter: placeId ? { place_id: placeId } : place_filter,
      },
    });

    if (placeId) {
      setValue(
        `${allPlaces[0].name}, ${allPlaces[0].city}, ${allPlaces[0].state}`,
      );
    }

    if (allPlaces.length !== 0) {
      const filteredPlaces = allPlaces.map(item => ({
        value: item,
        label: `${item.name}, ${item.city}, ${item.state}`,
      }));

      setPlaces(filteredPlaces);
      if (resolve) {
        resolve(filteredPlaces);
      }
    }
  };

  React.useEffect(() => {
    fetchPlaces();
  }, []);

  React.useEffect(() => {
    if (places && places.length !== 0) {
      setOptions([...places]);
    }
  }, [places]);

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      if (!inputValue) {
        fetchPlaces(null, resolve);
      } else {
        fetchPlaces(inputValue, resolve);
      }
    });

  return (
    <>
      {placeId ? (
        <Column size={4}>
          <Input
            label={values.enableMultiPlaceSelection ? 'Places' : 'Place'}
            value={value}
            disabled
          />
        </Column>
      ) : (
        <AsyncTagInputs
          label="Places"
          required
          isMulti={false}
          suggestions={options}
          promiseFunction={promiseOptions}
          defaultOptions={options}
          onChange={e => {
            if (e) {
              setFieldValue('place_id', e.value.place_id);
            }
          }}
        />
      )}
    </>
  );
};

export default AssignPlaces;
