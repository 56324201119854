import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { first } from 'lodash';
import { useStoreState } from 'easy-peasy';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

import { Loading, Select } from '../../../../components/elements';
import { handleOrderStartStopAction } from '../../Order/helpers';
import WeeklyHours from './WeeklyHours';
import OrderStatus from './OrderStatus';
// import { getCustomHours2 } from '../../Order/StartStopModal/helpers';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const FlexItem1 = styled.div`
  width: 20%;
`;

const FlexItem2 = styled.div`
  width: 80%;
`;

const OnButton = styled.button`
  &&& {
    background-color: #65b44b;
    font-weight: 600;
    border-top-left-radius: 290486px;
    border-top-right-radius: 290486px;
    border-bottom-right-radius: 290486px;
    border-bottom-left-radius: 290486px;
    border: none;
  }
`;

const OffButton = styled.button`
  &&& {
    background-color: red;
    font-weight: 600;
    border-top-left-radius: 290486px;
    border-top-right-radius: 290486px;
    border-bottom-right-radius: 290486px;
    border-bottom-left-radius: 290486px;
    border: none;
  }
`;

const OrderButton = styled.button`
  &&& {
    background-color: ${({ theme, takeOrder }) =>
      takeOrder ? '#65b44b' : theme.danger};
    font-weight: 600;
    width: 100%;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const compare = (a, b) => {
  if (a?.service_type && b?.service_type && a?.name && b?.name) {
    let bool = 0;
    if (a.service_type < b.service_type) {
      bool = -1;
    } else if (a.service_type > b.service_type) {
      bool = 1;
    } else if (a.name < b.name) {
      bool = -1;
    } else if (a.name > b.name) {
      bool = 1;
    }
    return bool;
  }
  return 0;
};

const Menu = ({ menus, fetchMenus, place }) => {
  const [selectedMenu, setSelectedMenu] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useStoreState(state => state.auth);
  const [customHours, setCustomHours] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getCustomHour = useCallback(async () => {
    setLoading(true);

    // await getCustomHours2({
    //   userId,
    //   menuIds: menus.map(item => item.menu_id),
    // });

    const timezone = tzLookup(place.latitude, place.longitude);

    const currentDateInTimezone = moment()
      .tz(timezone)
      .format('YYYY-MM-DD');

    const ch = [];

    menus.forEach(ele => {
      if (ele.custom_hour) {
        ele.custom_hour.forEach(ele2 => {
          const d1 = new Date(currentDateInTimezone);
          const d2 = new Date(ele2.date);
          d1.setHours(0, 0, 0, 0);
          d2.setHours(0, 0, 0, 0);
          if (d2 >= d1) {
            ch.push({ ...ele2, menu_id: ele.menu_id });
          } else {
            // pass
          }
        });
      }
    });

    setCustomHours(ch);

    setLoading(false);
  }, []);

  const orderAction = async action => {
    setIsLoading(true);
    try {
      await handleOrderStartStopAction({
        userId,
        action,
        menuId: selectedMenu.menu_id,
      });
      fetchMenus();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (menus.length !== 0) {
      getCustomHour();
      const filterMenu = selectedMenu
        ? menus.filter(item => item.menu_id === selectedMenu.menu_id)
        : [];
      if (filterMenu.length !== 0) {
        setSelectedMenu(first(filterMenu));
      } else {
        setSelectedMenu(first(menus));
      }
    }
  }, [menus]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="columns">
      <div className="column is-6">
        <OrderStatus place={place} menus={menus} customHours={customHours} />
        <br />
        <Flex>
          <FlexItem1>
            <h3 className="has-text-weight-semibold is-size-1">Menu</h3>
          </FlexItem1>
          <FlexItem2>
            <Select
              placeholder="Select Menu"
              value={selectedMenu ? { ...selectedMenu } : null}
              options={menus.sort(compare)}
              onChange={value => {
                setSelectedMenu(value);
              }}
              isLoading={false}
            />
          </FlexItem2>
        </Flex>
        <br />
        <Flex>
          <FlexItem1 style={{ width: '30%' }}>
            <h3 className="has-text-weight-semibold is-size-4">
              Order Online Status
            </h3>
          </FlexItem1>
          <FlexItem2 style={{ width: '20%' }}>
            {selectedMenu && selectedMenu.hours === 'STOPPED' && (
              <OffButton type="button" className="button control">
                <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                  OFF
                </p>
              </OffButton>
            )}
            {selectedMenu && selectedMenu.hours === 'CLOSED' && (
              <OffButton type="button" className="button control">
                <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                  Closed
                </p>
              </OffButton>
            )}
            {selectedMenu && selectedMenu.hours === 'OPENED' && (
              <OnButton type="button" className="button control">
                <p className="has-text-semibold" style={{ color: '#ffffff' }}>
                  Open
                </p>
              </OnButton>
            )}
          </FlexItem2>
          <FlexItem2 style={{ width: '50%' }}>
            {selectedMenu && (
              <OrderButton
                type="button"
                className={`button is-primary control ${isLoading &&
                  'is-loading'}`}
                takeOrder={selectedMenu && selectedMenu.hours === 'STOPPED'}
                onClick={() => {
                  if (selectedMenu) {
                    orderAction(
                      selectedMenu.hours === 'STOPPED' ? 'RESUME' : 'PAUSE',
                    );
                  }
                }}
                disabled={selectedMenu && selectedMenu.hours === 'CLOSED'}
              >
                {selectedMenu && selectedMenu.hours === 'STOPPED'
                  ? 'Resume new orders'
                  : 'Stop taking orders'}
              </OrderButton>
            )}
          </FlexItem2>
        </Flex>
        {selectedMenu && selectedMenu.hours === 'STOPPED' && (
          <p>
            Your orders are stopped temporarily until the next scheduled open
            hours for this menu. You can take your place back online anytime, by
            choosing 'Resume new orders'. To stop taking orders for the whole
            day, go to 'Holiday or Custom Hours' in the next tab and update the
            holiday / custom hours for any specific day. You can make changes to
            your regular menu hours below
          </p>
        )}
        {selectedMenu && selectedMenu.hours === 'OPENED' && (
          <p>
            If you choose &apos;Stop taking orders&apos;, this will pause orders
            until the next scheduled opening hour for this menu only. You can
            also stop taking orders temporarily for all the menus in your place
            by going to &apos;Stop/Start orders&apos; in Orders. To stop taking
            orders for the whole day, go to &apos;Holiday or Custom Hours&apos;
            in the next tab and update the holiday / custom hours for any
            specific day. You can make changes to your regular menu hours below
          </p>
        )}
      </div>

      <div className="column">
        {selectedMenu && (
          <WeeklyHours
            place={place}
            userId={userId}
            fetchMenus={fetchMenus}
            menu={selectedMenu}
            customHours={customHours}
          />
        )}
      </div>
    </div>
  );
};

export default Menu;
