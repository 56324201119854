import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { Loading } from '../../../../components/elements';
import { getModifiers } from '../helpers';
import ModifierGroup from './ModifierGroup';

const ItemContainer = styled.div``;

const Modifiers = ({
  placeId,
  link,
  linkRootAVDate,
  onHandleLinkModifierSubmission,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [modifierGroups, setModifierGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const list = await getModifiers({
        userId,
        placeId,
        ids: link.modifier_groups_id,
      });

      setModifierGroups(list);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ItemContainer>
      {modifierGroups.map(group => (
        <ModifierGroup
          key={group.modifier_groups_id}
          group={group}
          originalModifier={link.originalModifier}
          onHandleLinkModifierSubmission={onHandleLinkModifierSubmission}
          linkRootAVDate={linkRootAVDate}
        />
      ))}
    </ItemContainer>
  );
};

export default Modifiers;
