/* eslint-disable array-callback-return */
import React from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';

const AddGooglePlaceFeed = ({ history }) => (
  <Layout>
    <PlaceSelection
      claimStatus="NONE"
      hasServiceSetting
      heading="Select Place to add Google Feed"
      handleSelect={value => {
        history.push(`/google-feed/${value.place_id}`);
      }}
    />
  </Layout>
);

export default withRouter(AddGooglePlaceFeed);
