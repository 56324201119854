import React from 'react';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { Mutation } from 'react-apollo';

import Form from './Form';
import client from '../../../../utils/apolloClient';

const createDealMutation = gql`
  mutation createDeal($input: PlaceMenuDealInput) {
    create_place_menu_deal(input: $input) {
      deal_id
      user_id
      error {
        description
      }
    }
  }
`;

const DealForm = ({ user, history }) => {
  console.log(user);
  return (
    <Mutation
      client={client.clientPrivate}
      mutation={createDealMutation}
      onCompleted={({ create_place_menu_deal }) => {
        if (create_place_menu_deal.error) {
          create_place_menu_deal.error.map(item =>
            toast.error(item.description),
          );
        } else {
          swal(
            'Great!',
            'User Menu Deal has been created successfully!',
            'success',
          ).then(() => {
            history.push('/user-promos');
          });
        }
      }}
    >
      {(
        create_place_deal,
        { loading: createDealLoading, error: createDealError },
      ) => (
        <Form
          loading={createDealLoading}
          error={createDealError}
          user={user}
          deal={null}
          currentTimeZone={null}
          onSubmit={input => {
            create_place_deal({ variables: { input } });
          }}
        />
      )}
    </Mutation>
  );
};

export default DealForm;
