import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 1rem;
`;

const Head = styled.div`
  padding: 0.1rem 0rem;
`;

const Content = styled.div`
  padding: 0.05rem 0rem;
`;

const Options = ({ order }) => (
  <Container>
    <Head>
      <p className="is-size-4">
        {order.service_type === 'Pickup' && 'Pickup Options'}
        {order.service_type === 'Delivery' && 'Delivery Address'}
        {order.service_type === 'Dinein' && 'Dinein Details'}
      </p>
    </Head>
    <Content>
      {order.service_type === 'Pickup' &&
      (order.pickup_method || order.vehicle_information) ? (
        <p className="is-size-3">
          {order.pickup_method}
          {order.vehicle_information && order.vehicle_information.car_detail
            ? `, ${order.vehicle_information.car_detail}`
            : ''}
          {order.vehicle_information && order.vehicle_information.license_plate
            ? `,${order.vehicle_information.license_plate}`
            : ''}
        </p>
      ) : (
        order.service_type === 'Pickup' && (
          <p className="is-size-3">No {order.service_type} Data</p>
        )
      )}
      {order.service_type === 'Dinein' &&
      (order.no_of_attendees || order.table_number) ? (
        <p className="is-size-3">
          {order.no_of_attendees &&
            `${order.no_of_attendees} ${
              order.no_of_attendees > 1 ? 'Attendees, ' : 'Attendee, '
            }`}
          {order.table_number &&
            `${order.table_name || 'Table'} ${order.table_number}`}
        </p>
      ) : (
        order.service_type === 'Dinein' && (
          <p className="is-size-3">No {order.service_type} Data</p>
        )
      )}
      {order.service_type === 'Delivery' && (
        <>
          {order.address && (
            <p className="is-size-3">
              {(() => {
                const {
                  address_line_1,
                  city,
                  state,
                  post_code,
                } = order.address;
                return [address_line_1, city, state, post_code]
                  .filter(ele => !!ele)
                  .join(', ')
                  .trimEnd(', ');
              })()}
            </p>
          )}
          {order.address.address_line_2 && (
            <p className="is-size-3">{`${order.address.address_line_2}`}</p>
          )}
          {order.address && order.address.delivery_option && (
            <p className="is-size-3">
              {order.address.delivery_option.type === 'DELIVERY_TO_DOOR'
                ? 'Deliver to door'
                : 'Pickup Outside'}
              {order.address.delivery_option.caption &&
                order.address.delivery_option.caption.length !== 0 &&
                ' | '}
              {order.address.delivery_option.caption &&
                order.address.delivery_option.caption.map((value, index) => {
                  let text = '';
                  if (value === 'please_do_not_ring_the_bell') {
                    text = 'Please do not ring the bell';
                  } else if (value === 'call_upon_reaching_my_location') {
                    text = 'Call upon reaching my location';
                  }
                  return `${text} ${
                    index + 1 === order.address.delivery_option.caption.length
                      ? ''
                      : '|'
                  } `;
                })}
            </p>
          )}
        </>
      )}
    </Content>
  </Container>
);

export default Options;
