import React from 'react';

import {
  Input,
  CreatableSelectInputs,
  TagInputs,
  TextArea,
  Select,
} from '../../../components/elements';

const CompanyDetails = () => <div>Company Details</div>;

export default CompanyDetails;
