/* eslint-disable max-len */
import React from 'react';

const OverrideIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 9C3.5 4.03 7.53 0 12.5 0C17.47 0 21.5 4.03 21.5 9C21.5 13.97 17.47 18 12.5 18C10.44 18 8.54 17.3 7.02 16.14L8.44 14.7C9.59 15.51 10.99 16 12.5 16C16.37 16 19.5 12.87 19.5 9C19.5 5.13 16.37 2 12.5 2C8.63 2 5.5 5.13 5.5 9H8.5L4.5 13L0.5 9H3.5ZM12.5 7C13.6 7 14.5 7.9 14.5 9C14.5 10.1 13.6 11 12.5 11C11.4 11 10.5 10.1 10.5 9C10.5 7.9 11.4 7 12.5 7Z"
      fill="#737373"
    />
  </svg>
);

export default OverrideIcon;
