import React from 'react';
import styled from 'styled-components';
import { Tab, Column } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import uuidv4 from 'uuid/v4';
import { isNull } from 'lodash';
import moment from 'moment';
import MenuSuggestionInput from './MenuSuggestionInput';
import { Box, Tabs, Radio, Input, Select } from '../../../components/elements';
import OrderBasedRewards from './OrderBasedRewards';
import SpendBasedReward from './SpendBasedReward';
import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1.5rem;
`;

const RadioWrapper = styled.div`
  padding: 0rem 0.5rem;
`;

const Text = styled.div`
  font-size: 0.8rem;
  color: #737373;
`;

const Form = props => {
  const { loading, dirty, values, handleSubmit, setFieldValue } = props;

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <Header loading={loading} dirty={dirty} />
      <Box>
        <Tabs className="tabs is-medium">
          <Tab active onClick={() => {}}>
            Rewards
          </Tab>
        </Tabs>
        <Container>
          <Column.Group multiline gapSize={8}>
            <Column size={8}>
              <div className="field">
                <div className="label">Reward Type</div>
                <div className="control">
                  <div className="is-flex">
                    <RadioWrapper>
                      <Radio
                        label="Item based Rewards"
                        value={values.reward_type === 'Item based rewards'}
                        onChange={value => {
                          if (value) {
                            setFieldValue('reward_type', 'Item based rewards');
                          }
                        }}
                      />
                    </RadioWrapper>
                    <RadioWrapper>
                      <Radio
                        label="Order based Rewards"
                        value={values.reward_type === 'Order based Rewards'}
                        onChange={value => {
                          if (value) {
                            setFieldValue('reward_type', 'Order based Rewards');
                          }
                        }}
                      />
                    </RadioWrapper>
                    <RadioWrapper>
                      <Radio
                        label="Spend based Rewards"
                        value={values.reward_type === 'Spend based Rewards'}
                        onChange={value => {
                          if (value) {
                            setFieldValue('reward_type', 'Spend based Rewards');
                          }
                        }}
                      />
                    </RadioWrapper>
                  </div>
                </div>
              </div>
            </Column>
          </Column.Group>
          {values.reward_type === 'Item based rewards' && (
            <React.Fragment>
              <Column.Group>
                <Column size={7}>
                  <MenuSuggestionInput label="Applicable Menus" />
                </Column>
              </Column.Group>
              <React.Fragment>
                <div className="label">Reward Conditions</div>
                <Text>
                  Customers earn a free item after a set amount of eligible item
                  purchases{' '}
                </Text>
              </React.Fragment>
              <Column.Group>
                <Column size={8}>
                  <div className="is-flex">
                    <div className="label" style={{ paddingTop: '1.2rem' }}>
                      Number of orders to get reward
                    </div>

                    <Column size={2}>
                      <Input type="number" />
                    </Column>
                  </div>
                </Column>
              </Column.Group>
              <Text>
                Choose from 3, 5 or 10 item purchases to received a reward
              </Text>
              <Column.Group>
                <Column size={8}>
                  <div className="is-flex">
                    <div className="label" style={{ paddingTop: '1.2rem' }}>
                      Select eligible Items
                    </div>

                    <Column size={4}>
                      <Select />
                    </Column>
                  </div>
                </Column>
              </Column.Group>
              <Column.Group>
                <Column size={7}>
                  <div className="label" style={{ paddingTop: '1.2rem' }}>
                    Reward details
                  </div>
                  <Text>
                    Once the set amount of eligible item purchases are purchases
                    are reached, select the items that you would like your
                    customers choose as a free item reward at your resturant
                  </Text>
                </Column>
              </Column.Group>
              <Column.Group>
                <Column size={8}>
                  <div className="is-flex">
                    <div className="label" style={{ paddingTop: '1.2rem' }}>
                      Select reward Items
                    </div>
                    <Column size={4}>
                      <Select />
                    </Column>
                  </div>
                </Column>
              </Column.Group>
              <Column.Group>
                <Column size={7}>
                  <div className="label" style={{ paddingTop: '1.2rem' }}>
                    Reward redemption validity
                  </div>
                  <Text>Validity of the reward for the customer to redeem</Text>
                </Column>
              </Column.Group>
              <Column.Group>
                <Column size={8}>
                  <div className="is-flex">
                    <div className="label" style={{ paddingTop: '1.2rem' }}>
                      Max number of days to be redeem the reward
                    </div>

                    <Column size={2}>
                      <Input type="number" />
                    </Column>
                  </div>
                </Column>
              </Column.Group>
              <Text>
                Choose from credit expiry of 30 days, 60 days, 90 days and 120
                days
              </Text>
            </React.Fragment>
          )}
          {values.reward_type === 'Order based Rewards' && (
            <OrderBasedRewards values={values} />
          )}
          {values.reward_type === 'Spend based Rewards' && (
            <SpendBasedReward values={values} />
          )}
        </Container>
      </Box>
      <br />
      <br />
      <Footer loading={loading} dirty={dirty} />
    </form>
  );
};

const HolidayForm = withFormik({
  mapPropsToValues: ({ reward, userId }) => {
    const startDate =
      reward && !isNull(reward.start) ? moment(reward.start) : null;
    const endDate = reward && !isNull(reward.end) ? moment(reward.end) : null;
    return {
      user_id: userId,
      // place_id:
      //   reward && !isNull(reward.place_id) ? reward.place_id : reward.place_id,
      startDate: reward ? moment(startDate).toISOString() : null,
      startTime: reward ? moment(startDate).toISOString() : null,
      endDate: reward ? moment(endDate).toISOString() : null,
      endTime: reward ? moment(endDate).toISOString() : null,
      reward_type: reward ? reward.reward_type : 'Item based rewards',
      reward_id:
        reward && !isNull(reward.reward_id) ? reward.reward_id : uuidv4(),
      name: reward && !isNull(reward.name) ? reward.name : '',
      description:
        reward && !isNull(reward.description) ? reward.description : '',
    };
  },
  validationSchema: yup.object().shape({}),

  handleSubmit: () => {},

  displayName: 'Form',
})(Form);

export default HolidayForm;
