import React from 'react';

import { Table, Button, Icon } from 'rbx';

import styled from 'styled-components';

const Element = styled.div`
  width: 8rem;
`;

const List = ({ fees, onEdit, onDelete }) => (
  <>
    {fees.map(item => (
      <Table.Row>
        <Table.Cell>
          <Element>{item.service_type_name}</Element>
        </Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.display}</Table.Cell>
        <Table.Cell>
          <Element>{item.sale_method}</Element>
        </Table.Cell>
        <Table.Cell>
          <Element>{item.type}</Element>
        </Table.Cell>
        <Table.Cell>
          {item.type !== 'PERCENTAGE' ? `$` : ''}
          {parseFloat(item.value).toFixed(2)}
        </Table.Cell>
        <Table.Cell>
          {item.is_payment_gateway_fee === true ? 'Yes' : 'No'}
        </Table.Cell>
        <Table.Cell>
          {item.is_kravein_charge === true ? 'Yes' : 'No'}
        </Table.Cell>
        <Table.Cell>
          <Element>{item.applies_to}</Element>
        </Table.Cell>
        <Table.Cell>
          <Element>
            <Button.Group hasAddons>
              <Button onClick={() => onEdit(item)}>
                <Icon size="small">
                  <i className="fas fa-edit" />
                </Icon>
              </Button>
              <Button onClick={() => onDelete(item)}>
                <Icon size="small">
                  <i className="fas fa-trash has-text-grey" />
                </Icon>
              </Button>
            </Button.Group>
          </Element>
        </Table.Cell>
      </Table.Row>
    ))}
  </>
);

export default List;
