import React from 'react';
import styled from 'styled-components';
import Loading from 'react-loading';
import { Table } from '../../../components/elements';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const TransactionsSummaryTable = ({
  transactionsTotalData,
  loading,
  onClick,
  canLoadMoreData,
  loadMoreData,
}) => (
  <div style={{ padding: '10px' }}>
    <h1 id="transactions-totals-heading-csv-pdf" style={{ display: 'none' }}>
      Transaction Totals
    </h1>
    <div id="transactions-totals-table">
      <Table>
        <thead>
          <tr>
            {[
              'Venue',
              'All Orders',
              'Total',
              'Subtotal',
              'Promo',
              'Oth Charges',
              'Payout',
              'Application Fees',
              'Pickup Orders',
              'Pickup Sales',
              'Dinein Orders',
              'Dinein Sales',
              'Delivery Orders',
              'Delivery Sales',
              'C Fees',
              'Service Fees',
              'Delivery Fees',
              'KRAVEiN Fees',
              'Franchisee Fees',
              'B Fees',
            ].map(headerCellValue => (
              <th key={headerCellValue} style={{ fontWeight: 700 }}>
                <div>
                  <span>{headerCellValue}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        {(transactionsTotalData ?? []).length !== 0 && (
          <tbody>
            {transactionsTotalData.map(ttData => (
              <tr
                style={{ fontWeight: 600, cursor: 'pointer' }}
                key={ttData.Venue}
                onClick={() => onClick(ttData.placeId)}
              >
                <td style={{ color: '#00AEEF', whiteSpace: 'nowrap' }}>
                  {ttData.Venue}
                </td>
                <td>{ttData.Orders}</td>
                <td>{ttData.Total}</td>
                <td>{ttData.Subtotal}</td>
                <td>{ttData.Promo}</td>
                <td>{ttData['Oth Charges']}</td>
                <td>{ttData.Payout}</td>
                <td>{ttData['Application Fee']}</td>
                <td>{ttData['Pickup Orders']}</td>
                <td>{ttData['Pickup Sales']}</td>
                <td>{ttData['Dinein Orders']}</td>
                <td>{ttData['Dinein Sales']}</td>
                <td>{ttData['Del Orders']}</td>
                <td>{ttData['Del Sales']}</td>
                {/* <td>{ttData['Processing Fee']}</td> */}
                <td>{ttData['C Fees']}</td>
                <td>{ttData['Service Fees']}</td>
                <td>{ttData['Delivery Fee']}</td>
                <td>{ttData['KRAVEiN Fees']}</td>
                <td>{ttData['Franchisee Fees']}</td>
                <td>{ttData['B Fees']}</td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </div>
    {loading && (
      <Center>
        <Loading type="spin" color="#363636" width="60px" height="60px" />
      </Center>
    )}
    {canLoadMoreData && (
      <PaginationContainer>
        <ShowMoreButton type="button" onClick={loadMoreData}>
          <span>Show More</span>
        </ShowMoreButton>
      </PaginationContainer>
    )}
  </div>
);

export default TransactionsSummaryTable;
