import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Loading, Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import client from '../../../utils/apolloClient';

import DealForm from './DealForm';

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      date_of_birth
      gender
      description
      status
      user_handle
      external_payment_id
      visibility
      rating
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      access {
        place_id
      }
      address {
        address_id
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const UserPromo = ({ history, match }) => (
  <Layout>
    <Query
      client={client.clientPrivate}
      query={userQuery}
      variables={{
        userId: match.params.userId,
        user_handle: '',
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <Message>error</Message>;
        }

        const { fetch_user } = data;

        return <DealForm user={fetch_user} history={history} />;
      }}
    </Query>
  </Layout>
);

export default UserPromo;
