import React from 'react';
import { Link } from 'react-router-dom';

const CheckInList = ({ tableSetting }) => (
  <>
    <td>
      <Link to={`/table-booking-manager/${tableSetting.place_id}`}>
        {tableSetting.place_name}
      </Link>
    </td>
    <td>{tableSetting.service_type}</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </>
);

export default CheckInList;
