import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Tab } from 'rbx';
import { differenceBy, isNull } from 'lodash';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import EventTicketFee from './EventTicketFee';
import EventFee from './EventFee';
import PlaceMangedFee from './PlaceMangedFee';
import Footer from './Footer';
import { placeFeeManagementHandler } from '../../../utils/helpers';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Place Event Override Fee',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { handleSubmit, place, loading, back } = props;
  return (
    <React.Fragment>
      <Header
        heading="Edit Place Event Override Fee"
        placeName={`${place.name} | ${place.city}, ${place.post_code} | ${place.country}`}
        handleSubmit={handleSubmit}
        loading={loading}
        back={back}
      />

      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Container>
          <EventTicketFee {...props} />
          <EventFee {...props} />
          <br />
          <PlaceMangedFee {...props} />
        </Container>
      </Box>
      <hr />
      <Footer loading={loading} handleSubmit={handleSubmit} back={back} />
    </React.Fragment>
  );
};

const ClaimPlace = withFormik({
  mapPropsToValues: ({ generalFee, place }) => {
    const generalFeeFilterByServiceType = generalFee.filter(
      item => item.service_type === 'event',
    );
    const generalFeeCustomize = generalFeeFilterByServiceType.map(item => ({
      ...item,
      value: parseFloat(item.value).toFixed(2),
      isOverride: false,
      exempt_fee: false,
      place_managed: false,
    }));
    const placeFeeCustomize =
      place && !isNull(place.fee)
        ? place.fee.map(item => ({
            ...item,
            value: parseFloat(item.value).toFixed(2),
            isOverride: true,
          }))
        : null;
    const fee = placeFeeManagementHandler(
      generalFeeCustomize,
      placeFeeCustomize,
    );
    // const placeFeeOverride = fee.map(item => ({ ...item, isOverride: false }));
    const eventTicketFee = fee.filter(item => item.name.startsWith('o'));
    const eventFee = differenceBy(fee, eventTicketFee, 'name');
    const eventFeeWithoutPlaceMange = eventFee.filter(
      item => item.place_managed === false,
    );
    const placeManagedFee = eventFee.filter(
      item => item.place_managed === true,
    );

    return {
      eventTicketFee,
      eventFee: eventFeeWithoutPlaceMange,
      placeManagedFee,
    };
  },

  validationSchema: yup.object().shape({
    eventTicketFee: yup.array().of(
      yup.object().shape({
        applies_to: yup.string().required('Applies to is Required'),
        label: yup.string().required('Label is Required'),
        sales_method: yup.string().required('Sales Method is Required'),
        service_type: yup.string().required('Service Type is Required'),
        value: yup.number().required('Value is Required'),
        fee_value_type: yup.string().required('Value type is Required'),
      }),
    ),

    eventFee: yup.array().of(
      yup.object().shape({
        applies_to: yup.string().required('Applies to is Required'),
        label: yup.string().required('Label is Required'),
        sales_method: yup.string().required('Sales Method is Required'),
        // service_type: yup.string().required('Service Type is Required'),
        value: yup.number().required('Value is Required'),
        fee_value_type: yup.string().required('Value type is Required'),
      }),
    ),
    placeManagedFee: yup.array().of(
      yup.object().shape({
        applies_to: yup.string().required('Applies to is Required'),
        label: yup.string().required('Label is Required'),
        sales_method: yup.string().required('Sales Method is Required'),
        // service_type: yup.string().required('Service Type is Required'),
        value: yup.number().required('Value is Required'),
        fee_value_type: yup.string().required('Value type is Required'),
      }),
    ),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },

  displayName: 'BasicForm',
})(Form);

export default ClaimPlace;
