import React, { useState } from 'react';
import Editor from 'react-simple-wysiwyg';

const BasicTextEditor = ({ handleChange }) => {
  const [value, setValue] = useState('');

  return (
    <div className="mb-6">
      <Editor
        value={value}
        onChange={e => {
          setValue(e.target.value);
          handleChange(e.target.value);
        }}
      />
    </div>
  );
};

export default BasicTextEditor;
