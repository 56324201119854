import React from 'react';

import { Tab } from 'rbx';

import {
  Tabs,
  // InputErrorMessage,
} from '../../../components/elements';

const TabSection = ({ tab, setTab }) => (
  <Tabs className="tabs is-medium">
    <Tab active={tab === 0} onClick={() => setTab(0)}>
      Online Bookings
    </Tab>
    <Tab active={tab === 1} onClick={() => setTab(1)}>
      Booking Hours
    </Tab>
    <Tab active={tab === 2} onClick={() => setTab(2)}>
      Booking Custom Hours
    </Tab>
    <Tab active={tab === 3} onClick={() => setTab(3)}>
      Tags & Tables
    </Tab>
    <Tab active={tab === 4} onClick={() => setTab(4)}>
      Notification Settings
    </Tab>
  </Tabs>
);

export default TabSection;
