import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

// user_id: String
// template_id: String
// name: String
// status: String
// notification_platform: String

const createNotificationTemplateMutation = gql`
  mutation createNotificationTemplateMutation(
    $input: NotificationTemplateInput
  ) {
    create_notification_template(input: $input) {
      notification_template_id
      notification_platform
      notification_id
      name
      status
      error {
        code
        description
      }
    }
  }
`;

const getError = res =>
  Array.isArray(res?.error) && res.error[0] ? res.error[0].description : null;

export const createNotificationTemplate = async ({ input }) => {
  try {
    const {
      data: { create_notification_template },
    } = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createNotificationTemplateMutation,
      variables: { input },
    });
    return getError(create_notification_template);
  } catch (error) {
    return 'Error : UNEXPECTED NETWORK ERROR';
  }
};

const updateNotificationTemplateMutation = gql`
  mutation updateNotificationTemplateMutation(
    $input: NotificationTemplateInput
  ) {
    update_notification_template(input: $input) {
      notification_template_id
      notification_platform
      notification_id
      name
      status
      error {
        code
        description
      }
    }
  }
`;

export const updateNotificationTemplate = async ({ input }) => {
  try {
    const {
      data: { update_notification_template },
    } = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateNotificationTemplateMutation,
      variables: { input },
    });
    return getError(update_notification_template);
  } catch (error) {
    return 'Error : UNEXPECTED NETWORK ERROR';
  }
};

export const fetchNotificationTemplateQuery = gql`
  query fetchNotificationTemplateQuery(
    $userId: String
    $notificationId: String
  ) {
    fetch_notification_template(
      input: { user_id: $userId, notification_id: $notificationId }
    ) {
      service_type
      notification_template_id
      notification_id
      name
      status
      notification_platform
      user_role
      api_action
      action_status
      reply_to
      bcc
      cc
      to
      error {
        code
        description
      }
    }
  }
`;
export const fetchNotificationTemplate = async ({ userId, notificationId }) => {
  try {
    const {
      data: { fetch_notification_template },
    } = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: fetchNotificationTemplateQuery,
      variables: {
        userId,
        notificationId,
      },
    });
    if (fetch_notification_template) {
      return fetch_notification_template;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const searchNotificationTemplateQuery = gql`
  query searchNotificationTemplateQuery($input: SearchInput) {
    search_notification_template(input: $input) {
      total_pages
      total_size
      notification_template_listing {
        notification_id
        notification_template_id
        notification_platform
        status
        name
      }
    }
  }
`;

export const searchNotificationTemplate = async () => {
  try {
    await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchNotificationTemplateQuery,
      variables: {
        input: {
          user_id: '09850baf-a34d-44e7-a222-73f8d6c7cb99',
        },
      },
    });
    return null;
  } catch (error) {
    return null;
  }
};

export default {};
