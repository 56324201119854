import React from 'react';
import LocationForm from '../../../components/LocationForm';
import PrimaryDetailsForm from './PrimaryDetailsForm';
import Contacts from '../../../components/Contacts';
import Socials from '../../../components/Socials';

const PlaceInformation = ({ props }) => (
  <div>
    <PrimaryDetailsForm {...props} />
    <br />
    <LocationForm action={['create_place']} {...props} />
    <br />
    <Contacts action={['update_place', 'create_place']} {...props} />
    <br />
    <Socials action={['update_place', 'create_place']} {...props} />
  </div>
);
export default PlaceInformation;
