/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, forEach } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';

import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';
import PostForm from './PostForm';

const createFeedMutation = gql`
  mutation createFeed($input: FeedInput) {
    create_feed(input: $input) {
      feed_id
      error {
        description
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
      });
    }
  });
  return imageValues;
};

const PlacePostingAdd = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);
  const [images, setImages] = useState([]);
  const [placeId, setPlaceId] = useState('');

  return (
    <Layout>
      {placeSelection ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createImageMutation}
          onCompleted={() => {
            swal(
              'Great!',
              'Place posting with images has been created successfully!',
              'success',
            ).then(() => {
              history.goBack();
            });
          }}
        >
          {(create_image, { loading: imageLoading, error: imageError }) => (
            <Mutation
              client={client.clientPrivate}
              mutation={createFeedMutation}
              onCompleted={({ create_feed }) => {
                if (!isNull(create_feed.error)) {
                  create_feed.error.map(item => {
                    toast.error(item.description);
                  });
                } else if (images.length !== 1) {
                  create_image({
                    variables: {
                      input: {
                        user_id: userId,
                        object_id: create_feed.feed_id,
                        object_type: 'PLACE_FEED',
                        parent_id: placeId,
                        upload_type: 'ADMIN',
                        parent_type: 'PLACE',
                        app_type: 'ADMINUSER',
                        images: parseImage(images),
                      },
                    },
                  });
                } else {
                  swal(
                    'Great!',
                    'Place posting has been successfully published!',
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }
              }}
            >
              {(create_feed, { loading, error }) => (
                <PostForm
                  userId={userId}
                  error={error || imageError}
                  loading={loading || imageLoading}
                  place={place}
                  createForm
                  onSubmit={value => {
                    setImages(value.image);
                    setPlaceId(value.place_id);
                    const final = {};
                    Object.assign(
                      final,
                      {
                        approval_status: value.approval_status,
                      },
                      { content: value.content },
                      { default_image_url: value.default_image_url },
                      { feed_id: value.feed_id },
                      { object_id: value.object_id },
                      { object_type: value.object_type },
                      { status: value.status },
                      { title: value.title },
                      { user_id: value.user_id },
                    );
                    create_feed({ variables: { input: final } });
                  }}
                  isAddComponent
                />
              )}
            </Mutation>
          )}
        </Mutation>
      ) : (
        <PlaceSelection
          heading="Select Place to add postings"
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default withRouter(PlacePostingAdd);
