import React, { useState } from 'react';
import { Column } from 'rbx';
// import styled from 'styled-components';

import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import client from '../../../utils/apolloClient';

import { Input, Select } from '../../../components/elements';
import DictionarySuggestionInput from './DictionarySuggestionInput';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

// const Container = styled.div`
//   fieldset {
//     margin-right: 6rem;
//   }
// `;

// const checkItemStatusForDictionary = values => {
//   const activeItems = values.filter(item => item.status === 'ACTIVE');
//   const dictionaryStatus = activeItems.length === 0;
//   return dictionaryStatus;
// };

const updateItemMutation = gql`
  mutation updateItem($input: ItemInput) {
    update_item(input: $input) {
      item_id
      item_dictionary_id
      name
      status
      error {
        description
      }
    }
  }
`;

const Movement = ({ onSubmit, itemName, loading }) => {
  const [destinationDictionaryId, setDestinationDictionaryId] = useState(null);
  const [destinationDictionaryName, setDestinationDictionaryName] = useState(
    null,
  );
  return (
    <>
      <Column size={6}>
        <DictionarySuggestionInput
          itemName={itemName}
          label="Link and move this item to another item dictionary name"
          initialValue={destinationDictionaryName}
          onSelect={dictionary => {
            setDestinationDictionaryId(dictionary.item_dictionary_id);
            setDestinationDictionaryName(dictionary.name);
          }}
          onHandleClear={length => {
            if (length === 0) {
              setDestinationDictionaryId(null);
              setDestinationDictionaryName(null);
            }
          }}
        />
      </Column>
      {destinationDictionaryId && (
        <Column size={2}>
          <br />
          <button
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={() => {
              swal({
                title: 'Are you sure?',
                // eslint-disable-next-line max-len
                text: `Would you like to change the item name from "${itemName}" to "${destinationDictionaryName}  and move the linked reviews and images`,
                buttons: ['Cancel', 'Yes move item'],
                dangerMode: false,
              }).then(willDelete => {
                if (willDelete) {
                  onSubmit(destinationDictionaryId, destinationDictionaryName);
                }
              });
            }}
          >
            <span>Move Item</span>
          </button>
        </Column>
      )}
    </>
  );
};

const ItemDictionaryDetails = ({
  item,
  // touched,
  // errors,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
  index,
  // handleSubmit,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [moveItemAction, setMoveItemAction] = useState(false);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={updateItemMutation}
      onCompleted={({ update_item }) => {
        if (!isNull(update_item.error)) {
          update_item.error.map(edge => toast.error(edge.description));
        } else if (moveItemAction) {
          setValues({
            ...values,
            items: values.items.filter(
              edge => edge.item_id !== update_item.item_id,
            ),
          });
          toast.success(' Moved Item successfully!');
          setMoveItemAction(false);
        } else {
          toast.success('Item status updated successfully!');
        }
      }}
    >
      {(update_item, { loading: isLoading }) => (
        <fieldset className="box">
          <Column.Group vcentered multiline gapSize={8}>
            <Column size={4}>
              <Input
                label="Item Name"
                name="item_name"
                value={item.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
            </Column>
            <Movement
              itemName={item.name}
              loading={isLoading}
              onSubmit={(
                destinationDictionaryId,
                destinationDictionaryName,
              ) => {
                setMoveItemAction(true);
                update_item({
                  variables: {
                    input: {
                      user_id: userId,
                      item_id: item.item_id,
                      item_dictionary_id: destinationDictionaryId,
                      name: destinationDictionaryName,
                    },
                  },
                });
              }}
            />
          </Column.Group>
          <Column.Group vcentered multiline gapSize={8}>
            <Column size={4}>
              <Input label="Place Name" value={item.place_name} disabled />
            </Column>

            <Column size={4}>
              <Input label="City / Suburb" value={item.city} disabled />
            </Column>

            <Column size={4}>
              <Select
                label="Item Status"
                name="item_status"
                options={status}
                value={[{ value: item.status, label: item.status }]}
                onChange={({ value }) => {
                  setFieldValue(`items.${index}.status`, value);
                  update_item({
                    variables: {
                      input: {
                        user_id: userId,
                        item_id: item.item_id,
                        item_dictionary_id: item.item_dictionary_id,
                        status: value,
                      },
                    },
                  });
                }}
                disabled={values.status === 'INACTIVE'}
                isLoading={isLoading}
              />
            </Column>
            <Column size={4}>
              <Input
                label="Review Submitted"
                value={item.review_submitted}
                disabled
              />
            </Column>
            <Column size={4}>
              <Input
                label="Review Approved"
                value={item.review_approved}
                disabled
              />
            </Column>
            <Column size={4}>
              <Input
                label="Review Rejected"
                value={item.review_rejected}
                disabled
              />
            </Column>
          </Column.Group>
        </fieldset>
      )}
    </Mutation>
  );
};

export default ItemDictionaryDetails;
