import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { omit, first, forEach, concat, isNull } from 'lodash';
import moment from 'moment-timezone';
import { useStoreState } from 'easy-peasy';
import tzLookup from 'tz-lookup';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import AddEventForm from './AddEventForm';
import PlaceSelection from '../../../components/PlaceSelection';
import { Message } from '../../../components/elements';
import { parseContactValidValue } from '../../../utils/helpers';

const createEventMutation = gql`
  mutation createEvent($input: EventInput) {
    create_event(input: $input) {
      place_id
      event_id
      post_code
      slug
      city
      country
      listing_type
      error {
        code
        description
      }
    }
  }
`;

const createEventOccurrenceMutation = gql`
  mutation createEventOccurrence($input: EventOccurrenceInput) {
    create_event_occurrence(input: $input) {
      event_id
      event_occurrence_id
      error {
        code
        description
      }
    }
  }
`;

const createEventOccurrenceTicketsMutation = gql`
  mutation createEventOccurrenceTickets($input: [TicketInput]) {
    create_event_occurrence_ticket(input: $input) {
      event_occurrence_id
      ticket_id
      error {
        code
        description
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
      });
    }
  });
  return imageValues;
};

const AddEvent = ({ history }) => {
  const [step, setStep] = useState('1');
  const [selectPlace, setSelectedPlace] = useState();
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  const setTimeZone = value => {
    const lat = parseFloat(value.latitude).toFixed(5);
    const long = parseFloat(value.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    setCurrentTimeZone(timeZone);
    console.log('timeZone', timeZone);
    moment.tz.setDefault(timeZone);
  };

  return (
    <Layout>
      <div className="container">
        {step === '1' && (
          <PlaceSelection
            heading="Select a place to add an event"
            handleSelect={place => {
              setTimeZone(place);
              setSelectedPlace(place);
              setStep('2');
            }}
          />
        )}
        {step === '2' && selectPlace && (
          <View
            place={selectPlace}
            currentTimeZone={currentTimeZone}
            history={history}
          />
        )}
      </div>
    </Layout>
  );
};

const View = ({ history, place, currentTimeZone }) => {
  const [occurrenceInput, setOccurrenceInput] = useState({});
  const [ticketsInput, setTicketsInput] = useState([]);
  const [isOccurrenceTrigger, setIsOccurrenceTrigger] = useState(false);
  const [images, setImages] = useState([]);
  const [eventId, setEventId] = useState([]);

  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={createEventOccurrenceTicketsMutation}
      variables={{ input: [...ticketsInput] }}
      onCompleted={() => {
        swal('Hurray!', 'Event had been created successfully!', 'success').then(
          () => {
            history.goBack();
          },
        );
      }}
    >
      {(
        create_event_occurrence_ticket,
        { loading: loadingTickets, error: ticketsError },
      ) => (
        <Mutation
          client={client.clientPrivate}
          mutation={createEventOccurrenceMutation}
          onCompleted={() => {
            create_event_occurrence_ticket();
          }}
        >
          {(
            create_event_occurrence,
            { loading: occurrenceLoading, error: occurrenceError },
          ) => (
            <Mutation
              client={client.clientPrivate}
              mutation={createImageMutation}
              onCompleted={() => {
                if (isOccurrenceTrigger) {
                  console.log('occurrenceInput', occurrenceInput);
                  create_event_occurrence({
                    variables: { input: { ...occurrenceInput } },
                  });
                } else {
                  swal(
                    'Hurray!',
                    'Event had been created successfully!',
                    'success',
                  ).then(() => {
                    history.goBack();
                  });
                }
              }}
            >
              {(create_image, { loading: imageLoading, error: imageError }) => (
                <Mutation
                  client={client.clientPrivate}
                  mutation={createEventMutation}
                  onCompleted={({ create_event }) => {
                    if (!isNull(create_event.error)) {
                      create_event.error.map(item =>
                        toast.error(item.description),
                      );
                    } else if (images.length === 1) {
                      if (isOccurrenceTrigger) {
                        create_event_occurrence({
                          variables: { input: { ...occurrenceInput } },
                        });
                      } else {
                        swal(
                          'Hurray!',
                          'Event had been created successfully!',
                          'success',
                        ).then(() => {
                          history.goBack();
                        });
                      }
                    } else {
                      create_image({
                        variables: {
                          input: {
                            user_id: userId,
                            object_id: eventId,
                            object_type: 'EVENT',
                            parent_id: place.place_id,
                            upload_type: 'ADMIN',
                            parent_type: 'PLACE',
                            app_type: 'ADMINUSER',
                            links_to: [
                              {
                                id: eventId,
                                type: 'EVENT',
                              },
                            ],
                            images: parseImage(images),
                          },
                        },
                      });
                    }
                  }}
                >
                  {(create_event, { loading, error }) => (
                    <React.Fragment>
                      {(error || occurrenceError || ticketsError) && (
                        <Message type="error">{`${(error && error.message) ||
                          (occurrenceError && occurrenceError.message) ||
                          (ticketsError && ticketsError.message)}`}</Message>
                      )}
                      <AddEventForm
                        place={place}
                        userId={userId}
                        loading={
                          loading ||
                          occurrenceLoading ||
                          loadingTickets ||
                          imageLoading
                        }
                        error={error || imageError}
                        onSubmit={async data => {
                          await setImages(data.image);
                          await setEventId(data.event_id);
                          await setTicketsInput(data.ticket);
                          if (
                            first(data.listing_type) === 'private_tickets' ||
                            first(data.listing_type) === 'public_tickets'
                          ) {
                            setIsOccurrenceTrigger(true);
                          }

                          const customStartDate = moment(data.startDate).format(
                            'YYYY-MM-DD',
                          );
                          const startTimeWhenValueIsNull = moment(
                            data.startDate,
                          ).isSame()
                            ? moment().format('HH:mm:ss')
                            : '00:00:00';

                          const customStartTime = !isNull(data.startTime)
                            ? moment(data.startTime).format('HH:mm:ss')
                            : startTimeWhenValueIsNull;

                          const customEndDate = moment(data.endDate).format(
                            'YYYY-MM-DD',
                          );
                          const customEndTime = !isNull(data.endTime)
                            ? moment(data.endTime).format('HH:mm:ss')
                            : '23:59:00';

                          const startDate = moment
                            .tz(
                              `${customStartDate} ${customStartTime}`,
                              currentTimeZone,
                            )
                            .toISOString();

                          const endDate = moment
                            .tz(
                              `${customEndDate} ${customEndTime}`,
                              currentTimeZone,
                            )
                            .toISOString();

                          const inputs = await omit(data, [
                            'startDate',
                            'endDate',
                            'startTime',
                            'endTime',
                            'ticket',
                            'event_occurrence_id',
                            'business_hour',
                            'primaryEmail',
                            'displayEmail',
                            'primaryMobile',
                            'displayMobile',
                            'businessSite',
                            'displayBusiness',
                            'listing',
                            'visibility',
                            'ticket_holding_time_limit',
                            'total_capacity',
                            'display_remaining_ticket',
                            'total_tickets',
                            'fee',
                            'eventFeeForUpdate',
                            'image',
                            'uploadedImages',
                          ]);

                          await setOccurrenceInput({
                            user_id: userId,
                            event_id: data.event_id,
                            event_occurrence_id: data.event_occurrence_id,
                            start: startDate,
                            end: endDate,
                            status: data.status,
                            display_remaining_ticket:
                              data.display_remaining_ticket,
                            total_capacity: data.total_capacity,
                            total_tickets: data.total_tickets,
                          });

                          const primaryContacts = [
                            {
                              type: 'email_primary',
                              value: data.primaryEmail,
                              display: data.displayEmail,
                              is_primary: true,
                              display_order: '1',
                            },
                            {
                              type: 'phone_primary',
                              value: data.primaryMobile,
                              display: data.displayMobile,
                              is_primary: true,
                              display_order: '1',
                            },
                            {
                              type: 'website',
                              value: data.businessSite,
                              display: data.displayBusiness,
                              is_primary: true,
                              display_order: '1',
                            },
                          ];

                          const formContact = () => {
                            const final = [];
                            forEach(data.contact, item => {
                              const custom = omit(item, ['id']);
                              final.push(custom);
                            });
                            return final;
                          };

                          const feeInputs = () => {
                            const final = [];
                            forEach(data.fee, item => {
                              const custom = omit(item, ['__typename']);
                              final.push(custom);
                            });
                            return final;
                          };

                          const contact = await concat(
                            primaryContacts,
                            formContact(),
                          );

                          const final = await {
                            ...inputs,
                            contact: parseContactValidValue(contact),
                            start: startDate,
                            end: endDate,
                            fee: feeInputs(),
                          };
                          create_event({ variables: { input: final } });
                        }}
                      />
                    </React.Fragment>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default withRouter(AddEvent);
