import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  Message,
} from '../../../components/elements';

const searchItemDictionaryQuery = gql`
  query searchItemDictionary($input: SearchInput) {
    search_item_dictionary(input: $input) {
      total_pages
      total_size
      item_dictionary_listing {
        item_dictionary_id
        name
        description
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const listSize = 10;

const ItemReviews = props => {
  const routeState = qs.parse(props.location.search);
  const { name, status, page } = routeState;
  const statusFilter = status || 'ACTIVE';
  const nameFilter = name || '';
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('UPDATED_DESC');
  const [itemNameSort, setItemNameSort] = useState('AES');

  // const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();

  const item_dictionary_filter = { status: statusFilter };
  Object.assign(
    item_dictionary_filter,
    nameFilter.length !== 0 && { name: nameFilter },
  );
  const input = {
    sort,
    from: currentPage * listSize,
    size: listSize,
    filter: {
      item_dictionary_filter,
    },
  };

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          name={nameFilter}
          status={statusFilter}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              props.history.push(
                `/item-dictionaries${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setItemNameSort(itemNameSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          itemNameSort === 'AES' ? 'NAME_DESC' : 'NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          itemNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Item Dictionary Name
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>Created By</span>
                </div>
              </th>

              <th>
                <div>
                  <span>Updated At</span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_ASC' : 'STATUS_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>Action</span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={searchItemDictionaryQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.message}</Message>;
              }
              const { search_item_dictionary } = data;
              if (search_item_dictionary.item_dictionary_listing.length === 0) {
                return 'NO RECORDS FOUND';
              }
              return (
                <View
                  search_item_dictionary={search_item_dictionary}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({ search_item_dictionary, setPageCount, setTotalElements }) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_item_dictionary.total_pages);
    setTotalElements(search_item_dictionary.total_size);

    if (search_item_dictionary.item_dictionary_listing) {
      const userFilter = search_item_dictionary.item_dictionary_listing.map(
        item => item.audit.created_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_item_dictionary.total_pages]);

  const dictionaryData = search_item_dictionary.item_dictionary_listing;

  return (
    <tbody>
      {dictionaryData.map(dictionary => (
        <ListItem dictionary={dictionary} users={users} />
      ))}
    </tbody>
  );
};

export default ItemReviews;
