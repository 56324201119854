import React from 'react';
import { Link } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">Email Notifications</Title>
      </Level.Item>
      <Level.Item align="right">
        <Link
          to="/create-mobile-push-notification"
          className="button is-primary"
        >
          <span className="has-text-weight-semibold">
            Add Notification Template
          </span>
        </Link>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Header;
