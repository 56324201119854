import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage, Loading } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import { getOpenCloseStatus, updateOpenCloseStatus } from './helper';
import { updateServiceTypeSetting } from './Designer/helpers';

import Form from './Form';

const menuQuery = gql`
  query menu($input: SearchInput) {
    search_menu(input: $input) {
      menu_listing {
        place_id
        platform
        service_type_setting {
          service_type_setting_id
          service_type
        }
        place_name
        menu_id
        name
        publish_menu
        internal_name
        slug
        description
        status
        approval_status
        is_private_menu
        sales_method
        place_slug
        qr_code
        qr_code_url
        menu_hour {
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
      }
    }
  }
`;

const updateMenuMutation = gql`
  mutation updateMenu($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const fetchDisplayMenuHours = gql`
  query fetchDisplayMenuHours($service_type_setting_id: String) {
    fetch_service_type_setting(
      input: { service_type_setting_id: $service_type_setting_id }
    ) {
      display_menu_hours
      is_custom_service
    }
  }
`;

const silentUpdateMenu = async inputArray =>
  client.clientPrivate.mutate({
    client: client.clientPrivate,
    mutation: updateMenuMutation,
    variables: {
      input: [inputArray],
    },
  });

const MenuTypeUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const { id } = match.params;

  const [openCloseStatus, setOpenCloseStatus] = useState(null);
  const [menuListing, setMenuListing] = useState([]);
  const [openCloseLoading, setOpenCloseLoading] = useState(false);

  // const [menuListingLoading, setMenuListingLoading] = useState(true);

  useEffect(() => {
    getOpenCloseStatus(id).then(status => {
      setOpenCloseStatus({ ...status, touched: false });
    });
  }, []);

  return (
    <Layout>
      <Query
        client={client.clientPublic}
        query={menuQuery}
        variables={{
          input: {
            filter: {
              menu_filter: { menu_id: id },
            },
          },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const menu = data.search_menu.menu_listing[0];
          const serviceTypeSettingId =
            menu.service_type_setting.service_type_setting_id;

          return (
            <Query
              client={client.clientPrivate}
              query={fetchDisplayMenuHours}
              variables={{ service_type_setting_id: serviceTypeSettingId }}
            >
              {({
                data: serviceTypeData,
                loading: serviceTypeDataLoading,
                error: serviceTypeDataLoadingError,
              }) => {
                if (serviceTypeDataLoading) {
                  return <Loading />;
                }
                if (serviceTypeDataLoadingError) {
                  return <ErrorMessage message={error.message} />;
                }
                const {
                  display_menu_hours,
                  is_custom_service,
                } = serviceTypeData.fetch_service_type_setting;
                return (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={updateMenuMutation}
                    onCompleted={({ update_menu }) => {
                      if (!isNull(update_menu[0].error)) {
                        update_menu[0].error.map(item =>
                          toast.error(item.description),
                        );
                      } else {
                        swal(
                          'Great!',
                          'Menu updated successfully!',
                          'success',
                        ).then(() => {
                          history.goBack();
                        });
                      }
                    }}
                  >
                    {(
                      update_menu,
                      { loading: updateLoading, error: updateError },
                    ) => (
                      <>
                        {updateError && (
                          <ErrorMessage message={updateError.message} />
                        )}
                        <Form
                          isEdit
                          userId={userId}
                          menu={menu}
                          serviceTypeSettingId={serviceTypeSettingId}
                          setMenuListing={setMenuListing}
                          // setMenuListingLoading={setMenuListingLoading}
                          loading={updateLoading || openCloseLoading}
                          openCloseStatus={openCloseStatus}
                          display_menu_hours={display_menu_hours}
                          is_custom_service={is_custom_service}
                          onToggle={({
                            menu_id,
                            user_id,
                            place_id,
                            publish_menu,
                          }) => {
                            silentUpdateMenu({
                              menu_id,
                              user_id,
                              place_id,
                              publish_menu,
                            });
                          }}
                          onSubmit={inputs => {
                            const input = omit(inputs, ['menus', 'step']);
                            const inputsArray = {
                              menu_id: input.menu_id,
                              user_id: input.user_id,
                              menu_hour: input.menu_hour,
                              place_id: input.place_id,
                            };

                            Object.assign(
                              inputsArray,
                              !isEqual(
                                input.publish_menu,
                                menu.publish_menu,
                              ) && {
                                publish_menu: input.publish_menu,
                              },
                              !isEqual(
                                input.approval_status,
                                menu.approval_status,
                              ) && {
                                approval_status: input.approval_status,
                              },
                              !isEqual(input.description, menu.description) && {
                                description: input.description,
                              },
                              !isEqual(input.name, menu.name) && {
                                name: input.name,
                              },
                              !isEqual(input.place_id, menu.place_id) && {
                                place_id: input.place_id,
                              },
                              !isEqual(
                                input.service_type_setting_id,
                                menu.service_type_setting
                                  .service_type_setting_id,
                              ) && {
                                service_type_setting_id:
                                  input.service_type_setting_id,
                              },
                              !isEqual(input.status, menu.status) && {
                                status: input.status,
                              },
                            );

                            if (input.status === 'INACTIVE') {
                              Object.assign(inputsArray, {
                                publish_menu: false,
                              });
                            }
                            const shldUpdateMenuFunc = () => {
                              const keys = [
                                'menu_hour',
                                'publish_menu',
                                'approval_status',
                                'description',
                                'name',
                                'service_type_setting_id',
                                'status',
                              ];

                              const inputsArrayKeys = Object.keys(
                                inputsArray,
                              ).filter(
                                ele =>
                                  inputsArray[ele] !== undefined &&
                                  inputsArray[ele] !== null,
                              );

                              return keys.some(key =>
                                inputsArrayKeys.includes(key),
                              );
                            };

                            const shldUpdateMenu = shldUpdateMenuFunc();

                            if (input.openCloseHours.touched) {
                              updateOpenCloseStatus(
                                input.menu_id,
                                input.openCloseHours.hours,
                                () => {
                                  if (!shldUpdateMenu) {
                                    setOpenCloseLoading(true);
                                  }
                                },
                                success => {
                                  if (!shldUpdateMenu) {
                                    setOpenCloseLoading(false);
                                    if (success) {
                                      swal(
                                        'Great!',
                                        'Menu updated successfully!',
                                        'success',
                                      ).then(() => {
                                        history.goBack();
                                      });
                                    }
                                  }
                                },
                              );
                            }

                            if (shldUpdateMenu) {
                              update_menu({
                                variables: { input: [inputsArray] },
                              });

                              const menuListingWithoutCurrentMenu = menuListing.filter(
                                mnu => mnu.menu_id !== id,
                              );
                              if (menuListingWithoutCurrentMenu.length === 0) {
                                updateServiceTypeSetting({
                                  input: {
                                    user_id: userId,
                                    service_type_setting_id:
                                      menu.service_type_setting
                                        .service_type_setting_id,
                                    place_id: menu.place_id,
                                    publish_service_type:
                                      input.status === 'ACTIVE'
                                        ? input.publish_menu
                                        : false,
                                  },
                                });
                              } else if (
                                menuListingWithoutCurrentMenu.some(
                                  mnu => mnu.publish_menu === true,
                                )
                              ) {
                                updateServiceTypeSetting({
                                  input: {
                                    user_id: userId,
                                    service_type_setting_id:
                                      menu.service_type_setting
                                        .service_type_setting_id,
                                    place_id: menu.place_id,
                                    publish_service_type: true,
                                  },
                                });
                              } else if (
                                menuListingWithoutCurrentMenu.every(
                                  mnu =>
                                    mnu.publish_menu === false ||
                                    mnu.publish_menu === null ||
                                    mnu.publish_menu === undefined,
                                )
                              ) {
                                updateServiceTypeSetting({
                                  input: {
                                    user_id: userId,
                                    service_type_setting_id:
                                      menu.service_type_setting
                                        .service_type_setting_id,
                                    place_id: menu.place_id,
                                    publish_service_type:
                                      input.status === 'ACTIVE'
                                        ? input.publish_menu
                                        : false,
                                  },
                                });
                              }
                            }
                          }}
                        />
                      </>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default MenuTypeUpdate;
