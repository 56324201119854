import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';

import Items from './ModifierItems';
import SearchItemModal from './SearchItemModal';

const AddItemsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 40%;
  }
`;

const ModifierItems = ({ menuItemId }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  return (
    <FieldArray
      name="modifier"
      render={helpers => {
        const { values, setFieldValue } = helpers.form;

        return (
          <>
            <AddItemsButton
              type="button"
              className="button is-medium"
              onClick={() => setIsActiveModal(true)}
            >
              Add Items
            </AddItemsButton>

            <Items items={values.modifier} setFieldValue={setFieldValue} />
            <SearchItemModal
              placeId={values.place_id}
              menuItemId={menuItemId}
              isCreateLinkLoading={false}
              initialsItems={values.modifier}
              isActive={isActiveModal}
              onClose={() => setIsActiveModal(false)}
              onSubmit={inputs => {
                setFieldValue('modifier', values.modifier.concat(inputs));
                setIsActiveModal(false);
              }}
            />
          </>
        );
      }}
    />
  );
};

export default ModifierItems;
