import { config } from '../../../utils/config';

const URLS = {
  prod: [
    'https://www.kravein.com.au/order-food/Pickup/aashiana-balwyn',
    'https://www.kravein.com.au/order-food/Delivery/aashiana-balwyn',
    'https://www.kravein.com.au/order-food/Pickup/amazing-aromass-mornington',
    'https://www.kravein.com.au/order-food/Delivery/amazing-aromass-mornington',
    'https://www.kravein.com.au/order-food/Pickup/burger-burger-surrey-hills',
    'https://www.kravein.com.au/order-food/Delivery/burger-burger-surrey-hills',
    'https://www.kravein.com.au/order-food/Dinein/burger-burger-surrey-hills',
    'https://www.kravein.com.au/order-food/Delivery/burger-days-frankston',
    'https://www.kravein.com.au/order-food/Dinein/burger-days-frankston',
    'https://www.kravein.com.au/order-food/Pickup/burger-days-frankston',
    'https://www.kravein.com.au/order-food/Delivery/burger-love-frankston',
    'https://www.kravein.com.au/order-food/Pickup/burger-love-frankston',
    'https://www.kravein.com.au/order-food/Pickup/burger-love-frankston',
    'https://www.kravein.com.au/order-food/Pickup/cafe-85-percent-mount-martha',
    'https://www.kravein.com.au/order-food/Delivery/cafe-85-percent-mount-martha',
    'https://www.kravein.com.au/order-food/Pickup/choego-chicken-frankston',
    'https://www.kravein.com.au/order-food/Delivery/choego-chicken-frankston',
    'https://www.kravein.com.au/order-food/Dinein/choego-chicken-frankston',
    'https://www.kravein.com.au/order-food/Pickup/chinese-dumpling-dynasty-penrith',
    'https://www.kravein.com.au/order-food/Delivery/chinese-dumpling-dynasty-penrith',
    'https://www.kravein.com.au/order-food/Pickup/dumplings-eats-frankston',
    'https://www.kravein.com.au/order-food/Delivery/dumplings-eats-frankston',
    'https://www.kravein.com.au/order-food/Dinein/dumplings-eats-frankston',
    'https://www.kravein.com.au/order-food/Delivery/fortune-kitchen-mornington',
    'https://www.kravein.com.au/order-food/Pickup/fortune-kitchen-mornington',
    'https://www.kravein.com.au/order-food/Delivery/frankston-kebab-house-frankston',
    'https://www.kravein.com.au/order-food/Pickup/frankston-kebab-house-frankston',
    'https://www.kravein.com.au/order-food/Dinein/frankston-thai-hut-frankston',
    'https://www.kravein.com.au/order-food/Pickup/frankston-thai-hut-frankston',
    'https://www.kravein.com.au/order-food/Delivery/frankston-thai-hut-frankston',
    'https://www.kravein.com.au/order-food/Pickup/fratellis-frankston',
    'https://www.kravein.com.au/order-food/Delivery/fratellis-frankston',
    'https://www.kravein.com.au/order-food/Pickup/gami-chicken-beer-frankston',
    'https://www.kravein.com.au/order-food/Delivery/gami-chicken-beer-frankston',
    'https://www.kravein.com.au/order-food/Dinein/geonbae-frankston',
    'https://www.kravein.com.au/order-food/Pickup/geonbae-frankston',
    'https://www.kravein.com.au/order-food/Delivery/geonbae-frankston',
    'https://www.kravein.com.au/order-food/Pickup/gods-kitchen-mornington',
    'https://www.kravein.com.au/order-food/Delivery/gods-kitchen-mornington',
    'https://www.kravein.com.au/order-food/Dinein/gods-kitchen-mornington',
    'https://www.kravein.com.au/order-food/Pickup/golden-bell-kitchen-camberwell',
    'https://www.kravein.com.au/order-food/Delivery/golden-bell-kitchen-camberwell',
    'https://www.kravein.com.au/order-food/Pickup/good-fellas-kebab-grill-balwyn-north',
    'https://www.kravein.com.au/order-food/Delivery/good-fellas-kebab-grill-balwyn-north',
    'https://www.kravein.com.au/order-food/Pickup/hungry-mouth-pizza-fish-chips-frankston',
    'https://www.kravein.com.au/order-food/Delivery/hungry-mouth-pizza-fish-chips-frankston',
    'https://www.kravein.com.au/order-food/Pickup/huys-kitchen-south-melbourne',
    'https://www.kravein.com.au/order-food/Delivery/huys-kitchen-south-melbourne',
    'https://www.kravein.com.au/order-food/Pickup/jawhara-moroccan-palmyra',
    'https://www.kravein.com.au/order-food/Delivery/jawhara-moroccan-palmyra',

    'https://www.kravein.com.au/order-food/Pickup/king-and-i-thai-surrey-hills',
    'https://www.kravein.com.au/order-food/Delivery/king-and-i-thai-surrey-hills',
    'https://www.kravein.com.au/order-food/Pickup/king-of-kebabs-penrith',
    'https://www.kravein.com.au/order-food/Delivery/king-of-kebabs-penrith',
    'https://www.kravein.com.au/order-food/Pickup/le-feu-mornington-mornington',
    'https://www.kravein.com.au/order-food/Delivery/le-feu-mornington-mornington',
    'https://www.kravein.com.au/order-food/Dinein/le-feu-mornington-mornington',
    'https://www.kravein.com.au/order-food/Pickup/little-narai-of-epping-epping',
    'https://www.kravein.com.au/order-food/Delivery/little-narai-of-epping-epping',
    'https://www.kravein.com.au/order-food/Pickup/little-mias-pizzeria-thomastown',
    'https://www.kravein.com.au/order-food/Delivery/little-mias-pizzeria-thomastown',
    'https://www.kravein.com.au/order-food/Pickup/luna-kebab-pizza-bundoora',
    'https://www.kravein.com.au/order-food/Delivery/luna-kebab-pizza-bundoora',
    'https://www.kravein.com.au/order-food/Pickup/lunar-dumpling-bar-mornington',
    'https://www.kravein.com.au/order-food/Delivery/lunar-dumpling-bar-mornington',
    'https://www.kravein.com.au/order-food/Dinein/lunar-dumpling-bar-mornington',
    'https://www.kravein.com.au/order-food/Pickup/lures-fish-chips-alfred-cove',
    'https://www.kravein.com.au/order-food/Delivery/lures-fish-chips-alfred-cove',
    'https://www.kravein.com.au/order-food/Pickup/main-grill-bentons-square-mornington',
    'https://www.kravein.com.au/order-food/Delivery/main-grill-bentons-square-mornington',
    'https://www.kravein.com.au/order-food/Pickup/main-grill-mornington',
    'https://www.kravein.com.au/order-food/Delivery/main-grill-mornington',
    'https://www.kravein.com.au/order-food/Pickup/maneys-dumpling-bundoora',
    'https://www.kravein.com.au/order-food/Delivery/maneys-dumpling-bundoora',
    'https://www.kravein.com.au/order-food/Dinein/maneys-dumpling-bundoora',
    'https://www.kravein.com.au/order-food/Pickup/Manhattan-in-mornington',
    'https://www.kravein.com.au/order-food/Delivery/Manhattan-in-mornington',
    'https://www.kravein.com.au/order-food/Pickup/masala-magic-kitchen-werrington-county',
    'https://www.kravein.com.au/order-food/Delivery/masala-magic-kitchen-werrington-county',
    'https://www.kravein.com.au/order-food/Dinein/masala-magic-kitchen-werrington-county',
    'https://www.kravein.com.au/order-food/Pickup/Ottoman-Street-Food',
    'https://www.kravein.com.au/order-food/Delivery/Ottoman-Street-Food',
    'https://www.kravein.com.au/order-food/Pickup/naughty-norman-mornington',
    'https://www.kravein.com.au/order-food/Delivery/naughty-norman-mornington',
    'https://www.kravein.com.au/order-food/Dinein/naughty-norman-mornington',
    'https://www.kravein.com.au/order-food/Pickup/new-fortune-palace-chinese-restaurant-penrith',
    'https://www.kravein.com.au/order-food/Delivery/new-fortune-palace-chinese-restaurant-penrith',
    'https://www.kravein.com.au/order-food/Pickup/nishi-japanese-restaurant-myaree',
    'https://www.kravein.com.au/order-food/Delivery/nishi-japanese-restaurant-myaree',
    'https://www.kravein.com.au/order-food/Pickup/noodle-box-mornington',
    'https://www.kravein.com.au/order-food/Delivery/noodle-box-mornington',
    'https://www.kravein.com.au/order-food/Pickup/peking-inn-bundoora',
    'https://www.kravein.com.au/order-food/Delivery/peking-inn-bundoora',
    'https://www.kravein.com.au/order-food/Pickup/petit-thai-cuisine-st-marys',
    'https://www.kravein.com.au/order-food/Delivery/petit-thai-cuisine-st-marys',
    'https://www.kravein.com.au/order-food/Pickup/pho-frankston',
    'https://www.kravein.com.au/order-food/Delivery/pho-frankston',
    'https://www.kravein.com.au/order-food/Pickup/pika-sushi-mornington',
    'https://www.kravein.com.au/order-food/Delivery/pika-sushi-mornington',
    'https://www.kravein.com.au/order-food/Pickup/pisces-dumplings-restaurant-frankston',
    'https://www.kravein.com.au/order-food/Delivery/pisces-dumplings-restaurant-frankston',
    'https://www.kravein.com.au/order-food/Dinein/pisces-dumplings-restaurant-frankston',
    'https://www.kravein.com.au/order-food/Pickup/pizza-republica-surrey-hills',
    'https://www.kravein.com.au/order-food/Delivery/pizza-republica-surrey-hills',
    'https://www.kravein.com.au/order-food/Pickup/pondok-nasi-bakar-south-melbourne',
    'https://www.kravein.com.au/order-food/Delivery/pondok-nasi-bakar-south-melbourne',
    'https://www.kravein.com.au/order-food/Pickup/rudys-fried-chicken-mornington',
    'https://www.kravein.com.au/order-food/Delivery/rudys-fried-chicken-mornington',
    'https://www.kravein.com.au/order-food/Pickup/sakura-mt-eliza-mount-eliza',
    'https://www.kravein.com.au/order-food/Delivery/sakura-mt-eliza-mount-eliza',
    'https://www.kravein.com.au/order-food/Dinein/sakura-mt-eliza-mount-eliza',
    'https://www.kravein.com.au/order-food/Pickup/samrat-indian-restaurant-myaree',
    'https://www.kravein.com.au/order-food/Delivery/samrat-indian-restaurant-myaree',
    'https://www.kravein.com.au/order-food/Pickup/santa-margherita-camberwell',
    'https://www.kravein.com.au/order-food/Delivery/santa-margherita-camberwell',
    'https://www.kravein.com.au/order-food/Pickup/seagulls-greek-taverna-frankston',
    'https://www.kravein.com.au/order-food/Delivery/seagulls-greek-taverna-frankston',
    'https://www.kravein.com.au/order-food/Dinein/seagulls-greek-taverna-frankston',
    'https://www.kravein.com.au/order-food/Pickup/siam-thai-restaurant-penrith',
    'https://www.kravein.com.au/order-food/Delivery/siam-thai-restaurant-penrith',
    'https://www.kravein.com.au/order-food/Pickup/south-Melbourne-fish-and-chips',
    'https://www.kravein.com.au/order-food/Delivery/south-Melbourne-fish-and-chips',
    'https://www.kravein.com.au/order-food/Pickup/spice-club-frankston',
    'https://www.kravein.com.au/order-food/Delivery/spice-club-frankston',
    'https://www.kravein.com.au/order-food/Pickup/spicy-sizzlers-penrith',
    'https://www.kravein.com.au/order-food/Delivery/spicy-sizzlers-penrith',
    'https://www.kravein.com.au/order-food/Dinein/spicy-sizzlers-penrith',
    'https://www.kravein.com.au/order-food/Pickup/stokers-fine-pancakes-south-melbourne',
    'https://www.kravein.com.au/order-food/Delivery/stokers-fine-pancakes-south-melbourne',
    'https://www.kravein.com.au/order-food/Pickup/supreme-leader-chicken-mornington',
    'https://www.kravein.com.au/order-food/Delivery/supreme-leader-chicken-mornington',
    'https://www.kravein.com.au/order-food/Pickup/thai-deli-south-melbourne',
    'https://www.kravein.com.au/order-food/Delivery/thai-deli-south-melbourne',
    'https://www.kravein.com.au/order-food/Pickup/the-greekery-balwyn-north',
    'https://www.kravein.com.au/order-food/Delivery/the-greekery-balwyn-north',
    'https://www.kravein.com.au/order-food/Pickup/turkish-kebabs-on-clarendon-south-melbourne',
    'https://www.kravein.com.au/order-food/Delivery/turkish-kebabs-on-clarendon-south-melbourne',
    'https://www.kravein.com.au/order-food/Delivery/king-of-kebabs-penrith',
    'https://www.kravein.com.au/order-food/Pickup/uthong-thai-mornington',
    'https://www.kravein.com.au/order-food/Delivery/uthong-thai-mornington',
    'https://www.kravein.com.au/order-food/Pickup/yummo-ice-cream-lolly-shop-mornington',
  ],
  dev: [
    'https://dev11.portal.kravein.com.au/order-food/Delivery/thirsty-camel-southbank-st-kilda',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/95-espresso-coffee-st-kilda',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/la-pinoz-pizza-st-kilda',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/subway-st-kilda',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/fitzrovia-st-kilda',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/the-chicken-salad-spot-st-kilda',
    'https://dev11.portal.kravein.com.au/order-food/Delivery/sister-of-soul-stripes-st-kilda-super',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/sister-of-soul-stripes-st-kilda-super',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/kebab-connection-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Delivery/kebab-connection-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Dinein/kebab-connection-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/crystal-jade-chinese-restaurant-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Delivery/crystal-jade-chinese-restaurant-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Dinein/crystal-jade-chinese-restaurant-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/urban-spice-hub-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Delivery/urban-spice-hub-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/fratelli-frankston-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Delivery/fratelli-frankston-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Dinein/fratelli-frankston-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/frankston-thai-hut-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Dinein/frankston-thai-hut-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Pickup/ceylon-girls-cafe-and-bar-frankston',
    'https://dev11.portal.kravein.com.au/order-food/Dinein/ceylon-girls-cafe-and-bar-frankston',
  ],
};

export default URLS[config.apiPrefix === 'dev1.' ? 'dev' : 'prod'];
