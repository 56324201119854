import React from 'react';
import styled from 'styled-components';
import { Column, Title } from 'rbx';
import { Select, Input, TextArea } from '../../../components/elements';

const Container = styled.div`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 60rem;
    }
    .modal-card-head,
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }

    a {
      font-size: 1rem;
    }
  }
`;
const Wrapper = styled.div`
  padding-left: 1.5rem;
`;
const CommentModal = ({ isActive, onClose }) => (
  <Container className={`modal ${isActive && 'is-active'}`}>
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Comment Details</p>
        <button
          type="button"
          className="delete"
          aria-label="close"
          onClick={onClose}
        />
      </header>

      <section className="modal-card-body">
        <Wrapper>
          <hr />
          <Title>View Comments</Title>
          <Column.Group multiline gapSize={8}>
            <Column size={4}>
              <Input label="Comment User" disabled />
            </Column>
            <Column size={4}>
              <Select label="Status" disabled />
            </Column>
          </Column.Group>
          <Column.Group multiline vcentered gapSize={8}>
            <Column size={4}>
              <Input label="Comment User Email ID" disabled />
            </Column>
            <Column size={4}>
              <Input label="Comment User Date and Time" disabled />
            </Column>

            <Column size={11}>
              <TextArea label="Comment Content" disabled />
            </Column>
          </Column.Group>

          <legend className="label">Comment Update Details</legend>
          <Column.Group multiline vcentered gapSize={8}>
            <Column size={4}>
              <Input label="Comment Update User Login(Email)" disabled />
            </Column>
            <Column size={4}>
              <Input label="Comment Update Date and Time" disabled />
            </Column>
          </Column.Group>

          <br />
        </Wrapper>
      </section>
    </div>
  </Container>
);

export default CommentModal;
