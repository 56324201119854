import React from 'react';
import styled from 'styled-components';
import { Column, Button, Icon } from 'rbx';

import { Select } from '../../../components/elements';

const Wrapper = styled.div`
  padding-top: 1.5rem;
`;

const AddDeleteButton = ({ add, remove, showAddButton, hideDeleteButton }) => (
  <Wrapper>
    <Button.Group hasAddons>
      {!hideDeleteButton && (
        <Button onClick={remove}>
          <Icon size="small">
            <i className="fas fa-trash has-text-grey" />
          </Icon>
        </Button>
      )}
      {showAddButton && (
        <Button onClick={add}>
          <Icon size="small">
            <i className="fas fa-plus has-text-grey" />
          </Icon>
        </Button>
      )}
    </Button.Group>
  </Wrapper>
);

const Partner = () => (
  <Column.Group multiline vcentered gapSize={8}>
    <Column size={6}>
      <Select
        label="Partner Admin User"
        options={[]}
        value={{
          value: '',
          label: '',
        }}
        onChange={() => {}}
      />
    </Column>
    <Column size={6}>
      <Column.Group gapSize={1}>
        <Column size={9}>
          <Select
            label="Partner Use Role"
            options={[]}
            value={{
              value: '',
              label: '',
            }}
            onChange={() => {}}
          />
        </Column>
        <Column size={3}>
          <AddDeleteButton
            add={() => {}}
            remove={() => {}}
            showAddButton
            hideDeleteButton={false}
          />
        </Column>
      </Column.Group>
    </Column>
  </Column.Group>
);

export default Partner;
