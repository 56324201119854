import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { Query, Mutation } from 'react-apollo';
import { isNull, first } from 'lodash';
import gql from 'graphql-tag';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import qs from 'querystringify';
import { withRouter } from 'react-router-dom';
import Layout from '../../../components/global/Layout';
import { Loading } from '../../../components/elements';
import client from '../../../utils/apolloClient';
import EditAttendeeHeader from './EditAttendeeHeader';
import CheckInForm from './CheckInForm';
import CheckInActivity from './CheckInActivity';

const Container = styled.div`
  padding: 1rem;
`;

const ticketRecordQuery = gql`
  query searchEventTicketRecord($ticketId: String) {
    search_event_ticket_records(
      input: {
        filter: { event_ticket_record_filter: { ticket_id: $ticketId } }
      }
    ) {
      ticket_record_listing {
        booking_id
        ticket_record_id
        booking_number
        ticket_number
        ticket_type
        ticket_id
        sales_method
        check_in {
          type
          timestamp
        }
        attendee {
          attendee_name
          attendee_age
          attendee_email
          attendee_phone
          attendee_gender
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      first_name
      last_name
      email
    }
  }
`;

const updateTicketRecord = gql`
  mutation update_ticket_record($input: TicketRecordPerAttendeeInput) {
    update_ticket_record(input: $input) {
      place_id
      error {
        description
      }
    }
  }
`;

const Form = props => {
  const [user, setUser] = useState();
  const { history, record, handleSubmit, loading, isEditable } = props;

  useEffect(() => {
    if (record.audit.created_by) {
      client.clientPrivate
        .query({
          query: userQuery,
          variables: { userId: record.audit.created_by },
        })
        .then(({ data }) => {
          if (data) {
            setUser(data.fetch_user);
          }
        })
        .catch(() => {});
    }
  }, []);

  return (
    <Container>
      <Level>
        <Level.Item align="left">
          <Title size={2}>Edit Attendee Info</Title>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-primary">
              <span>Back</span>
            </a>
          </Level.Item>
          {isEditable && (
            <Level.Item>
              <a
                onClick={handleSubmit}
                className={`button is-primary ${loading && 'is-loading'}`}
              >
                <span>Update</span>
              </a>
            </Level.Item>
          )}
        </Level.Item>
      </Level>
      <EditAttendeeHeader ticket={record} />
      <br />
      {isEditable ? (
        <CheckInForm user={user} {...props} />
      ) : (
        <CheckInActivity ticket={record} />
      )}

      <Level>
        <Level.Item align="left">
          <a onClick={() => history.goBack()} className="button is-primary">
            <span>Back</span>
          </a>
        </Level.Item>
        {isEditable && (
          <Level.Item align="right">
            <a
              className={`button is-primary ${loading && 'is-loading'}`}
              onClick={handleSubmit}
            >
              <span>Update</span>
            </a>
          </Level.Item>
        )}
      </Level>
    </Container>
  );
};

const EditAttendeeInfoForm = withFormik({
  mapPropsToValues: ({ record }) => ({
    attendee_name: !isNull(record.attendee.attendee_name)
      ? record.attendee.attendee_name
      : '',
    attendee_phone: !isNull(record.attendee.attendee_phone)
      ? record.attendee.attendee_phone
      : '',
    attendee_email: !isNull(record.attendee.attendee_email)
      ? record.attendee.attendee_email
      : '',
    attendee_age: !isNull(record.attendee.attendee_age)
      ? record.attendee.attendee_age
      : '',
    attendee_gender: !isNull(record.attendee.attendee_gender)
      ? record.attendee.attendee_gender
      : '',
    notes: '',
  }),
  validationSchema: yup.object().shape({
    attendee_email: yup.string().email('PLease enter valid email!'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(false);
    props.onSubmit(values);
  },
  displayName: 'EditAttendeeInfoForm',
})(Form);

const EditAttendeeInfo = ({ match, history, location }) => {
  const { userId } = useStoreState(state => state.auth);
  const routeState = qs.parse(location.search);
  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={ticketRecordQuery}
        variables={{ ticketId: match.params.ticketId }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }

          const record = first(
            data.search_event_ticket_records.ticket_record_listing,
          );
          return (
            <>
              <Mutation
                client={client.clientPrivate}
                mutation={updateTicketRecord}
                onCompleted={() => {
                  swal(
                    'Great!',
                    'Attendee details updated successfully!!',
                    'success',
                  );
                }}
              >
                {(update_ticket_record, { loading: updateLoading }) => (
                  <EditAttendeeInfoForm
                    isEditable={routeState.type === 'details'}
                    loading={updateLoading}
                    record={record}
                    history={history}
                    onSubmit={input => {
                      console.log('input', input);
                      update_ticket_record({
                        variables: {
                          input: {
                            user_id: userId,
                            ticket_record_id: record.ticket_record_id,
                            attendee: {
                              attendee_name: input.attendee_name,
                              attendee_email: input.attendee_email,
                              attendee_age: parseInt(input.attendee_age, 10),
                              attendee_gender: input.attendee_gender,
                              attendee_phone: input.attendee_phone,
                            },
                          },
                        },
                      });
                    }}
                  />
                )}
              </Mutation>
            </>
          );
        }}
      </Query>
    </Layout>
  );
};

export default withRouter(EditAttendeeInfo);
