import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import moment from 'moment-timezone';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RightIcon = styled.span`
  &&& {
    pointer-events: initial;
    cursor: pointer;
  }
`;

const DateTimePickerCustomInput = ({
  value,
  onClick,
  placeholder,
  onClear,
  clear,
  disabled,
}) => (
  <div className="field" onClick={onClick}>
    <p className="control has-icons-left has-icons-right">
      <input
        className="input"
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
      <span className="icon is-small is-left">
        <i className="fas fa-calendar-alt" />
      </span>
      {clear === undefined && value.length !== 0 && (
        <RightIcon
          className="icon is-small is-right"
          onClick={onClear}
          style={{ zIndex: 1 }}
        >
          <i className="fas fa-times" />
        </RightIcon>
      )}
    </p>
  </div>
);

const ReactDateTimePicker = ({ label, value, onChange, clear, ...props }) => {
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (moment(value).toISOString() !== moment(startDate).toISOString()) {
      setStartDate(value === null ? null : new Date(value));
    }
  }, [value]);

  const handleChange = date => {
    setStartDate(date);
    onChange(moment(date).toISOString());
  };
  const onClear = () => {
    setStartDate(new Date());
    onChange(new Date());
  };

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <DatePicker
          selected={startDate}
          customInput={
            <DateTimePickerCustomInput
              placeholder="Select Date"
              onClear={onClear}
              clear={clear}
              disabled={props?.disabled}
            />
          }
          onChange={date => handleChange(date)}
          dateFormat="MMMM d, yyyy"
          showMonthDropdown
          showYearDropdown
          placeholderText="Select Date"
          {...props}
        />
      </div>
    </div>
  );
};

export default ReactDateTimePicker;
