import React from 'react';
import moment from 'moment';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SubHeading, Input } from '../../../components/elements';

const Wrapper = styled.div`
  .level:not(:last-child) {
    margin-bottom: 0;
    margin-top: 5rem;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 0;
  padding-bottom: 20px;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Header = ({ event, subHeading3, goToStepOne, goToStepTwo, step }) => (
  <React.Fragment>
    <Flex>
      <Wrapper>
        <Level.Item align="left">
          <Title size={2}>{event.name}(Public event)</Title>
        </Level.Item>
        <SubHeading className="is-size-3">
          {event.place_name} | {event.state}
        </SubHeading>
        <SubHeading className="is-size-3">
          {event.address_line_1} | {event.city} | {event.post_code} |{' '}
          {event.country}
        </SubHeading>
        {/* <SubHeading className="is-size-4">
          {moment(event.start).format('LLLL')}
        </SubHeading> */}
        <SubHeading className="is-size-4 has-text-weight-semibold">
          {subHeading3}
        </SubHeading>
      </Wrapper>
      <div>
        <ButtonWrapper>
          {step !== 2 && (
            <Level.Item>
              <Link to="/manage-events" className="button is-light">
                <span>Back to Search</span>
              </Link>
            </Level.Item>
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          {step === 2 && (
            <a
              onClick={() => (step === 2 ? goToStepOne() : goToStepTwo())}
              className="button is-primary"
            >
              <span>Cancel</span>
            </a>
          )}
        </ButtonWrapper>
        <Wrapper>
          <div className="is-flex">
            <Input
              label="Event Status"
              value={event.status}
              placeholder="Active"
              disabled
            />
          </div>
        </Wrapper>
      </div>
    </Flex>
  </React.Fragment>
);

export default Header;
