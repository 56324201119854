import React from 'react';
import { Column, Field, Label } from 'rbx';
import { replace } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  Input,
  TextArea,
  MobileInput,
  Select,
} from '../../../components/elements';

const CheckInForm = ({
  values,
  handleChange,
  handleBlur,
  record,
  setFieldValue,
  errors,
  touched,
  user,
}) => (
  <>
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={4}>
        <Input
          label="Tickets Sales Method"
          value={record.sales_method}
          placeholder="Ticket Agent"
          disabled
        />
      </Column>
      <Column size={4}>
        <Input
          label="Purchased By"
          value={record.attendee.attendee_name}
          placeholder="Tasty"
          disabled
        />
      </Column>
      <Column size={4}>
        <Input
          label="Booked By"
          value={user && `${user.first_name} ${user.last_name}`}
          placeholder="Super Admin"
          disabled
        />
      </Column>
      <Column size={4}>
        <Input label="Email Id" value={user && user.email} disabled />
      </Column>
      <Column size={4}>
        <Input
          label="Date and Time"
          placeholder="25-May-2019 05:48 PM"
          value={moment(record.audit.created_at).format('llll')}
          disabled
        />
      </Column>
      <Column size={4}>
        <Input label="Ticket Type" value={record.ticket_type} disabled />
      </Column>
      <Column size={4}>
        <Input
          label="Booking No"
          value={record.booking_number}
          placeholder="XE921540101"
          disabled
        />
      </Column>
      <Column size={4}>
        <Input
          label="Ticket No"
          value={record.ticket_number}
          placeholder="myiEH789"
          disabled
        />
      </Column>
    </Column.Group>

    <Column.Group vcentered multiline gapSize={8}>
      <Column size={6}>
        <Input
          label="Attendee Name"
          name="attendee_name"
          id="EventManagement_EventCheckinAttendees_AttendeeName"
          type="text"
          value={values.attendee_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Column>

      <Column size={6}>
        <MobileInput
          label="Attendee Mobile"
          type="number"
          id="EventManagement_EventCheckinAttendees_AttendeeMobile"
          name="attendee_phone"
          value={values.attendee_phone}
          onChange={value =>
            setFieldValue('attendee_phone', replace(value, ' ', ''))
          }
          onBlur={handleBlur}
        />
      </Column>

      <Column size={6}>
        <Input
          label="Attendee Email"
          type="email"
          id="EventManagement_EventCheckinAttendees_AttendeeEmail"
          name="attendee_email"
          value={values.attendee_email}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.attendee_email}
          touched={touched.attendee_email}
        />
      </Column>

      <Column size={3}>
        <Input
          label="Age"
          type="number"
          id="EventManagement_EventCheckinAttendees_Age"
          name="attendee_age"
          value={values.attendee_age}
          onChange={event => {
            const value = parseFloat(event.target.value).toFixed(0);

            if (
              (value <= 120 && value > 0 && event.target.value !== 'e') ||
              event.target.value === ''
            ) {
              setFieldValue('attendee_age', value);
            }
          }}
        />
      </Column>

      <Column size={3}>
        <Field>
          <Label className="is-flex">Gender</Label>
          <Select
            value={{
              label: values.attendee_gender,
              value: values.attendee_gender,
            }}
            options={[
              { value: 'MALE ', label: 'MALE' },
              { value: 'FEMALE', label: 'FEMALE' },
              { value: 'OTHER', label: 'OTHER' },
            ]}
            onChange={value => setFieldValue('attendee_gender', value.value)}
          />
        </Field>
      </Column>

      <Column size={8} rows={4}>
        <TextArea
          label="Attendee Notes"
          name="notes"
          id="EventManagement_EventCheckinAttendees_AttendeeNotes"
          value={values.notes}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Column>
      <Column size={4}>
        <Link
          to={`/edit-attendee-info/${record.ticket_id}?type=activity`}
          className="button is-primary"
        >
          CheckIn Activity
        </Link>
      </Column>
    </Column.Group>
  </>
);

export default CheckInForm;
