import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { first, isNull, isUndefined } from 'lodash';
import moment from 'moment';
import { FieldArray } from 'formik';
import uuid from 'uuid';
// import Input from './Input';
import CreatableSelectInputs from './CreatableSelectInputs';
import { upload_image_s3 } from '../../utils/s3';

const DropZoneWrapper = styled.div`
  .box {
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  padding: 1rem;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: ${({ defaultButtonDisabled }) =>
    defaultButtonDisabled ? '#e2e2e2' : '#ffffff'};
`;

const Figure = styled.figure`
  opacity: ${opacity => opacity};
`;

const LoaderWrapper = styled.div`
  &&& {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    bottom: 48%;
  }
`;

const placeTagValues = tagsValue => {
  const tags = [];
  if (!isUndefined(tagsValue)) {
    tagsValue.map(item => {
      tags.push({ value: item, label: item });
      return null;
    });
  }
  return tags;
};

const placeTagInput = tagsValue => {
  const tags = [];
  tagsValue.map(item => {
    tags.push(item.value);
    return null;
  });
  return tags;
};

const MyDropzone = ({
  item,
  s3UploadPath,
  onChange,
  metaData,
  handleSelectLocalFile,
  children,
  index,
  // handleChange,
  // handleBlur,
  setFieldValue,
  handleRemoveImage,
  setDefaultImage,
  defaultButtonDisabled,
  defaultImageLoading,
}) => {
  const onDrop = useCallback(async acceptedFiles => {
    const file = acceptedFiles[0];
    handleSelectLocalFile(URL.createObjectURL(file));
    const imageName = file.name.split('.');
    const name = `${first(imageName)}-${moment().format(
      'YYYYMMDD_hhmmss',
    )}.${file.type.substring(6)}`;

    const { Location: url } = await upload_image_s3(
      file,
      `${s3UploadPath}${name}`,
      metaData,
    );
    const response = { path: name, url };
    onChange(response);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropZoneWrapper>
      {item.preview !== null && (
        <ImageContainer defaultButtonDisabled={defaultButtonDisabled}>
          <Figure
            className="image is-16by9"
            opacity={item.uploadingStatus === 'uploading' ? 0.5 : 1}
          >
            <img src={item.preview} alt="image-upload" />
          </Figure>
          {item.uploadingStatus === 'uploading' && (
            <LoaderWrapper>
              <progress className="progress is-small is-success" max="100">
                15%
              </progress>
              <p className="is-size-2 has-text-weight-bold">Uploading....</p>
            </LoaderWrapper>
          )}
          <br />
          {item.uploadingStatus === 'uploaded' && (
            <React.Fragment>
              {/* <Input
                className="is-small"
                label="Link"
                name={`uploadedImages.${index}.link`}
                value={item.link}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                className="is-small"
                label="Tooltip"
                name={`uploadedImages.${index}.tooltip`}
                value={item.tooltip}
                onChange={handleChange}
                onBlur={handleBlur}
              /> */}
              <CreatableSelectInputs
                components={{
                  DropdownIndicator: null,
                }}
                label="Tags"
                suggestions={[]}
                value={!isNull(item.tags) ? placeTagValues(item.tags) : []}
                onChange={value => {
                  if (value) {
                    setFieldValue(
                      `uploadedImages.${index}.tags`,
                      placeTagInput(value),
                    );
                  }
                }}
                isClearable={false}
              />
              <div className="is-flex">
                <a
                  className={`button is-light is-fullwidth ${defaultImageLoading &&
                    'is-loading'}`}
                  onClick={setDefaultImage}
                  disabled={defaultButtonDisabled}
                >
                  Set Default
                </a>
                &nbsp;&nbsp;&nbsp;
                <a
                  className="button is-danger is-fullwidth"
                  onClick={!defaultButtonDisabled && handleRemoveImage}
                  disabled={defaultButtonDisabled}
                >
                  Delete
                </a>
              </div>
            </React.Fragment>
          )}
        </ImageContainer>
      )}

      {item.uploadingStatus === 'pending' && (
        <div className={children ? '' : 'box'} {...getRootProps()}>
          <input
            {...getInputProps()}
            accept="image/x-png,image/gif,image/jpeg,image/jpg"
          />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <React.Fragment>
              {children || (
                <p>
                  Drag &apos;n&apos; drop some files here, or click to select
                  files
                </p>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </DropZoneWrapper>
  );
};

const UploadImage = ({
  s3UploadPath,
  metaData,
  setFieldValue,
  handleRemoveUploadedImage,
  setDefaultImage,
  defaultImage,
  defaultImageLoading,
  whichImageSettingDefault,
}) => (
  <FieldArray
    name="image"
    render={arrayHelpers => (
      <React.Fragment>
        {arrayHelpers.form.values &&
          !isUndefined(arrayHelpers.form.values.uploadedImages) &&
          !isNull(arrayHelpers.form.values.uploadedImages) &&
          arrayHelpers.form.values.uploadedImages.map((item, index) => (
            <div className="column is-4">
              <MyDropzone
                item={item}
                s3UploadPath={s3UploadPath}
                metaData={metaData}
                index={index}
                handleChange={arrayHelpers.form.handleChange}
                handleBlur={arrayHelpers.form.handleBlur}
                setFieldValue={setFieldValue}
                handleSelectLocalFile={preview => {
                  setFieldValue(`uploadedImages.${index}.preview`, preview);
                  setFieldValue(
                    `uploadedImages.${index}.uploadingStatus`,
                    'uploading',
                  );

                  arrayHelpers.push({
                    id: uuid(),
                    url: '',
                    preview: null,
                    tooltip: '',
                    link: '',
                    tag: [],
                    uploadingStatus: 'pending',
                  });
                }}
                onChange={value => {
                  setFieldValue(`uploadedImages.${index}.url`, value.url);
                  setFieldValue(
                    `uploadedImages.${index}.uploadingStatus`,
                    'uploaded',
                  );
                }}
                handleRemoveImage={() => handleRemoveUploadedImage(item.id)}
                setDefaultImage={() => setDefaultImage(item)}
                defaultButtonDisabled={defaultImage === item.url}
                defaultImageLoading={
                  whichImageSettingDefault === item.url
                    ? defaultImageLoading
                    : false
                }
              />
            </div>
          ))}
      </React.Fragment>
    )}
  />
);

MyDropzone.defaultProps = {
  s3UploadPath: 'dummy/',
  defaultImage: '',
  metaData: {},
  onChange: () => {},
  handleRemoveUploadedImage: () => {},
  setDefaultImage: () => {},
};

MyDropzone.propTypes = {
  s3UploadPath: PropTypes.string,
  metaData: PropTypes.object,
  defaultImage: PropTypes.string,
  onChange: PropTypes.func,
  handleRemoveUploadedImage: PropTypes.func,
  setDefaultImage: PropTypes.func,
};

export default UploadImage;
