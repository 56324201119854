import React from 'react';
import { Link } from 'react-router-dom';

const AttendeeList = ({ ticket, goToStepTwo }) => (
  <React.Fragment>
    <td>{ticket.attendee.attendee_name || '-'}</td>
    <td>{ticket.attendee.attendee_email || '-'}</td>
    <td>{ticket.attendee.attendee_phone || '-'}</td>
    <td>{ticket.attendee.attendee_age || '-'}</td>
    <td>{ticket.ticket_name}</td>
    <td>{`${ticket.booking_number}-${ticket.ticket_number}`}</td>
    <td>{ticket.ticket_status}</td>
    <td>
      <Link
        to={`/ticket/booking-detail/edit-attendee-info/${ticket.ticket_id}`}
      >
        <span className="icon">
          <i className="fas fa-pencil-alt" />
        </span>
      </Link>
    </td>
  </React.Fragment>
);
export default AttendeeList;
