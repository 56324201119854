import React from 'react';
import styled from 'styled-components';

const Counter = styled.div`
  display: inline-flex;
  position: relative;
  left: 20px;
  bottom: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  padding: 0;
  background-color: white;
  border: 1px solid #00aeef;
  color: #00aeef;
  width: 160px;
  height: 35px;
  font-size: 12px;
  font-weight: bold;

  .counter-btn {
    cursor: pointer;
    border-radius: 30px;
    border: none;
    background-color: #00aeef;
    height: 30px;
    width: 30px;
    color: white;
    padding: 0;
    font-size: 20px;
  }

  .counter-value {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    color: black;
    font-size: 12px;
  }

  .plus {
    margin-right: 2px;
  }

  .minus {
    margin-left: 2px;
  }
`;

const AutoCancelCounter = ({ values, setFieldValue }) => (
  <Counter>
    <button
      type="button"
      className="counter-btn minus"
      onClick={() => {
        if (values.time_in_mins > 3) {
          setFieldValue('time_in_mins', parseInt(values.time_in_mins, 10) - 1);
        }
      }}
    >
      <i className="fas fa-minus" />
    </button>
    <div className="counter-value">{values.time_in_mins} MINS</div>
    <button
      type="button"
      className="counter-btn plus"
      onClick={() => {
        if (values.time_in_mins < 20) {
          setFieldValue('time_in_mins', parseInt(values.time_in_mins, 10) + 1);
        }
      }}
    >
      <i className="fas fa-plus" />
    </button>
  </Counter>
);

export default AutoCancelCounter;
