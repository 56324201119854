import React from 'react';

import { Select, Input } from '../../../../components/elements';

const options = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'INACTIVE', value: 'INACTIVE' },
];

const ImageItem = ({
  imageSrc,
  imageName,
  status,
  handleApprovalStatus,
  approvalStatus,
}) => (
  <div>
    <Select
      label="Status"
      options={options}
      value={{ label: status, value: status }}
      required
      disabled
    />
    <figure className="image is-square">
      <img src={imageSrc} alt={imageName} />
    </figure>
    <br />
    <Input label="Approval Status" value={approvalStatus} required disabled />
    {approvalStatus === 'APPROVED' && (
      <a
        onClick={() => handleApprovalStatus('REJECTED')}
        className="button is-danger  is-fullwidth"
      >
        REJECT
      </a>
    )}
    {approvalStatus === 'SUBMITTED' && (
      <a
        onClick={() => handleApprovalStatus('REJECTED')}
        className="button is-danger  is-fullwidth"
      >
        REJECT
      </a>
    )}
    {approvalStatus === 'REJECTED' && (
      <a
        onClick={() => handleApprovalStatus('APPROVED')}
        className="button is-primary  is-fullwidth"
      >
        APPROVE
      </a>
    )}
  </div>
);

export default ImageItem;
