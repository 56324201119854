import React from 'react';
import { useStoreState } from 'easy-peasy';
import qs from 'querystringify';
import { omit } from 'lodash';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import PlaceSearch from './Search';
import OrderDetailsTable from './OrderDetailsTable';
import { fetchUserOrdersDetails, transformUserOrderDetails } from './helpers';

const currentDate = new Date();

const OrderRefundsDashboard = ({ history, location }) => {
  const { userId } = useStoreState(state => state.auth);
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const routeState = qs.parse(location.search);
  const {
    placename,
    username,
    useremail,
    userphone,
    orderRef,
    service,
    startDate,
    endDate,
  } = routeState;

  const serviceTypes = {
    All: { label: 'ALL', value: 'All' },
    Pickup: { label: 'PICKUP', value: 'Pickup' },
    Delivery: { label: 'DELIVERY', value: 'Delivery' },
    Dinein: { label: 'DINEIN', value: 'Dinein' },
  };

  const search = {
    placename: placename ?? '',
    username: username ?? '',
    useremail: useremail ?? '',
    userphone: userphone ?? '',
    orderRef: orderRef ?? '',
    service: serviceTypes[service] ?? serviceTypes.All,
    startDate: startDate ?? currentDate,
    endDate: endDate ?? currentDate,
  };

  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const canLoadMoreData = currentPage + 1 < totalPages;

  const loadMoreDataFunc = () => {
    setCurrentPage(currentPage + 1);
  };

  React.useEffect(() => {
    let ignore = false;
    const fetchFunc = async () => {
      setLoading(true);
      const response = await fetchUserOrdersDetails({
        userId,
        from: currentPage * 50,
        placename: search.placename,
        orderRef: search.orderRef,
        service: search.service?.value,
        startDate: search.startDate,
        endDate: search.endDate,
        username: search.username,
        useremail: search.useremail,
        userphone: search.userphone,
      });
      if (!ignore && response && currentPage === 0) {
        setOrders(transformUserOrderDetails(response.data));
        setTotalPages(response.totalPages);
        setCount(response.totalSize);
      } else if (!ignore && response) {
        setOrders([...orders, ...transformUserOrderDetails(response.data)]);
      }
      setLoading(false);
    };
    fetchFunc();
    return () => {
      ignore = true;
    };
  }, [
    search.placename,
    search.orderRef,
    search.service?.value,
    search.startDate,
    search.endDate,
    search.username,
    search.useremail,
    search.userphone,
    currentPage,
  ]);

  const handlePush = (args, key) => {
    setTotalPages(0);
    setCurrentPage(0);
    const newRoutState = omit(routeState, key);
    history.push(
      `/order-refunds-dashboard${qs.stringify(
        {
          ...newRoutState,
          ...args,
        },
        true,
      )}`,
    );
  };

  return (
    <Layout>
      <div style={{ position: 'relative' }}>
        <Header />
        <PlaceSearch
          clearPagination={() => {
            setCurrentPage(0);
            setTotalPages(0);
          }}
          handlePush={handlePush}
          search={search}
        />
        {count === 0 ? (
          ''
        ) : (
          <div
            style={{ textAlign: 'right', padding: '1rem' }}
          >{`Total Orders : ${count}`}</div>
        )}
        <OrderDetailsTable
          loading={loading}
          userOrderDetailsData={orders}
          canLoadMoreData={canLoadMoreData}
          loadMoreData={loadMoreDataFunc}
          history={history}
        />
      </div>
    </Layout>
  );
};

export default OrderRefundsDashboard;
