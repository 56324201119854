import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { first, replace } from 'lodash';
import moment from 'moment';

const EventList = ({ event }) => (
  <React.Fragment>
    <td>
      <Link to={`/sell-tickets/${event.event_id}`}>{event.name}</Link>
    </td>
    <td>{`${event.place_name}`}</td>
    <td>{`${event.city}`}</td>
    <td className="is-capitalized">
      {replace(first(event.listing_type), '_', ' ')}
    </td>

    <td>{moment(event.start).format('lll')}</td>
  </React.Fragment>
);

export default EventList;
