/* eslint-disable import/prefer-default-export */
import uuidv4 from 'uuid/v4';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import {
  fetchAllCategoriesOfThePlace,
  addCategory,
  menuSectionDetails,
  createNewMenuSection,
} from '../helpers';

export const useCategoriesFetchFn = ({
  userId,
  placeId,
  setCategories,
  setLoading,
  currentMenuCategories,
}) => {
  const transformResponse = categories => {
    if (currentMenuCategories.length === 0) {
      return categories;
    }
    const menuSectionIds = new Set();
    currentMenuCategories.forEach(category => {
      menuSectionIds.add(category.menu_section_id);
    });
    return categories.filter(
      category => !menuSectionIds.has(category.menu_section_id),
    );
  };
  const fetchFn = async ({ query = '' }) => {
    let response;
    setLoading(true);
    const hasQuery = query !== '';
    try {
      response = await fetchAllCategoriesOfThePlace({
        user_id: userId,
        place_id: placeId,
        query: query ?? '',
      });
      response = transformResponse(response);
    } catch {
      response = [];
    }
    setCategories(prev =>
      hasQuery && response.length === 0 ? prev : response,
    );
    setLoading(false);
  };
  return fetchFn;
};

export const useAddButtonClickHandler = ({
  setAddCategoryModalIsActive,
  userId,
  menuId,
  fetchCategories,
  setAddCategoryFormActive,
  setLoading,
}) => {
  const clickHandler = async category => {
    setLoading(true);
    let menuIds;
    const categoryMenuIds = await menuSectionDetails({
      menu_section_id: category.menu_section_id,
    });
    if (
      categoryMenuIds &&
      categoryMenuIds.length !== 0 &&
      categoryMenuIds.filter(ele => ele === menuId).length !== 0
    ) {
      return;
    }
    if (categoryMenuIds && categoryMenuIds.length !== 0) {
      menuIds = [...categoryMenuIds];
      menuIds.push(menuId);
    } else {
      menuIds = [menuId];
    }
    try {
      await addCategory({
        user_id: userId,
        menu_id: menuIds,
        menu_section_id: category.menu_section_id,
      });
      setLoading(false);
      swal({
        text: 'Category is added successfully to the Menu',
      }).then(() => {
        setAddCategoryModalIsActive(false);
        setAddCategoryFormActive(false);
        fetchCategories();
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return clickHandler;
};

export const useSubmitHandler = ({
  userId,
  placeId,
  menuId,
  setAddCategoryModalIsActive,
  fetchCategories,
  setAddCategoryFormActive,
}) => {
  const submitHandler = async name => {
    if (name.length > 35) {
      toast.error('Category Name cannot be greater than 35 characters');
      return;
    }
    try {
      const { data } = await createNewMenuSection({
        input: {
          user_id: userId,
          place_id: placeId,
          menu_id: [menuId],
          menu_section_id: uuidv4(),
          name,
          status: 'ACTIVE',
        },
      });
      if (
        Array.isArray(data?.create_menu_section?.error) &&
        data.create_menu_section.error[0]
      ) {
        if (
          data.create_menu_section.error[0].description?.includes(
            'already exists for the place',
          )
        ) {
          swal(
            `Cannot add this Category as ${name} already exists for the place`,
          );
        }
      } else {
        swal({
          text: 'New Category is added successfully to the Menu',
        }).then(() => {
          setAddCategoryModalIsActive(false);
          setAddCategoryFormActive(false);
          fetchCategories();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return submitHandler;
};
