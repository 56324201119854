import React from 'react';
import moment from 'moment';
import { first } from 'lodash';
import ReactTooltip from 'react-tooltip';

const FlagUserList = ({ flag, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === flag.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <tr>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user.full_name}>{user.full_name}</p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user && user.email}</p>
                <p>objectId: {user && user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          flag.audit.updated_by
        )}
      </td>
      <td>{user && user.email}</td>
      <td>{moment(flag.audit.created_at).format('lll')}</td>
      <td>{flag.flag_content}</td>
    </tr>
  );
};
export default FlagUserList;
