import React, { useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';

import client from '../../../utils/apolloClient';
import TimeCounter from './TimeCounter';

const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 1.2rem;
  cursor: pointer;
`;

const updateMenuOrderMutation = gql`
  mutation updateMenuOrder($input: MenuOrderInput) {
    update_menu_order(input: $input) {
      menu_order_id
      error {
        description
      }
    }
  }
`;

const ModalContainer = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: ${({ height }) => height};
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      bottom: 0;
      width: 35rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: center;
      background: white;
      border-top: 0px;
      .button {
        font-weight: 600;
      }
    }
  }
`;

const NonDeliveryOrderTimeEstimates = ({
  isActive,
  setTimeEstimateModal,
  menuOrderId,
  estimates,
}) => {
  const [updateMenuOrder, { loading }] = useMutation(updateMenuOrderMutation, {
    client: client.clientPublic,
    onCompleted: () => {
      setTimeEstimateModal(false);
    },
  });

  const preparationEstimateFromBackend = (
    estimates.find(ele => ele.type === 'preparation_estimate') ?? {}
  ).value_in_mins;

  const [preparationEstimate, setPreparationEstimate] = useState(
    preparationEstimateFromBackend ?? 15,
  );

  return (
    <ModalContainer
      height="40vh"
      className={`modal ${isActive && 'is-active'}`}
    >
      <div className="modal-background" />
      <div className="modal-card">
        <section className="modal-card-body" style={{ height: '40vh' }}>
          <CloseButtonWrapper>
            <FaAngleLeft
              size={30}
              type="button"
              onClick={() => {
                setTimeEstimateModal(false);
              }}
            />
          </CloseButtonWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{ paddingTop: '20px' }}>
              <img src="/images/clock-icon.png" alt="clock-icon" />
            </div>

            <p className="is-size-2 has-text-weight-semibold mr-4">
              How long will this take to prepare?
            </p>
            <div style={{ paddingTop: '20px' }}>
              <TimeCounter
                value={preparationEstimate}
                onChange={value => setPreparationEstimate(value)}
              />
            </div>
            <p style={{ color: '#737373' }}>
              Increase is only active for 30 minutes
            </p>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-large is-fullwidth is-primary ${loading &&
              'is-loading'}`}
            onClick={() => {
              if (preparationEstimateFromBackend !== preparationEstimate) {
                updateMenuOrder({
                  variables: {
                    input: {
                      menu_order_id: menuOrderId,
                      estimates: [
                        {
                          type: 'preparation_estimate',
                          value_in_mins: preparationEstimate,
                        },
                      ],
                      is_role: 'PARTNER',
                    },
                  },
                });
              } else {
                setTimeEstimateModal(false);
              }
            }}
          >
            DONE
          </button>
        </footer>
      </div>
    </ModalContainer>
  );
};

const DeliveryOrderTimeEstimates = ({
  isActive,
  setTimeEstimateModal,
  menuOrderId,
  estimates,
}) => {
  const [updateMenuOrder, { loading }] = useMutation(updateMenuOrderMutation, {
    client: client.clientPublic,
    onCompleted: () => {
      setTimeEstimateModal(false);
    },
  });

  const preparationEstimateFromBackend = (
    estimates.find(ele => ele.type === 'preparation_estimate') ?? {}
  ).value_in_mins;

  const deliveryEstimateFromBackend = (
    estimates.find(ele => ele.type === 'delivery_estimate') ?? {}
  ).value_in_mins;

  const [preparationEstimate, setPreparationEstimate] = useState(
    preparationEstimateFromBackend ?? 15,
  );

  const [deliveryEstimate, setDeliveryEstimate] = useState(
    deliveryEstimateFromBackend ?? 15,
  );

  return (
    <div>
      <ModalContainer
        height="64vh"
        className={`modal ${isActive && 'is-active'}`}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <section className="modal-card-body" style={{ height: '64vh' }}>
            <CloseButtonWrapper>
              <FaAngleLeft
                size={30}
                type="button"
                onClick={() => {
                  setTimeEstimateModal(false);
                }}
              />
            </CloseButtonWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ paddingTop: '20px' }}>
                <img src="/images/delivery-icon.png" alt="delivery-icon" />
              </div>

              <p className="is-size-2 has-text-weight-semibold mr-4">
                How long will this take to Deliver?
              </p>
              <div style={{ paddingTop: '20px' }}>
                <TimeCounter
                  value={deliveryEstimate}
                  onChange={value => setDeliveryEstimate(value)}
                />
              </div>
              <img src="/images/clock-icon.png" alt="clock-icon" />

              <p className="is-size-2 has-text-weight-semibold mr-4">
                How long will this take to Prepare?
              </p>
              <div style={{ paddingTop: '20px' }}>
                <TimeCounter
                  value={preparationEstimate}
                  onChange={value => setPreparationEstimate(value)}
                />
              </div>
              <p style={{ color: '#737373' }}>
                Increase is only active for 30 minutes
              </p>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              className={`button is-large is-fullwidth is-primary ${loading &&
                'is-loading'}`}
              onClick={() => {
                updateMenuOrder({
                  variables: {
                    input: {
                      menu_order_id: menuOrderId,
                      estimates: [
                        {
                          type: 'preparation_estimate',
                          value_in_mins: preparationEstimate,
                        },
                        {
                          type: 'delivery_estimate',
                          value_in_mins: deliveryEstimate,
                        },
                      ],
                      is_role: 'PARTNER',
                    },
                  },
                });
              }}
            >
              DONE
            </button>
          </footer>
        </div>
      </ModalContainer>
    </div>
  );
};

export { NonDeliveryOrderTimeEstimates, DeliveryOrderTimeEstimates };
