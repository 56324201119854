import React from 'react';
import moment from 'moment';
import { first } from 'lodash';

const CommentUserList = ({ comment, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === comment.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <tr>
      <td>
        {user ? (
          <React.Fragment>
            <p>{user.display_name} </p>
          </React.Fragment>
        ) : (
          comment.audit.updated_by
        )}
      </td>
      <td>{user && user.email}</td>
      <td>{moment(comment.audit.updated_at).format('lll')}</td>
      <td>{comment.content}</td>
      <td>{comment.status}</td>
    </tr>
  );
};
export default CommentUserList;
