import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { useQuery } from '@apollo/react-hooks';
import uuidv4 from 'uuid/v4';
import gql from 'graphql-tag';
import client from '../../../../utils/apolloClient';
import { Loading } from '../../../../components/elements';

const MenuCheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 400;
`;

const Container = styled.div`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 10px;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const SecondaryHeader = styled.div`
  background: white;
`;

const onChangeHandler = (
  deliveryIntegration,
  menuId,
  menuName,
  setFieldValue,
  setMenuNames,
) => value => {
  setFieldValue('random_id', uuidv4());
  if (value) {
    const temp = deliveryIntegration.menu_id;
    const menuIdAlreadyPresent = temp.find(ele => ele === menuId);
    if (!menuIdAlreadyPresent) {
      temp.push(menuId);
      setMenuNames(prev => {
        const ms = [...prev];
        ms.push(menuName);
        return ms;
      });
    }
    setFieldValue('delivery_integration', {
      ...deliveryIntegration,
      menu_id: temp,
    });
  } else {
    const temp = deliveryIntegration.menu_id.filter(ele => ele !== menuId);
    setMenuNames(prev => prev.filter(ele => ele !== menuName));
    setFieldValue('delivery_integration', {
      ...deliveryIntegration,
      menu_id: temp,
    });
  }
};

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        menu_id
        name
      }
    }
  }
`;

const MenuCheckbox = ({ menuName, checked, onChange }) => (
  <MenuCheckBoxContainer>
    <span>{menuName}</span>
    <input
      type="checkbox"
      onChange={() => onChange(!checked)}
      checked={checked}
    />
  </MenuCheckBoxContainer>
);

const MenuModal = ({
  isActive,
  onClose,
  deliveryIntegration,
  setFieldValue,
  serviceSettingId,
  setMenuNames,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const { data, loading } = useQuery(searchMenusQuery, {
    variables: {
      input: {
        filter: {
          menu_filter: {
            service_type_setting_id: serviceSettingId,
          },
        },
        user_id: userId,
      },
    },
    client: client.clientPrivate,
    onCompleted: res => {
      const menusArray =
        res?.search_menus && Array.isArray(res.search_menus.menu_listing)
          ? res.search_menus.menu_listing
          : [];
      const menuIds = deliveryIntegration.menu_id;
      const ns = [];
      menuIds.forEach(ele => {
        const menuObj = menusArray.find(ele2 => ele2.menu_id === ele);
        if (menuObj) {
          ns.push(menuObj.name);
        }
      });
      setMenuNames(ns);
    },
  });

  const menusArray =
    data?.search_menus && Array.isArray(data.search_menus.menu_listing)
      ? data.search_menus.menu_listing
      : [];

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Menu(s)</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <SecondaryHeader />

        <section className="modal-card-body">
          <div style={{ width: '60%' }}>
            {loading ? (
              <Loading />
            ) : (
              <>
                {menusArray.map(menu => (
                  <MenuCheckbox
                    key={menu.menu_id}
                    menuName={menu.name}
                    checked={
                      deliveryIntegration.menu_id.filter(
                        ele => ele === menu.menu_id,
                      ).length !== 0
                    }
                    onChange={onChangeHandler(
                      deliveryIntegration,
                      menu.menu_id,
                      menu.name,
                      setFieldValue,
                      setMenuNames,
                    )}
                  />
                ))}
              </>
            )}
          </div>
        </section>

        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-grey-light"
            aria-label="close"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="button is-primary"
            aria-label="close"
            onClick={onClose}
            // disabled={selectedItems.length === 0}
          >
            <p className="has-text-weight-semibold">Add</p>
          </button>
        </footer>
      </div>
    </Container>
  );
};

export default MenuModal;
