/* eslint-disable arrow-body-style */
import React from 'react';
import { Column } from 'rbx';
import Pencil from './Pencil';
import CloseSvg from './CloseSvg';
import { editDispatchEnums } from '../helpers';

const FlexContainer = ({ children, backgroundColor = null }) => (
  <Column
    size={2}
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor,
      wordWrap: 'break-word',
    }}
  >
    {children}
  </Column>
);

const SpanContainer = ({
  color = '#0D0D0D',
  children,
  cursor = null,
  paddingLeft = null,
  onClick = null,
  paddingRight = null,
}) => (
  <span
    style={{
      color,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      cursor,
      paddingLeft,
      paddingRight,
    }}
    onClick={onClick}
  >
    {children}
  </span>
);

const AddMenuItemsBtn = ({ setAddItemModalIsActive, onClick }) => {
  return (
    <SpanContainer
      onClick={() => {
        onClick();
        setAddItemModalIsActive(true);
      }}
      color="#00AEEF"
      cursor="pointer"
    >
      + Add Menu Items
    </SpanContainer>
  );
};

const AddCategoryBtn = ({ setAddCategoryModalIsActive, onClick }) => (
  <SpanContainer
    color="#00AEEF"
    cursor="pointer"
    paddingLeft="10px"
    onClick={() => {
      onClick();
      setAddCategoryModalIsActive(true);
    }}
  >
    + Add Category
  </SpanContainer>
);

const MenuDesignerHeader = ({
  setAddCategoryModalIsActive,
  setAddItemModalIsActive,
  edits,
  editsDispatch,
}) => {
  return (
    <Column.Group
      style={{
        backgroundColor: '#E0E0E0',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        marginBottom: '0',
      }}
      multiline
      gapSize={2}
    >
      <FlexContainer>
        <SpanContainer paddingRight="8px">Categories&nbsp;</SpanContainer>
        {!edits.editCategories && (
          <Pencil
            onClick={() =>
              editsDispatch({ type: editDispatchEnums.START_EDIT_CATEGORIES })
            }
            width="14"
            height="14"
          />
        )}
        {edits.editCategories && (
          <CloseSvg
            onClick={() =>
              editsDispatch({ type: editDispatchEnums.STOP_EDIT_CATEGORIES })
            }
            width="14"
            height="14"
          />
        )}
      </FlexContainer>

      <FlexContainer>
        <SpanContainer paddingRight="8px">Items&nbsp;</SpanContainer>
        {!edits.editMenuItems && (
          <Pencil
            onClick={() =>
              editsDispatch({ type: editDispatchEnums.START_EDIT_MENU_ITEMS })
            }
            width="14"
            height="14"
          />
        )}
        {edits.editMenuItems && (
          <CloseSvg
            width="14"
            height="14"
            onClick={() =>
              editsDispatch({ type: editDispatchEnums.STOP_EDIT_MENU_ITEMS })
            }
          />
        )}
      </FlexContainer>
      <Column size={2}>
        <AddCategoryBtn
          setAddCategoryModalIsActive={setAddCategoryModalIsActive}
          onClick={() => {
            editsDispatch({ type: editDispatchEnums.STOP_EDIT_CATEGORIES });
            editsDispatch({ type: editDispatchEnums.STOP_EDIT_MENU_ITEMS });
          }}
        />
      </Column>

      <Column size={2}>
        <AddMenuItemsBtn
          setAddItemModalIsActive={setAddItemModalIsActive}
          onClick={() => {
            editsDispatch({ type: editDispatchEnums.STOP_EDIT_CATEGORIES });
            editsDispatch({ type: editDispatchEnums.STOP_EDIT_MENU_ITEMS });
          }}
        />
      </Column>
    </Column.Group>
  );
};

export default MenuDesignerHeader;
