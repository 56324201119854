import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import moment from 'moment';
import qs from 'querystringify';
import Layout from '../../../components/global/Layout';
import Header from './ListingComponents/Header';
import Search from './ListingComponents/Search';
import ListItem from './ListingComponents/ListItem';
import TableHead from './ListingComponents/TableHead';
import { Loading, Pagination, Table, Box } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const searchItemImageApprovalsQuery = gql`
  query searchItemImageApprovals($input: SearchInput) {
    search_item_photo_approvals(input: $input) {
      total_pages
      total_size
      photo_approval_listing {
        group_id
        item_name
        place_name
        place_city
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const listSize = 10;

const ItemImageApprovals = props => {
  const routeState = qs.parse(props.location.search);
  const { status, placeName, itemName, reviewOn, page } = routeState;

  const placeNameFilter = placeName || '';
  const itemNameFilter = itemName || '';
  const statusFilter = status || 'SUBMITTED';
  const reviewOnFilter = reviewOn || null;
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;

  const [sort, setSort] = useState('CREATED_DESC');

  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();

  const input = { sort, from: currentPage * listSize, size: listSize };
  const photo_approval_filter = {};
  Object.assign(
    photo_approval_filter,
    itemNameFilter && { item_name: itemNameFilter },
    placeNameFilter && { place_name: placeNameFilter },
    { approval_status: statusFilter },

    reviewOnFilter && {
      date_range: {
        start_date: moment(reviewOnFilter).format('YYYY-MM-DD'),
        end_date: moment(reviewOnFilter)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      },
    },
  );

  Object.assign(
    input,

    {
      filter: {
        photo_approval_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={statusFilter}
          reviewOn={reviewOnFilter}
          placeName={placeNameFilter}
          itemName={itemNameFilter}
          routeState={routeState}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value =>
              props.history.push(
                `/item-image-approvals${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              )
            }
          />
        )}

        <Table>
          <TableHead sort={sort} setSort={setSort} />
          <Query
            client={client.clientPrivate}
            query={searchItemImageApprovalsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_item_photo_approvals } = data;
              if (isNull(search_item_photo_approvals)) {
                return 'no data found';
              }
              if (search_item_photo_approvals.length === 0) {
                return 'no data found';
              }

              return (
                <React.Fragment>
                  <View
                    search_item_photo_approvals={search_item_photo_approvals}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default ItemImageApprovals;

const View = ({
  search_item_photo_approvals,
  setPageCount,
  setTotalElements,
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setPageCount(search_item_photo_approvals.total_pages);
    setTotalElements(search_item_photo_approvals.total_size);
    if (search_item_photo_approvals.photo_approval_listing) {
      const userFilter = search_item_photo_approvals.photo_approval_listing.map(
        item => item.audit.created_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_item_photo_approvals.total_pages]);

  const imageReviews = search_item_photo_approvals.photo_approval_listing || [];

  return (
    <tbody>
      {imageReviews.map(review => (
        <ListItem review={review} users={users} />
      ))}
    </tbody>
  );
};
