import React from 'react';
import { Link } from 'react-router-dom';

const CheckInList = ({ review }) => (
  <tr>
    <td>
      <Link to={`/item-activity/${review.place_id}/${review.item_id}`}>
        {review.name}
      </Link>
    </td>
    <td>{review.place_name}</td>
    <td>{review.city}</td>
    <td>{review.status}</td>
    {/* <td>not found</td>
    <td>not found</td>
    <td>not found</td>
    <td>not found</td> */}
  </tr>
);

export default CheckInList;
