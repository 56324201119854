import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Input, Select } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';
import DateFilter from './DateFilter';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const PlaceSearch = ({
  enablePlaceFilter,
  placeName,
  city,
  filterBy,
  setFilterBy,
  setPlaceName,
  setStartDate,
  setEndDate,
  setCityFilter,
}) => {
  //   const handlePush = (args, key) => {
  //     const newRoutState = omit(routeState, key);
  //     history.push(
  //       `/orders-dashboard${qs.stringify(
  //         {
  //           ...newRoutState,
  //           ...args,
  //           page: 1,
  //         },
  //         true,
  //       )}`,
  //     );
  //   };

  const filterOptions = [
    { value: 'FILTER BY DAY', label: 'FILTER BY DAY' },
    { value: 'FILTER BY MONTH', label: 'FILTER BY MONTH' },
    { value: 'FILTER BY WEEK', label: 'FILTER BY WEEK' },
  ];

  return (
    <Wrapper>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-3">
            <Input
              label="Place Name"
              type="text"
              value={placeName}
              placeholder="Start entering place name..."
              onChange={event => setPlaceName(event.target.value)}
              disabled={!enablePlaceFilter}
            />
          </div>
          <div className="column is-3">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={value => {
                    if (enablePlaceFilter) {
                      setCityFilter(value.name);
                    }
                  }}
                  onHandleClear={() => {
                    if (enablePlaceFilter) {
                      setCityFilter('');
                    }
                  }}
                  placeholder="Start entering company name..."
                  // disabled={!enablePlaceFilter}
                />
              </div>
            </div>
          </div>
          <div className="column" style={{ position: 'relative', top: '20px' }}>
            <Select
              options={filterOptions}
              value={filterBy}
              onChange={({ value }) =>
                setFilterBy(filterOptions.find(ele => ele.value === value))
              }
            />
          </div>
          <div className="column" style={{ position: 'relative', top: '25px' }}>
            <DateFilter
              filterType={filterBy}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(PlaceSearch);
