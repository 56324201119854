import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Column, Table } from 'rbx';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Header from './Header';
import Search from './Search';
import UserList from './EventActivityUserList';
import {
  Input,
  Table as CustomTable,
  Loading,
} from '../../../components/elements';

const searchSocial = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_count {
          type
          count
        }
        total_social_per_object_id {
          total_social_count {
            type
            user {
              user_id
              time
            }
          }
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const Container = styled.div`
  padding: 0rem 1rem;
`;

const Activity = ({ event }) => {
  const [socialCount, setSocialCount] = useState({
    like: 0,
    bookmark: 0,
    share: 0,
  });

  const [user, setUser] = useState({
    like: [],
    bookmark: [],
    share: [],
  });

  const [filterType, setFilterType] = useState('like');

  const socialFilter = {
    object_id: event.event_id,
    object_type: 'EVENT',
  };

  return (
    <Container>
      <br />
      <Header
        event={event}
        subHeading3={moment(event.start.start).format('LLLL')}
      />
      <hr />
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={3}>
          <Input label="Page Views" placeholder="0" disabled />
        </Column>
        <Column size={3}>
          <Input label="Event Share Count" value={socialCount.share} disabled />
        </Column>
        <Column size={3}>
          <Input label="Event Like Count" value={socialCount.like} disabled />
        </Column>
        <Column size={3}>
          <Input
            label="Event Bookmark Count"
            value={socialCount.bookmark}
            disabled
          />
        </Column>
      </Column.Group>
      <Search filterType={filterType} setFilterType={setFilterType} />

      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User LogIn
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Updated At
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Shared On
                </span>
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Query
          query={searchSocial}
          variables={{
            input: {
              filter: {
                social_filter: socialFilter,
              },
            },
          }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return 'error';
            }

            const { search_social } = data;

            return (
              <UserList
                setSocialCount={setSocialCount}
                socialFilter={socialFilter}
                users={user[filterType]}
                setUser={setUser}
                search_social={search_social}
              />
            );
          }}
        </Query>
      </CustomTable>
    </Container>
  );
};

export default Activity;
