import { isArray, isString, intersection } from 'lodash';

import config from './config';

const check = (action, apiActions) => {
  let apiActionPermissions = [];

  if (isArray(action)) {
    apiActionPermissions = intersection(action, apiActions);
  }
  if (isString(action)) {
    apiActionPermissions = apiActions.filter(item => item === action);
  }

  if (!config.enableAcls || apiActionPermissions.length !== 0) {
    return true;
  }
  return false;
};

const Can = props =>
  check(props.action, props.apiActions, props.pageActions)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
