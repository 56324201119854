import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { uniq } from 'lodash';
import { Query } from 'react-apollo';
import { Box, Table, Loading, Pagination } from '../../../components/elements';
import Header from './Header';
import Search from './Search';
import PlaceList from './PlaceList';
import client from '../../../utils/apolloClient';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      total_pages
      total_size
      place_listing {
        place_id
        display_order
        service_type {
          description
          name
          display_order
        }
        # is_open
        listing_status
        claim_status
        featured
        event_business
        item_review_place
        status
        approval_status
        voucher_count
        default_url
        price_range
        name
        trading_name
        tagline
        description
        slug
        # stars
        review_count
        like_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        audit {
          updated_by
          updated_at
        }
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        social {
          type
          logo
          description
          value
          display
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const Places = ({ heading, handleSelect, listingStatus, claimStatus }) => {
  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeData, setPlaceData] = useState([]);
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');
  const [claimStatusSort, setClaimStatusSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [vendorNameSearchInput, setVendorNameSearchInput] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');
  const { userId } = useStoreState(state => state.auth);
  const input = { user_id: userId };
  const place_filter = { status: 'ACTIVE' };
  Object.assign(
    place_filter,
    vendorNameSearchInput !== '' && { vendor_name: vendorNameSearchInput },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    { listing_status: listingStatus },
    // { event_business: eventBusiness },
    claimStatus !== 'NONE' && { claim_status: claimStatus },
  );

  Object.assign(
    input,
    { sort },
    { from: pageFrom, size: listSize },
    location !== '' && { location },
    {
      filter: {
        place_filter,
      },
    },
  );

  return (
    <React.Fragment>
      <Header heading={heading} />
      <Box>
        <Search
          vendorNameSearchInput={vendorNameSearchInput}
          setVendorNameSearchInput={setVendorNameSearchInput}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          location={location}
          setLocation={setLocation}
        />
        <br />
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>

              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedBySort(updatedBySort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedBySort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedBySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated By
                </span>
              </th>
              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedAtSort(updatedAtSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedAtSort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedAtSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated At
                </span>
              </th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }
              const { search_places } = data;

              return (
                <React.Fragment>
                  <View
                    placeData={placeData}
                    setPlaceData={setPlaceData}
                    search_places={search_places}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                    handleSelect={handleSelect}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </React.Fragment>
  );
};

Places.defaultProps = {
  heading: '',
  listingStatus: 'APPROVED',
  claimStatus: 'CLAIMED',
  handleSelect: () => {},
};

Places.propTypes = {
  heading: PropTypes.string,
  listingStatus: PropTypes.string,
  claimStatus: PropTypes.string,
  handleSelect: PropTypes.func,
};

export default Places;

const View = ({
  search_places,
  setPageCount,
  setTotalElements,
  setPlaceData,
  placeData,
  handleSelect,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_places.total_pages);
    setTotalElements(search_places.total_size);
    if (search_places.place_listing) {
      setPlaceData(search_places.place_listing);
      const userFilter = search_places.place_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_places.total_pages]);
  return (
    <tbody>
      {placeData &&
        placeData.map(place => (
          <tr>
            <PlaceList
              handleSelect={handleSelect}
              place={place}
              users={users}
            />
          </tr>
        ))}
    </tbody>
  );
};
