import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { first } from 'lodash';
import client from '../../../utils/apolloClient';

import ManageEvent from './ManageEvent';
import Layout from '../../../components/global/Layout';
import { Message, Loading } from '../../../components/elements';

const allEventQuery = gql`
  query allEventQuery($eventId: String) {
    fetch_event(input: { event_id: $eventId }) {
      place_id
      event_id
      status
      place_name
      start
      end
      place_name
      name
      latitude
      longitude
      fee {
        name
        value
        sales_method
      }
      address_line_1
      address_line_2
      city
      state
      country
      post_code
    }
    fetch_event_occurrence(input: { event_id: $eventId }) {
      event_id
      event_occurrence_id
      total_capacity
      total_tickets
    }
  }
`;

const fetchEventBookingSummeryQuery = gql`
  query fetchEventBookingSummery($eventId: String) {
    fetch_event_booking_summary(
      input: { filter: { event_booking_filter: { event_id: $eventId } } }
    ) {
      total_bookings
      total_sales
      online_sales
      agent_sales
      refunds
      online_bookings
      agent_bookings
      total_discount
      total_booking_fee
      total_fee
      tickets_sold
      online_tickets_sold
      agent_tickets_sold
      total_absorb_fee
      total_passon_fee
      online_absorb_fee
      agent_absorb_fee
      online_passon_fee
      agent_passon_fee
      total_tickets
      tickets_available
    }
  }
`;

const ManageEventPage = ({ match }) => {
  const { params } = match;
  return (
    <Layout>
      <div className="container">
        <Query
          query={allEventQuery}
          variables={{
            eventId: params.eventId,
          }}
          fetchPolicy="network-only"
        >
          {({
            data: allEventData,
            loading: queryLoading,
            error: queryError,
          }) => {
            if (queryError) {
              return <Message>{queryError.message}</Message>;
            }
            if (queryLoading) {
              return <Loading />;
            }
            const { fetch_event, fetch_event_occurrence } = allEventData;

            return (
              <Query
                client={client.clientPrivate}
                query={fetchEventBookingSummeryQuery}
                variables={{
                  eventId: params.eventId,
                }}
                fetchPolicy="network-only"
              >
                {({ data: data2, loading: loading2, error: error2 }) => {
                  if (error2) {
                    return <Message>{error2.message}</Message>;
                  }
                  if (loading2) {
                    return <Loading />;
                  }

                  const { fetch_event_booking_summary } = data2;

                  if (fetch_event_booking_summary === null) {
                    return <Message>Something went wrong</Message>;
                  }

                  return (
                    <ManageEvent
                      event={fetch_event}
                      eventOccurrence={first(fetch_event_occurrence)}
                      bookingSummary={fetch_event_booking_summary}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default ManageEventPage;
