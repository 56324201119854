import { createContext } from 'react';

const aclsContext = createContext({
  role: [],
  apiActions: [],
  pageActions: [],
  assignRoles: [],
});

export const ACLSProvider = aclsContext.Provider;
export const ACLSConsumer = aclsContext.Consumer;
