import React from 'react';
import moment from 'moment';
import { first } from 'lodash';

const FollowersList = ({ follow, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === follow.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <tr>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user.full_name}>{user.full_name}</p>
          </React.Fragment>
        ) : (
          follow.audit.updated_by
        )}
      </td>
      <td>-</td>
      <td> {moment(follow.audit.updated_at).format('lll')} </td>
      {/* <td>-</td>
      <td>-</td>
      <td>--</td> */}
    </tr>
  );
};

export default FollowersList;
