const statusOptions = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const approvalStatusOptions = [
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'UNAPPROVED', label: 'UNAPPROVED' },
  { value: 'SUBMITTED', label: 'SUBMITTED' },
];

const paymentOptions = [
  { value: 'cash', label: 'Cash' },
  { value: 'credit-card', label: 'Credit Card' },
  { value: 'counter-payment', label: 'Pay At The Counter' },
];

export default { statusOptions, approvalStatusOptions, paymentOptions };
