import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';
import { Level } from 'rbx';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { uniq } from 'lodash';
import { Query } from 'react-apollo';
import {
  Box,
  Table,
  Loading,
  Pagination,
  Heading,
} from '../../../components/elements';

import Search from '../PlaceListing/Search';
import PlaceList from './PlaceList';
import client from '../../../utils/apolloClient';
import { placesOrderStatus } from './helpers';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      total_pages
      total_size
      place_listing {
        place_id
        display_order
        # is_open
        service_type {
          description
          name
          display_order
        }
        listing_status
        claim_status
        featured
        event_business
        item_review_place
        status
        approval_status
        voucher_count
        default_url
        price_range
        name
        trading_name
        tagline
        description
        slug
        # stars
        review_count
        like_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        audit {
          updated_by
          updated_at
        }
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        social {
          type
          logo
          description
          value
          display
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;
const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Order Manager</Heading>
      </Level.Item>
    </Level>
  </Wrapper>
);

const Places = ({ handleSelect, listingStatus, claimStatus }) => {
  const [sort, setSort] = useState('UPDATED_DESC');
  const { userId } = useStoreState(state => state.auth);

  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');

  const [page, setPage] = useState(0);
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [vendorNameSearchInput, setVendorNameSearchInput] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');
  const input = { user_id: userId };
  const place_filter = { status: 'ACTIVE' };

  Object.assign(
    place_filter,
    { has_service_type_setting: true },
    vendorNameSearchInput !== '' && { vendor_name: vendorNameSearchInput },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    { listing_status: listingStatus },
    // { event_business: eventBusiness },
    claimStatus !== 'NONE' && { claim_status: claimStatus },
  );

  Object.assign(
    input,
    { sort },
    { from: currentPage * listSize, size: listSize },
    location !== '' && { location },
    {
      filter: {
        place_filter,
        service_type_setting_filter: { status: 'ACTIVE' },
      },
    },
  );

  return (
    <React.Fragment>
      <Box>
        <Header />
        <Search
          vendorNameSearchInput={vendorNameSearchInput}
          setVendorNameSearchInput={setVendorNameSearchInput}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          location={location}
          setLocation={setLocation}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * listSize}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => setPage(value.selected + 1)}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Services
                  </span>
                  <br />
                </div>
              </th>

              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedBySort(updatedBySort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedBySort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedBySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated By
                </span>
              </th>
              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedAtSort(updatedAtSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedAtSort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedAtSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated At
                </span>
              </th>

              <th>
                <div>
                  <a
                    onClick={() => {
                      setPlaceNameSort(placeNameSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        placeNameSort === 'AES'
                          ? 'PLACE_NAME_DESC'
                          : 'PLACE_NAME_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        placeNameSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  <span>Order Status</span>
                  <br />
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
            client={client.clientPrivate}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }
              const { search_places } = data;

              return (
                <React.Fragment>
                  <View
                    search_places={search_places}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                    handleSelect={handleSelect}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </React.Fragment>
  );
};

Places.defaultProps = {
  listingStatus: 'APPROVED',
  claimStatus: 'CLAIMED',
  handleSelect: () => {},
};

Places.propTypes = {
  listingStatus: PropTypes.string,
  claimStatus: PropTypes.string,
  handleSelect: PropTypes.func,
};

export default Places;

const View = ({
  search_places,
  setPageCount,
  setTotalElements,
  handleSelect,
}) => {
  const [users, setUsers] = useState([]);
  const [orderStatus, setOrderStatus] = useState(null);
  useEffect(() => {
    setPageCount(search_places.total_pages);
    setTotalElements(search_places.total_size);
    if (search_places.place_listing) {
      const userFilter = search_places.place_listing.map(
        item => item.audit?.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: {
              filter: {
                user_filter: {
                  user_id: uniq(userFilter),
                  role_not_exist: true,
                },
              },
            },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_places.total_pages]);

  useEffect(() => {
    const tempFetchFunc = async () => {
      if (search_places && search_places.place_listing) {
        const placeIds = search_places.place_listing.map(
          place => place.place_id,
        );
        const pos = await placesOrderStatus(placeIds);
        setOrderStatus(pos);
      }
    };
    tempFetchFunc();
  }, [search_places]);

  console.log(orderStatus);
  const placeData = search_places.place_listing;
  return (
    <tbody>
      {placeData &&
        placeData.map(place => (
          <tr>
            <PlaceList
              handleSelect={handleSelect}
              place={place}
              users={users}
              orderStatus={orderStatus}
            />
          </tr>
        ))}
    </tbody>
  );
};
