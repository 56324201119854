import React from 'react';
import { Level } from 'rbx';
import styled from 'styled-components';

import { Heading } from '../elements';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({ heading }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>{heading}</Heading>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Header;
