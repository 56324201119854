import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';
import { getTicketUrlPdf } from '../../../utils/s3';

const TopWrapper = styled.div`
  padding: 0.2rem;
  margin-bottom: 0.5rem;
  /* border-bottom: 2px solid #f6f6f6; */
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Header = ({ event, booking, history }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const getTicket = async () => {
    const link = await getTicketUrlPdf(booking.url);
    console.log(link);

    setPdfUrl(link);
    setLoading(false);
  };
  useEffect(() => {
    getTicket();
  }, []);

  return (
    <React.Fragment>
      <TopWrapper>
        <Level>
          <Level.Item align="left">
            <Level.Item>
              <Title
                as="p"
                size={2}
                subtitle
                className="has-text-weight-semibold"
              >
                Booking details
              </Title>
            </Level.Item>
          </Level.Item>
          <Level.Item align="right">
            <a onClick={() => history.goBack()} className="button is-primary">
              <span>Back</span>
            </a>
          </Level.Item>
        </Level>
      </TopWrapper>

      <Flex>
        <Wrapper>
          <Level.Item align="left">
            <Title size={2}>{event.name}(Public event)</Title>
          </Level.Item>

          <SubHeading className="is-size-3">
            {event.place_name} | {event.state}
          </SubHeading>
          <SubHeading className="is-size-3">
            {event.address_line_1} | {event.city} | {event.post_code} |{' '}
            {event.country}
          </SubHeading>
          <SubHeading className="is-size-4 has-text-weight-semibold">
            {moment(event.start).format('LLLL')}
          </SubHeading>
        </Wrapper>
        <Wrapper>
          {/* <Input label="Booking No" value={booking.booking_number} disabled /> */}
          <div className="is-flex">
            <a
              className={`button is-primary ${loading && 'is-loading'}`}
              href={pdfUrl}
              download={`${kebabCase(event.name)}-${booking.booking_id}.pdf`}
            >
              Download Ticket(s)
            </a>
            &nbsp;&nbsp;
            <button className="button is-primary">Resend Ticket(s)</button>
          </div>
        </Wrapper>
      </Flex>
    </React.Fragment>
  );
};

export default withRouter(Header);
