import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import TextMask from 'react-text-mask';

import { range, forEach, toString, padStart, first, upperCase } from 'lodash';
import moment from 'moment';

const Container = styled.div`
  position: relative;
  width: auto;
`;
const Wrapper = styled.div`
  max-height: 10rem;
  position: absolute;
  width: 100%;
  overflow-y: scroll;
  margin-top: 6px;
  -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: white;
  z-index: 100;
`;

const Item = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: black;
  :hover {
    background: #f2f2f2;
  }
  :focus {
    background: #f2f2f2;
  }
`;

function timeMask(value) {
  const chars = value.split('');

  let hours;
  if (chars[0] === '0' || chars[0] === '1') {
    if (chars[1] === ':') {
      // only one digit before the colon: "0", "1", "2"
      hours = [/[0-2]/];
    } else {
      // two digits: 00, 01, 02, 03, 04, ..., 18, 19, 20, 21, 22, 23
      hours = [/[0-2]/, chars[0] === '1' ? /[0-2]/ : /[0-9]/];
    }
  } else {
    // one digit greater than two: 3, 4, 5, 6, 7, 8, 9
    hours = [/[3-9]/];
  }

  // minutes is always two-digits
  const minutes = [/[0-5]/, /[0-9]/];

  const format = [/[A|a|P|p]/, /[M|m]/];

  return hours
    .concat(':')
    .concat(minutes)
    .concat(' ')
    .concat(format);
}

const Suggestions = ({ suggestions, onSelect }) => (
  <Wrapper>
    {suggestions.map(item => (
      <a onClick={() => onSelect(item)}>
        <Item key={item}>{item}</Item>
      </a>
    ))}
  </Wrapper>
);

const TimeSelect = ({
  value,
  onChange,
  disabled,
  children,
  closed = false,
}) => {
  const [time, setTime] = useState(
    value ? moment(value, 'hh:mm A').format('hh:mm A') : '',
  );
  const [showDropDown, setShowDropDown] = useState(false);
  const container = useRef();
  const calculateRange = () => {
    const hoursRange = [];
    const hours = [12].concat(range(1, 12));
    const minute = range(0, 59, 15);
    forEach(['AM', 'PM'], item3 => {
      forEach(hours, item => {
        const valueRange =
          toString(item).length === 1 ? padStart(item, 2, '0') : toString(item);
        forEach(minute, item2 => {
          const value2 =
            toString(item2).length === 1
              ? padStart(item2, 2, '0')
              : toString(item2);
          hoursRange.push(`${valueRange}:${value2} ${item3}`);
        });
      });
    });
    return hoursRange;
  };
  const handleClick = e => {
    if (container.current.contains(e.target)) {
      return null;
    }
    setShowDropDown(false);
    return null;
  };

  useEffect(() => {
    if (!showDropDown) {
      if (time && time.length === 8) {
        const hours = moment(time, 'hh:mm A').hours();
        const minutes = moment(time, 'hh:mm A').minutes();
        onChange({ time, hours, minutes });
      } else if (time && time.length !== 0) {
        setTime('');
      }
    }
  }, [showDropDown]);

  useEffect(() => {
    if (value !== time) {
      setTime(value);
    }
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <Container ref={container}>
      {closed ? (
        <input
          value="CLOSED"
          className={`input ${time && time.length !== 8 && 'is-danger'}`}
          placeholder="00:00 AM"
          disabled={disabled}
        />
      ) : (
        <TextMask
          value={time}
          className={`input ${time && time.length !== 8 && 'is-danger'}`}
          mask={timeMask}
          onChange={e => {
            if (e.target.value) {
              setTime(e.target.value.toUpperCase());
            } else {
              setTime(e.target.value);
            }
          }}
          onFocus={() => setShowDropDown(true)}
          onBlur={e => {
            if (time && time.length === 6) {
              setTime(`${time}AM`);
            }
            if (time && time.length === 5) {
              setTime(`${time} AM`);
            }
            if (time && time.length < 4) {
              setTime(first(calculateRange()));
            }
            if (
              e.target.value === '00:00 AM' ||
              e.target.value === '00:00 PM'
            ) {
              setTime(null);
            }
            // setTimeout(() => {
            //   setShowDropDown(false);
            // }, 200);
          }}
          guide={false}
          placeholder="00:00 AM"
          disabled={disabled}
        />
      )}

      {children}
      {showDropDown && (
        <Suggestions
          suggestions={calculateRange()}
          onSelect={input => {
            setTime(input);
            setShowDropDown(false);
            const hours = moment(input, 'hh:mm A').hours();
            const minutes = moment(input, 'hh:mm A').minutes();
            onChange({ time: upperCase(input), hours, minutes });
          }}
        />
      )}
    </Container>
  );
};

export default TimeSelect;
