import React from 'react';
import { withRouter } from 'react-router-dom';

import { Radio, CheckBox } from '../../../components/elements';
import ListingTypeView from './ListingTypeView';

// import { getEventListingTypes } from '../../utils/fetchStatic';

const listingTypes = [
  { value: 'event-listing', label: 'Event listing' },
  { value: 'event with tickets', label: 'Event with tickets' },
  { value: 'event-registration', label: 'Event registration' },
];

const listingVisibility = [
  {
    value: 'private',
    label: 'Private',
    optionalText: '( Visible to people with the url link )',
  },
  {
    value: 'public',
    label: 'Public',
    optionalText: '( Visible to everyone and searchable )',
  },
];

const ListingTypeForm = ({ values, setFieldValue, location }) => {
  const handleEventListingType = (listingValue, visibilityValue) => {
    if (listingValue === 'event-listing' && visibilityValue === 'private') {
      setFieldValue('listing_type', ['private_listing']);
    }
    if (
      listingValue === 'event with tickets' &&
      visibilityValue === 'private'
    ) {
      setFieldValue('listing_type', ['private_tickets']);
    }
    if (
      listingValue === 'event-registration' &&
      visibilityValue === 'private'
    ) {
      setFieldValue('listing_type', ['private_registration']);
    }
    if (listingValue === 'event-listing' && visibilityValue === 'public') {
      setFieldValue('listing_type', ['public_listing']);
    }
    if (listingValue === 'event with tickets' && visibilityValue === 'public') {
      setFieldValue('listing_type', ['public_tickets']);
    }
    if (listingValue === 'event-registration' && visibilityValue === 'public') {
      setFieldValue('listing_type', ['public_registration']);
    }
  };

  return (
    <div>
      {location.pathname === '/add-event' && (
        <React.Fragment>
          <div className="label">Listing Type</div>
          <div className="columns is-multiline">
            {listingTypes.map(item => (
              <div className="column is-4">
                <Radio
                  label={item.label}
                  value={values.listing === item.value}
                  onChange={() => {
                    setFieldValue('listing', item.value);
                    handleEventListingType(item.value, values.visibility);
                  }}
                />
              </div>
            ))}
          </div>
          <div className="label">Visibility Type</div>
          <div className="columns is-multiline">
            {listingVisibility.map(item => (
              <div className="column is-4">
                <Radio
                  label={item.label}
                  optionalText={item.optionalText}
                  value={values.visibility === item.value}
                  onChange={() => {
                    setFieldValue('visibility', item.value);
                    handleEventListingType(values.listing, item.value);
                  }}
                />
              </div>
            ))}
            <div className="column is-3">
              <CheckBox
                label="Featured"
                value={values.featured}
                onChange={value => {
                  setFieldValue('featured', value);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <ListingTypeView values={values} />
    </div>
  );
};

export default withRouter(ListingTypeForm);
