import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import * as yup from 'yup';
import uuid from 'uuid';
import { useStoreState } from 'easy-peasy';
// import { Query } from 'react-apollo';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';
import { ACLSConsumer } from '../../../utils/aclsContext';

const usersQuery = gql`
  query users($input: SearchInput) {
    search_users(input: $input) {
      total_size
      total_pages
      user_listing {
        user_id

        first_name
        last_name
        display_name
        email
        date_of_birth
        gender
        status
        last_login

        contact {
          type
          value
          display
          display_order
          is_primary
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        error {
          code
          description
        }
      }
    }
  }
`;

const isJustAPartner = roleArray => {
  const partner = (roleArray ?? []).filter(rl => rl.startsWith('partner'));
  const kravein = (roleArray ?? []).filter(rl => rl.startsWith('kravein'));

  let isPartner = false;

  if (kravein.length !== 0) {
    isPartner = false;
  } else if (partner.length !== 0) {
    isPartner = true;
  }

  return isPartner;
};

const getUsers = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: usersQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });

const View = ({
  search_users,
  setPageCount,
  setTotalElements,
  userData,
  setUserData,
}) => {
  useEffect(() => {
    setPageCount(search_users.total_pages);
    setTotalElements(search_users.total_size);
    if (search_users.user_listing) {
      setUserData(search_users.user_listing);
    }
  }, [search_users.total_pages]);
  return (
    <tbody>
      {userData.map(user => (
        <ListItem user={user} />
      ))}
    </tbody>
  );
};

const TableRows = ({
  search_users,
  loading,
  error,
  userData,
  setUserData,
  setPageCount,
  setTotalElements,
}) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return 'error';
  }

  return (
    <ACLSConsumer>
      {({ role }) =>
        isJustAPartner(role) &&
        search_users.user_listing &&
        search_users.user_listing.length === 0 ? (
          <p style={{ color: '#363636', fontSize: '12px', padding: '12px' }}>
            No Results Found
          </p>
        ) : (
          <View
            userData={userData}
            setUserData={setUserData}
            search_users={search_users}
            setPageCount={setPageCount}
            setTotalElements={setTotalElements}
          />
        )
      }
    </ACLSConsumer>
  );
};

const Users = ({ assignRoles }) => {
  const { userId } = useStoreState(state => state.auth);
  const [userData, setUserData] = useState([]);
  const [sort, setSort] = useState('FIRSTNAME_DESC');

  const [firstNameSort, setFirstNameSort] = useState('AES');
  const [lastNameSort, setLastNameSort] = useState('AES');
  const [emailSort, setEmailSort] = useState('AES');
  const [displayNameSort, setDisplayNameSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const [status, setStatus] = useState('ACTIVE');

  const [loginSearchInput, setLoginSearchInput] = useState('');
  const [firstNameSearchInput, setFirstNameSearchInput] = useState('');
  const [lastNameSearchInput, setLastNameSearchInput] = useState('');
  const [displayNameSearchInput, setDisplayNameSearchInput] = useState('');

  const [loading, setLoading] = useState(false);
  const [search_users, setSearchUsers] = useState([]);
  const [error, setError] = useState(false);
  const [randomId, setRandomId] = useState(uuid());
  const changeRandomId = () => {
    setRandomId(uuid());
  };

  const input = {};
  const user_filter = { status, role: false };
  Object.assign(
    user_filter,
    loginSearchInput !== '' && { email: loginSearchInput },
    firstNameSearchInput !== '' && { full_name: firstNameSearchInput },
    lastNameSearchInput !== '' && { full_name: lastNameSearchInput },
    displayNameSearchInput !== '' && { display_name: displayNameSearchInput },
  );

  Object.assign(
    input,
    { user_id: userId },
    { from: pageFrom, size: listSize },
    { sort },
    {
      filter: {
        user_filter,
      },
    },
  );

  useEffect(() => {
    const asyncFnc = async () => {
      const [userRole] = assignRoles;
      const userType = userRole?.substring(0, 7);
      if (userType === 'kravein') {
        setLoading(true);
        await getUsers(input)
          .then(data => {
            setSearchUsers(data.search_users);
            setLoading(false);
          })
          .catch(() => setError(true));
      } else if (loginSearchInput !== '') {
        setLoading(true);
        const schema = yup.object().shape({
          email: yup.string().email(),
        });
        const isValidEmail = await schema.isValid({
          email: loginSearchInput.trim(),
        });
        if (isValidEmail) {
          await getUsers(input)
            .then(data => {
              setSearchUsers(data.search_users);
              setLoading(false);
            })
            .catch(() => setError(true));
        } else {
          setLoading(false);
        }
      }
    };
    asyncFnc();
  }, [
    randomId,
    loginSearchInput,
    firstNameSearchInput,
    lastNameSearchInput,
    displayNameSearchInput,
    assignRoles,
    sort,
    currentPage,
  ]);

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={status}
          setStatus={setStatus}
          loginSearchInput={loginSearchInput}
          setLoginSearchInput={setLoginSearchInput}
          firstNameSearchInput={firstNameSearchInput}
          setFirstNameSearchInput={setFirstNameSearchInput}
          lastNameSearchInput={lastNameSearchInput}
          setLastNameSearchInput={setLastNameSearchInput}
          displayNameSearchInput={displayNameSearchInput}
          setDisplayNameSearchInput={setDisplayNameSearchInput}
          changeRandomId={changeRandomId}
        />
        <br />
        <ACLSConsumer>
          {({ role }) =>
            isJustAPartner(role) &&
            (loginSearchInput.length === 0 ||
              search_users?.user_listing?.length === 0)
              ? null
              : totalElements > 0 && (
                  <Pagination
                    pageFrom={pageFrom}
                    pageCount={pageCount}
                    listSize={listSize}
                    currentPage={currentPage}
                    totalElements={totalElements}
                    handlePageClick={value => {
                      setPageFrom(value.selected * listSize);
                      setCurrentPage(value.selected);
                    }}
                  />
                )
          }
        </ACLSConsumer>
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEmailSort(emailSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          emailSort === 'AES' ? 'EMAIL_DESC' : 'EMAIL_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          emailSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Login
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setFirstNameSort(
                          firstNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          firstNameSort === 'AES'
                            ? 'FIRSTNAME_DESC'
                            : 'FIRSTNAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          firstNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    First Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setLastNameSort(lastNameSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          lastNameSort === 'AES'
                            ? 'LASTNAME_DESC'
                            : 'LASTNAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          lastNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Last Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setDisplayNameSort(
                          displayNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          displayNameSort === 'AES'
                            ? 'DISPLAYNAME_DESC'
                            : 'DISPLAYNAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          displayNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Display Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              {/* <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down is-size-7" /> Image
                    Status
                  </span>
                  <br />
                  <CustomInput />
                </div>
              </th> */}
              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down is-size-7" /> Last
                    Logged In
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <ACLSConsumer>
            {({ role }) =>
              isJustAPartner(role) && loginSearchInput.length === 0 ? null : (
                <TableRows
                  search_users={search_users}
                  loading={loading}
                  error={error}
                  userData={userData}
                  setUserData={setUserData}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                  loginSearchInput={loginSearchInput}
                />
              )
            }
          </ACLSConsumer>
        </Table>
      </Box>
    </Layout>
  );
};

export default Users;
