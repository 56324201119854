import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import {
  Input,
  ReactDateTimePicker,
  Select,
} from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const setDateHours = dateObject => {
  dateObject.setHours(0, 0, 0, 0);
  return dateObject;
};

const startDateISOString = dateObject => setDateHours(dateObject).toISOString();
// handlePush({ placeName: event.target.value }, 'placeName')

const PlaceSearch = ({ search, clearPagination, handlePush }) => (
  <Wrapper>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-2">
          <Input
            label="Place Name"
            type="text"
            value={search.placename}
            onChange={e => {
              clearPagination();
              handlePush({ placename: e.target.value }, 'placename');
            }}
          />
        </div>
        <div className="column is-2">
          <Input
            label="User Name"
            type="text"
            value={search.username}
            onChange={e => {
              clearPagination();
              handlePush({ username: e.target.value }, 'username');
            }}
          />
        </div>
        <div className="column is-3">
          <Input
            label="User Email"
            type="text"
            value={search.useremail}
            onChange={e => {
              clearPagination();
              handlePush({ useremail: e.target.value }, 'useremail');
            }}
          />
        </div>
        <div className="column is-3">
          <Input
            label="User Phone"
            type="text"
            value={search.userphone}
            onChange={e => {
              clearPagination();
              handlePush({ userphone: e.target.value }, 'userphone');
            }}
          />
        </div>
        <div className="column is-2">
          <Select
            label="Payment Method"
            options={[{ label: 'CARD', value: 'CARD' }]}
            value={[{ label: 'CARD', value: 'CARD' }]}
            disabled
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-2">
          <Input
            label="Order Reference"
            type="text"
            value={search.orderRef}
            onChange={e => {
              clearPagination();
              handlePush({ orderRef: e.target.value }, 'orderRef');
            }}
          />
        </div>
        <div className="column is-2">
          <Select
            label="Status"
            options={[{ label: 'COMPLETED', value: 'COMPLETED' }]}
            value={[{ label: 'COMPLETED', value: 'COMPLETED' }]}
            disabled
            disableToastMessage
          />
        </div>
        <div className="column is-2">
          <Select
            label="Service"
            options={[
              { label: 'ALL', value: 'All' },
              { label: 'PICKUP', value: 'Pickup' },
              { label: 'DELIVERY', value: 'Delivery' },
              { label: 'DINEIN', value: 'Dinein' },
            ]}
            value={search.service}
            onChange={e => {
              clearPagination();
              handlePush({ service: e.value }, 'service');
            }}
          />
        </div>
        <div className="column is-3">
          <ReactDateTimePicker
            value={search.startDate}
            onChange={date => {
              clearPagination();
              handlePush(
                { startDate: startDateISOString(new Date(date)) },
                'startDate',
              );
            }}
            label="From Date"
            clear={false}
          />
        </div>

        <div className="column is-3">
          <ReactDateTimePicker
            minDate={moment(
              search.startDate || startDateISOString(new Date()),
            ).toDate()}
            maxDate={moment(search.startDate || startDateISOString(new Date()))
              .add(45, 'days')
              .toDate()}
            value={search.endDate}
            label="To Date"
            onChange={date => {
              clearPagination();

              handlePush(
                {
                  endDate: moment(date)
                    .set({
                      hour: 23,
                      minute: 59,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString(),
                },
                'endDate',
              );
            }}
            clear={false}
          />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);
export default withRouter(PlaceSearch);
