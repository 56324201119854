import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { join } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useStoreActions } from 'easy-peasy';

import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';
import Delete from './Delete';

const CheckInList = ({ hours, refetch }) => {
  const { setDefaultPlaceId } = useStoreActions(state => state.menu);
  return (
    <>
      <td>
        <Link
          onClick={() => setDefaultPlaceId(hours.place_id)}
          to={`/update-custom-hour/${hours.custom_hour_id}`}
        >
          {moment(hours.day, 'YYYY-MM-DD').format('MMM, DD YYYY')}
        </Link>
      </td>
      <td>{hours.place.name}</td>
      <td>{hours.place.city}</td>
      <td>{hours.object_type}</td>
      <td>
        {hours.menu && hours.menu.length !== 0 ? join(hours.menu, ',') : '-'}
      </td>
      <td>
        <ReactTooltip html place="top" type="dark" effect="float" />
        <p
          data-tip={`<span>
          <p>email: ${hours.updated_by_user.email}</p>
          <p>objectId: ${hours.updated_by_user.user_id}</p>
        </span>`}
          data-html
        >
          {hours.updated_by_user.display_name}
        </p>
      </td>
      <td> {moment(hours.audit.updated_at).format('lll')} </td>
      <td>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action="delete_custom_hour"
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Delete
                  placeName={hours.place.name}
                  customHourId={hours.custom_hour_id}
                  onHandleComplete={() => refetch()}
                >
                  <i className="fas fa-trash has-text-grey" />
                </Delete>
              )}
            />
          )}
        </ACLSConsumer>
      </td>
    </>
  );
};

export default CheckInList;
