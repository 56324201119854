import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({ history }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button
            className="button is-primary"
            // onClick={}
          >
            <span>Submit Changes</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Header);
