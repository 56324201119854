import React, { useState, useEffect } from 'react';
import {
  useCategoriesFetchFn,
  useSubmitHandler,
  useAddButtonClickHandler,
} from './helper';
import AddCategoryModalUI from './AddCategoryModalUI';

const AddCategoryModal = ({
  userId,
  placeId,
  menuId,
  addCategoryModalIsActive,
  setAddCategoryModalIsActive,
  fetchCategories,
  currentMenuCategories,
}) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [addCategoryFormActive, setAddCategoryFormActive] = useState(false);

  const categoriesFetcher = useCategoriesFetchFn({
    userId,
    placeId,
    currentMenuCategories,
    setLoading,
    setCategories,
  });

  const submitHandler = useSubmitHandler({
    userId,
    placeId,
    menuId,
    setAddCategoryModalIsActive,
    setAddCategoryFormActive,
    fetchCategories,
  });

  const addBtnClickHandler = useAddButtonClickHandler({
    userId,
    menuId,
    setAddCategoryModalIsActive,
    setAddCategoryFormActive,
    fetchCategories,
    setLoading,
  });

  useEffect(() => {
    categoriesFetcher({});
  }, [currentMenuCategories]);

  return (
    <AddCategoryModalUI
      categoriesFetcher={categoriesFetcher}
      loading={loading}
      categories={categories}
      addCategoryModalIsActive={addCategoryModalIsActive}
      setAddCategoryModalIsActive={setAddCategoryModalIsActive}
      submitHandler={submitHandler}
      addBtnClickHandler={addBtnClickHandler}
      addCategoryFormActive={addCategoryFormActive}
      setAddCategoryFormActive={setAddCategoryFormActive}
    />
  );
};

export default AddCategoryModal;
