import React from 'react';
import { BoxWithHeader, SingleImageUpload } from '../../../components/elements';

const BookingTablePhoto = ({
  values,
  setFieldValue,
  onDeleteImage = () => {},
  errors,
}) => (
  <div style={{ width: '300px' }}>
    <BoxWithHeader title="Photos">
      <SingleImageUpload
        s3UploadPath="table-booking/"
        imageValue={values.image_url}
        onChange={image => setFieldValue('image_url', image.url)}
        onDelete={() => {
          setFieldValue('image_url', null);
          if (onDeleteImage) {
            onDeleteImage();
          }
        }}
      />
    </BoxWithHeader>
    {errors?.image_url && (
      <p className="help is-danger is-size-4">{errors?.image_url}</p>
    )}
  </div>
);

export default BookingTablePhoto;
