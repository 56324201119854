import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.lightGreyAlpha};
`;

const OrderTrackDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpecialSpacing = styled.div`
  padding: 0rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const CompleteStatus = ({ order, handleTimeLineTime, trackPath }) => (
  <Container>
    <SpecialSpacing className="time-line">
      <OrderTrackDiv className="is-size-4 has-text-weight-medium">
        <p className="is-size-4 has-text-weight-bold">
          {handleTimeLineTime('RECEIVED') && (
            <>
              <i className="fa fa-check" />{' '}
              {moment(handleTimeLineTime('RECEIVED')).format('LT')}
            </>
          )}
        </p>
        <span>Placed</span>
        <p className="is-size-4 has-text-weight-bold">
          {handleTimeLineTime('RECEIVED') && (
            <>{moment(handleTimeLineTime('RECEIVED')).format('DD MMM')}</>
          )}
        </p>
      </OrderTrackDiv>
      <OrderTrackDiv className="is-size-4 has-text-weight-medium">
        <p className="is-size-4 has-text-weight-bold">
          {handleTimeLineTime('CONFIRMED') && (
            <>
              <i className="fa fa-check" />{' '}
              {moment(handleTimeLineTime('CONFIRMED')).format('LT')}
            </>
          )}
        </p>
        <span>Confirmed</span>
        <p className="is-size-4 has-text-weight-bold">
          {handleTimeLineTime('CONFIRMED') && (
            <>{moment(handleTimeLineTime('CONFIRMED')).format('DD MMM')}</>
          )}
        </p>
      </OrderTrackDiv>
      {order.status !== 'CANCELLED' ? (
        <>
          <OrderTrackDiv className="is-size-4 has-text-weight-medium">
            <p className="is-size-4 has-text-weight-bold">
              {handleTimeLineTime('READY') && (
                <>
                  <i className="fa fa-check" />
                  {moment(handleTimeLineTime('READY')).format('LT')}
                </>
              )}
            </p>
            <span>Ready</span>
            <p className="is-size-4 has-text-weight-bold">
              {handleTimeLineTime('READY') && (
                <>{moment(handleTimeLineTime('READY')).format('DD MMM')}</>
              )}
            </p>
          </OrderTrackDiv>
          <OrderTrackDiv className="is-size-4 has-text-weight-medium">
            <p className="is-size-4 has-text-weight-bold">
              {handleTimeLineTime('COMPLETE') && (
                <>
                  <i className="fa fa-check" />{' '}
                  {moment(handleTimeLineTime('COMPLETE')).format('LT')}
                </>
              )}
            </p>
            <span>{trackPath()}</span>
            <p className="is-size-4 has-text-weight-bold">
              {handleTimeLineTime('COMPLETE') && (
                <>{moment(handleTimeLineTime('COMPLETE')).format('DD MMM')}</>
              )}
            </p>
          </OrderTrackDiv>
        </>
      ) : (
        <OrderTrackDiv>
          <p className="is-size-4 has-text-weight-bold">
            {handleTimeLineTime('CANCELLED') && (
              <>
                <i className="fa fa-check" />{' '}
                {moment(handleTimeLineTime('CANCELLED')).format('LT')}
              </>
            )}
          </p>
          <span>Cancelled</span>
          <p className="is-size-4 has-text-weight-bold">
            {handleTimeLineTime('CANCELLED') && (
              <>{moment(handleTimeLineTime('CANCELLED')).format('DD MMM')}</>
            )}
          </p>
        </OrderTrackDiv>
      )}
    </SpecialSpacing>
  </Container>
);

export default CompleteStatus;
