import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import uuidv4 from 'uuid/v4';

import { Box } from '../../../components/elements';
import Header from './Header';
import { getMenus } from '../Order/helpers';
import Tabs from './Tabs';
import StartStop from './StartStop';
import CustomHours from './CustomHours';

const Container = styled.div``;

const Main = ({ place }) => {
  const [isActiveTab, setIsActiveTab] = useState('customHours');
  const { userId } = useStoreState(state => state.auth);
  const [loading, setLoading] = useState(false);

  const [menus, setMenus] = useState([]);
  const [triggerFetchMenus, setTriggerFetchMenus] = useState(uuidv4());

  const refetchMenus = () => setTriggerFetchMenus(uuidv4());

  const fetchMenus = useCallback(async () => {
    try {
      setLoading(true);
      const menuList = await getMenus({
        placeId: place.place_id,
        userId,
      });
      setMenus(
        menuList.map(item => ({
          ...item,
          label: `${item.service_type} - ${item.name}`,
          value: item.menu_id,
        })),
      );
      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMenus();
  }, [triggerFetchMenus]);

  return (
    <>
      <Header
        placeName={place.name}
        placeLocation={`${place.city}, ${place.post_code} | ${place.country} `}
      />
      <Box>
        <Tabs isActiveTab={isActiveTab} setIsActiveTab={setIsActiveTab} />
        <Container>
          {isActiveTab === 'menuHours' && (
            <StartStop
              loading={loading}
              place={place}
              menus={menus}
              fetchMenus={fetchMenus}
            />
          )}
          {isActiveTab === 'customHours' && (
            <CustomHours
              refetchMenus={refetchMenus}
              place={place}
              menus={menus}
              userId={userId}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Main;
