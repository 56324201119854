import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({ history, loading, dirty }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">Manage Rewards</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-grey">
            <span className="has-text-weight-semibold">Cancel</span>
          </a>
        </Level.Item>
        <Level.Item>
          <button
            type="submit"
            className={`button is-primary ${loading && 'is-loading'}`}
            disabled={!dirty}
          >
            <span className="has-text-weight-semibold">Submit</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Header);
