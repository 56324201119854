import gql from 'graphql-tag';
import format from 'date-fns/format';

import client from '../../../utils/apolloClient';

const searchUserOrdersDetailsQuery = gql`
  query searchUserOrdersDetailsQuery($input: SearchInput) {
    search_user_orders_details(input: $input) {
      user_order_details_listing {
        menu_order_id
        order_reference
        status
        display_name
        email
        app_type
        place_name
        date
        service_type
        amount
        payment_method
        contact {
          type
          is_primary
          value
        }
      }
      total_pages
      total_size
    }
  }
`;

export const fetchUserOrdersDetails = async ({
  userId,
  from = 0,
  size = 50,
  placename,
  orderRef,
  username,
  useremail,
  userphone,
  service,
  startDate,
  endDate,
}) => {
  try {
    const filterObj = {};

    filterObj.menu_order_filter = {};

    filterObj.menu_order_filter.status = 'COMPLETE';
    filterObj.menu_order_filter.payment_method = 'CARD';

    if (orderRef) {
      filterObj.menu_order_filter.order_reference = orderRef;
    }

    if (service && service !== 'All') {
      filterObj.menu_order_filter.service_type = service;
    }

    if (placename) {
      filterObj.place_filter = {};
      filterObj.place_filter.place_name = placename;
    }

    if (username || useremail || userphone) {
      filterObj.user_filter = {};

      if (username) {
        filterObj.user_filter.display_name = username;
      }

      if (useremail) {
        filterObj.user_filter.email = useremail;
      }

      if (userphone) {
        // pass
        // filterObj.user_filter.userphone = userphone;
      }
    }

    if (new Date(startDate)?.getTime() === new Date(endDate)?.getTime()) {
      filterObj.menu_order_filter.date_range = {
        start_date: new Date(startDate).toISOString(),
        end_date: new Date(endDate).toISOString(),
      };
    } else if (startDate && endDate) {
      filterObj.menu_order_filter.date_range = {
        start_date: startDate,
        end_date: endDate,
      };
    }

    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchUserOrdersDetailsQuery,
      variables: {
        input: {
          filter: {
            ...filterObj,
          },
          from,
          size,
          sort: 'CREATED_AT_DESC',
          user_id: userId,
        },
      },
      fetchPolicy: 'network-only',
    });
    if (
      data &&
      data.data &&
      data.data.search_user_orders_details &&
      Array.isArray(
        data.data.search_user_orders_details.user_order_details_listing,
      )
    ) {
      console.log(
        'search_transactions_summary',
        data.data.search_user_orders_details,
      );

      return {
        data: data.data.search_user_orders_details.user_order_details_listing,
        totalPages: data.data.search_user_orders_details.total_pages,
        totalSize: data.data.search_user_orders_details.total_size,
      };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const transformUserOrderDetails = details =>
  (details ?? []).map(detail => ({
    'Order Ref': detail.order_reference,
    Status: detail.status,
    Customer: detail.display_name,
    Email: detail.email,
    Phone:
      (
        (detail?.contact ?? []).filter(
          ele => ele.type === 'mobile' && ele.is_primary,
        )[0] ?? {}
      ).value ?? '-',
    'Device Info': detail.app_type,
    'Place Name': detail.place_name,
    Date: format(new Date(detail.date), 'dd-MMM-yyyy, p'),
    Service: detail.service_type,
    Promo: '-',
    Amount: detail.amount,
    Refund: '-',
    Method: detail.payment_method,
    menu_order_id: detail.menu_order_id,
  }));

export default {};
