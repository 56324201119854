import React from 'react';
import styled from 'styled-components';
import { Column, Field, Label, Control } from 'rbx';
import moment from 'moment-timezone';

import {
  Radio,
  Input,
  TextArea,
  Select,
  ReactDateTimePicker,
  BoxWithHeader,
  SingleImageUpload,
  MobileInput,
  InputErrorMessage,
} from '../../../../components/elements';

const RadioWrapper = styled.div`
  padding-right: 1rem;
`;

const UserDetails = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  touched,
  user,
}) => (
  <Column.Group multiline>
    <Column size="4">
      <BoxWithHeader title="Photos">
        <SingleImageUpload
          s3UploadPath={`profile/${values.user_id}/`}
          imageValue={values.image_url}
          onChange={image => setFieldValue('image_url', image.url)}
          onDelete={() => setFieldValue('image_url', '')}
        />
      </BoxWithHeader>
    </Column>
    <Column size="8">
      <Column.Group multiline>
        <Column size="half">
          <Input label="Login" value={values.email} required disabled />
        </Column>
        <Column size="half">
          <Select
            label="Status"
            value={[{ value: values.status, label: values.status }]}
            options={[
              { value: 'ACTIVE', label: 'ACTIVE' },
              { value: 'INACTIVE', label: 'INACTIVE' },
            ]}
            onChange={value => setFieldValue('status', value.value)}
            required
          />
        </Column>
        <Column size="half">
          <Input
            label="First Name"
            name="first_name"
            id="UserManagement_AddEditUser_FirstName"
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.first_name}
            touched={touched.first_name}
            disableToastMessage
            required
          />
        </Column>
        <Column size="half">
          <Input
            label="Last Name"
            name="last_name"
            id="UserManagement_AddEditUser_LastName"
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.last_name}
            touched={touched.last_name}
            disableToastMessage
            required
          />
        </Column>
        <Column size="half">
          <Input
            label="Display Name"
            name="display_name"
            id="UserManagement_AddEditUser_DisplayName"
            value={values.display_name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.display_name}
            touched={touched.display_name}
            disableToastMessage
            required
          />
        </Column>

        <Column size="half">
          <Input
            label="User Handle"
            name="user_handle"
            value={values.user_handle}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.user_handle}
            touched={touched.user_handle}
            disableToastMessage
          />
        </Column>
        <Column size="half">
          <Input
            label="Primary Email"
            name="email"
            id="UserManagement_AddEditUser_PrimaryEmail"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.email}
            touched={touched.email}
            disableToastMessage
            required
            disabled={user}
          />
        </Column>
        <Column size="half">
          <MobileInput
            label="Primary Mobile"
            name="mobile"
            id="UserManagement_AddEditUser_PrimaryEmail"
            value={values.mobile}
            onChange={value => setFieldValue('mobile', value)}
          />
          <InputErrorMessage
            errors={errors.mobile}
            touched={touched.mobile}
            disableToastMessage
          />
        </Column>
        <Column size="half">
          <ReactDateTimePicker
            label="Date of Birth"
            value={values.date_of_birth}
            onChange={date => {
              setFieldValue('date_of_birth', moment(date).toISOString());
            }}
            required
          />
          <InputErrorMessage
            errors={errors.date_of_birth}
            touched={touched.date_of_birth}
            disableToastMessage
          />
        </Column>
        <Column size="half">
          <Field>
            <Label>Gender</Label>
            <Control>
              <div className="is-flex">
                <RadioWrapper>
                  <Radio
                    label="Male"
                    id="UserManagement_AddEditUser_Male"
                    value={values.gender === 'MALE'}
                    onChange={value => {
                      if (value) {
                        setFieldValue('gender', 'MALE');
                      }
                    }}
                  />
                </RadioWrapper>
                <RadioWrapper>
                  <Radio
                    label="Female"
                    id="UserManagement_AddEditUser_Female"
                    value={values.gender === 'FEMALE'}
                    onChange={value => {
                      if (value) {
                        setFieldValue('gender', 'FEMALE');
                      }
                    }}
                  />
                </RadioWrapper>
                <RadioWrapper>
                  <Radio
                    label="Other"
                    id="UserManagement_AddEditUser_Other"
                    value={values.gender === 'OTHER'}
                    onChange={value => {
                      if (value) {
                        setFieldValue('gender', 'OTHER');
                      }
                    }}
                  />
                </RadioWrapper>
              </div>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </Column>
    <Column size="full">
      <TextArea
        label="About User"
        name="description"
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.description}
        touched={touched.description}
      />
    </Column>
    {/* <Column size="full">
      <Contacts
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setValues={setValues}
        onlyAdditionalContact
      />
    </Column> */}
  </Column.Group>
);

export default UserDetails;
