import React, { useEffect } from 'react';
import styled from 'styled-components';
import { forEach } from 'lodash';
import { withRouter } from 'react-router-dom';

import { Radio, TagInputs } from '../../../components/elements';

const RadioWrapper = styled.div`
  padding: 0rem 0.3rem;
`;

const parseSuggestion = values => {
  const args = values.filter(
    item => item.event_occurrence_id !== '' && item.ticket_id !== '',
  );
  const events = [];
  forEach(args, item => {
    events.push({
      value: item.ticket_id,
      label: `${item.event_name} - ${item.ticket_name}`,
      item: { ...item },
    });
  });

  return events;
};

const TicketSelection = ({ values, setFieldValue, tickets, location }) => {
  const events = values.applies_to_event;
  const ticketSuggestion = () => {
    const data = [];
    tickets.map(item =>
      events.map(item2 => item.event_id === item2.event_id && data.push(item)),
    );
    return data;
  };

  useEffect(() => {
    if (location.pathname !== '/add-event-deal') {
      if (tickets.length === values.applies_to_tickets) {
        setFieldValue('ticketsSelection', 'all');
      } else {
        setFieldValue('ticketsSelection', 'selected');
      }
    }
  }, []);

  if (ticketSuggestion().length === 0) {
    return (
      <p className="has-text-warning">
        No Ticket Found (Please Select event check ticket)
      </p>
    );
  }

  return (
    <div className="field">
      <div className="label">Applicable Ticket(s)</div>
      <div className="control">
        <div className="is-flex">
          <RadioWrapper>
            <Radio
              label="All"
              value={values.ticketsSelection === 'all'}
              onChange={value => {
                if (value) {
                  setFieldValue('ticketsSelection', 'all');
                  setFieldValue('applies_to_tickets', ticketSuggestion());
                }
              }}
              disabled={events.length === 0}
            />
          </RadioWrapper>
          <RadioWrapper>
            <Radio
              label="Select Ticket(s)"
              value={values.ticketsSelection === 'selected'}
              onChange={value => {
                if (value) {
                  setFieldValue('ticketsSelection', 'selected');
                  setFieldValue('applies_to_tickets', []);
                }
              }}
              disabled={events.length === 0}
            />
          </RadioWrapper>
        </div>
        <br />
        {values.ticketsSelection === 'all' && (
          <React.Fragment>
            {tickets.length !== 0 && (
              <TagInputs
                label="Ticket(s)"
                value={parseSuggestion(values.applies_to_tickets)}
                required
                disabled
                suggestions={parseSuggestion(tickets)}
              />
            )}
          </React.Fragment>
        )}

        {values.ticketsSelection === 'selected' && (
          <TagInputs
            label="Ticket(s)"
            value={parseSuggestion(values.applies_to_tickets)}
            suggestions={parseSuggestion(ticketSuggestion())}
            onChange={value => {
              if (value) {
                const applies_to = value.map(item => ({
                  event_id: item.item.event_id,
                  event_occurrence_id: item.item.event_occurrence_id,
                  ticket_id: item.item.ticket_id,
                  ticket_price: item.item.ticket_price,
                  event_name: item.item.event_name,
                  ticket_name: item.item.ticket_name,
                }));
                setFieldValue('applies_to_tickets', applies_to);
              } else {
                setFieldValue('applies_to_tickets', []);
              }
            }}
            required
            disabled={events.length === 0}
          />
        )}
        {values.ticketsSelection === '' && (
          <TagInputs
            label="Ticket(s)"
            required
            disabled
            suggestions={parseSuggestion(tickets)}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(TicketSelection);
