import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { first } from 'lodash';
import uuidv4 from 'uuid/v4';

import { Select } from '../../../../components/elements';
import List from './List';
import AddEditCustomHour from './AddEditCustomHour';

const Container = styled.div`
  padding: 0rem 2rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 40%;
`;

const FlexItem1 = styled.div`
  width: 20%;
`;

const FlexItem2 = styled.div`
  width: 80%;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const Middle = styled.div`
  padding: 1rem 0rem;
`;

const ButtonText = styled.p`
  font-weight: 600;
  font-size: 12px;
`;

const Button = styled.button`
  &&& {
    background-color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 35%;
    height: 5vh;
  }
`;

const AddEditCustomHours = ({ place, menus, refetchMenus }) => {
  const [selectedMenu, setSelectedMenu] = useState();
  const [activeMenu, setActiveMenu] = useState();
  const [isActive, setIsActive] = useState(false);
  const [customHours, setCustomHours] = useState();
  const initializeState = React.useRef(true);

  const [holidays, setHolidays] = useState([]);
  const [holidayLoading, setHolidayLoading] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  useEffect(() => {
    if (selectedMenu && selectedMenu?.value !== 'All') {
      setSelectedMenu(menus.find(ele => ele.menu_id === selectedMenu.menu_id));
    }
  }, [menus]);

  const fetchHours = useCallback(async () => {
    setHolidayLoading(true);
    if (selectedMenu && selectedMenu?.value !== 'All') {
      const { custom_hour } = selectedMenu;
      if (Array.isArray(custom_hour) && custom_hour.length !== 0) {
        const tempCustomHours = custom_hour.map(ele => ({
          ...ele,
          custom_hour_id: uuidv4(),
        }));
        setCustomHours(tempCustomHours);
        const temp = [];
        tempCustomHours.forEach(ele => {
          temp.push({
            ...ele,
            place: { name: place.name, city: place.city },
            menu: [selectedMenu.name],
            selectedMenu,
          });
        });
        setHolidays(temp);
        setHolidayLoading(false);
      } else {
        setCustomHours();
        setHolidays([]);
        setHolidayLoading(false);
      }
    } else {
      const custom_hour = menus
        .map(ele => ({ ch: ele.custom_hour, mn: ele }))
        .filter(ele => Array.isArray(ele.ch))
        .reduce((p, c) => {
          const temp = [...p];
          const { ch, mn } = c;
          ch.forEach(ele2 => {
            temp.push({ ...ele2, selectedMenu: mn });
          });
          return temp;
        }, [])
        .sort((ch1, ch2) => new Date(ch1.date) - new Date(ch2.date));

      if (Array.isArray(custom_hour) && custom_hour.length !== 0) {
        const tempCustomHours = custom_hour.map(ele => ({
          ...ele,
          custom_hour_id: uuidv4(),
        }));
        setCustomHours(tempCustomHours);
        const temp = [];
        tempCustomHours.forEach(ele => {
          temp.push({
            ...ele,
            place: { name: place.name, city: place.city },
            menu: [ele.selectedMenu.name],
          });
        });
        setHolidays(temp);
        setHolidayLoading(false);
      } else {
        setCustomHours();
        setHolidays([]);
        setHolidayLoading(false);
      }
    }
  }, [selectedMenu, menus]);

  if (initializeState.current && menus.length !== 0) {
    setActiveMenu(first(menus));
    setSelectedMenu({ value: 'All', label: 'All' });
    initializeState.current = false;
  }

  return (
    <>
      {activeMenu && isActive && (
        <AddEditCustomHour
          place={place}
          isActive={isActive}
          setIsActive={setIsActive}
          fetchHours={fetchHours}
          customHour={updateData}
          setUpdateData={setUpdateData}
          refetchMenus={refetchMenus}
          customHoursFromMenu={customHours}
          activeMenu={activeMenu}
          menus={menus}
        />
      )}
      <Container>
        <FlexRow>
          <Flex>
            <FlexItem1>
              <h3 className="has-text-weight-semibold is-size-1">Menu</h3>
            </FlexItem1>
            <FlexItem2>
              <Select
                placeholder="Select Menu"
                value={selectedMenu}
                options={[{ value: 'All', label: 'All' }, ...menus]}
                onChange={value => {
                  setSelectedMenu(value);
                }}
                isLoading={false}
              />
            </FlexItem2>
          </Flex>
          <Button
            className="button is-primary"
            onClick={() => setIsActive(true)}
          >
            <ButtonText>ADD A NEW DATE</ButtonText>
          </Button>
        </FlexRow>
        {selectedMenu && (
          <Middle>
            <p className="has-text-weight-semibold is-size-2">
              Holiday or Custom hours
              {selectedMenu?.service_type &&
                selectedMenu?.name &&
                `for: ${selectedMenu.service_type} -
              ${selectedMenu.name}`}
            </p>
            <br />
            <p>
              Add a date and set your menu hours if you plan to close for a
              holiday, stay open for a limited hours or even to stay open for
              longer. To make changes to your regular menu hours go to
              &apos;Menu Hours&apos; in the previous tab.
            </p>
          </Middle>
        )}
      </Container>

      <List
        holidays={holidays}
        holidayLoading={holidayLoading}
        customHoursFromMenu={customHours}
        placeName={place.name}
        fetchHours={fetchHours}
        refetchMenus={refetchMenus}
        onHandleUpdate={values => {
          const { selectedMenu: sMenu, ...temp } = values;
          setUpdateData(temp);
          setIsActive(true);
          setActiveMenu(sMenu);
        }}
      />
    </>
  );
};

export default AddEditCustomHours;
