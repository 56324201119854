import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { first } from 'lodash';
import ReactTooltip from 'react-tooltip';

const CompanyList = ({ company, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === company.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  // const placeCounts = company.places.length !== 0 ? company.places.length : 0;
  return (
    <React.Fragment>
      <td>
        <Link to={`/update-company/${company.company_id}`}>{company.name}</Link>
      </td>
      <td>{company.city}</td>
      <td />
      {/* <td>{`${company.status}`}</td> */}
      <td />
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user.full_name}>{user.full_name}</p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user && user.email}</p>
                <p>objectId: {user && user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          company.audit.updated_by
        )}
      </td>
      <td>{moment(company.audit.updated_at).format('lll')}</td>
    </React.Fragment>
  );
};

export default CompanyList;
