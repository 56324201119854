import React from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../../../components/global/Layout';
import PlaceListing from '../PlaceListing';

const Orders = ({ history }) => (
  <Layout>
    <PlaceListing
      heading="Order History"
      handleSelect={value => {
        history.push(`/order-history/${value.place_id}`);
      }}
    />
  </Layout>
);

export default withRouter(Orders);
