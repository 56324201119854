import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Layout from '../../../components/global/Layout';
import PlaceApprovalForm from './PlaceApprovalForm';
import { Loading, Message } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const fetchImageReviewQuery = gql`
  query fetchImageReview($input: NavigateImageReviewInput) {
    fetch_image_review(input: $input) {
      image_id
      object_id
      object_type
      parent_id
      parent_type
      upload_type
      status
      url
      tags
      link
      note {
        type
        date
        value
      }
      approval_status
      approved_by
      approved_at
      rejected_by
      rejected_at
      audit {
        created_at
        created_by
      }

      error {
        code
        description
      }
    }
  }
`;

const fetchPlaceQuery = gql`
  query fetchPlace($input: NavigatePlaceInput) {
    fetch_place(input: $input) {
      place_id
      name
      city
      post_code
      country
      status
      claim_status
    }
  }
`;

const updateImageReviewMutation = gql`
  mutation action_image($input: ImageReviewInput) {
    action_image(input: $input) {
      image_id
      error {
        description
      }
    }
  }
`;

const PhotoApproval = ({ match, history }) => {
  const { imageReviewId } = match.params;
  const [action, setAction] = useState('');

  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={fetchImageReviewQuery}
        variables={{
          input: {
            image_id: imageReviewId,
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Message>{error.message}</Message>;
          }

          const review = data.fetch_image_review;

          if (!review) {
            return <Loading />;
          }

          return (
            <Query
              query={fetchPlaceQuery}
              variables={{ input: { place_id: review.parent_id } }}
              fetchPolicy="network-only"
            >
              {({ data: data1, loading: loading1, error: placeError }) => {
                if (loading1) {
                  return <Loading />;
                }
                if (error) {
                  return <Message>{placeError.message}</Message>;
                }
                const place = data1.fetch_place;
                return (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={updateImageReviewMutation}
                    onCompleted={({ action_image }) => {
                      if (!isNull(action_image.error)) {
                        action_image.error.map(item =>
                          toast.error(item.description),
                        );
                      } else {
                        swal(
                          'Hurray!',
                          `Place Photo ${
                            action === 'APPROVED' ? 'Approved' : 'Rejected'
                          } successfully!`,
                          'success',
                        ).then(() => {
                          history.goBack();
                        });
                      }
                    }}
                  >
                    {(action_image, { loading: updateLoading }) => (
                      <PlaceApprovalForm
                        images={review.url}
                        review={review}
                        place={place}
                        loading={updateLoading}
                        action={action}
                        handleAction={setAction}
                        onSubmit={value => {
                          const input = {
                            user_id: userId,
                            image_id: review.image_id,
                            note: [
                              {
                                value: value.note,
                                date: moment().toISOString(),
                              },
                            ],
                            approval_status: action,
                          };
                          action_image({ variables: { input } });
                        }}
                      />
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};
export default withRouter(PhotoApproval);
