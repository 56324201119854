import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({ loading, handleSubmit, history, showSubmit }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>
      {showSubmit && (
        <Level.Item align="right">
          <Level.Item>
            <button
              className={`button is-primary ${loading && 'is-loading'}`}
              onClick={handleSubmit}
              type="submit"
            >
              <span>Submit</span>
            </button>
          </Level.Item>
        </Level.Item>
      )}
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
