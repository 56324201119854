import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Column } from 'rbx';
import { trim, first } from 'lodash';

import { Select, Input, CheckBox } from './elements';
import LocationSuggestion from './global/GoogleSuggest';
import { ACLSConsumer } from '../utils/aclsContext';
import Can from '../utils/Can';

const CheckBoxWrapper = styled.div`
  padding-top: 2rem;
`;

const TIME_ZONE_OPTIONS = [
  { value: '+10', label: 'AEST' },
  { value: '+8', label: 'AWST' },
  { value: '+9.5', label: 'ACST' },
  { value: '+5.5', label: 'IST' },
];

const LocationForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  isEventComponent,
  action,
}) => {
  const [isManual, setManual] = useState(false);
  const timeZoneLabel = first(
    TIME_ZONE_OPTIONS.filter(item => item.value === values.timezone),
  );

  return (
    <Column.Group vcentered multiline gapSize={8}>
      <ACLSConsumer>
        {({ apiActions, pageActions }) => (
          <Can
            action="create_place"
            apiActions={apiActions}
            pageActions={pageActions}
            yes={() => (
              <Column size={6}>
                <LocationSuggestion
                  action={action}
                  label="Search Address"
                  id="PlaceManagement_AddEditPlace_SearchAddress"
                  initialValue={values.city}
                  onSelect={place => {
                    setFieldValue('address_line_1', trim(place.addressLine1));
                    setFieldValue('city', place.city);
                    setFieldValue('post_code', place.postcode);
                    setFieldValue('state', place.state);
                    setFieldValue('country', place.country || 'AUSTRALIA');
                    // setFieldValue('timezone', place.timezone || '+10');
                    setFieldValue('latitude', place.latitude);
                    setFieldValue('longitude', place.longitude);
                  }}
                  disabled={isManual ? !isManual : isEventComponent}
                />
              </Column>
            )}
            no={() => (
              <Column size={6}>
                <LocationSuggestion
                  action={action}
                  label="Search Address"
                  id="PlaceManagement_AddEditPlace_SearchAddress"
                  initialValue={values.city}
                  disabled
                />
              </Column>
            )}
          />
        )}
      </ACLSConsumer>

      <Column size={6}>
        <CheckBoxWrapper>
          <CheckBox
            action={action}
            label="Override Location"
            id="PlaceManagement_AddEditPlace_OverrideLocation"
            value={isManual}
            onChange={() => {
              setManual(!isManual);
            }}
          />
        </CheckBoxWrapper>
      </Column>
      <Column size={6}>
        <Input
          action={action}
          label="Street Address"
          name="address_line_1"
          value={values.address_line_1}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!isManual}
          className={
            errors.address_line_1 && touched.address_line_1 && 'is-danger'
          }
          required
          errors={errors.address_line_1}
          touched={touched.address_line_1}
        />
      </Column>
      <Column size={5}>
        <Input
          action={action}
          label="Additional Address Information "
          id="PlaceManagement_AddEditPlace_AdditionalAddressInformation"
          name="address_line_2"
          value={values.address_line_2}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Landmark, Shopping Center, etc"
        />
      </Column>
      <Column size={4}>
        <Input
          action={action}
          label="Suburb / City"
          name="city"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!isManual}
          className={errors.city && touched.city && 'is-danger'}
          required
          errors={errors.city}
          touched={touched.city}
        />
      </Column>
      <Column size={2}>
        <Input
          action={action}
          label="Pin / Postcode"
          disabled={!isManual}
          name="post_code"
          value={values.post_code}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.post_code && touched.post_code && 'is-danger'}
          required
          errors={errors.post_code}
          touched={touched.post_code}
        />
      </Column>
      <Column size={5}>
        <Input
          action={action}
          label="State"
          disabled={!isManual}
          name="state"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.state && touched.state && 'is-danger'}
          required
          errors={errors.state}
          touched={touched.state}
        />
      </Column>
      <Column size={4}>
        <Input
          action={action}
          label="Country"
          disabled={!isManual}
          name="country"
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.country && touched.country && 'is-danger'}
          required
          errors={errors.country}
          touched={touched.country}
        />
      </Column>
      <Column size={2}>
        <Select
          action={action}
          label="TimeZone"
          disabled={!isManual}
          options={TIME_ZONE_OPTIONS}
          value={[
            {
              value: values.timezone,
              label:
                timeZoneLabel && timeZoneLabel.length !== 0
                  ? timeZoneLabel.label
                  : 'AEST',
            },
          ]}
          onChange={({ value }) => setFieldValue('timezone', value)}
          className={errors.timezone && touched.timezone && 'is-danger'}
          required
          errors={errors.timezone}
          touched={touched.timezone}
        />
      </Column>
      <Column size={5}>
        <Column.Group gapSize={0}>
          <Column size={5}>
            <Input
              action={action}
              label="Latitude"
              type="number"
              disabled={!isManual}
              name="latitude"
              value={values.latitude}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.latitude && touched.latitude && 'is-danger'}
              required
              errors={errors.latitude}
              touched={touched.latitude}
            />
          </Column>
          <Column size={2} />
          <Column size={5}>
            <Input
              action={action}
              label="Longitude"
              type="number"
              disabled={!isManual}
              name="longitude"
              value={values.longitude}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.longitude && touched.longitude && 'is-danger'}
              required
              errors={errors.longitude}
              touched={touched.longitude}
            />
          </Column>
        </Column.Group>
      </Column>
    </Column.Group>
  );
};

LocationForm.defaultProps = {
  isEventComponent: false,
};

LocationForm.propTypes = {
  isEventComponent: PropTypes.bool,
};

export default LocationForm;
