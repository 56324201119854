import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { capitalize } from 'lodash';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { parsedAmount } from '../helpers';

const Container = styled.div`
  padding: 0.6rem;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
`;

const SpecialSpacing = styled.div`
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
  &&.time-line {
    align-items: flex-end;
  }
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const SpecialSpacingTopHr = styled(SpecialSpacing)``;

const CardDetails = ({ order }) => {
  console.log('orderdata', order);
  if (order.payment.payment_method === 'CARD') {
    return (
      <Container>
        <SpecialSpacingTopHr>
          <div>
            <p className="is-size-3 has-text-weight-semibold">
              {capitalize(
                order.payment &&
                  order.payment.payment_card &&
                  order.payment.payment_card.brand,
              )}{' '}
              ending in{' '}
              {order.payment &&
                order.payment.payment_card &&
                order.payment.payment_card.last4}
            </p>
            <p className="is-size-4">
              Paid - {moment(order.payment.payment_at).format('llll')}
            </p>
          </div>
          <p className="is-size-3 has-text-weight-semibold">
            {parsedAmount(order.billing.order_total.toFixed(2))}
          </p>
        </SpecialSpacingTopHr>

        {Array.isArray(order.refunds) && (
          <>
            {order.refunds
              .map(ele => [
                `${format(parseISO(ele.created_at), 'dd/MM/yyyy')} at ${format(
                  parseISO(ele.created_at),
                  'hh:mm a',
                )}`,
                `$${parseFloat(ele.refund_amount).toFixed(2)}`,
                ele.refund_reason,
              ])
              .map(ele => (
                <div className="mb-3">
                  <div className="is-flex is-justify-content-space-between">
                    <p className="is-size-3 has-text-weight-semibold">
                      <span style={{ color: '#EE2A7B' }}>Refund</span> {ele[0]}
                    </p>
                    <p className="is-size-3 has-text-weight-semibold">
                      {ele[1]}
                    </p>
                  </div>
                  <p className="is-size-4">{ele[2]}</p>
                </div>
              ))}
          </>
        )}
      </Container>
    );
  }
  return null;
};

export default CardDetails;
