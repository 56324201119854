import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Loading from 'react-loading';

// import styled from 'styled-components';
import { first, kebabCase } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
// import swal from 'sweetalert';
import AddDishPhoto from './AddDishPhoto';
import { updateMenuItem } from './helper';

import { upload_image_s3 } from '../../../../../utils/s3';

const MyDropzone = ({
  imageValue,
  s3UploadPath,
  onChange,
  metaData,
  userId,
  placeId,
  menuItemId,
  //   onDelete,
}) => {
  const [imagePreview, setImagePreview] = useState(imageValue);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(async acceptedFiles => {
    setLoading(true);
    const file = acceptedFiles[0];
    setImagePreview(URL.createObjectURL(file));
    const imageName = file.name.split('.');
    const name = `${kebabCase(first(imageName))}-${moment().format(
      'YYYYMMDD_hhmmss',
    )}.${file.type.substring(6)}`;
    const acceptImageList = ['image/jpeg', 'image/png', 'image/jpg'];
    const imageAcceptable = acceptImageList.filter(
      item1 => item1 === file.type,
    );
    if (imageAcceptable.length !== 0) {
      const { Location: url } = await upload_image_s3(
        file,
        `${s3UploadPath}${name}`,
        metaData,
      );
      const response = { path: name, url };
      try {
        await updateMenuItem({ userId, placeId, menuItemId, imageUrl: url });
      } catch (error) {
        console.log('error while upating menu item with default photo', error);
      }
      onChange(response);
      setLoading(false);
    } else {
      toast.error('Only png, jpeg, jpg image are accepted!');
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    onDrop,
  });

  useEffect(() => {
    setImagePreview(imageValue);
  }, [imageValue]);

  //   const handleDeleteImage = () => {
  //     swal({
  //       title: 'Are you sure?',
  //       // eslint-disable-next-line max-len
  //       text: `Are you sure you want to delete this Image`,
  //       buttons: ['Cancel', 'Yes'],
  //       dangerMode: true,
  //     }).then(async willDelete => {
  //       if (willDelete) {
  //         setLoading(true);
  //         const keySplit = imagePreview && imagePreview.split('au/')[1];
  //         await delete_image_s3(keySplit)
  //           .then(res => {
  //             console.log(res);
  //             toast.warn('Image Deleted...!');
  //             onDelete();
  //             setLoading(false);
  //           })
  //           .catch(err => {
  //             console.log(err);
  //             toast.error(err);
  //             setLoading(false);
  //           });
  //       }
  //     });
  //   };

  return (
    <div
      style={{
        width: '23%',
        height: '100%',
        position: 'relative',
      }}
      onClick={e => e.stopPropagation()}
    >
      <div
        style={{
          height: '100%',
        }}
        {...getRootProps()}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          {loading && (
            <Loading type="spin" color="#363636" width="24px" height="24px" />
          )}
        </div>
        <div className="field" style={{ position: 'absolute', right: '0' }}>
          <label>
            <input
              className="file-input is-loading"
              name="image-upload"
              {...getInputProps()}
              disabled={loading}
            />
          </label>

          <AddDishPhoto />
        </div>
        {imagePreview && (
          <img
            src={imagePreview}
            alt="image-upload"
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              borderRadius: '4px 0 0 4px',
              opacity: loading ? '0.5' : '1',
            }}
          />
        )}
        <br />
      </div>
    </div>
  );
};

MyDropzone.defaultProps = {
  imageValue: 'https://bulma.io/images/placeholders/256x256.png',
  s3UploadPath: 'dummy/',
  metaData: {},
  onChange: () => {},
  handleRemoveCurrentImage: () => {},
  defaultImage: '',
  setDefaultImage: () => {},
};

MyDropzone.propTypes = {
  imageValue: PropTypes.string,
  s3UploadPath: PropTypes.string,
  defaultImage: PropTypes.string,
  metaData: PropTypes.object,
  onChange: PropTypes.func,
  handleRemoveCurrentImage: PropTypes.func,
  setDefaultImage: PropTypes.func,
};

export default MyDropzone;
