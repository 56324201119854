import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { first, replace } from 'lodash';
import moment from 'moment';

import { getZoneTime } from '../../../utils/eventHelper';
import DeleteEventAction from './DeleteEventAction';

const EventList = ({ event, queryInput }) => {
  const [active, setActive] = useState(true);
  if (!active) {
    return null;
  }

  return (
    <React.Fragment>
      <td>
        <Link to={`/update-event/${event.place_id}/${event.event_id}`}>
          {event.name}
        </Link>
      </td>
      <td>{`${event.place_name}`}</td>
      <td>{`${event.city}`}</td>
      <td className="is-capitalized">
        {replace(first(event.listing_type), '_', ' ')}
      </td>
      {/* <td>{`${event.place_name}`}</td> */}
      <td>{`${moment(
        getZoneTime(event.start, event.latitude, event.longitude),
      ).format('lll')}`}</td>

      <td>{event.status}</td>
      <td>
        <DeleteEventAction
          eventId={event.event_id}
          queryInput={queryInput}
          onComplete={() => setActive(false)}
        />
      </td>
    </React.Fragment>
  );
};

export default EventList;
