import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull, omit, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import qs from 'querystringify';

import Layout from '../../../components/global/Layout';
import { ErrorMessage, Loading } from '../../../components/elements';
import Form from './Form';
import client from '../../../utils/apolloClient';

import { updateSectionsInMenu } from './helpers';

const menuSectionQuery = gql`
  query menuSection($input: NavigateMenuSectionInput) {
    fetch_menu_section(input: $input) {
      place_id
      platform
      menu_id
      menu_section_id
      name
      description
      status
      tag
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      error {
        code
        description
      }
    }
  }
`;

const updateServiceMutation = gql`
  mutation updateService($input: [MenuSectionInput]) {
    update_menu_section(input: $input) {
      menu_section_id
      name
      error {
        description
      }
    }
  }
`;

const ServiceUpdate = ({ history, match, location }) => {
  const { userId } = useStoreState(state => state.auth);
  const { id } = match.params;
  const routeState = qs.parse(location.search);
  console.log('routeState', routeState);
  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={menuSectionQuery}
        variables={{
          input: { menu_section_id: id },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <ErrorMessage message={error.message} />;
          }
          const section = data.fetch_menu_section;
          return (
            <Mutation
              client={client.clientPrivate}
              mutation={updateServiceMutation}
              onCompleted={({ update_menu_section }) => {
                if (update_menu_section) {
                  update_menu_section.map(edge => {
                    if (!isNull(edge.error)) {
                      edge.error.map(item => toast.error(item.description));
                    } else {
                      swal(
                        'Good job!',
                        'Menu Category changes have been submitted successfully!',
                        'success',
                      ).then(() => {
                        history.push('/menu-sections');
                      });
                    }
                  });
                }
              }}
            >
              {(update_menu_section, { loading: loading2, error: error1 }) => (
                <>
                  {error1 && <ErrorMessage message={error1.message} />}
                  <Form
                    userId={userId}
                    section={section}
                    activeAssignTab={routeState.assign_item_active}
                    loading={loading2}
                    onSubmit={values => {
                      const input = omit(values, ['menus']);
                      // console.log('input cat', input);
                      const inputsArray = {
                        user_id: input.user_id,
                        menu_section_id: input.menu_section_id,
                      };

                      Object.assign(
                        inputsArray,
                        !isEqual(input.menu_id, section.menu_id) && {
                          menu_id: input.menu_id,
                        },
                        !isEqual(input.description, section.description) && {
                          description: input.description,
                        },
                        !isEqual(input.name, section.name) && {
                          name: input.name,
                        },
                        !isEqual(input.place_id, section.place_id) && {
                          place_id: input.place_id,
                        },
                        !isEqual(input.tag, section.tag) && {
                          tag: input.tag,
                        },

                        !isEqual(input.status, section.status) && {
                          status: input.status,
                        },
                      );
                      update_menu_section({
                        variables: {
                          input: [inputsArray],
                        },
                      });

                      try {
                        const section_menu_id_set = new Set(section.menu_id);

                        const input_menu_id_set = new Set(input.menu_id);

                        const transformMenuSection = sections =>
                          sections.map((ele, idx) => ({
                            ...omit(ele, '__typename'),
                            display_order: idx,
                          }));

                        if (section.menu_id.length < input.menu_id.length) {
                          input.menu_id
                            .filter(ele => !section_menu_id_set.has(ele))
                            .map(m_id =>
                              values.menus.find(ele => ele.menu_id === m_id),
                            )
                            .map(mnus => ({
                              user_id: userId,
                              menu_section: transformMenuSection([
                                ...(mnus?.menu_section ?? []),
                                {
                                  name: input.name,
                                  menu_section_id: input.menu_section_id,
                                  display_order: 0,
                                },
                              ]),
                              menu_id: mnus.menu_id,
                            }))
                            .forEach(updateSectionsInMenuInput => {
                              updateSectionsInMenu({
                                ...updateSectionsInMenuInput,
                              });
                            });
                        } else if (
                          section.menu_id.length > input.menu_id.length
                        ) {
                          section.menu_id
                            .filter(ele => !input_menu_id_set.has(ele))
                            .map(mnu_id =>
                              values.menus.find(ele => ele.menu_id === mnu_id),
                            )
                            .map(mnus => ({
                              user_id: userId,
                              menu_section: transformMenuSection([
                                ...(mnus?.menu_section ?? []).filter(
                                  ele =>
                                    ele.menu_section_id !==
                                    input.menu_section_id,
                                ),
                              ]),
                              menu_id: mnus.menu_id,
                            }))
                            .forEach(updateSectionsInMenuInput => {
                              updateSectionsInMenu({
                                ...updateSectionsInMenuInput,
                              });
                            });
                        }
                      } catch (error2) {
                        console.log(
                          'error on updating sections in menu',
                          error2,
                        );
                      }
                    }}
                  />
                </>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default ServiceUpdate;
