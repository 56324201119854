import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Tab } from 'rbx';
import { withFormik } from 'formik';

import * as yup from 'yup';
import { Tabs, TextArea } from '../../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import Images from './Images';
import CreatedUserDetails from './CreatedUserDetails';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Photo Details',
  },
  // {
  //   key: 1,
  //   name: 'Photo Likes',
  // },
  // {
  //   key: 2,
  //   name: 'Photo Shares',
  // },
  // {
  //   key: 3,
  //   name: 'Photo Flags',
  // },
];

const Form = props => {
  const { place, user } = props;
  const [activeTab, setActiveTab] = useState(0);
  const prev = () =>
    activeTab === 0 ? setActiveTab(3) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 3 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  return (
    <React.Fragment>
      <Header
        heading="Place Photo Approvals"
        prev={prev}
        next={next}
        activeTab={activeTab}
        action={props.action}
        loading={props.loading}
        onSubmit={() => {
          props.handleSubmit();
        }}
        status={false}
        // eslint-disable-next-line max-len
        subHeading={`${place.name} | ${place.city}, ${place.post_code} | ${place.country} ( ${place.status} ) `}
      />
      <Tabs className="tabs is-medium">
        {tabs.map(tab => (
          <Tab
            active={activeTab === tab.key}
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.name}
          </Tab>
        ))}
      </Tabs>
      <Box>
        <Container>
          <Images
            setFieldValue={props.setFieldValue}
            images={props.values.images}
          />
          <TextArea
            required
            label="Note"
            name="note"
            id="ReviewManagement_ReviewPlacePhotos_Note"
            value={props.values.note}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            rows={2}
            errors={props.errors.note}
            touched={props.touched.note}
          />
          <CreatedUserDetails
            user={user}
            createdAt={props.values.images[0].audit.created_at}
          />
        </Container>
      </Box>
      <hr />
      <Footer
        next={next}
        prev={prev}
        activeTab={activeTab}
        action={props.action}
        loading={props.loading}
        onSubmit={() => {
          props.handleSubmit();
        }}
        status={false}
      />
    </React.Fragment>
  );
};

const PlaceImageApprovalForm = withFormik({
  mapPropsToValues: ({ images }) => ({
    images,
    note: '',
  }),

  validationSchema: yup.object().shape({
    note: yup.string().required('Note is required!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },

  displayName: 'PlaceImageApprovalForm',
})(Form);

export default PlaceImageApprovalForm;
