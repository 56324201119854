import axios from 'axios';
import config from './config';

// const url = `https://${config.apiPrefix}content.kravein.com.au/static`;
const url = `https://s3-ap-southeast-2.amazonaws.com/content.kravein.com.au/static`;
const businessTypesUrl = `${url}/place/business/type.json`;
const cuisineUrl = `${url}/menu/cuisine/style.json`;
const itemTypesUrl = `${url}/menu/item/type.json`;
const diningStyleUrl = `${url}/place/dining/style.json`;
const dietaryInformationUrl = `${url}/place/dietary/information.json`;
const parkingDetailsUrl = `${url}/common/parking/detail.json`;
const publicTransportUrl = `${url}/common/public/transport.json`;
const noiseLevelUrl = `${url}/place/noise/level.json`;
const dressCodeUrl = `${url}/place/dress/code.json`;
const ambienceUrl = `${url}/place/ambience.json`;
const goodeForUrl = `${url}/place/good/for.json`;
const serviceTypesUrl = `${url}/place/service/type.json`;
const eventTypesUrl = `${url}/event/type.json`;
const featureUrl = `${url}/common/feature.json`;
const serviceCategoryUrl = `${url}/place/service/category.json`;
const eventCategoryUrl = `${url}/event/category.json`;
const contactTypeUrl = `${url}/contact/type.json`;
const feedbackTypeUrl = `${url}/contact/feedback/type.json`;
const eventListingTypeUrl = `${url}/event/listing/type.json`;
const eventRestrictionUrl = `${url}/event/restriction.json`;
const eventTicketTypeUrl = `${url}/event/ticket/type.json`;
const eventTicketSalesMethodUrl = `${url}/event/ticket/sales/method.json`;
const eventTicketStatusUrl = `${url}/event/ticket/status.json`;
const generalFeeUrl = `${url}/general/fee.json`;
const userRolesUrl = `${url}/security/role.json`;
const taxUrl = `${url}/company/tax.json`;
const rolesUrl = `${url}/security/role.json`;
const menuTypeDeals = `${url}/place/menu/deal/type.json`;

export const getRoles = () =>
  new Promise((resolve, reject) => {
    axios
      .get(rolesUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getTax = () =>
  new Promise((resolve, reject) => {
    axios
      .get(taxUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getUserRoles = () =>
  new Promise((resolve, reject) => {
    axios
      .get(userRolesUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getPlaceTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(businessTypesUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getCuisines = () =>
  new Promise((resolve, reject) => {
    axios
      .get(cuisineUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getItemTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(itemTypesUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getDiningStyle = () =>
  new Promise((resolve, reject) => {
    axios
      .get(diningStyleUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getDietaryInformation = () =>
  new Promise((resolve, reject) => {
    axios
      .get(dietaryInformationUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getParkingDetails = () =>
  new Promise((resolve, reject) => {
    axios
      .get(parkingDetailsUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getPublicTransports = () =>
  new Promise((resolve, reject) => {
    axios
      .get(publicTransportUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getNoiseLevels = () =>
  new Promise((resolve, reject) => {
    axios
      .get(noiseLevelUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getDressCodes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(dressCodeUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getAmbience = () =>
  new Promise((resolve, reject) => {
    axios
      .get(ambienceUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getGoodFor = () =>
  new Promise((resolve, reject) => {
    axios
      .get(goodeForUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getServicesTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(serviceTypesUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventTypesUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getFeatures = () =>
  new Promise((resolve, reject) => {
    axios
      .get(featureUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getServiceCategories = () =>
  new Promise((resolve, reject) => {
    axios
      .get(serviceCategoryUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventCategories = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventCategoryUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getContactTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(contactTypeUrl)
      .then(response => {
        //
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getFeedbackTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(feedbackTypeUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventListingTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventListingTypeUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventRestrictions = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventRestrictionUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventTicketTypes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventTicketTypeUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventTicketSalesMethods = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventTicketSalesMethodUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getEventTicketStatuses = () =>
  new Promise((resolve, reject) => {
    axios
      .get(eventTicketStatusUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getGeneralFee = () =>
  new Promise((resolve, reject) => {
    axios
      .get(generalFeeUrl)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getMenuTypeDeals = () =>
  new Promise((resolve, reject) => {
    axios
      .get(menuTypeDeals)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
