import gql from 'graphql-tag';
import { sortBy } from 'lodash';
import client from '../../../../utils/apolloClient';

const searchItemLinkQuery = gql`
  query searchMenuSectionLink($input: SearchInput) {
    search_menu_sections(input: $input) {
      menu_section_listing {
        menu_section_id
        name
        place_id
        display_order
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getAllCategories = (filter, userId) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchItemLinkQuery,
        variables: {
          input: {
            size: 100,
            filter: {
              menu_section_filter: {
                menu_id: [filter.menu_id],
              },
              place_filter: { place_id: [filter.place_id] },
            },
            user_id: userId,
            sort: 'DISPLAY_ORDER_ASC',
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_sections &&
          data.search_menu_sections.menu_section_listing
        ) {
          resolve(
            sortBy(
              data.search_menu_sections.menu_section_listing,
              'display_order',
            ),
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
