import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';

import { useBookingSlots } from './helpers';

const GreenCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin: 2px;
`;

const RedCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin: 2px;
`;

const SelectedSlotContainer = styled.div`
  border: 1px solid #e2e2e2;
  background-color: #737373;
  color: white;
  min-width: 60px;
  font-size: 10px;
  font-weight: 700;
  flex: 1;
  min-height: 60px;
  cursor: pointer;
`;

const SlotContainer = styled.div`
  border: 2px solid#e2e2e2;
  min-width: 60px;
  height: 60px;
  font-size: 10px;
  font-weight: 700;
  flex: 1;
  min-height: 60px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  height: 60px;
  width: 75vw;
  position: relative;
  left: 40px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &&::-webkit-scrollbar {
    display: none;
  }
  margin-top: 20px;
`;

const Slot = ({ hasTooManyGuests, timing, onClick, isSelected }) =>
  isSelected ? (
    <SelectedSlotContainer
      className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
      onClick={onClick}
    >
      {hasTooManyGuests ? <RedCircle /> : <GreenCircle />}
      <div>{timing}</div>
    </SelectedSlotContainer>
  ) : (
    <SlotContainer
      className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
      onClick={onClick}
    >
      {hasTooManyGuests ? <RedCircle /> : <GreenCircle />}
      <div>{timing}</div>
    </SlotContainer>
  );

const BookingSlotsList = ({
  placeId,
  date = new Date(),
  setFieldValue,
  booking_time,
}) => {
  const { userId: adminUserId } = useStoreState(state => state.auth);

  useBookingSlots({
    adminUserId,
    date: date
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replaceAll('/', '-'),
    placeId,
  });

  const currentDateBookingSlots = [
    { time_slot: '10:30 AM', total_guest_count: 12 },
    { time_slot: '10:45 AM', total_guest_count: 7 },
    { time_slot: '11:00 AM', total_guest_count: 15 },
    { time_slot: '11:15 AM', total_guest_count: 4 },
    { time_slot: '11:30 AM', total_guest_count: 9 },
    { time_slot: '11:45 AM', total_guest_count: 18 },
    { time_slot: '12:00 PM', total_guest_count: 11 },
    { time_slot: '12:15 PM', total_guest_count: 6 },
    { time_slot: '12:30 PM', total_guest_count: 14 },
    { time_slot: '12:45 PM', total_guest_count: 3 },
    { time_slot: '01:00 PM', total_guest_count: 8 },
    { time_slot: '01:15 PM', total_guest_count: 17 },
    { time_slot: '01:30 PM', total_guest_count: 10 },
    { time_slot: '01:45 PM', total_guest_count: 2 },
    { time_slot: '02:00 PM', total_guest_count: 13 },
    { time_slot: '02:15 PM', total_guest_count: 5 },
    { time_slot: '02:30 PM', total_guest_count: 19 },
    { time_slot: '02:45 PM', total_guest_count: 1 },
    { time_slot: '03:00 PM', total_guest_count: 16 },
    { time_slot: '03:15 PM', total_guest_count: 20 },
    { time_slot: '03:30 PM', total_guest_count: 9 },
    { time_slot: '03:45 PM', total_guest_count: 14 },
    { time_slot: '04:00 PM', total_guest_count: 3 },
    { time_slot: '04:15 PM', total_guest_count: 12 },
    { time_slot: '04:30 PM', total_guest_count: 8 },
    { time_slot: '04:45 PM', total_guest_count: 11 },
    { time_slot: '05:00 PM', total_guest_count: 17 },
    { time_slot: '05:15 PM', total_guest_count: 15 },
    { time_slot: '05:30 PM', total_guest_count: 6 },
    { time_slot: '05:45 PM', total_guest_count: 2 },
    { time_slot: '06:00 PM', total_guest_count: 13 },
    { time_slot: '06:15 PM', total_guest_count: 4 },
    { time_slot: '06:30 PM', total_guest_count: 19 },
    { time_slot: '06:45 PM', total_guest_count: 10 },
    { time_slot: '07:00 PM', total_guest_count: 1 },
    { time_slot: '07:15 PM', total_guest_count: 16 },
    { time_slot: '07:30 PM', total_guest_count: 18 },
    { time_slot: '07:45 PM', total_guest_count: 9 },
    { time_slot: '08:00 PM', total_guest_count: 7 },
    { time_slot: '08:15 PM', total_guest_count: 20 },
    { time_slot: '08:30 PM', total_guest_count: 5 },
    { time_slot: '08:45 PM', total_guest_count: 12 },
    { time_slot: '09:00 PM', total_guest_count: 14 },
    { time_slot: '09:15 PM', total_guest_count: 3 },
    { time_slot: '09:30 PM', total_guest_count: 11 },
    { time_slot: '09:45 PM', total_guest_count: 8 },
    { time_slot: '10:00 PM', total_guest_count: 17 },
    { time_slot: '10:15 PM', total_guest_count: 2 },
    { time_slot: '10:30 PM', total_guest_count: 15 },
    { time_slot: '10:45 PM', total_guest_count: 4 },
    { time_slot: '11:00 PM', total_guest_count: 19 },
  ];

  return (
    <ContentContainer className="is-flex">
      {currentDateBookingSlots.map(ele => (
        <Slot
          hasTooManyGuests={ele.total_guest_count > 6}
          timing={ele.time_slot}
          onClick={() => {
            setFieldValue('booking_time', ele.time_slot);
          }}
          isSelected={ele.time_slot === booking_time}
          key={ele.time_slot}
        />
      ))}
    </ContentContainer>
  );
};

export default BookingSlotsList;
