/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';

import Header from './Header';
import Footer from './Footer';
import FeedReview from './FeedReview';
import PlaceReview from './PlaceReview';
import ItemReview from './ItemReview';
import Comment from './Comment';
import ImageReview from './ImageReview';
import FlaggedUserDetails from './FlaggedUserDetails';

import { Input, TextArea } from '../../../components/elements';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const Details = ({
  image,
  loading,
  action,
  flag,
  place,

  userData,
  handleSubmit,
  placeName,
  userPlaceReviewCount,
  userFlagData,
  userItemReviewCount,
  items,
}) => {
  const handleRejectAction = () => {
    handleSubmit('REJECTED');
  };

  const handleApproveAction = () => {
    handleSubmit('APPROVED');
  };

  return (
    <>
      <Container>
        {/*  {flag.object_type === 'ITEM_REVIEW' ||
        flag.object_type === 'ITEM_REVIEW_COMMENT' ||
        flag.object_type === 'IMAGE' ? (
          <ItemHeader
            action={action}
            heading="Flagged Reviews"
            placeName={placeName}
            items={items}
            loading={loading}
            handleApproveAction={handleApproveAction}
            handleRejectAction={handleRejectAction}
            flag={flag}
            // eslint-disable-next-line max-len
            subHeading={`${place.name} | ${place.city}, ${place.post_code} | ${place.country} (${place.status} / ${place.claim_status} / ${place.listing_status})`}
          />
        ) : ( */}
        <Header
          action={action}
          heading="Flagged Reviews"
          placeName={placeName}
          loading={loading}
          handleApproveAction={handleApproveAction}
          handleRejectAction={handleRejectAction}
          flag={flag}
          status={flag.status}
          // eslint-disable-next-line max-len
          subHeading={`${place.name} | ${place.city}, ${place.post_code} | ${place.country} (${place.status} / ${place.claim_status} / ${place.listing_status})`}
        />
        {/* )} */}

        <Column>
          <fieldset className="box">
            <legend className="label">Flag Details</legend>
            <Column>
              <Column.Group vcentered multiline gapSize={8}>
                <Column size="half">
                  <Input
                    label="Review Type"
                    value={flag.object_type}
                    disabled
                  />
                </Column>
                <Column size="half">
                  <Input
                    label="Parent Type"
                    value={flag.parent_type}
                    disabled
                  />
                </Column>
                <Column size="half">
                  <Input label="Flag Type" value={flag.flag_type} disabled />
                </Column>
                <Column size="half">
                  <Input label="Status" value={flag.status} disabled />
                </Column>
              </Column.Group>
            </Column>

            <Column size="full">
              <TextArea
                label="Flag Content"
                value={flag.flag_content}
                disabled
              />
            </Column>

            <FlaggedUserDetails
              flag={flag}
              createdBy={flag.audit.created_by}
              createdTime={flag.audit.created_at}
              userFlagData={userFlagData}
              userItemReviewCount={userItemReviewCount}
              userPlaceReviewCount={userPlaceReviewCount}
            />
          </fieldset>
        </Column>

        <div>
          {flag.object_type === 'PLACE_FEED' ? (
            <FeedReview flag={flag} user={userData} />
          ) : flag.object_type === 'PLACE_REVIEW' ? (
            <PlaceReview flag={flag} placeName={place} />
          ) : flag.object_type === 'ITEM_REVIEW_COMMENT' ||
            flag.object_type === 'PLACE_REVIEW_COMMENT' ? (
            <Comment flag={flag} />
          ) : flag.object_type === 'ITEM_REVIEW' ? (
            <ItemReview flag={flag} items={items} />
          ) : (
            <ImageReview flag={flag} image={image} />
          )}
        </div>

        <Footer
          action={action}
          loading={loading}
          flag={flag}
          status={flag.status}
          handleRejectAction={handleRejectAction}
          handleApproveAction={handleApproveAction}
        />
      </Container>
    </>
  );
};

export default Details;
