import React from 'react';
import moment from 'moment-timezone';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({ user }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2} className="is-capitalized">
          {user.first_name} {user.last_name}
        </Title>
      </Level.Item>
    </Level>
    <React.Fragment>
      <SubHeading className="is-size-2">{user.email}</SubHeading>
      <SubHeading className="is-size-2">
        Joined {moment(user.audit.created_at).format('llll')} (
        {moment(user.audit.created_at).fromNow()})
      </SubHeading>
    </React.Fragment>
  </Wrapper>
);

export default Header;
