import gql from 'graphql-tag';
import moment from 'moment-timezone';
import tzLookup from 'tz-lookup';
import { forEach } from 'lodash';
import client from '../../../../utils/apolloClient';

const searchCustomHoursQuery = gql`
  query searchCustomHours($input: SearchInput) {
    search_custom_hours(input: $input) {
      total_pages
      total_size
      custom_hours_listing {
        custom_hour_id
        place_id
        menu_id
        object_type
        day
        menu
        description
        option {
          start
          start_in_sec
          end
          end_in_sec
        }
        closed_all_day
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        created_by_user {
          user_id
          email
          display_name
        }
        updated_by_user {
          user_id
          email
          display_name
        }
        place {
          place_id
          name
          city
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getCustomHours = async ({ userId, menuIds }) => {
  console.log('menu', menuIds);

  const { data } = await client.clientPrivate.query({
    query: searchCustomHoursQuery,
    variables: {
      input: {
        user_id: userId,
        filter: {
          custom_hour_filter: {
            day: moment().format('YYYY-MM-DD'),
            menu_id: menuIds,
          },
        },
      },
    },
  });
  return data.search_custom_hours;
};

const numberOfDaysRemainingInAWeek = () => 7 - (new Date().getDay() - 1);

const range = to => Array.from({ length: to }, (_, index) => index);

const transformDateRange = dateRange =>
  dateRange.map(dr =>
    moment()
      .add(dr, 'day')
      .format('YYYY-MM-DD'),
  );

export const getCustomHours2 = async ({ userId, menuIds }) => {
  const temp = transformDateRange(range(numberOfDaysRemainingInAWeek()));
  const { data } = await client.clientPrivate.query({
    query: searchCustomHoursQuery,
    variables: {
      input: {
        user_id: userId,
        filter: {
          custom_hour_filter: {
            date_range: {
              start_date: temp[0],
              end_date: temp[temp.length - 1],
            },
            menu_id: menuIds,
          },
        },
      },
    },
  });
  return data.search_custom_hours;
};
const get_current_time_from_location = (latitude, longitude) => {
  const time_zone = tzLookup(parseFloat(latitude), parseFloat(longitude));
  return moment().tz(time_zone);
};

const sorter = {
  0: 'SUN',
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI',
  6: 'SAT',
};

export const todayHours = (menuHours, location) => {
  const date_now = get_current_time_from_location(
    location.latitude,
    location.longitude,
  );
  const currentDayHour = menuHours.filter(
    item => item.day === sorter[date_now.day()],
  );

  return currentDayHour[0].option.length !== 0 ? currentDayHour[0].option : [];
};

export const calculateOpeningHours = (menuHours, location) => {
  let opening = null;
  const date_now = get_current_time_from_location(
    location.latitude,
    location.longitude,
  );
  const currentDayHour = menuHours.filter(
    item => item.day === sorter[date_now.day()],
  );
  if (currentDayHour.length !== 0) {
    const hours = date_now.hours();
    const min = date_now.minutes();
    const sec = date_now.seconds();
    const timeInSec = hours * 3600 + min * 60 + sec;
    currentDayHour[0].option.forEach(element => {
      if (
        element.start_in_sec <= timeInSec &&
        element.end_in_sec >= timeInSec
      ) {
        opening = element;
      }
    });
  }
  return opening;
};
export const mergeMenuHours = (menuHours, customHours) => {
  const mergedHours = menuHours;
  customHours.forEach(ch => {
    const day = moment(ch.date)
      .format('ddd')
      .toUpperCase();
    const temp = mergedHours.find(mh => mh.day === day);
    temp.option = ch.option;
    temp.isCustom = true;
  });
  return mergedHours;
};
