import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Title, Level } from 'rbx';
import { omit } from 'lodash';
import qs from 'querystringify';
import { withRouter } from 'react-router-dom';
import {
  Input,
  ReactDateTimePicker,
  Radio,
} from '../../../components/elements';

import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;
const BoxWrapper = styled.div`
  &&& {
    background: #f6f6f6;
  }
`;

const SearchBox = ({
  eventNameSearchQuery,
  setEventNameSearchQuery,
  status,
  setStatus,
  setLocation,
  setStartDateRangeFilter,
  setEndDateRangeFilter,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  eventStatus,
  eventInactiveStatus,
  routeState,
  history,
  event_name,
  place_name,
}) => {
  const [city, setCity] = useState('');

  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/select-events-for-sell-tickets${qs.stringify(
        {
          ...newRoutState,
          ...args,
        },
        true,
      )}`,
    );
  };

  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Current"
              value={status === 'current'}
              onChange={() => {
                setStatus('current');
                setStartDateRangeFilter({
                  end_date: moment().format('YYYY-MM-DD'),
                });
                setEndDateRangeFilter({
                  // start_date: moment(
                  //   `${moment().format('L')} 11:59 PM`,
                  // ).toISOString(),
                  start_date: moment().format('YYYY-MM-DD'),
                });
              }}
            />
          </Level.Item>

          <Level.Item>
            <Radio
              label="Future"
              value={status === 'future'}
              onChange={() => {
                setStatus('future');
                setStartDateRangeFilter({
                  start_date: moment(`${moment().format('L')} 12:00 AM`)
                    .add(1, 'day')
                    .toISOString(),
                });
                setEndDateRangeFilter();
              }}
            />
          </Level.Item>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Event Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Active"
              value={eventStatus === 'ACTIVE'}
              onChange={() =>
                handlePush({ eventStatus: 'ACTIVE' }, 'eventStatus')
              }
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Inactive"
              value={eventStatus === 'INACTIVE'}
              onChange={() =>
                handlePush({ eventStatus: 'INACTIVE' }, 'eventStatus')
              }
            />
          </Level.Item>
        </Level.Item>
      </Level>

      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-one-quarter">
            <Input
              label="Place Name"
              type="text"
              value={place_name}
              onChange={event => {
                handlePush({ place_name: event.target.value }, 'place_name');
              }}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-one-quarter">
            <Input
              label="Event Name"
              name="searchQuery"
              value={event_name}
              onChange={event => {
                handlePush({ event_name: event.target.value }, 'event_name');
              }}
              placeholder="Start entering event name..."
            />
          </div>
          <div className="column is-one-quarter">
            <LocationSuggestion
              label="City / Suburb"
              initialValue={city}
              onSelect={data => {
                setLocation(data.location);
                setCity(data.name);
              }}
              onHandleClear={length => {
                if (length === 0) {
                  setLocation('');
                  setCity('');
                }
              }}
            />
          </div>
          <div className="column">
            <ReactDateTimePicker
              value={null}
              label=" Event Start date"
              onChange={date => {
                setStatus('');
                if (date === null) {
                  setStatus('active');
                  setStartDateRangeFilter({
                    end_date: moment()
                      .add(1, 'day')
                      .format('YYYY-MM-DD'),
                  });
                  setEndDateRangeFilter({
                    start_date: moment().format('YYYY-MM-DD'),
                  });
                } else {
                  setStatus('active');
                  setStartDateRangeFilter({
                    start_date: moment(date).format('YYYY-MM-DD'),
                    end_date: moment(
                      `${moment(date).format('L')} 11:59 PM`,
                    ).format('YYYY-MM-DD'),
                  });
                  setEndDateRangeFilter();
                }
              }}
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(SearchBox);
