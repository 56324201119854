import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'rbx';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import uuidv4 from 'uuid/v4';
import Loading from 'react-loading';
import swal from 'sweetalert';

import { Table as CustomTable, Box } from '../../../../../components/elements';
import client from '../../../../../utils/apolloClient';
import { fetchMenuFees } from './helpers';
import List from './List';
import AddEditForm from './AddEditForm';

const Container = styled.div`
  .table-container {
    min-height: 30rem;
  }
`;

const AddChargeButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 40%;
    margin: 1rem 0rem;
  }
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const createMenuFeeMutation = gql`
  mutation createMenuFeeMutation($input: MenuFeeInput) {
    create_menu_fee(input: $input) {
      error {
        description
      }
      user_id
      place_id
      menu_id
      internal_name
      is_editable
      fee_type
      value
      payment_method
      period
      display_name
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      days {
        day
        is_active
      }
    }
  }
`;

const updateMenuFeeMutation = gql`
  mutation updateMenuFeeMutation($input: MenuFeeInput) {
    update_menu_fee(input: $input) {
      error {
        description
      }
      user_id
      place_id
      menu_id
      display_name
      internal_name
      is_editable
      fee_type
      value
      payment_method
      period
      start_date
      start_time
      end_date
      end_time
    }
  }
`;

const deleteMenuFeeMutation = gql`
  mutation deleteMenuFeeMutation($input: NavigateMenuFeeInput) {
    delete_menu_fee(input: $input) {
      error {
        description
      }
      user_id
      place_id
      menu_fee_id
      menu_id
      display_name
      internal_name
      is_editable
      fee_type
      value
      payment_method
      period
      start_date
      start_time
      end_date
      end_time
    }
  }
`;

const Details = ({ menuId, placeId }) => {
  const { userId } = useStoreState(state => state.auth);
  const [fees, setFees] = useState([]);
  const [fee, setFee] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [refetch, setRefetch] = useState(uuidv4());

  // console.log(listLoading);
  // console.log(placeId);
  // console.log(menuId);
  // console.log(setFees);
  // console.log(fees);

  useEffect(() => {
    const fetchFunc = async () => {
      setListLoading(true);
      fetchMenuFees({ menuId }).then(feesResponse => {
        setFees(feesResponse);
        setListLoading(false);
      });
    };
    fetchFunc();
  }, [menuId, refetch]);

  const [createFee, { loading }] = useMutation(createMenuFeeMutation, {
    client: client.clientPrivate,
    onCompleted: ({ create_menu_fee }) => {
      if (!isNull(create_menu_fee.error)) {
        create_menu_fee.error.map(item => toast.error(item.description));
      } else {
        setIsActiveModal(false);
        setRefetch(uuidv4());
      }
    },
  });

  const [updateFee, { loading: loading2 }] = useMutation(
    updateMenuFeeMutation,
    {
      client: client.clientPrivate,
      onCompleted: ({ update_menu_fee }) => {
        if (!isNull(update_menu_fee.error)) {
          update_menu_fee.error.map(item => toast.error(item.description));
        } else {
          setIsActiveModal(false);
          setRefetch(uuidv4());
          setFee(null);
        }
      },
    },
  );

  const [deleteFee] = useMutation(deleteMenuFeeMutation, {
    client: client.clientPrivate,
    onCompleted: ({ delete_menu_fee }) => {
      if (!isNull(delete_menu_fee.error)) {
        delete_menu_fee.error.map(item => toast.error(item.description));
      } else {
        setRefetch(uuidv4());
      }
    },
  });

  return (
    <>
      <Box>
        <Container>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AddChargeButton
              type="button"
              className="button control"
              onClick={() => {
                setFee(null);
                setIsActiveModal(true);
              }}
            >
              Add Charge
            </AddChargeButton>
          </div>
          <CustomTable style={{ position: 'relative' }}>
            <Table.Head>
              <Table.Row>
                <Table.Heading>Fee Name</Table.Heading>
                <Table.Heading>Fee Display Label</Table.Heading>
                <Table.Heading>Status</Table.Heading>
                <Table.Heading>Fee Type</Table.Heading>
                <Table.Heading>Fee</Table.Heading>
                <Table.Heading>Period</Table.Heading>
                <Table.Heading>Applies To</Table.Heading>
                <Table.Heading>Action</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <List
                fees={fees}
                onEdit={value => {
                  setFee(value);
                  setIsActiveModal(true);
                }}
                onDelete={value => {
                  swal('Are you sure you want to delete the fee?', {
                    buttons: ['Cancel', true],
                  }).then(willDelete => {
                    if (willDelete) {
                      deleteFee({
                        variables: {
                          input: {
                            user_id: userId,
                            menu_fee_id: value.menu_fee_id,
                          },
                        },
                      });
                    }
                  });
                }}
              />
            </Table.Body>
            {listLoading && (
              <Center>
                <Loading
                  type="spin"
                  color="#363636"
                  width="60px"
                  height="60px"
                />
              </Center>
            )}
          </CustomTable>
        </Container>
      </Box>
      {isActiveModal && (
        <AddEditForm
          fee={fee}
          loading={loading || loading2}
          isActive={isActiveModal}
          onClose={() => setIsActiveModal(false)}
          onSubmit={values => {
            if (fee) {
              const inp = {
                menu_fee_id: fee.menu_fee_id,
                user_id: userId,
                internal_name: values.internal_name,
                is_editable: values.is_editable,
                status: values.status,
                value: values.value,
                payment_method: values.payment_method,
                period: values.period,
                display_name: values.display_name,
                fee_type: values.fee_type,
                start_date: null,
                start_time: null,
                end_date: null,
                end_time: null,
                days: null,
              };

              if (values.period === 'DAYS') {
                inp.days = values.days;
              } else if (values.period === 'DATE') {
                inp.start_date = values.start_date;
                inp.start_time = values.start_time;
                inp.end_date = values.end_date;
                inp.end_time = values.end_time;
              }

              updateFee({
                variables: {
                  input: inp,
                },
              });
            } else {
              createFee({
                variables: {
                  input: {
                    user_id: userId,
                    menu_id: menuId,
                    place_id: placeId,
                    ...values,
                  },
                },
              });
            }
          }}
        />
      )}
    </>
  );
};

export default Details;
