import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import * as yup from 'yup';
import { Input, Radio } from '../../../components/elements';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  status,
  setStatus,
  loginSearchInput,
  setLoginSearchInput,
  firstNameSearchInput,
  setFirstNameSearchInput,
  lastNameSearchInput,
  setLastNameSearchInput,
  displayNameSearchInput,
  setDisplayNameSearchInput,
  changeRandomId,
}) => {
  const isJustAPartner = roleArray => {
    const partner = (roleArray ?? []).filter(rl => rl.startsWith('partner'));
    const kravein = (roleArray ?? []).filter(rl => rl.startsWith('kravein'));

    let isPartner = false;

    if (kravein.length !== 0) {
      isPartner = false;
    } else if (partner.length !== 0) {
      isPartner = true;
    }

    return isPartner;
  };

  const [searchLoginAsPartner, setSearchLoginAsPartner] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);

  return (
    <ACLSConsumer>
      {({ role, loading: securityLoading }) => (
        <Wrapper>
          {!securityLoading && !isJustAPartner(role) && (
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <TitleWrapper>
                    <Title className="label">Status</Title>
                  </TitleWrapper>
                </Level.Item>
                <Level.Item>
                  <Radio
                    label="ACTIVE"
                    value={status === 'ACTIVE'}
                    onChange={() => {
                      setStatus('ACTIVE');
                    }}
                  />
                </Level.Item>
                <Level.Item>
                  <Radio
                    label="INACTIVE"
                    value={status === 'INACTIVE'}
                    onChange={() => {
                      setStatus('INACTIVE');
                    }}
                  />
                </Level.Item>
              </Level.Item>
            </Level>
          )}
          <BoxWrapper className="box">
            <div className="columns">
              {!securityLoading && !isJustAPartner(role) ? (
                <div className="column is-3">
                  <Input
                    label="Login"
                    type="text"
                    id="UserManagement_AddAdminUser_Login"
                    value={loginSearchInput}
                    onChange={user => setLoginSearchInput(user.target.value)}
                    placeholder="Start entering login email..."
                  />
                </div>
              ) : (
                <div style={{ position: 'relative' }} className="column is-5">
                  <Input
                    label="Login"
                    type="text"
                    id="UserManagement_AddAdminUser_Login"
                    value={searchLoginAsPartner}
                    onChange={user => {
                      setEmailError(false);
                      setSearchLoginAsPartner(user.target.value);
                    }}
                    placeholder="Enter an email connected to KRAVEiN"
                  />
                  <button
                    type="button"
                    className="button is-primary"
                    style={{
                      position: 'absolute',
                      top: '38px',
                      right: '-90px',
                    }}
                    onClick={async () => {
                      const schema = yup.object().shape({
                        email: yup.string().email(),
                      });

                      const isValidEmail = await schema.isValid({
                        email: searchLoginAsPartner.trim(),
                      });

                      if (!isValidEmail) {
                        setEmailError(true);
                      } else {
                        setLoginSearchInput(searchLoginAsPartner);
                        changeRandomId();
                      }
                    }}
                  >
                    <span className="has-text-weight-semibold">Search</span>
                  </button>
                  {emailError && (
                    <p
                      style={{
                        fontSize: '10px',
                        position: 'absolute',
                        bottom: '0',
                        color: 'red',
                        marginLeft: '2px',
                      }}
                    >
                      Please enter a valid email
                    </p>
                  )}
                </div>
              )}
              {!securityLoading && !isJustAPartner(role) && (
                <>
                  <div className="column is-3">
                    <Input
                      label="First Name"
                      type="text"
                      id="UserManagement_AddAdminUser_FirstName"
                      value={firstNameSearchInput}
                      onChange={user =>
                        setFirstNameSearchInput(user.target.value)
                      }
                      placeholder="Start entering first name..."
                    />
                  </div>
                  <div className="column is-3">
                    <Input
                      label="Last Name"
                      type="text"
                      id="UserManagement_AddAdminUser_LastName"
                      value={lastNameSearchInput}
                      onChange={user =>
                        setLastNameSearchInput(user.target.value)
                      }
                      placeholder="Start entering last name..."
                    />
                  </div>
                  <div className="column is-3">
                    <Input
                      label="Display Name"
                      type="text"
                      id="UserManagement_AddAdminUser_DisplayName"
                      value={displayNameSearchInput}
                      onChange={user =>
                        setDisplayNameSearchInput(user.target.value)
                      }
                      placeholder="Start entering display name..."
                    />
                  </div>
                </>
              )}
            </div>
          </BoxWrapper>
        </Wrapper>
      )}
    </ACLSConsumer>
  );
};

export default Search;
