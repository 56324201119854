import React from 'react';
import { Link } from 'react-router-dom';
import DeleteEmailTemplate from './DeleteEmailTemplate';

const EmailTemplateList = ({ notification, refetch }) => (
  <tr>
    <td>
      <Link
        to={`/update-email-notification-template/${notification.notification_id}/`}
      >
        {notification.name}
      </Link>
    </td>
    <td>{notification.notification_platform}</td>
    <td>{notification.status}</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>
      <DeleteEmailTemplate
        notificationId={notification.notification_id}
        refetch={refetch}
      />
    </td>
  </tr>
);

export default EmailTemplateList;
