import gql from 'graphql-tag';
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';
import { isNull } from 'lodash';
import client from '../../../utils/apolloClient';

const searchOrdersQuery = gql`
  query searchOrders($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
        scheduled_date_time
        is_scheduled
        user_id
        estimated_completion_time
        no_of_attendees
        status
        age_above_18
        order_contains_alcohol
        table_number
        table_name
        pickup_method
        vehicle_information {
          car_detail
          license_plate
        }
        refunds {
          refund_id
          refund_amount
          refund_reason
          status
          refund_from_place
          refund_application_fee
          created_at
          created_by
          note {
            value
          }
        }
        payment {
          payment_method_type
          payment_method
          payment_method_id
          payment_intent_id
          payment_status
          payment_card {
            brand
            country
            last4
          }
          payment_at
        }
        menu {
          name
        }
        place {
          latitude
          longitude
          name
          place_id
        }
        large_order
        include_cuttlery
        address {
          type
          address_line_1
          address_line_2
          city
          state
          post_code
          country
          delivery_option {
            type
            caption
          }
        }
        place_id
        order_reference
        service_type
        received {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        confirmed {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        ready {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        complete {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        cancelled {
          created_by
          created_at
          status
          user_type
          user_data {
            first_name
            last_name
            email
          }
          note {
            type
            value
            date
          }
        }
        service_type_setting {
          display_table_number
          allow_pickup_options
          pickup_method
          pickup_instructions
          include_vehicle_information
        }

        overall_quantity
        line_item {
          name
          variant_name
          price
          overall_price
          modifier {
            name
            variant_name
            price
            price_per_item
            net_price_per_item
            quantity
          }
          special_request
          quantity
          sold_out
        }
        contact {
          contact_name
          contact_phone
        }
        billing {
          delivery_fee_discount
          sub_total
          order_total
          deal_discount
          payment_gateway_fee
          adjustment_amount
          order_amount
          delivery_fee
          charge
          charges_list {
            name
            value
            is_payment_gateway_fee
          }
          surcharge_list {
            name
            charge
            menu_fee_id
          }
        }
        adjustments {
          name
          amount
          note
          adjusted_at
        }
        estimates {
          type
          value_in_mins
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const actionOrderMutation = gql`
  mutation actionOrder($input: NavigateMenuOrderInput) {
    action_menu_order(input: $input) {
      menu_order_id
      scheduled_date_time
      is_scheduled
      estimated_completion_time
      status
      table_number
      pickup_method
      vehicle_information {
        car_detail
        license_plate
      }
      payment {
        payment_method_type
        payment_method
        payment_method_id
        payment_intent_id
        payment_status
        payment_card {
          brand
          country
          last4
        }
        payment_at
      }
      menu_name
      large_order
      no_of_attendees
      address {
        type
        address_line_1
        address_line_2
        city
        state
        post_code
        country
      }
      place_id
      include_cuttlery
      order_reference
      service_type
      received {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }
      confirmed {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }
      ready {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }
      complete {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
      }
      cancelled {
        created_by
        created_at
        status
        user_type
        note {
          type
          value
          date
        }
        user_data {
          first_name
          last_name
          email
        }
      }

      overall_quantity
      line_item {
        name
        variant_name
        price
        overall_price
        modifier {
          name
          variant_name
          price
          price_per_item
          net_price_per_item
          quantity
        }
        special_request
        quantity
        sold_out
      }
      contact {
        contact_name
        contact_phone
      }
      billing {
        delivery_fee_discount
        sub_total
        order_total
        deal_discount
        payment_gateway_fee
        adjustment_amount
        order_amount
        delivery_fee
        charge
        charges_list {
          name
          value
          is_payment_gateway_fee
        }
        surcharge_list {
          name
          charge
          menu_fee_id
        }
      }
      adjustments {
        name
        amount
        note
        adjusted_at
      }
      estimates {
        reason
        type
        value_in_mins
      }
      audit {
        created_at
        created_by
      }
      error {
        description
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getReceivedOrders = ({
  placeId,
  status,
  dateRange,
  userId,
  size = 50,
}) =>
  new Promise((resolve, reject) => {
    const menuFilter = {
      status,
    };
    Object.assign(
      menuFilter,
      dateRange && {
        date_range: dateRange,
      },
    );
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchOrdersQuery,
        variables: {
          input: {
            size,
            sort: 'CREATED_AT_ASC',
            filter: {
              menu_order_filter: menuFilter,
              place_filter: { place_id: [placeId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getScheduledOrder = ({ place, placeId, userId }) =>
  new Promise((resolve, reject) => {
    const lat = parseFloat(place.latitude).toFixed(5);
    const long = parseFloat(place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    moment.tz.setDefault(timeZone);
    const startDate = moment()
      .tz(timeZone)
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    const endDate = moment()
      .tz(timeZone)
      .add(20, 'day')
      .endOf('day')
      .toISOString();
    const dateRange = {
      start_date: startDate,
      end_date: endDate,
    };

    const menuOrderFilter = {
      status: ['CONFIRMED'],
      is_scheduled: true,
      date_range: dateRange,
    };

    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchOrdersQuery,
        variables: {
          input: {
            size: 50,
            sort: 'ESTIMATED_COMPLETION_TIME_ASC',
            filter: {
              menu_order_filter: menuOrderFilter,
              place_filter: { place_id: [placeId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const fetchOrder = ({ placeId, menuOrderId, userId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchOrdersQuery,
        variables: {
          input: {
            filter: {
              menu_order_filter: { menu_order_id: menuOrderId },
              place_filter: { place_id: [placeId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          Array.isArray(data.search_menu_orders.menu_order_listing) &&
          data.search_menu_orders.menu_order_listing[0]
        ) {
          resolve(data.search_menu_orders.menu_order_listing[0]);
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getCancelledOrders = ({ placeId, status, dateRange, userId }) =>
  new Promise((resolve, reject) => {
    const menuFilter = {
      status,
    };
    Object.assign(
      menuFilter,
      dateRange && {
        date_range: dateRange,
      },
    );
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchOrdersQuery,
        variables: {
          input: {
            size: 50,
            sort: 'CREATED_AT_ASC',
            filter: {
              menu_order_filter: menuFilter,
              place_filter: { place_id: [placeId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getCompleteddOrders = ({ placeId, status, dateRange, userId }) =>
  new Promise((resolve, reject) => {
    const menuFilter = {
      status,
    };
    Object.assign(
      menuFilter,
      dateRange && {
        date_range: dateRange,
      },
    );
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchOrdersQuery,
        variables: {
          input: {
            size: 50,
            sort: 'CREATED_AT_ASC',
            filter: {
              menu_order_filter: menuFilter,
              place_filter: { place_id: [placeId] },
            },
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_listing
        ) {
          resolve(data.search_menu_orders.menu_order_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

// order count

const searchOrdersCountQuery = gql`
  query searchOrdersCountQuery($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_listing {
        menu_order_id
      }
    }
  }
`;

const getOrderCount2 = ({
  placeId,
  status,
  dateRange,
  userId,
  sort,
  isScheduled,
  size = 50,
}) =>
  new Promise((resolve, reject) => {
    const menuFilter = {
      status,
    };
    Object.assign(
      menuFilter,
      dateRange && {
        date_range: dateRange,
      },
      isScheduled && {
        is_scheduled: true,
      },
    );
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchOrdersCountQuery,
        variables: {
          input: {
            filter: {
              menu_order_filter: menuFilter,
              place_filter: { place_id: [placeId] },
            },
            sort,
            size,
            user_id: userId,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          Array.isArray(data.search_menu_orders.menu_order_listing)
        ) {
          resolve({ count: data.search_menu_orders.menu_order_listing.length });
        } else {
          resolve({ count: 0 });
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const fetchOrderCounts = async ({
  place,
  placeId,
  dateRange,
  userId,
}) => {
  const lat = parseFloat(place.latitude).toFixed(5);
  const long = parseFloat(place.longitude).toFixed(5);
  const timeZone = tzLookup(lat, long);
  moment.tz.setDefault(timeZone);
  const startDate = moment()
    .tz(timeZone)
    .add(1, 'day')
    .startOf('day')
    .toISOString();
  const endDate = moment()
    .tz(timeZone)
    .add(20, 'day')
    .endOf('day')
    .toISOString();
  const scheduledDateRange = {
    start_date: startDate,
    end_date: endDate,
  };

  // eslint-disable-next-line prefer-const
  let countObj = {
    receivedOrder: 0,
    readyOrder: 0,
    cancelOrder: 0,
    confirmedOrder: 0,
    completedOrder: 0,
    scheduledOrder: 0,
  };
  try {
    const counts = await Promise.all([
      getOrderCount2({
        status: ['RECEIVED'],
        placeId,
        userId,
        sort: 'CREATED_AT_ASC',
      }),
      getOrderCount2({
        status: ['READY'],
        placeId,
        userId,
        sort: 'CREATED_AT_ASC',
        size: 30,
      }),
      getOrderCount2({
        status: ['CANCELLED'],
        placeId,
        dateRange,
        userId,
        sort: 'CREATED_AT_ASC',
      }),
      getOrderCount2({
        status: ['CONFIRMED'],
        placeId,
        userId,
        sort: 'CREATED_AT_ASC',
      }),
      getOrderCount2({
        status: ['COMPLETE'],
        placeId,
        dateRange,
        userId,
        sort: 'CREATED_AT_ASC',
      }),
      getOrderCount2({
        status: ['CONFIRMED'],
        placeId,
        dateRange: scheduledDateRange,
        userId,
        sort: 'ESTIMATED_COMPLETION_TIME_ASC',
        isScheduled: true,
      }),
    ]);
    const [recieved, ready, cancel, confirm, complete, schedule] = counts;
    Object.assign(
      countObj,
      recieved && { receivedOrder: recieved.count },
      ready && { readyOrder: ready.count },
      cancel && { cancelOrder: cancel.count },
      confirm && { confirmedOrder: confirm.count },
      complete && { completedOrder: complete.count },
      schedule && { scheduledOrder: schedule.count },
    );
  } catch (error) {
    console.log('error will querying for order count...', error);
  }
  return countObj;
};

export const handleOrderAction = ({
  menuId,
  userId,
  action,
  delay,
  note = null,
  adjustments = null,
  reason,
}) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: actionOrderMutation,
        variables: {
          input: {
            menu_order_id: menuId,
            action,
            user_id: userId,
            delayed_estimate: delay,
            delayed_reason: reason,
            note,
            adjustments,
          },
        },
      })
      .then(({ data }) => {
        if (!isNull(data.action_menu_order.error)) {
          reject(data.action_menu_order.error);
        } else {
          resolve(data.action_menu_order);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchLinksQuery = gql`
  query searchLinks($input: SearchInput) {
    search_menu_item_links(input: $input) {
      menu_item_link_listing {
        item_link_id
        place_id
        menu_section {
          menu_section_id
        }
        variant {
          prompt
          variants {
            variant_id
            variant_price
            availability_status
          }
        }
        menu_item {
          menu_item_id
          name
          price
          image_url
          description
          calories
          kilojoules
          popular
          variant {
            prompt
            variants {
              variant_id
              variant_name
              variant_price
              availability_status
            }
          }
        }
      }
    }
  }
`;

export const getLinks = ({ menuId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        query: searchLinksQuery,
        variables: {
          input: {
            filter: { menu_item_link_filter: { menu_id: menuId } },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_item_links &&
          data.search_menu_item_links.menu_item_link_listing
        ) {
          resolve(data.search_menu_item_links.menu_item_link_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const orderStartStopMutation = gql`
  mutation orderStartStop($input: ActionMenuInput) {
    action_menu(input: $input) {
      error {
        code
        description
      }
    }
  }
`;

export const handleOrderStartStopAction = ({ userId, action, menuId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: orderStartStopMutation,
        variables: {
          input: {
            user_id: userId,
            action,
            menu_id: menuId,
          },
        },
      })
      .then(({ data }) => {
        console.log('data', data);
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const searchOrderCountQuery = gql`
  query searchOrderCount($input: SearchInput) {
    search_menu_orders(input: $input) {
      menu_order_aggregation {
        total_order_by_status {
          status
          count
        }
      }
    }
  }
`;

export const getOrderCount = ({ placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPublic
      .query({
        client: client.clientPublic,
        query: searchOrderCountQuery,
        variables: {
          input: {
            filter: {
              menu_order_filter: {
                place_id: placeId,
              },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (
          data.search_menu_orders &&
          data.search_menu_orders.menu_order_aggregation
        ) {
          resolve(
            data.search_menu_orders.menu_order_aggregation
              .total_order_by_status,
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const parsedTime = () => {
  const timeZone = tzLookup(-37.8597673, 144.9836167);
  const time = moment.tz('2020-08-16T19:52:21.883Z', timeZone);
  console.log('time', time);
  return 123;
};

export const getMinutes = time => {
  const now = moment();
  const startTime = moment(time);
  const duration = moment.duration(now.diff(startTime));
  const minutes = duration.asMinutes();
  console.log('minutes', minutes);
  return minutes.toFixed(1);
};

export const parsedAmount = amount => {
  const actualAmount = Math.sign(amount);
  if (actualAmount === -1) {
    return `-$${amount.slice(1)}`;
  }
  return `$${amount}`;
};

const searchMenusQuery = gql`
  query searchMenus($input: SearchInput) {
    search_menus(input: $input) {
      menu_listing {
        menu_id
        name
        service_type
        service_type_setting {
          service_type_setting_id
        }
        hours
        stopped_hour {
          start
          end
        }
        status
        menu_hour {
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
        custom_hour {
          date
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
        holiday_hour {
          day
          is_active
          option {
            start
            end
            start_in_sec
            end_in_sec
          }
        }
      }
    }
  }
`;

export const getMenus = ({ userId, placeId }) =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchMenusQuery,
        variables: {
          input: {
            user_id: userId,
            filter: {
              place_filter: {
                place_id: [placeId],
              },
              menu_filter: {
                status: 'ACTIVE',
                service_types: ['Pickup', 'Dinein', 'Delivery'],
                has_hours: true,
              },
              service_type_setting_filter: { status: 'ACTIVE' },
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menus && data.search_menus.menu_listing) {
          resolve(
            data.search_menus.menu_listing.filter(menu => !!menu.menu_hour),
          );
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
