import React from 'react';
import { first } from 'lodash';
import moment from 'moment';

const PlaceList = ({ place, handleSelect, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === place.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <React.Fragment>
      <td>
        <a onClick={() => handleSelect(place)}>{place.name}</a>
      </td>
      <td>{place.city}</td>
      <td>{user ? <p> {user.full_name}</p> : place.audit.updated_by}</td>
      <td>{moment(place.audit.updated_at).format('lll')}</td>
    </React.Fragment>
  );
};

export default PlaceList;
