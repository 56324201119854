import React, { useState } from 'react';
import styled from 'styled-components';
import { Title, Table } from 'rbx';
import moment from 'moment';
import { isNull } from 'lodash';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { getZoneTime } from '../../../utils/eventHelper';
import {
  Table as CustomTable,
  Box,
  Message,
  Loading,
} from '../../../components/elements';

const HeaderWrapper = styled.div`
  padding: 1rem;
`;

const getTicketStatus = ticket => {
  if (ticket.quantity === ticket.sold) {
    return 'SOLD OUT';
  }
  return ticket.status;
};

const eventTicketsQuery = gql`
  query eventTicketsQuery($eventOccurrenceId: String, $sort: String) {
    fetch_event_occurrence_ticket(
      input: { sort: $sort, event_occurrence_id: $eventOccurrenceId }
    ) {
      ticket_id
      status
      sales_method
      name
      type
      sold
      description
      quantity
      sale_start
      sale_end
      price
    }
  }
`;

const TicketList = ({ event, eventOccurrenceId }) => {
  const [sort, setSort] = useState('CREATED_DESC');
  const [quantitySort, setQuantitySort] = useState('AES');
  const [priceSort, setPriceSort] = useState('AES');
  const [endDateSort, setEndDateSort] = useState('AES');
  const [startDateSort, setStartDateSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');
  const [soldSort, setSoldSort] = useState('AES');
  const [ticketCategorySort, setTicketCategorySort] = useState('AES');

  return (
    <Box>
      <HeaderWrapper>
        <Title size={1}>Booking By Ticket Category</Title>
      </HeaderWrapper>
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setTicketCategorySort(
                        ticketCategorySort === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        ticketCategorySort === 'AES'
                          ? 'TICKET_NAME_DESC'
                          : 'TICKET_NAME_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        ticketCategorySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Ticket Category
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setQuantitySort(quantitySort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        quantitySort === 'AES'
                          ? 'QUANTITY_DESC'
                          : 'QUANTITY_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        quantitySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Quantity
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setSoldSort(soldSort === 'AES' ? 'DES' : 'AES');
                      setSort(soldSort === 'AES' ? 'SOLD_DESC' : 'SOLD_ASC');
                    }}
                  >
                    <i
                      className={
                        soldSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Sold
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setPriceSort(priceSort === 'AES' ? 'DES' : 'AES');
                      setSort(priceSort === 'AES' ? 'PRICE_DESC' : 'PRICE_ASC');
                    }}
                  >
                    <i
                      className={
                        priceSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Price
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setPriceSort(priceSort === 'AES' ? 'DES' : 'AES');
                      setSort(priceSort === 'AES' ? 'PRICE_DESC' : 'PRICE_ASC');
                    }}
                  >
                    <i
                      className={
                        priceSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Sale Value
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_DESC',
                      );
                    }}
                  >
                    <i
                      className={
                        statusSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Status
                </span>
                <br />
                {/* <CustomSelect
                options={[
                  { value: '', label: 'Select..' },
                  { value: 'Available ', label: 'Available' },
                  { value: 'Hidden', label: 'Hidden' },
                ]}
              /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setStartDateSort(startDateSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        startDateSort === 'AES'
                          ? 'SALE_START_DESC'
                          : 'SALE_START_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        startDateSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Sale Start Date and Time
                </span>
                <br />
                {/* <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setEndDateSort(endDateSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        endDateSort === 'AES'
                          ? 'SALE_END_DESC'
                          : 'SALE_END_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        endDateSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Sale End Date and Time
                </span>
                <br />
                {/* <CustomInput />  */}
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Query
            query={eventTicketsQuery}
            variables={{ sort, eventOccurrenceId }}
            fetchPolicy="network-only"
          >
            {({
              data: ticketsData,
              loading: ticketsLoading,
              error: ticketsQueryError,
            }) => {
              if (ticketsQueryError) {
                return <Message>{ticketsQueryError.message}</Message>;
              }
              if (ticketsLoading) {
                return <Loading />;
              }

              const { fetch_event_occurrence_ticket: tickets } = ticketsData;

              return (
                <>
                  {tickets.map(ticket => (
                    <Table.Row>
                      <Table.Cell>{ticket.name}</Table.Cell>
                      <Table.Cell>{ticket.quantity}</Table.Cell>
                      <Table.Cell>
                        {isNull(ticket.sold) ? 0 : ticket.sold}
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        ${parseFloat(ticket.price).toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        ${parseFloat(ticket.price * ticket.sold).toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>{getTicketStatus(ticket)}</Table.Cell>
                      <Table.Cell>
                        {`${moment(
                          getZoneTime(
                            ticket.sale_start,
                            event.latitude,
                            event.longitude,
                          ),
                        ).format('lll')}`}
                      </Table.Cell>
                      <Table.Cell>
                        {isNull(ticket.sale_end)
                          ? `${moment(
                              getZoneTime(
                                event.end,
                                event.latitude,
                                event.longitude,
                              ),
                            ).format('lll')}`
                          : `${moment(
                              getZoneTime(
                                ticket.sale_end,
                                event.latitude,
                                event.longitude,
                              ),
                            ).format('lll')}`}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              );
            }}
          </Query>
        </Table.Body>
      </CustomTable>
    </Box>
  );
};

export default TicketList;
