import React, { useState } from 'react';
import styled from 'styled-components';

import ModifierItem from './ModifierItem';

const Container = styled.div``;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.waterMarkGrey};
  padding: 1rem 1rem;
  align-items: center;
  pointer-events: ${({ availability }) =>
    availability === 'UNAVAILABLE' ? 'none' : 'all'};
  padding-left: 2rem;
`;

const Item = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
`;

const IconStyled = styled.i`
  margin-right: 10px;
`;

const ModifierGroup = ({
  group,
  onHandleLinkModifierSubmission,
  originalModifier,
  linkRootAVDate,
}) => {
  const [isActive, setIsActive] = useState(false);
  console.log('originalModifier', originalModifier);
  return (
    <>
      <Container
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <Flex availability="">
          <Item>
            <>
              {isActive ? (
                <IconStyled className="fas fa-chevron-down" />
              ) : (
                <IconStyled className="fas fa-chevron-right" />
              )}
            </>

            <div />
            <div>
              <h4 className="title is-4">{group.name}</h4>
            </div>
          </Item>
        </Flex>
      </Container>
      {isActive &&
        group.modifier.map(modifier => (
          <ModifierItem
            key={modifier.menu_item.menu_item_id}
            modifier_group_id={group.modifier_group_id}
            modifier={modifier}
            originalModifiers={originalModifier}
            onHandleLinkModifierSubmission={onHandleLinkModifierSubmission}
            linkRootAVDate={linkRootAVDate}
          />
        ))}
    </>
  );
};

export default ModifierGroup;
