import React, { useState } from 'react';
import styled from 'styled-components';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { first, groupBy, mapKeys, omit, isNull, times, forEach } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import BookingDetailsForm from './BookingDetailsForm';
import TicketSelection from './TicketSelection';
import AttendeeForm from './AttendeeForm';
import BookingResult from './BookingResult';
import { Message, Loading } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  padding: 0rem 1rem;
`;

const allEventQuery = gql`
  query allEventQuery($eventId: String) {
    fetch_event(input: { event_id: $eventId }) {
      place_id
      event_id
      status
      listing_type
      start
      end
      place_name
      venue_name
      name
      description
      tagline
      tag
      slug
      voucher_count
      minimum_price
      maximum_price
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      fee {
        name
        value
        service_type
        applies_to
        sales_method
      }
    }

    fetch_event_occurrence(input: { event_id: $eventId }) {
      event_id
      event_occurrence_id
      start
      end
      sold_tickets
      total_tickets
      remaining_tickets
      display_remaining_ticket
    }
  }
`;

const eventBookingsQuery = gql`
  query {
    search_event_bookings {
      event_booking_listing {
        place_id
        event_id
        booking_number
        url
      }
    }
  }
`;

const eventTicketsQuery = gql`
  query eventTicketsQuery($eventOccurrenceId: String) {
    fetch_event_occurrence_ticket(
      input: { event_occurrence_id: $eventOccurrenceId }
    ) {
      event_occurrence_id
      ticket_id
      status
      sales_method
      name
      type
      description
      quantity
      sale_start
      sale_end
      minimum
      maximum
      sold
      price
      fee {
        name
        value
        service_type
        applies_to
        sales_method
      }
      attribute {
        name
        label
        required
        values
        validation
        display_order
      }
    }
  }
`;

const createBookingMutation = gql`
  mutation createBooking($input: EventBookingInput) {
    create_event_booking(input: $input) {
      place_id
      event_id
      event_occurrence_id
      booking_id
      booking_number
      booking_status
      timestamp
      total_fee
      transaction_fee
      booking_fee
      total_price
      sub_total_price
      fee {
        name
        label
        value
        applies_to
      }
      url
      ticket_record {
        ticket_record_id
        ticket_number
        barcode_number
        qr_code
        ticket_status
        ticket_name
        ticket_type
        is_refund
        price
        amount_refund
        fee {
          name
          label
          value
          applies_to
        }
        attendee {
          attendee_name
          attendee_email
          attendee_age
          attendee_gender
          attendee_phone
        }
      }
      error {
        code
        description
      }
    }
  }
`;

const parseOfflineTicket = listOfTickets => {
  const finalTicket = [];
  forEach(listOfTickets, item => {
    forEach(item.sales_method, item2 => {
      if (item2 !== 'online') {
        finalTicket.push(item);
      }
    });
  });
  return finalTicket;
};

const SellTickets = ({ match }) => {
  const [step, setStep] = useState(1);
  const [purchaser, setPurchaser] = useState({});
  const [paymentType, setPaymentType] = useState('');
  const [subTotalPrice, setSubTotalPrice] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [ticketSelections, setTicketSelections] = useState([]);
  const [bookingFee, setBookingFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [bookingDetails, setBookingDetails] = useState();
  const [bookingFeeOfEvent, setBookingFeeOfEvent] = useState();
  const [
    checkSelectionHaveAttendeeDetailsOrNot,
    setCheckSelectionHaveAttendeeDetailsOrNot,
  ] = useState(false);

  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <Query
        client={client.clientPublic}
        query={allEventQuery}
        variables={{
          eventId: match.params.eventId,
        }}
        fetchPolicy="network-only"
      >
        {({ data: allEventData, loading: queryLoading, error: queryError }) => {
          if (queryError) {
            return <Message>{queryError.message}</Message>;
          }
          if (queryLoading) {
            return <Loading />;
          }
          const { fetch_event, fetch_event_occurrence } = allEventData;

          return (
            <Query
              client={client.clientPrivate}
              query={eventBookingsQuery}
              fetchPolicy="network-only"
            >
              {({
                data: bookingData,
                loading: bookingLoading,
                error: bookingError,
              }) => {
                if (bookingLoading) {
                  return <Loading />;
                }
                if (bookingError) {
                  return <Message>{bookingError.message}</Message>;
                }
                const { search_event_bookings } = bookingData;
                return (
                  <Query
                    query={eventTicketsQuery}
                    variables={{
                      eventOccurrenceId:
                        fetch_event_occurrence.length !== 0
                          ? first(fetch_event_occurrence).event_occurrence_id
                          : '',
                    }}
                    fetchPolicy="network-only"
                  >
                    {({
                      data: ticketsData,
                      loading: ticketsLoading,
                      error: ticketsQueryError,
                    }) => {
                      if (ticketsQueryError) {
                        return <Message>{ticketsQueryError.message}</Message>;
                      }
                      if (ticketsLoading) {
                        return <Loading />;
                      }

                      const { fetch_event_occurrence_ticket } = ticketsData;

                      const bookingFeeValues =
                        fetch_event.fee.length !== 0
                          ? fetch_event.fee.filter(
                              item =>
                                item.name === 'booking_fee' &&
                                item.sales_method === 'offline',
                            )
                          : [];

                      const eventBookingFee =
                        bookingFeeValues.length !== 0
                          ? first(bookingFeeValues).value
                          : 0;

                      return (
                        <Container>
                          <Header
                            heading={`${fetch_event.name} ( Public event )`}
                            subHeading1={`${fetch_event.place_name} | ${fetch_event.state}`}
                            subHeading2={`${fetch_event.address_line_1} | ${fetch_event.city}, ${fetch_event.post_code} | ${fetch_event.country}`}
                            subHeading3={moment(fetch_event.start).format(
                              'LLLL',
                            )}
                            paymentType={paymentType}
                            setPaymentType={setPaymentType}
                            step={step}
                            totalQuantity={totalQuantity}
                          />
                          {step === 1 && (
                            <BookingDetailsForm
                              onSubmit={values => {
                                const data = {
                                  first_name: values.firstName,
                                  last_name: values.lastName,
                                  email: values.email,
                                  phone: values.mobile,
                                };
                                setPurchaser(data);
                                setStep(2);
                              }}
                            />
                          )}

                          <Mutation
                            client={client.clientPrivate}
                            mutation={createBookingMutation}
                            onCompleted={data => {
                              if (isNull(data.create_event_booking)) {
                                toast.error('Something went wrong');
                              } else if (
                                !isNull(data.create_event_booking.error)
                              ) {
                                data.create_event_booking.error.map(item =>
                                  toast.error(item.description),
                                );
                              } else {
                                const customTickets = [];
                                const groupTickets = groupBy(
                                  data.create_event_booking.ticket_record,
                                  'ticket_name',
                                );
                                const values = omit(
                                  data.create_event_booking,
                                  'ticket_record',
                                );
                                mapKeys(groupTickets, item => {
                                  customTickets.push(item);
                                });

                                setBookingDetails({
                                  ...values,
                                  ticket_record: customTickets,
                                });
                                setStep(4);
                              }
                            }}
                          >
                            {(
                              create_event_booking,
                              { loading: bookingLoading, error: bookingError },
                            ) => (
                              <React.Fragment>
                                {step === 2 && (
                                  <TicketSelection
                                    occurrence={first(fetch_event_occurrence)}
                                    event={fetch_event}
                                    eventBookingFee={eventBookingFee}
                                    bookingFeeOfEvent={bookingFeeOfEvent}
                                    setBookingFeeOfEvent={setBookingFeeOfEvent}
                                    tickets={parseOfflineTicket(
                                      fetch_event_occurrence_ticket,
                                    )}
                                    subTotalPrice={subTotalPrice}
                                    setSubTotalPrice={setSubTotalPrice}
                                    totalQuantity={totalQuantity}
                                    setTotalQuantity={setTotalQuantity}
                                    ticketSelections={ticketSelections}
                                    setTicketSelections={setTicketSelections}
                                    bookingFee={bookingFee}
                                    setBookingFee={setBookingFee}
                                    discount={discount}
                                    setDiscount={setDiscount}
                                    isCouponApplied={isCouponApplied}
                                    setCouponCode={setCouponCode}
                                    setIsCouponApplied={setIsCouponApplied}
                                    checkSelectionHaveAttendeeDetailsOrNot={
                                      checkSelectionHaveAttendeeDetailsOrNot
                                    }
                                    setCheckSelectionHaveAttendeeDetailsOrNot={
                                      setCheckSelectionHaveAttendeeDetailsOrNot
                                    }
                                    loading={bookingLoading}
                                    back={() => setStep(1)}
                                    onHandleConfirmBooking={() => {
                                      if (
                                        paymentType === '' &&
                                        subTotalPrice !== 0
                                      ) {
                                        toast.error(
                                          'Please select payment mode to go forwards',
                                        );
                                      } else if (
                                        !checkSelectionHaveAttendeeDetailsOrNot
                                      ) {
                                        const tickets = ticketSelections.map(
                                          item => ({
                                            ticket_id: item.id,
                                            quantity: item.quantity,
                                            attendee: times(item.quantity).map(
                                              () => ({
                                                attendee_name: null,
                                                attendee_email: null,
                                                attendee_phone: null,
                                                attendee_age: null,
                                                attendee_gender: null,
                                              }),
                                            ),
                                          }),
                                        );

                                        const input = {
                                          user_id: userId,
                                          place_id: fetch_event.place_id,
                                          event_id: fetch_event.event_id,
                                          event_occurrence_id: first(
                                            fetch_event_occurrence,
                                          ).event_occurrence_id,
                                          purchaser,
                                          voucher_code:
                                            couponCode !== ''
                                              ? [couponCode]
                                              : [],
                                          sales_method: 'offline',
                                          override_discount_value:
                                            couponCode !== '' ? 0 : discount,
                                          ticket: tickets,
                                          payment: [
                                            {
                                              type: paymentType,
                                            },
                                          ],
                                        };

                                        create_event_booking({
                                          variables: { input },
                                        });
                                      } else if (totalQuantity === 0) {
                                        toast.error(
                                          'Please select at least one ticket to go forwards',
                                        );
                                      } else if (totalQuantity >= 50) {
                                        toast.error(
                                          'Maximum 50 tickets allow for booking',
                                        );
                                      } else {
                                        setStep(3);
                                      }
                                    }}
                                  />
                                )}
                                {step === 3 && (
                                  <AttendeeForm
                                    ticketSelections={ticketSelections}
                                    totalQuantity={totalQuantity}
                                    loading={bookingLoading}
                                    orderTotal={
                                      subTotalPrice +
                                      bookingFee -
                                      discount +
                                      bookingFeeOfEvent
                                    }
                                    onSubmit={values => {
                                      const tickets = values.ticketSelections.map(
                                        ticket => {
                                          const customTicket = {
                                            ticket_id: ticket.ticket_id,
                                            quantity: ticket.quantity,
                                            attendee: ticket.attendees.map(
                                              attendee => {
                                                const name = attendee.attributes.filter(
                                                  item => item.name === 'name',
                                                );
                                                const email = attendee.attributes.filter(
                                                  item => item.name === 'email',
                                                );
                                                const mobile = attendee.attributes.filter(
                                                  item =>
                                                    item.name === 'mobile',
                                                );
                                                const age = attendee.attributes.filter(
                                                  item => item.name === 'age',
                                                );
                                                const gender = attendee.attributes.filter(
                                                  item =>
                                                    item.name === 'gender',
                                                );

                                                return {
                                                  attendee_name:
                                                    name.length !== 0
                                                      ? first(name).values
                                                      : null,
                                                  attendee_email:
                                                    email.length !== 0
                                                      ? first(email).values
                                                      : null,
                                                  attendee_age:
                                                    age.length !== 0
                                                      ? parseInt(
                                                          first(age).values,
                                                          10,
                                                        )
                                                      : null,
                                                  attendee_gender:
                                                    gender.length !== 0
                                                      ? first(gender).values
                                                      : null,
                                                  attendee_phone:
                                                    mobile.length !== 0
                                                      ? first(mobile).values
                                                      : null,
                                                };
                                              },
                                            ),
                                          };
                                          return customTicket;
                                        },
                                      );

                                      const input = {
                                        user_id: userId,
                                        place_id: fetch_event.place_id,
                                        event_id: fetch_event.event_id,
                                        event_occurrence_id: first(
                                          fetch_event_occurrence,
                                        ).event_occurrence_id,
                                        purchaser,
                                        voucher_code:
                                          couponCode !== '' ? [couponCode] : [],
                                        sales_method: 'offline',
                                        override_discount_value:
                                          couponCode !== '' ? 0 : discount,
                                        ticket: tickets,
                                        payment: [
                                          {
                                            type: paymentType,
                                          },
                                        ],
                                      };

                                      create_event_booking({
                                        variables: { input },
                                      });
                                    }}
                                    back={() => setStep(2)}
                                  />
                                )}
                              </React.Fragment>
                            )}
                          </Mutation>

                          {step === 4 && bookingDetails && (
                            <BookingResult
                              event={fetch_event}
                              eventStartDate={fetch_event.start}
                              bookingDetails={bookingDetails}
                              ticketSelections={ticketSelections}
                              booking={
                                search_event_bookings.event_booking_listing
                              }
                              discount={discount}
                            />
                          )}
                          {/* <hr />
                    <Footer /> */}
                        </Container>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default SellTickets;
