import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { isNull } from 'lodash';
import { ACLSConsumer } from '../../utils/aclsContext';
import Can from '../../utils/Can';

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '1px',
    // height: '29px',
    fontSize: '0.8rem',
  }),
  input: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    minHeight: '1px',
    height: '11px',
  }),
  clearIndicator: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: '1px',
    // height: '27px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: provided => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px',
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8rem',
  }),
};

const CreatableSelectInputs = ({
  label,
  value,
  onChange,
  suggestions,
  errors,
  touched,
  disabled,
  required,
  action,
  ...props
}) => (
  <div className="field">
    <label className="label">
      {label} {required && <span className="has-text-danger">*</span>}
    </label>
    <div className="control">
      {action ? (
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={action}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <CreatableSelect
                  styles={customStyles}
                  value={!isNull(value) ? value : []}
                  closeMenuOnSelect={false}
                  isMulti
                  className="basic-multi-select"
                  options={suggestions}
                  onChange={onChange}
                  isDisabled={disabled}
                  {...props}
                />
              )}
              no={() => (
                <CreatableSelect
                  styles={customStyles}
                  value={!isNull(value) ? value : []}
                  closeMenuOnSelect={false}
                  isMulti
                  className="basic-multi-select"
                  options={suggestions}
                  onChange={onChange}
                  isDisabled
                  {...props}
                />
              )}
            />
          )}
        </ACLSConsumer>
      ) : (
        <CreatableSelect
          styles={customStyles}
          value={!isNull(value) ? value : []}
          closeMenuOnSelect={false}
          isMulti
          className="basic-multi-select"
          options={suggestions}
          onChange={onChange}
          isDisabled={disabled}
          {...props}
        />
      )}
    </div>
    {errors && touched && <p className="help is-danger is-size-4">{errors}</p>}
  </div>
);

export default CreatableSelectInputs;
