import React from 'react';
import { Link } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  heading,
  placeName,
  loading,
  handleSubmit,
  next,
  prev,
  activeTab,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>{heading}</Title>
      </Level.Item>
      <div>
        <SubHeading className="is-size-2">
          David(raj_mallampati@hotmail.com)
        </SubHeading>
        <SubHeading className="is-size-3">
          Joined 18 June 2018 (2 years 2 month before)
        </SubHeading>
      </div>

      <Level.Item align="right">
        {activeTab !== 0 && (
          <Level.Item>
            <button onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}
        {activeTab !== 5 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next Tab</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Header;
