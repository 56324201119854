import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { first } from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Delete from './Delete';

const DealList = ({ deal, users = [] }) => {
  const [dealActive, setDealActive] = useState(true);

  const userArray = users.filter(
    item => item.user_id === deal.audit.updated_by,
  );

  if (!dealActive) {
    return null;
  }

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <React.Fragment>
      <td>
        <Link to={`/update-event-deal/${deal.place_id}/${deal.deal_id}`}>
          {deal.name}
        </Link>
      </td>
      <td>{deal.voucher_code}</td>
      <td>{deal.place_name}</td>

      <td> {moment(deal.start).format('LL')}</td>
      <td>{moment(deal.end).format('LL')}</td>
      <td> {moment(deal.audit.updated_at).format('lll')} </td>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user.full_name}>{user.full_name}</p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user && user.email}</p>
                <p>objectId: {user && user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          deal.audit.updated_by
        )}
      </td>

      <td>
        <Delete dealId={deal.deal_id} onComplete={() => setDealActive(false)} />
      </td>
    </React.Fragment>
  );
};
export default DealList;
