import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { first } from 'lodash';
import ReactTooltip from 'react-tooltip';

const CheckInList = ({ review, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === review.audit.created_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <>
      <tr>
        <td>
          <Link to={`item-image-approval/${review.group_id}`}>
            {review.item_name}
          </Link>
        </td>
        <td>{review.place_name}</td>

        <td>{review.place_city}</td>
        <td>
          {user ? (
            <React.Fragment>
              <p data-tip="" data-for={user.full_name}>
                {user.full_name}
              </p>
              <ReactTooltip
                id={user.full_name}
                place="top"
                type="dark"
                effect="float"
              >
                <span>
                  <p>email: {user.email}</p>
                  <p>objectId: {user.user_id}</p>
                </span>
              </ReactTooltip>
            </React.Fragment>
          ) : (
            review.audit.updated_by
          )}
        </td>
        <td>
          {review.audit ? moment(review.audit.created_at).format('lll') : '-'}
        </td>
        <td> {`${review.status}`}</td>
      </tr>
    </>
  );
};
export default CheckInList;
