import React, { useState } from 'react';
import { omit, isEmpty, differenceWith } from 'lodash';
import { components } from 'react-select';
import styled from 'styled-components';

import { Select } from '../../../../components/elements';

const CustomOptionDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const CustomOption = ({ ...props }) => (
  <components.Option {...props}>
    <div>{props.value.name}</div>
    <CustomOptionDescriptionWrapper>
      {props.value.menu_item_price ? (
        <>
          <div>{props.value.menu_section_name} |&nbsp;</div>
          <div>${parseFloat(props.value.menu_item_price).toFixed(2)}</div>
        </>
      ) : (
        <>
          <div>{props.value.menu_section_name} |&nbsp;</div>
          <div>(Has Variants&nbsp;+)</div>
        </>
      )}
    </CustomOptionDescriptionWrapper>
  </components.Option>
);

const SearchItems = ({ links, selectedLinkIds, setFieldValue }) => {
  const [searchItemName, setSearchItemName] = useState(null);

  const selectItemOptions = differenceWith(
    links.map(link => ({
      value: {
        ...omit(link, [
          'variant',
          'type',
          'value',
          'menu_item_id',
          'new_price',
          'savings',
        ]),
      },
      label: link.name,
    })),
    selectedLinkIds,
    (i, j) => i.value.item_link_id === j,
  );

  return (
    <Select
      placeholder="Select Item"
      value={searchItemName}
      options={selectItemOptions}
      onChange={value => {
        const { item_link_id } = value.value;
        setSearchItemName(value);
        const isNewItemForDiscount = isEmpty(
          selectedLinkIds.filter(id => id === item_link_id),
        );
        if (isNewItemForDiscount) {
          setFieldValue('selectedLinkIds', [item_link_id, ...selectedLinkIds]);
        }
      }}
      components={{ Option: CustomOption }}
      applyZIndex
    />
  );
};

export default SearchItems;
