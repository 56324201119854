import React from 'react';

import { first } from 'lodash';

const PlaceList = ({ review, users = [] }) => {
  const userArray = users.filter(
    item => item.user_id === review.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <tr>
      <td>
        {' '}
        {user ? (
          <React.Fragment>{user.full_name}</React.Fragment>
        ) : (
          review.audit.created_by
        )}
      </td>
      <td>{user && user.email}</td>
      {/* <td>{moment(placeReview.audit.updated_at).format('lll')}</td> */}
      <td>-</td>
      {/* <td>{placeReview.count}</td> */}
      {/* <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td> */}
    </tr>
  );
};

export default PlaceList;
