import React from 'react';
import gql from 'graphql-tag';
import uuid from 'uuid';
import client from '../../../utils/apolloClient';

const searchTableBookingByTimeQuery = gql`
  query searchTableBookingByTimeQuery($input: SearchInput) {
    search_table_booking_by_time(input: $input) {
      table_booking_listing {
        time_slot
        total_guest_count
      }
    }
  }
`;

export const searchTableBookingSlotForDate = async ({
  adminUserId,
  date,
  placeId,
}) => {
  const input = { user_id: adminUserId, filter: { table_booking_filter: {} } };

  if (date) {
    input.filter.table_booking_filter.booking_date = date;
  }

  if (placeId) {
    input.filter.place_filter = {};
    input.filter.place_filter.place_id = placeId;
    input.filter.table_booking_filter.place_id = placeId;
  }

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTableBookingByTimeQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_table_booking_by_time &&
      Array.isArray(
        data.data.search_table_booking_by_time?.table_booking_listing,
      ) &&
      data.data.search_table_booking_by_time.table_booking_listing.length !== 0
    ) {
      return data.data.search_table_booking_by_time.table_booking_listing;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useBookingSlots = ({ adminUserId, date, placeId }) => {
  const [slots, setSlots] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      setSlots([]);
      const res = await searchTableBookingSlotForDate({
        adminUserId,
        date,
        placeId,
      });
      if (res.length !== 0) {
        setSlots(res);
      }
      setLoading(false);
    };
    fetchFunc();
  }, [adminUserId, date, placeId]);
  return [loading, slots];
};

const updateTableBookingMutation = gql`
  mutation updateTableBookingMutation($input: TableBookingInput) {
    update_table_booking(input: $input) {
      error {
        code
        description
      }
      user_id
      service_type_setting_id
      place_id
      platform
      guest_count
      guest_notes
      table_booking_id
      booking_date
      booking_time
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      service_type
      booking_reference
      booking_status
      guest_status
      image_url
      table_number
    }
  }
`;

export const updateTableBooking = async ({ input }) => {
  try {
    const data = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateTableBookingMutation,
      variables: {
        input,
      },
    });

    if (
      data &&
      data?.data?.update_table_booking &&
      data.data.update_table_booking.error
    ) {
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const useUpdateTableBooking = () => {
  const [loading, setLoading] = React.useState(false);
  const [shldReload, setShldReload] = React.useState(uuid());

  const mutateFnc = async input => {
    setLoading(true);
    const res = await updateTableBooking({ input });
    if (res) {
      setShldReload(uuid());
    }
    setLoading(false);
    return res;
  };
  return [loading, mutateFnc, shldReload];
};

const createTableBookingMutation = gql`
  mutation createTableBookingMutation($input: TableBookingInput) {
    create_table_booking(input: $input) {
      error {
        code
        description
      }
      user_id
      service_type_setting_id
      place_id
      platform
      guest_count
      guest_notes
      table_booking_id
      booking_date
      booking_time
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      service_type
      booking_reference
      booking_status
      guest_status
      image_url
      table_number
    }
  }
`;

export const createTableBooking = async ({ input }) => {
  try {
    const data = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: createTableBookingMutation,
      variables: {
        input,
      },
    });

    if (
      data &&
      data?.data?.create_table_booking &&
      data.data.create_table_booking.error
    ) {
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const useCreateTableBooking = () => {
  const [loading, setLoading] = React.useState(false);
  const [shldReload, setShldReload] = React.useState(uuid());

  const mutateFnc = async input => {
    setLoading(true);
    const res = await createTableBooking({ input });
    if (res) {
      setShldReload(uuid());
    }
    setLoading(false);
    return res;
  };
  return [loading, mutateFnc, shldReload];
};

export default {};
