import React from 'react';
import styled from 'styled-components';
import { Label } from 'rbx';

import {
  // CheckBox,
  // Radio,
  Input,
} from '../../../components/elements';

const Container = styled.div`
  padding: 1rem;
  width: 40%;
`;

const OtherSettings = ({ values }) => (
  <Container>
    <div>
      <Label>Deliverect Settings</Label>
      <div>
        <Input label="Platform" value={values.platform} disabled />
        <Input
          label="Channel Link ID"
          value={values.channel_link_id}
          disabled
        />
        <Input
          label="Deliverect Modifier Group ID"
          value={values.deliverect_modifier_group_id}
          disabled
        />
        <Input
          label="Deliverect Modifier Group PLU ID"
          value={values.deliverect_modifier_group_plu_id}
          disabled
        />
      </div>
    </div>
  </Container>
);

export default OtherSettings;
