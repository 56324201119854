import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { omit, first } from 'lodash';
import moment from 'moment';

import { upload_image_s3 } from '../../utils/s3';
import Loading from './Loading';

const DropZoneWrapper = styled.div`
  .box {
    cursor: pointer;
  }
`;

const MyDropzone = ({ s3UploadPath, onChange, metaData, children }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const onDrop = useCallback(async acceptedFiles => {
    setLoading(true);

    const file = acceptedFiles[0];
    setFiles({ preview: URL.createObjectURL(file) });
    const omittedFile = omit(file, ['name']);
    const imageName = file.name.split('.');
    const name = `${first(imageName)}-${moment().format(
      'YYYYMMDD_hhmmss',
    )}.${file.type.substring(6)}`;
    const customFile = { ...omittedFile, name, path: name };
    console.log(customFile);
    const { Location: url } = await upload_image_s3(
      file,
      `${s3UploadPath}${name}`,
      metaData,
    );
    const response = { path: name, url };
    onChange(response);

    setLoading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropZoneWrapper>
      {loading ? (
        <React.Fragment>
          <img src={files.preview} alt="images" />
          <Loading />
        </React.Fragment>
      ) : (
        <div className={children ? '' : 'box'} {...getRootProps()}>
          <input
            {...getInputProps()}
            accept="image/x-png,image/gif,image/jpeg,image/jpg"
          />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <React.Fragment>
              {children || (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </React.Fragment>
          )}
        </div>
      )}
      {/* {thumbs} */}
    </DropZoneWrapper>
  );
};

MyDropzone.defaultProps = {
  s3UploadPath: 'dummy/',
  metaData: {},
  onChange: () => {},
};

MyDropzone.propTypes = {
  s3UploadPath: PropTypes.string,
  metaData: PropTypes.object,
  onChange: PropTypes.func,
};

export default MyDropzone;
