import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({ activeTab, loading, prev, next, onCancel }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <Link to={onCancel} className="button is-light">
            <span>Cancel</span>
          </Link>
        </Level.Item>
        {activeTab === 1 && (
          <Level.Item>
            <button type="button" onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button
            type="submit"
            className={`button is-primary ${loading && 'is-loading'}`}
          >
            <span>Submit</span>
          </button>
        </Level.Item>
        {activeTab === 0 && (
          <Level.Item>
            <button type="button" onClick={next} className="button is-primary">
              <span>Next</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
