import React from 'react';
import styled from 'styled-components';
import { Column, Title } from 'rbx';
// import Header from './Header';
import Footer from './Footer';

import { Input, TextArea } from '../../../components/elements';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const UserDashboard = ({
  activity,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  createForm,
}) => (
  <Container>
    <fieldset className="box">
      <legend className="label">Social</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input
            label="Following"
            value={activity.deals.deals_total}
            // placeholder="60"
            disabled
          />
        </Column>
        <Column size={3}>
          <Input
            label="Followers"
            value={activity.deals.deals_active}
            placeholder="600"
            disabled
          />
        </Column>
        <Column size={3}>
          <Input
            label="User Rating"
            value={activity.deals.deals_active}
            placeholder="600"
            disabled
          />
        </Column>
        <Column size={3}>
          <Input label="User Flags" placeholder="600" disabled />
        </Column>
      </Column.Group>
    </fieldset>
    <fieldset className="box">
      <legend className="label">Place</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="User Uploaded Photos" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Place Views " placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Place Following" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Place Bookmarks" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Place Reviews" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Place Ratings" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Place Review Flags" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Place Review Comments" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Like it " placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Been there " placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Shares" placeholder="40" disabled />
        </Column>
        <Column size={3}>
          <Input label="Facebook Shares" placeholder="40" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Deals</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Total Deals" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Deal Views" placeholder="600" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Events</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Likes" placeholder="60" disabled />
        </Column>

        <Column size={3}>
          <Input label="Shares" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Bookmarks" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Views" placeholder="60" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <legend className="label">Item</legend>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label=" Tried" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Loved" placeholder="60" disabled />
        </Column>
        <Column size={3}>
          <Input label="Instagram Shares" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Review Flags" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Review Comments" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Bookmarks" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Comments" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Views" placeholder="600" disabled />
        </Column>
      </Column.Group>
    </fieldset>

    <fieldset className="box">
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Mobile Calls" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Directions and Map Views" placeholder="600" disabled />
        </Column>
        <Column size={3}>
          <Input label="Menu Bookmarks" placeholder="600" disabled />
        </Column>
      </Column.Group>
    </fieldset>
  </Container>
);
export default UserDashboard;
