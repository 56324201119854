import React, { useState } from 'react';
import styled from 'styled-components';
import { Label } from 'rbx';

import ModifierList from './ModifierList';

import SearchModifiersModal from './SearchModifiersModal';
import { Loading } from '../../../components/elements';

const Container = styled.div`
  .acc_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: ${({ theme }) => theme.lightGreyAlpha};
  }
`;
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Flex = styled(Item)`
  width: 50%;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const AddItemsButton = styled.button`
  &&& {
    border-color: ${({ theme }) => theme.primaryColor};
    border-width: 2px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    width: 48%;
  }
`;

const Modifiers = ({
  values,
  setFieldValue,
  setIsActiveAddEditModal,
  searchGroupsLoading,
}) => {
  const [isActiveSearchGroupModal, setIsActiveSearchGroupModal] = useState(
    false,
  );

  const deleteMenuGroup = id => {
    setFieldValue(
      'modifierGroups',
      values.modifierGroups.filter(item => item.modifier_group_id !== id),
    );
  };

  if (searchGroupsLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <Label className="title is-size-3">Add Modifiers</Label>
      <Flex>
        <AddItemsButton
          type="button"
          className="button"
          onClick={() => setIsActiveSearchGroupModal(true)}
        >
          Add modifiers group(s)
        </AddItemsButton>

        <AddItemsButton
          type="button"
          className="button"
          onClick={() => setIsActiveAddEditModal(true)}
        >
          Create new modifiers group
        </AddItemsButton>
      </Flex>
      {values.modifierGroups.length !== 0 && (
        <ModifierList
          setFieldValue={setFieldValue}
          modifierGroups={values.modifierGroups}
          deleteMenuGroup={deleteMenuGroup}
        />
      )}

      <SearchModifiersModal
        placeId={values.place_id}
        menuItemId={values.menu_item_id}
        initialsGroups={values.modifierGroups}
        onSubmit={inputs => {
          setFieldValue('modifierGroups', values.modifierGroups.concat(inputs));
          setIsActiveSearchGroupModal(false);
        }}
        isActive={isActiveSearchGroupModal}
        onClose={() => setIsActiveSearchGroupModal(false)}
      />
    </Container>
  );
};

export default Modifiers;
