import React from 'react';
import Select from 'react-select';

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '1px',
    // height: '29px',
    fontSize: '0.8rem',
  }),
  input: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    minHeight: '1px',
    height: '11px',
  }),
  clearIndicator: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: '1px',
    // height: '27px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: provided => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px',
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8rem',
  }),
};

const ReactSelectInput = ({ ...props }) => (
  <Select styles={customStyles} {...props} />
);
export default ReactSelectInput;
