import React from 'react';
import styled from 'styled-components';
import qs from 'querystringify';
import { omit } from 'lodash';
import moment from 'moment-timezone';

import { Input, ReactDateTimePicker } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = ({
  history,
  placeName,
  date,
  city,
  routeState,
  defaultPlace,
}) => {
  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/custom-hours${qs.stringify(
        {
          ...newRoutState,
          ...args,
          page: 1,
        },
        true,
      )}`,
    );
  };

  return (
    <Wrapper>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeName}
              onChange={event => {
                handlePush({ placeName: event.target.value }, 'placeName');
              }}
              placeholder="Enter the Place name..."
              key={defaultPlace}
            />
          </div>
          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city || ''}
                  onSelect={data => {
                    handlePush({ city: data.name }, 'city');
                  }}
                  onHandleClear={() => {
                    handlePush({ city: '' }, 'city');
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column is-4">
            <ReactDateTimePicker
              label="Date"
              value={date}
              onChange={value => {
                handlePush({ date: moment(value).toISOString() }, 'date');
              }}
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
