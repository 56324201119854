import discoverEvent from '../../assets/icons2/discover-events.svg';
import placeAroundMe from '../../assets/icons2/places-around-me.svg';
import addCamera from '../../assets/icons2/camera-add.svg';
import location from '../../assets/icons2/location.svg';
import restaurantAndBars from '../../assets/icons2/restaurant-and-bars.svg';
import itemRatingGold from '../../assets/icons2/item-rating-gold.svg';
import itemRatingGrey from '../../assets/icons2/item-rating-grey.svg';
import reviewPlace from '../../assets/icons2/review-place.svg';
import reviewPlaceBlue from '../../assets/icons2/review-place-blue.svg';
import reviewItem from '../../assets/icons2/review-item.svg';
import tried from '../../assets/icons2/tried.svg';
import triedGrey from '../../assets/icons2/tried-grey.svg';
import loveTheItem from '../../assets/icons2/love-the-item.svg';
import loveTheItemGrey from '../../assets/icons2/love-the-item-grey.svg';
import bookmark from '../../assets/icons2/bookmark.svg';
import bookmarkGrey from '../../assets/icons2/bookmark-grey.svg';
import share from '../../assets/icons2/share.svg';
import shareGrey from '../../assets/icons2/share-grey.svg';
import itemListReviewGrey from '../../assets/icons2/item-list-review-grey.svg';
import itemListReview from '../../assets/icons2/item-list-review.svg';
import menuBurger from '../../assets/icons2/menu-burger.svg';
import menuBurgerWhite from '../../assets/icons2/menu-burger-white.svg';

import myBookmark from '../../assets/icons2/my-bookmark.svg';
import findFriends from '../../assets/icons2/find-friends.svg';
import myOrders from '../../assets/icons2/my-orders.svg';
import supportAndFeedback from '../../assets/icons2/support-and-feedback.svg';
import settings from '../../assets/icons2/settings.svg';
import addAPlaces from '../../assets/icons2/add-a-places.svg';
import claimYourPlaces from '../../assets/icons2/claim-your-places.svg';
import follow from '../../assets/icons2/follow.svg';
import following from '../../assets/icons2/following.svg';
import beThere from '../../assets/icons2/be-there.svg';
import beThereGray from '../../assets/icons2/be-there-grey.svg';
import likeStar from '../../assets/icons2/like-star.svg';
import likeStarGrey from '../../assets/icons2/like-star-grey.svg';
import editProfile from '../../assets/icons2/profile-edit.svg';
import facebook from '../../assets/icons2/facebook.svg';
import notFound from '../../assets/images/404.svg';
import error from '../../assets/images/error.svg';
import flag from '../../assets/icons2/flag.svg';
import comment from '../../assets/icons2/comment.svg';
import notifications from '../../assets/icons2/notifications.svg';
import socialSharing from '../../assets/icons2/social-sharing.svg';
import profileSettings from '../../assets/icons2/profile-settings.svg';
import changePassword from '../../assets/icons2/change-password.svg';
import beenThereBlue from '../../assets/icons2/been-there-blue.svg';
import twitter from '../../assets/icons2/twitter.svg';
import instagram from '../../assets/icons2/instagram.svg';
// my profile page
import reviews from '../../assets/icons2/reviews.svg';
import myActivity from '../../assets/icons2/my-activity.svg';
import myFriends from '../../assets/icons2/my-friends.svg';
import paymentDetails from '../../assets/icons2/payment-details.svg';
import follow2 from '../../assets/icons2/follow2.svg';
import added from '../../assets/icons2/added.svg';

import addressDetails from '../../assets/icons2/address-details.svg';
import myBadges from '../../assets/icons2/my-badges.svg';
import recycleBin from '../../assets/icons2/recycle-bin.svg';
import edit from '../../assets/icons2/edit.svg';
import findAndShareItemSolid from '../../assets/icons2/find-and-share-Item-solid.svg';
import like from '../../assets/icons2/like.svg';

// badges
import kraver from '../../assets/icons2/kraver.svg';
import inspiredKraver from '../../assets/icons2/inspired-kraver.svg';
import socialiteKraver from '../../assets/icons2/socialite-kraver.svg';
import explorerKraver from '../../assets/icons2/explorer-kraver.svg';
import explorerKraver2 from '../../assets/icons2/explorer-kraver-2.svg';
import persistentKraver from '../../assets/icons2/persistent-kraver.svg';
import perfectKraver from '../../assets/icons2/perfect-kraver.svg';
import expertKraver from '../../assets/icons2/expert-kraver.svg';
import connoisseur from '../../assets/icons2/connoisseur.svg';
import amazingKraver from '../../assets/icons2/amazing-kraver.svg';
import incredibleKraver from '../../assets/icons2/incredible-kraver.svg';
import marvelousKraver from '../../assets/icons2/marvelous-kraver.svg';
import superstarKraver from '../../assets/icons2/superstar-kraver.svg';

// payment details
import debitCard from '../../assets/icons2/debit-card.svg';
import paypal from '../../assets/icons2/paypal.svg';
import visaCard from '../../assets/icons2/visa-card.svg';

// claim plan

// import trashsIcon from '../../pages/ManageEvent/Icons/trash.svg';

const svgIcons = {
  follow2,
  added,
  debitCard,
  paypal,
  visaCard,
  superstarKraver,
  kraver,
  inspiredKraver,
  socialiteKraver,
  explorerKraver,
  explorerKraver2,
  persistentKraver,
  perfectKraver,
  expertKraver,
  connoisseur,
  amazingKraver,
  incredibleKraver,
  marvelousKraver,
  like,
  findAndShareItemSolid,
  recycleBin,
  edit,
  reviews,
  myActivity,
  myFriends,
  paymentDetails,
  addressDetails,
  myBadges,
  discoverEvent,
  placeAroundMe,
  addCamera,
  location,
  restaurantAndBars,
  itemRatingGold,
  itemRatingGrey,
  reviewPlace,
  reviewPlaceBlue,
  reviewItem,
  tried,
  triedGrey,
  loveTheItem,
  loveTheItemGrey,
  bookmark,
  bookmarkGrey,
  share,
  shareGrey,
  itemListReviewGrey,
  itemListReview,
  menuBurger,
  menuBurgerWhite,
  myBookmark,
  findFriends,
  myOrders,
  supportAndFeedback,
  settings,
  addAPlaces,
  claimYourPlaces,
  follow,
  following,
  beThere,
  beThereGray,
  likeStar,
  likeStarGrey,
  editProfile,
  facebook,
  notFound,
  error,
  flag,
  comment,
  notifications,
  socialSharing,
  twitter,
  instagram,
  changePassword,
  profileSettings,
  beenThereBlue,
  // trashsIcon,
};

export default { ...svgIcons };
