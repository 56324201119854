import React from 'react';
import Layout from '../../../components/global/Layout';
import PlaceListing from '../PlaceListing';

const CustomMenuHoursPlaceListing = ({ history }) => (
  <Layout>
    <PlaceListing
      heading="Menu & Custom Hours"
      handleSelect={value => {
        history.push(`/menu-custom-hour/${value.place_id}`);
      }}
    />
  </Layout>
);

export default CustomMenuHoursPlaceListing;
