import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
// import gql from 'graphql-tag';
import { FieldArray } from 'formik';

import { forEach, reverse, omit } from 'lodash';

import swal from 'sweetalert';

import EditLinkModal from './EditLinkModal';
import { removeIdFromVariantsInput } from './helpers';
import { BoxWithHeader, CheckBox } from '../../../components/elements';
import AddNewMenuAndSection from './AddNewMenuAndSection';

const Container = styled.div`
  max-width: 80%;
`;

const Span = styled.span`
  padding-left: 0.7rem;
`;

const CustomColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  &&& {
    .columns.is-gapless:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

// const updateItemLinkMutation = gql`
//   mutation updateItemLink($input: [MenuItemLinkInput]) {
//     update_menu_item_link(input: $input) {
//       item_link_id
//       error {
//         description
//       }
//     }
//   }
// `;

// const deleteItemLinkMutation = gql`
//   mutation deleteItemLink($input: [NavigateMenuItemLinkInput]) {
//     delete_menu_item_link(input: $input) {
//       item_link_id
//       error {
//         description
//       }
//     }
//   }
// `;

const isZeroExist = values => {
  let isExist = false;
  forEach(values, item => {
    if (
      parseFloat(item.variant_price).toFixed(0) === parseFloat(0).toFixed(0)
    ) {
      isExist = true;
    }
  });
  return isExist;
};
const calculateVariantPriceBase = (rootVariant, variantId) => {
  let price = parseFloat(0);
  forEach(rootVariant[0].variants, item => {
    if (item.variant_id === variantId) {
      price = parseFloat(item.variant_price);
    }
  });
  return parseFloat(price).toFixed(2);
};

const checkOverride = (rootVariant, linkVariant) => {
  let isOverride = false;
  forEach(linkVariant[0].variants, item => {
    if (item.variant_price || item.variant_price == 0) {
      isOverride = true;
    }
  });
  return isOverride;
};

const Row = ({ values, link, itemVariant, onDelete, onUpdate }) => {
  const [priceOverrideValue, setPriceOverrideValue] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [linkData, setLinkData] = useState({
    variant: [],
    price: null,
  });

  useEffect(() => {
    if (
      link.variant &&
      link.variant.length > 0 &&
      values.variant &&
      values.variant.length > 0
    ) {
      const value = checkOverride(values.variant, link.variant);
      setPriceOverrideValue(value);
    } else {
      setPriceOverrideValue(link.price || link.price == 0 ? true : false);
    }

    setLinkData({
      variant: link.variant,
      price: link.price,
    });
  }, []);

  const firstVariant =
    linkData.variant && linkData.variant.length !== 0
      ? linkData.variant[0]
      : [];

  const variants = firstVariant.length !== 0 ? firstVariant.variants : [];
  const prompt = firstVariant.length !== 0 ? firstVariant.prompt : null;
  const prompt_id = firstVariant.length !== 0 ? firstVariant.prompt_id : null;
  return (
    <>
      <BoxWithHeader
        header={
          <Column.Group vcentered multiline gapless>
            <Column size="4">
              <p className="has-text-weight-regular">
                {link.service_type_setting?.service_type} -{' '}
                {link.menu && link.menu.name}
              </p>
            </Column>
            <Column size="3">
              <Span>{link.menu_section && link.menu_section.name}</Span>
            </Column>
            <Column size="3">
              <CheckBox
                value={priceOverrideValue}
                label="Price Override"
                onChange={value => {
                  if (value) {
                    setIsActiveModal(true);
                    setLinkData({
                      variant: link.variant,
                      price: link.price,
                    });
                  } else {
                    swal({
                      title: 'Are you sure?',
                      // eslint-disable-next-line max-len
                      text: `Are you sure you want to remove the price override?`,
                      buttons: ['Cancel', 'Yes'],
                      dangerMode: true,
                    }).then(willDelete => {
                      if (willDelete) {
                        onUpdate({
                          ...link,
                          variant:
                            values.variant && values.variant.length !== 0
                              ? values.variant.map(item =>
                                  omit(
                                    {
                                      ...item,
                                      variants: item.variants.map(variants => ({
                                        ...variants,
                                        variant_price: null,
                                      })),
                                    },
                                    'id',
                                  ),
                                )
                              : null,
                          price: null,
                        });
                        setLinkData({
                          variant:
                            values.variant && values.variant.length !== 0
                              ? values.variant.map(item =>
                                  omit(
                                    {
                                      ...item,
                                      variants: item.variants.map(variants => ({
                                        ...variants,
                                        variant_price: null,
                                      })),
                                    },
                                    'id',
                                  ),
                                )
                              : null,
                          price: null,
                        });
                        setPriceOverrideValue(false);
                      }
                    });
                  }
                }}
              />
            </Column>

            <Column size="2">
              <button
                type="button"
                className="button"
                onClick={() => {
                  swal({
                    title: 'Are you sure?',
                    // eslint-disable-next-line max-len
                    text: `Are you sure you want to remove the Menu and Menu Category?`,
                    buttons: ['Cancel', 'Yes'],
                    dangerMode: true,
                  }).then(willDelete => {
                    if (willDelete) {
                      onDelete(link.item_link_id);
                    }
                  });
                }}
              >
                <i className="far fa-trash-alt" />
              </button>
            </Column>
          </Column.Group>
        }
      >
        {
          <CustomColumn>
            {(!link.variant || link.variant.length == 0) &&
              link.price !== null && (
                <>
                  <Column.Group vcentered multiline gapless>
                    <Column size="4">
                      <p className="has-text-weight-semibold is-size-4">
                        Base Price
                      </p>
                    </Column>
                    <Column size="3">
                      <p className="is-size-4">
                        <Span>${parseFloat(values.price).toFixed(2)}</Span>
                      </p>
                    </Column>
                  </Column.Group>
                  <Column.Group vcentered multiline gapless>
                    <Column size="4">
                      <p className="has-text-weight-semibold is-size-4">
                        Price override
                      </p>
                    </Column>
                    <Column size="3">
                      <p className="is-size-4">
                        <Span>${parseFloat(link.price).toFixed(2)}</Span>
                      </p>
                    </Column>
                    <Column size="4">
                      <a
                        onClick={() => {
                          setLinkData({
                            variant: link.variant,
                            price: link.price,
                          });
                          setIsActiveModal(true);
                        }}
                      >
                        <p className="has-text-weight-semibold is-size-4 has-text-primary">
                          Update Price
                        </p>
                      </a>
                    </Column>
                  </Column.Group>
                </>
              )}

            {variants.length !== 0 && (
              <Column.Group vcentered multiline gapless>
                <Column size="4">
                  <p className="has-text-weight-semibold is-size-4">Variants</p>
                </Column>
                <Column size="3">
                  <p className="has-text-weight-semibold is-size-4">
                    <Span>Base Price</Span>
                  </p>
                </Column>
                <Column size="3">
                  <p className="has-text-weight-semibold is-size-4">
                    <Span>Price Override</Span>
                  </p>
                </Column>
                <Column size="2">
                  <a
                    onClick={() => {
                      setLinkData({
                        variant: link.variant,
                        price: link.price,
                      });
                      setIsActiveModal(true);
                    }}
                  >
                    <p className="has-text-weight-semibold is-size-4 has-text-primary">
                      Update Price
                    </p>
                  </a>
                </Column>
              </Column.Group>
            )}
            <div>
              {variants.map(item => {
                const basePrice = calculateVariantPriceBase(
                  values.variant,
                  item.variant_id,
                );
                return (
                  <Column.Group vcentered multiline gapless>
                    <Column size="4">
                      <p className="is-capitalized is-size-4">
                        {item.variant_name}
                      </p>
                    </Column>
                    <Column size="3">
                      <p className="is-size-4">
                        <Span>
                          {basePrice === 'NaN' ? '-' : `$${basePrice}`}
                        </Span>
                      </p>
                    </Column>
                    <Column size="3">
                      <p className="is-size-4">
                        <Span>
                          {item.variant_price || item.variant_price == 0
                            ? `$${parseFloat(item.variant_price).toFixed(2)}`
                            : `-`}
                        </Span>
                      </p>
                    </Column>
                  </Column.Group>
                );
              })}
            </div>
          </CustomColumn>
        }
      </BoxWithHeader>
      <EditLinkModal
        name={`${link.service_type_setting?.service_type} - ${link.menu.name}`}
        variants={variants}
        itemVariant={itemVariant}
        basePrice={values.price}
        price={linkData.price}
        loading={false}
        isActive={isActiveModal}
        onClose={() => setIsActiveModal(false)}
        onSubmit={input => {
          const isZeroPriceExistVar =
            input.variants && input.variants.length !== 0
              ? isZeroExist(input.variants)
              : false;
          const isZeroPriceExist = !!(
            input.price &&
            parseFloat(input.price).toFixed(2) === parseFloat(0).toFixed(2)
          );

          if (isZeroPriceExistVar || isZeroPriceExist) {
            swal({
              title: 'Are you sure?',
              // eslint-disable-next-line max-len
              text: `Are you sure you want to override the price 0.00?`,
              buttons: ['Cancel', 'Yes'],
              dangerMode: true,
            }).then(willDelete => {
              if (willDelete) {
                onUpdate({
                  ...link,
                  variant: prompt_id
                    ? removeIdFromVariantsInput([
                        { prompt_id, prompt, variants: input.variants },
                      ])
                    : null,
                  price:
                    input.variants && input.variants.length !== 0
                      ? null
                      : input.price,
                });
                setLinkData({
                  variant: [
                    {
                      prompt_id,
                      prompt: firstVariant.prompt,
                      variants: input.variants,
                    },
                  ],
                  price:
                    input.variants && input.variants.length !== 0
                      ? null
                      : input.price,
                });
                setPriceOverrideValue(true);
                setIsActiveModal(false);
              }
            });
          } else {
            onUpdate({
              ...link,
              price:
                input.variants && input.variants.length !== 0
                  ? null
                  : input.price,
              variant: prompt_id
                ? removeIdFromVariantsInput([
                    { prompt_id, prompt, variants: input.variants },
                  ])
                : null,
            });
            setLinkData({
              variant: [
                {
                  prompt_id,
                  prompt: firstVariant.prompt,
                  variants: input.variants,
                },
              ],
              price:
                input.variants && input.variants.length !== 0
                  ? null
                  : input.price,
            });
            setPriceOverrideValue(true);
            setIsActiveModal(false);
          }
        }}
      />
    </>
  );
};

const MenuAndSections = ({
  itemVariant,
  itemRootPrice,
  itemId,
  placeId,
  values,
  setFieldValue,
}) => (
  <Container>
    <Column.Group>
      <Column size={4}>
        <p className="title">Menu</p>
      </Column>
      <Column size={4}>
        <p className="title">
          <Span>Category</Span>
        </p>
      </Column>
    </Column.Group>
    <AddNewMenuAndSection
      links={values.updateLinks.concat(values.createLinks)}
      placeId={placeId}
      itemId={itemId}
      price={values.price}
      variant={values.variant}
      onSubmit={value => {
        setFieldValue('createLinks', reverse(values.createLinks.concat(value)));
      }}
    />
    <FieldArray
      name="createLinks"
      render={() => (
        <>
          {values.createLinks.map(
            (link, index) =>
              link && (
                <Row
                  values={values}
                  link={link}
                  itemVariant={itemVariant}
                  itemRootPrice={itemRootPrice}
                  onUpdate={item => {
                    setFieldValue(`createLinks.${index}`, item);
                  }}
                  onDelete={id =>
                    setFieldValue(
                      'createLinks',
                      values.createLinks.filter(
                        item => item.item_link_id !== id,
                      ),
                    )
                  }
                />
              ),
          )}
        </>
      )}
    />
    <FieldArray
      name="updateLinks"
      render={() => (
        <>
          {values.updateLinks.map(
            (link, index) =>
              link && (
                <Row
                  values={values}
                  link={link}
                  itemVariant={itemVariant}
                  itemRootPrice={itemRootPrice}
                  onUpdate={item => {
                    setFieldValue(`updateLinks.${index}`, item);
                  }}
                  onDelete={id => {
                    setFieldValue('deleteLinks', values.deleteLinks.concat(id));
                    setFieldValue(
                      'updateLinks',
                      values.updateLinks.filter(
                        item => item.item_link_id !== id,
                      ),
                    );
                  }}
                />
              ),
          )}
        </>
      )}
    />
  </Container>
);

export default MenuAndSections;
