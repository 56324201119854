import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import { isNull } from 'lodash';
import * as yup from 'yup';

import ItemInformation from './ItemInformation';
import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Item Information',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);

  const prev = () =>
    activeTab === 0 ? setActiveTab(0) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 0 ? setActiveTab(0) : setActiveTab(activeTab + 1);
  return (
    <React.Fragment>
      <Header heading="Item Dictionary" />

      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>

        <Container>
          {activeTab === 0 && <ItemInformation {...props} />}
        </Container>
      </Box>
      <hr />
      <Footer prev={prev} next={next} />
    </React.Fragment>
  );
};

const ItemDictionaryActivity = withFormik({
  mapPropsToValues: ({ itemDictionary, items, item }) => ({
    item_dictionary_id: itemDictionary.item_dictionary_id,
    name:
      itemDictionary && !isNull(itemDictionary.name) ? itemDictionary.name : '',
    item_name: item && !isNull(item.name) ? item.name : '',
    description:
      itemDictionary && !isNull(itemDictionary.description)
        ? itemDictionary.description
        : '',
    status: itemDictionary ? itemDictionary.status : '',
    items: items || [],
    // item_status: itemDictionary ? itemDictionary.status : '',
  }),

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'At least 3 Characters are Required!')
      .max(75, 'Name cannot be greater than 75 Characters')
      .required('Item Dictionary Name!'),
    description: yup
      .string()
      .min(26, 'At least 26 Characters are Required!')
      .max(1200, 'Description should not be greater than 1200 Characters!'),
    status: yup.string().required('Status is required'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'ItemDictionaryActivity',
})(Form);

export default ItemDictionaryActivity;
