import React from 'react';
import { first } from 'lodash';

import moment from 'moment';

const FlagList = ({ flag, users }) => {
  const userArray = users.filter(
    item => item.user_id === flag.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <React.Fragment>
      <td>
        {user ? (
          <React.Fragment>
            <p>{user.full_name}</p>
          </React.Fragment>
        ) : (
          flag.audit.updated_by
        )}
      </td>
      <td>
        {user ? (
          <React.Fragment>
            <span>
              <p>{user.email}</p>
            </span>
          </React.Fragment>
        ) : (
          flag.audit.updated_by
        )}
      </td>
      <td>{moment(flag.audit.updated_at).format('lll')}</td>
      <td>{flag.flag_content}</td>
    </React.Fragment>
  );
};

export default FlagList;
