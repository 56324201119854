import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
// import swal from 'sweetalert';
import { Message } from 'rbx';
import { first } from 'lodash';
import ItemActivity from './ItemActivity';
import Layout from '../../../components/global/Layout';
import { Loading } from '../../../components/elements';

// import PlaceSelection from '../../../components/PlaceSelection';

const ItemQuery = gql`
  query searchItem(
    $input: NavigateItemInput
    $socialInput: SearchInput
    $imageFilter: ImageFilter
  ) {
    fetch_item(input: $input) {
      name
      item_id
      place_name
      approval_status
      post_code
      status
      city
      country
      state
      default_image_url
      item_dictionary_id
    }
    search_images(input: { filter: { image_filter: $imageFilter } }) {
      image_id
      url
      link
      tooltip
      tags
      status
    }
    search_social(input: $socialInput) {
      social_aggregation {
        total_social_count {
          type
          count
        }
      }
    }
  }
`;

const Event = ({ match }) => {
  const { itemId, placeId } = match.params;
  return (
    <Layout>
      <Query
        query={ItemQuery}
        variables={{
          input: {
            item_id: itemId,
          },
          imageFilter: {
            object_id: itemId,
            object_type: ['ITEM'],
            parent_id: placeId,
            parent_type: ['PLACE'],
            links_to_id: [itemId],
            links_to_type: ['ITEM'],
          },
          socialInput: {
            filter: {
              social_filter: {
                object_id: itemId,
                object_type: 'ITEM',
              },
            },
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data: itemData, loading: itemLoading, error }) => {
          if (itemLoading) {
            return <Loading />;
          }
          if (error) {
            return <Message>{error.message}</Message>;
          }
          const { fetch_item, search_social, search_images } = itemData;
          const item = first(fetch_item);
          return (
            <ItemActivity
              social={search_social.social_aggregation}
              item={item}
              images={search_images}
            />
          );
        }}
      </Query>
    </Layout>
  );
};

export default Event;
