import React from 'react';

import { FieldArray } from 'formik';

import { Radio, Table, SwitchButton } from '../../../components/elements';

const TicketAttendeeForm = ({ values, setFieldValue }) => (
  <FieldArray
    name="attribute"
    render={() => (
      <div>
        <div className="label">Attendee Info to be collected</div>
        <Table>
          <thead>
            <tr>
              <th>Attendee Info</th>
              <th>Optional</th>
              <th>Required</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {values.attribute.map((item, index) => (
              <tr>
                <td>
                  <p className="is-size-4">{item.label}</p>
                </td>
                <td>
                  <Radio
                    label=""
                    value={item.isBelong ? !item.required : false}
                    onChange={() =>
                      setFieldValue(`attribute.${index}.required`, false)
                    }
                    disabled={!item.isBelong}
                  />
                </td>
                <td>
                  <Radio
                    label=""
                    value={item.required}
                    onChange={() =>
                      setFieldValue(`attribute.${index}.required`, true)
                    }
                    disabled={!item.isBelong}
                  />
                </td>
                <td>
                  <SwitchButton
                    width={40}
                    height={18}
                    value={item.isBelong}
                    onChange={value => {
                      setFieldValue(`attribute.${index}.isBelong`, value);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )}
  />
);

export default TicketAttendeeForm;
