/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';

import * as yup from 'yup';
import { MdClose } from 'react-icons/md';

const Container = styled.form`
  &&& {
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 100vh;
      padding: 0px;
    }
    .modal-card {
      max-height: calc(100vh - 0px);
      position: absolute;
      right: 0;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1rem 2rem;
      padding-top: 1.5rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: space-between;
      background: white;
      border-top: 0px;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const DetailsFormWrapper = styled.div`
  padding: 1rem 2rem;
`;

const SpecialText = styled.p`
  color: ${({ theme }) => theme.coolGrey};
  font-size: 0.8rem;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.dark};
  font-size: 0.8rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: space-between;
`;

const SpecialSpacing = styled.div`
  display: flex;
  justify-content: space-between;
  .separator {
    padding: 0rem 1rem;
    padding-top: 7px;
  }
`;

const Form = props => {
  const { isActive, onClose, handleSubmit, resetForm, values } = props;

  return (
    <>
      <Container
        className={`modal ${isActive && 'is-active'}`}
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <div>
              <p className="modal-card-title">Order: 6452W322ER343</p>
            </div>

            <CloseButtonWrapper>
              <MdClose
                size={30}
                type="button"
                onClick={() => {
                  onClose();

                  resetForm();
                }}
              />
            </CloseButtonWrapper>
          </header>

          <section className="modal-card-body">
            <DetailsFormWrapper>
              <>
                <SpecialSpacing>
                  <p className="is-size-3 has-text-weight-semibold">Delivery</p>
                </SpecialSpacing>
                <SpecialText>Delivered On Apr 23 2020, 11:30 pm</SpecialText>
                <p className="is-size-3 has-text-weight-semibold">Cash</p>

                <br />
              </>
              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">
                  Thin Crust Cheese Pizza
                </p>
                <p className="is-size-3 has-text-weight-semibold">$15.65</p>
              </SpecialSpacing>
              <SpecialText>10&apos;&apos; Small Pizza(+$12.65)</SpecialText>
              <SpecialText>Substitute Whole Wheat Crust</SpecialText>
              <SpecialText>Sausage (+$1.50)</SpecialText>
              <SpecialText>Pepperoni (+$1.50)</SpecialText>
              <br />
              <SpecialSpacing>
                <p className="is-size-4">Items sbtotal</p>
                <p className="is-size-4">$15.00</p>
              </SpecialSpacing>
              <SpecialSpacing>
                <p className="is-size-4">Delivery fee</p>
                <p className="is-size-4">$5.00</p>
              </SpecialSpacing>
              <SpecialSpacing>
                <p className="is-size-4 ">Promo</p>
                <p className="is-size-4">-$5.00</p>
              </SpecialSpacing>

              <SpecialSpacing>
                <p className="is-size-4">Service fee (Including GST)</p>
                <p className="is-size-4">(-$3.00)</p>
              </SpecialSpacing>

              <SpecialSpacing>
                <p className="is-size-4">Transaction fee (Including GST)</p>
                <p className="is-size-4">(-$0.50)</p>
              </SpecialSpacing>
              <hr />
              <SpecialSpacing>
                <p className="is-size-3 has-text-weight-semibold">Net Payout</p>
                <p className="is-size-3 has-text-weight-semibold">$11.50</p>
              </SpecialSpacing>
              <Flex>
                <p className="is-size-3 has-text-weight-semibold">Item Total</p>
                <p className="is-size-3 has-text-weight-semibold">
                  {'  '} - 11
                </p>
              </Flex>
              <hr />

              <br />
            </DetailsFormWrapper>
          </section>
        </div>
      </Container>
    </>
  );
};

const OrderDetailsForm = withFormik({
  mapPropsToValues: () => ({}),

  validationSchema: yup.object().shape({}),

  handleSubmit: () => {},

  displayName: 'OrderDetailsForm',
})(Form);

export default OrderDetailsForm;
