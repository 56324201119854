import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { first } from 'lodash';
import ReactTooltip from 'react-tooltip';
import Delete from './DeleteButton';

const DictionaryList = ({ dictionary, users = [] }) => {
  const [active, setActive] = useState(true);
  if (!active) {
    return null;
  }

  const userArray = users.filter(
    item => item.user_id === dictionary.audit.created_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <tr>
      <td>
        <Link to={`/update-item-dictionary/${dictionary.item_dictionary_id}`}>
          {dictionary.name}
        </Link>
      </td>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip="" data-for={user.full_name}>
              {user.full_name}
            </p>
            <ReactTooltip
              id={user.full_name}
              place="top"
              type="dark"
              effect="float"
            >
              <span>
                <p>email: {user.email}</p>
                <p>objectId: {user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          dictionary.audit.updated_by
        )}
      </td>
      <td>{moment(dictionary.audit.updated_at).format('lll')}</td>
      <td>{dictionary.status}</td>
      <td>
        <Delete
          id={dictionary.item_dictionary_id}
          onCompleted={() => setActive(false)}
        />
      </td>
    </tr>
  );
};
export default DictionaryList;
