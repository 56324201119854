import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column, Level, Title, Field, Label } from 'rbx';
import { Query, Mutation } from 'react-apollo';
import { isNull, first, replace } from 'lodash';
import moment from 'moment';
import gql from 'graphql-tag';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';

import Layout from '../../../components/global/Layout';
import {
  Input,
  TextArea,
  Loading,
  Select,
  MobileInput,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  padding: 1rem;
`;

const ticketRecordQuery = gql`
  query searchEventTicketRecord($ticketId: String) {
    search_event_ticket_records(
      input: {
        filter: { event_ticket_record_filter: { ticket_id: $ticketId } }
      }
    ) {
      ticket_record_listing {
        booking_id
        ticket_record_id
        booking_number
        ticket_number
        ticket_type
        sales_method
        attendee {
          attendee_name
          attendee_age
          attendee_email
          attendee_phone
          attendee_gender
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      first_name
      last_name
      email
    }
  }
`;

const updateTicketRecord = gql`
  mutation update_ticket_record($input: TicketRecordPerAttendeeInput) {
    update_ticket_record(input: $input) {
      place_id
      error {
        description
      }
    }
  }
`;

const Form = props => {
  const [user, setUser] = useState();
  const {
    values,
    handleChange,
    handleBlur,
    history,
    record,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    loading,
  } = props;
  useEffect(() => {
    if (record.audit.created_by) {
      client.clientPrivate
        .query({
          query: userQuery,
          variables: { userId: record.audit.created_by },
        })
        .then(({ data }) => {
          if (data) {
            setUser(data.fetch_user);
          }
        })
        .catch(() => {});
    }
  }, [record]);

  return (
    <Container>
      <Level>
        <Level.Item align="left">
          <Title size={2}>Edit Attendee Info</Title>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-primary">
              <span>Back</span>
            </a>
          </Level.Item>
          <Level.Item>
            <a
              onClick={handleSubmit}
              className={`button is-primary ${loading && 'is-loading'}`}
            >
              <span>Update</span>
            </a>
          </Level.Item>
        </Level.Item>
      </Level>

      <Column.Group vcentered multiline gapSize={8}>
        <Column size={4}>
          <Input
            label="Booking No"
            value={record.booking_number}
            placeholder="TF287768857"
            disabled
          />
        </Column>

        <Column size={4}>
          <Input
            label="Ticket No"
            value={record.ticket_number}
            placeholder="N0FZQRNG"
            disabled
          />
        </Column>
      </Column.Group>

      <hr />

      <Column.Group vcentered multiline gapSize={8}>
        <Column size={4}>
          <Input
            label="Booked By"
            value={user && `${user.first_name} ${user.last_name}`}
            disabled
          />
        </Column>

        <Column size={4}>
          <Input
            label="Email Id"
            name="email"
            value={user && user.email}
            disabled
          />
        </Column>

        <Column size={4}>
          <Input
            label="Date And Time"
            value={moment(record.audit.created_at).format('lll')}
            disabled
          />
        </Column>

        <Column size={4}>
          <Input
            label="Purchased By"
            value={record.attendee.attendee_name}
            disabled
          />
        </Column>

        <Column size={4}>
          <Input
            label="Ticket Type"
            name="ticketType"
            value={record.ticket_type}
            disabled
          />
        </Column>

        <Column size={4}>
          <Input
            label="Ticket Sales Method"
            value={record.sales_method}
            disabled
          />
        </Column>
      </Column.Group>
      <br />

      <Column.Group vcentered multiline gapSize={8}>
        <Column size={6}>
          <Input
            label="Attendee Name"
            name="attendee_name"
            type="text"
            value={values.attendee_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Column>

        <Column size={6}>
          <MobileInput
            label="Attendee Mobile"
            type="number"
            name="attendee_phone"
            value={values.attendee_phone}
            onChange={value =>
              setFieldValue('attendee_phone', replace(value, ' ', ''))
            }
            onBlur={handleBlur}
          />
        </Column>

        <Column size={6}>
          <Input
            label="Attendee Email"
            type="email"
            name="attendee_email"
            value={values.attendee_email}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.attendee_email}
            touched={touched.attendee_email}
          />
        </Column>

        <Column size={3}>
          <Input
            label="Age"
            type="number"
            name="attendee_age"
            value={values.attendee_age}
            onChange={event => {
              const value = parseFloat(event.target.value).toFixed(0);

              if (
                (value <= 120 && value > 0 && event.target.value !== 'e') ||
                event.target.value === ''
              ) {
                setFieldValue('attendee_age', value);
              }
            }}
          />
        </Column>

        <Column size={3}>
          <Field>
            <Label className="is-flex">Gender</Label>
            <Select
              value={{
                label: values.attendee_gender,
                value: values.attendee_gender,
              }}
              options={[
                { value: 'MALE ', label: 'MALE' },
                { value: 'FEMALE', label: 'FEMALE' },
                { value: 'OTHER', label: 'OTHER' },
              ]}
              onChange={value => setFieldValue('attendee_gender', value.value)}
            />
          </Field>
        </Column>

        <Column size={8} rows={4}>
          <TextArea
            label="Attendee Notes"
            name="notes"
            value={values.notes}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Column>
      </Column.Group>
      <Level>
        <Level.Item align="left">
          <a onClick={() => history.goBack()} className="button is-primary">
            <span>Back</span>
          </a>
        </Level.Item>
        <Level.Item align="right">
          <a
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            <span>Update</span>
          </a>
        </Level.Item>
      </Level>
    </Container>
  );
};

const EditAttendeeInfoForm = withFormik({
  mapPropsToValues: ({ record }) => ({
    attendee_name: !isNull(record.attendee.attendee_name)
      ? record.attendee.attendee_name
      : '',
    attendee_phone: !isNull(record.attendee.attendee_phone)
      ? record.attendee.attendee_phone
      : '',
    attendee_email: !isNull(record.attendee.attendee_email)
      ? record.attendee.attendee_email
      : '',
    attendee_age: !isNull(record.attendee.attendee_age)
      ? record.attendee.attendee_age
      : '',
    attendee_gender: !isNull(record.attendee.attendee_gender)
      ? record.attendee.attendee_gender
      : '',
    notes: '',
  }),
  validationSchema: yup.object().shape({
    attendee_email: yup.string().email('Please enter valid email!'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(false);
    props.onSubmit(values);
  },
  displayName: 'EditAttendeeInfoForm',
})(Form);

const EditAttendeeInfo = ({ match, history }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={ticketRecordQuery}
        variables={{ ticketId: match.params.ticketId }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }

          const record = first(
            data.search_event_ticket_records.ticket_record_listing,
          );
          return (
            <Mutation
              client={client.clientPrivate}
              mutation={updateTicketRecord}
              onCompleted={() => {
                swal(
                  'Great!',
                  'Attendee details updated successfully!!',
                  'success',
                );
              }}
            >
              {(update_ticket_record, { loading: updateLoading }) => (
                <EditAttendeeInfoForm
                  loading={updateLoading}
                  record={record}
                  history={history}
                  onSubmit={input => {
                    console.log('input', input);
                    update_ticket_record({
                      variables: {
                        input: {
                          user_id: userId,
                          ticket_record_id: record.ticket_record_id,
                          attendee: {
                            attendee_name: input.attendee_name,
                            attendee_email: input.attendee_email,
                            attendee_age: parseInt(input.attendee_age, 10),
                            attendee_gender: input.attendee_gender,
                            attendee_phone: input.attendee_phone,
                          },
                        },
                      },
                    });
                  }}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default EditAttendeeInfo;
