import gql from 'graphql-tag';
import format from 'date-fns/format';
import { capitalize } from 'lodash';

import client from '../../../utils/apolloClient';

const searchTransactionsSummaryQuery = gql`
  query searchTransactionsSummaryQuery(
    $userId: String
    $placeName: String
    $cityFilter: String
    $from: Int
    $size: Int
    $startDate: String
    $endDate: String
  ) {
    search_transactions_summary(
      input: {
        filter: {
          place_filter: { place_name: $placeName, city: $cityFilter }
          menu_order_filter: {
            date_range: { start_date: $startDate, end_date: $endDate }
          }
        }
        user_id: $userId
        from: $from
        size: $size
      }
    ) {
      total_pages
      total_size
      transaction_summary_listing {
        place_id
        place_name
        all_orders
        other_fees
        promo
        sales
        sub_total
        payout
        app_fee
        pickup
        pickup_sales
        dinein
        dinein_sales
        delivery
        delivery_sales
        c_fees
        srv_fees
        del_fees
        b_fees
        k_fees
        f_fees
      }
    }
  }
`;

const fetchTransactionsSummary = async ({
  userId,
  from = 0,
  size = 50,
  placeName,
  cityFilter,
  startDate,
  endDate,
  log = false,
}) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTransactionsSummaryQuery,
      variables: {
        userId,
        startDate,
        endDate,
        from,
        size,
        placeName,
        cityFilter,
      },
      fetchPolicy: 'network-only',
    });
    if (
      data &&
      data.data &&
      data.data.search_transactions_summary &&
      Array.isArray(
        data.data.search_transactions_summary.transaction_summary_listing,
      )
    ) {
      if (log)
        console.log(
          'search_transactions_summary',
          data.data.search_transactions_summary,
        );
      return data.data.search_transactions_summary;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const transformTransactionsTotals = ele => {
  if (ele.transaction_summary_listing.length !== 0) {
    const temp = ele.transaction_summary_listing;
    return temp.map(ele2 => ({
      placeId: ele2.place_id,
      Venue: ele2.place_name,
      Orders: ele2.all_orders,
      Subtotal: `$${parseFloat(ele2.sub_total ?? 0).toFixed(2)}`,
      Promo: `$${parseFloat(ele2.promo ?? 0).toFixed(2)}`,
      'Oth Charges': `$${parseFloat(ele2.other_fees ?? 0).toFixed(2)}`,
      Total: `$${parseFloat(ele2.sales ?? 0).toFixed(2)}`,
      'Application Fee': `$${parseFloat(ele2.app_fee ?? 0).toFixed(2)}`,
      Payout: `$${parseFloat(ele2.payout ?? 0).toFixed(2)}`,
      'Service Fees': `$${parseFloat(ele2.srv_fees ?? 0).toFixed(2)}`,
      'Delivery Fee': `$${parseFloat(ele2.del_fees ?? 0).toFixed(2)}`,
      'C Fees': `$${parseFloat(ele2.c_fees ?? 0).toFixed(2)}`,
      'KRAVEiN Fees': `$${parseFloat(ele2.k_fees ?? 0).toFixed(2)}`,
      'Franchisee Fees': `$${parseFloat(ele2.f_fees ?? 0).toFixed(2)}`,
      'B Fees': `$${parseFloat(ele2.b_fees ?? 0).toFixed(2)}`,
      'Del Orders': ele2.delivery,
      'Del Sales': `$${parseFloat(ele2.delivery_sales ?? 0).toFixed(2)}`,
      'Pickup Orders': ele2.pickup,
      'Pickup Sales': `$${parseFloat(ele2.pickup_sales ?? 0).toFixed(2)}`,
      'Dinein Orders': ele2.dinein,
      'Dinein Sales': `$${parseFloat(ele2.dinein_sales ?? 0).toFixed(2)}`,
    }));
  }
  return null;
};

const searchTransactionsDetailQuery = gql`
  query searchTransactionsDetailQuery(
    $userId: String
    $placeId: [String]
    $startDate: String
    $endDate: String
    $from: Int
    $size: Int
  ) {
    search_transactions_detail(
      input: {
        filter: {
          place_filter: { place_id: $placeId }
          menu_order_filter: {
            date_range: { start_date: $startDate, end_date: $endDate }
          }
        }
        user_id: $userId
        from: $from
        size: $size
      }
    ) {
      total_pages
      total_size
      transaction_detail_listing {
        order_date
        other_fees
        promo
        place_name
        all_orders
        sales
        sub_total
        payout
        app_fee
        pickup
        pickup_sales
        dinein
        dinein_sales
        delivery
        delivery_sales
        c_fees
        srv_fees
        del_fees
        k_fees
        f_fees
        b_fees
      }
    }
  }
`;

const fetchTransactionsDetails = async ({
  userId,
  placeId,
  startDate,
  endDate,
  from = 0,
  size = 50,
  log = false,
}) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTransactionsDetailQuery,
      variables: { placeId: [placeId], userId, startDate, endDate, from, size },
      fetchPolicy: 'network-only',
    });
    if (
      data &&
      data.data &&
      data.data.search_transactions_detail &&
      Array.isArray(
        data.data.search_transactions_detail.transaction_detail_listing,
      )
    ) {
      if (log)
        console.log(
          'search_transactions_detail',
          data.data.search_transactions_detail,
        );
      return data.data.search_transactions_detail;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const transformTransactionsDetails = ele => {
  if (ele.transaction_detail_listing.length !== 0) {
    const temp = ele.transaction_detail_listing;
    return temp.map(ele2 => ({
      orderDate: ele2.order_date,
      Venue: ele2.place_name,
      Orders: ele2.all_orders,
      Subtotal: `$${parseFloat(ele2.sub_total ?? 0).toFixed(2)}`,
      Promo: `$${parseFloat(ele2.promo ?? 0).toFixed(2)}`,
      'Oth Charges': `$${parseFloat(ele2.other_fees ?? 0).toFixed(2)}`,
      Total: `$${parseFloat(ele2.sales ?? 0).toFixed(2)}`,
      'Application Fee': `$${parseFloat(ele2.app_fee ?? 0).toFixed(2)}`,
      Payout: `$${parseFloat(ele2.payout ?? 0).toFixed(2)}`,
      'Service Fees': `$${parseFloat(ele2.srv_fees ?? 0).toFixed(2)}`,
      'Delivery Fee': `$${parseFloat(ele2.del_fees ?? 0).toFixed(2)}`,
      'KRAVEiN Fees': `$${parseFloat(ele2.k_fees ?? 0).toFixed(2)}`,
      'Franchisee Fees': `$${parseFloat(ele2.f_fees ?? 0).toFixed(2)}`,
      'Royalty Fees': `$${parseFloat(ele2.c_fees ?? 0).toFixed(2)}`,
      'B Fees': `$${parseFloat(ele2.b_fees ?? 0).toFixed(2)}`,
      'Del Orders': ele2.delivery,
      'Del Sales': `$${parseFloat(ele2.delivery_sales ?? 0).toFixed(2)}`,
      'Pickup Orders': ele2.pickup,
      'Pickup Sales': `$${parseFloat(ele2.pickup_sales ?? 0).toFixed(2)}`,
      'Dinein Orders': ele2.dinein,
      'Dinein Sales': `$${parseFloat(ele2.dinein_sales ?? 0).toFixed(2)}`,
    }));
  }
  return null;
};

const searchPlacesQuery = gql`
  query searchPlacesQuery($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        location
      }
    }
  }
`;

const fetchPlaceData = async ({ placeId, userId }) => {
  try {
    const { data } = await client.clientPrivate.query({
      query: searchPlacesQuery,
      variables: {
        input: {
          filter: {
            place_filter: {
              place_id: placeId,
            },
          },
          user_id: userId,
        },
      },
    });
    if (
      data &&
      data.search_places &&
      Array.isArray(data.search_places.place_listing) &&
      data.search_places.place_listing[0]
    ) {
      return data.search_places.place_listing[0];
    }
    return null;
  } catch {
    // pass
    return null;
  }
};

const searchTransactionsOrderQuery = gql`
  query searchTransactionsOrderQuery(
    $userId: String
    $placeId: [String]
    $startDate: String
    $endDate: String
    $from: Int
    $size: Int
  ) {
    search_transactions_order(
      input: {
        filter: {
          place_filter: { place_id: $placeId }
          menu_order_filter: {
            date_range: { start_date: $startDate, end_date: $endDate }
          }
        }
        size: $size
        from: $from
        user_id: $userId
      }
    ) {
      total_pages
      total_size
      transaction_detail_listing {
        menu_order_id
        payment
        payment_method_type
        other_fees
        promo
        order_no
        order_date
        service_type
        amount
        payout
        sub_total
        app_fee
        c_fees
        srv_fees
        del_fees
        k_fees
        f_fees
        b_fees
      }
    }
  }
`;

const fetchTransactionsOrders = async ({
  userId,
  placeId,
  startDate,
  endDate,
  from = 0,
  size = 50,
  log = false,
}) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchTransactionsOrderQuery,
      variables: { placeId: [placeId], userId, startDate, endDate, from, size },
      fetchPolicy: 'network-only',
    });
    if (
      data &&
      data.data &&
      data.data.search_transactions_order &&
      Array.isArray(
        data.data.search_transactions_order.transaction_detail_listing,
      )
    ) {
      if (log)
        console.log(
          'search_transactions_detail',
          data.data.search_transactions_order,
        );
      return data.data.search_transactions_order;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPaymentTitle = to => {
  const { PaymentMethodType, Payment } = to;
  if (PaymentMethodType) {
    if (PaymentMethodType === 'G_PAY') {
      return 'Google Pay';
    }
    if (PaymentMethodType === 'APPLE_PAY') {
      return 'Apple Pay';
    }
    if (PaymentMethodType === 'CARD') {
      return 'Card';
    }
  }
  return capitalize(Payment);
};

const transformTransactionsOrders = ele => {
  if (ele.transaction_detail_listing.length !== 0) {
    const temp = ele.transaction_detail_listing;
    return temp.map(ele2 => ({
      orderId: ele2.menu_order_id,
      orderNumber: ele2.order_no,
      orderDate: format(new Date(ele2.order_date), 'dd-MMM, p'),
      service: ele2.service_type,
      amount: `$${parseFloat(ele2.amount ?? 0).toFixed(2)}`,
      Promo: `$${parseFloat(ele2.promo ?? 0).toFixed(2)}`,
      Subtotal: `$${parseFloat(ele2.sub_total ?? 0).toFixed(2)}`,
      Payout: `$${parseFloat(ele2.payout ?? 0).toFixed(2)}`,
      Payment: ele2.payment ?? '-',
      PaymentMethodType: ele2.payment_method_type ?? '-',
      'Application Fees': `$${parseFloat(ele2.app_fee ?? 0).toFixed(2)}`,
      'Royalty Fees': `$${parseFloat(ele2.c_fees ?? 0).toFixed(2)}`,
      'Service Fees': `$${parseFloat(ele2.srv_fees ?? 0).toFixed(2)}`,
      'Delivery Fees': `$${parseFloat(ele2.del_fees ?? 0).toFixed(2)}`,
      'KRAVEiN Fees': `$${parseFloat(ele2.k_fees ?? 0).toFixed(2)}`,
      'Franchisee Fees': `$${parseFloat(ele2.f_fees ?? 0).toFixed(2)}`,
      'B Fees': `$${parseFloat(ele2.b_fees ?? 0).toFixed(2)}`,
    }));
  }
  return null;
};

const searchOrdersQuery = gql`
  query searchOrders($input: SearchInput) {
    search_menu_orders(input: $input) {
      total_pages
      menu_order_listing {
        scheduled_date_time
        is_scheduled
        user_id
        menu_order_id
        estimated_completion_time
        no_of_attendees
        status
        table_number
        table_name
        pickup_method
        vehicle_information {
          car_detail
          license_plate
        }
        refunds {
          refund_id
          refund_amount
          refund_reason
          status
          refund_from_place
          refund_application_fee
          created_at
          created_by
          note {
            value
          }
        }
        payment {
          payment_method_type
          payment_method
          payment_method_id
          payment_intent_id
          payment_status
          payment_card {
            brand
            country
            last4
          }
          payment_at
        }
        menu {
          name
        }
        place {
          latitude
          longitude
          name
          city
        }
        large_order
        include_cuttlery
        address {
          type
          address_line_1
          address_line_2
          city
          state
          post_code
          country
          delivery_option {
            type
            caption
          }
        }
        place_id
        order_reference
        service_type
        received {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        confirmed {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        ready {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        complete {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        cancelled {
          created_by
          created_at
          status
          user_type
          user_data {
            first_name
            last_name
            email
          }
          note {
            type
            value
            date
          }
        }

        overall_quantity
        line_item {
          name
          variant_name
          price
          overall_price
          modifier {
            name
            variant_name
            price
            price_per_item
            net_price_per_item
            quantity
          }
          special_request
          quantity
        }
        contact {
          contact_name
          contact_phone
        }
        billing {
          sub_total
          order_total
          deal_discount
          payment_gateway_fee
          adjustment_amount
          order_amount
          delivery_fee
          charge
          charges_list {
            name
            value
            is_payment_gateway_fee
          }
        }
        adjustments {
          name
          amount
          note
          adjusted_at
        }
        estimates {
          type
          value_in_mins
        }
        service_type_setting {
          display_table_number
          allow_pickup_options
          pickup_method
          pickup_instructions
          include_vehicle_information
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

const getOrders = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchOrdersQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu_orders && data.search_menu_orders) {
          resolve(data.search_menu_orders);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

const downloadCSVFile = (csvData, fileName) => {
  const CSVFile = new Blob([csvData], { type: 'text/csv' });
  const temp_link = document.createElement('a');
  temp_link.download = fileName;
  const url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;
  temp_link.style.display = 'none';
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
};

export {
  fetchTransactionsSummary,
  transformTransactionsTotals,
  downloadCSVFile,
  fetchTransactionsDetails,
  transformTransactionsDetails,
  fetchPlaceData,
  fetchTransactionsOrders,
  transformTransactionsOrders,
  getOrders,
};

export default {};
