/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { omit } from 'lodash';

import CategoriesUI from './CategoriesUi';

import client from '../../../../../utils/apolloClient';

import {
  getLinks,
  transformLinks,
  getMenusOfMenuSection,
  updateMenuSection,
} from './helper';

const updateMenuMutation = gql`
  mutation updateMenu($input: [MenuInput]) {
    update_menu(input: $input) {
      menu_id
      error {
        description
      }
    }
  }
`;

const CategoriesSection = ({
  setItemsOfActiveCategory,
  setActiveCategory,
  setItemIsLoading,
  categories,
  categoriesIsLoading,
  activeCategory,
  // location,
  menuId,
  // placeId,
  setItemDetailsFormData,
  setCategories,
  editCategories,
  editsDispatch,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const [updateMenu] = useMutation(updateMenuMutation, {
    client: client.clientPrivate,
    onCompleted: () => {},
  });

  const categoryBtnClickHandler = async category => {
    setItemDetailsFormData(null);
    setActiveCategory(category.menu_section_id);
    setItemIsLoading(true);
    try {
      let links = await getLinks({ menuId });
      links =
        Array.isArray(links) && links.length !== 0
          ? links.filter(
              lnk =>
                lnk.menu_section.menu_section_id === category.menu_section_id,
            )
          : [];
      if (links.length !== 0) {
        setItemsOfActiveCategory(transformLinks(links));
        setItemIsLoading(false);
      } else {
        setItemsOfActiveCategory([]);
        setItemIsLoading(false);
        swal({
          title: 'There are no items assigned to this category',
          text: 'Please add items using the Add Menu Items button',
        });
      }
    } catch (error) {
      setItemsOfActiveCategory([]);
      setItemIsLoading(false);
      console.log(error);
    }
  };

  const onTrashIconClick = (categoryId, setIsDeleting) => {
    swal('Are you sure you want to delete the category from the menu?', {
      buttons: ['Cancel', true],
    }).then(async willDelete => {
      if (willDelete) {
        setIsDeleting(true);
        try {
          let links = await getLinks({ menuId });
          links =
            Array.isArray(links) && links.length !== 0
              ? links.filter(
                  lnk => lnk.menu_section.menu_section_id === categoryId,
                )
              : [];
          if (links.length === 0) {
            const menus = await getMenusOfMenuSection({
              menuSectionId: categoryId,
            });
            if (Array.isArray(menus) && menus.length !== 0) {
              const filteredMenus = menus.filter(mnu => mnu !== menuId);
              await updateMenuSection({
                userId,
                menuSectionId: categoryId,
                menuIds: filteredMenus,
              });
            }
            updateMenu({
              variables: {
                input: {
                  menu_section: categories
                    .filter(category => category.menu_section_id !== categoryId)
                    .map(category => omit(category, '__typename')),
                  user_id: userId,
                  menu_id: menuId,
                },
              },
            });

            setActiveCategory(null);
            setItemsOfActiveCategory([]);
            setItemDetailsFormData(null);
            setCategories(prev =>
              prev.filter(ele => ele.menu_section_id !== categoryId),
            );
            setIsDeleting(false);
          } else {
            swal({
              icon: 'warning',
              dangerMode: true,
              text:
                // eslint-disable-next-line max-len
                'This category has items. To remove the Category from this Menu, please remove all Items from this Category.',
            });
            setIsDeleting(false);
            setActiveCategory(categoryId);
            setItemsOfActiveCategory(transformLinks(links));
          }
        } catch (error) {
          console.log('error at onTrashIconClick handler', { error });
          setIsDeleting(false);
        }
      }
    });
  };

  return (
    <CategoriesUI
      categories={categories}
      activeCategory={activeCategory}
      onClick={categoryBtnClickHandler}
      setCategories={setCategories}
      categoriesIsLoading={categoriesIsLoading}
      editCategories={editCategories}
      editsDispatch={editsDispatch}
      onTrashIconClick={onTrashIconClick}
      userId={userId}
      menuId={menuId}
    />
  );
};

export default CategoriesSection;
