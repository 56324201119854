import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import Can from '../../../utils/Can';
import { ACLSConsumer } from '../../../utils/aclsContext';

const Container = styled.div`
  padding: 1rem 0.5rem;
`;

const Header = ({ loading, handleSubmit, history, location }) => (
  <Container>
    <Level>
      <Level.Item align="left">
        <Title>Admin User</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            Cancel
          </a>
        </Level.Item>
        <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/admin-user-activity'
                  ? 'update_user'
                  : 'update_user'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item>
                  <button
                    className={`button is-primary ${loading && 'is-loading'}`}
                    onClick={handleSubmit}
                  >
                    <span>Submit</span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer>
      </Level.Item>
    </Level>
  </Container>
);

export default withRouter(Header);
