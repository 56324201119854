/* eslint-disable array-callback-return */
import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import { isNull, forEach } from 'lodash';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
import { Loading, Message } from '../../../components/elements';
import { getEncodedImage } from '../../../utils/s3';
import Layout from '../../../components/global/Layout';
import PostForm from '../PlacePostingAdd/PostForm';

const allNeededQuery = gql`
  query fetch_place(
    $placeId: String
    $feedId: String!
    $objectType: [String]
    $objectId: [String]
    $parentType: [String]
  ) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      timezone
      status
      claim_status
    }

    fetch_feed(input: { feed_id: $feedId }) {
      feed_id
      object_id
      object_type
      title
      content
      status
      approval_status
      default_image_url
      error {
        code
        description
      }
    }
    search_images(
      input: {
        filter: {
          image_filter: {
            object_type: $objectType
            object_id: $objectId
            parent_type: $parentType
          }
        }
      }
    ) {
      image_id
      url
      link
      tooltip
      tags
    }
  }
`;

const updatePostMutation = gql`
  mutation updatePost($input: FeedInput) {
    update_feed(input: $input) {
      feed_id
      error {
        description
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
      });
    }
  });
  return imageValues;
};

const parseSearchImages = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        id: item.image_id,
        url: item.url,
        preview: getEncodedImage(item.url, 300, 300),
        tooltip: item.tooltip,
        link: item.link,
        tag: item.tags,
        uploadingStatus: 'uploaded',
      });
    }
  });
  return imageValues;
};

const PlacePostingUpdate = ({ match, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [placeId, setPlaceId] = React.useState('');
  const [images, setImages] = React.useState([]);
  return (
    <Layout>
      <Query
        query={allNeededQuery}
        variables={{
          placeId: match.params.placeId,
          feedId: match.params.feedId,
          objectType: 'PLACE_FEED',
          parentType: 'PLACE',
          objectId: match.params.feedId,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Message>error</Message>;
          }

          const { fetch_place, fetch_feed, search_images } = data;

          return (
            <Mutation
              client={client.clientPrivate}
              mutation={createImageMutation}
              onCompleted={() => {
                swal(
                  'Great!',
                  'Place posting with images has been successfully updated!',
                  'success',
                ).then(() => {
                  history.goBack();
                });
              }}
            >
              {(create_image, { loading: imageLoading, error: imageError }) => (
                <Mutation
                  client={client.clientPrivate}
                  mutation={updatePostMutation}
                  onCompleted={({ update_feed }) => {
                    if (!isNull(update_feed.error)) {
                      update_feed.error.map(item => {
                        toast.error(item.description);
                      });
                    } else if (images.length !== 1) {
                      create_image({
                        variables: {
                          input: {
                            user_id: userId,
                            object_id: update_feed.feed_id,
                            object_type: 'PLACE_FEED',
                            parent_id: placeId,
                            upload_type: 'ADMIN',
                            parent_type: 'PLACE',
                            app_type: 'ADMINUSER',
                            images: parseImage(images),
                          },
                        },
                      });
                    } else {
                      swal(
                        'Great!',
                        'Place posting has been successfully updated!',
                        'success',
                      ).then(() => {
                        history.goBack();
                      });
                    }
                  }}
                >
                  {(
                    update_feed,
                    { loading: feedLoading, error: feedError },
                  ) => (
                    <PostForm
                      error={feedError || imageError}
                      userId={userId}
                      loading={feedLoading || imageLoading}
                      place={fetch_place}
                      feed={fetch_feed}
                      uploadedImages={parseSearchImages(search_images)}
                      onSubmit={value => {
                        setImages(value.image);
                        setPlaceId(value.place_id);
                        const final = {};
                        Object.assign(
                          final,
                          {
                            approval_status: value.approval_status,
                          },
                          { content: value.content },
                          { default_image_url: value.default_image_url },
                          { feed_id: value.feed_id },
                          { object_id: value.object_id },
                          { object_type: value.object_type },
                          { status: value.status },
                          { title: value.title },
                          { user_id: userId },
                        );
                        update_feed({ variables: { input: final } });
                      }}
                      isAddComponent={false}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    </Layout>
  );
};

export default withRouter(PlacePostingUpdate);
