import React from 'react';
import { Table } from '../../../components/elements';

const MenuServiceAuditTable = ({ loading, placeMenuServiceAudit }) => (
  <>
    <Table>
      <thead>
        <tr>
          <th>
            <div>
              <span>
                <a>
                  <i
                  // className={
                  //   placeNameSort === 'AES'
                  //     ? 'fas fa-sort-amount-down'
                  //     : 'fas fa-sort-amount-up'
                  // }
                  />
                </a>
                Place Name
              </span>
            </div>
          </th>
          <th>
            <div>
              <span>Service Type</span>
            </div>
          </th>
          <th>
            <span>Status</span>
          </th>
          <th>
            <span>Catering</span>
          </th>
          <th>
            <span>Payment</span>
          </th>
          <th>
            <span>Menu</span>
          </th>
          <th>
            <span>A / Confirm</span>
          </th>
          <th>
            <span>A / Ready</span>
          </th>
          <th>
            <span>A / Cancel</span>
          </th>
          <th>
            <span>Cancel Time</span>
          </th>
          <th>
            <span>Delivery Channel</span>
          </th>
          <th>
            <span>API Key</span>
          </th>
          <th>
            <span>Prep</span>
          </th>
          <th>
            <span>Zone Est/Fee</span>
          </th>
          <th>
            <span>Schd in Adv</span>
          </th>
          <th>
            <span>Schd when Closed</span>
          </th>
        </tr>
      </thead>

      <tbody>
        {placeMenuServiceAudit.map(pms => {
          const zoneText = pms?.zone_fee
            ? [...pms.zone_fee]
                .map(
                  ele =>
                    `${ele.name} : ${
                      ele.delivery_estimate
                    } min(s), $${parseFloat(ele?.delivery_fee ?? 0).toFixed(
                      2,
                    )}`,
                )
                .join('\n')
            : '-';
          return (
            <>
              <tr key="">
                <td
                  style={{
                    cursor: 'pointer',
                    color: '#00AEEF',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span>{pms.name}</span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>{pms.service_type}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{pms.status}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{pms.catering}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{pms.payment_method}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{pms.menu_name}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {pms.auto_confirm ? 'Y' : 'N'}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {pms.auto_ready ? 'Y' : 'N'}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {pms?.auto_cancel?.is_auto_cancel ? 'Y' : 'N'}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {`${pms?.auto_cancel?.time_in_mins} Min(s)`}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {pms?.delivery_channel}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>{pms?.api_key}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {`${pms?.preparation_estimate} Min(s)`}
                </td>
                <td style={{ whiteSpace: 'pre-line', minWidth: '170px' }}>
                  {zoneText}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {pms?.schedule_in_advance ? 'Y' : 'N'}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {pms?.schedule_order_when_closed ? 'Y' : 'N'}
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </Table>
    {loading && (
      <span style={{ padding: '1rem', color: '#00AEEF' }}>Loading...</span>
    )}
  </>
);

export default MenuServiceAuditTable;
