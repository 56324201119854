import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import moment from 'moment';
import { Title, Table } from 'rbx';
import { sortBy, reverse } from 'lodash';
import { Table as CustomTable, Box } from '../../../components/elements';
import BookingByTicketList from './BookingByTicketList';

const Heading = styled.div`
  padding: 0.5rem 1rem;
  background-color: #f6f6f6;
  margin-bottom: 1rem;
`;

const BookingByTicket = ({ tickets, setSort }) => {
  const [ticketData, setTicketData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [ticketCategorySort, setTicketCategorySort] = useState('TICKET_AES');
  const [quantitySort, setQuantitySort] = useState('QUANTITY_ASC');
  const [priceSort, setPriceSort] = useState('PRICE_ASC');
  const [oLPassOnFee, setOLPassOnFee] = useState('OLPassOnFee_AES');
  const [oLAbsorbFee, setOLAbsorbFee] = useState('OLAbsorbFee_AES');
  const [agentPassOnFee, setAgentPassOnFee] = useState('AgentPassOnFee_AES');
  const [agentAbsorbFee, setAgentAbsorbFee] = useState('AgentAbsorbFee_AES');
  const [ticketType, setTicketType] = useState('TicketType_AES');

  return (
    <Box>
      {/* {totalElements > 0 && (
        <Pagination
          setPageCount={setPageCount}
          setTotalElements={setTotalElements}
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )} */}
      <Heading>
        <Title size={3}>Booking By Ticket Category</Title>
      </Heading>
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        ticketCategorySort === 'TICKET_AES'
                          ? sortBy(ticketData, 'ticket_name')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setTicketCategorySort(
                        ticketCategorySort === 'TICKET_AES'
                          ? 'TICKET_DES'
                          : 'TICKET_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        ticketCategorySort === 'TICKET_AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Ticket Category
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        quantitySort === 'QUANTITY_ASC'
                          ? sortBy(ticketData, 'QUANTITY')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setQuantitySort(
                        quantitySort === 'QUANTITY_ASC'
                          ? 'QUANTITY_DESC'
                          : 'QUANTITY_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        quantitySort === 'QUANTITY_ASC'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Quantity
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        priceSort === 'PRICE_ASC'
                          ? sortBy(ticketData, 'price')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setPriceSort(
                        priceSort === 'PRICE_ASC' ? 'PRICE_DESC' : 'PRICE_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        priceSort === 'QUANTITY_ASC'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Price
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        oLPassOnFee === 'OLPassOnFee_AES'
                          ? sortBy(ticketData, 'OLPassOnFee')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setOLPassOnFee(
                        oLPassOnFee === 'OLPassOnFee_AES'
                          ? 'OLPassOnFee_DESC'
                          : 'OLPassOnFee_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        oLPassOnFee === 'OLPassOnFee_AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  OL Pass On Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        oLAbsorbFee === 'OLAbsorbFee_AES'
                          ? sortBy(ticketData, 'oLAbsorbFee')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setOLAbsorbFee(
                        oLAbsorbFee === 'OLAbsorbFee_AES'
                          ? 'OLAbsorbFee_DESC'
                          : 'OLAbsorbFee_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        oLAbsorbFee === 'OLAbsorbFee_AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  OL Absorb Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        agentPassOnFee === 'AgentPassOnFee_AES'
                          ? sortBy(ticketData, 'agentPassOnFee')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setAgentPassOnFee(
                        agentPassOnFee === 'AgentPassOnFee_AES'
                          ? 'AgentPassOnFee_DESC'
                          : 'AgentPassOnFee_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        agentPassOnFee === 'AgentPassOnFee_AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Agent Pass On Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        agentAbsorbFee === 'AgentAbsorbFee_AES'
                          ? sortBy(ticketData, 'agentPassOnFee')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setAgentAbsorbFee(
                        agentAbsorbFee === 'AgentAbsorbFee_AES'
                          ? 'AgentAbsorbFee_DESC'
                          : 'AgentAbsorbFee_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        agentAbsorbFee === 'AgentAbsorbFee_AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Agent Absorb Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        ticketType === 'TicketType_AES'
                          ? sortBy(ticketData, 'agentPassOnFee')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setTicketType(
                        ticketType === 'TicketType_AES'
                          ? 'TicketType_DESC'
                          : 'TicketType_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        ticketType === 'TicketType_AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Ticket Type
                </span>
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>

        <View
          ticket={tickets}
          ticketData={ticketData}
          setTicketData={setTicketData}
          setPageCount={setPageCount}
          setTotalElements={setTotalElements}
        />
      </CustomTable>
    </Box>
  );
};
export default BookingByTicket;
const View = ({
  ticket,
  setPageCount,
  setTotalElements,
  setTicketData,
  ticketData,
}) => {
  useEffect(() => {
    setPageCount(ticket.total_pages);
    setTotalElements(ticket.total_size);
    if (ticket.ticket_record_listing) {
      setTicketData(ticket.ticket_record_listing);
    }
  }, [ticket.total_pages]);

  return (
    <>
      <BookingByTicketList
        aggregationTicket={ticket.ticket_record_aggregation}
        tickets={ticket}
      />
    </>
  );
};
