import React, { useState } from 'react';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import ItemSuggestionInput from './ItemSuggestionInput';
import apolloClient from '../../../../utils/apolloClient';

const swapItemReviewMutation = gql`
  mutation swapItemReview($input: SwapItemReviewInput) {
    swap_item_review(input: $input) {
      item_id
      item_review_id
      content
      error {
        description
      }
    }
  }
`;

const swapItemReview = ({ client, input }) =>
  new Promise((resolve, reject) => {
    client
      .mutate({ mutation: swapItemReviewMutation, variables: {input} })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });

const ItemReview = ({ placeId, reviewId, onHandleSelect, ...props }) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const { userId } = useStoreState(state => state.auth);
  if (!disabled) {
    return (
      <ItemSuggestionInput
        placeId={placeId}
        onSelect={value => {
          swal({
            title: 'Are you sure?',
            text: 'You want to swap item review.',
            buttons: true,
          }).then(async willDelete => {
            if (willDelete) {
              setDisabled(true);
              setLoading(true);
              await swapItemReview({
                client: apolloClient.clientPrivate,
                input: {
                  user_id: userId,
                  item_id: value.item_id,
                  item_review_id: reviewId,
                },
              });

              onHandleSelect(value);
              setLoading(false);
            } else {
              setDisabled(true);
            }
          });
        }}
      />
    );
  }

  return (
    <div>
      <div className="field has-addons is-fullwidth">
        <div className="control" style={{ width: '100%' }}>
          <input
            className="input is-fullwidth"
            disabled={disabled}
            {...props}
          />
        </div>
        <div className="control is-fullwidth">
          <a
            className={`button is-info ${loading && 'is-loading'}`}
            onClick={() => setDisabled(false)}
          >
            Edit
          </a>
        </div>
      </div>
    </div>
  );
};

export default ItemReview;
