import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { first, isNull, forEach, omit, isEqual } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import client from '../../../utils/apolloClient';
// import qs from 'querystringify';

import Layout from '../../../components/global/Layout';
import ItemReviewForm from './ItemReviewForm';
import { Loading, Message } from '../../../components/elements';

// import PlaceSelection from '../../../components/PlaceSelection';

const itemReviewsQuery = gql`
  query fetchItemReview($itemReviewId: String, $itemId: String) {
    fetch_item_review(
      input: { item_id: $itemId, item_review_id: $itemReviewId }
    ) {
      place_id
      item_id
      item_review_id
      item {
        name
      }
      status
      rating
      note {
        type
        value
        date
      }
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      content

      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      item_review_audit {
        item_review_approved_by
        item_review_approved_at
        item_review_rejected_by
        item_review_rejected_at
      }
      error {
        description
      }
    }
  }
`;

const placeImageQuery = gql`
  query fetchItemReview($itemReviewId: String, $placeId: String) {
    search_image_review(
      input: {
        filter: {
          image_review_filter: {
            object_id: $itemReviewId
            parent_id: $placeId
            object_type: "ITEM_REVIEW"
            parent_type: "PLACE"
            approval_status_many: ["SUBMITTED", "APPROVED", "REJECTED"]
          }
        }
      }
    ) {
      image_review_listing {
        image_id
        image_id
        url
        tags
        approval_status
        status
      }
    }
  }
`;

const placeQuery = gql`
  query placeReview($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      city
      status
      claim_status
      listing_status
      post_code
      country
      item_review_place
    }
  }
`;

const actionItemReviewMutation = gql`
  mutation actionItemReview($input: ActionItemReviewInput) {
    action_item_review(input: $input) {
      place_id
      item_id
      item_review_id
      error {
        description
      }
    }
  }
`;

const approvePhotoMutation = gql`
  mutation approvePhotosAction(
    $user_id: String
    $image_id: String
    $image_review_status: String
  ) {
    action_image(
      input: {
        user_id: $user_id
        image_id: $image_id
        approval_status: $image_review_status
      }
    ) {
      status
      image_id
      url
      error {
        description
      }
    }
  }
`;

const updateItemMutation = gql`
  mutation update_item_mutation($input: ItemReviewInput) {
    update_item_review(input: $input) {
      item_id
      item_review_id
      error {
        description
      }
    }
  }
`;

const handleImageApprove = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        mutation: approvePhotoMutation,
        variables: {
          ...input,
        },
      })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const PlaceReview = ({ match, history }) => {
  const { itemId, reviewId } = match.params;
  const [currentImages, setCurrentImages] = useState([]);
  let reviewStatusFromDb = '';
  const [action, setAction] = useState('');
  const [imageApprovalLoading, setImageApprovalLoading] = useState(false);

  const { userId } = useStoreState(state => state.auth);

  const handleImageApproveFunc = async values => {
    setImageApprovalLoading(true);
    const promises = [];
    values.map(item => {
      const formStatus = action;
      const itemStatus = item.approval_status;
      let approvalStatus;
      if (formStatus === 'REJECTED' || itemStatus === 'REJECTED') {
        approvalStatus = 'REJECTED';
      } else {
        approvalStatus = 'APPROVED';
      }

      if (
        reviewStatusFromDb === 'REJECTED' &&
        formStatus === 'APPROVED' &&
        values.every(item2 => item2.approval_status === 'REJECTED')
      ) {
        approvalStatus = 'APPROVED';
      }

      promises.push(
        handleImageApprove({
          user_id: userId,
          image_id: item.image_id,
          image_review_status: approvalStatus,
        }),
      );
      return null;
    });

    try {
      await Promise.all(promises);
      swal(
        'Hurray!',
        `Item Review ${
          action === 'APPROVED' ? 'APPROVED' : 'REJECTED'
        } successfully!`,
        'success',
      ).then(() => {
        setImageApprovalLoading(false);
        history.goBack();
      });
    } catch (error1) {
      console.log(error1);
    }
  };

  return (
    <Layout>
      <Query
        client={client.clientPublic}
        query={itemReviewsQuery}
        variables={{
          itemId,
          itemReviewId: reviewId,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Message>{error.message}</Message>;
          }

          const review = first(data.fetch_item_review);
          reviewStatusFromDb = review?.status;

          return (
            <Query
              client={client.clientPrivate}
              query={placeImageQuery}
              variables={{
                placeId: review.place_id,
                itemId,
                itemReviewId: reviewId,
              }}
              fetchPolicy="network-only"
            >
              {({ data: data1, loading: loading1, error: error1 }) => {
                if (loading1) {
                  return <Loading />;
                }
                if (error1) {
                  return <Message>{JSON.stringify(error)}</Message>;
                }

                const images = data1.search_image_review.image_review_listing;
                return (
                  <Query
                    query={placeQuery}
                    variables={{
                      placeId: review.place_id,
                    }}
                    fetchPolicy="network-only"
                  >
                    {({ data: data2, loading: loading2, error: error2 }) => {
                      if (loading2) {
                        return <Loading />;
                      }
                      if (error2) {
                        return <Message>{JSON.stringify(error2)}</Message>;
                      }
                      const place = data2.fetch_place;
                      return (
                        <Mutation
                          client={client.clientPrivate}
                          mutation={actionItemReviewMutation}
                          onCompleted={async ({ action_item_review }) => {
                            if (!isNull(action_item_review.error)) {
                              action_item_review.error.map(item =>
                                toast.error(item.description),
                              );
                            } else if (currentImages.length !== 0) {
                              handleImageApproveFunc(currentImages);
                            } else {
                              swal(
                                'Hurray!',
                                `Item Review ${
                                  action === 'APPROVED'
                                    ? 'APPROVED'
                                    : 'REJECTED'
                                } successfully!`,
                                'success',
                              ).then(() => {
                                history.goBack();
                              });
                            }
                          }}
                        >
                          {(action_item_review, { loading: updateLoading }) => (
                            <Mutation
                              client={client.clientPrivate}
                              mutation={updateItemMutation}
                              onCompleted={() => {
                                swal(
                                  'Hurray!',
                                  `Item Review updated successfully!`,
                                  'success',
                                ).then(() => {
                                  history.goBack();
                                });
                              }}
                            >
                              {(
                                update_item,
                                {
                                  loading: updateItemReviewLoading,
                                  error: updateItemReviewError,
                                },
                              ) => (
                                <ItemReviewForm
                                  place={place}
                                  loading={
                                    updateLoading || imageApprovalLoading
                                  }
                                  images={images}
                                  action={action}
                                  handleAction={setAction}
                                  review={review}
                                  updateItemReviewLoading={
                                    updateItemReviewLoading
                                  }
                                  updateItemReviewError={updateItemReviewError}
                                  onSubmit={values => {
                                    if (action === 'ITEM_UPDATE') {
                                      update_item({
                                        variables: {
                                          input: {
                                            user_id: userId,
                                            item_id: values.item_id,
                                            item_review_id:
                                              review.item_review_id,
                                            content: values.content,
                                            item_type: removeTypename(
                                              values.item_type,
                                            ),
                                            cuisine_style: removeTypename(
                                              values.cuisine_style,
                                            ),
                                          },
                                        },
                                      });
                                    } else {
                                      if (
                                        values.images?.every(
                                          img =>
                                            img.approval_status === 'REJECTED',
                                        ) &&
                                        action === 'APPROVED'
                                      ) {
                                        toast.error(
                                          'Please Approve atleast one Image',
                                          {
                                            autoClose: 3000,
                                            closeOnClick: true,
                                          },
                                        );
                                        return;
                                      }
                                      setCurrentImages(values.images);

                                      const cuisine_style = removeTypename(
                                        values.cuisine_style,
                                      );
                                      const item_type = removeTypename(
                                        values.item_type,
                                      );
                                      const input = {
                                        user_id: userId,
                                        place_id: review.place_id,
                                        item_review_id: review.item_review_id,
                                        status: action,
                                        note: [
                                          {
                                            type: action,
                                            value: values.note,
                                            date: moment().toISOString(),
                                          },
                                        ],
                                      };
                                      Object.assign(
                                        input,
                                        !isEqual(
                                          review.cuisine_style,
                                          cuisine_style,
                                        ) && {
                                          cuisine_style,
                                        },
                                        !isEqual(
                                          review.item_type,
                                          item_type,
                                        ) && {
                                          item_type,
                                        },
                                      );
                                      action_item_review({
                                        variables: {
                                          input,
                                        },
                                      });
                                    }
                                  }}
                                />
                              )}
                            </Mutation>
                          )}
                        </Mutation>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default withRouter(PlaceReview);
