import React from 'react';
import { Button, Tab } from 'rbx';
import swal from 'sweetalert';

import { Loading, Tabs, Pagination } from '../../../components/elements';

import Header from './Header';
import Layout from '../../../components/global/Layout';

import GoogleFeedsTable from './GoogleFeedsTable';

import GoogleFeedsLogTable from './LogsTable';

import { googleOrderFeeds, manualPostToGoogle } from './helpers';

import { downloadCSVFile } from '../UserOrdersHistory/helpers';

import Search from './Search';
import { getGoogleFeedJSON } from '../../../utils/s3';

const GoogleFeeds = ({ history }) => {
  const [googleFeeds, setGoogleFeeds] = React.useState([]);
  const [googleFeedsLoading, setGoogleFeedsLoading] = React.useState(false);

  const [sort, setSort] = React.useState('UPDATED_ASC');
  const [placeNameSort, setPlaceNameSort] = React.useState('AES');
  const [citySort, setCitySort] = React.useState('AES');

  const [activeTab, setActiveTab] = React.useState('TAB1');
  const [cityName, setCityName] = React.useState();
  const [placeNameSearchInput, setPlaceNameSearchInput] = React.useState();
  const [service, setService] = React.useState({ label: 'ALL', value: 'All' });
  const [
    manualPostToGoogleLoading,
    setManualPostToGoogleLoading,
  ] = React.useState(false);

  const [downloadCsv, setDownloadCsv] = React.useState(false);
  const [downloadJSONLoading, setDownloadJSONLoading] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  const [pageCount, setPageCount] = React.useState(0);

  const [totalElements, setTotalElements] = React.useState();

  React.useEffect(() => {
    const fetchFunc = async () => {
      setGoogleFeedsLoading(true);
      const gf = await googleOrderFeeds({
        from: currentPage * 10,
        sort,
        city: cityName,
        place: placeNameSearchInput,
        serviceType: service.value === 'All' ? '' : service.value,
      });
      if (gf) {
        setGoogleFeeds(gf.google_order_feed_listing);
        setPageCount(gf.total_pages);
        setTotalElements(gf.total_size);
      }
      setGoogleFeedsLoading(false);
    };
    fetchFunc();
  }, [sort, cityName, placeNameSearchInput, page, service]);

  return (
    <Layout>
      <Header />
      <Search
        setCityName={v => {
          setPage(0);
          setCityName(v);
        }}
        placeNameSearchInput={placeNameSearchInput}
        setPlaceNameSearchInput={v => {
          setPage(0);
          setPlaceNameSearchInput(v);
        }}
        setService={v => {
          setPage(0);
          setService(v);
        }}
        service={service}
        activeTab={activeTab}
      />
      <Tabs className="tabs is-medium is-fullwidth">
        <Tab
          active={activeTab === 'TAB1'}
          onClick={() => {
            setActiveTab('TAB1');
          }}
          style={{ cursor: 'pointer' }}
        >
          Order Food Places
        </Tab>
        <Tab
          active={activeTab === 'TAB2'}
          onClick={() => {
            setActiveTab('TAB2');
          }}
          style={{ cursor: 'pointer' }}
        >
          Order Food Feed Logs
        </Tab>
        <div style={{ marginLeft: 'auto' }}>
          <div>
            <Button
              type="button"
              className={`button control ${downloadJSONLoading &&
                'is-loading'}`}
              onClick={async () => {
                setDownloadJSONLoading(true);
                await getGoogleFeedJSON(setDownloadJSONLoading);
              }}
            >
              Download JSON
            </Button>

            <Button
              type="button"
              className={`button control ${manualPostToGoogleLoading &&
                'is-loading'}`}
              style={{
                backgroundColor: '#00AEEF',
                color: 'white',
                marginLeft: '15px',
              }}
              onClick={async () => {
                swal({
                  title: 'Are you sure to post to Google?',
                  text:
                    // eslint-disable-next-line max-len
                    'Before posting to google, download the JSON file and ensure the data is correct. If you have already reviewed the file click OK to post to google or click Cancel to abort the operation.',
                  buttons: ['Cancel', 'OK'],
                  // dangerMode: true,
                }).then(async willDelete => {
                  if (willDelete) {
                    setManualPostToGoogleLoading(true);
                    await manualPostToGoogle();
                    setManualPostToGoogleLoading(false);
                  }
                });
              }}
            >
              Manual Post To Google
            </Button>
            <Button
              type="button"
              className={`button control ${downloadCsv && 'is-loading'}`}
              style={{
                marginLeft: '15px',
              }}
              onClick={async () => {
                function generateArray(n) {
                  return Array.from(Array(n - 1).keys(), x => x + 1);
                }
                setDownloadCsv(true);

                let temp = [];

                const gf = await googleOrderFeeds({
                  from: 0 * 10,
                });

                const temp2 = (gf?.google_order_feed_listing ?? []).map(
                  ele => ({
                    place_name: ele.place_name,
                    service_types: ele?.service_types?.join(' '),
                    website: ele.website,
                    telephone: ele.telephone,
                    google_order_feed_id: ele.google_order_feed_id,
                  }),
                );

                temp = [...temp2];

                const totalPages = gf.total_pages;

                const res = await Promise.all(
                  generateArray(totalPages).map(async ele =>
                    googleOrderFeeds({
                      from: ele * 10,
                    }),
                  ),
                );

                res
                  .map(ele => ele?.google_order_feed_listing ?? [])
                  .reduce((p, c) => [...p, ...c], [])
                  .map(ele => ({
                    place_name: ele.place_name,
                    service_types: ele?.service_types?.join(' '),
                    website: ele.website,
                    telephone: ele.telephone,
                    google_order_feed_id: ele.google_order_feed_id,
                  }))
                  .forEach(ele => {
                    temp = [...temp, ele];
                  });

                const csvData = (() => {
                  let header = Object.keys(temp[0]).join(',');
                  header = `${header}\n`;
                  const body = temp
                    .map(ele => Object.values(ele))
                    .map(ele => ele.join(','))
                    .join('\n');
                  return `${header}${body}`;
                })();

                downloadCSVFile(csvData, 'google_feeds.csv');
                setDownloadCsv(false);
              }}
            >
              Download CSV
            </Button>
          </div>
        </div>
      </Tabs>
      {activeTab === 'TAB1' && (
        <>
          {googleFeedsLoading ? (
            <Loading />
          ) : (
            <>
              {totalElements > 0 && (
                <Pagination
                  pageFrom={currentPage * 10}
                  pageCount={pageCount}
                  listSize={10}
                  totalElements={totalElements}
                  currentPage={currentPage}
                  handlePageClick={value => setPage(value.selected + 1)}
                />
              )}
              {googleFeeds.length !== 0 && (
                <GoogleFeedsTable
                  setSort={setSort}
                  setPlaceNameSort={setPlaceNameSort}
                  placeNameSort={placeNameSort}
                  setCitySort={setCitySort}
                  citySort={citySort}
                  googleFeeds={googleFeeds}
                  history={history}
                />
              )}
            </>
          )}
        </>
      )}
      {activeTab === 'TAB2' && <GoogleFeedsLogTable />}
    </Layout>
  );
};

export default GoogleFeeds;
