export default `<html
  data-editor-version="2"
  class="sg-campaigns"
  xmlns="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
    />
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <!--<![endif]-->
    <!--[if (gte mso 9)|(IE)]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG />
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    <![endif]-->
    <!--[if (gte mso 9)|(IE)]>
      <style type="text/css">
        body {
          width: 600px;
          margin: 0 auto;
        }
        table {
          border-collapse: collapse;
        }
        table,
        td {
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        }
        img {
          -ms-interpolation-mode: bicubic;
        }
      </style>
    <![endif]-->
    <style type="text/css">
      body, p, div { font-family: inherit; font-size: 14px; } body { color:
      #000000; } body a { color: #1188e6; text-decoration: none; } p { margin:
      0; padding: 0; } table.wrapper { width: 100% !important; table-layout:
      fixed; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust:
      100%; -moz-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
      img.max-width { max-width: 100% !important; } .column.of-2 { width: 50%; }
      .column.of-3 { width: 33.333%; } .column.of-4 { width: 25%; } ul ul ul ul
      { list-style-type: disc !important; } ol ol { list-style-type: lower-roman
      !important; } ol ol ol { list-style-type: lower-latin !important; } ol ol
      ol ol { list-style-type: decimal !important; } @media screen and
      (max-width: 480px) { .preheader .rightColumnContent, .footer
      .rightColumnContent { text-align: left !important; } .preheader
      .rightColumnContent div, .preheader .rightColumnContent span, .footer
      .rightColumnContent div, .footer .rightColumnContent span { text-align:
      left !important; } .preheader .rightColumnContent, .preheader
      .leftColumnContent { font-size: 80% !important; padding: 5px 0; }
      table.wrapper-mobile { width: 100% !important; table-layout: fixed; }
      img.max-width { height: auto !important; max-width: 100% !important; }
      a.bulletproof-button { display: block !important; width: auto !important;
      font-size: 80%; padding-left: 0 !important; padding-right: 0 !important; }
      .columns { width: 100% !important; } .column { display: block !important;
      width: 100% !important; padding-left: 0 !important; padding-right: 0
      !important; margin-left: 0 !important; margin-right: 0 !important; }
      .social-icon-column { display: inline-block !important; } }
    </style>
    <!--user entered Head Start-->
    <link
      href="https://fonts.googleapis.com/css?family=Fredoka+One&display=swap"
      rel="stylesheet"
    />
    <style>
      body { font-family: "Fredoka One", cursive; }
    </style>
    <!--End Head user entered-->
  </head>
  <body>
    <center
      class="wrapper"
      data-link-color="#1188E6"
      data-body-style="font-size:14px; font-family:inherit; color:#000000; background-color:#e5dcd2;"
    >
      <div class="webkit">
        <table
          cellpadding="0"
          cellspacing="0"
          border="0"
          width="100%"
          class="wrapper"
          bgcolor="#e5dcd2"
        >
          <tr>
            <td valign="top" bgcolor="#e5dcd2" width="100%">
              <table
                width="100%"
                role="content-container"
                class="outer"
                align="center"
                cellpadding="0"
                cellspacing="0"
                border="0"
              >
                <tr>
                  <td width="100%">
                    <table
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      border="0"
                    >
                      <tr>
                        <td>
                          <!--[if mso]>
    <center>
    <table><tr><td width="600">
  <![endif]-->
                          <table
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            style="width: 100%; max-width: 600px"
                            align="center"
                          >
                            <tr>
                              <td
                                role="modules-container"
                                style="
                                  padding: 0px 0px 0px 0px;
                                  color: #000000;
                                  text-align: left;
                                "
                                bgcolor="#FFFFFF"
                                width="100%"
                                align="left"
                              >
                                <table
                                  class="module preheader preheader-hide"
                                  role="module"
                                  data-type="preheader"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    display: none !important;
                                    mso-hide: all;
                                    visibility: hidden;
                                    opacity: 0;
                                    color: transparent;
                                    height: 0;
                                    width: 0;
                                  "
                                >
                                  <tr>
                                    <td role="module-content">
                                      <p>
                                        New Order: Please confirm immediately
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="center"
                                  width="100%"
                                  role="module"
                                  data-type="columns"
                                  style="padding: 30px 30px 25px 30px"
                                  bgcolor="#00AEEF"
                                  data-distribution="1,1"
                                >
                                  <tbody>
                                    <tr role="module-content">
                                      <td height="100%" valign="top">
                                        <table
                                          width="270"
                                          style="
                                            width: 270px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 0px 0px 0px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="wrapper"
                                                  role="module"
                                                  data-type="image"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="901d0310-672c-49c8-9b04-ee16588bea64"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          font-size: 6px;
                                                          line-height: 10px;
                                                          padding: 0px 10px 0px
                                                            0px;
                                                        "
                                                        valign="top"
                                                        align="left"
                                                      >
                                                        <a
                                                          href="http://www.kravein.com.au"
                                                        ><img
                                                            class="max-width"
                                                            border="0"
                                                            style="
                                                              display: block;
                                                              color: #000000;
                                                              text-decoration: none;
                                                              font-family: Helvetica,
                                                                arial,
                                                                sans-serif;
                                                              font-size: 16px;
                                                              max-width: 60% !important;
                                                              width: 60%;
                                                              height: auto !important;
                                                            "
                                                            width="162"
                                                            alt="KRAVEiN"
                                                            data-proportionally-constrained="true"
                                                            data-responsive="true"
                                                            src="http://cdn.mcauto-images-production.sendgrid.net/8740b01413463518/a7058133-8fe2-42ab-8b29-3b1c37b4714a/1600x484.jpg"
                                                          /></a>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          width="270"
                                          style="
                                            width: 270px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 0px 0px 0px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-1"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="text"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="980fd676-0984-4612-a68e-cabfbeb16cf8"
                                                  data-mc-module-version="2019-10-22"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          padding: 18px 0px 18px
                                                            0px;
                                                          line-height: 0px;
                                                          text-align: inherit;
                                                          background-color: #00aeef;
                                                        "
                                                        height="100%"
                                                        valign="top"
                                                        bgcolor="#00AEEF"
                                                        role="module-content"
                                                      >
                                                        <div>
                                                          <div
                                                            style="
                                                              font-family: inherit;
                                                              text-align: left;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-style: normal;
                                                                font-variant-ligatures: normal;
                                                                font-variant-caps: normal;
                                                                font-weight: 400;
                                                                letter-spacing: normal;
                                                                orphans: 2;
                                                                text-align: start;
                                                                text-indent: 0px;
                                                                text-transform: none;
                                                                white-space-collapse: preserve;
                                                                text-wrap: wrap;
                                                                widows: 2;
                                                                word-spacing: 0px;
                                                                -webkit-text-stroke-width: 0px;
                                                                background-color: rgb(
                                                                  0,
                                                                  174,
                                                                  239
                                                                );
                                                                text-decoration-thickness: initial;
                                                                text-decoration-style: initial;
                                                                text-decoration-color: initial;
                                                                float: none;
                                                                display: inline;
                                                                color: #ffffff;
                                                                font-family: arial,
                                                                  helvetica,
                                                                  sans-serif;
                                                                font-size: 16px;
                                                              "
                                                            ><strong
                                                              ></strong></span>
                                                          </div>
                                                          <div></div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="module"
                                  role="module"
                                  data-type="text"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  min-height="700px"
                                  style="table-layout: fixed; min-height: 240px"
                                  data-muid="0902f2b3-2491-4ed3-9dba-9e6cc3557268"
                                  data-mc-module-version="2019-10-22"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          padding: 18px 0px 18px 18px;
                                          line-height: 22px;
                                          text-align: inherit;
                                        "
                                        height="100%"
                                        valign="top"
                                        bgcolor=""
                                        role="module-content"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-family: inherit;
                                              text-align: start;
                                            "
                                          >
                                            {{ENTER_HERE}}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </table>

                          <!--[if mso]>
                                  </td>
                                </tr>
                              </table>
                            </center>
                            <![endif]-->
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </center>

  </body>
</html>
`;
