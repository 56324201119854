import React from 'react';

import { Table } from '../../../components/elements';

const DetailTable = () => (
  <>
    <Table>
      <thead>
        <tr>
          <th>
            <div>
              <span>
                <a onClick={() => {}}>
                  <i className="fas fa-sort-amount-down" />
                </a>
                Ticket Category
              </span>
            </div>
          </th>
          <th>
            <div>
              <span>
                <a onClick={() => {}}>
                  <i className="fas fa-sort-amount-down" />
                </a>{' '}
                Quantity
              </span>
            </div>
          </th>
          <th>
            <div>
              <span>
                <a onClick={() => {}}>
                  <i className="fas fa-sort-amount-down" />
                </a>{' '}
                Price
              </span>
            </div>
          </th>
          <th>
            <div>
              <span>
                <a onClick={() => {}}>
                  <i className="fas fa-sort-amount-down" />
                </a>{' '}
                Fee
              </span>
            </div>
          </th>

          <th>
            <div>
              <span>
                <a onClick={() => {}}>
                  <i className="fas fa-sort-amount-down" />
                </a>{' '}
                Fee Type
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>100 Lucky</td>
          <td>1</td>
          <td>$7.00</td>
          <td>$0.00</td>
          <td>Agent Pass On Fee</td>
        </tr>
      </tbody>
    </Table>
  </>
);

export default DetailTable;
