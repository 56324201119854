import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  &&& {
    th {
      font-weight: 600;
      white-space: nowrap;
    }
    .table td,
    .table th {
      vertical-align: middle;
    }
  }
`;

const Table = ({ children }) => (
  <Container className="table-container">
    <table className="table is-fullwidth is-size-5-touch is-size-6-desktop is-size-5-widescreen is-size-4-fullhd">
      {children}
    </table>
  </Container>
);

export default Table;
