import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import Layout from '../../../components/global/Layout';
import { ErrorMessage } from '../../../components/elements';
import Form from './Form';
import client from '../../../utils/apolloClient';

const createServiceMutation = gql`
  mutation createGlobalMenu($input: ServiceTypeInput) {
    create_service_type(input: $input) {
      service_type_id
      name
      error {
        description
      }
    }
  }
`;

const GlobalMenuAdd = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <Mutation
        client={client.clientPrivate}
        mutation={createServiceMutation}
        onCompleted={({ create_service_type }) => {
          if (!isNull(create_service_type.error)) {
            create_service_type.error.map(item =>
              toast.error(item.description),
            );
          } else {
            swal(
              'Good job!',
              'Service type created successfully!',
              'success',
            ).then(() => {
              history.goBack();
            });
          }
        }}
      >
        {(create_service_type, { loading, error }) => (
          <>
            {error && <ErrorMessage message={error.message} />}
            <Form
              loading={loading}
              onSubmit={values => {
                create_service_type({
                  variables: {
                    input: {
                      user_id: userId,
                      menu_only: true,
                      ...values,
                    },
                  },
                });
              }}
            />
          </>
        )}
      </Mutation>
    </Layout>
  );
};

export default GlobalMenuAdd;
