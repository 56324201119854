import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import PlaceSelection from '../../../components/PlaceSelection';
import Form from './Form';

import { updateSectionsInMenu } from './helpers';

const createMenuSectionsMutationMutation = gql`
  mutation createMenuSectionsMutation($input: MenuSectionInput) {
    create_menu_section(input: $input) {
      place_id
      menu_id
      menu_section_id
      error {
        description
      }
    }
  }
`;

const MenuSectionAdd = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);
  const { defaultPlace } = useStoreState(state => state.menu);
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);

  const placeData = defaultPlace ?? place;
  const placeSelectionValue =
    defaultPlace !== null ? !!defaultPlace : placeSelection;

  return (
    <Layout>
      {placeSelectionValue ? (
        <Mutation
          client={client.clientPrivate}
          mutation={createMenuSectionsMutationMutation}
          onCompleted={({ create_menu_section }) => {
            if (!isNull(create_menu_section.error)) {
              create_menu_section.error.map(item =>
                toast.error(item.description),
              );
            } else if (create_menu_section.menu_id.length === 0) {
              swal(
                'Great!',
                'Menu category created successfully!',
                'success',
              ).then(() => {
                history.goBack();
              });
            } else {
              swal({
                title: 'Menu created successfully!',
                text: 'Would you also like to assign items now or later?',
                buttons: ['Cancel', 'Assign Items'],
              }).then(willDelete => {
                if (willDelete) {
                  history.push(
                    `/update-menu-section/${create_menu_section.menu_section_id}?assign_item_active=true`,
                  );
                } else {
                  history.goBack();
                }
              });
            }
          }}
        >
          {(create_menu_section, { loading, error }) => (
            <>
              {error && <ErrorMessage message={error.message} />}
              <Form
                isCreatePage
                userId={userId}
                place={placeData}
                loading={loading}
                onSubmit={inputs => {
                  const input = omit(inputs, ['menus']);
                  create_menu_section({
                    variables: {
                      input,
                    },
                  });

                  try {
                    const transformMenuSection = sections =>
                      sections.map((ele, idx) => ({
                        ...omit(ele, '__typename'),
                        display_order: idx,
                      }));
                    input.menu_id
                      .map(m_id =>
                        inputs.menus.find(ele => ele.menu_id === m_id),
                      )
                      .map(mnus => ({
                        user_id: userId,
                        menu_section: transformMenuSection([
                          ...(mnus?.menu_section ?? []),
                          {
                            name: input.name,
                            menu_section_id: input.menu_section_id,
                            display_order: 0,
                          },
                        ]),
                        menu_id: mnus.menu_id,
                      }))
                      .forEach(updateSectionsInMenuInput => {
                        updateSectionsInMenu({
                          ...updateSectionsInMenuInput,
                        });
                      });
                  } catch (error2) {
                    console.log('error on updating sections in menu', error2);
                  }
                }}
              />
            </>
          )}
        </Mutation>
      ) : (
        <PlaceSelection
          heading="Select place to add menu category"
          claimStatus="NONE"
          hasServiceSetting
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default MenuSectionAdd;
