/* eslint-disable func-names */
import React from 'react';

import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../components/elements';

yup.addMethod(yup.string, 'testNonNegativeValue', function(errorMessage) {
  return this.test(`test-non-negative-value`, errorMessage, function(value) {
    const { path, createError } = this;
    const num = +value;

    return num >= 0 || createError({ path, message: errorMessage });
  });
});

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      padding: 1rem 1rem;
      height: 15rem;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 25rem;
    }
    .modal-card-head {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const Form = props => {
  const {
    isActive,
    onClose,
    setFieldValue,
    values,
    handleSubmit,
    loading,
    touched,
    errors,
  } = props;

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Override Fee</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body">
          <Input label="Fee Name" value={values.fee_name} disabled />
          <Input
            label="Fee Display Label"
            value={values.display}
            onChange={event => {
              setFieldValue(`display`, event.target.value);
            }}
            required
            errors={errors.display}
            touched={touched.display}
            disableToastMessage
          />

          <Input
            label="Fee"
            value={values.value}
            type="number"
            min="0"
            onChange={event => {
              if (
                event.target.value &&
                parseFloat(event.target.value) >= 0 &&
                parseFloat(event.target.value) <= 99999
              ) {
                setFieldValue(`value`, event.target.value);
              } else if (event.target.value.length === 0) {
                setFieldValue(`value`, null);
              }
            }}
            onBlur={event =>
              setFieldValue(`value`, parseFloat(event.target.value).toFixed(2))
            }
            required
            errors={errors.value}
            touched={touched.value}
            disableToastMessage
          />
          <Input
            label="Min Order Amount"
            value={values.minimum_order_amount}
            type="number"
            onChange={event => {
              if (event.target.value === '') {
                setFieldValue('minimum_order_amount', null);
              } else if (
                !isNaN(parseFloat(event.target.value)) &&
                parseFloat(event.target.value) >= 0 &&
                parseFloat(event.target.value) <= 99999
              ) {
                setFieldValue(`minimum_order_amount`, event.target.value);
              }
            }}
            onBlur={event =>
              setFieldValue(
                `minimum_order_amount`,
                event.target.value
                  ? parseFloat(event.target.value).toFixed(2)
                  : null,
              )
            }
            errors={errors.minimum_order_amount}
            touched={touched.minimum_order_amount}
            disableToastMessage
          />
        </section>

        <footer className="modal-card-foot">
          <button type="button" className="button" onClick={onClose}>
            cancel
          </button>
          <button
            type="button"
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            Save
          </button>
        </footer>
      </div>
    </Container>
  );
};

const AddEditForm = withFormik({
  mapPropsToValues: ({ fee }) => ({
    display:
      fee.order_fee && fee.order_fee_override
        ? fee.order_fee_override.display
        : fee.order_fee.display,
    fee_name: fee.order_fee.name,
    value: fee.order_fee_override
      ? fee.order_fee_override.value
      : parseFloat(fee.order_fee.value).toFixed(2),
    minimum_order_amount: fee.order_fee_override
      ? fee.order_fee_override.minimum_order_amount
      : null,
  }),

  validationSchema: yup.object().shape({
    value: yup
      .string()
      .nullable('Fee is required!')
      .required('Fee is required!')
      .testNonNegativeValue('Fee should not be negative value'),
    minimum_order_amount: yup.string().nullable('Fee is required!'),
    display: yup.string().nullable('Display label is required!'),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit({
      value: values.value,
      minimum_order_amount: values.minimum_order_amount,
      display: values.display,
    });
  },

  displayName: 'AddPlaceForm',
})(Form);

export default AddEditForm;
