import React from 'react';
import styled from 'styled-components';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import formatISO from 'date-fns/formatISO';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addDays from 'date-fns/addDays';

const RightIcon = styled.span`
  &&& {
    pointer-events: initial;
    cursor: pointer;
  }
`;

const useInitializer = ({ type, setStartDateFilter, setEndDateFilter }) => {
  const i = new Date();
  React.useEffect(() => {
    if (type === 'FILTER BY DAY') {
      setStartDateFilter(formatISO(startOfDay(i)));
      setEndDateFilter(formatISO(endOfDay(i)));
    }
    if (type === 'FILTER BY MONTH') {
      setStartDateFilter(formatISO(startOfMonth(i)));
      setEndDateFilter(formatISO(endOfMonth(i)));
    }
    if (type === 'FILTER BY WEEK') {
      const startOfWeekDate = startOfWeek(i, { weekStartsOn: 0 });
      const endOfWeekDate = endOfWeek(i, { weekStartsOn: 0 });
      setStartDateFilter(formatISO(startOfDay(startOfWeekDate)));
      setEndDateFilter(formatISO(endOfDay(endOfWeekDate)));
    }
  }, [type]);
};

const DateTimePickerCustomInput = ({
  value,
  onClick,
  placeholder,
  onClear,
  clear,
  disabled,
}) => (
  <div className="field" onClick={onClick}>
    <p className="control has-icons-left has-icons-right">
      <input
        className="input"
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
      <span className="icon is-small is-left">
        <i className="fas fa-calendar-alt" />
      </span>
      {clear === undefined && value.length !== 0 && (
        <RightIcon
          className="icon is-small is-right"
          onClick={onClear}
          style={{ zIndex: 1 }}
        >
          <i className="fas fa-times" />
        </RightIcon>
      )}
    </p>
  </div>
);

const FilterByRange = ({
  setStartDateFilter = () => {},
  setEndDateFilter = () => {},
}) => {
  const initialStart = startOfWeek(new Date(), { weekStartsOn: 0 });
  const initialEnd = endOfWeek(new Date(), { weekStartsOn: 0 });

  const [startDate, setStartDate] = React.useState(initialStart);
  const [endDate, setEndDate] = React.useState(initialEnd);
  const onChange = dates => {
    const [start, ,] = dates;
    if (start) {
      const i = new Date(start);
      const datePlusSixDays = addDays(i, 6);
      setStartDate(i);
      setEndDate(datePlusSixDays);
      setStartDateFilter(formatISO(startOfDay(i)));
      setEndDateFilter(formatISO(endOfDay(datePlusSixDays)));
    }
  };
  return (
    <DatePicker
      closeOnScroll
      customInput={<DateTimePickerCustomInput placeholder="Select Date" />}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      dateFormat="dd/MM/yyyy"
      maxDate={new Date()}
    />
  );
};

const FilterByMonth = ({
  setStartDateFilter = () => {},
  setEndDateFilter = () => {},
}) => {
  const [startDate, setStartDate] = React.useState(new Date());
  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };
  return (
    <DatePicker
      customInput={<DateTimePickerCustomInput placeholder="Select Date" />}
      selected={startDate}
      onChange={date => {
        setStartDate(date);
        setStartDateFilter(formatISO(startOfMonth(date)));
        setEndDateFilter(formatISO(endOfMonth(date)));
      }}
      renderMonthContent={renderMonthContent}
      showMonthYearPicker
      dateFormat="MM/yyyy"
    />
  );
};

const FilterByDay = ({
  setStartDateFilter = () => {},
  setEndDateFilter = () => {},
}) => {
  const [startDate, setStartDate] = React.useState(new Date());
  return (
    <DatePicker
      closeOnScroll
      customInput={<DateTimePickerCustomInput placeholder="Select Date" />}
      dateFormat="MMMM d, yyyy"
      selected={startDate}
      onChange={date => {
        setStartDate(date);
        setStartDateFilter(formatISO(startOfDay(date)));
        setEndDateFilter(formatISO(endOfDay(date)));
      }}
      maxDate={new Date()}
    />
  );
};

const DateFilter = ({ filterType, setStartDate, setEndDate }) => {
  useInitializer({
    type: filterType.value,
    setStartDateFilter: setStartDate,
    setEndDateFilter: setEndDate,
  });
  if (filterType.value === 'FILTER BY DAY') {
    return (
      <FilterByDay
        setStartDateFilter={setStartDate}
        setEndDateFilter={setEndDate}
      />
    );
  }

  if (filterType.value === 'FILTER BY MONTH') {
    return (
      <FilterByMonth
        setStartDateFilter={setStartDate}
        setEndDateFilter={setEndDate}
      />
    );
  }

  if (filterType.value === 'FILTER BY WEEK') {
    return (
      <FilterByRange
        setStartDateFilter={setStartDate}
        setEndDateFilter={setEndDate}
      />
    );
  }

  return (
    <FilterByRange
      setStartDateFilter={setStartDate}
      setEndDateFilter={setEndDate}
    />
  );
};

export default DateFilter;
