import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { startCase } from 'lodash';
import { ACLSConsumer } from '../../../utils/aclsContext';
import Can from '../../../utils/Can';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({
  history,
  loading,
  dirty,
  children,
  categoryName,
  isAssignActive,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">Menu Category</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a
            onClick={() => history.push('/menu-sections')}
            className="button is-grey"
          >
            <span className="has-text-weight-semibold">Cancel</span>
          </a>
        </Level.Item>

        {isAssignActive && (
          <ACLSConsumer>
            {({ apiActions, pageActions }) => (
              <Can
                action={['create_menu_section', 'update_menu_section']}
                apiActions={apiActions}
                pageActions={pageActions}
                yes={() => (
                  <Level.Item>
                    <button
                      type="submit"
                      className={`button is-primary ${loading && 'is-loading'}`}
                      disabled={!dirty}
                    >
                      <span className="has-text-weight-semibold">Submit</span>
                    </button>
                  </Level.Item>
                )}
              />
            )}
          </ACLSConsumer>
        )}
      </Level.Item>
    </Level>
    <p className="is-size-2">{startCase(categoryName)}</p>
    {children}
  </Wrapper>
);

export default withRouter(Header);
