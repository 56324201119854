import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';

import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import client from '../../../utils/apolloClient';

const deleteEventDealMutation = gql`
  mutation delete_place_event_voucher($input: NavigatePlaceEventVoucherInput) {
    delete_place_event_voucher(input: $input) {
      deal_id
      error {
        description
      }
    }
  }
`;

const Delete = ({ dealId, onComplete }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteEventDealMutation}
      onCompleted={({ delete_place_event_voucher }) => {
        if (delete_place_event_voucher.error !== null) {
          delete_place_event_voucher.error.map(item =>
            toast.error(item.description),
          );
        } else {
          onComplete();
        }
      }}
    >
      {delete_place_event_voucher => (
        <a
          onClick={() => {
            swal('Are you sure you want to delete the deal?', {
              buttons: ['Cancel', true],
            }).then(willDelete => {
              if (willDelete) {
                delete_place_event_voucher({
                  variables: {
                    input: {
                      user_id: userId,
                      deal_id: dealId,
                    },
                  },
                });
              }
            });
          }}
        >
          <span>
            <i className="fas fa-trash has-text-grey" />
          </span>
        </a>
      )}
    </Mutation>
  );
};

export default Delete;
