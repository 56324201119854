import React from 'react';
import { Level } from 'rbx';
import styled from 'styled-components';
import { Heading } from '../../../components/elements';
import PlaceDataHeader from './PlaceDataHeader';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #f6f6f6;
`;

const Header = ({ placeId, history }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Heading>Booking Manager</Heading>
      </Level.Item>
      <Level.Item
        align="right"
        style={{ padding: 0, marginTop: 0, marginBottom: 0 }}
      >
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-grey">
            <span className="has-text-weight-semibold">Back</span>
          </a>
        </Level.Item>
        <Level.Item>
          <a
            onClick={() => history.push(`/table-booking-setting/${placeId}`)}
            className="button is-grey"
          >
            <span className="has-text-weight-semibold">Go To Settings</span>
          </a>
        </Level.Item>
      </Level.Item>
    </Level>
    <PlaceDataHeader placeId={placeId} />
  </Wrapper>
);

export default Header;
