import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { Accordion } from 'react-accessible-accordion';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import LinkItem from './LinkItem';
import { finalLinkListingItemDataParser } from './helpers';
import client from '../../../utils/apolloClient';

const Container = styled(Accordion)``;

const LoadingText = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00aeef;
  font-size: 24px;
  font-weigth: 800;
  padding: 24px;
`;

const updateAvailabilityItemLevelMutation = gql`
  mutation updateAvailability($input: AvailabilityInput) {
    update_availability(input: $input) {
      message
      error {
        code
        description
      }
    }
  }
`;

const AvailabilityList = ({
  links,
  place,
  refetchLinks,
  fetchMoreLinks,
  moreLoading,
  page,
}) => {
  const { userId } = useStoreState(state => state.auth);
  const divRef = React.useRef(null);

  React.useEffect(() => {
    const handleIntersection = entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        fetchMoreLinks({ pageValue: page.current + 1 });
      }
    };
    const observer = new IntersectionObserver(handleIntersection);
    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  const [updateLinkLevel, { loading }] = useMutation(
    updateAvailabilityItemLevelMutation,
    {
      client: client.clientPrivate,
      onCompleted: ({ update_availability }) => {
        console.log('update_availability', update_availability);
      },
    },
  );
  return (
    <Container>
      {links.map(item => (
        <LinkItem
          loading={loading}
          placeId={place.place_id}
          link={finalLinkListingItemDataParser({ link: item })}
          onHandleLinkSubmission={async (date, action, otherValues) => {
            if (action === 'ITEM_LINK') {
              await updateLinkLevel({
                variables: {
                  input: {
                    type: action,
                    user_id: userId,
                    item_link_id: item.item_link_id,
                    availability_date: moment(date).format('YYYY-MM-DD'),
                    availability_status: date ? 'UNAVAILABLE' : 'AVAILABLE',
                  },
                },
              });
            }
            if (action === 'ITEM_LINK_VARIANT') {
              await updateLinkLevel({
                variables: {
                  input: {
                    type: action,
                    user_id: userId,
                    item_link_id: item.item_link_id,
                    prompt_id: otherValues.prompt_id,
                    variant_id: otherValues.variant_id,
                    availability_date: moment(date).format('YYYY-MM-DD'),
                    availability_status: date ? 'UNAVAILABLE' : 'AVAILABLE',
                  },
                },
              });
            }
            refetchLinks();
          }}
          onHandleLinkModifierSubmission={async (date, action, otherValues) => {
            if (action === 'MODIFIER_ITEM_LINK') {
              await updateLinkLevel({
                variables: {
                  input: {
                    type: action,
                    user_id: userId,
                    modifier_id: otherValues.modifier_id,
                    item_link_id: item.item_link_id,
                    availability_date: moment(date).format('YYYY-MM-DD'),
                    availability_status: date ? 'UNAVAILABLE' : 'AVAILABLE',
                  },
                },
              });
            }
            if (action === 'MODIFIER_ITEM_LINK_VARIANT') {
              await updateLinkLevel({
                variables: {
                  input: {
                    type: action,
                    user_id: userId,
                    modifier_id: otherValues.modifier_id,
                    item_link_id: item.item_link_id,
                    prompt_id: otherValues.prompt_id,
                    variant_id: otherValues.variant_id,
                    availability_date: moment(date).format('YYYY-MM-DD'),
                    availability_status: date ? 'UNAVAILABLE' : 'AVAILABLE',
                  },
                },
              });
            }
            refetchLinks();
          }}
        />
      ))}
      <div ref={divRef} />
      {moreLoading && <LoadingText>Loading...</LoadingText>}
    </Container>
  );
};

export default AvailabilityList;
