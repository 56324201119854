import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { first, isNaN, toLower } from 'lodash';
import voucher_codes from 'voucher-code-generator';
import moment from 'moment-timezone';
import ReactLoading from 'react-loading';

import {
  Select,
  Radio,
  Input,
  CheckBox,
  BoxWithHeader,
  InputAddOn,
} from '../../../../components/elements';

import DealTimeDatePicker from '../../../PlaceManagement/PlaceDealAdd/DealTimeDatePicker';
import ItemsAndVariantsDiscount from '../../../PlaceManagement/PlaceDealAdd/ItemsAndVariantsDiscount';
import {
  parseLinkAndMenuItem,
  getLinksAndMenuItem,
} from '../../../PlaceManagement/PlaceDealAdd/helpers';
import AssignPlaces from './AssignPlaces';

const mergeLinksAndMenuItem = (links, menuItem) =>
  links.map(parseLinkAndMenuItem(menuItem));

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
`;

const RadioWrapper = styled.div`
  padding: 0rem 0.3rem;
`;

const CheckBoxWrapper = styled.div``;

const LoaderWrapper = styled.div`
  position: relative;
  left: 50%;
  right: 50%;
  opacity: 0.5;
  z-index: 1000;
`;

const VoucherDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  dealType,
  menuName,
  menuId,
  deliveryChannel,
}) => {
  const [autoGenerateRadioValue, setAutoGenerateRadioValue] = useState(true);
  const defaultCouponCode = voucher_codes.generate({
    length: 10,
    count: 1,
    charset: voucher_codes.charset('alphabetic'),
  });
  const [links, setLinks] = useState([]);
  const [linksLoading, setLinksLoading] = useState(false);

  useEffect(() => {
    if (toLower(values.deal_type) === 'specific_item_discount') {
      setFieldValue('name', 'More savings on select items');
    }
  }, []);

  const fetchLinks = useCallback(async () => {
    if (toLower(values.deal_type) !== 'specific_item_discount') {
      return;
    }
    try {
      const response = await getLinksAndMenuItem(
        {
          sort: 'NAME_ASC',
          from: 0,
          size: 1000,
          // search_criteria: 'Chicken',
          // user_id: userId,
          filter: {
            menu_item_link_filter: {
              place_id: values.place_id,
              menu_id: menuId,
              status: 'ACTIVE',
            },
          },
        },
        {
          sort: 'NAME_ASC',
          from: 0,
          size: 1000,
          filter: {
            menu_item_filter: {
              place_id: values.place_id,
              status: 'ACTIVE',
            },
            menu_filter: {
              menu_id: menuId,
            },
          },
        },
      );
      setLinks(
        mergeLinksAndMenuItem(
          response.search_menu_item_links.menu_item_link_listing,
          response.search_menu_items.menu_item_listing,
        ),
      );
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    setLinksLoading(true);
    fetchLinks().then(() => setLinksLoading(false));
  }, []);

  return (
    <>
      <Column.Group vcentered multiline>
        <Column size={3}>
          <Input label="Discount Type" value={dealType} disabled />
        </Column>
        <Column size={3}>
          <Input label="Menu" value={menuName} disabled />
        </Column>
        <Column size={3}>
          <Select
            label="Deal / Voucher Status"
            options={status}
            value={[{ value: values.status, label: values.status }]}
            onChange={({ value }) => setFieldValue('status', value)}
          />
        </Column>
        {!!deliveryChannel &&
          toLower(values.deal_type) === 'delivery_fee_discount' && (
            <Input label="Delivery Channel" value={deliveryChannel} disabled />
          )}
      </Column.Group>

      <Column.Group vcentered multiline>
        <AssignPlaces placeId={values.place_id} values={values} />
        <div style={{ position: 'relative', top: '10px' }}>
          <CheckBox
            label="Mobile Only Deal"
            value={values.mobile_only}
            onChange={value => {
              setFieldValue('mobile_only', value);
            }}
          />
        </div>
      </Column.Group>

      <BoxWithHeader title="Deal / Voucher Type">
        <Column.Group vcentered multiline>
          <Column size={3}>
            <RadioWrapper>
              <Radio
                label="Automatic Promotion"
                value={values.promotion === 'AUTOMATIC'}
                onChange={value => {
                  if (value) {
                    setFieldValue('promotion', 'AUTOMATIC');
                    setFieldValue('is_published', true);
                  }
                }}
              />
            </RadioWrapper>
          </Column>
          <Column size={3}>
            <RadioWrapper>
              <Radio
                label="Requires Voucher Code"
                value={values.promotion === 'PROMOCODE'}
                onChange={value => {
                  if (value) {
                    setFieldValue('promotion', 'PROMOCODE');
                    const coupon = voucher_codes.generate({
                      length: 10,
                      count: 1,
                      charset: voucher_codes.charset('alphabetic'),
                    });
                    setFieldValue('promo_code', first(coupon).toUpperCase());
                    // setFieldValue('is_published', false);
                  }
                }}
              />
            </RadioWrapper>
          </Column>

          <Column size={3}>
            <CheckBoxWrapper>
              {values.promotion === 'PROMOCODE' && (
                <>
                  <CheckBox
                    label="Auto generate Voucher Code"
                    value={autoGenerateRadioValue}
                    onChange={value => {
                      if (value) {
                        const coupon = voucher_codes.generate({
                          length: 10,
                          count: 1,
                          charset: voucher_codes.charset('alphabetic'),
                        });
                        setFieldValue(
                          'promo_code',
                          first(coupon).toUpperCase(),
                        );
                        setAutoGenerateRadioValue(true);
                      } else {
                        setAutoGenerateRadioValue(false);
                      }
                    }}
                  />
                  <Input
                    name="promo_code"
                    value={values.promo_code}
                    onChange={handleChange}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setFieldValue(
                          'promo_code',
                          first(defaultCouponCode).toUpperCase(),
                        );
                      } else {
                        setFieldValue('promo_code', e.target.value);
                      }
                    }}
                    errors={errors.promo_code}
                    touched={touched.promo_code}
                    disabled={autoGenerateRadioValue}
                  />
                </>
              )}
            </CheckBoxWrapper>
          </Column>
          <Column size={3}>
            <br />
            {values.promotion !== 'AUTOMATIC' && (
              <CheckBox
                label="Publish voucher /deal"
                value={values.is_published}
                onChange={value => setFieldValue('is_published', value)}
              />
            )}
          </Column>
        </Column.Group>
      </BoxWithHeader>
      {/* <BoxWithHeader title="Choose audience">
        <Column.Group vcentered>
          <Column size={3}>
            <RadioWrapper>
              <Radio
                label="All customers"
                value={values.customer_type === 'EXISTING'}
                onChange={value => {
                  if (value) {
                    setFieldValue('customer_type', 'EXISTING');
                  }
                }}
              />
            </RadioWrapper>
          </Column>
          <Column size={3}>
            <RadioWrapper>
              <Radio
                label="New customers"
                value={values.customer_type === 'NEW_CUSTOMER'}
                onChange={value => {
                  if (value) {
                    setFieldValue('customer_type', 'NEW_CUSTOMER');
                  }
                }}
              />
            </RadioWrapper>
          </Column>
          <Column size={3}>
            <RadioWrapper>
              <CheckBox
                label="Can be clubbed with other discount"
                value={values.can_be_clubbed}
                onChange={value => setFieldValue('can_be_clubbed', value)}
                disabled
              />
            </RadioWrapper>
          </Column>
        </Column.Group>
      </BoxWithHeader> */}
      <BoxWithHeader title="Offer Details">
        <Column.Group vcentered>
          {toLower(values.deal_type) === 'delivery_fee_discount' && (
            <Column size={10}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RadioWrapper>
                  <Radio
                    label="Free Delivery"
                    value={values.free_delivery}
                    onChange={value => {
                      setFieldValue('free_delivery', value);
                      setFieldValue('value', 1);
                    }}
                  />
                </RadioWrapper>
                <RadioWrapper>
                  <Radio
                    label="Flat Amount Discount"
                    value={values.fee_type === 'FLAT' && !values.free_delivery}
                    onChange={() => {
                      if (values.fee_type === 'FLAT' && !values.free_delivery) {
                        // pass
                      } else {
                        setFieldValue('fee_type', 'FLAT');
                        setFieldValue('free_delivery', false);
                        setFieldValue('value', null);
                      }
                    }}
                  />
                </RadioWrapper>
                <RadioWrapper>
                  <Radio
                    label="Percentage Discount"
                    value={
                      values.fee_type === 'PERCENTAGE' && !values.free_delivery
                    }
                    onChange={() => {
                      if (
                        values.fee_type === 'PERCENTAGE' &&
                        !values.free_delivery
                      ) {
                        // pass
                      } else {
                        setFieldValue('fee_type', 'PERCENTAGE');
                        setFieldValue('free_delivery', false);
                        setFieldValue('value', null);
                      }
                    }}
                  />
                </RadioWrapper>
              </div>
              {values.fee_type === 'FLAT' && !values.free_delivery && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1rem',
                  }}
                >
                  <InputAddOn
                    addonsText="$"
                    isLeft
                    required
                    type="number"
                    min="0"
                    label="Minimum purchase amount"
                    value={values.minimum_purchase_amount}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setFieldValue('minimum_purchase_amount', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'minimum_purchase_amount',
                          parseFloat(e.target.value).toFixed(2),
                        );
                      }
                    }}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('minimum_purchase_amount', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'minimum_purchase_amount',
                          parseFloat(e.target.value),
                        );
                        if (
                          toLower(values.deal_type) === 'delivery_fee_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Free Delivery when you spend $${e.target.value}`,
                          );
                        }
                        if (
                          toLower(values.deal_type) === 'flat_amount_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Spend $${e.target.value} and Save $${values.value}`,
                          );
                        }
                        if (
                          toLower(values.deal_type) === 'percentage_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Spend $${e.target.value} and Save ${values.value}%`,
                          );
                        }
                      }
                    }}
                    errors={errors.minimum_purchase_amount}
                    touched={touched.minimum_purchase_amount}
                    disableToastMessage
                  />
                  <div style={{ marginRight: '1rem' }} />

                  <InputAddOn
                    addonsText="$"
                    isLeft
                    type="number"
                    min="0"
                    required
                    label="Discount Amount"
                    value={values.value}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setFieldValue('value', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'value',
                          parseFloat(e.target.value).toFixed(2),
                        );
                      }
                    }}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('value', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue('value', parseFloat(e.target.value));
                        if (
                          toLower(values.deal_type) === 'flat_amount_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Spend $${values.minimum_purchase_amount} and Save $${e.target.value} `,
                          );
                        }
                      }
                    }}
                    errors={errors.value}
                    touched={touched.value}
                    disableToastMessage
                  />
                </div>
              )}
              {values.fee_type === 'PERCENTAGE' && !values.free_delivery && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1rem',
                  }}
                >
                  <InputAddOn
                    addonsText="$"
                    isLeft
                    required
                    type="number"
                    min="0"
                    label="Minimum purchase amount"
                    value={values.minimum_purchase_amount}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setFieldValue('minimum_purchase_amount', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'minimum_purchase_amount',
                          parseFloat(e.target.value).toFixed(2),
                        );
                      }
                    }}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('minimum_purchase_amount', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'minimum_purchase_amount',
                          parseFloat(e.target.value),
                        );
                        if (
                          toLower(values.deal_type) === 'delivery_fee_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Free Delivery when you spend $${e.target.value}`,
                          );
                        }
                        if (
                          toLower(values.deal_type) === 'flat_amount_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Spend $${e.target.value} and Save $${values.value}`,
                          );
                        }
                        if (
                          toLower(values.deal_type) === 'percentage_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Spend $${e.target.value} and Save ${values.value}%`,
                          );
                        }
                      }
                    }}
                    errors={errors.minimum_purchase_amount}
                    touched={touched.minimum_purchase_amount}
                    disableToastMessage
                  />

                  <div style={{ marginRight: '1rem' }} />

                  <InputAddOn
                    addonsText="%"
                    isRight
                    type="number"
                    min="0"
                    id="DiscountPercentage"
                    label="Discount Percentage"
                    required
                    value={values.value}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('value', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue('value', parseFloat(e.target.value));
                        setFieldValue(
                          'name',
                          `Spend $${values.minimum_purchase_amount} and Save ${e.target.value}%`,
                        );
                      }
                    }}
                    errors={errors.value}
                    touched={touched.value}
                    disableToastMessage
                  />
                </div>
              )}
              {values.free_delivery && (
                <>
                  <div style={{ marginTop: '1rem' }} />
                  <InputAddOn
                    addonsText="$"
                    isLeft
                    required
                    type="number"
                    min="0"
                    label="Minimum purchase amount"
                    value={values.minimum_purchase_amount}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setFieldValue('minimum_purchase_amount', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'minimum_purchase_amount',
                          parseFloat(e.target.value).toFixed(2),
                        );
                      }
                    }}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('minimum_purchase_amount', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'minimum_purchase_amount',
                          parseFloat(e.target.value),
                        );
                        if (
                          toLower(values.deal_type) === 'delivery_fee_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Free Delivery when you spend $${e.target.value}`,
                          );
                        }
                        if (
                          toLower(values.deal_type) === 'flat_amount_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Spend $${e.target.value} and Save $${values.value}`,
                          );
                        }
                        if (
                          toLower(values.deal_type) === 'percentage_discount'
                        ) {
                          setFieldValue(
                            'name',
                            `Spend $${e.target.value} and Save ${values.value}%`,
                          );
                        }
                      }
                    }}
                    errors={errors.minimum_purchase_amount}
                    touched={touched.minimum_purchase_amount}
                    disableToastMessage
                  />
                </>
              )}
            </Column>
          )}

          {toLower(values.deal_type) !== 'delivery_fee_discount' && (
            <Column size={3}>
              <InputAddOn
                addonsText="$"
                isLeft
                required
                type="number"
                min="0"
                label="Minimum purchase amount"
                value={values.minimum_purchase_amount}
                onBlur={e => {
                  if (e.target.value === '') {
                    setFieldValue('minimum_purchase_amount', null);
                  } else if (!isNaN(parseFloat(e.target.value))) {
                    setFieldValue(
                      'minimum_purchase_amount',
                      parseFloat(e.target.value).toFixed(2),
                    );
                  }
                }}
                onChange={e => {
                  if (e.target.value === '') {
                    setFieldValue('minimum_purchase_amount', null);
                  } else if (!isNaN(parseFloat(e.target.value))) {
                    setFieldValue(
                      'minimum_purchase_amount',
                      parseFloat(e.target.value),
                    );
                    if (toLower(values.deal_type) === 'delivery_fee_discount') {
                      setFieldValue(
                        'name',
                        `Free Delivery when you spend $${e.target.value}`,
                      );
                    }
                    if (toLower(values.deal_type) === 'flat_amount_discount') {
                      setFieldValue(
                        'name',
                        e.target.value !== '0'
                          ? `Spend $${e.target.value} and Save $${values.value}`
                          : `Top Offer - $${values.value} off`,
                      );
                    }
                    if (toLower(values.deal_type) === 'percentage_discount') {
                      setFieldValue(
                        'name',
                        e.target.value !== '0'
                          ? `Spend $${e.target.value} and Save ${values.value}%`
                          : `Top Offer - ${values.value}% off`,
                      );
                    }
                  }
                }}
                errors={errors.minimum_purchase_amount}
                touched={touched.minimum_purchase_amount}
                disableToastMessage
              />
            </Column>
          )}
          {(toLower(values.deal_type) === 'flat_amount_discount' ||
            toLower(values.deal_type) === 'percentage_discount') && (
            <>
              <Column size={3}>
                {toLower(values.deal_type) === 'flat_amount_discount' && (
                  <InputAddOn
                    addonsText="$"
                    isLeft
                    type="number"
                    min="0"
                    required
                    label="Discount Amount"
                    value={values.value}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setFieldValue('value', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'value',
                          parseFloat(e.target.value).toFixed(2),
                        );
                      }
                    }}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('value', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue('value', parseFloat(e.target.value));
                        if (
                          toLower(values.deal_type) === 'flat_amount_discount'
                        ) {
                          setFieldValue(
                            'name',

                            values.minimum_purchase_amount !== '0.00'
                              ? `Spend $${values.minimum_purchase_amount} and Save $${e.target.value} `
                              : `Top Offer - $${e.target.value} off`,
                          );
                        }
                      }
                    }}
                    errors={errors.value}
                    touched={touched.value}
                    disableToastMessage
                  />
                )}
                {toLower(values.deal_type) === 'percentage_discount' && (
                  <InputAddOn
                    addonsText="%"
                    isRight
                    type="number"
                    min="0"
                    id="DiscountPercentage"
                    label="Discount Percentage"
                    required
                    value={values.value}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('value', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue('value', parseFloat(e.target.value));
                        setFieldValue(
                          'name',
                          values.minimum_purchase_amount !== '0.00'
                            ? `Spend $${values.minimum_purchase_amount} and Save ${e.target.value}%`
                            : `Top Offer - ${e.target.value}% off`,
                        );
                      }
                    }}
                    errors={errors.value}
                    touched={touched.value}
                    disableToastMessage
                  />
                )}
              </Column>
              <Column size={3}>
                {toLower(values.deal_type) === 'percentage_discount' && (
                  <InputAddOn
                    addonsText="$"
                    isLeft
                    type="number"
                    id="MaximumDiscountAmount"
                    label="Maximum discount amount"
                    value={values.maximum_discount_amount}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setFieldValue('maximum_discount_amount', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'maximum_discount_amount',
                          parseFloat(e.target.value).toFixed(2),
                        );
                      }
                    }}
                    onChange={e => {
                      if (e.target.value === '') {
                        setFieldValue('maximum_discount_amount', null);
                      } else if (!isNaN(parseFloat(e.target.value))) {
                        setFieldValue(
                          'maximum_discount_amount',
                          parseFloat(e.target.value),
                        );
                      }
                    }}
                    errors={errors.maximum_discount_amount}
                    touched={touched.maximum_discount_amount}
                    disableToastMessage
                    required
                  />
                )}
              </Column>
            </>
          )}
        </Column.Group>
        <p className="is-size-4">
          Discount amount cannot exceed 70% of minimum purchase
        </p>
      </BoxWithHeader>
      {toLower(values.deal_type) === 'specific_item_discount' && linksLoading && (
        <LoaderWrapper>
          <ReactLoading
            type="spin"
            color="#505050"
            height="3rem"
            width="3rem"
          />
        </LoaderWrapper>
      )}
      {toLower(values.deal_type) === 'specific_item_discount' &&
        !linksLoading &&
        links && (
          <ItemsAndVariantsDiscount
            linksLoading={linksLoading}
            links={links}
            setFieldValue={setFieldValue}
            menu_item={values.menu_item}
            selectedLinkIds={values.selectedLinkIds}
          />
        )}
      <Column.Group>
        <Column size={9}>
          <Input
            label="Deal/Voucher Tagline"
            name="name"
            required
            id="VoucherTagline"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.name}
            touched={touched.name}
            disabled={toLower(values.deal_type) === 'specific_item_discount'}
          />
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size={5}>
          <DealTimeDatePicker
            label="Start Date"
            timeLabel="Start Time"
            date={values.startDate}
            time={values.startTime}
            onDateChange={value => {
              setFieldValue('startDate', value);
            }}
            onTimeChange={value => {
              setFieldValue('startTime', value ? moment(value) : null);
            }}
            minDate={new Date()}
            errors={errors.startDate}
            touched={touched.startDate}
            required
          />
        </Column>
        <Column size={5}>
          <DealTimeDatePicker
            label="End Date"
            timeLabel="End Time"
            date={values.endDate}
            time={values.endTime}
            onDateChange={value => {
              setFieldValue('endDate', value);
            }}
            onTimeChange={value => {
              setFieldValue('endTime', value ? moment(value) : null);
            }}
            minDate={new Date(values.startDate)}
            errors={errors.endDate}
            touched={touched.endDate}
            disabled={values.startDate === null}
            required
          />
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size={5}>
          <BoxWithHeader title="Budget">
            {/* <Radio
              label="Unlimited users"
              value={values.maximum_no_of_discounts < 0}
              onChange={value => {
                if (value) {
                  setFieldValue('maximum_no_of_discounts', -1);
                } else {
                  setFieldValue('maximum_no_of_discounts', 0);
                }
              }}
              disabled
            /> */}
            <Flex>
              <Radio
                label="Maximum number of discounts"
                value={values.maximum_no_of_discounts > -1}
                onChange={value => {
                  if (value) {
                    setFieldValue('maximum_no_of_discounts', 0);
                  } else {
                    setFieldValue('maximum_no_of_discounts', -1);
                  }
                }}
                disabled
              />
              {values.maximum_no_of_discounts > -1 && (
                <Input
                  type="number"
                  min="0"
                  value={values.maximum_no_of_discounts}
                  onChange={e => {
                    if (e.target.value === '') {
                      setFieldValue('maximum_no_of_discounts', 0);
                    } else if (!isNaN(parseFloat(e.target.value))) {
                      setFieldValue(
                        'maximum_no_of_discounts',
                        parseFloat(e.target.value),
                      );
                    }
                  }}
                  disabled
                />
              )}
            </Flex>
            <br />
            <InputAddOn
              label="Budget Amount"
              addonsText="$"
              id="BudgetAmount"
              isLeft
              type="number"
              min="0"
              value={values.maximum_discount}
              onChange={e => {
                if (e.target.value === '') {
                  setFieldValue('maximum_discount', 0);
                } else if (!isNaN(parseFloat(e.target.value))) {
                  setFieldValue('maximum_discount', parseFloat(e.target.value));
                }
              }}
              disabled
            />
          </BoxWithHeader>
        </Column>
        <Column size={5}>
          <BoxWithHeader title="Maximum Number of discount per user">
            <Radio
              label="Unlimited"
              value={values.maximum_no_of_discounts_per_user < 0}
              onChange={value => {
                if (value) {
                  setFieldValue('maximum_no_of_discounts_per_user', -1);
                } else {
                  setFieldValue('maximum_no_of_discounts_per_user', 0);
                }
              }}
            />
            <Flex>
              <Radio
                label="Maximum number of discounts"
                value={values.maximum_no_of_discounts_per_user > -1}
                onChange={value => {
                  if (value) {
                    setFieldValue('maximum_no_of_discounts_per_user', 0);
                  } else {
                    setFieldValue('maximum_no_of_discounts_per_user', -1);
                  }
                }}
              />
              {values.maximum_no_of_discounts_per_user > -1 && (
                <Input
                  type="number"
                  min="0"
                  value={values.maximum_no_of_discounts_per_user}
                  onChange={e => {
                    if (e.target.value === '') {
                      setFieldValue('maximum_no_of_discounts_per_user', 0);
                    } else if (!isNaN(parseFloat(e.target.value))) {
                      setFieldValue(
                        'maximum_no_of_discounts_per_user',
                        parseFloat(e.target.value),
                      );
                    }
                  }}
                />
              )}
            </Flex>
          </BoxWithHeader>
        </Column>
      </Column.Group>
    </>
  );
};

export default VoucherDetails;
